import { Box, Button, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { HistoryCustomer } from '../../orderLookupModels';
import { CustomerDisplay } from '../CustomerDisplay';
import { HistoryMetadataDialog } from './HistoryMetadataDialog';

export function HistoryCustomerDisplay(customer: HistoryCustomer) {
    const hasCustomerMetadata = !!customer?.Metadatas && customer?.Metadatas.length > 0;
    const [customerMetadatasDialogOpen, setCustomerMetadatasDialogOpen] = React.useState(false);

    return !!customer.Id ? (
        <React.Fragment>
            <HistoryMetadataDialog
                metadatas={customer?.Metadatas}
                dialogTitle={'Customer Metadata'}
                showDialog={customerMetadatasDialogOpen}
                handleOpenDialog={setCustomerMetadatasDialogOpen}
            />
            <CustomerDisplay {...customer} />
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="%100">
                <Tooltip title={hasCustomerMetadata ? '' : 'No Metadata'} placement="top-start">
                    <span>
                        <Button
                            size="small"
                            disabled={!hasCustomerMetadata}
                            onClick={() => {
                                setCustomerMetadatasDialogOpen(true);
                            }}
                        >
                            METADATA
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        </React.Fragment>
    ) : (
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
            <Typography>Not Found</Typography>
        </Box>
    );
}
