import { Box, Button, Divider, TextField } from '@mui/material';
import React from 'react';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import shipMatrixApi from '../../shipMatrixApi';
import { Group } from '../../shipMatrixModel';
import CompaniesTransferList from './CompaniesTransferList';
import EditLogAutocomplete from './editLog/EditLogAutocomplete';

interface ShipMatrixClientsTabProps {
    currGroup: Group;
}

const ShipMatrixClientsTab = ({ currGroup }: ShipMatrixClientsTabProps) => {
    const [selectedClients, setSelectedClients] = React.useState(currGroup?.Clients || []);

    const [triggerEditClient, editClientResponse] = shipMatrixApi.endpoints.editClient.useMutation();

    useNotificationByResponse({
        isFetching: editClientResponse.isLoading,
        isError: editClientResponse.isError,
        errorContent:
            editClientResponse?.error && 'data' in editClientResponse?.error
                ? JSON.stringify(editClientResponse.error.data)
                : 'Failed to edit',
        isSuccess: editClientResponse.isSuccess,
        successContent: 'Edit successful',
        isUninitialized: editClientResponse.isUninitialized,
    });

    const [companyFilter, setCompanyFilter] = React.useState('');

    const handleFormSubmit = () => {
        triggerEditClient({
            GroupId: currGroup.Id,
            Clients: selectedClients,
        });
    };

    return (
        <Box sx={{ px: 2 }}>
            <TextField
                sx={{ width: '100%', mb: 2 }}
                label={'Filter'}
                value={companyFilter}
                id="company filter"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    setCompanyFilter(newValue);
                }}
            />

            <CompaniesTransferList
                currGroupId={currGroup.Id}
                selectedClients={selectedClients}
                setSelectedClients={setSelectedClients}
                companyFilter={companyFilter}
            />

            <Button sx={{ width: '100%', mt: 2 }} color="primary" variant="contained" onClick={handleFormSubmit}>
                Save
            </Button>

            <Divider sx={{ marginY: 2 }} />

            <EditLogAutocomplete groupId={currGroup.Id} />
        </Box>
    );
};

export default ShipMatrixClientsTab;
