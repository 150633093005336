import { useAppSelector } from '../../../app/store';
import ColoredDialog from './ColoredDialog';

const GlobalDialog = () => {
    const dialogOpen = useAppSelector(state => state.notification.dialogOpen);
    const dialogTitle = useAppSelector(state => state.notification.dialogTitle);
    const dialogContent = useAppSelector(state => state.notification.dialogContent);
    const dialogColor = useAppSelector(state => state.notification.dialogColor);

    return <ColoredDialog open={dialogOpen} title={dialogTitle} content={dialogContent} color={dialogColor} />;
};

export default GlobalDialog;
