import { FormControl } from '@mui/base/FormControl';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import orderLookupApi from '../../orderLookupApi';
import { Item, Serial } from '../../orderLookupModels';
import { SerialsAddFormTextField } from './SerialsAddFormTextField';

export function SerialsAddForm({ itemData, handleClose }: { itemData: Item | undefined; handleClose: () => void }) {
    const [serialValues, setSerialValues] = React.useState<Serial[]>([]);
    const cleanedSerialValues = serialValues.filter(serial => !!serial.Serial || !!serial.Serial2);
    const [formValidated, setFormValidated] = React.useState(false);
    const [postAddSerials, postAddSerialsReponse] = orderLookupApi.endpoints.postAddSerials.useMutation();
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);

    const saveSerialsEdits = () => {
        if (formValidated) {
            postAddSerials({
                serials: cleanedSerialValues,
                momCode: momCode,
                orderId: orderId,
                item: itemData,
            });
        }
    };

    useNotificationByResponse({
        isFetching: postAddSerialsReponse.isLoading,
        isError: !postAddSerialsReponse.data?.Success,
        errorContent: postAddSerialsReponse.data ? postAddSerialsReponse.data.Message : '',
        isUninitialized: postAddSerialsReponse.isUninitialized,
    });

    React.useEffect(() => {
        if (!postAddSerialsReponse.isUninitialized) {
            if (postAddSerialsReponse.data?.Success) {
                handleClose();
            }
        }
    }, [postAddSerialsReponse]);

    return (
        <Box>
            <FormControl>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ flex: 1, m: 2 }}>
                        <SerialsAddFormTextField
                            itemData={itemData}
                            setFormSerials={setSerialValues}
                            setFormValidated={setFormValidated}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button sx={{ flex: 1, m: 0, p: 1 }} color="error" onClick={saveSerialsEdits}>
                        ADD SERIALS
                    </Button>
                    <Button sx={{ flex: 1, m: 0, p: 1 }} onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </FormControl>
        </Box>
    );
}
