import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { SyntheticEvent } from 'react';
import { Rule } from '../../../receiversModels';
import React from 'react';

interface ScoreCardCheckboxProps extends Omit<FormControlLabelProps, 'onChange' | 'control'> {
    readOnly?: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
    rule?: Rule;
}

const ScoreCardCheckbox = ({
    readOnly = false,
    disabled = false,
    onChange,
    rule = {
        Regex: '',
        IsDisabled: false,
        IsRequired: false,
    },
    ...scoreCardCheckboxProps
}: ScoreCardCheckboxProps) => {
    const combinedIsDisabled = rule.IsDisabled || disabled;

    React.useEffect(() => {
        if (combinedIsDisabled && !readOnly) onChange(true);
    }, [combinedIsDisabled]);

    return (
        <FormControlLabel
            control={<Checkbox disabled={combinedIsDisabled} />}
            checked={scoreCardCheckboxProps?.checked}
            sx={{ flex: 1 }}
            onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) => {
                if (!readOnly) onChange(checked);
            }}
            {...scoreCardCheckboxProps}
        />
    );
};

export default ScoreCardCheckbox;
