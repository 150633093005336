import { FC, memo } from 'react';
import NumberTextfieldV2 from '../../../../../../common/components/TextField/NumberTextfieldV2';

interface MemoNumberTextFieldProps {
    label: string;
    step?: number;
    value: number;
    onChange: (newValue: number) => void;
    disabled?: boolean;
    error?: boolean;
}

const MemoNumberTextField: FC<MemoNumberTextFieldProps> = memo(
    ({ label, step = 1, value, onChange, disabled = false, error = false }) => {
        return (
            <NumberTextfieldV2
                value={value}
                onChange={newValue => {
                    onChange(newValue);
                }}
                shouldOnlyAllowValidNumberOnChange={true}
                textFieldProps={{
                    label: label,
                    disabled: disabled,
                    required: false,
                    sx: { mt: 2, width: '100%' },
                }}
            />
        );
    },
    arePropsEqual
);

function arePropsEqual(oldProps: MemoNumberTextFieldProps, newProps: MemoNumberTextFieldProps) {
    return oldProps.value === newProps.value;
}

export default MemoNumberTextField;
