import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import qualityControlApi from '../qualityControlApi';
import { resetQCReducer } from '../qualityControlSlice';
import { OrderDataDisplay } from './OrderDataDisplay';
import QualityControlDataGrid from './QualityControlDataGrid';
import QualityControlScanner from './QualityControlScanner';
import StateChangeButtons from './StateChangeButtons';
import useMount from '../../../common/hooks/useMount';

const QualityControlBox = () => {
    const dispatch = useAppDispatch();
    const orderData = useAppSelector(state => state.qualityControl.orderData);
    const [currBackgroundColor, setCurrBackgroundColor] = useState('#FFFFFF');
    const [isMounted] = useMount();

    useEffect(() => {
        return () => {
            if (!isMounted.current && orderData) {
                // triggerPostPartialSubmit(createPartialSubmitBody(orderData));
                dispatch(resetQCReducer());
                dispatch(qualityControlApi.util.resetApiState());
            }
        };
    }, [orderData]);

    return (
        <Box sx={{ backgroundColor: currBackgroundColor, pt: 1 }}>
            <QualityControlScanner />

            <Typography
                variant="h3"
                sx={{
                    textAlign: 'center',
                }}
            >
                {orderData?.PackageId.PkgId}
            </Typography>

            <QualityControlDataGrid />

            <OrderDataDisplay />

            <StateChangeButtons setCurrBackgroundColor={setCurrBackgroundColor} />
        </Box>
    );
};

export default QualityControlBox;
