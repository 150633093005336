import { Box, TextField } from '@mui/material';
import { Item } from '../../../../../receiversModels';

const ItemDetails = ({ item }: { item: Item | undefined }) => {
    return (
        <Box sx={{ m: 1 }}>
            <Box display={'flex'}>
                <TextField
                    value={item === undefined ? 'N/A' : item?.Sku || ''}
                    label={'SKU'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    value={item === undefined ? 'N/A' : item?.Upc || ''}
                    label={'UPC'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
            <Box display={'flex'}>
                <TextField
                    value={item === undefined ? 'N/A' : item?.Description || ''}
                    label={'Description'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>

            <Box display={'flex'}>
                <TextField
                    value={item === undefined ? 'N/A' : item?.Available?.toLocaleString() || ''}
                    label={'Available'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <TextField
                    value={item === undefined ? 'N/A' : item?.Bin || ''}
                    label={'Bin'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>

            <Box display={'flex'}>
                <TextField
                    value={item === undefined ? 'N/A' : item?.BOUnits || '0'}
                    label={'Back Ordered Units'}
                    sx={{ flex: 1, backgroundColor: item?.BOUnits && item?.BOUnits > 0 ? 'red' : '' }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <TextField
                    value={item === undefined ? 'N/A' : item?.UnitsInBox || '0'}
                    label={'Units in Box'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>

            <Box display={'flex'} sx={{}}>
                <TextField
                    value={item === undefined ? 'N/A' : item?.Serialized || 'false'}
                    label={'Is Serialized'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <TextField
                    value={item === undefined ? 'N/A' : item?.RequiredSerials || '0'}
                    label={'Number of Required Serials Per Item'}
                    sx={{ flex: 1 }}
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
        </Box>
    );
};

export default ItemDetails;
