import { Card, Dialog, DialogTitle, Typography } from '@mui/material';
import React, { FC } from 'react';
import { History, HistoryChangesRow } from '../../../../receiversModels';
import { formatDate } from '../../../../../../common/utils/dateUtil';
import EditHistoryCardDialogBody from './EditHistoryCardDialogBody';
import { object } from 'prop-types';

interface EditHistoryCardProps {
    historyElement: History;
}

const EditHistoryCard: FC<EditHistoryCardProps> = ({ historyElement }) => {
    const [open, setOpen] = React.useState(false);

    const handleCardClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //"{\"Location\":{\"+\":\"\",\"-\":\"Engle-A\"},\"ScDeliveryApt\":{\"+\":true,\"-\":false},\"ScPlqty\":{\"+\":true,\"-\":false}"
    const castToHistoryChangesRow = (input: string): HistoryChangesRow[] => {
        let historyChangesRequestObj = JSON.parse(input.replace('\\', ''));
        let result: HistoryChangesRow[] = [];

        for (const [key, value] of Object.entries(historyChangesRequestObj) as [string, any]) {
            const newRow: HistoryChangesRow = {
                item: key,
                old: typeof value['-'] === 'boolean' ? (value['-'] ? 'true' : 'false') : value['-'] ?? 'null',
                new: typeof value['+'] === 'boolean' ? (value['+'] ? 'true' : 'false') : value['+'] ?? 'null',
            };
            result = [...result, newRow];
        }

        return result;
    };

    const changesRows = castToHistoryChangesRow(historyElement.Changes);

    return (
        <>
            {' '}
            <Card variant="outlined" sx={{ p: 1, m: 2, display: 'flex' }} onClick={handleCardClick}>
                <Typography variant="body1" sx={{ flex: 1 }}>
                    {historyElement.User}
                </Typography>
                <Typography variant="body1" sx={{ flex: 1 }} align="center">
                    {formatDate(historyElement.DateCreated)}
                </Typography>
                <Typography variant="body1" sx={{ flex: 1 }} align="right">
                    {'View Changes'}
                </Typography>
            </Card>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>
                    Edited by {historyElement.User} on {formatDate(historyElement.DateCreated)}
                </DialogTitle>
                <EditHistoryCardDialogBody rows={changesRows} />
            </Dialog>
        </>
    );
};

export default EditHistoryCard;
