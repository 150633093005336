import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetRmaItemRequest, SetToGetSerialsForPayload } from './rmaModels';

export interface RmaState {
    momCode: string;
    rmaNumber: number;
    allSerials: string[];
    upcToGetSerialsFor: string;
    quantityIndexToGetSerialsFor: number;
}

const initialState: RmaState = {
    momCode: '',
    rmaNumber: 0,
    allSerials: [],
    upcToGetSerialsFor: '',
    quantityIndexToGetSerialsFor: -1,
};

const slice = createSlice({
    name: 'rma',
    initialState,
    reducers: {
        setToGetSerialsFor: (state, action: PayloadAction<SetToGetSerialsForPayload>) => {
            state.upcToGetSerialsFor = action.payload.upc;
            state.quantityIndexToGetSerialsFor = action.payload.quantityIndex;
        },
        setGetRmaParameters: (state, action: PayloadAction<GetRmaItemRequest>) => {
            state.momCode = action.payload.momCode;
            state.rmaNumber = action.payload.rmaNumber;
        },
        addToAllSerials: (state, action: PayloadAction<string | string[]>) => {
            if (typeof action.payload === 'string') {
                state.allSerials.push(action.payload);
            } else {
                state.allSerials = state.allSerials.concat(action.payload);
            }
        },
        deleteFromAllSerials: (state, action: PayloadAction<string | string[]>) => {
            if (typeof action.payload === 'string') {
                state.allSerials = state.allSerials.filter(serial => serial !== action.payload);
            } else {
                action.payload.map(
                    strToRemove => (state.allSerials = state.allSerials.filter(serial => serial !== strToRemove))
                );
            }
        },
        resetRmaReducer: (state, action: PayloadAction<void>) => {
            state.momCode = '';
            state.rmaNumber = 0;
        },
        resetRmaNumber: (state, action: PayloadAction<void>) => {
            state.rmaNumber = 0;
            state.allSerials = [];
            state.upcToGetSerialsFor = '';
            state.quantityIndexToGetSerialsFor = -1;
        },
        resetToGetSerialsFor: (state, action: PayloadAction<void>) => {
            state.upcToGetSerialsFor = '';
            state.quantityIndexToGetSerialsFor = -1;
        },
    },
});

export const {
    setToGetSerialsFor,
    setGetRmaParameters,
    resetRmaReducer,
    resetRmaNumber,
    addToAllSerials,
    deleteFromAllSerials,
    resetToGetSerialsFor,
} = slice.actions;

export default slice.reducer;
