import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../../app/store';
import ItemDropdown from '../../../../common/components/dropdown/ItemDropdown';
import { SubmissionFormItem } from '../../../inbound/receiversModels';
import { setDialog } from '../../../notification/notificationSlice';
import ItemDetails from '../../../receivers/components/createDialog/formPartTwo/items/addItemDialog/ItemDetails';
import receiversApi from '../../../receivers/receiversApi';
import { INVALID_CUSTOM_SKU_MESSAGE } from '../../constants';
import { SkuAdjustment } from '../../models';
import { getValidCustomSku } from '../../util';
import ItemEditDataGrid from './ItemEditDataGrid';

const AddItemDialog = ({
    isDialogOpen,
    client,
    onDialogClose,
    items,
    onAddNewItem,
    onProcessRowUpdate,
    onRowDelete,
    isFreeSoloEnabled = false,
}: {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    client: string;
    items: SkuAdjustment[];
    onAddNewItem: (newSkuAdjustment: SkuAdjustment) => void;
    onProcessRowUpdate: (newSkuAdjustment: SkuAdjustment) => void;
    onRowDelete: (sku: string) => void;
    isFreeSoloEnabled?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const [selectedSku, setSelectedSku] = React.useState('');

    const [triggerGetItemData, getItemResponse] = receiversApi.endpoints.getItem.useLazyQuery();

    const fetchedItemData = getItemResponse?.data?.Data[0];

    const handleAddNewItem = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!selectedSku) {
            dispatch(setDialog({ content: 'Must select valid SKU', title: 'Item not allowed' }));
            return;
        }

        if (items.find(item => item.Sku === selectedSku)) {
            dispatch(setDialog({ content: 'Cannot add same SKU', title: 'Item not allowed' }));
            return;
        }

        onAddNewItem({ Sku: selectedSku, Amount: 0 });
        setSelectedSku('');
    };

    const handleItemOnChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: NonNullable<string | SubmissionFormItem> | null
    ) => {
        if (!value) return;
        if (typeof value === 'string') {
            const validCustomSku = getValidCustomSku(value);
            if (!validCustomSku) {
                dispatch(
                    setDialog({
                        content: INVALID_CUSTOM_SKU_MESSAGE,
                        title: 'Invalid SKU',
                    })
                );
                return;
            }
            setSelectedSku(validCustomSku);
            return;
        }
        if (value?.Item) {
            triggerGetItemData({
                MomCode: client,
                Sku: value?.Item,
            });
            setSelectedSku(value?.Item);
        }
    };

    return (
        <Dialog onClose={onDialogClose} open={isDialogOpen} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>Add Receiver Item</DialogTitle>
            <DialogContent>
                <form onSubmit={handleAddNewItem}>
                    <Box sx={{ display: 'flex', my: 1 }}>
                        <ItemDropdown
                            client={client}
                            value={selectedSku}
                            onChange={handleItemOnChange}
                            sx={{ flex: 1 }}
                            textFieldProps={{ label: 'Stock Item' }}
                            freeSolo={isFreeSoloEnabled}
                        />
                        <Button color="secondary" variant="contained" sx={{ m: 1 }} type="submit">
                            Add Item
                        </Button>
                        {selectedSku}
                    </Box>
                </form>

                <ItemEditDataGrid
                    items={items}
                    onRowClick={sku => {
                        setSelectedSku(sku);
                    }}
                    onRowDelete={onRowDelete}
                    onProcessRowUpdate={onProcessRowUpdate}
                />
                <ItemDetails item={fetchedItemData} />
            </DialogContent>
        </Dialog>
    );
};

export default AddItemDialog;
