import { FormControlLabel } from '@mui/material';
import { FormGroup, Checkbox } from '@mui/material';

import React, { FC, SyntheticEvent, memo } from 'react';

interface MemoCheckboxProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
}

const MemoCheckbox: FC<MemoCheckboxProps> = memo(({ label, checked, onChange, disabled = false }) => {
    return (
        <FormControlLabel
            control={<Checkbox />}
            checked={checked}
            onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) => {
                onChange(checked);
            }}
            label={label}
            disabled={disabled}
            key={label}
        />
    );
}, arePropsEqual);

function arePropsEqual(oldProps: MemoCheckboxProps, newProps: MemoCheckboxProps) {
    return oldProps.checked === newProps.checked && oldProps.disabled === newProps.disabled;
}

export default MemoCheckbox;
