import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Backdrop, Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

const CanvasSpeedDial = ({
    onResetCameraButtonClick,
    onCreateInstructionButtonClick,
}: {
    onResetCameraButtonClick: () => void;
    onCreateInstructionButtonClick: () => void;
}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    const { procedureId } = useParams();

    const actions = [
        {
            name: 'Create New Instruction Card',
            icon: <EditIcon />,
            handleClick: onCreateInstructionButtonClick,
        },
        {
            name: 'Recenter Screen',
            icon: <CenterFocusStrongIcon />,
            handleClick: onResetCameraButtonClick,
        },
        {
            name: 'Preview Procedure',
            icon: <VisibilityIcon />,
            handleClick: () => {
                navigate(`/swrop/preview/${procedureId}`);
            },
        },
    ];
    return (
        <Box
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.modal + 1,
            }}
        >
            <Backdrop open={open} sx={{ zIndex: theme => theme.zIndex.fab - 1 }} />
            <SpeedDial
                ariaLabel="Work Order Actions"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClick={open ? handleClose : handleOpen}
                open={open}
                onClose={(e, reason) => {
                    if (reason === 'blur') handleClose();
                }}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                            handleClose();
                            action.handleClick();
                        }}
                        // FabProps={{ disabled: action.isDisabled }}
                        sx={{
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            '&.Mui-disabled': {
                                pointerEvents: 'auto',
                            },
                            // '&.MuiFab-root:hover': {
                            //     bgcolor: theme => (action.isDisabled ? theme.palette.action.disabledBackground : ''),
                            // },
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};

export default CanvasSpeedDial;
