import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Divider,
    FormHelperText,
} from '@mui/material';

import { actions, reducer } from './palletComReducer';
import { PalletModal } from './PalletModal';
import { Spinner } from '../../../common/components/index';

const PREFIX = 'Pallet';

const classes = {
    root: `${PREFIX}-root`,
    columns: `${PREFIX}-columns`,
    formControl: `${PREFIX}-formControl`,
    select: `${PREFIX}-select`,
    totalUnits: `${PREFIX}-totalUnits`,
    divider: `${PREFIX}-divider`,
    error: `${PREFIX}-error`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },

    [`& .${classes.columns}`]: {
        display: 'flex',
        flexDirection: theme.breakpoints.up('xs') ? 'row' : 'column',
    },

    [`& .${classes.formControl}`]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.select}`]: {
        textAlign: 'left',
    },

    [`& .${classes.totalUnits}`]: {
        marginBottom: '20px',
        fontSize: '18px',
    },

    [`& .${classes.divider}`]: {
        margin: '10px 0',
    },

    [`& .${classes.error}`]: {
        color: 'red',
    },
}));

const initialState = {
    palletSize: { value: '', error: true },
    sealedCase: { value: '', error: true },
    unitsPerCase: { value: '', error: true },
    partialQuantity: { value: '', error: true },
    comments: '',
};

const action = (actionType, actionValue) => ({
    type: actionType,
    value: actionValue,
});

export const Pallet = React.memo(
    ({ isLoading, palletData, taskTypeData, onSubmit, displayNextPallet, errorMessage, error, warehouse, sku }) => {
        // showError to handle the error on first render, not to display the error during first time render
        const [showError, setShowError] = useState(false);

        // maintaing all fields of state using reducer
        const [state, dispatch] = useReducer(reducer, initialState);
        const { palletSize, sealedCase, unitsPerCase, partialQuantity, comments } = state;

        const handlePalletSizeChange = useCallback(event => {
            const value = event.target.value;
            dispatch(action(actions.CHANGE_PALLET_SIZE, value)); // dispatching action for updating state
        }, []);

        const handleSealedCasesChange = useCallback(event => {
            const value = event.target.value;
            dispatch(action(actions.CHANGE_SEALED_CASES, value)); // dispatching action for updating state
        }, []);

        const handleUnitPerCaseChange = useCallback(event => {
            const value = event.target.value;
            dispatch(action(actions.CHANGE_UNIT_PER_CASE, value)); // dispatching action for updating state
        }, []);

        const handlePartialQuantityChange = useCallback(event => {
            const value = event.target.value;
            dispatch(
                action(actions.CHANGE_PARTIAL_QUANTITY, value) // dispatching action for updating state
            );
        }, []);

        const handleCommentsChange = useCallback(event => {
            const value = event.target.value;
            dispatch(action(actions.ADD_COMMENTS, value)); // dispatching action for updating state
        }, []);

        //submit 0 and go next if "no" pallet at location
        const handleNextPallet = useCallback(event => {
            displayNextPallet();
            const submitPalletData = {
                ...palletData,
                caseCount: 0,
                sealedCases: 0,
                partialQuantity: 0,
                size: 0,
                comment: 'IMPOSSIBLE',
                isCompleted: true,
                completedDateTimeOffset: new Date().toISOString(),
            };
            delete submitPalletData.palletTag;
            delete submitPalletData.location;
            onSubmit(submitPalletData);
        }, []);

        const totalUnits = useMemo(() => sealedCase.value * unitsPerCase.value + partialQuantity.value, [
            sealedCase,
            unitsPerCase,
            partialQuantity,
        ]);

        const handlePalletSubmit = useCallback(
            event => {
                event.persist();
                event.preventDefault();
                if (
                    !state.palletSize.value ||
                    state.sealedCase.value === '' ||
                    state.unitsPerCase.value === '' ||
                    state.partialQuantity.value === ''
                ) {
                    setShowError(true);
                    return;
                }
                const submitPalletData = {
                    ...palletData,
                    locationId: 2, // Remove this after checking with Nikhil
                    caseCount: state.unitsPerCase.value,
                    sealedCases: state.sealedCase.value,
                    partialQuantity: state.partialQuantity.value,
                    size: state.palletSize.value,
                    comment: (state.comments || '').trim(),
                    isCompleted: true,
                    completedDateTimeOffset: new Date().toISOString(),
                };
                delete submitPalletData.locationId;
                delete submitPalletData.palletTag;
                delete submitPalletData.location;
                onSubmit(submitPalletData);
            },
            [state, palletData, onSubmit]
        );
        if (isLoading) {
            return <Spinner />;
        }
        return (
            <Root>
                {error && <span className={classes.error}>{errorMessage}</span>}
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box>
                            <strong>Warehouse: </strong>
                            {warehouse}
                        </Box>
                        <Box>
                            <strong>Location:</strong> {palletData.location}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box>
                            <strong>Pallet Tag:</strong> {palletData.palletTag}
                        </Box>
                        <Box>
                            <strong>SKU:</strong> {sku}
                        </Box>
                    </Box>
                </Box>
                <Divider className={classes.divider} />
                <form className={classes.root} noValidate autoComplete="off" onSubmit={handlePalletSubmit}>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth size="small">
                        <InputLabel id="palletSize-input" error={showError && palletSize.error}>
                            Pallet Size
                        </InputLabel>
                        <Select
                            required
                            labelId="palletSize-label"
                            id="palletSize-select"
                            value={palletSize.value}
                            onChange={handlePalletSizeChange}
                            className={classes.select}
                            error={showError && palletSize.error}
                            label="Pallet Size * "
                        >
                            <MenuItem value="0">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">Standard</MenuItem>
                            <MenuItem value="2">Oversize</MenuItem>
                            <MenuItem value="3">Shelf</MenuItem>
                        </Select>
                        {showError && palletSize.error && (
                            <FormHelperText className={classes.error}>Please select a value</FormHelperText>
                        )}
                    </FormControl>
                    <TextField
                        label="Sealed Cases"
                        placeholder="Sealed Cases"
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={sealedCase.value}
                        onChange={handleSealedCasesChange}
                        error={showError && sealedCase.error}
                        helperText={showError && sealedCase.error && 'Please enter 0 if count is not available'}
                    />
                    <TextField
                        label="Units per Case"
                        placeholder="Units per Case"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        value={unitsPerCase.value}
                        onChange={handleUnitPerCaseChange}
                        error={showError && unitsPerCase.error}
                        helperText={showError && unitsPerCase.error && 'Please enter 0 if count is not available'}
                    />
                    <TextField
                        label="Partial Quantity"
                        placeholder="Partial Quantity"
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={partialQuantity.value}
                        onChange={handlePartialQuantityChange}
                        error={showError && partialQuantity.error}
                        helperText={showError && partialQuantity.error && 'Please enter 0 if count is not available'}
                    />
                    <TextField
                        label="Comments"
                        placeholder="Comments"
                        multiline
                        rows={3}
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={comments}
                        onChange={handleCommentsChange}
                    />
                    <Divider className={classes.divider} />
                    <Box className={classes.totalUnits}>
                        <strong>Total Units:</strong> {totalUnits}
                    </Box>

                    <Box m={2}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Box>
                </form>
                <PalletModal
                    showDialog={!error}
                    palletTag={palletData.palletTag}
                    location={palletData.location}
                    displayNextPallet={handleNextPallet}
                />
            </Root>
        );
    }
);

Pallet.propTypes = {
    palletData: PropTypes.object.isRequired,
    taskTypeData: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    displayNextPallet: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    error: PropTypes.bool,
};
