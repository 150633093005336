import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/store';

export const RmaIdDisplay = () => {
    const rma = useAppSelector(state => state.rma.rma);

    if (rma?.AltRmaNumber) {
        return <Typography variant="h6">RMA: {rma?.AltRmaNumber}</Typography>;
    }
    if (rma?.RmaNumber) {
        return <Typography variant="h6">RMA: {rma?.RmaNumber}</Typography>;
    }
    if (rma?.TrackingNumbers.length == 1) {
        return <Typography variant="h6">TRACKING: {rma?.TrackingNumbers[0]}</Typography>;
    }

    return (
        <Box>
            {(!rma?.TrackingNumbers || rma.TrackingNumbers.length > 0) && (
                <Typography variant="h6">TRACKING NUMBERS:</Typography>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                {rma?.TrackingNumbers.map(number => (
                    <Typography>{number}</Typography>
                ))}
            </Box>
        </Box>
    );
};
