import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { SkuAdjustment } from '../../models';

export default function ItemEditDataGrid({
    items,
    onRowClick,
    onRowDelete,
    onProcessRowUpdate,
}: {
    items: SkuAdjustment[];
    onRowClick: (sku: string) => void;
    onRowDelete: (sku: string) => void;
    onProcessRowUpdate: (newRow: SkuAdjustment) => void;
}) {
    const handleRowClick = (params: GridRowParams<SkuAdjustment>) => {
        onRowClick(params.row.Sku);
    };

    const handleRowProcess = (newRow: SkuAdjustment): SkuAdjustment => {
        onProcessRowUpdate(newRow);
        return newRow;
    };

    const columns: GridColDef<SkuAdjustment>[] = [
        {
            field: 'Sku',
            headerName: 'Sku',
            flex: 3,
        },
        {
            field: 'Amount',
            headerName: 'Amount',
            flex: 1,
            editable: true,
            type: 'number',
        },
        {
            field: 'Delete',
            type: 'actions',
            headerName: 'Delete',
            flex: 1,
            getActions: value => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => onRowDelete(value.row.Sku)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <DataGrid
            rows={items}
            columns={columns}
            editMode="row"
            processRowUpdate={handleRowProcess}
            getRowId={row => row.Sku}
            onRowClick={handleRowClick}
            sx={{ minHeight: 300, my: 2 }}
            hideFooter
        />
    );
}
