import { ReceivedItem, ReceivedItemUnitDetail } from './rmaModels';

export const NEW_RECEIVED_ITEM: ReceivedItem = {
    Id: null,
    RmaId: null,
    Quantity: null,
    ExpectedItemId: null,
    Disposition: null,
    NotFound: false,
    Serials: [],
    ItemUnitDetails: [],
    Sku: null,
    Upc: null,
    SerialVerify: null,
    IsSerialized: false,
    Description: null,
    ValidToSubmit: false,
};

export const NEW_RECEIVED_ITEM_UNIT_DETAIL: ReceivedItemUnitDetail = {
    SerialNotes: null,
    DispositionNotes: null,
    Serials: [],
    Disposition: null,
    MissingSerialReason: null,
    ValidToSubmit: false,
};

export const NEW_STATUS = 'NEW';
export const COMPLETED_STATUS = 'COMPLETED';
export const NEW_STATUS_ID = 1;
export const COMPLETED_STATUS_ID = 2;
export const RMA_STATUS: { [key: number]: string } = { 1: NEW_STATUS, 2: COMPLETED_STATUS };

export const SKU_IMG_HEIGHT = { xs: 233, md: 167 };
export const SKU_IMG_WIDTH = { xs: 370, md: 260 };

export const BULK_UNIT_THRESHOLD_QTY = 10;
export const DISPOSITION_OTHER_ID = 4;
export const MISSING_SERIAL_REASON_OTHER_ID = 3;

export const SERIAL_QTY_LIMIT_PER_UNIT = 1;
export const CLIENT_CODE_REGEX_FROM_REF_PO = /^[A-Za-z]{3,4}/;
