import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../common/components/Dialog/DialogCloseButton';
import { ReceivedItem } from '../rmaModels';

export const ConfirmClosingDialog = React.memo(
    ({
        setPreviousDialog,
        handleOpenDialog,
        unsavedItem,
        resetToUnsavedItem,
        showDialog = false,
    }: {
        setPreviousDialog: (openState: boolean) => void;
        handleOpenDialog: (openState: boolean) => void;
        unsavedItem?: ReceivedItem;
        resetToUnsavedItem?: (item: ReceivedItem) => void;
        showDialog?: boolean;
    }) => {
        const handleConfirmExit = () => {
            handleOpenDialog(false);
            setPreviousDialog(false);
            // Ensuring reseting the item after the dialog closes fully
            // Changing the qty will change the dialog size prior to closing
            if (!!resetToUnsavedItem && !!unsavedItem) {
                setTimeout(() => {
                    resetToUnsavedItem({ ...unsavedItem });
                }, 100);
            }
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm">
                <DialogTitle color="white" bgcolor="error.main" align="center">
                    <Typography fontWeight={'fontWeightMedium'} variant="h6">
                        CONFIRM EXIT
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Box
                            sx={{
                                my: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6">ARE YOU SURE?</Typography>
                            <Typography variant="h6">ALL CHANGES WILL BE LOST!</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mt: 3 }}>
                            <Button
                                size="large"
                                fullWidth
                                onClick={() => {
                                    handleOpenDialog(false);
                                }}
                            >
                                NO
                            </Button>
                            <Button
                                size="large"
                                fullWidth
                                variant="contained"
                                color="error"
                                onClick={handleConfirmExit}
                            >
                                EXIT WITHOUT SAVING
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogCloseButton
                    handleClose={() => {
                        handleOpenDialog(false);
                    }}
                />
            </Dialog>
        );
    }
);
