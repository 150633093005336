import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect } from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { BULK_UNIT_THRESHOLD_QTY } from '../../constants';
import { ExpectedItem, ReceivedItem } from '../../rmaModels';
import { ReceivedItemUnitDetailIndividualForm } from './ReceivedItemUnitDetailIndividualForm';

export const ReceivedItemUnitDetailIndividualFormDialog = React.memo(
    ({
        receivedItem,
        expectedItem,
        setReceivedItem,
        handleOpenDialog,
        unitIndex,
        showDialog = false,
    }: {
        receivedItem: ReceivedItem;
        expectedItem?: ExpectedItem;
        setReceivedItem: (item: ReceivedItem) => void;
        handleOpenDialog: (openState: boolean) => void;
        unitIndex: number;
        showDialog?: boolean;
    }) => {
        const [editingItem, setEditingItem] = React.useState<ReceivedItem>(receivedItem);
        const [showErrors, setShowErrors] = React.useState(false);

        const closeWithoutSaving = () => {
            setEditingItem(receivedItem);
            handleOpenDialog(false);
        };

        const handleSaveEditedItem = () => {
            // Check if fields are properly filled out
            if (!passFormErrorValidation()) {
                setShowErrors(true);
                return;
            }

            savedEditedItem();
            setShowErrors(false);
            handleOpenDialog(false);
        };

        const savedEditedItem = () => {
            setReceivedItem({
                ...editingItem,
                Quantity: receivedItem.Quantity,
                Serials: editingItem.ItemUnitDetails.map(unit => unit.Serials).flat(),
                ValidToSubmit: !editingItem.ItemUnitDetails.some((unit, index) => !unit.ValidToSubmit),
            });
        };

        useEffect(() => {
            setEditingItem(receivedItem);
        }, [receivedItem]);

        // Update if units are deleted or added
        useEffect(() => {
            //This happens when the unit has been deleted so close the current dialog
            if (receivedItem.ItemUnitDetails.length > editingItem.ItemUnitDetails.length) {
                handleOpenDialog(false);
            }
            setReceivedItem(editingItem);
        }, [editingItem.ItemUnitDetails.length]);

        const passFormErrorValidation = () => {
            // Check if each item unit has been completed
            if (editingItem.ItemUnitDetails.some((unit, index) => !unit.ValidToSubmit && index === unitIndex)) {
                return false;
            }
            return true;
        };

        return (
            <React.Fragment>
                <Dialog
                    open={showDialog}
                    maxWidth={!!editingItem.Quantity && editingItem.Quantity > BULK_UNIT_THRESHOLD_QTY ? 'lg' : 'md'}
                    fullWidth
                    PaperProps={{
                        sx: { backgroundColor: grey[100] },
                    }}
                >
                    <DialogTitle color="white" bgcolor="primary.main" align="center">
                        <Typography fontWeight={'fontWeightMedium'} variant="h5">
                            PROCESS UNIT # {unitIndex + 1}
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ mt: 2 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <ReceivedItemUnitDetailIndividualForm
                                unitIndex={unitIndex}
                                editingItem={editingItem}
                                setEditingItem={setEditingItem}
                                showErrors={showErrors}
                                hideDialogTitle={true}
                            />
                            <Box sx={{ display: 'flex', mt: 1 }}>
                                <Button
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleSaveEditedItem}
                                    variant="contained"
                                >
                                    APPLY
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogCloseButton handleClose={closeWithoutSaving} />
                </Dialog>
            </React.Fragment>
        );
    }
);
