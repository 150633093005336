import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { CreatePlusIcon } from '../../../../common/components';
import CreateProcedureDialog from './CreateProcedureDialog';

const CreateProcedureButton = () => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const handleButtonClick = () => {
        setIsDialogOpen(true);
    };

    //dialog asks for client and title
    return (
        <>
            <CreateProcedureDialog
                isOpen={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
            />
            <CreatePlusIcon testId={'create-procedure'} label="add" buttonIcon={AddIcon} onClick={handleButtonClick} />;
        </>
    );
};

export default CreateProcedureButton;
