import React from 'react';
import { styled } from '@mui/material/styles';
import { Slider, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const PREFIX = 'ProgressSlider';

const classes = {
    slider: `${PREFIX}-slider`,
    value: `${PREFIX}-value`,
};

const StyledBox = styled(Box)(({ theme }) => {
    return {
        [`&.${classes.slider}`]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [`& .${classes.value}`]: {
            marginLeft: 8,
            fontSize: 14,
            color: theme.palette.grey[500],
        },
    };
});

const useSliderStyles = makeStyles(() => ({
    root: {
        height: 10,
    },
    rail: {
        borderRadius: 10,
        height: 10,
        backgroundColor: 'rgb(202,211,216)',
    },
    track: {
        borderRadius: 10,
        height: 10,
        backgroundColor: 'rgb(126, 184, 126)',
    },
    thumb: {
        display: 'none',
    },
}));

export const ProgressSlider = React.memo(({ completedNumber, totalNumber }) => {
    const sliderStyles = useSliderStyles();

    let displayProgress;
    if (completedNumber === 0 && totalNumber === 0) {
        // displayProgress = (
        //     <Typography variant="body1" className={classes.onlyText}>
        //         Not started
        //     </Typography>
        // );
        displayProgress = null;
    } else if (completedNumber > 0 && totalNumber === completedNumber) {
        displayProgress = null;
        // displayProgress = (
        //     <Typography variant="body1" className={classes.onlyText}>
        //         Completed
        //     </Typography>
        // );
    } else {
        displayProgress = (
            <>
                <Slider classes={sliderStyles} value={(completedNumber * 100) / totalNumber} disabled />
                <Typography variant="body1" className={classes.value}>
                    {completedNumber}/{totalNumber}
                </Typography>{' '}
            </>
        );
    }

    return <StyledBox className={classes.slider}>{displayProgress}</StyledBox>;
});
