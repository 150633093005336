import { Typography, Box, Container, Card, Toolbar, AppBar, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import { ProgressSlider } from '../../../common/components/sllider/ProgressSlider';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import { ReAssignTask } from '../task/ReAssignTask';
import { useDispatch } from 'react-redux';
import {
    getReAssignTaskDetailsAction,
    getApproveTaskDetailsAction,
    updateTaskListAfterReAssigningAction,
    getRejectTaskDetailsAction,
    discardTaskAction,
} from '../../store/actions/action';
import TaskPalletCardsDetail from './TaskPalletCardsDetail';
import TaskPalletActionBox from './TaskPalletActionBox';
import TaskRejectCommentBox from './TaskRejectCommentBox';
import { customTheme } from '../../../common/components/Theme';
import { isFeatureAccessible } from '../../../common/utils/commonUtils';
import * as permissions from '../../../features/roles/permissionsList';
import { ACTIVE_PAGE, PENDING_PAGE, REVIEW_PAGE } from '../../../common/constants/stockCountTabs';
import { isInCountingState } from '../../taskUtil';
import { APPROVED_STATE, COMPLETED_STATE, REJECTED_STATE } from '../../../common/constants/taskStates';

const StyledWrapper = styled(Container)(({ theme }) => ({
    minWidth: '700px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
        minWidth: 500,
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: '100vw',
    },
}));

const StyledCard = styled(Card)(({ theme }) => ({
    padding: '16px 0',
    boxShadow: '0 0 6px rgba(0,0,0,0.1)',
    margin: '20px 20px 0',
}));

const StyledRedChip = styled(Chip)(({ theme }) => ({
    backgroundColor: '#FF4651',
    marginLeft: 10,
    color: '#fff',
}));

const StyledGreenChip = styled(Chip)(({ theme }) => ({
    backgroundColor: '#0CCE6B',
    marginLeft: 10,
    color: '#fff',
}));

const StyledReviewPalletCardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'space-between',
    },
}));

const StyledReviewPalletCardHeaderTitle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const StyledDrawerSubHeader = styled('div')(({ theme }) => ({
    padding: '15px 20px',
    boxShadow: '0 2px 2px rgb(233 233 233)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },
}));

const StyledInfoHeaders = styled(Typography)(({ theme }) => ({
    paddingRight: 10,
    color: '#616060',
}));

const StyledReviewButton = styled(Button)(({ theme }) => ({
    color: 'white',
    // backgroundColor: customTheme.buttonGreen,
    fontSize: '0.7rem',
    paddingTop: 4,
    paddingBottom: 4,
    [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-end',
    },
}));

const StockCountTaskPalletCards = ({
    countTask,
    toggleDrawer,
    skuData,
    companyDetails,
    handleCloseDialog,
    tabType,
}) => {
    const {
        tasks: { taskList, taskListOfUser, rejectTaskDetails, reAssignTaskDetails },
        users: { userPermissions },
    } = useSelector(state => state);
    const [isReAssignTaskDialogOpen, setIsReAssignTaskDialogOpen] = useState(false);
    const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [rejectedTaskList, setRejectedTaskList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (taskList && taskList.length > 0) {
            const rejectedTasksArray = taskList.filter(tl => tl.taskState === 4);
            setRejectedTaskList(rejectedTasksArray);
        } else {
            setRejectedTaskList([]);
        }
    }, [taskList]);

    const onReAssignTaskHandler = useCallback(() => {
        dispatch(getReAssignTaskDetailsAction(countTask && countTask.taskId));
        // if (reAssignTaskDetails && Object.keys(reAssignTaskDetails).length > 0) {
        onClickReAssignTaskDialogHandler();
        // }
    }, [dispatch, countTask]);

    const onApproveTaskHandler = useCallback(() => {
        const userSessionDetails = localStorage.getItem('userSession')
            ? JSON.parse(localStorage.getItem('userSession'))
            : '';
        if (userSessionDetails && userSessionDetails.userId) {
            dispatch(
                getApproveTaskDetailsAction({
                    taskId: countTask && countTask.taskId,
                    completedDateTimeOffSet: `${new Date().toISOString()}`,
                    updatedByUserId: userSessionDetails.userId,
                    companyId: companyDetails?.companyId,
                    sku: countTask && countTask.sku,
                    taskState: [3, 4],
                })
            );
            // setCurrentTaskStateId(4);
            const taskListArray = [...taskList];
            if (taskListArray.length === 1) {
                const updatedTaskList = taskListArray.filter(task => task.taskId !== countTask.taskId);
                dispatch(updateTaskListAfterReAssigningAction(updatedTaskList));
            }
            // onSuccess(true);
        }
    }, [taskList, countTask, companyDetails?.companyId, dispatch]);

    const discardTaskHandler = useCallback(() => {
        const userSessionDetails = localStorage.getItem('userSession')
            ? JSON.parse(localStorage.getItem('userSession'))
            : '';
        if (userSessionDetails && userSessionDetails.userId) {
            dispatch(
                discardTaskAction({
                    taskId: countTask && countTask.taskId,
                    completedDateTimeOffSet: `${new Date().toISOString()}`,
                    updatedByUserId: userSessionDetails.userId,
                    companyId: companyDetails?.companyId,
                    sku: countTask && countTask.sku,
                    taskState: [3, 4],
                })
            );
        }
    }, [countTask, companyDetails?.companyId, dispatch]);

    const onRejectTaskHandler = useCallback(() => {
        onClickReAssignTaskDialogHandler();
    }, [countTask, rejectReason, dispatch]);

    const onClickReAssignTaskDialogHandler = () => {
        setIsReAssignTaskDialogOpen(isReAssignTaskDialogOpen => !isReAssignTaskDialogOpen);
    };

    const getPalletBadge = pallet => {
        if (pallet.taskState === 5) {
            return <StyledGreenChip size="small" label="Approved" />;
        } else if (pallet.taskState === 4) {
            return <StyledRedChip size="small" label="Rejected" />;
        } else if (pallet.taskState === 3) {
            return <StyledGreenChip size="small" label="Completed" />;
        } else {
            if (pallet.numCompleted > 0 && pallet.numPallets === pallet.numCompleted) {
                return <StyledGreenChip size="small" label="Completed" />;
            } else {
                if (pallet.numCompleted === 0 && pallet.numPallets === 0) {
                    return <StyledRedChip size="small" label="Not Started" />;
                }
                return null;
            }
        }
    };

    const getWarehouseTotalCount = () => {
        if (taskList && taskList.length > 0) {
            if (tabType === ACTIVE_PAGE) {
                return taskList.reduce(
                    (acc, counter) => (counter.warehouseCount ? acc + counter.warehouseCount : acc),
                    0
                );
            }
            if (tabType === REVIEW_PAGE) {
                return taskList
                    .filter(pallet => pallet?.taskId === countTask?.taskId)
                    .reduce((acc, counter) => {
                        if (counter.warehouseCount) {
                            const subWarehouseCountTotal = Object.values(counter.warehouseCount).reduce(
                                (acc, counter) => acc + counter,
                                0
                            );
                            return acc + subWarehouseCountTotal;
                        }
                        return acc;
                    }, 0);
            }
        }
        return 0;
    };

    const handleCommentChange = useCallback(({ target: { value } }) => {
        setRejectReason(value);
    }, []);

    const getCardTitle = pallet => {
        if (tabType === PENDING_PAGE || tabType === REVIEW_PAGE) {
            return (
                <span
                    style={{
                        fontWeight: 700,
                        color:
                            pallet.taskState === COMPLETED_STATE
                                ? '#FF8C00'
                                : pallet.taskState === APPROVED_STATE
                                ? '#0AAC49'
                                : pallet.taskState === REJECTED_STATE
                                ? '#DC143C'
                                : 'default',
                    }}
                >
                    {pallet.taskState === COMPLETED_STATE
                        ? 'In Review'
                        : pallet.taskState === APPROVED_STATE
                        ? 'Approved'
                        : pallet.taskState === REJECTED_STATE
                        ? 'Rejected'
                        : pallet.warehouse}
                </span>
            );
        }
        if (tabType === ACTIVE_PAGE) {
            return (
                <span
                    style={{
                        fontWeight: 700,
                        color: '#616060',
                    }}
                >{`${pallet.warehouse} : ${pallet.warehouseCount}`}</span>
            );
        }
        return pallet.warehouse;
    };

    return (
        <Fragment>
            <AppBar position="static">
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <Typography>{companyDetails?.companyName}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ marginRight: 10 }}>{countTask && countTask.sku}</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {tabType === PENDING_PAGE &&
                                isFeatureAccessible(userPermissions, permissions.TASK_REASSIGN) && (
                                    <StyledReviewButton
                                        style={{ marginRight: 7, backgroundColor: customTheme.buttonGreen }}
                                        onClick={onReAssignTaskHandler}
                                    >
                                        Re-Assign
                                    </StyledReviewButton>
                                )}
                            <CloseIcon
                                style={{ cursor: 'pointer', color: '#fff' }}
                                onClick={() => toggleDrawer(false, null)}
                            />
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div>
                {tabType !== ACTIVE_PAGE && (
                    <StyledDrawerSubHeader>
                        <StyledInfoHeaders variant="body1" color="initial">
                            Total: <span>{getWarehouseTotalCount()}</span>
                        </StyledInfoHeaders>
                        <StyledInfoHeaders variant="body1" color="initial">
                            Expected: <span>{(countTask && countTask.snapShotCount) || 0}</span>
                        </StyledInfoHeaders>
                        {countTask && !!countTask.snapShotCount && (
                            <StyledInfoHeaders variant="body1" color="initial">
                                Difference:{' '}
                                <span
                                    style={{
                                        color:
                                            getWarehouseTotalCount() - countTask.snapShotCount === 0 ? 'green' : 'red',
                                    }}
                                >
                                    {getWarehouseTotalCount() - countTask.snapShotCount}
                                </span>
                            </StyledInfoHeaders>
                        )}
                    </StyledDrawerSubHeader>
                )}
                <StyledWrapper>
                    {taskList
                        .slice()
                        .filter(pallet => {
                            if (!countTask || !countTask.taskId) return true;
                            if (isInCountingState(pallet.taskState)) {
                                //show only task details
                                return pallet?.taskId === countTask?.taskId;
                            } else {
                                //show all tasks in series
                                if (countTask.countSeriesNumber)
                                    return pallet?.countSeriesNumber === countTask?.countSeriesNumber;
                                else return pallet?.countSeriesNumber === taskListOfUser[0]?.countSeriesNumber;
                            }
                        })
                        .sort((a, b) => (a.assignedOnDateTime > b.assignedOnDateTime ? -1 : 1))
                        .map((pallet, ind) => (
                            <div
                                style={{ marginTop: ind === 0 ? 0 : 15 }}
                                key={pallet.assignedOnDateTime + pallet.warehouse}
                            >
                                <StyledCard
                                    elevation={1}
                                    // sx={{
                                    //     backgroundColor:
                                    //         pallet?.taskId === countTask?.taskId ? theme => theme.palette.purple : '',
                                    // }}
                                >
                                    <StyledReviewPalletCardHeader>
                                        <StyledReviewPalletCardHeaderTitle>
                                            <StyledInfoHeaders variant="body1" color="initial">
                                                {getCardTitle(pallet)}
                                            </StyledInfoHeaders>
                                            {getPalletBadge(pallet)}
                                        </StyledReviewPalletCardHeaderTitle>
                                        {isFeatureAccessible(userPermissions, permissions.TASK_UPDATE) && (
                                            <TaskPalletActionBox
                                                pallet={pallet}
                                                tabType={tabType}
                                                onReAssignTaskHandler={onReAssignTaskHandler}
                                                onApproveTaskHandler={onApproveTaskHandler}
                                                setIsCommentBoxOpen={setIsCommentBoxOpen}
                                                discardTaskHandler={discardTaskHandler}
                                                rejectedTaskList={rejectedTaskList}
                                                userPermissions={userPermissions}
                                            />
                                        )}
                                    </StyledReviewPalletCardHeader>
                                    {pallet.rejectedBy && (
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            style={{
                                                fontSize: 12,
                                                marginTop: 7,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                            }}
                                        >
                                            <span style={{ color: '#616060' }}>
                                                {pallet.taskState === 5
                                                    ? 'Accepted '
                                                    : pallet.taskState === 6
                                                    ? 'Discarded'
                                                    : 'Rejected'}{' '}
                                                By:{' '}
                                            </span>
                                            {pallet.rejectedBy}
                                        </Typography>
                                    )}
                                    {pallet.comment && (
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            style={{
                                                fontSize: 12,
                                                marginTop: 5,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                            }}
                                        >
                                            <span style={{ color: '#616060' }}>Reason: </span>
                                            {pallet.comment}
                                        </Typography>
                                    )}
                                    {pallet.hasOwnProperty('numCompleted') &&
                                        pallet.hasOwnProperty('numPallets') &&
                                        pallet.numCompleted !== pallet.numPallets && (
                                            <div style={{ padding: '0 10px', display: 'flex', alignItems: 'center' }}>
                                                <StyledInfoHeaders>Progress :</StyledInfoHeaders>
                                                <div style={{ flex: '1', marginLeft: 10 }}>
                                                    <ProgressSlider
                                                        completedNumber={pallet.numCompleted}
                                                        totalNumber={pallet.numPallets}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                    <TaskPalletCardsDetail
                                        tabType={tabType}
                                        pallet={pallet}
                                        countTask={countTask}
                                        disabled={pallet?.taskId !== countTask?.taskId}
                                    />
                                </StyledCard>
                            </div>
                        ))}
                    {/* {tabType === 1 && <TaskRejectedUsersList countTask={countTask} />} */}
                </StyledWrapper>
            </div>

            {isFeatureAccessible(userPermissions, permissions.TASK_REJECT) && (
                <TaskRejectCommentBox
                    rejectReason={rejectReason}
                    isCommentBoxOpen={isCommentBoxOpen}
                    setIsCommentBoxOpen={setIsCommentBoxOpen}
                    onRejectTaskHandler={onRejectTaskHandler}
                    handleCommentChange={handleCommentChange}
                />
            )}

            {isFeatureAccessible(userPermissions, permissions.TASK_REASSIGN) && countTask && (
                <ReAssignTask
                    rejectTaskDetails={rejectTaskDetails}
                    taskId={reAssignTaskDetails.taskId}
                    userDetails={countTask}
                    companyDetails={companyDetails}
                    skuName={countTask && countTask.sku}
                    onClickReAssignTaskDialogHandler={onClickReAssignTaskDialogHandler}
                    isReAssignTaskDialogOpen={isReAssignTaskDialogOpen}
                    handleClose={handleCloseDialog}
                    reAssignTaskDetails={reAssignTaskDetails}
                    rejectReason={rejectReason}
                    countTaskTaskId={countTask.taskId}
                    countSeriesNumber={taskList[0]?.countSeriesNumber}
                    tabType={tabType}
                />
            )}
        </Fragment>
    );
};

export default StockCountTaskPalletCards;
