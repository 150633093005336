// Read material-ui dropdown sorting functionality before understanding it
export const createSortedListForSelect = (list, baseProperty) => {
    if (list.length > 0) return list;
    const options = list.map(option => {
        const firstLetter = option[baseProperty][0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });
    return options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
};

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
    if (array && Array.isArray(array) && array.length > 0) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }
    return [];
};

export const isFeatureAccessible = (userPermissions, accessFeature) => {
    return userPermissions.includes(accessFeature);
};

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

export const getPalletTotal = (caseCount, sealedCases, partialQuantity) => {
    return caseCount * sealedCases + partialQuantity;
};

export const getMomCode = invoiceNumber => {
    const firstDigit = invoiceNumber.match(/\d/);
    const indexed = invoiceNumber.indexOf(firstDigit);
    return invoiceNumber.substring(0, indexed);
};

export const getUsdString = numberValue => {
    return numberValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};
