import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/rejectTaskDetailsApi';
import { createTaskSaga } from './createTaskSaga';

export function* getRejectTaskDetailsSaga(action) {
    try {
        const json = yield call(api.rejectTaskDetailsApi, action);
        yield put({ type: actionTypes.GET_REJECT_TASK_DETAILS_SUCCESS, payload: json.data });
        // if(action.)
        // yield put(getCompanySkusByTaskStateAction([3,4]));
    } catch (error) {
        yield put({ type: actionTypes.GET_REJECT_TASK_DETAILS_FAILURE, error: error });
    }
}

export function* rejectAndCreateTaskActionSaga(action) {
    try {
        yield call(getRejectTaskDetailsSaga, action);
        yield call(createTaskSaga, action);
    } catch (error) {
        yield put({ type: actionTypes.GET_REJECT_TASK_DETAILS_FAILURE, error: error });
    }
}

export default function* rejectTaskDetailsWatcher() {
    yield takeLatest(actionTypes.GET_REJECT_TASK_DETAILS, getRejectTaskDetailsSaga);
    yield takeLatest(actionTypes.REJECT_AND_CREATE_TASK, rejectAndCreateTaskActionSaga);
}
