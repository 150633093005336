import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React from 'react';
import { useDispatch } from 'react-redux';
import { resetSnackbar } from '../../../features/notification/notificationSlice';

export function TimeoutSnackbar({
    showSnackbar,
    snackbarSeverity,
    snackbarMessage,
    handleSnackbarCloseProp = () => {},
}) {
    const dispatch = useDispatch();

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(resetSnackbar());
        handleSnackbarCloseProp();
    };

    return (
        <Snackbar
            open={showSnackbar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose} // sx={{ height: '100%' }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity || 'success'}
                sx={{
                    width: '100%',
                }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
}
