import { Box } from '@mui/system';
import React from 'react';
import MaterialsItemForm from './MaterialsItemForm';
import MaterialsItemList from './MaterialsItemList';
import workOrderApi from '../../workOrderApi';
import { Typography } from '@mui/material';
import { Spinner } from '../../../../common/components';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';

interface WorkOrderBillOfMaterialsProps {
    isDisabled: boolean;
}

const WorkOrderBillOfMaterials = ({ isDisabled }: WorkOrderBillOfMaterialsProps) => {
    const getBillOfMaterialsOptionsResponse = workOrderApi.endpoints.getBillOfMaterialsOptions.useQuery();

    useNotificationByResponse({
        isFetching: getBillOfMaterialsOptionsResponse.isFetching,
        isError: getBillOfMaterialsOptionsResponse.isError,
        errorContent: 'Failed to get Bill of Materials',
        // isSuccess: getBillOfMaterialsOptionsResponse.isSuccess,
        // successContent: `Received to get Bill of Materials`,
    });

    return (
        <Box>
            {getBillOfMaterialsOptionsResponse?.data ? (
                <Box>
                    <Box sx={{ display: isDisabled ? 'none' : '' }}>
                        <MaterialsItemForm isDisabled={isDisabled} />
                    </Box>

                    <MaterialsItemList isDisabled={isDisabled} />
                </Box>
            ) : getBillOfMaterialsOptionsResponse.isError ? (
                <Typography>Failed to get Bill of Materials</Typography>
            ) : (
                <Spinner />
            )}
        </Box>
    );
};

export default React.memo(WorkOrderBillOfMaterials);
