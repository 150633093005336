import { Card } from '@mui/material';
import { OrderData, SubmitForm } from '../../serializedBatchShippingModels';
import ReadOnlyTextfield from './ReadOnlyTextfield';

const OrderDataDisplay = ({
    orderData,
    form,
}: {
    orderData: OrderData;

    form: SubmitForm;
}) => {
    return (
        <Card sx={{ display: 'flex', flexWrap: 'wrap', my: 2, p: 3 }}>
            <ReadOnlyTextfield label="Order" value={orderData.PackageId.PkgId} />
            {orderData.Items.map((item, index) => (
                <ReadOnlyTextfield label="SKU" value={item.Sku} key={item.Sku + index} />
            ))}
            <ReadOnlyTextfield label="Tracking" value={form.ParsedTracking} />
        </Card>
    );
};

export default OrderDataDisplay;
