import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { overrideForm } from '../../workOrderSlice';

interface LeftDatesProps {
    isDisabled: boolean;
    isErrorDisplayed: boolean;
}

const LeftDates = ({ isDisabled, isErrorDisplayed }: LeftDatesProps) => {
    const dateCreated = useAppSelector(state => state.workOrder.form.DateCreated);
    const dueDate = useAppSelector(state => state.workOrder.form.DueDate);
    const dateCompleted = useAppSelector(state => state.workOrder.form.DateCompleted);
    const isCompleted = useAppSelector(state => state.workOrder.form.Completed);
    const mode = useAppSelector(state => state.workOrder.workOrderMode);

    const dispatch = useAppDispatch();

    const [dateCompletedError, setDateCompletedError] = React.useState<DateValidationError | null>(null);
    const dateCompletedErrorMessage = React.useMemo(() => {
        switch (dateCompletedError) {
            case 'maxDate':
            case 'minDate': {
                return 'Cannot be before current billing period';
            }

            case 'invalidDate': {
                return 'Your date is not valid';
            }

            default: {
                return ' ';
            }
        }
    }, [dateCompletedError]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={isDisabled ? 'Creation Date' : 'Creation Date*'}
                value={dayjs(dateCreated)}
                onChange={newValue => {
                    dispatch(
                        overrideForm({
                            DateCreated: newValue?.format() || dayjs().format(),
                        })
                    );
                }}
                sx={{ flex: 1 }}
                key={dateCreated}
                readOnly={isDisabled}
                disabled={!isDisabled}
            />

            <DatePicker
                label={isDisabled ? 'Due Date' : 'Due Date*'}
                value={dayjs(dueDate)}
                onChange={newValue => {
                    dispatch(overrideForm({ DueDate: newValue?.format() || dayjs().format() }));
                }}
                sx={{ flex: 1, ml: 2, mr: mode === 'Create' ? 0 : 2 }}
                readOnly={isDisabled}
            />

            <DatePicker
                label={'Date Completed'}
                value={!dateCompleted ? null : dayjs(dateCompleted)}
                onChange={newValue => {
                    dispatch(
                        overrideForm({
                            DateCompleted: newValue?.isValid() ? newValue?.format() : null,
                        })
                    );
                }}
                sx={{ flex: 1, display: mode === 'Create' ? 'none' : '' }}
                minDate={isErrorDisplayed ? dayjs().startOf('month') : undefined}
                slotProps={{
                    textField: {
                        helperText: dateCompletedErrorMessage,
                    },
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
                onError={newError => {
                    if (isErrorDisplayed) setDateCompletedError(newError);
                }}
                readOnly={isDisabled}
            />
        </LocalizationProvider>
    );
};

export default React.memo(LeftDates);
