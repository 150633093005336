import { AppBar, Toolbar, IconButton, Typography, Button, Box } from '@mui/material';
import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { blue, deepPurple, green } from '@mui/material/colors';

interface DialogAppBarProps {
    handleClose: () => void;
    title: string;
    rightText?: string;
}

const DialogAppBar: FC<DialogAppBarProps> = ({ handleClose, title, rightText = '' }) => {
    return (
        <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {title}
                </Typography>
                {/* <Button autoFocus color="inherit" onClick={handleContinue}>
                    Continue
                </Button> */}
                {rightText && (
                    <Typography sx={{}} variant="h6" component="div">
                        {rightText}
                    </Typography>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default DialogAppBar;
