import { put, takeLatest } from 'redux-saga/effects';
import API from '../../features/authentication/axiosApiInstance';
import * as actionTypes from '../store/actions/actionTypes';
import { GET_COUNT_RESULT_URL } from '../../app/services/api_constants';

function* getCountResultSaga(action) {
    try {
        const response = yield API.get(GET_COUNT_RESULT_URL);
        yield put({
            type: actionTypes.GET_COUNT_RESULT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.GET_COUNT_RESULT_FAILURE,
            errorMessage: error,
        });
    }
}

export default function* countResultSagaWatcher() {
    yield takeLatest(actionTypes.GET_COUNT_RESULT, getCountResultSaga);
}
