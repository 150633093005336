import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../../archive/store/actions/actionTypes';
import API from './axiosAuthenticate';
import { AUTH_REFRESH } from '../../app/services/api_constants';
import { makeLoginRequest } from './authenticateApi';

export function* authenticateUserSaga(action) {
    try {
        yield put({ type: actionTypes.SET_AUTHENTICATE_LOADING });
        const json = yield call(makeLoginRequest, action);
        yield put({
            type: actionTypes.AUTHENTICATION_SUCCESS,
            payload: json.data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.AUTHENTICATION_FAILED,
            error: {
                statusCode: error?.response?.status,
            },
        });
    }
}

function* getNewTokenSaga(action) {
    try {
        yield put({ type: actionTypes.SET_AUTHENTICATE_LOADING });
        const userSessionFromStorage = JSON.parse(window.localStorage.getItem('userSession'));
        const res = yield API.post(AUTH_REFRESH, {
            token: userSessionFromStorage.token,
            refresh: userSessionFromStorage.refresh,
        });
        const { data } = res;
        const updateUserSessionFromStorage = {
            ...userSessionFromStorage,
            token: data.token,
            refresh: data.refresh,
        };
        localStorage.setItem('userSession', JSON.stringify(updateUserSessionFromStorage));
        API.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        yield put({
            type: actionTypes.UPDATE_TOKEN,
            payload: {
                token: data.token,
            },
        });
    } catch (error) {
        // if(error.response && (error.response.status === 401 || error.response.status === 403)){
        yield put({
            type: actionTypes.GET_NEW_TOKEN_FAILURE,
            error: {
                statusCode: error?.response?.status,
            },
        });
        // }
    }
}

export function* authenticateSagaWatcher() {
    yield takeLatest(actionTypes.AUTHENTICATE, authenticateUserSaga);
}
export function* getNewTokenSagaWatcher() {
    yield takeLatest(actionTypes.GET_NEW_TOKEN, getNewTokenSaga);
}

export default function* authenticationSagaWatcher() {
    yield all([fork(authenticateSagaWatcher), fork(getNewTokenSagaWatcher)]);
}
