import { TextField, Toolbar } from '@mui/material';
import React from 'react';
// import { lighten } from '@mui/material/styles';

// const useToolbarStyles = makeStyles(theme => ({
//     root: {
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(1),
//     },
//     highlight:
//         theme.palette.mode === 'light'
//             ? {
//                   color: theme.palette.primary.main,
//                   backgroundColor: lighten(theme.palette.primary.light, 0.85),
//               }
//             : {
//                   color: theme.palette.text.primary,
//                   backgroundColor: theme.palette.primary.dark,
//               },
//     title: {
//         flex: '1 1 100%',
//     },
// }));

const EnhancedTableToolbar = ({ handleChange }) => {
    // const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <div style={{ flex: 1, marginRight: 20 }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="Search User"
                        hiddenLabel
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        onChange={({ target: { value } }) => handleChange(value)}
                        inputProps={{
                            'data-testid': 'user-search-input',
                        }}
                    />
                </div>
                {/* <Button variant="contained" color="primary" onClick={()=>{setSelectedRole(null);setIsAddingRole(true)}}>
                  Add Role
              </Button> */}
            </div>
        </Toolbar>
    );
};

export default EnhancedTableToolbar;
