import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetDialog } from '../../../features/notification/notificationSlice';
import { red } from '@mui/material/colors';

const ColoredDialog = ({ open, title, content, color }) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(resetDialog());
    };
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle
                sx={{
                    bgcolor: theme => {
                        if (color) return color;
                        return red[50];
                    },
                    display: 'flex',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>{title}</Box>
                <IconButton
                    sx={{ outline: 'none !important', marginLeft: 5 }}
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box>{content && <DialogContent style={{ whiteSpace: 'pre-line' }}>{content}</DialogContent>}</Box>
        </Dialog>
    );
};

export default ColoredDialog;
