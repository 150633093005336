import { Autocomplete, TextField } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React, { Dispatch, FC, SetStateAction, memo } from 'react';
import trackingApi from '../trackingApi';
import { RootState, useAppSelector } from '../../../app/store';
import { Tracking } from '../trackingModels';

interface ScacAutoCompleteProps {
    value: string;
    setForm: Dispatch<SetStateAction<Tracking>>;
}

const ScacAutoComplete: FC<ScacAutoCompleteProps> = memo(({ value, setForm }) => {
    const momCode = useAppSelector((state: RootState) => state.tracking.momCode);
    const { data } = trackingApi.endpoints.getCarriers.useQuery(momCode ? momCode : skipToken);

    const getUniqueScacs = React.useMemo(() => {
        const scacs = data?.Carriers.map(carrier => carrier.Scac) || [];

        const inputWithTruthyValues = scacs.filter(scac => scac !== '');
        const inputWithoutDuplicates = inputWithTruthyValues.filter(
            (value, index, array) => array.indexOf(value) === index
        );

        return [...inputWithoutDuplicates, 'null'];
    }, [data?.Carriers]);

    return (
        <Autocomplete
            options={getUniqueScacs}
            data-testid="scac-autocomplete"
            autoSelect
            disablePortal
            onChange={(event: any, newValue: string | null) => {
                if (newValue === null) return;
                setForm(
                    (prevForm): Tracking => {
                        return {
                            ...prevForm,
                            Scac: newValue || '',
                        };
                    }
                );
            }}
            value={value}
            getOptionLabel={(scac: string) =>
                !scac || scac === 'null'
                    ? 'No option selected'
                    : `${scac} - ${data?.Carriers.find(carrier => carrier.Scac === scac)?.CarrierName}`
            }
            renderInput={params => (
                <TextField {...params} label={'SCAC'} helperText={''} variant="outlined" error={false} />
            )}
            sx={{ mt: 2, width: '100%' }}
            isOptionEqualToValue={(option: string, value: string) => option === value}
        />
    );
});

// function arePropsEqual(oldProps: CarrierAutoCompleteProps, newProps: CarrierAutoCompleteProps) {
//     return oldProps.value === newProps.value;
// }

export default ScacAutoComplete;
