import { Box, Button, Card, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, QueryDefinition } from '@reduxjs/toolkit/query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import CompanyDropdownV3 from '../../../../common/components/dropdown/CompanyDropdownV3';
import UserDropdownV2 from '../../../../common/components/dropdown/UserDropdownV2';
import DateRangePickerPopover from '../../../../common/components/popover/DateRangePickerPopover';
import { GetProceduresFilter, SimplifiedProcedureRecord } from '../../models';

const initialFilter = {
    MaxRowsCount: 1000,
} as GetProceduresFilter;

const ProceduresFilter = ({
    triggerGetProcedures,
}: {
    triggerGetProcedures: LazyQueryTrigger<
        QueryDefinition<
            GetProceduresFilter,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
            'IndividualProcedure',
            SimplifiedProcedureRecord[],
            'api'
        >
    >;
}) => {
    const [filter, setFilter] = React.useState(initialFilter);

    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamsToFilter = (searchParams: URLSearchParams): GetProceduresFilter => {
        const id = Number(searchParams.get('Id'));

        return {
            ...Object.fromEntries(searchParams),
            Id: id === 0 ? undefined : id,
        } as GetProceduresFilter;
    };

    React.useEffect(() => {
        const isSearchParamsEmpty = searchParams.size === 0;
        const filterToSearch = isSearchParamsEmpty ? initialFilter : searchParamsToFilter(searchParams);

        setFilter(filterToSearch);
        triggerGetProcedures(filterToSearch);
    }, [searchParams]);

    const resetFilter = () => {
        setFilter(initialFilter);
    };

    const handleSearchButtonClick = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSearchParams((filter as unknown) as URLSearchParams);
    };

    return (
        <Card elevation={6} sx={{ mb: 2, p: 1 }}>
            <form onSubmit={handleSearchButtonClick}>
                <Box sx={{ display: 'flex' }}>
                    <CompanyDropdownV3
                        value={filter.Client || ''}
                        textFieldProps={{ label: 'Client' }}
                        sx={{ flex: 3, m: 1 }}
                        onChange={(event, company) => {
                            setFilter(prevFilter => {
                                return { ...prevFilter, Client: company?.mom || '' };
                            });
                        }}
                    />
                    <TextField
                        value={filter.Id || 0}
                        label="Id"
                        sx={{ flex: 1, m: 1 }}
                        type="number"
                        onChange={event => {
                            const newValue = event.target.value;
                            setFilter(prevFilter => {
                                return { ...prevFilter, Id: Number(newValue) || 0 };
                            });
                        }}
                    />
                    <TextField
                        label="Title"
                        sx={{ flex: 3, m: 1 }}
                        value={filter.Title}
                        onChange={event => {
                            const newValue = event.target.value;
                            setFilter(prevFilter => {
                                return { ...prevFilter, Title: newValue };
                            });
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <UserDropdownV2
                        onChange={(event, newValue) => {
                            setFilter(prevFilter => {
                                return { ...prevFilter, CreatedBy: newValue?.code };
                            });
                        }}
                        textFieldProps={{ label: 'User' }}
                        value={filter.CreatedBy || ''}
                        sx={{ flex: 1, m: 1 }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePickerPopover
                            startDate={filter.CreatedAtStart}
                            endDate={filter.CreatedAtEnd}
                            textFieldProps={{
                                label: 'Created Date',
                                sx: { width: '100%' },
                            }}
                            sx={{ flex: 1, m: 1 }}
                            onStartDateChange={newValue => {
                                setFilter(prevFilter => {
                                    return { ...prevFilter, CreatedAtStart: newValue };
                                });
                            }}
                            onEndDateChange={newValue => {
                                setFilter(prevFilter => {
                                    return { ...prevFilter, CreatedAtEnd: newValue };
                                });
                            }}
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{ display: 'flex', mt: 1, mb: 3 }}>
                    <Button sx={{ flex: 1, mx: 2 }} variant="contained" onClick={resetFilter} color={'secondary'}>
                        Reset Filter
                    </Button>

                    <Button sx={{ flex: 5, mx: 2 }} variant="contained" type="submit">
                        Search
                    </Button>
                </Box>
            </form>
        </Card>
    );
};

export default ProceduresFilter;
