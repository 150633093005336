import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { Sscc } from '../orderLookupModels';
import DialogCloseButton from '../../../common/components/Dialog/DialogCloseButton';

// Adds id attribute to Sscc objects to work with DataGrid
interface GridSscc extends Sscc {
    id: number; // DataGrid needs id attribute for each element.
}

export const SsccDialog = React.memo(
    ({ sscc, inpartId, showDialog = false }: { sscc: Sscc[] | undefined; inpartId: string; showDialog?: boolean }) => {
        const gridSsccData: GridSscc[] = [];
        const [open, setOpen] = React.useState(false);
        const [ssccTitle, setSsccTitle] = React.useState('');
        const handleClose = () => {
            setOpen(false);
        };

        sscc?.forEach((sscc, index) => {
            const gridSscc: GridSscc = { ...sscc, id: index + 1 };
            gridSsccData.push(gridSscc);
        });
        React.useEffect(() => {
            if (showDialog) {
                setOpen(true);
                setSsccTitle(`INPART ${inpartId}`);
            }
        }, [showDialog]);

        const columns: GridColDef[] = [
            {
                align: 'center',
                headerAlign: 'center',
                field: 'BoxNumber',
                headerName: 'Box Number',
                type: 'number',
                flex: 1.5,
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'SsccNumber',
                headerName: 'SSCC Number',
                type: 'number',
                flex: 2,
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'UnitsInBox',
                headerName: 'Units in Box',
                type: 'number',
                flex: 1.5,
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'TotalBoxes',
                headerName: 'Total Boxes',
                type: 'number',
                flex: 1.2,
            },
        ];

        return (
            <Dialog open={open} onClose={handleClose} fullWidth>
                <Box sx={{ m: 3 }}>
                    <DialogTitle sx={{ m: 0, p: 2 }}>{ssccTitle}</DialogTitle>
                    <DialogCloseButton handleClose={handleClose} />
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'BoxNumber', sort: 'asc' }],
                            },
                        }}
                        rows={gridSsccData || []}
                        columns={columns}
                        disableRowSelectionOnClick
                        hideFooter
                    />
                </Box>
            </Dialog>
        );
    }
);
