import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../app/store';
import { SignIn } from '../../common/components/index';

export const LoginPage = () => {
    const { username, authenticationResponse } = useAppSelector(state => state.authentication);
    const isUserAuthenticated = username && authenticationResponse;

    return <>{isUserAuthenticated ? <Navigate to="/" /> : <SignIn />}</>;
};
