import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Button,
    Backdrop,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Box,
} from '@mui/material';

import { RedirectToTasks } from '../../../features/authentication/index';

const PREFIX = 'PalletModal';

const classes = {
    dialogActions: `${PREFIX}-dialogActions`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.dialogActions}`]: {
        justifyContent: 'space-between',
        padding: '8px 16px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PalletModal = React.memo(({ palletTag, location, showDialog = false, displayNextPallet }) => {
    const [open, setOpen] = React.useState(showDialog);
    const [redirectToTasks, setRedirectToTasks] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseAndRoute = () => {
        setOpen(false);
        setRedirectToTasks(true);
    };

    return (
        <Root>
            <Backdrop open={open}></Backdrop>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Please confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Is the pallet with tag <strong>{palletTag}</strong> at the location <strong> </strong>
                        {location}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Box>
                        <Button onClick={handleCloseAndRoute} color="primary">
                            Go to Tasks
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={handleClose} color="primary">
                            Yes
                        </Button>
                        <Button onClick={displayNextPallet} color="primary">
                            No
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {redirectToTasks && <RedirectToTasks pageData={{ taskPage: true }} />}
        </Root>
    );
});
