import { Box, Button, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/store';
import useNotificationByQueryResponse from '../../../common/hooks/useNotificationByQueryResponse';
import { setSnackbar } from '../../notification/notificationSlice';
import useScanner from '../../qualityControl/hooks/useScanner';
import rapidQcApi from '../rapidQcApi';
import { QC_BATCH_STATUS_PENDINGQC, QC_BATCH_STATUS_READYSHIP, QC_BATCH_STATUS_SHIPPED } from '../rapidQcConstants';
import { setBatchId as setBatchIdSlice, setBatchIdMomCode, setLotNumberCaptured } from '../rapidQcSlice';
import textFieldFocus from '../utils/textFieldFocus';
import { ConfirmBatchIdDialog } from './ConfirmBatchIdDialog';
import { InvalidBatchIdDialog } from './InvalidBatchIdDialog';
import { LotNumberCapturedDialog } from './LotNumberCapturedDialog';
import { LotNumberDialog } from './LotNumberDialog';
import { WrongQtyDialog } from './WrongQtyDialog';

export function BatchIdDisplay() {
    const [batchId, setBatchId] = React.useState('');
    const capturedLotNumber = useAppSelector(state => state.rapidQc.lotNumberCaptured);
    const batchIdTextfieldRef = useRef<HTMLInputElement>(null);

    const [errorMessage, setErrorMessage] = React.useState('');

    const [currentQueriedBatchId, setCurrentQueriedBatchId] = React.useState('');
    const [currentQueriedMomCode, setCurrentQueriedMomCode] = React.useState('');
    const [currentQueriedqcStatus, setCurrentQueriedqcStatus] = React.useState('');

    // Dialog setters
    const [confirmQtyDialogOpen, setConfirmQtyDialogOpen] = React.useState(false);
    const [lotNumberDialogOpen, setLotNumberDialogOpen] = React.useState(false);
    const [wrongQtyDialogOpen, setWrongQtyDialogOpen] = React.useState(false);
    const [invalidBatchIdDialogOpen, setInvalidBatchIdDialogOpen] = React.useState(false);
    const [lotNumberCapturedDialogOpen, setLotNumberCapturedDialogOpen] = React.useState(false);

    const dispatch = useDispatch();
    const [momCode, setMomCode] = React.useState('');
    const [triggerGetBatchIdResponse, getBatchIdResponse] = rapidQcApi.endpoints.getBatchId.useLazyQuery();

    useNotificationByQueryResponse({
        response: getBatchIdResponse,
    });

    React.useEffect(() => {
        if (!getBatchIdResponse.isUninitialized && !getBatchIdResponse.isLoading) {
            if (getBatchIdResponse.isSuccess) {
                handleQcStatus(getBatchIdResponse.data.Batch.Status);
                setCurrentQueriedBatchId(batchId);
                setCurrentQueriedMomCode(momCode);
                setCurrentQueriedqcStatus(getBatchIdResponse.data.Batch.Status);
            }
        }
    }, [getBatchIdResponse.isLoading, getBatchIdResponse.data]);

    React.useEffect(() => {
        if (!getBatchIdResponse.isUninitialized) {
            if (getBatchIdResponse.isError) {
                setInvalidBatchIdDialogOpen(true);
                setErrorMessage('DOES NOT APPEAR TO BE VALID');
            }
        }
    }, [getBatchIdResponse.isError]);

    const submitHandler = () => {
        if (!!batchId) {
            triggerGetBatchIdResponse({ batchId: batchId, momCode: momCode });
            dispatch(setBatchIdSlice(batchId));
            dispatch(setBatchIdMomCode(momCode));
            if (currentQueriedBatchId == batchId && currentQueriedMomCode == momCode) {
                handleQcStatus(currentQueriedqcStatus);
            }
        }
    };

    useScanner(batchId, submitHandler);

    // Close previous windows, show captured window, and show success alerts.
    React.useEffect(() => {
        if (!!capturedLotNumber) {
            setConfirmQtyDialogOpen(false);
            setLotNumberDialogOpen(false);
            dispatch(setLotNumberCaptured(''));
            if (!!batchId) {
                setLotNumberCapturedDialogOpen(true);
                dispatch(
                    setSnackbar({
                        content: `BATCH: ${batchId} CAPTURED WITH LOT NUMBER: ${capturedLotNumber}`,
                        severity: 'success',
                    })
                );
            }
        }
    }, [capturedLotNumber]);

    // Clear input and focus to batch id if batch has the wrong qty
    React.useEffect(() => {
        if (!!wrongQtyDialogOpen) {
            setConfirmQtyDialogOpen(false);
            if (!!batchId) {
                setBatchId('');
                textFieldFocus({ textFieldRef: batchIdTextfieldRef });
            }
        }
    }, [wrongQtyDialogOpen]);

    React.useEffect(() => {
        if (!!invalidBatchIdDialogOpen) {
            setConfirmQtyDialogOpen(false);
            if (!!batchId) {
                setBatchId('');
                textFieldFocus({ textFieldRef: batchIdTextfieldRef });
            }
        }
    }, [invalidBatchIdDialogOpen]);

    // Set focus to batch id text
    React.useEffect(() => {
        if (!!lotNumberCapturedDialogOpen) {
            if (!!batchId) {
                setBatchId('');
                textFieldFocus({ textFieldRef: batchIdTextfieldRef });
            }
        }
    }, [lotNumberCapturedDialogOpen]);

    const handleQcStatus = (qcStatus?: string) => {
        if (qcStatus == QC_BATCH_STATUS_PENDINGQC) {
            setConfirmQtyDialogOpen(true);
        } else if (qcStatus == QC_BATCH_STATUS_READYSHIP) {
            setErrorMessage('DOES NOT NEED QC');
            setInvalidBatchIdDialogOpen(true);
        } else if (qcStatus == QC_BATCH_STATUS_SHIPPED) {
            setErrorMessage('HAS ALREADY SHIPPED');
            setInvalidBatchIdDialogOpen(true);
        }
    };

    const parseForMomCodeInBatchId = (batchId: string) => {
        // Captures the first group of chars as non-digits, second group as other chars
        const Regex = /^([\D\d]*?)(\d+)?$/;
        const matches = batchId.match(Regex);

        if (matches) {
            const [, momCode] = matches;
            return momCode;
        } else {
            return '';
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <ConfirmBatchIdDialog
                qty={getBatchIdResponse.data?.Batch.OrderCount}
                handleOpenDialog={setConfirmQtyDialogOpen}
                showDialog={confirmQtyDialogOpen}
                handleLotNumberDialog={setLotNumberDialogOpen}
                handleWrongQtyDialog={setWrongQtyDialogOpen}
            />
            <InvalidBatchIdDialog
                message={errorMessage}
                handleOpenDialog={setInvalidBatchIdDialogOpen}
                showDialog={invalidBatchIdDialogOpen}
            />
            <LotNumberDialog handleOpenDialog={setLotNumberDialogOpen} showDialog={lotNumberDialogOpen} />
            <WrongQtyDialog handleOpenDialog={setWrongQtyDialogOpen} showDialog={wrongQtyDialogOpen} />
            <LotNumberCapturedDialog
                handleOpenDialog={setLotNumberCapturedDialogOpen}
                showDialog={lotNumberCapturedDialogOpen}
            />
            <TextField
                inputRef={batchIdTextfieldRef}
                autoFocus
                sx={{ flex: 4, ml: 1 }}
                value={batchId}
                label="SCAN BATCH ID"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBatchId(event.target.value);
                    setMomCode(parseForMomCodeInBatchId(event.target.value));
                }}
            />
            <Button sx={{ flex: 1, ml: 1 }} variant="contained">
                SUBMIT
            </Button>
        </Box>
    );
}
