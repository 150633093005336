import { Box } from '@mui/material';
import serialApi from '../../serialApi';
import { GetSerialRequest } from '../../serialModels';
import OutboundDataGrid from '../outbound/OutboundDataGrid';

const InboundTab = ({ formValues }: { formValues: GetSerialRequest }) => {
    const getSerialsResponse = serialApi.endpoints.getInboundSerials.useQuery(formValues);

    return (
        <Box>
            <OutboundDataGrid
                data={getSerialsResponse.data || []}
                fileName={`inbound-serials-${formValues.Client}-${formValues.Sku}`}
            />
        </Box>
    );
};

export default InboundTab;
