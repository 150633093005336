import { Container } from '@mui/material';
import authenticationApi from '../authentication/authenticationApi';
import CardGrid from './CardGrid';
import LoadingCards from './LoadingCards';

export const MainSection = () => {
    const getUserPermissionsResponse = authenticationApi.endpoints.getUserPermissions.useQuery();

    return (
        <Container className="container-fluid">
            {getUserPermissionsResponse.isLoading ? <LoadingCards /> : <CardGrid />}
        </Container>
    );
};
