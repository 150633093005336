import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ReceiverItemRecord } from '../../../../procedure/models';

const SkuAdjustmentTable = ({
    targetReceiverItemAdjustments,
}: {
    targetReceiverItemAdjustments: ReceiverItemRecord[];
}) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Sku</TableCell>
                    <TableCell align="right">Target Adjustment Amount</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(targetReceiverItemAdjustments || []).map(targetReceiverItemAdjustment => (
                    <TableRow key={targetReceiverItemAdjustment.Sku}>
                        <TableCell>{targetReceiverItemAdjustment.Sku}</TableCell>
                        <TableCell align="right">
                            {targetReceiverItemAdjustment.CurrentProgressAmount} /{' '}
                            {targetReceiverItemAdjustment.AdjustmentAmount}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default SkuAdjustmentTable;
