import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { formatDate } from '../../../common/utils/dateUtil';
// import AssigneeIcon from '../../../assets/images/assigneeIcon.png';
// import AssignedOnIcon from '../../../assets/images/assignedOnIcon.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreIcon from '@mui/icons-material/Store';
import { getFilteredPalletsAction } from '../../store/actions/action';
import TaskPalletDisplayTable from './TaskPalletDisplayTable';
import { REVIEW_PAGE } from '../../../common/constants/stockCountTabs';

const StyledSubCard = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
}));

const StyledSubCardIcon = styled('img')(({ theme }) => ({
    height: 25,
    width: 25,
}));

const StyledInfoHeads = styled(Typography)(({ theme }) => ({
    width: 115,
    paddingRight: 10,
    color: '#616060',
}));

//countTask: taskId, assignedToUserId, assignedToUserName, snapShotCount, sku
const TaskPalletCardsDetail = ({ pallet, countTask, tabType, disabled }) => {
    const dispatch = useDispatch();
    const [groupedPallets, setGroupedPallets] = useState([]);
    const {
        tasks: { taskListOfUser },
        pallets: { filteredPallets },
    } = useSelector(state => state);

    //when data arrives, set data for palletDisplayTable, need to update taskListOfUser
    useEffect(() => {
        if (taskListOfUser.length !== 0 && tabType === REVIEW_PAGE) {
            let companyTask = taskListOfUser.filter(task => task.id === pallet.taskId);
            let listOfCountIdsToGetCommentsOf = companyTask[0]?.typeDetail?.map(taskDetail => taskDetail.id);
            if (listOfCountIdsToGetCommentsOf) {
                dispatch(getFilteredPalletsAction(listOfCountIdsToGetCommentsOf));
            }
        }
    }, [taskListOfUser]);

    useEffect(() => {
        if (filteredPallets.length === 0) {
            setGroupedPallets([]);
        }

        if (filteredPallets.length !== 0) {
            var newGroupedPallets = [];

            var currCountTaskId = filteredPallets[0].countTaskId;
            var currGroup = [];
            filteredPallets.map((pallet, i) => {
                if (pallet.countTaskId !== currCountTaskId) {
                    //new group
                    newGroupedPallets = [...newGroupedPallets, currGroup];
                    currGroup = [pallet];
                    currCountTaskId = pallet.countTaskId;
                } else {
                    //continuing group
                    currGroup.push(pallet);
                }
            });

            newGroupedPallets = [...newGroupedPallets, currGroup];
            setGroupedPallets(newGroupedPallets);
        }
    }, [filteredPallets]);

    const getWarehouseCount = warehouseCount => {
        if (!warehouseCount) {
            return 0;
        }
        return Object.values(warehouseCount).reduce((acc, counter) => acc + counter, 0);
    };

    const getTitleValuePair = (head, data) => (
        <Grid item xs={12} lg={6}>
            <StyledSubCard>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10 }}>{/* <StyledSubCardIcon src={AssigneeIcon} alt="avatar" /> */}</div>
                    <StyledInfoHeads variant="body2" color="initial">
                        {head}
                    </StyledInfoHeads>
                    {/* <Typography variant="subtitle1" color="initial" className={classes.seperator}>
                        :
                    </Typography> */}
                </Box>
                <Typography variant="body2" color="initial" style={{ wordBreak: 'keep-all', color: '#25262a' }}>
                    {data}
                </Typography>
            </StyledSubCard>
        </Grid>
    );

    return (
        <>
            {(tabType === REVIEW_PAGE || pallet.taskState === 4) && (
                <Accordion
                    disabled={disabled}
                    elevation={0}
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{
                        margin: '10px !important',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        boxShadow: '0 0 3px rgb(233,233,233)',
                        '&::before': {
                            display: 'none',
                        },
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    }}
                >
                    <AccordionSummary style={{ paddingLeft: 0 }} expandIcon={<ExpandMoreIcon />}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 10 }}>
                                <StoreIcon
                                    alt="avatar"
                                    sx={{ height: 25, width: 25 }}
                                    style={{
                                        color: '#cbcbcb',
                                    }}
                                />
                            </div>
                            <StyledInfoHeads
                                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                                variant="body2"
                                color="initial"
                            >
                                Warehouse Count -{' '}
                                {pallet.taskState === 3 && pallet.snapShotCount === null ? (
                                    <span style={{ marginLeft: 10 }}>
                                        No pallets were available for this count task. You can discard it.
                                    </span>
                                ) : (
                                    getWarehouseCount(pallet.warehouseCount)
                                )}
                            </StyledInfoHeads>
                            {/* <Typography variant="body2" color="initial" className={classes.infoHeaders}>
                          {getWarehouseCount(pallet.warehouseCount)} / {pallet.snapShotCount}
                      </Typography> */}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: 'column', padding: 0 }}>
                        {pallet.hasOwnProperty('warehouseCount') &&
                        (tabType === REVIEW_PAGE || pallet.taskState === 4) ? (
                            Object.keys(pallet.warehouseCount).map((warehouse, i) => (
                                <Box key={warehouse} sx={{ padding: '15px 2px' }}>
                                    <Typography
                                        variant="body2"
                                        color="initial"
                                        component="span"
                                        align="justify"
                                        style={{
                                            borderTop: '1px solid #f1f1f1',
                                            color: '#25262a',
                                            whiteSpace: 'pre-line',
                                            // backgroundColor: '#F5F5FA',
                                        }}
                                    >
                                        {warehouse}: {pallet.warehouseCount[warehouse]}
                                    </Typography>
                                    <Box sx={{ width: '100%' }}>
                                        {groupedPallets[i] && (
                                            <TaskPalletDisplayTable key={warehouse} rows={groupedPallets[i]} sx={{}} />
                                        )}
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography
                                variant="body2"
                                color="initial"
                                style={{
                                    padding: '15px 33px',
                                    borderTop: '1px solid #f1f1f1',
                                    color: '#25262a',
                                }}
                            >
                                {pallet.warehouse}: {pallet.warehouseCount}
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            )}
            <Grid container style={{ maxWidth: 600, padding: '5px 10px 0' }}>
                {getTitleValuePair(
                    'Assignee',
                    tabType === REVIEW_PAGE || pallet.taskState === 4
                        ? pallet && pallet.assignee
                        : countTask && countTask.assignedToUserName
                )}
                {getTitleValuePair('Assigned On', pallet.assignedOnDateTime && formatDate(pallet.assignedOnDateTime))}
                {pallet.lastUpdatedDateTimeOffset && pallet.lastUpdatedDateTimeOffset !== '' && (
                    <Grid item xs={12} lg={6}>
                        <StyledSubCard>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: 10 }}>
                                    {/* <StyledSubCardIcon src={AssignedOnIcon} alt="avatar" /> */}
                                </div>
                                <StyledInfoHeads variant="body2" color="initial">
                                    {pallet.taskState === 4 ? 'Reviewed On' : 'Last Update'}
                                </StyledInfoHeads>
                                {/* <Typography variant="subtitle1" color="initial" className={classes.seperator}>
                                :
                            </Typography> */}
                            </Box>
                            <Typography
                                variant="body2"
                                color="initial"
                                style={{ wordBreak: 'keep-all', wordWrap: 'normal', color: '#25262a' }}
                            >
                                {pallet.lastUpdatedDateTimeOffset && formatDate(pallet.lastUpdatedDateTimeOffset)}
                            </Typography>
                        </StyledSubCard>
                    </Grid>
                )}
                <Grid item xs={12} lg={6}>
                    <StyledSubCard>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: 10 }}>
                                {/* <StyledSubCardIcon src={AssignedOnIcon} alt="avatar" /> */}
                            </div>
                            <StyledInfoHeads variant="body2" color="initial">
                                Started On
                            </StyledInfoHeads>
                            {/* <Typography variant="subtitle1" color="initial" className={classes.seperator}>
                                :
                            </Typography> */}
                        </Box>
                        <Typography
                            variant="body2"
                            color="initial"
                            style={{ wordBreak: 'keep-all', wordWrap: 'normal', color: '#25262a' }}
                        >
                            {pallet.taskStartedDateTimeOffset ? formatDate(pallet.taskStartedDateTimeOffset) : '-'}
                        </Typography>
                    </StyledSubCard>
                </Grid>
            </Grid>
        </>
    );
};

export default TaskPalletCardsDetail;
