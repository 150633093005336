import { Autocomplete, Box, Button, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useAppDispatch } from '../../../../../app/store';
import { CompanyDropdownV2 } from '../../../../../common/components/dropdown/CompanyDropdownV2';
import useNotificationByResponse from '../../../../../common/hooks/useNotificationByResponse';
import receiversApi from '../../../oldReceiversApi';
import { setMomCode } from '../../../oldReceiversSlice';
import { RECEIVER_PROJECTS_OPTIONS, RECEIVER_PROJECTS_OPTIONS_WITH_PO_NUMBER } from '../../../receiversConstants';
import { Inbound, SubmissionForm } from '../../../receiversModels';

interface FormPartOneProps {
    form: SubmissionForm;
    setForm: Dispatch<SetStateAction<SubmissionForm>>;
    handleFormContinue: () => void;
    isInEditMode: boolean;
}

const FormPartOne: React.FC<FormPartOneProps> = ({ form, setForm, handleFormContinue, isInEditMode }) => {
    const dispatch = useAppDispatch();

    const [formDisabled, setFormDiabled] = React.useState(false);
    const [PONumberDisabled, setPONumberDisabled] = React.useState(false);
    const [shouldFormShowError, setShouldFormShowError] = React.useState(false);

    const [triggerGetReceiverConfig, config] = receiversApi.endpoints.getReceiversConfigDeprecated.useLazyQuery();
    const [triggerGetReceiverInbounds, inbounds] = receiversApi.endpoints.getReceiverInboundsDeprecated.useLazyQuery();
    const [triggerGetStocks, stocks] = receiversApi.endpoints.getReceiverStocksDeprecated.useLazyQuery();

    useNotificationByResponse({
        isFetching: config.isFetching,
        isError: config.isError,
        errorContent: 'Could not get PO Number pattern',
        isUninitialized: config.isUninitialized,
    });
    useNotificationByResponse({
        isFetching: inbounds.isFetching,
        isError: inbounds.isError,
        errorContent: 'Could not get Inbounds',
        isUninitialized: inbounds.isUninitialized,
    });
    useNotificationByResponse({
        isFetching: stocks.isFetching,
        isError: stocks.isError,
        errorContent: 'Could not get Item stocks',
        isUninitialized: stocks.isUninitialized,
    });

    const getIsPoNumberValid = (): boolean => {
        // const regex = new RegExp(config?.data?.regex || '');
        // const doesMatchRegex = regex.test(form.Po || '')
        const isThereCompany = !!form.Client;
        const POMatchesCompanyRegex = true;
        const result = isThereCompany && (POMatchesCompanyRegex || PONumberDisabled);
        return result;
    };

    const handleContinueClick = (e: any) => {
        e.preventDefault();
        if (!getIsPoNumberValid() || !form.Client) {
            setShouldFormShowError(true);
        }
        if (!form.Client || !form.Po) {
            return;
        }
        setFormDiabled(true);
        triggerGetStocks(form.Client);
        handleFormContinue();
    };

    const getPoNumberHelpperText = (): string => {
        if (PONumberDisabled) {
            return 'PO Number not required';
        }

        if (!PONumberDisabled && !form.Po) {
            return 'PO Number required';
        }

        if (!getIsPoNumberValid() && !isInEditMode && shouldFormShowError) {
            return 'Incorrect PO Number pattern';
        }

        return ' ';
    };

    return (
        <Box sx={{ m: 5 }}>
            <form onSubmit={handleContinueClick}>
                <CompanyDropdownV2
                    setCompany={newValue => {
                        if (newValue) {
                            const newMomValue = newValue;
                            dispatch(setMomCode(newMomValue));
                            triggerGetReceiverConfig(newMomValue);
                            triggerGetReceiverInbounds(newMomValue);
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, Client: newMomValue ?? '', Po: '' };
                                }
                            );
                        }
                    }}
                    startingMomCode={form.Client}
                    label={'Company'}
                    autoFocus={true}
                    key="select-company"
                    disabled={formDisabled || isInEditMode}
                    error={!form.Client && shouldFormShowError}
                />

                {isInEditMode && form.Number && (
                    <TextField label={'Receiver Number'} value={form.Number} sx={{ mt: 2, width: '100%' }} disabled />
                )}

                <Autocomplete
                    disablePortal
                    autoSelect
                    options={RECEIVER_PROJECTS_OPTIONS}
                    renderInput={params => <TextField {...params} label="Project" />}
                    value={form.Project}
                    onChange={(event: any, newValue: string | null) => {
                        if (!newValue) return;
                        if (!RECEIVER_PROJECTS_OPTIONS_WITH_PO_NUMBER.includes(newValue)) {
                            setPONumberDisabled(true);
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, Po: 'N/A', Project: newValue };
                                }
                            );
                        } else {
                            setPONumberDisabled(false);
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, Po: '', Project: newValue };
                                }
                            );
                        }
                    }}
                    sx={{ mt: 2 }}
                    disabled={formDisabled || isInEditMode}
                />

                <Autocomplete
                    options={inbounds?.data?.map((inbound: Inbound) => inbound.POReferenceNumber) || []}
                    freeSolo
                    autoSelect
                    disablePortal
                    onInputChange={(event: any, newValue: string | null) => {
                        setForm(
                            (prevForm: SubmissionForm): SubmissionForm => {
                                return { ...prevForm, Po: newValue || '' };
                            }
                        );
                    }}
                    value={form.Po}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="PO Number"
                            helperText={getPoNumberHelpperText()}
                            variant="outlined"
                            error={!PONumberDisabled && !getIsPoNumberValid() && !isInEditMode && shouldFormShowError}
                        />
                    )}
                    sx={{ mt: 2, width: '100%' }}
                    disabled={PONumberDisabled}
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleContinueClick}
                    sx={{ mt: 2, width: '100%', display: formDisabled || isInEditMode ? 'none' : 'block' }}
                >
                    Continue
                </Button>
            </form>
        </Box>
    );
};

export default FormPartOne;
