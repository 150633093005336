import { Box, Button } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import rmaApi from '../rmaApi';
import { resetRmaNumber } from '../rmaSlice';
import RmaInputDialog from './RmaInputDialog';
import RmaItemsScanner from './RmaItemsScanner';
import { PostSerialRequest, TransformedGetRmaItemsResponse } from '../rmaModels';
import { setDialog, setSnackbar } from '../../notification/notificationSlice';

const RmaElement = () => {
    const dispatch = useAppDispatch();
    const rmaState = useAppSelector(state => state.oldRma);
    const getRmaItemsResponse = rmaApi.endpoints.getRmaItems.useQuery(
        rmaState.rmaNumber ? { momCode: rmaState.momCode, rmaNumber: rmaState.rmaNumber } : skipToken
    );
    const rmaHasItem = getRmaItemsResponse.data?.Items && getRmaItemsResponse.data.Items.length > 0;
    const [readyToContinue, setReadyToContinue] = useState(false);
    const [triggerPostRmaSerial, postRmaSerialResponse] = rmaApi.endpoints.postRmaSerial.useMutation();

    useNotificationByResponse({
        isFetching: getRmaItemsResponse.isFetching,
        isError: getRmaItemsResponse.isError,
        errorContent: 'Invalid RMA',
        isUninitialized: getRmaItemsResponse.isUninitialized,
    });

    useNotificationByResponse({
        isFetching: getRmaItemsResponse.isFetching,
        isError: !rmaHasItem && !getRmaItemsResponse.isError,
        errorContent: 'No Items to scan in RMA',
        isUninitialized: getRmaItemsResponse.isUninitialized,
    });

    const [currBackgroundColor, setCurrBackgroundColor] = useState('#FFFFFF');
    const isRmaAlreadyCompleted: boolean =
        (getRmaItemsResponse.data as TransformedGetRmaItemsResponse)?.Items.filter(item => !item.IsCompletedFromStart)
            .length === 0;

    const { username } = useAppSelector(state => state.authentication);

    const handleButtonClick = () => {
        if (!isRmaAlreadyCompleted) {
            triggerPostRmaSerial({ ...getRmaItemsResponse.data, Username: username } as PostSerialRequest)
                .unwrap()
                .then(payload => dispatch(setSnackbar({ content: 'Successfully submitted' })))
                .catch(error => dispatch(setDialog({ content: JSON.stringify(error) })));
        }

        setCurrBackgroundColor('#fff');
        dispatch(resetRmaNumber());
        dispatch(rmaApi.util.resetApiState());
        setReadyToContinue(false);
    };

    useEffect(() => {
        if (
            (getRmaItemsResponse.data as TransformedGetRmaItemsResponse)?.Items.filter(item => !item.IsCompleted)
                .length === 0
        ) {
            setCurrBackgroundColor('#c8e6c9');
            setReadyToContinue(true);
        } else {
            setCurrBackgroundColor('#fff');
            setReadyToContinue(false);
        }
    }, [getRmaItemsResponse.data]);

    return (
        <Box sx={{ backgroundColor: currBackgroundColor, pt: 1 }}>
            <RmaInputDialog />
            <RmaItemsScanner />

            <Button
                variant="contained"
                onClick={handleButtonClick}
                disabled={!readyToContinue}
                sx={{ width: '100%', my: 2 }}
            >
                {isRmaAlreadyCompleted ? 'Return' : 'Add Serials'}
            </Button>
        </Box>
    );
};

export default RmaElement;
