import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, Paper, Typography, TextField, Button, AppBar, Toolbar, Container, IconButton } from '@mui/material';
import { Spinner } from '../../../common/components/Spinner/Spinner';
import { SlideUpTransition } from '../../../common/components/Transition/SlideUpTransition';
import { useDispatch } from 'react-redux';
import {
    getAllRolePermissionAction,
    resetRoleSuccessStatusAction,
    getUnAssignedRolePermissionsAction,
    getAssignedRolePermissionsAction,
    editRolePermissionsAction,
    addRoleAction,
} from '../../../archive/store/actions/action';
import CloseIcon from '@mui/icons-material/Close';
import PermissionsTable from './PermissionsTable';
import { SuccessSnackbar } from '../../../common/components/Snackbar/SuccessSnackbar';
import { isFeatureAccessible } from '../../../common/utils/commonUtils';
import * as accessPermission from '../permissionsList';

const PREFIX = 'AddNewRole';

const classes = {
    roleModalWrapper: `${PREFIX}-roleModalWrapper`,
    addMoreBtn: `${PREFIX}-addMoreBtn`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.roleModalWrapper}`]: {
        padding: 20,
    },

    [`& .${classes.addMoreBtn}`]: {
        position: 'fixed',
        right: 30,
        bottom: 40,
    },
}));

const AddNewRole = ({
    isEdittingRoleName,
    isAddingRole,
    setIsAddingRole,
    setIsEdittingRoleName,
    roleName,
    setRoleName,
    isModalLoading,
    selectedRole,
    assignedPermissions,
    unAssignedPermissions,
    allPermissions,
    permissionSuccess,
    permissionSuccessMessage,
    isPermissionLoading,
    userPermissions,
}) => {
    const dispatch = useDispatch();
    const [isAssigningNewPermission, setIsAssigningNewPermission] = useState(false);

    useEffect(() => {
        if (isAddingRole) {
            dispatch(getAllRolePermissionAction());
        }
        if (isEdittingRoleName) {
            dispatch(
                getAssignedRolePermissionsAction({ roleId: selectedRole.id, assigned: true, pageNo: 1, pageSize: 100 })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingRole, isEdittingRoleName, selectedRole]);

    useEffect(() => {
        if (isAssigningNewPermission) {
            dispatch(
                getUnAssignedRolePermissionsAction({
                    roleId: selectedRole.id,
                    assigned: false,
                    pageNo: 1,
                    pageSize: 100,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRole, isAssigningNewPermission]);

    useEffect(() => {
        if (permissionSuccess) {
            setIsAssigningNewPermission(false);
        }
    }, [permissionSuccess, permissionSuccessMessage]);

    const handleCancel = () => {
        setIsAddingRole(false);
        setIsEdittingRoleName(false);
    };

    const handleSubmit = rolePermissions => {
        dispatch(resetRoleSuccessStatusAction());
        if (roleName === '' || roleName.trim() === '') {
            return;
        }
        if (selectedRole) {
            dispatch(
                editRolePermissionsAction(
                    {
                        roleId: selectedRole.id,
                        rolePermissions,
                    },
                    {
                        success: true,
                        successMessage: 'Permissions un-assigned successfully',
                    }
                )
            );
            // USED FOR UPDATING ROLE NAME AND PERMISSION AT THE SAME TIME
            // dispatch(
            //     updateRoleAction({
            //       data:{
            //         ...selectedRole,
            //         name: roleName,
            //       },
            //       updateRolePermission:{
            //         roleId:selectedRole.id,
            //         rolePermissions
            //       }
            //   })
            // );
        } else {
            dispatch(
                addRoleAction({
                    data: {
                        name: roleName,
                    },
                    rolePermissions,
                })
            );
        }
    };

    const handleAssignMoreRoles = rolePermissions => {
        dispatch(resetRoleSuccessStatusAction());
        dispatch(
            editRolePermissionsAction(
                {
                    roleId: selectedRole.id,
                    rolePermissions,
                },
                {
                    refetchAssigned: true,
                }
            )
        );
    };

    return (
        <StyledDialog
            open={isAddingRole || isEdittingRoleName}
            onClose={() => {
                setIsAddingRole(false);
                setIsEdittingRoleName(false);
            }}
            fullScreen
            className={classes.root}
            aria-labelledby="form-dialog-title"
            TransitionComponent={SlideUpTransition}
        >
            {permissionSuccess && <SuccessSnackbar show={permissionSuccess} message={permissionSuccessMessage} />}
            <AppBar position="static" color="primary">
                <Toolbar>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">{isEdittingRoleName ? 'Update Role' : 'Add Role'}</Typography>
                        <IconButton aria-label="" onClick={handleCancel} data-testid="close-add-new-role" size="large">
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <Paper className={classes.roleModalWrapper}>
                    {isModalLoading ? (
                        <Spinner style={{ height: 300 }} />
                    ) : (
                        <Fragment>
                            <div>
                                <div style={{ flex: 1, marginRight: 20, marginBottom: 20 }}>
                                    {isAddingRole ? (
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="Role Name"
                                            hiddenLabel
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            value={roleName}
                                            inputProps={{
                                                'data-testid': 'role-input',
                                            }}
                                            onChange={({ target: { value } }) => setRoleName(value)}
                                        />
                                    ) : (
                                        <Typography variant="body1" color="initial" style={{ fontWeight: 600 }}>
                                            Role : {roleName}
                                        </Typography>
                                    )}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="body1" color="initial">
                                        Permissions
                                    </Typography>
                                    {isFeatureAccessible(userPermissions, accessPermission.ROLE_PERMISSION_EDIT) &&
                                        isEdittingRoleName && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setIsAssigningNewPermission(true)}
                                            >
                                                Add Permissions
                                            </Button>
                                        )}
                                </div>
                                {isAddingRole ? (
                                    <PermissionsTable
                                        isAdding={true}
                                        list={allPermissions}
                                        handleCancel={handleCancel}
                                        handleSubmit={handleSubmit}
                                        isEdittingRoleName={isEdittingRoleName}
                                        isAssigningNewPermission={isAssigningNewPermission}
                                        userPermissions={userPermissions}
                                    />
                                ) : (
                                    !isAssigningNewPermission && (
                                        <PermissionsTable
                                            list={assignedPermissions}
                                            handleCancel={handleCancel}
                                            handleSubmit={handleSubmit}
                                            isEdittingRoleName={isEdittingRoleName}
                                            isAssigningNewPermission={isAssigningNewPermission}
                                            userPermissions={userPermissions}
                                        />
                                    )
                                )}
                            </div>
                        </Fragment>
                    )}
                </Paper>
                <Dialog
                    open={isAssigningNewPermission}
                    onClose={() => {
                        setIsAssigningNewPermission(false);
                    }}
                    fullScreen
                    className={classes.root}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={SlideUpTransition}
                >
                    <AppBar position="static" color="primary">
                        <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6">Assign Permissons</Typography>
                            <IconButton
                                className={classes.cancelButton}
                                edge="start"
                                color="inherit"
                                onClick={() => setIsAssigningNewPermission(false)}
                                aria-label="close"
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                        <Paper className={classes.roleModalWrapper}>
                            {isModalLoading ? (
                                <Spinner style={{ height: 300 }} />
                            ) : (
                                <Fragment>
                                    <div>
                                        <div style={{ flex: 1, marginRight: 20, marginBottom: 20 }}>
                                            <Typography variant="body1" color="initial">
                                                Role: {roleName}
                                            </Typography>
                                        </div>
                                        <Typography variant="body1" color="initial">
                                            Permissions
                                        </Typography>
                                        {
                                            <PermissionsTable
                                                list={unAssignedPermissions?.[0] ? unAssignedPermissions : []}
                                                handleCancel={handleCancel}
                                                handleSubmit={handleSubmit}
                                                isEdittingRoleName={isEdittingRoleName}
                                                isAssigningNewPermission={isAssigningNewPermission}
                                                handleAssignMoreRoles={handleAssignMoreRoles}
                                                isPermissionLoading={isPermissionLoading}
                                                userPermissions={userPermissions}
                                            />
                                        }
                                    </div>
                                </Fragment>
                            )}
                        </Paper>
                    </Container>
                </Dialog>
            </Container>
        </StyledDialog>
    );
};

export default AddNewRole;
