import React from 'react';
import InputDialog from '../../../common/components/Dialog/InputDialog';
import { TextField } from '@mui/material';

interface AddSerialDialogProps {
    handleSubmit: (invoiceNumber: string) => void;
    isHidden: boolean;
}

const AddSerialDialog = ({ handleSubmit, isHidden }: AddSerialDialogProps) => {
    const [invoiceNumber, setInvoiceNumber] = React.useState('');

    const handleSubmitButtonClick = () => {
        handleSubmit(invoiceNumber);
        setInvoiceNumber('');
    };

    return (
        <InputDialog
            title={'Add Serial'}
            isHidden={isHidden}
            bodyElement={
                <TextField
                    label="Invoice Number"
                    sx={{ width: '100%' }}
                    value={invoiceNumber}
                    autoFocus={true}
                    onChange={event => {
                        setInvoiceNumber(event.target.value.toUpperCase());
                    }}
                />
            }
            handleSubmit={handleSubmitButtonClick}
        />
    );
};

export default AddSerialDialog;
