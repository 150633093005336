import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from '@mui/material';
import { ResponsiveContainer, Tooltip, TooltipProps, Treemap } from 'recharts';
import { DISTINCT_COLORS } from '../../../workOrder/util';
import { GetSkuVelocityResponse } from '../../models';

interface TreeMapObjectChildren {
    name: string;
    quantity: number;
}

interface TreeMapObject {
    name: string;
    children?: TreeMapObjectChildren[];
}

const CustomizedContent = ({ root, depth, x, y, width, height, index, payload, colors, rank, name }: any) => {
    return (
        <g>
            <rect
                x={x}
                y={y}
                z={1}
                width={width}
                height={height}
                style={{
                    fill:
                        depth < 2 ? colors[Math.floor((index / root?.children?.length) * colors?.length)] : '#ffffff00',
                    stroke: '#fff',
                    strokeWidth: 2 / (depth + 1e-10),
                    strokeOpacity: 1 / (depth + 1e-10),
                }}
            />
            {depth === 1 && (
                <>
                    <text
                        textAnchor="middle"
                        x={x + width / 2}
                        y={y + height / 2 + 7}
                        z={100}
                        style={{ fill: '#fff', stroke: '#fff', strokeWidth: 1 }}
                        fontSize={20}
                        strokeLinecap="round"
                    >
                        {name}
                    </text>
                </>
            )}
        </g>
    );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<string, string>) => {
    if (active && payload && payload.length) {
        return (
            <Card sx={{ p: 1 }}>
                <Typography className="label">{`Client: ${payload[0].payload.root.name}`}</Typography>
                <Typography className="desc">{`Sku: ${payload[0].payload.name}`}</Typography>
                <Typography className="desc">{`Quantity: ${payload[0].value}`}</Typography>
            </Card>
        );
    }

    return null;
};

const TopQuantityTreeMap = ({ data }: { data: GetSkuVelocityResponse[] }) => {
    const grouped: TreeMapObject[] = data.reduce<TreeMapObject[]>((result, skuVelocity) => {
        const matchingClient = result.find(element => element.name === skuVelocity.Client);
        const newSku: TreeMapObjectChildren = { name: skuVelocity.Sku, quantity: skuVelocity.Quantity };

        if (matchingClient) {
            if (matchingClient.children) {
                matchingClient.children.push(newSku);
            } else {
                matchingClient.children = [newSku];
            }
        } else {
            result.push({ name: skuVelocity.Client, children: [newSku] });
        }

        return result;
    }, []);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Tree Map</AccordionSummary>
            <AccordionDetails sx={{ height: 1000 }}>
                <ResponsiveContainer>
                    <Treemap
                        data={grouped}
                        dataKey="quantity"
                        aspectRatio={4 / 3}
                        content={<CustomizedContent colors={DISTINCT_COLORS} />}
                        isAnimationActive={false}
                    >
                        <Tooltip content={<CustomTooltip />} />
                    </Treemap>
                </ResponsiveContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default TopQuantityTreeMap;
