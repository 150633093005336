import { Box, Button, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { Dispatch, SetStateAction } from 'react';
import { SubmissionForm } from '../../../receiversModels';
import EditHistory from './editHistory/EditHistory';
import MemoDateTimePicker from './formControlledInputs/MemoDateTimePicker';
import Items from './items/Items';
import { NumericalAndBooleanFields } from './NumericalAndBooleanFields';
import { TextFieldFields } from './TextFieldFields';
import { RECEIVER_PROJECTS_OPTIONS_WITH_REQUIRED_LADING } from '../../../receiversConstants';
import { isLadingError } from '../../../receiversUtil';

interface FormPartTwoProps {
    form: SubmissionForm;
    setForm: Dispatch<SetStateAction<SubmissionForm>>;
    handleFormSubmission: () => void;
    isInEditMode: boolean;
}

const FormPartTwo: React.FC<FormPartTwoProps> = ({ form, setForm, handleFormSubmission, isInEditMode }) => {
    const [shouldFormShowError, setShouldFormShowError] = React.useState(false);
    const isFormError = isLadingError(form.Lading, form.Project) || form.Items.length <= 0;

    const handleFormPartTwoSubmission = (e: any) => {
        e.preventDefault();
        if (isFormError) {
            setShouldFormShowError(true);
        }
        handleFormSubmission();
    };

    return (
        <Box sx={{ m: 5 }}>
            <form onSubmit={handleFormPartTwoSubmission}>
                <MemoDateTimePicker
                    label={'Received Date'}
                    value={dayjs(form.RecDate)}
                    disabled={isInEditMode}
                    onChange={(newValue: Dayjs) => {
                        setForm(
                            (prevForm: SubmissionForm): SubmissionForm => {
                                return {
                                    ...prevForm,
                                    RecDate: newValue.format(),
                                };
                            }
                        );
                    }}
                />

                <TextFieldFields
                    isInEditMode={isInEditMode}
                    form={form}
                    setForm={setForm}
                    shouldFormShowError={shouldFormShowError}
                />

                <NumericalAndBooleanFields form={form} setForm={setForm} />

                <Items form={form} setForm={setForm} shouldFormShowError={shouldFormShowError} />

                <TextField
                    value={form.Comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        setForm(
                            (prevForm: SubmissionForm): SubmissionForm => {
                                return { ...prevForm, Comment: newValue };
                            }
                        );
                    }}
                    label="Notes"
                    variant="outlined"
                    multiline
                    rows={3}
                    sx={{ mt: 2, width: '100%' }}
                />

                {isInEditMode && <EditHistory />}

                <Button
                    data-testid="final-submit-button"
                    variant="contained"
                    color="primary"
                    onClick={handleFormPartTwoSubmission}
                    sx={{ mt: 2, width: '100%' }}
                    type="submit"
                >
                    {isInEditMode ? 'Update' : 'Create'}
                </Button>
            </form>
        </Box>
    );
};

export default FormPartTwo;
