import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { History, HistoryChangesRow } from '../../../../receiversModels';

interface EditHistoryCardProps {
    rows: HistoryChangesRow[];
}

const EditHistoryCardDialogBody: FC<EditHistoryCardProps> = ({ rows }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">Old</TableCell>
                        <TableCell align="right">New</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.item} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {row.item}
                            </TableCell>

                            <TableCell align="right">{prettifyString(row.old)}</TableCell>
                            <TableCell align="right">{prettifyString(row.new)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const prettifyString = (input: string) => {
    return JSON.stringify(input)
        .replaceAll('"', '')
        .replaceAll('[', '')
        .replaceAll(']', '')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll(',', '\t');
};

export default EditHistoryCardDialogBody;
