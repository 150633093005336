export const startAction = 'START';
export const completeAction = 'COMPLETE';
export const abortAction = 'ABORT';
export const beingQcAction = 'Begin QC';
export const orderScanCompletedAction = 'Order Scan Completed';
export const inProgressAction = 'INPROGRESS';

export const omittedActions = new Set<string>([startAction, completeAction]);
export const startingActions = new Set<string>([beingQcAction]);
export const endingActions = new Set<string>([abortAction, orderScanCompletedAction]);
export const successfulActions = new Set<string>([orderScanCompletedAction, completeAction]);
export const failedActions = new Set<string>([abortAction]);
export const inProgressActions = new Set<string>([inProgressAction]);
