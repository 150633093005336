import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import moment from 'moment';

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
    array.reduce((acc, value, index, array) => {
        (acc[predicate(value, index, array)] || (acc[predicate(value, index, array)] = [])).push(value);
        return acc;
    }, {} as { [key: string]: T[] });

export const extendNumberToTwoDecimalPlaces = (number: number) => {
    return (Math.round(number * 100) / 100).toFixed(2);
};

export const formatTypedDate = (date: dayjs.Dayjs): string | null => {
    const fixedDate = date.toString();
    const newDate = moment(fixedDate).format('MM/DD/YYYY h:mm.ss a');
    if (newDate !== 'Invalid date') return newDate;
    return null;
};

//takes in generic modal and returns object where everything is optional but at least one property is provided
export type AtLeastOne<T extends Record<string, any>> = keyof T extends infer K
    ? K extends string
        ? Pick<T, K & keyof T> & Partial<T>
        : never
    : never;

//returns string representation of an interface's property
export function propertyOf<TObj>(name: keyof TObj) {
    return name;
}

export const stringToBoolean = (input: string | undefined | null): boolean => {
    if (!input) return false;

    return input.toLowerCase().trim() === 'true';
};

//given a uri, will make browser download it
export const downloadURI = (uri: string, name: string) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

//convert file to base 64
export const toBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    });
};

//get content portion of a base64 encoded string
export const getContent = (base64: string) => {
    return base64?.split(',')[1];
};

//get header portion of a base64 encoded string
export const getEncodingHeaeder = (base64: string) => {
    return base64?.split(',')[0] + ',';
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
    return (
        typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string'
    );
}

export const parseErrorMessage = (error: FetchBaseQueryError) => {
    if ('error' in error) {
        return error.error;
    }

    if (error?.data) {
        const data = error.data as any;
        if ('Message' in data) {
            return data?.Message;
        }
    }

    return JSON.stringify(error.data);
};

const GROUP_SEPARATOR_CHARACTER_CODE = 29;
const ERROR_ON_TRACKING_LENGTH = 50;
const ON_TRAC_TRACKING_LENGTH = 15;
const UPS_TRACKING_LENGTH = 18;

export const parseTrackingNumberV2 = (tracking: string): string | null => {
    const isInvalid = !tracking || tracking.length > ERROR_ON_TRACKING_LENGTH;
    if (isInvalid) {
        return null;
    }

    const fixedTracking = tracking.trim().toUpperCase();

    const isOnTrac =
        fixedTracking.length === ON_TRAC_TRACKING_LENGTH &&
        (fixedTracking.startsWith('D') || fixedTracking.startsWith('C'));

    const isUps = fixedTracking.length === UPS_TRACKING_LENGTH && fixedTracking.startsWith('1Z');

    if (isOnTrac || isUps) {
        return fixedTracking;
    }

    const groupSepareterIndex = fixedTracking.charCodeAt(GROUP_SEPARATOR_CHARACTER_CODE);
    const isDhl = !!groupSepareterIndex;

    if (isDhl) {
        return fixedTracking.substring(groupSepareterIndex);
    }

    return null;
};

export const getOrderNumber = (fullOrderId: string): string => {
    return fullOrderId.replace(/[^0-9]/g, '');
};

export const getClient = (fullOrderId: string): string => {
    return fullOrderId.match(/[^0-9]+/g)?.[0] || '';
};

export const getInpart = (fullOrderId: string): string => {
    return fullOrderId.match(/[^0-9]+/g)?.[1] || 'A';
};
