import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetReceiversRequest } from './receiversModels';

export interface ReceiversState {
    momCode: string;
    getReceiversRequest: GetReceiversRequest;
    receiverNumber: number;
}

const initialState: ReceiversState = {
    momCode: '',
    getReceiversRequest: {} as GetReceiversRequest,
    receiverNumber: 0,
};

const slice = createSlice({
    name: 'oldReceivers',
    initialState,
    reducers: {
        setMomCode: (state, action: PayloadAction<string>) => {
            state.momCode = action.payload;
        },
        setGetReceiversRequest: (state, action: PayloadAction<GetReceiversRequest>) => {
            state.getReceiversRequest = action.payload;
        },
        setReceiverNumber: (state, action: PayloadAction<number>) => {
            state.receiverNumber = action.payload;
        },
    },
});

export const { setMomCode, setGetReceiversRequest, setReceiverNumber } = slice.actions;

export default slice.reducer;

// export const selectGlobalPollingEnabled = (state: RootState) => state.polling.enabled;
// export const selectPollingConfigByApp = (state: RootState, app: PollingAppKey) => state.polling.apps[app];
