import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { customTheme } from '../../../common/components/Theme';
import { isFeatureAccessible } from '../../../common/utils/commonUtils';
import * as permissions from '../../../features/roles/permissionsList';
import { DISCARDED_STATE } from '../../../common/constants/taskStates';
import { REVIEW_PAGE } from '../../../common/constants/stockCountTabs';
import { isInCountingState } from '../../taskUtil';

const StyledReviewPalletCardHeaderBtnWpr = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: 15,
    },
}));

const StyledReviewButton = styled(Button)(({ theme }) => ({
    color: 'white',
    // backgroundColor: customTheme.buttonGreen,
    fontSize: '0.7rem',
    paddingTop: 4,
    paddingBottom: 4,
    [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-end',
    },
}));

const TaskPalletActionBox = ({
    pallet,
    tabType,
    onReAssignTaskHandler,
    onApproveTaskHandler,
    setIsCommentBoxOpen,
    discardTaskHandler,
    rejectedTaskList,
    userPermissions,
}) => {
    if (tabType === REVIEW_PAGE) {
        if (pallet.taskState === 3) {
            if (pallet.snapShotCount != null) {
                return (
                    <StyledReviewPalletCardHeaderBtnWpr style={{ marginLeft: 10 }}>
                        {isFeatureAccessible(userPermissions, permissions.TASK_APPROVE) && (
                            <StyledReviewButton
                                style={{ marginRight: 7, backgroundColor: customTheme.buttonGreen }}
                                onClick={onApproveTaskHandler}
                            >
                                Approve
                            </StyledReviewButton>
                        )}
                        {isFeatureAccessible(userPermissions, permissions.TASK_REJECT) && (
                            <StyledReviewButton
                                style={{ backgroundColor: customTheme.buttonRed }}
                                onClick={() => setIsCommentBoxOpen(true)}
                            >
                                Reject
                            </StyledReviewButton>
                        )}
                    </StyledReviewPalletCardHeaderBtnWpr>
                );
            } else {
                if (pallet.taskState !== DISCARDED_STATE) {
                    return (
                        <StyledReviewPalletCardHeaderBtnWpr style={{ marginLeft: 10 }}>
                            {isFeatureAccessible(userPermissions, permissions.TASK_DISCARD) && (
                                <StyledReviewButton
                                    style={{ marginRight: 7, backgroundColor: customTheme.buttonGreen }}
                                    onClick={discardTaskHandler}
                                >
                                    Discard
                                </StyledReviewButton>
                            )}
                        </StyledReviewPalletCardHeaderBtnWpr>
                    );
                }
                return null;
            }
        } else {
            if (
                rejectedTaskList.find(
                    rejectTsk =>
                        rejectTsk.countseriesNumber === pallet.countseriesNumber &&
                        rejectTsk.snapShotCount === pallet.snapShotCount
                ) &&
                isInCountingState(pallet.taskState) &&
                isFeatureAccessible(userPermissions, permissions.TASK_REASSIGN)
            ) {
                return (
                    <StyledReviewButton
                        style={{ marginRight: 7, backgroundColor: '#3f51b5' }}
                        onClick={onReAssignTaskHandler}
                        data-testid="re-assign-btn"
                    >
                        Re-Assign
                    </StyledReviewButton>
                );
            } else {
                return null;
            }
        }
    }

    // if (tabType === 1) {
    //     return (
    //         <Button
    //             className={classes.reviewButton}
    //             style={{ marginRight: 7, backgroundColor: customTheme.buttonGreen }}
    //             onClick={onReAssignTaskHandler}
    //         >
    //             Re-Assign
    //         </Button>
    //     );
    // }
    return <div />;
};

export default TaskPalletActionBox;
