import { useAppSelector } from '../../app/store';
import Page from '../../common/components/container/Page';
import InvoiceNumberDialog from './components/InvoiceNumberDialog';
import QualityControlBox from './components/QualityControlBox';

const QualityControl = () => {
    const orderData = useAppSelector(state => state.qualityControl.orderData);

    return (
        <Page title={'Quality Control'} childrenElement={orderData ? <QualityControlBox /> : <InvoiceNumberDialog />} />
    );
};

export default QualityControl;
