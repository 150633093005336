import { Box, Grid, TextField, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../app/store';
import orderLookupApi from '../orderLookupApi';
import React from 'react';

export function NotesDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const ordMemo = getOrderDetailsResponse.data?.Data[0]?.OrdMemo;

    return (
        <React.Fragment>
            {!!ordMemo ? (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            value={ordMemo?.Notes || '-'}
                            InputProps={{ readOnly: true, disableUnderline: true }}
                            variant="filled"
                            multiline
                            rows={ordMemo?.Notes.split(/\r\n|\r|\n/).length}
                            label="Order"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={ordMemo?.Fullfill || '-'}
                            InputProps={{ readOnly: true, disableUnderline: true }}
                            variant="filled"
                            multiline
                            rows={ordMemo?.Fullfill.split(/\r\n|\r|\n/).length}
                            label="Fullfill"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            ) : (
                <Box>
                    <Typography>NO NOTES</Typography>
                </Box>
            )}
        </React.Fragment>
    );
}
