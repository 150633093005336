import { Box, Button, TextField } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import {
    decrementIsNonIntrusiveSpinnerOpen,
    incrementIsNonIntrusiveSpinnerOpen,
    setDialog,
    setSnackbar,
} from '../../notification/notificationSlice';
import useScanner from '../hooks/useScanner';
import qualityControlApi from '../qualityControlApi';
import {
    AllScans,
    CartonQtyWithUPC,
    NonUPCScan,
    OrderData,
    QCSerialCheckResult,
    SerialCheckPayload,
    SerialCheckRollbackState,
    SerialVerify,
    TransformedItem,
    TransformedItemSerialVerify,
} from '../qualityControlModels';
import { appendQCLog, setOrderData, setSerial1, setSerial2, setTransformedItems } from '../qualityControlSlice';
import { parseInitialQCScan } from '../util/handleInitialScan';
import { QualityControlDrawer } from './QualityControlDrawer';

const QualityControlScanner = () => {
    const orderData = useAppSelector(state => state.qualityControl.orderData) as OrderData;
    const transformedItems = useAppSelector(state => state.qualityControl.transformedItems) as TransformedItem[];

    const [triggerGetSerialCheck, getSerialCheckResponse] = qualityControlApi.endpoints.getSerialCheck.useLazyQuery();
    const [triggerGetSerialCheck2, getSerialCheckResponse2] = qualityControlApi.endpoints.getSerialCheck.useLazyQuery();

    const dispatch = useAppDispatch();

    const [initialScan, setInitialScan] = useState('');
    const [serialInput, setSerialInput] = useState('');
    const [serialDrawerOpen, setSerialDrawerOpen] = useState(false);
    const [allScans, setAllScans] = useState<AllScans>();

    const [serialCheckRollbackMap, setSerialCheckRollbackMap] = useState<Map<string, SerialCheckRollbackState>>(
        new Map()
    );
    const [allSerials, setAllSerials] = useState<string[]>([]);
    const [currItemSerialList, setCurrItemSerialList] = useState<string[]>([]);

    const upcInputRef = useRef(null);
    const serialTimeout = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        return () => {
            if (serialTimeout?.current) clearInterval(serialTimeout.current);
        };
    }, []);

    const handleUPCSubmitButtonClicked = () => {
        if (initialScan === '') {
            dispatch(
                setDialog({
                    content: `Empty submission`,
                    title: 'Valid UPC required',
                })
            );
            return;
        }

        let parsedData = parseInitialQCScan(orderData.PackageId.Mom, initialScan.trim());
        if (!parsedData.upc && parsedData.sku)
            parsedData.item = orderData.Items.find(item => item.Sku === parsedData.sku);
        else parsedData.item = orderData.Items.find(item => item.Upc === parsedData.upc);

        if (!parsedData.item && parsedData.upc) {
            const altUpcScanned = altUpcMap.get(parsedData.upc);
            if (altUpcScanned) {
                handleAltUpcScan(altUpcScanned);
            } else {
                dispatch(
                    setDialog({
                        content: `No matching item on order found for scan: ${JSON.stringify(parsedData)}`,
                    })
                );
                dispatch(
                    appendQCLog({
                        scanQty: 0,
                        upc: JSON.stringify(parsedData),
                        serial: '',
                        error: true,
                        action: `Item not on order`,
                    })
                );
                setInitialScan('');
            }
        } else if (parsedData.item) {
            setAllScans(parsedData as AllScans);
        } else if (!parsedData.item)
            dispatch(
                setDialog({
                    content: `No matching item for input`,
                })
            );
    };

    useScanner(initialScan, handleUPCSubmitButtonClicked);

    useEffect(() => {
        if (allScans?.item) {
            //after all scans just set
            const isFirstSerialGood = allScans.serial && isSerialGood(allScans.serial);
            const isSecondSerialGood = allScans.serial2 && isSerialGood(allScans.serial2, true);
            let newCurrItemSerialList = [...currItemSerialList];

            //mark down curr serials from input
            if (isFirstSerialGood) {
                newCurrItemSerialList[newCurrItemSerialList.length] = allScans.serial || '';
                const serial1Payload: SerialCheckPayload = {
                    momCode: orderData.PackageId.Mom,
                    sku: allScans?.item.Sku || '',
                    serialNumber: allScans.serial || '',
                };
                triggerGetSerialCheck(serial1Payload);
                dispatch(setSerial1(serial1Payload));
            }
            if (isSecondSerialGood) {
                newCurrItemSerialList[newCurrItemSerialList.length] = allScans.serial2 || '';

                const serial2Payload: SerialCheckPayload = {
                    momCode: orderData.PackageId.Mom,
                    sku: allScans?.item.Sku || '',
                    serialNumber: allScans.serial2 || '',
                };

                triggerGetSerialCheck2(serial2Payload);
                dispatch(setSerial2(serial2Payload));
            }

            //goto either ask for more serials or mark as complete
            setCurrItemSerialList(newCurrItemSerialList);
            const isThereMoreSerialsToScan =
                allScans?.item && newCurrItemSerialList.length < allScans.item?.SerialVerify.length;
            if (isThereMoreSerialsToScan) {
                promptForSerialScan();
            } else {
                dispatch(
                    setSnackbar({
                        content: `Scanned ${allScans?.item?.Upc} with ${allScans.serial} successfully!`,
                        severity: 'success',
                    })
                );
                markAsSuccessfulItemScan(newCurrItemSerialList);
            }
        }
    }, [allScans]);

    const altUpcMap = useMemo(() => {
        const result = new Map<string, CartonQtyWithUPC>();
        if (!orderData) return result;

        orderData.Items.forEach(item => {
            item.CartonQty.forEach(carton => {
                result.set(carton.AltUpc.toUpperCase(), {
                    carton,
                    upc: item.Upc,
                });
            });
        });

        return result;
    }, [orderData]);

    const handleAltUpcScan = (altUpcScanned: CartonQtyWithUPC) => {
        const upcOfAltUpcScanned = altUpcScanned.upc;
        const cartonQty = altUpcScanned.carton.Qty;
        const updatedItemToModify = orderData.Items.find(item => item.Upc === upcOfAltUpcScanned);

        if (!updatedItemToModify) {
            dispatch(
                setDialog({
                    content: `upc: ${upcOfAltUpcScanned} \n carton quantity size: ${cartonQty} \n description: ${altUpcScanned.carton.Desc}`,
                    title: `Unable to do carton quantity scan for ${altUpcScanned.upc} `,
                })
            );
            dispatch(
                appendQCLog({
                    scanQty: 0,
                    upc: altUpcScanned.upc || '',
                    serial: '',
                    error: true,
                    action: `Invalid carton quantity scan`,
                })
            );
            setInitialScan('');
        } else if (updatedItemToModify.Quants - cartonQty < 0) {
            dispatch(
                setDialog({
                    content: `upc: ${upcOfAltUpcScanned} \n carton quantity size: ${cartonQty} \n description: ${altUpcScanned.carton.Desc}`,
                    title: `Too many units scanned for ${altUpcScanned.upc} `,
                })
            );
            dispatch(
                appendQCLog({
                    scanQty: 0,
                    upc: altUpcScanned.upc || '',
                    serial: '',
                    error: true,
                    action: `Too many scanned`,
                })
            );
            setInitialScan('');
        } else {
            dispatch(
                setSnackbar({
                    content: `Scanned ${altUpcScanned.upc} successfully with quantity of ${cartonQty}!`,
                    severity: 'success',
                })
            );
            dispatch(
                appendQCLog({
                    scanQty: cartonQty,
                    upc: altUpcScanned.upc || '',
                    serial: '',
                    error: false,
                    action: `Carton Quantity Scan`,
                })
            );
            updateDataDueToSuccessfulScan(altUpcScanned.upc, cartonQty);
            setInitialScan('');
        }
    };

    const markAsSuccessfulItemScan = (inputCurrItemSerialList: string[] = currItemSerialList) => {
        if (allScans?.item?.Quants === 0) {
            dispatch(setDialog({ content: `Too many units scanned for ${allScans?.item?.Upc}` }));
            dispatch(
                appendQCLog({
                    scanQty: 0,
                    upc: allScans?.item?.Upc || '',
                    serial: '',
                    error: true,
                    action: `Too many scanned`,
                })
            );
            setInitialScan('');
        } else {
            dispatch(
                setSnackbar({
                    content: `Scanned ${allScans?.item?.Upc} successfully!`,
                    severity: 'success',
                })
            );
            if (!allScans?.serial2 && !allScans?.serial) {
                dispatch(
                    appendQCLog({
                        scanQty: 1,
                        upc: allScans?.item?.Upc || '',
                        serial: '',
                        error: false,
                        action: 'UPC Scan',
                    })
                );
            }

            recordFinalSerialScanInSet(inputCurrItemSerialList);
            updateDataDueToSuccessfulScan();
            setInitialScan('');
        }
    };

    const promptForSerialScan = () => {
        const isSerializedCorrectly =
            allScans?.item?.SerialVerify.length !== 0 &&
            (allScans?.item?.SerialVerify.length === allScans?.item?.PerUnit ||
                (allScans?.item?.PerUnit === 0 && allScans?.item?.SerialVerify.length === 1));
        if (!isSerializedCorrectly) {
            dispatch(setDialog({ content: `${allScans?.item?.Upc} was not serialized correctly` }));
            setInitialScan('');
        } else {
            dispatch(
                setSnackbar({
                    content: `Do additional scan for ${allScans?.item?.Upc}`,
                    severity: 'warning',
                })
            );

            setSerialInput('');
            if (serialDrawerOpen) {
                setSerialDrawerOpen(false);
                serialTimeout.current = setTimeout(() => {
                    setSerialDrawerOpen(true);
                }, 500);
            } else {
                setSerialDrawerOpen(true);
            }
        }
    };

    const handleSerialSubmitButtonClicked = () => {
        let parsedData = parseInitialQCScan(orderData.PackageId.Mom, serialInput.trim(), false);
        setAllScans(prevScan => {
            return { ...prevScan, ...parsedData } as AllScans; //item still exists, so should pass after handleInitialScan to serial check immediately
        });
    };

    const isSerialGood = (serialToProcess: string, isSecondSerial: boolean = false): boolean => {
        if (!serialToProcess) {
            dispatch(setDialog({ content: `No value was scanned` }));
            return false;
        }

        const isSerialScannedInCurrentQC =
            allSerials.includes(serialToProcess) ||
            allScans?.item?.Serial.PausedSerials?.map(pausedSerial => pausedSerial.Value).includes(serialToProcess) ||
            currItemSerialList.includes(serialToProcess);
        const currSerialVerify =
            allScans?.item &&
            allScans.item?.SerialVerify.find(
                serialVerify =>
                    serialVerify.ScanOrder === currItemSerialList.length ||
                    serialVerify.ScanOrder === currItemSerialList.length + 1
            );
        if (!currSerialVerify) {
            dispatch(setDialog({ content: `${serialToProcess} item does not have serial verify` }));
            return false;
        }

        const serialRegex = new RegExp(currSerialVerify.Regex);
        const isSerialMatchingRegex = serialRegex.test(serialToProcess);
        if (isSerialScannedInCurrentQC) {
            dispatch(setDialog({ content: `${serialToProcess} was scanned in current QC` }));
            dispatch(
                appendQCLog({
                    scanQty: 0,
                    upc: allScans?.item?.Upc || '',
                    serial: serialToProcess,
                    error: true,
                    action: `Serial already used in current QC`,
                })
            );
            setSerialInput('');
            return false;
        } else if (!isSerialMatchingRegex) {
            dispatch(setDialog({ content: `${serialToProcess} is invalid` }));
            dispatch(
                appendQCLog({
                    scanQty: 0,
                    upc: allScans?.item?.Upc || '',
                    serial: serialToProcess,
                    error: true,
                    action: `Serial does not match regex`,
                })
            );
            setSerialInput('');
            return false;
        } else {
            //no problems, so store valid state to be used in case of rollback
            setSerialCheckRollbackMap(
                prevMap =>
                    new Map(
                        prevMap.set(serialToProcess, {
                            orderData: orderData,
                            allSerials: allSerials,
                            currSerialVerifyIndex: isSecondSerial
                                ? currItemSerialList.length + 1
                                : currItemSerialList.length,
                            currItemSerialList: currItemSerialList,
                            currItemId: allScans.item?.ItemId,
                        } as SerialCheckRollbackState)
                    )
            );
            return true;
        }
    };

    //two use effects required since can't catch both at same time when made in parallel
    useEffect(() => {
        //show nonintrusive spinner if is fetching
        if (getSerialCheckResponse.isUninitialized) return;
        if (getSerialCheckResponse.status === 'pending') dispatch(incrementIsNonIntrusiveSpinnerOpen());
        else dispatch(decrementIsNonIntrusiveSpinnerOpen());

        if (getSerialCheckResponse.status !== 'fulfilled' || !getSerialCheckResponse.isSuccess) return; //throw error and reset
        handleSerialCheckResponse(getSerialCheckResponse);
    }, [getSerialCheckResponse.isFetching]);

    useEffect(() => {
        if (getSerialCheckResponse2.isUninitialized) return;
        if (getSerialCheckResponse2.status === 'pending') dispatch(incrementIsNonIntrusiveSpinnerOpen());
        else dispatch(decrementIsNonIntrusiveSpinnerOpen());

        if (getSerialCheckResponse2.status !== 'fulfilled' || !getSerialCheckResponse2.isSuccess) return;
        handleSerialCheckResponse(getSerialCheckResponse2);
    }, [getSerialCheckResponse2.isFetching]);

    const handleSerialCheckResponse = (response: any) => {
        if (!response.isSuccess) return;

        const serialCheckResponseData: QCSerialCheckResult = response?.data;
        const isPrevScannedSerial =
            serialCheckResponseData.SerialUseRecords.length > 0 && !allScans?.item?.DuplicateSerial;
        const serialToProcess = serialCheckResponseData.SerialNumber;
        if (response?.data?.IsQuarantined) {
            dispatch(setDialog({ content: `${serialToProcess} is under quarantine` }));
            dispatch(
                appendQCLog({
                    scanQty: 0,
                    upc: allScans?.item?.Upc || '',
                    serial: serialToProcess,
                    error: true,
                    action: `Serial under quarantine`,
                })
            );
            setSerialInput('');

            rollbackToSerialCheckRollbackState(serialToProcess);
        } else if (isPrevScannedSerial) {
            dispatch(setDialog({ content: `${serialToProcess} was scanned in previous QC` }));
            dispatch(
                appendQCLog({
                    scanQty: 0,
                    upc: allScans?.item?.Upc || '',
                    serial: serialToProcess,
                    error: true,
                    action: `Serial already used in previous QC`,
                })
            );
            setSerialInput('');

            rollbackToSerialCheckRollbackState(serialToProcess);
        } else {
            let currRollbackState = serialCheckRollbackMap.get(serialToProcess);
            let newTransformedItems: TransformedItem[] = JSON.parse(JSON.stringify(transformedItems));
            if (currRollbackState?.currItemId) {
                const indexOfItemToModify = newTransformedItems
                    .map(item => (item.IsSerialCompleted ? -1 : item.ItemId))
                    .indexOf(currRollbackState.currItemId);
                if (indexOfItemToModify !== -1) {
                    const indexOfSerialVerifyToModify = newTransformedItems[indexOfItemToModify].SerialVerify.map(
                        serialVerify => serialVerify.ScanOrder
                    ).indexOf(currRollbackState?.currSerialVerifyIndex);
                    if (indexOfSerialVerifyToModify !== -1) {
                        newTransformedItems[indexOfItemToModify].SerialVerify[indexOfSerialVerifyToModify] = {
                            ...newTransformedItems[indexOfItemToModify].SerialVerify[indexOfSerialVerifyToModify],
                            ScanValue: serialToProcess,
                        };
                        const isEmptySerialVerifyValueFound = newTransformedItems[
                            indexOfItemToModify
                        ].SerialVerify.find(currSerialVerify => currSerialVerify.ScanValue === '');
                        if (isEmptySerialVerifyValueFound) {
                            newTransformedItems[indexOfItemToModify].IsSerialCompleted = false;
                            dispatch(
                                appendQCLog({
                                    scanQty: 0,
                                    upc: allScans?.item?.Upc || '',
                                    serial: serialToProcess,
                                    error: false,
                                    action: `Serial Scan`,
                                })
                            );
                        } else {
                            newTransformedItems[indexOfItemToModify].IsSerialCompleted = true;
                            dispatch(
                                appendQCLog({
                                    scanQty: 1,
                                    upc: newTransformedItems[indexOfItemToModify].Upc || '',
                                    serial: serialToProcess,
                                    error: false,
                                    action: 'UPC/Serial Scan',
                                })
                            );
                        }
                        dispatch(setTransformedItems(newTransformedItems));
                    }
                }
            }
        }
    };

    const rollbackToSerialCheckRollbackState = (serialToProcess: string) => {
        const stateToRollbackTo = serialCheckRollbackMap.get(serialToProcess);
        if (stateToRollbackTo) {
            dispatch(setOrderData(stateToRollbackTo.orderData));
            setAllSerials(stateToRollbackTo.allSerials);
            setCurrItemSerialList(stateToRollbackTo.currItemSerialList);
        }
    };

    const recordFinalSerialScanInSet = (inputCurrItemSerialList: string[] = currItemSerialList) => {
        //currItemSerialList must be added to NonUPCScans with index
        const convertedFinalItemSerialListToSubmitFormat = inputCurrItemSerialList.map(
            (serial, index) =>
                ({
                    Key: index + 1,
                    Value: serial,
                } as NonUPCScan)
        );

        if (!allScans?.item) return;
        const newNonUpcScans = allScans.item.NonUPCScans
            ? [...allScans.item.NonUPCScans, ...convertedFinalItemSerialListToSubmitFormat]
            : convertedFinalItemSerialListToSubmitFormat;
        const newOrderDataItems = orderData.Items.map(item =>
            item.Upc === allScans?.item?.Upc ? { ...item, NonUPCScans: newNonUpcScans } : item
        );
        dispatch(setOrderData({ ...orderData, Items: newOrderDataItems }));

        setAllSerials(prevState => [...prevState, ...inputCurrItemSerialList]);

        updateDataDueToSuccessfulScan();
        resetSerialDrawer();
        setTimeout(() => {
            if (upcInputRef && upcInputRef.current) {
                ((((upcInputRef.current as unknown) as HTMLElement)?.firstChild as HTMLElement)
                    ?.firstChild as HTMLElement)?.focus();
            }
        }, 100);
    };

    const resetSerialDrawer = () => {
        setSerialDrawerOpen(false);
        setSerialInput('');
        setInitialScan('');
        setCurrItemSerialList([]);
    };

    const updateDataDueToSuccessfulScan = (inputUpcCode = allScans?.item?.Upc, numScanned = 1) => {
        const newOrderDataItems = orderData.Items.map(item =>
            item.Upc === inputUpcCode
                ? { ...item, Quantp: item.Quantp + numScanned, Quants: item.Quants - numScanned }
                : item
        );
        const newOrderData = { ...orderData, Items: newOrderDataItems };
        dispatch(setOrderData(newOrderData));
    };

    return (
        <Box>
            <Box sx={{ p: '10px 24px', marginBottom: 2, display: 'flex', flexDirection: 'row' }}>
                <TextField
                    autoFocus
                    id="invoice-number-input"
                    label="UPC code"
                    variant="outlined"
                    value={initialScan}
                    onChange={e => setInitialScan(e.target.value.toUpperCase())}
                    sx={{ flex: 1 }}
                    inputRef={input => (input != null ? upcInputRef : null)}
                    multiline
                    rows={3}
                />

                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    style={{ padding: '2px 1rem', margin: 10 }}
                    onClick={handleUPCSubmitButtonClicked}
                    // form="upc-form-id"
                >
                    {' '}
                    Submit{' '}
                </Button>
            </Box>

            <QualityControlDrawer
                serialDrawerOpen={serialDrawerOpen}
                setSerialDrawerOpen={setSerialDrawerOpen}
                serialInput={serialInput}
                setSerialInput={setSerialInput}
                handleDrawerClose={resetSerialDrawer}
                handleSerialSubmitButtonClicked={handleSerialSubmitButtonClicked}
                alias={
                    allScans?.item?.SerialVerify.find(
                        serialVerify => serialVerify.ScanOrder === currItemSerialList.length
                    )?.Alias || 'Serial Input'
                }
            />
        </Box>
    );
};

export default QualityControlScanner;
