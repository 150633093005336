import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '../../../../app/store';
import textFieldFocus from '../../../rapidQc/utils/textFieldFocus';
import { DISPOSITION_OTHER_ID } from '../../constants';
import rmaApi from '../../rmaApi';
import { ItemReceivedDisposition, ReceivedItemUnitDetail } from '../../rmaModels';
import { isRmaCompleted } from '../../utilities';

export const ReceivedItemUnitDetailIndividualFormDisposition = React.memo(
    ({
        editingItemUnit,
        setEditingItemUnit,
        showErrors = false,
    }: {
        editingItemUnit: ReceivedItemUnitDetail;
        setEditingItemUnit: (unit: ReceivedItemUnitDetail) => void;
        showErrors?: boolean;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const dispositionOptions: ItemReceivedDisposition[] =
            rmaApi.endpoints.getDispositions.useQuery({}).data?.Data || [];

        const hasDispositionNotesError =
            editingItemUnit.Disposition?.Id === DISPOSITION_OTHER_ID && !!!editingItemUnit.DispositionNotes;

        const showDispositionNotesError = hasDispositionNotesError && showErrors;
        const dispositionNotesTextfieldRef = useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (DISPOSITION_OTHER_ID === editingItemUnit.Disposition?.Id) {
                textFieldFocus({ textFieldRef: dispositionNotesTextfieldRef });
            }
        }, [editingItemUnit.Disposition]);

        return (
            <React.Fragment>
                <FormControl variant="standard" fullWidth>
                    <InputLabel error={showErrors && editingItemUnit.Disposition === null} id="disposition-label">
                        DISPOSITION
                    </InputLabel>
                    <Select
                        disabled={isRmaCompleted(rma?.Status)}
                        labelId="disposition-label"
                        id="disposition"
                        error={showErrors && editingItemUnit.Disposition === null}
                        value={editingItemUnit.Disposition?.Id || 0}
                        onChange={event => {
                            setEditingItemUnit({
                                ...editingItemUnit,
                                Disposition: dispositionOptions.find(option => option.Id === event.target.value),
                            });
                        }}
                        label="DISPOSITION"
                    >
                        {dispositionOptions.map(option => (
                            <MenuItem value={option.Id}>{option.Label}</MenuItem>
                        ))}
                    </Select>
                    {showErrors && editingItemUnit.Disposition === null && (
                        <FormHelperText sx={{ color: 'error.main' }}>Select a disposition</FormHelperText>
                    )}
                </FormControl>
                <TextField
                    sx={{ flex: 1, mt: 1 }}
                    disabled={isRmaCompleted(rma?.Status)}
                    InputLabelProps={{ shrink: !!editingItemUnit.DispositionNotes }}
                    fullWidth
                    inputRef={dispositionNotesTextfieldRef}
                    error={showDispositionNotesError}
                    value={editingItemUnit.DispositionNotes || ''}
                    id="dispositionNotes"
                    label="ENTER DISPOSITION NOTES"
                    helperText={showDispositionNotesError ? 'Enter details on this disposition' : ''}
                    variant="standard"
                    onChange={event => {
                        setEditingItemUnit({
                            ...editingItemUnit,
                            DispositionNotes: event.target.value,
                        });
                    }}
                />
            </React.Fragment>
        );
    }
);
