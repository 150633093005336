import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import useNotificationByQueryResponse from '../../../../common/hooks/useNotificationByQueryResponse';
import batchOrderMonitorApi from '../../batchOrderMonitorApi';
import { BatchOrderStatusCounts } from '../../batchOrderMonitorModels';
import { DAYS_BACK, HOURS_PER_INCREMENT, MILLISEC_REFRESH_TIME } from '../../constants';
import { BatchOrderChartHorizontal } from './BatchOrderChartHorizontal';
import { BatchOrderChartVertical } from './BatchOrderChartVertical';

const BATCHED_DATE_FORMAT = 'MM/DD/YYYY HH:00:00';

export const BatchOrderCountsChart = ({ location }: { location: string }) => {
    const theme = useTheme();
    const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'));

    const daysAgo = dayjs().subtract(DAYS_BACK, 'day');
    const [batchOrderCounts, setBatchOrderCounts] = React.useState<BatchOrderStatusCounts[]>();

    const getBatchOrderCountsResponse = batchOrderMonitorApi.endpoints.getBatchOrderCounts.useQuery(
        {
            FromDate: daysAgo.format(BATCHED_DATE_FORMAT),
            ToDate: null,
            MomCode: null,
            IncrementHours: HOURS_PER_INCREMENT,
            Location: location,
        },
        { pollingInterval: MILLISEC_REFRESH_TIME }
    );

    useNotificationByQueryResponse({
        response: getBatchOrderCountsResponse,
        successFinishedFunction: () => {
            if (getBatchOrderCountsResponse.data?.Data) {
                setBatchOrderCounts(getBatchOrderCountsResponse.data?.Data.toReversed());
            }
        },
    });

    const chartData = batchOrderCounts?.map(b => ({
        DateYear: dayjs(b.FromDate).format('M-D-YY'),
        Date: dayjs(b.FromDate).format('M-D'),
        Completed: b.QcCompleted,
        'Not Completed': b.Batched - b.QcCompleted,
        Manifested: b.Manifested,
        IncompleteBatches: b.IncompleteBatches,
        TotalBatches: b.TotalBatches,
        Total: b.Batched,
    }));

    if (isSmallViewPort) return <BatchOrderChartVertical chartData={chartData} />;

    return <BatchOrderChartHorizontal chartData={chartData} />;
};
