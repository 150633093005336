import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    editRolesByUserAction,
    getAssignedRolesByUserAction,
    resetUsersSuccessStatusAction,
} from '../../store/actions/action';
import {
    Drawer,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    IconButton,
    AppBar,
    Toolbar,
    Button,
    Checkbox,
} from '@mui/material';
import { getComparator, stableSort } from '../../../common/utils/commonUtils';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../common/components';
import UserRoleDrawerTableHead from './UserRoleDrawerTableHead';
import CloseIcon from '@mui/icons-material/Close';
import UserRoleTable from './UserRoleTable';

const PREFIX = 'UsersRoleDrawer';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    roleModalWrapper: `${PREFIX}-roleModalWrapper`,
    iconBtnStyles: `${PREFIX}-iconBtnStyles`,
    assignBtn: `${PREFIX}-assignBtn`,
    deleteBtn: `${PREFIX}-deleteBtn`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${classes.root}`]: {
        position: 'relative',
        height: '100vh',
    },

    [`& .${classes.paper}`]: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: 600,
        },
        [theme.breakpoints.down('600px')]: {
            width: '100%',
        },
    },

    [`& .${classes.table}`]: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 600,
        },
    },

    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    [`& .${classes.roleModalWrapper}`]: {
        width: '500px',
        padding: 20,
    },

    [`& .${classes.iconBtnStyles}`]: {
        outline: 'none',
        padding: '7px',
        '&:focus': {
            outline: 'none',
        },
    },

    [`& .${classes.assignBtn}`]: {
        position: 'absolute',
        right: 25,
        bottom: 35,
    },

    [`& .${classes.deleteBtn}`]: {
        position: 'absolute',
        right: 25,
        bottom: 150,
        background: 'red',
        '&:hover': {
            background: 'red',
        },
    },
}));

const UsersRoleDrawer = ({ selectedUser, setSelectedUser, rolesList, isLoading }) => {
    const dispatch = useDispatch();

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isAssigningRole, setIsAssigningRole] = useState(false);
    const [unAssignedRoles, setUnAssignedRoles] = useState([]);

    useEffect(() => {
        if (selectedUser) {
            dispatch(getAssignedRolesByUserAction({ userId: selectedUser.userId }));
        }
    }, [selectedUser, dispatch]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rolesList.length - page * rowsPerPage);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAll = ({ target: { checked } }) => {
        if (checked) {
            setUnAssignedRoles([...rolesList]);
            return;
        }
        setUnAssignedRoles([]);
    };

    const handleClick = row => {
        const isListItem = unAssignedRoles.find(role => role.id === row.id);
        if (isListItem) {
            setUnAssignedRoles(unAssignedRoles.filter(role => role.id !== row.id));
            return;
        }
        setUnAssignedRoles(unAssignedRoles.concat(row));
    };

    const isSelected = roleId => (unAssignedRoles.find(unRole => unRole.id === roleId) ? true : false);

    const handleUnAssignRoles = () => {
        dispatch(resetUsersSuccessStatusAction());
        dispatch(
            editRolesByUserAction(
                {
                    userId: selectedUser.userId,
                    userRoles: unAssignedRoles.map(role => ({ roleId: role.id, assigned: false })),
                },
                'UNASSIGNED'
            )
        );
    };

    return (
        <StyledDrawer
            variant="temporary"
            anchor="right"
            open={selectedUser ? true : false}
            onClose={() => setSelectedUser(null)}
        >
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <AppBar position="static" color="primary">
                        <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6" data-testid="user-name">
                                {selectedUser?.username}
                            </Typography>
                            <IconButton
                                aria-label=""
                                data-testid="close-user-roles-drawer"
                                onClick={() => setSelectedUser(null)}
                                size="large"
                            >
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <TableContainer>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
                            {unAssignedRoles.length > 0 && (
                                <Button
                                    variant="contained"
                                    style={{ color: 'white', background: 'red' }}
                                    onClick={handleUnAssignRoles}
                                >
                                    Un-Assign Roles
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '1rem' }}
                                onClick={() => {
                                    dispatch(resetUsersSuccessStatusAction());
                                    setIsAssigningRole(true);
                                }}
                            >
                                Assign More Roles
                            </Button>
                        </div>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                            <UserRoleDrawerTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                handleSelectAll={handleSelectAll}
                                numSelected={unAssignedRoles.length}
                                rowCount={rolesList.length}
                            />
                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: 300,
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <Spinner />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    stableSort(rolesList, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            const isItemSelected = isSelected(row.id);
                                            return (
                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    color="primary"
                                                    onClick={() => handleClick({ ...row })}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            color="primary"
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        style={{
                                                            paddingTop: '5px',
                                                            paddingBottom: '5px',
                                                            fontWeight: 500,
                                                            textDecoration: isItemSelected ? 'line-through' : 'none',
                                                        }}
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                )}
                                {emptyRows > 0 && !isLoading && (
                                    <TableRow style={{ height: 43 * emptyRows }}>
                                        <TableCell colSpan={3} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 25]}
                        component="div"
                        count={rolesList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    <Drawer
                        variant="temporary"
                        anchor="right"
                        open={isAssigningRole ? true : false}
                        onClose={() => setIsAssigningRole(false)}
                        style={{ width: '100%' }}
                        data-testid="assign-role-drawer"
                    >
                        <Paper>
                            <UserRoleTable
                                setIsAssigningRole={setIsAssigningRole}
                                isLoading={isLoading}
                                assignedList={rolesList}
                                selectedUser={selectedUser}
                            />
                        </Paper>
                    </Drawer>
                </Paper>
                {/* {unAssignedRoles.length > 0 && <IconButton aria-label="" onClick={handleUnAssignRoles} className={classes.deleteBtn} onclick={handleUnAssignRoles}>
        <DeleteIcon style={{color:'white'}} />
      </IconButton>} */}
                {/* <Button variant="contained" color="primary" className={classes.assignBtn} onClick={()=>setIsAssigningRole(true)}>
        Assign Role
      </Button> */}
            </div>
        </StyledDrawer>
    );
};

export default UsersRoleDrawer;
