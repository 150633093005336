import { GET_COMPANIES_URL } from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getCompanies = async action => {
    const res = await API.get(GET_COMPANIES_URL);
    // if (res.status >= 400) {
    //     throw new Error('Invalid Credentials');
    // }
    return res;
};
