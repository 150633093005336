import { Skeleton } from '@mui/material';

const LoadingCard = () => {
    return (
        <>
            <Skeleton variant="rectangular" animation="wave" width={290} height={148} />
            <Skeleton variant="rectangular" animation="wave" width={290} height={30} style={{ margin: '0.5rem 0' }} />
            <Skeleton variant="rectangular" animation="wave" width={290} height={30} />
        </>
    );
};

export default LoadingCard;
