import { Box, Button, TextField } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../app/store';
import InputDialog from '../../../../common/components/Dialog/InputDialog';
import CompanyDropdownV3 from '../../../../common/components/dropdown/CompanyDropdownV3';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import { ProcedureRecord } from '../../models';
import ProcedureApi from '../../ProcedureApi';

const CreateProcedureDialog = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const username = useAppSelector(state => state.authentication.username);

    const [form, setForm] = React.useState<ProcedureRecord>({
        Title: '',
        Client: '',
        CreatedAt: dayjs().format(),
        CreatedBy: username,
        Instructions: [],
        TargetReceiverItemAdjustments: [],
    });

    const [triggerUpsert, upsertResponse] = ProcedureApi.endpoints.upsertProcedure.useMutation();
    const navigate = useNavigate();

    useNotificationByMutationResponseV2({
        response: upsertResponse,
        successFinishedFunction: () => {
            navigate(`canvas/${upsertResponse.data}`);
        },
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (form.Client && form.Title)
            triggerUpsert({
                ...form,
                CreatedAt: dayjs().format(),
            });
    };

    return (
        <InputDialog
            bodyElement={
                <form onSubmit={handleSubmit}>
                    <CompanyDropdownV3
                        value={form.Client}
                        textFieldProps={{ label: 'Client', required: true }}
                        onChange={(event, company) => {
                            setForm({ ...form, Client: company?.mom || '' });
                        }}
                        sx={{ mb: 3 }}
                        key={form.Client}
                    />
                    <TextField
                        label="Title"
                        fullWidth
                        onChange={event => {
                            const newValue = event.target.value;
                            setForm({ ...form, Title: newValue });
                        }}
                        required
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button type="submit" variant="contained" sx={{ marginTop: 2 }}>
                            Create
                        </Button>
                    </Box>
                </form>
            }
            title="Create Procedure"
            isHidden={!isOpen}
            handleClose={onClose}
        />
    );
};

export default CreateProcedureDialog;
