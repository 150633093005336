import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/reAssignTaskDetailApi';

export function* getReAssignTaskDetailsSaga(action) {
    try {
        const json = yield call(api.getReAssignTaskDetailApi, action);

        yield put({ type: actionTypes.GET_REASSIGN_TASK_DETAILS_SUCCESS, payload: json.data });
    } catch (error) {
        yield put({ type: actionTypes.GET_REASSIGN_TASK_DETAILS_FAILURE, error: error });
    }
}

export default function* reAssignTaskDetailsWatcher() {
    yield takeLatest(actionTypes.GET_REASSIGN_TASK_DETAILS, getReAssignTaskDetailsSaga);
}
