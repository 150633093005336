import React, { useState, useEffect, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../common/components/index';
import { getCompanySkusByTaskStateAction } from '../store/actions/action';
import TaskAccordian from './StockCount/TaskAccordian';
import TaskAll from './StockCount/TaskAll';

const PREFIX = 'SupervisorDashboard';

const classes = {
    root: `${PREFIX}-root`,
    tasks: `${PREFIX}-tasks`,
    error: `${PREFIX}-error`,
    button: `${PREFIX}-button`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        backgroundColor: theme.palette.background.paper,
        // width: '100vw',
        position: 'relative',
        margin: 'auto',
        boxShadow: '0 0 6px rgba(0,0,0,0.1)',
        borderRadius: 5,
        overflow: 'hidden',
    },

    [`& .${classes.tasks}`]: {
        height: 'calc(100vh - 104px)',
        overflowY: 'auto',
    },

    [`& .${classes.error}`]: {
        color: 'red',
    },

    [`& .${classes.button}`]: {
        '& button': {
            //targeting the inner button element
            outline: 'none',
            '&:focus': {
                outline: 'none',
            },
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `action-tab-${index}`,
        'aria-controls': `action-tabpanel-${index}`,
    };
}

export const SupervisorDashboard = React.memo(({ handleCloseDialog, setSelectedTab }) => {
    const dispatch = useDispatch();
    const { isLoading, ...companySkusByTaskState } = useSelector(state => state.companySkusByTaskState);

    const [value, setValue] = useState(0);
    const [activeTasks, setActiveTasks] = useState([]);
    const [pendingTasks, setPendingTasks] = useState([]);
    const [reviewTasks, setReviewTasks] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 0) {
            dispatch(getCompanySkusByTaskStateAction([2]));
            setSelectedTab(0);
        } else if (value === 1) {
            dispatch(getCompanySkusByTaskStateAction([1]));
            setSelectedTab(1);
        } else if (value === 2) {
            dispatch(getCompanySkusByTaskStateAction([3]));
            setSelectedTab(2);
        } else if (value === 3) {
            setSelectedTab(3);
        }
        // return () => dispatch(setCompanySkusByTaskStateInitialStateAction());
    }, [dispatch, value, setSelectedTab]);

    useEffect(() => {
        if (
            companySkusByTaskState.companySkusForCurrentTaskState &&
            companySkusByTaskState.companySkusForCurrentTaskState.length >= 0
        ) {
            if (value === 0) {
                setActiveTasks(companySkusByTaskState.companySkusForCurrentTaskState);
            }
            if (value === 1) {
                setPendingTasks(companySkusByTaskState.companySkusForCurrentTaskState);
            }
            if (value === 2) {
                setReviewTasks(companySkusByTaskState.companySkusForCurrentTaskState);
            }
        }
    }, [companySkusByTaskState, value]);

    return (
        <Root className={classes.root}>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: 'white' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.button}
                    // variant="fullWidth"
                    aria-label="supervisor action tabs"
                >
                    <Tab label="Active" {...a11yProps(0)} />
                    <Tab label="Pending" {...a11yProps(1)} />
                    <Tab label="Review" {...a11yProps(2)} />
                    <Tab label="All" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            {isLoading ? (
                <Box style={{ maxHeight: 350 }}>
                    <Spinner style={{ height: 350 }} />
                </Box>
            ) : value === 3 ? (
                <TaskAll handleCloseDialog={handleCloseDialog} />
            ) : companySkusByTaskState.companySkusForCurrentTaskState &&
              companySkusByTaskState.companySkusForCurrentTaskState.length > 0 ? (
                <Fragment>
                    {value === 0 &&
                        activeTasks?.[0] &&
                        activeTasks.map((company, index) => (
                            <TaskAccordian
                                key={company.companyId}
                                companyDetails={{ companyName: company.companyName, companyId: company.companyId }}
                                skuList={company.assignedSkus}
                                index={index}
                                tabType={value}
                            />
                        ))}
                    {value === 1 &&
                        pendingTasks?.[0] &&
                        pendingTasks.map((company, index) => (
                            <TaskAccordian
                                key={company.companyId}
                                companyDetails={{ companyName: company.companyName, companyId: company.companyId }}
                                skuList={company.assignedSkus}
                                index={index}
                                handleCloseDialog={handleCloseDialog}
                                tabType={value}
                            />
                        ))}
                    {value === 2 &&
                        reviewTasks?.[0] &&
                        reviewTasks.map((company, index) => (
                            <TaskAccordian
                                key={company.companyId}
                                companyDetails={{ companyName: company.companyName, companyId: company.companyId }}
                                skuList={company.assignedSkus}
                                index={index}
                                tabType={value}
                            />
                        ))}
                </Fragment>
            ) : (
                <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body1" color="initial">
                        No Companies Yet
                    </Typography>
                </div>
            )}
        </Root>
    );
});
