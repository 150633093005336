import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Box, Button, Divider, Popover, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/store';
import '../../common/assets/css/primaryAppBar.css';
import logo from '../../common/assets/images/RushOrder.png';
import sealLogo from '../../common/assets/images/seal-white-white-transparant.png';
import { customTheme } from '../../common/components/Theme';
import { Drawer } from '../../common/components/index';
import { logOut } from '../authentication/authenticationSlice';
import { setIsNavigationWarningDialogOpen } from '../notification/notificationSlice';
import { FRONT_END_VERSION } from '../version/version';
import versionApi from '../version/versionApi';

const PREFIX = 'PrimaryAppBar';

const classes = {
    grow: `${PREFIX}-grow`,
    menuButton: `${PREFIX}-menuButton`,
    navBar: `${PREFIX}-navBar`,
    homeIcon: `${PREFIX}-homeIcon`,
    title: `${PREFIX}-title`,
    sectionMobile: `${PREFIX}-sectionMobile`,
    logo: `${PREFIX}-logo`,
    input: `${PREFIX}-input`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.grow}`]: {
        flexGrow: 1,
    },

    [`& .${classes.menuButton}`]: {
        marginRight: theme.spacing(2),
    },

    [`& .${classes.navBar}`]: {
        '&:hover': {
            color: 'white',
        },
    },

    [`& .${classes.homeIcon}`]: {
        '&:hover': {
            color: 'white',
        },
    },

    [`& .${classes.title}`]: {
        color: 'white',
        textDecoration: 'none',
    },

    [`& .${classes.sectionMobile}`]: {
        // display: 'flex',
        padding: 0,
    },

    [`& .${classes.logo}`]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(3),
    },

    [`& .${classes.input}`]: {
        background: 'red',
    },
}));

export const PrimaryAppBar = React.memo(() => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const { fullName, username } = useAppSelector(state => state.authentication);
    const userId = useAppSelector(state => state.authentication.authenticationResponse?.userInfo.id);
    const shouldShowNavigationWarningDialog = useAppSelector(
        state => state.notification.shouldShowNavigationWarningDialog
    );
    const versionResponse = versionApi.endpoints.getApplicationVersion.useQuery(undefined, {
        pollingInterval: 900000, //15 mins
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const id = open ? 'simple-popover' : undefined;
    const isVersionGood = versionResponse?.data ? versionResponse.data === FRONT_END_VERSION : true;

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleDrawer = (open: any) => (event: any) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    return (
        <Root className={classes.grow}>
            <AppBar position="static" sx={{ backgroundColor: isVersionGood ? '#0080ff' : red['A700'] }}>
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flex: '0.9' }}>
                        <Box
                            sx={{ display: 'flex', '&:hover': { cursor: 'pointer' } }}
                            onClick={() => {
                                if (shouldShowNavigationWarningDialog) dispatch(setIsNavigationWarningDialogOpen(true));
                                else navigate('/');
                            }}
                        >
                            <Avatar alt="Seal Logo" src={sealLogo} />

                            <Box sx={{ mx: 2 }}>
                                <img src={logo} alt="" />
                            </Box>
                        </Box>

                        {isVersionGood ? (
                            <></>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ mx: 1 }}>New Version Available! (Please Refresh Page)</Typography>
                                <WarningIcon />
                            </Box>
                        )}
                    </div>
                    <div>
                        <Typography align={'center'}>
                            {fullName} <br />
                        </Typography>
                    </div>
                    <div>
                        <Avatar onClick={handleClick} style={{ cursor: 'pointer' }} />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer open={open} toggleDrawer={handleToggleDrawer} />
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    zIndex: theme => theme.zIndex.appBar + 1,
                }}
            >
                <div>
                    <Box display="flex" alignItems="center" style={{ minWidth: 300, padding: '15px 12px' }}>
                        <Avatar style={{ height: 50, width: 50, marginRight: 10 }} />
                        <div>
                            <Typography style={{ color: customTheme.primaryTextColor }}>{username}</Typography>
                            <Typography style={{ color: customTheme.secondaryTextColor }}>{userId}</Typography>
                        </div>
                    </Box>
                    <Divider />
                    <Button fullWidth onClick={() => dispatch(logOut())}>
                        Logout
                    </Button>
                </div>
            </Popover>
            <Outlet />
        </Root>
    );
});
