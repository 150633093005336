import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import CompanyDropdownV3 from '../../../../common/components/dropdown/CompanyDropdownV3';
import { GetSerialRequest } from '../../serialModels';

const CompanySkuForm = ({
    initialValue,
    onSubmit,
}: {
    initialValue: GetSerialRequest;
    onSubmit: (request: GetSerialRequest) => void;
}) => {
    const [client, setClient] = React.useState(initialValue.Client);
    const [sku, setSku] = React.useState(initialValue.Sku);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                onSubmit({ Client: client, Sku: sku });
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <CompanyDropdownV3
                    value={client}
                    onChange={(event, value) => {
                        setClient(value?.mom || '');
                    }}
                    sx={{ flex: 2, m: 1 }}
                    textFieldProps={{ label: 'Client', name: 'client' }}
                />

                <TextField
                    variant="outlined"
                    label="SKU"
                    name="sku"
                    value={sku}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        setSku(newValue);
                    }}
                    sx={{ flex: 3, m: 1 }}
                />
            </Box>
            <Box sx={{ display: 'flex', m: 1 }}>
                <Button type="submit" sx={{ flex: 1 }} variant="contained">
                    Search
                </Button>
            </Box>
        </form>
    );
};

export default CompanySkuForm;
