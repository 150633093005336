import { RmaAllScans } from '../../oldRma/rmaModels';
import { AllScans } from '../qualityControlModels';

const APPLICATION_IDENTIFIER_START_SEPARATOR_SYMBOL = '(';
const UPC_APPLICATION_IDENTIFIER = '(01)';
const SERIAL_APPLICATION_IDENTIFIER = '(21)';
const LOT_APPLICATION_IDENTIFIER = '(10)';
const LIST_OF_APPLICATION_IDENTIFIERS = [UPC_APPLICATION_IDENTIFIER, SERIAL_APPLICATION_IDENTIFIER];

const GROUP_SEPARATOR_START_SEPARATOR_SYMBOL = '<';
const UPC_GROUP_SEPARATOR = '<GS>01';
const SERIAL_GROUP_SEPARATOR = '<GS>21';
const SKU_GROUP_SEPARATOR = '<GS>241';
const ADDITIONAL_SERIAL_GROUP_SEPARATOR = '<GS>250';
const LIST_OF_GROUP_SEPARATORS = [
    UPC_GROUP_SEPARATOR,
    SERIAL_GROUP_SEPARATOR,
    SKU_GROUP_SEPARATOR,
    ADDITIONAL_SERIAL_GROUP_SEPARATOR,
];

//parses to handle indiviudal company's 2D scan
//throws error if can't be parsed
export const parseInitialQCScan = (
    mom: string,
    scanInput: string,
    isInitialScan: boolean = true
): AllScans | RmaAllScans => {
    try {
        let result = scanInput;

        //can't believe these companies use white space as separator >:(
        switch (mom) {
            case 'WSL':
                if (result.includes('\n')) return parseWSL(result);
                break;
            // Sometimes TST client has other client's parsing logic
            case 'TST':
                mom = process.env.REACT_APP_PARSE_GUN_TST_MOMCODE_MAPPING || mom;
                break;
        }

        const scanInputWithoutWhitespace = result.replace(/\s/g, '');
        result = scanInputWithoutWhitespace;
        if (result.includes('SN:')) return parseSN(result);

        switch (mom) {
            case 'PUR':
                if (result.includes(',')) return parsePUR(result);
                break;
            case 'STM':
                if (result.includes(',,')) return parseSTM(result);
                break;
            case 'HHH':
                if (result.includes('-')) return parseHHH(result);
                break;
            case 'GBM':
                result = preParseGBM(result);
                break;
            case 'HIN':
                result = preParseHIN(result);
                break;
        }

        if (LIST_OF_APPLICATION_IDENTIFIERS.some(separator => result.includes(separator)))
            return parseApplicationIdentifier(result);

        if (LIST_OF_GROUP_SEPARATORS.some(separator => result.includes(separator))) return parseGroupSeparator(result);

        if (isInitialScan) return { upc: result };
        else return { serial: result };
    } catch (e) {
        throw e;
    }
};

const parseScanIntoKeyValue = (
    scanInput: string,
    listOfKeys: string[],
    startSymbol: string,
    longestIdentifier: string
) => {
    let currScan = scanInput;
    let result = {};
    while (currScan.includes(startSymbol)) {
        let identifier;

        if (currScan.startsWith(startSymbol)) {
            listOfKeys.forEach(separator => {
                if (currScan.startsWith(separator)) identifier = currScan.substring(0, separator.length);
            });
            if (!identifier) identifier = currScan.substring(0, longestIdentifier.length);
            currScan = currScan.substring(identifier.length);
        }

        let indexOfEndOfValue = currScan.indexOf(startSymbol);
        indexOfEndOfValue = indexOfEndOfValue === -1 ? currScan.length : indexOfEndOfValue;
        const value = currScan.substring(0, indexOfEndOfValue);
        currScan = currScan.substring(value.length);

        if (identifier) result = { ...result, [identifier]: value };
    }

    return result;
};

const parseApplicationIdentifier = (scanInput: string): AllScans => {
    const map = parseScanIntoKeyValue(
        scanInput,
        LIST_OF_APPLICATION_IDENTIFIERS,
        APPLICATION_IDENTIFIER_START_SEPARATOR_SYMBOL,
        UPC_APPLICATION_IDENTIFIER
    );

    let result = {};
    for (const [key, value] of Object.entries(map)) {
        switch (key) {
            case UPC_APPLICATION_IDENTIFIER:
                result = { ...result, upc: value };
                break;
            case SERIAL_APPLICATION_IDENTIFIER:
                result = { ...result, serial: value };
                break;
            case LOT_APPLICATION_IDENTIFIER:
                result = { ...result, lot: value };
        }
    }

    return result;
};

const parseGroupSeparator = (scanInput: string): AllScans => {
    const map = parseScanIntoKeyValue(
        scanInput,
        LIST_OF_GROUP_SEPARATORS,
        GROUP_SEPARATOR_START_SEPARATOR_SYMBOL,
        SKU_GROUP_SEPARATOR
    );

    let result = {};
    for (const [key, value] of Object.entries(map)) {
        switch (key) {
            case UPC_GROUP_SEPARATOR:
                result = { ...result, upc: value };
                break;
            case SERIAL_GROUP_SEPARATOR:
                result = { ...result, serial: value };
                break;
            case SKU_GROUP_SEPARATOR:
                result = { ...result, sku: value };
                break;
            case ADDITIONAL_SERIAL_GROUP_SEPARATOR:
                if ('serial' in result) result = { ...result, serial2: value };
                else result = { ...result, serial: value };
                break;
        }
    }

    return result;
};

const parseWSL = (scanInput: string): AllScans => {
    const tokens = scanInput.split('\n');
    return {
        upc: tokens[0],
        ...(tokens[1] && { serial: tokens[1] }),
        ...(tokens[1] && { sku: tokens[2] }),
    };
};

const parseSTM = (scanInput: string): AllScans => {
    const tokens = scanInput.split(',,');
    return {
        upc: tokens[0],
        ...(tokens[1] && { serial: tokens[1] }),
    };
};

const parseHHH = (scanInput: string): AllScans => {
    const tokens = scanInput.split('-');
    return {
        upc: tokens[0],
        serial: scanInput,
    };
};

const parsePUR = (scanInput: string): AllScans => {
    const tokens = scanInput.split(',');
    let result = {};
    for (let token of tokens) {
        if (token.startsWith('SN:')) result = { ...result, serial: token.split(':')[1] };
        else if (token.startsWith('UPC:')) {
            result = { ...result, upc: token.split(':')[1] };
        }
    }
    return result;
};

const GBM_UPC_APPLICATION_IDENTIFIER = '(01)0';
// const GBM_TOKENS_TO_BE_REMOVED = ['UDI', 'MD', '(RD)'];
const GBM_TOKENS_TO_BE_REMOVED = ['(RD)'];

const preParseGBM = (scanInput: string) => {
    let result = scanInput.replace(GBM_UPC_APPLICATION_IDENTIFIER, UPC_APPLICATION_IDENTIFIER);

    //warning: serial may contain bad token and be unintentionally removed
    GBM_TOKENS_TO_BE_REMOVED.forEach(badToken => {
        result = result.replace(badToken, '');
    });

    if (result.startsWith('*') && result.endsWith('*')) result = result.substring(1, result.length - 1);

    return result;
};

const preParseHIN = (scanInput: string) => {
    let result = scanInput;

    if (result.includes(LOT_APPLICATION_IDENTIFIER) && !result.includes(SERIAL_APPLICATION_IDENTIFIER))
        result = result.replace(LOT_APPLICATION_IDENTIFIER, SERIAL_APPLICATION_IDENTIFIER);

    return result;
};

//idk what client this is, but this was an example test case that needs to be handled
//SN:23P750NA00000547,DC:240423,MFG:RS,CO:CN, UPC:860006033521
const parseSN = (scanInput: string) => {
    const tokens = scanInput.replace(/\s/g, '').split(',');
    let result: AllScans = {};

    for (let token of tokens) {
        if (token.startsWith('SN:')) result = { ...result, serial: token.substring(3) };
        if (token.startsWith('UPC:')) result = { ...result, upc: token.substring(4) };
    }

    return result;
};
