import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
    EDIT_CLIENTS_URL,
    EDIT_RATE_SHOPPING_URL,
    GET_CLIENT_EDIT_LOG_URL,
    GET_GROUP_DICT_URL,
    GET_MAP_EDIT_LOG_URL,
    GET_MATRIX_URL,
    GET_RATE_SHOPPING_COMPANY_URL,
    GET_SHIP_METHODS_URL,
} from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';
import {
    Filter,
    GetClientEditLogDetailsRequest,
    GetClientEditLogDetailsResponse,
    GetClientEditLogRequest,
    GetClientEditLogResponse,
    GetCompaniesResponse,
    GetGroupsResponse,
    GetMapEditLogDetailsResponse,
    GetRateShoppingRowsResponse,
    GetShipMethodResponse,
    GroupClientEditRequest,
    SetShipMethodRequest,
} from './shipMatrixModel';

export default createApi({
    reducerPath: 'shipMatrixApi',
    baseQuery: dreamBaseQueryWithReauth,
    tagTypes: ['RateShoppingGroups', 'RateShoppingMatrix'],
    endpoints: builder => ({
        getMatrix: builder.query<GetRateShoppingRowsResponse, Filter>({
            query: param => ({
                url: GET_MATRIX_URL,
                method: 'GET',
                params: param,
            }),
            providesTags: ['RateShoppingMatrix'],
            keepUnusedDataFor: 0,
        }),
        getRateShoppingGroups: builder.query<GetGroupsResponse, void>({
            query: () => ({
                url: GET_GROUP_DICT_URL,
                method: 'GET',
            }),
            providesTags: ['RateShoppingGroups'],
        }),
        getShipMethods: builder.query<GetShipMethodResponse, void>({
            query: () => ({
                url: GET_SHIP_METHODS_URL,
                method: 'GET',
            }),
        }),
        getRateShoppingCompanies: builder.query<GetCompaniesResponse, void>({
            query: () => ({
                url: GET_RATE_SHOPPING_COMPANY_URL,
                method: 'GET',
            }),
            providesTags: ['RateShoppingGroups'],
        }),
        editClient: builder.mutation<boolean, Partial<GroupClientEditRequest>>({
            query: param => ({
                url: EDIT_CLIENTS_URL,
                method: 'POST',
                body: param,
            }),
            invalidatesTags: ['RateShoppingGroups'],
        }),
        editShipMethod: builder.mutation<boolean, Partial<SetShipMethodRequest>>({
            query: param => ({
                url: EDIT_RATE_SHOPPING_URL,
                method: 'POST',
                body: param,
            }),
            invalidatesTags: ['RateShoppingMatrix'],
        }),
        getClientEditLog: builder.query<GetClientEditLogResponse, GetClientEditLogRequest>({
            query: param => ({
                url: GET_CLIENT_EDIT_LOG_URL,
                method: 'GET',
                params: param,
            }),
            providesTags: ['RateShoppingGroups'],
        }),
        getClientEditLogDetails: builder.query<GetClientEditLogDetailsResponse, GetClientEditLogDetailsRequest>({
            query: param => ({
                url: GET_CLIENT_EDIT_LOG_URL,
                method: 'GET',
                params: param,
            }),
            providesTags: ['RateShoppingGroups'],
        }),
        getMapEditLog: builder.query<GetClientEditLogResponse, GetClientEditLogRequest>({
            query: param => ({
                url: GET_MAP_EDIT_LOG_URL,
                method: 'GET',
                params: param,
            }),
            providesTags: ['RateShoppingMatrix'],
        }),
        getMapEditLogDetails: builder.query<GetMapEditLogDetailsResponse, GetClientEditLogDetailsRequest>({
            query: param => ({
                url: GET_MAP_EDIT_LOG_URL,
                method: 'GET',
                params: param,
            }),
            providesTags: ['RateShoppingMatrix'],
        }),
    }),
});
