import {
    GET_USERS_URL,
    GET_USER_PERMISSION_URL,
    GET_ASSIGNED_ROLES_BY_USER_URL,
    EDIT_ROLES_BY_USER_URL,
} from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getUserUrlApi = async action => {
    const res = await API.get(GET_USERS_URL);
    return res;
};

export const getUserPermissionApi = async action => {
    const res = await API.get(GET_USER_PERMISSION_URL);
    return res;
};

export const getAssignedRolesByUserApi = async action => {
    const res = await API.get(GET_ASSIGNED_ROLES_BY_USER_URL, {
        params: action.payload,
    });
    return res;
};

export const editRolesByUserApi = async action => {
    const res = await API.post(EDIT_ROLES_BY_USER_URL, action.payload);
    return res;
};
