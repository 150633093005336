import { Chip, Divider } from '@mui/material';

const FormDivider = ({ text }: { text: string }) => {
    return (
        <Divider sx={{ py: 3 }} variant="middle">
            <Chip label={text} sx={{ fontSize: 18, padding: 1 }} />
        </Divider>
    );
};

export default FormDivider;
