import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { formatDateWithoutSeconds } from '../../../../common/utils/dateUtil';
import { InboundRecord, OutboundRecord } from '../../serialModels';

const columns: readonly GridColDef<OutboundRecord | InboundRecord>[] = [
    {
        field: 'ReceiverNumber',
        headerName: 'Receiver Number',
        flex: 1,
    },
    {
        field: 'Serial',
        headerName: 'Serial',
        flex: 1,
    },
    {
        field: 'CreatedAt',
        headerName: 'Created At',
        flex: 1,
        valueGetter: (value, row) => {
            const newDate = formatDateWithoutSeconds(row.CreatedAt);
            if (newDate === 'Invalid date') {
                return 'N/A';
            }
            return newDate;
        },
    },
    {
        field: 'OutboundedAt',
        headerName: 'Outbounded At',
        flex: 1,
        valueGetter: (value, row) => {
            const newDate = formatDateWithoutSeconds(row.OutboundedAt);
            if (newDate === 'Invalid date') {
                return 'N/A';
            }
            return newDate;
        },
    },
    {
        field: 'OutboundedOrder',
        headerName: 'Outbounded Order',
        flex: 1,
    },
];

const OutboundDataGrid = ({ data, fileName }: { data: OutboundRecord[] | InboundRecord[]; fileName: string }) => {
    return (
        <DataGrid
            rows={data}
            columns={columns}
            getRowId={row => row.Serial + row.CreatedAt}
            sx={{ mt: 2 }}
            density="compact"
            slots={{ toolbar: GridToolbar }}
            slotProps={{ toolbar: { csvOptions: { fileName: fileName } } }}
        />
    );
};

export default OutboundDataGrid;
