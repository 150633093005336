import { Box } from '@mui/material';
import { BatchOrderCharts } from './BatchOrderChart/BatchOrderCharts';

export const BatchOrderMonitorDisplay = () => {
    return (
        <Box>
            <BatchOrderCharts />
        </Box>
    );
};
