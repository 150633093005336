import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { CreatedReceiverItemRecord } from '../../../models';

const ConfirmFinishDialog = ({
    isDialogOpen,
    onDialogClose,
    items,
    onSubmit,
}: {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    items: CreatedReceiverItemRecord[];
    onSubmit: () => void;
}) => {
    return (
        <Dialog onClose={onDialogClose} open={isDialogOpen} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>Confirm Finish</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableCell>Sku</TableCell>
                        <TableCell>Adjustment Amount</TableCell>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.Id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{item.Sku}</TableCell>
                                <TableCell>{item.AdjustmentAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit}>Finish</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmFinishDialog;
