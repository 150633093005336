import React from 'react';
import { Toolbar, Grid, TextField, Typography, Button } from '@mui/material';

const EnhancedTableToolbar = ({
    numSelected,
    selectedRole,
    handleSubmit,
    searchText,
    setSearchText,
    activeTab,
    setIsAssigningUsers,
}) => {
    return (
        <Toolbar style={{ paddingTop: 5, paddingBottom: 8 }}>
            <Grid container spacing={2}>
                <Grid xs={12} md={2} item style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="initial">
                        {' '}
                        {selectedRole?.name}{' '}
                    </Typography>
                </Grid>
                <Grid xs={12} md={4} item>
                    <TextField
                        id="outlined-basic"
                        placeholder="Search User"
                        hiddenLabel
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        value={searchText}
                        onChange={({ target: { value } }) => setSearchText(value)}
                    />
                </Grid>
                <Grid xs={12} md={6} item style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {setIsAssigningUsers && numSelected > 0 && (
                        <Button
                            variant="contained"
                            style={{ color: 'white', background: 'red' }}
                            onClick={handleSubmit}
                        >
                            Un-Assign Users
                        </Button>
                    )}
                    {setIsAssigningUsers && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setIsAssigningUsers(true);
                            }}
                            style={{ marginLeft: '1rem' }}
                        >
                            Assign Users
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Toolbar>
    );
};

export default EnhancedTableToolbar;
