import React from 'react';
import componentsApi from '../componentsApi';
import { Autocomplete, SxProps, TextField, Theme } from '@mui/material';
import { User } from '../componentsModel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface UserDropdownProps {
    setName: (name: string) => void;
    label?: string;
    readOnly?: boolean;
    error?: boolean;
    helperText?: string;
    startingName?: string | null;
    sx?: SxProps<Theme>;
    testId?: string;
    disabled?: boolean;
}

//appends testId with response state
const UserDropdown = ({
    setName,
    label = 'User',
    readOnly = false,
    error = false,
    helperText = '',
    startingName = null,
    sx = {},
    testId = 'user-dropdown-autocomplete',
    disabled = false,
}: UserDropdownProps) => {
    const response = componentsApi.endpoints.getUsers.useQuery();
    const [currUser, setCurrUser] = React.useState<User | null | undefined>(null);

    const sortedUsers = React.useMemo(() => {
        if (response.isSuccess) {
            const startingUser = response?.data?.find(user => user.name === (startingName || null));
            if (startingUser) {
                setCurrUser(startingUser);
            }

            let seenNames = new Set();
            let uniqueList = response?.data?.filter(nameDetail => {
                let currName = nameDetail['name'];
                return seenNames.has(currName) ? false : seenNames.add(currName);
            });
            return uniqueList?.sort((a, b) => a.name.localeCompare(b.name));
        }
    }, [response.isSuccess, startingName]);

    return response.isError || response?.data?.length == 0 ? (
        <Autocomplete
            autoSelect
            freeSolo={response.isError || response?.data?.length == 0}
            disablePortal
            options={sortedUsers?.map(option => option.name) || []}
            data-testid={`${testId}-${response.status}`}
            value={currUser?.name || null}
            onChange={(event, newValue) => {
                setName(newValue || '');
                setCurrUser(sortedUsers?.find(option => option.name === newValue));
            }}
            // getOptionLabel={(option: User) => `${option.name} (${option.code})`}
            renderInput={params => (
                <TextField {...params} variant="outlined" label={label} error={error} helperText={helperText} />
            )}
            readOnly={readOnly}
            popupIcon={readOnly ? '' : <ArrowDropDownIcon />}
            sx={{ ...sx }}
            disabled={disabled}
        />
    ) : (
        <Autocomplete
            disablePortal
            options={sortedUsers || []}
            data-testid={`${testId}-${response.status}`}
            value={currUser}
            onChange={(event, newValue) => {
                setName(newValue?.name || '');
                setCurrUser(newValue);
            }}
            getOptionLabel={(option: User) => `${option.name} (${option.code})`}
            renderInput={params => (
                <TextField {...params} variant="outlined" label={label} error={error} helperText={helperText} />
            )}
            readOnly={readOnly}
            popupIcon={readOnly ? '' : <ArrowDropDownIcon />}
            sx={{ ...sx }}
            disabled={disabled}
        />
    );
};

export default UserDropdown;
