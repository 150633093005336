import React from 'react';
import { overrideForm } from '../../../receiversSlice';
import ReceiverDialogTextField from './ReceiverDialogTextField';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { Rule } from '../../../receiversModels';

const CommentField = ({ rule }: { rule: Rule | undefined }) => {
    const dispatch = useAppDispatch();
    const comment = useAppSelector(state => state.receivers.form.Comment);

    return (
        <ReceiverDialogTextField
            label="Notes"
            value={comment}
            onChange={newValue => {
                dispatch(overrideForm({ Comment: newValue }));
            }}
            textFieldProps={{
                multiline: true,
                rows: 3,
            }}
            rule={rule}
        />
    );
};

export default CommentField;
