import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../app/store';
import DialogCloseButton from '../../../common/components/Dialog/DialogCloseButton';

export const ConfirmBatchIdDialog = React.memo(
    ({
        qty,
        handleOpenDialog,
        showDialog = false,
        handleLotNumberDialog,
        handleWrongQtyDialog,
    }: {
        qty: number | undefined;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
        handleLotNumberDialog: (openState: boolean) => void;
        handleWrongQtyDialog: (openState: boolean) => void;
    }) => {
        const batchId = useAppSelector(state => state.rapidQc.batchId);
        const handleClose = () => {
            handleOpenDialog(false);
        };
        return (
            <Dialog open={showDialog} fullWidth maxWidth={'sm'} onClose={handleClose}>
                <DialogTitle color="white" bgcolor="primary.main" sx={{ px: 3 }}>{`BATCH ID: ${batchId}`}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ py: 6, flex: 6 }}>
                            <Typography variant="h6">
                                IS <b>{qty}</b> THE EXPECTED # OF ORDERS?
                            </Typography>
                        </Box>
                        <Box sx={{ flex: 6 }}>
                            <Button
                                sx={{ mr: 5, px: 7 }}
                                variant="contained"
                                size="large"
                                onClick={() => {
                                    handleLotNumberDialog(true);
                                }}
                            >
                                YES
                            </Button>
                            <Button
                                sx={{ px: 7 }}
                                variant="contained"
                                color="error"
                                size="large"
                                onClick={() => {
                                    handleWrongQtyDialog(true);
                                }}
                            >
                                NO
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
