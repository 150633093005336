import { Button, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridFilterModel, GridToolbar } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { formatDateWithoutTime } from '../../../../common/utils/dateUtil';
import { BatchOrderMonitor } from '../../batchOrderMonitorModels';
import { QcStatus } from '../../constants';

export const OrderViewDataGrid = React.memo(
    ({
        batchOrders,
        filterBatchIdModel,
        setFilterBatchIdModel,
    }: {
        batchOrders: BatchOrderMonitor[];
        filterBatchIdModel: GridFilterModel;
        setFilterBatchIdModel: (filterModel: GridFilterModel) => void;
    }) => {
        const theme = useTheme();
        const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'));

        const getStatus = (started: boolean, completed: boolean) => {
            if (completed) {
                return QcStatus.COMPLETED;
            }

            if (started) {
                return QcStatus.STARTED;
            }

            return QcStatus.NOT_STARTED;
        };

        const lgViewPortcolumns: GridColDef[] = [
            {
                field: 'Batched',
                headerName: 'Date Created',
                flex: 1,
                valueGetter: value => formatDateWithoutTime(value),
            },
            {
                field: 'PackageId',
                headerName: 'Order',
                flex: 1,
            },
            {
                field: 'BatchId',
                headerName: 'Batch Id',
                flex: 1.5,
            },
            {
                field: 'QcStarted',
                headerName: 'QC Status',
                flex: 1,
                valueGetter: (value, row) => getStatus(!!row.QcStarted, !!row.Completed),
            },
            {
                field: 'Manifested',
                headerName: 'Manifest Date',
                flex: 1,
                valueGetter: value => (!!value ? formatDateWithoutTime(value) : '-'),
            },
            {
                field: 'Client',
                headerName: 'Client',
                flex: 0.5,
            },
        ];

        const smViewPortcolumns: GridColDef[] = [
            {
                field: 'PackageId',
                headerName: 'Order',
                flex: 1,
            },
        ];

        return (
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'Batched', sort: 'asc' }],
                    },
                }}
                filterModel={filterBatchIdModel}
                onFilterModelChange={newModel => setFilterBatchIdModel(newModel)}
                disableDensitySelector
                disableColumnSelector
                autoHeight
                getRowId={row => row.Id}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                rows={batchOrders || []}
                columns={isSmallViewPort ? smViewPortcolumns : lgViewPortcolumns}
                disableRowSelectionOnClick
                scrollbarSize={20}
            />
        );
    }
);
