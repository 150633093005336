import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import { useAppSelector } from '../../../app/store';
import rmaApi from '../rmaApi';
import { Box, Typography } from '@mui/material';

const RmaDisplay = () => {
    const rmaState = useAppSelector(state => state.oldRma);
    const getRmaItemsResponse = rmaApi.endpoints.getRmaItems.useQuery(
        rmaState.rmaNumber ? { momCode: rmaState.momCode, rmaNumber: rmaState.rmaNumber } : skipToken
    );

    return (
        <Box
            sx={{
                p: 5,
                display: 'flex',
                w: '100%',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    textAlign: 'center',
                }}
            >
                <Typography variant="h3">{getRmaItemsResponse.data?.MomCode}</Typography>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    borderLeft: 1,
                    textAlign: 'center',
                    borderColor: 'grey.400',
                }}
            >
                <Typography variant="h3">{getRmaItemsResponse.data?.RmaNumber}</Typography>
            </Box>
        </Box>
    );
};

export default RmaDisplay;
