import React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PrintIcon from '@mui/icons-material/Print';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import workOrderApi from '../../workOrderApi';
import usePdfWindow from '../../../../common/hooks/usePdfWindow';
import useNotificationByMutationResponse from '../../../../common/hooks/useNotificationByMutationResponse';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatDateWithoutTime } from '../../../../common/utils/dateUtil';
import { cleanTemplateIntoWorkOrderData, setMode } from '../../workOrderSlice';
import { PanToolAlt } from '@mui/icons-material';
import { setToWorkOrderTab } from '../../util';

interface EditWorkOrderSpeedDialProps {
    closeDialog: () => void;
}

const EditWorkOrderSpeedDial = ({ closeDialog }: EditWorkOrderSpeedDialProps) => {
    const form = useAppSelector(state => state.workOrder.form);
    const mode = useAppSelector(state => state.workOrder.workOrderMode);
    const username = useAppSelector(state => state.authentication.username);

    const [triggerGetPdf, getPdfResponse] = usePdfWindow({
        useLazyQuery: workOrderApi.endpoints.getPdf.useLazyQuery(),
    });
    const [triggerDeleteWorkOrder, deleteWorkOrderResponse] = useNotificationByMutationResponse({
        useMutation: workOrderApi.endpoints.deleteWorkOrder.useMutation(),
        errorContent: 'Failed to delete', //msg overriden by fetch
        successContent: 'Successfully deleted',
        finishedFuntion: () => {
            closeDialog();
        },
    });
    const [triggerCompleteWorkOrder, completeWorkOrderResponse] = useNotificationByMutationResponse({
        useMutation: workOrderApi.endpoints.completeWorkOrder.useMutation(),
        errorContent: 'Failed to complete',
        successContent: 'Successfully completed',
        finishedFuntion: () => {
            closeDialog();
        },
    });
    const tab = useAppSelector(state => state.workOrder.tab);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useAppDispatch();

    const actions = [
        {
            icon: <PanToolAlt />,
            name: 'Use',
            handleClick: (id: number | null) => {
                dispatch(cleanTemplateIntoWorkOrderData());
                dispatch(setMode('Create'));
                setToWorkOrderTab(dispatch);
            },
            enabledDuringWorkOrder: false,
            enabledDuringTemplate: true,
        },
        {
            icon: mode === 'Edit' ? <VisibilityIcon /> : <EditIcon />,
            name: mode === 'Edit' ? 'View' : 'Edit',
            handleClick: (id: number | null) => {
                const newMode = mode === 'Edit' ? 'View' : 'Edit';
                dispatch(setMode(newMode));
            },
            enabledDuringWorkOrder: true,
            enabledDuringTemplate: true,
        },

        {
            icon: <DeleteIcon />,
            name: form.Deleted
                ? `Deleted by ${form.DeletedBy || 'unknown'} on ${formatDateWithoutTime(form.DeletedDate)}`
                : 'Delete',
            handleClick: (id: number | null) => {
                if (id && !form.Deleted) {
                    triggerDeleteWorkOrder({
                        Id: id,
                        Username: username,
                    });
                }
            },
            enabledDuringWorkOrder: true,
            enabledDuringTemplate: true,
            isDisabled: form.Deleted,
        },
        {
            icon: <TaskAltIcon />,
            name: form.Completed
                ? `Completed by ${form.CompletedBy || 'unknown'} on ${formatDateWithoutTime(form.DateCompleted)}`
                : 'Complete',
            handleClick: (id: number | null) => {
                if (id && !form.Completed) {
                    triggerCompleteWorkOrder({
                        Id: id,
                        Username: username,
                    });
                }
            },
            enabledDuringWorkOrder: true,
            enabledDuringTemplate: false,
            isDisabled: form.Completed,
        },
        {
            icon: <PrintIcon />,
            name: 'Print',
            handleClick: (id: number | null) => {
                if (id) triggerGetPdf(id);
            },
            enabledDuringWorkOrder: true,
            enabledDuringTemplate: true,
        },
    ];

    return (
        <Box
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.modal + 1,
            }}
        >
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="Work Order Actions"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClick={open ? handleClose : handleOpen}
                open={open}
                onClose={(e, reason) => {
                    if (reason === 'blur') handleClose();
                }}
            >
                {actions
                    .filter(action =>
                        tab === 'Templates' ? action.enabledDuringTemplate : action.enabledDuringWorkOrder
                    )
                    .map(action => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => {
                                handleClose();
                                action.handleClick(form.Id);
                            }}
                            FabProps={{ disabled: action.isDisabled }}
                            sx={{
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                '&.Mui-disabled': {
                                    pointerEvents: 'auto',
                                },
                                '&.MuiFab-root:hover': {
                                    bgcolor: theme =>
                                        action.isDisabled ? theme.palette.action.disabledBackground : '',
                                },
                            }}
                        />
                    ))}
            </SpeedDial>
        </Box>
    );
};

export default EditWorkOrderSpeedDial;
