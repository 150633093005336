import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface InputDialogProps {
    title: String;
    bodyElement: JSX.Element;
    handleSubmit?: () => void;
    isHidden?: boolean;
    handleClose?: () => void;
}

const InputDialog = ({
    title,
    bodyElement,
    handleSubmit,
    isHidden = false,
    handleClose,
}: InputDialogProps): JSX.Element => {
    const navigate = useNavigate();

    const handleDialogClose = () => {
        if (handleClose) handleClose();
        else navigate('/');
    };

    const handleSubmitClick = () => {
        handleSubmit && handleSubmit();
    };

    return (
        <Dialog
            open={!isHidden}
            onClose={handleDialogClose}
            fullWidth
            onKeyUp={e => {
                if (e.key === 'Enter') {
                    handleSubmitClick();
                }
            }}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ flex: 1 }}>
                        {title}
                    </Typography>

                    <IconButton
                        sx={{ outline: 'none !important', marginLeft: 0 }}
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 1 }}>{bodyElement}</Box>
            </DialogContent>
            {handleSubmit && (
                <DialogActions>
                    <Button variant="contained" type="submit" color="primary" onClick={handleSubmitClick}>
                        {' '}
                        Submit{' '}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default InputDialog;
