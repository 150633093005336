import { takeLatest, put, call, all, fork } from 'redux-saga/effects';
import {
    GET_ORDER_BY_WAREHOUSE,
    GET_ORDER_BY_WAREHOUSE_FAILURE,
    GET_ORDER_BY_WAREHOUSE_SUCCESS,
    SET_WAREHOUSE_ORDER_LOADING,
    GET_DASHBOARD_WAREHOUSE_SUCCESS,
    GET_DASHBOARD_WAREHOUSE_FAILURE,
    GET_DASHBOARD_WAREHOUSE,
} from '../store/actions/actionTypes';
import { getOrderbyWarehouseApi, getDashboardWarehouseApi } from '../store/api/dashboardApi';

export function* getOrderbyWarehouseSaga(action) {
    try {
        yield put({ type: SET_WAREHOUSE_ORDER_LOADING });
        const { data } = yield call(getOrderbyWarehouseApi, action);
        yield put({ type: GET_ORDER_BY_WAREHOUSE_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: GET_ORDER_BY_WAREHOUSE_FAILURE, errorMessage: error });
    }
}

export function* getDashboardWarehouseSaga(action) {
    try {
        yield put({ type: SET_WAREHOUSE_ORDER_LOADING });
        const { data } = yield call(getDashboardWarehouseApi, action);
        yield put({ type: GET_DASHBOARD_WAREHOUSE_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: GET_DASHBOARD_WAREHOUSE_FAILURE, errorMessage: error });
    }
}

function* getOrderbyWarehouseWatcher() {
    yield takeLatest(GET_ORDER_BY_WAREHOUSE, getOrderbyWarehouseSaga);
}

function* getDashboardWarehouseWatcher() {
    yield takeLatest(GET_DASHBOARD_WAREHOUSE, getDashboardWarehouseSaga);
}

export default function* DashboardSaga() {
    yield all([fork(getOrderbyWarehouseWatcher), fork(getDashboardWarehouseWatcher)]);
}
