import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { Company } from '../../shipMatrixModel';
import React, { MouseEventHandler } from 'react';

interface CompaniesTransiferIndividualListProps {
    title: string;
    companies: readonly Company[];
    companyFilter: string;
    currGroupId: number;
    checked: readonly Company[];
    handleToggle: (company: Company) => MouseEventHandler<HTMLLIElement> | undefined;
}

const CompaniesTransiferIndividualList = ({
    title,
    companies,
    companyFilter,
    currGroupId,
    checked,
    handleToggle,
}: CompaniesTransiferIndividualListProps) => {
    const sortCompanyList = React.useCallback(
        (list: readonly Company[]) => {
            let arrayToSort = [...list];
            const result = arrayToSort.sort((a, b) => {
                if (a.Mom < b.Mom) {
                    return -1;
                }
                if (a.Mom > b.Mom) {
                    return 1;
                }
                return 0;
            });

            return result;
        },
        [companies]
    );

    return (
        <Paper sx={{ width: 400, height: 500, overflow: 'auto' }}>
            <Typography sx={{ textAlign: 'center', p: 1 }}>{title}</Typography>
            <List dense component="div" role="list">
                {sortCompanyList(companies)
                    .filter(
                        company =>
                            company?.Mom?.toLowerCase().includes(companyFilter.toLowerCase()) ||
                            company?.Name?.toLowerCase().includes(companyFilter.toLowerCase())
                    )
                    .map((value: Company) => {
                        const labelId = `transfer-list-item-${value}-label`;

                        return (
                            <ListItem key={value.Id} role="listitem" onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={`${value.Name} (${value.Mom})`}
                                    secondary={`${value?.AssignedShipMatrix ? value.AssignedShipMatrix.Name : ''}`}
                                    secondaryTypographyProps={{
                                        style: {
                                            color: value?.AssignedShipMatrix?.Id === currGroupId ? '' : 'blue',
                                        },
                                    }}
                                />
                            </ListItem>
                        );
                    })}
            </List>
        </Paper>
    );
};

export default React.memo(CompaniesTransiferIndividualList);
