import { takeLatest, put, call, all, fork } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/tasksApi';
import { getCompanySkusByTaskStateAction } from '../store/actions/action';

export function* getTasksByUserIdAndStateSaga(action) {
    try {
        // yield put({ type: actionTypes.SET_TASK_LOADING_TRUE });
        const { data } = yield call(api.getTaskByUserIdAndStateApi, action);
        yield put({ type: actionTypes.TASKS_RECEIVED_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: actionTypes.TASKS_RECEIVED_FAILED, error: error });
    }
}

export function* getFilteredTasksSaga(action) {
    try {
        yield put({ type: actionTypes.SET_FILTERED_TASK_LOADING_TRUE });
        const { data } = yield call(api.getFilteredTasksApi, action);
        yield put({ type: actionTypes.FILTERED_TASKS_RECEIVED_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: actionTypes.FILTERED_TASKS_RECEIVED_FAILED, error: error });
    }
}

export function* getReviewTaskDetailsSaga(action) {
    try {
        yield put({ type: actionTypes.SET_TASK_LOADING_TRUE });
        const { data } = yield call(api.getReviewTaskDetailsApi, action);
        yield put({
            type: actionTypes.GET_REVIEW_TASK_DETAILS_SUCCESS,
            payload: { data: data, shouldMerge: action.meta.shouldMerge },
        });
    } catch (error) {
        yield put({ type: actionTypes.GET_REASSIGN_TASK_DETAILS_FAILURE, error: error });
    }
}

export function* discardTaskSaga(action) {
    try {
        yield put({ type: actionTypes.SET_TASK_LOADING_TRUE });
        const { data } = yield call(api.discardTaskApi, action);
        yield put({ type: actionTypes.DISCARD_TASK_FAILURE, payload: data });
        yield put(getCompanySkusByTaskStateAction([3, 4]));
    } catch (error) {
        yield put({ type: actionTypes.DISCARD_TASK_FAILURE, error: error });
    }
}

function* getTasksByUserIdAndStateSagaWatcher() {
    yield takeLatest(actionTypes.GET_TASKS_BY_USER_ID_AND_STATE, getTasksByUserIdAndStateSaga);
}

function* getFilteredTasksSagaWatcher() {
    yield takeLatest(actionTypes.GET_FILTERED_TASKS, getFilteredTasksSaga);
}

function* getReviewTaskDetailsSagaWatcher() {
    yield takeLatest(actionTypes.GET_REVIEW_TASK_DETAILS, getReviewTaskDetailsSaga);
}

function* discardTaskSagaWatcher() {
    yield takeLatest(actionTypes.DISCARD_TASK, discardTaskSaga);
}

export default function* taskSagaWatcher() {
    yield all([
        fork(getTasksByUserIdAndStateSagaWatcher),
        fork(getFilteredTasksSagaWatcher),
        fork(getReviewTaskDetailsSagaWatcher),
        fork(discardTaskSagaWatcher),
    ]);
}
