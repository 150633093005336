import { Box } from '@mui/material';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import serialApi from '../../serialApi';
import { GetSerialRequest } from '../../serialModels';
import LocationForm from './LocationForm';
import SerialLocationDataGrid from './SerialLocationDataGrid';

const LocationTab = ({ formValues }: { formValues: GetSerialRequest }) => {
    const [triggerGetSerialLocation, getSerialLocationResponse] = serialApi.endpoints.getSerialLocations.useLazyQuery();

    useNotificationByResponse({
        isFetching: getSerialLocationResponse.isFetching,
        isError: getSerialLocationResponse.isError,
        errorContent: 'Failed to get',
        isUninitialized: getSerialLocationResponse.isUninitialized,
    });

    return (
        <Box>
            <LocationForm triggerGetSerialLocation={triggerGetSerialLocation} upperFormValues={formValues} />
            <SerialLocationDataGrid
                data={getSerialLocationResponse.data || []}
                fileName={`hvc-location-serials-${formValues.Client}-${formValues.Sku}`}
            />
        </Box>
    );
};

export default LocationTab;
