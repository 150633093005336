import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/store';
export function OrderDataDisplay() {
    const orderData = useAppSelector(state => state.qualityControl.orderData);

    return (
        <>
            {' '}
            <Box
                sx={{
                    p: 5,
                    display: 'flex',
                    w: '100%',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        textAlign: 'center',
                    }}
                >
                    <Typography>
                        {orderData?.ShipTo.FirstName} {orderData?.ShipTo.LastName}
                    </Typography>
                    <Typography>{orderData?.ShipTo.Company}</Typography>
                    <Typography>{orderData?.ShipTo.Address1}</Typography>
                    <Typography>{orderData?.ShipTo.Address2}</Typography>
                    <Typography>
                        {orderData?.ShipTo.City}, {orderData?.ShipTo.State}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        borderLeft: 1,
                        textAlign: 'center',
                        borderColor: 'grey.400',
                    }}
                >
                    <Typography>{orderData?.Carrier.Type}</Typography>
                    <Typography variant="h3">{orderData?.Carrier.Carrier}</Typography>
                    <Typography>{orderData?.Carrier.Title}</Typography>
                </Box>
            </Box>
        </>
    );
}
