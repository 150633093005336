import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import React from 'react';
import { QcLog } from '../../orderLookupModels';
import { failedActions, successfulActions } from './QcLogConstants';
import { QcLogPanelReadOnlyTextField } from './QcLogPanelReadOnlyTextField';

export default function QcLogPanel({
    qcLog,
    sameDateFinished,
    endingQcLog,
    isParent = false,
    isOnePanelSection = false,
    elapsedTime = '',
    listOpened = true,
    index = 0,
}: {
    qcLog: QcLog;
    sameDateFinished: boolean;
    endingQcLog?: QcLog;
    isParent?: boolean;
    isOnePanelSection?: boolean;
    elapsedTime?: string;
    listOpened?: boolean;
    index?: number;
}) {
    const getEndingStepColor = () => {
        if (!!endingQcLog?.Action) {
            if (successfulActions.has(endingQcLog?.Action)) {
                return 'success';
            }
            if (failedActions.has(endingQcLog?.Action) || endingQcLog.Error) {
                return 'warning';
            }
        }
        return 'primary';
    };

    const getActionColor = (qcLog: QcLog) => {
        if (successfulActions.has(qcLog.Action)) {
            return 'success.main';
        }
        if (failedActions.has(qcLog.Action) || qcLog.Error) {
            return 'warning.main';
        }
        return 'primary.main';
    };

    const getStatusIcon = (qcLog: QcLog) => {
        if (failedActions.has(qcLog.Action)) {
            return <DoDisturbOnOutlinedIcon />;
        }
        if (successfulActions.has(qcLog.Action)) {
            return <CheckIcon />;
        }
        return <></>;
    };

    const getActionString = (qcLog: QcLog) => {
        return !!qcLog.Serial ? `${qcLog.Action}: ${qcLog.Serial}` : qcLog.Action;
    };

    const actionColor = getActionColor(!!endingQcLog ? endingQcLog : qcLog);

    const cursorType = isParent && !!!isOnePanelSection ? 'pointer' : 'auto';
    const childBgColor = grey['100'];

    return (
        <Box
            sx={{
                borderRadius: 2,
                border: isParent ? 1 : 0,
                borderColor: actionColor,
                bgcolor: !isParent && index % 2 != 0 ? childBgColor : '',
            }}
        >
            <Box display="flex" justifyContent="flex-start" width="100%" sx={{ p: 1 }}>
                {!!endingQcLog ? (
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box>
                            {!!!isOnePanelSection ? (
                                <Tooltip title={listOpened ? 'Hide Details' : 'Show Details'} placement="top-start">
                                    <Chip
                                        color={getEndingStepColor()}
                                        label={getActionString(endingQcLog)}
                                        deleteIcon={getStatusIcon(endingQcLog)}
                                        onDelete={() => {}} // Needs this property to have the icon be shown on the right
                                    />
                                </Tooltip>
                            ) : (
                                <Chip
                                    color={getEndingStepColor()}
                                    label={getActionString(endingQcLog)}
                                    deleteIcon={getStatusIcon(endingQcLog)}
                                    onDelete={() => {}} // Needs this property to have the icon be shown on the right
                                />
                            )}
                        </Box>
                    </Box>
                ) : (
                    ''
                )}
                {!!isParent ? (
                    <React.Fragment>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            alignItems="center"
                            sx={{ pl: 2 }}
                        >
                            <Box width="10%">
                                <QcLogPanelReadOnlyTextField label="USER" value={qcLog.User} cursorType={cursorType} />
                            </Box>
                            <QcLogPanelReadOnlyTextField label="SYSTEM" value={qcLog.System} cursorType={cursorType} />

                            {!!elapsedTime ? (
                                <QcLogPanelReadOnlyTextField
                                    label="DURATION"
                                    value={elapsedTime}
                                    cursorType={cursorType}
                                />
                            ) : (
                                ''
                            )}
                            {!!endingQcLog?.Upc && isOnePanelSection ? (
                                <QcLogPanelReadOnlyTextField
                                    label="UPC"
                                    cursorType={cursorType}
                                    value={endingQcLog.Upc}
                                />
                            ) : (
                                ''
                            )}
                            <QcLogPanelReadOnlyTextField
                                label="COMPLETED"
                                cursorType={cursorType}
                                value={
                                    !!endingQcLog
                                        ? dayjs(endingQcLog.DateTime).format('M/DD/YY h:mm:ss A')
                                        : dayjs(qcLog.DateTime).format('M/DD/YY h:mm:ss A')
                                }
                            />
                        </Box>
                    </React.Fragment>
                ) : (
                    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" sx={{ pl: 1 }}>
                        <Box width="40%">
                            <Typography color={actionColor}>{getActionString(qcLog)}</Typography>
                        </Box>
                        <Box width="25%">
                            <Typography>{!!qcLog.Upc ? `UPC: ${qcLog.Upc}` : ''}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2">
                                {sameDateFinished
                                    ? dayjs(qcLog.DateTime).format('h:mm:ss A')
                                    : dayjs(qcLog.DateTime).format('M/DD/YY - h:mm:ss A')}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
