import { Autocomplete, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { MatrixCell, ShipMethod } from '../../shipMatrixModel';
import shipMatrixApi from '../../shipMatrixApi';

interface ShipMatrixUpdateFormProps {
    selectedCells: MatrixCell[];
    setSubmitData: React.Dispatch<React.SetStateAction<MatrixCell[]>>;
    onSubmit: () => void;
}

const ShipMatrixUpdateForm = ({ selectedCells, setSubmitData, onSubmit }: ShipMatrixUpdateFormProps) => {
    const [newShipMethod, setNewShipMethod] = React.useState<ShipMethod | null>(null);
    const getShipmethodsResponse = shipMatrixApi.endpoints.getShipMethods.useQuery();

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!newShipMethod) return;
        setNewShipMethod(null);
        setSubmitData(prev =>
            prev.map(cell => {
                if (selectedCells.includes(cell)) {
                    return { ...cell, ShipMethodId: newShipMethod.Id, ShipMethod: newShipMethod.Name } as MatrixCell;
                }
                return { ...cell };
            })
        );
        onSubmit();
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <Box sx={{ display: 'flex' }}>
                <Autocomplete
                    disablePortal
                    options={getShipmethodsResponse?.data?.Data || []}
                    value={newShipMethod}
                    onChange={(event, newValue) => {
                        setNewShipMethod(newValue || null);
                    }}
                    getOptionLabel={(option: ShipMethod) => `${option.Name}`}
                    renderInput={params => <TextField {...params} variant="outlined" label={'Ship Code'} />}
                    sx={{ flex: 4 }}
                />

                <Button sx={{ flex: 1, m: 1 }} variant="contained" color="secondary" type="submit">
                    Edit
                </Button>
            </Box>
        </form>
    );
};

export default ShipMatrixUpdateForm;
