import {
    ABORT_QC_URL_POSTFIX,
    COMPLETE_QC_URL_POSTFIX,
    GET_QC_ORDER_DATA_URL,
    GET_QC_ORDER_STATUS_URL_POSTFIX,
    GET_QC_ORDER_STATUS_URL_PREFIX,
    GET_QC_SERIAL_CHECK_URL,
    PARTIAL_SUBMIT_QC_URL,
    RESET_QC_URL_POSTFIX,
    START_QC_URL_POSTFIX,
    SUBMIT_QC_URL,
} from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstanceDream';

export const getQCOrderStatusApi = async action => {
    const res = await API.get(`${GET_QC_ORDER_STATUS_URL_PREFIX}${action.invoice}${GET_QC_ORDER_STATUS_URL_POSTFIX}`);
    return res;
};

export const getQCOrderDataApi = async action => {
    const res = await API.get(`${GET_QC_ORDER_DATA_URL}${action.invoice}`);
    return res;
};

export const partialSubmitQCApi = async action => {
    const res = await API.post(PARTIAL_SUBMIT_QC_URL, action.payload);
    return res;
};

export const submitQCApi = async action => {
    const res = await API.post(SUBMIT_QC_URL, action.payload);
    return res;
};

export const getQCSerialCheckApi = async action => {
    const res = await API.get(
        `${GET_QC_SERIAL_CHECK_URL}?momCode=${action.payload.momCode}&sku=${action.payload.sku}&serialNumber=${action.payload.serialNumber}`
    );
    return res;
};

export const startQCApi = async action => {
    const res = await API.put(
        `${GET_QC_ORDER_STATUS_URL_PREFIX}${action.invoice}${START_QC_URL_POSTFIX}`,
        action.payload
    );
    return res;
};

export const completeQCApi = async action => {
    const res = await API.put(
        `${GET_QC_ORDER_STATUS_URL_PREFIX}${action.invoice}${COMPLETE_QC_URL_POSTFIX}`,
        action.payload
    );
    return res;
};

export const abortQCApi = async action => {
    const res = await API.put(
        `${GET_QC_ORDER_STATUS_URL_PREFIX}${action.invoice}${ABORT_QC_URL_POSTFIX}`,
        action.payload
    );
    return res;
};

export const resetQCApi = async action => {
    const res = await API.put(
        `${GET_QC_ORDER_STATUS_URL_PREFIX}${action.invoice}${RESET_QC_URL_POSTFIX}`,
        action.payload
    );
    return res;
};
