import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import Barcode from 'react-barcode';

export const BatchIdBarcodeDialog = React.memo(
    ({
        batchId,
        handleOpenDialog,
        showDialog = false,
    }: {
        batchId: string;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm" onClose={handleClose}>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>BATCH ID BARCODE</Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Barcode value={batchId} />
                    </Box>
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
