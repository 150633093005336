import { TextField } from '@mui/material';

const ReadOnlyTextfield = ({ label, value }: { label: string; value: string | undefined }) => {
    return (
        <TextField
            label={label}
            value={value}
            variant="filled"
            sx={{ flex: 1, m: 1, minWidth: 300 }}
            InputProps={{
                readOnly: true,
            }}
        />
    );
};

export default ReadOnlyTextfield;
