import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_SKUS_SUCCESS, GET_SKUS_FAILURE, GET_SKUS } from '../store/actions/actionTypes';
import * as api from '../store/api/skuApi';

export function* getSkuForCompanyId(action) {
    try {
        const response = yield call(api.getSkuApi, action);

        yield put({
            type: GET_SKUS_SUCCESS,
            skuList: response.data,
        });
    } catch (error) {
        yield put({
            type: GET_SKUS_FAILURE,
            errorMessage: error,
        });
    }
}

export default function* skuSagaWatcher() {
    yield takeLatest(GET_SKUS, getSkuForCompanyId);
}
