import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Avatar } from '@mui/material';
import Typography from '@mui/material/Typography';
// import ArrowImage from '../../../assets/images/Arrow.png';
import {
    getTaskDetailsBasedOnSkuAction,
    getReviewTaskDetailsAction,
    getTasksByUserIdAndStateAction,
} from '../../store/actions/action';
import { useDispatch } from 'react-redux';
import { REVIEW_PAGE, PENDING_PAGE } from '../../../common/constants/stockCountTabs';

const StyledColumn = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('sm')]: {
        minWidth: 180,
    },
}));

const StockCountTask = ({ skuName, index, companyDetails, skuData, toggleDrawer, totalItems, tabType }) => {
    const dispatch = useDispatch();

    const onClickSkuDetailsHandler = useCallback(
        (skuName, skuData, companyDetails) => {
            if (tabType === REVIEW_PAGE) {
                dispatch(
                    getReviewTaskDetailsAction(
                        { sku: skuName, companyDetails: companyDetails.companyId, state: [3, 4] },
                        false
                    )
                );
                dispatch(getTasksByUserIdAndStateAction(skuData.assignedToUserId, [3]));
            } else {
                if (tabType === PENDING_PAGE) {
                    dispatch(
                        getTaskDetailsBasedOnSkuAction(
                            skuName,
                            skuData.assignedToUserId,
                            companyDetails.companyId,
                            tabType
                        )
                    );
                } else {
                    dispatch(
                        getTaskDetailsBasedOnSkuAction(skuName, skuData.assignedToUserId, companyDetails.companyId)
                    );
                }
            }
        },
        [tabType, dispatch]
    );

    return (
        <div>
            <Grid
                container
                style={{
                    padding: '10px 16px',
                    cursor: 'pointer',
                    borderBottom: index === totalItems - 1 ? 'none' : '2px solid #E0E2E5',
                }}
                onClick={() => {
                    toggleDrawer(true, skuData);
                    onClickSkuDetailsHandler(skuName, skuData, companyDetails);
                }}
            >
                <Grid item xs={4} style={{ alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <StyledColumn>{`${skuName} ${index === 1 ? '' : ''}`}</StyledColumn>
                    </div>
                </Grid>
                <Grid item xs={4} style={{ alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <Avatar
                            sx={{
                                marginRight: '10px',
                                backgroundColor: '#91F5E4',
                                color: '#5BD2BE',
                                height: 35,
                                width: 35,
                            }}
                        />
                        <StyledColumn>{skuData.assignedToUserName}</StyledColumn>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
                        {/* <div>
                            <img src={ArrowImage} alt="arrow" />
                        </div> */}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default StockCountTask;
