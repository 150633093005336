import { Box, Paper } from '@mui/material';
import { BatchOrderCountsChart } from './BatchOrderCountsChart';
import { BatchOrderTotalCountsPanel } from '../BatchOrderTotalCountsPanel';

const CALIFORNIA_LOCATION = 'California';
const OHIO_LOCATION = 'Ohio';

export const BatchOrderCharts = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: {
                    xs: 'column',
                    md: 'row',
                },
            }}
        >
            <Paper sx={{ flex: 1, p: 3, flexGrow: 1 }} elevation={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <BatchOrderTotalCountsPanel location={CALIFORNIA_LOCATION} />
                    <BatchOrderCountsChart location={CALIFORNIA_LOCATION} />
                </Box>
            </Paper>
            <Paper sx={{ flex: 1, ml: { sm: 0, md: 4, lg: 6 }, mt: { xs: 1, md: 0 }, p: 3 }} elevation={2}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <BatchOrderTotalCountsPanel location={OHIO_LOCATION} />
                    <BatchOrderCountsChart location={OHIO_LOCATION} />
                </Box>
            </Paper>
        </Box>
    );
};
