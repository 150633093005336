import { TextField } from '@mui/material';

export function ReadOnlyTextField({
    label,
    value,
    multiline = false,
}: {
    label: string;
    value: string | number | boolean | undefined | null;
    multiline?: boolean;
}) {
    var display_value = undefined;

    if (typeof value == 'boolean') {
        display_value = value ? 'Yes' : 'No';
    } else {
        display_value = value ? value : '-';
    }

    return (
        <TextField
            multiline={multiline}
            value={display_value}
            InputProps={{ readOnly: true, disableUnderline: true }}
            label={label}
            variant="filled"
            sx={{ mr: 1, mb: 1 }}
            size="small"
            fullWidth
        />
    );
}
