import React from 'react';
import { Company, CompanyWithStatus } from '../../../shipMatrixModel';
import { Paper, Typography, List, ListItem, ListItemIcon, Checkbox, ListItemText } from '@mui/material';

const CompanyList = ({ companies }: { companies: CompanyWithStatus[] }) => {
    const sortCompanyList = React.useCallback(
        (list: readonly CompanyWithStatus[]) => {
            let arrayToSort = [...list];
            const result = arrayToSort.sort((a, b) => {
                if (a.Mom < b.Mom) {
                    return -1;
                }
                if (a.Mom > b.Mom) {
                    return 1;
                }
                return 0;
            });

            return result;
        },
        [companies]
    );

    return (
        <Paper sx={{ m: 5 }} elevation={10}>
            {/* <Typography sx={{ textAlign: 'center', p: 1 }}>{'Companies'}</Typography> */}
            <List dense component="div" role="list">
                {sortCompanyList(companies).map((value: CompanyWithStatus) => {
                    const labelId = `company-list-item-${value}-label`;
                    const primaryText = () => {
                        if (value.Status === 'added') return `+ ${value.Name} (${value.Mom})`;
                        if (value.Status === 'removed') return `- ${value.Name} (${value.Mom})`;
                        return `${value.Name} (${value.Mom})`;
                    };

                    const textColor = () => {
                        if (value.Status === 'added') return `darkGreen`;
                        if (value.Status === 'removed') return `darkRed`;
                        return `black`;
                    };

                    return (
                        <ListItem key={value.Id} role="listitem">
                            <ListItemText id={labelId} primary={primaryText()} sx={{ color: textColor() }} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
};

export default CompanyList;
