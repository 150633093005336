import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import orderLookupApi from '../../orderLookupApi';
import { ReadOnlyTextField } from '../ReadOnlyTextField';

export const QcLogResetManifestDialog = React.memo(
    ({
        invoice,
        handleOpenDialog,
        showDialog = false,
    }: {
        invoice: string | undefined;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const userId = useAppSelector(state => state.authentication.authenticationResponse?.userInfo.id);
        const momCode = useAppSelector(state => state.orderLookup.momCode);

        const [triggerPutResetManifest] = orderLookupApi.endpoints.putResetManifest.useMutation();

        const confirmResetManifest = () => {
            if (!!invoice) {
                triggerPutResetManifest({ invoice: invoice, userId: userId, momCode: momCode });
            }
            handleClose();
        };

        const handleClose = () => {
            handleOpenDialog(false);
        };
        const resetWarningMessage = 'Are you sure want to Reset Manifest?';

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm" onClose={handleClose}>
                <DialogCloseButton handleClose={handleClose} />
                <Box sx={{ m: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <DialogTitle sx={{ flex: 4, m: 0, p: 1 }}>{resetWarningMessage}</DialogTitle>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <ReadOnlyTextField label={'Invoice'} value={invoice} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button sx={{ flex: 1, m: 0, p: 1 }} color="error" onClick={confirmResetManifest}>
                            RESET MANIFEST
                        </Button>
                        <Button sx={{ flex: 1, m: 0, p: 1 }} onClick={handleClose}>
                            CANCEL
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        );
    }
);
