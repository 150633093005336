import { Box, Button, Paper } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import useNavigationWarning from '../../../../../common/hooks/useNavigationWarning';
import useNotificationByMutationResponseV2 from '../../../../../common/hooks/useNotificationByMutationResponseV2';
import useNotificationByQueryResponse from '../../../../../common/hooks/useNotificationByQueryResponse';
import { setDialog } from '../../../../notification/notificationSlice';
import receiversApi from '../../../receiversApi';
import { overrideForm, resetAndOverrideForm, setMode } from '../../../receiversSlice';
import { fixForm, getActiveRules } from '../../../receiversUtil';
import EditHistory from '../../createDialog/formPartTwo/editHistory/EditHistory';
import CreatedReceiverSpeedDial from '../../tabbedView/CreatedReceiverSpeedDial';
import CommentField from './CommentField';
import Header from './Header';
import Items from './Items';
import LaborTime from './LaborTime';
import ScoreCard from './ScoreCard';
import TextFields from './TextFields';

const DialogForm = ({
    hasAsn = false,
    handleExitForm = () => {},
}: {
    hasAsn?: boolean;
    handleExitForm?: () => void;
}) => {
    const mode = useAppSelector(state => state.receivers.mode);
    const form = useAppSelector(state => state.receivers.form);
    const dispatch = useAppDispatch();
    const username = useAppSelector(state => state.authentication.username);

    const getReceiversConfigResponse = receiversApi.endpoints.getReceiversConfig.useQuery(
        form.Client ? form.Client : skipToken
    );

    useNotificationByQueryResponse({
        response: getReceiversConfigResponse,
        errorMessage: 'Could not load receiver client config',
    });

    const formFieldsWithOptions = JSON.stringify(
        getReceiversConfigResponse?.data?.Rules?.filter(rule => rule.Options?.length > 0)
            .map(rule => rule.Label)
            .map(property => form?.[property] || '')
    );

    const activeRules = React.useMemo(() => {
        return getActiveRules(getReceiversConfigResponse?.data, form);
    }, [getReceiversConfigResponse, formFieldsWithOptions]);

    useNavigationWarning({ shouldShowWarningOnNavigationChange: true });

    useEffect(() => {
        if (!form.RecBy) {
            dispatch(setMode('Create'));
            if (hasAsn) dispatch(overrideForm({ RecBy: username }));
            else dispatch(resetAndOverrideForm({ RecBy: username }));
        }
    }, [form.RecBy, username]);

    const [triggerFormCreate, createResponse] = receiversApi.endpoints.createReceiver.useMutation();

    useNotificationByMutationResponseV2({
        response: createResponse,
        errorMessage: 'Failed to create',
        successMessage: 'Successfully created',
        finishedFunction: handleExitForm,
    });

    const [triggerFormUpdate, updateResponse] = receiversApi.endpoints.updateReceiver.useMutation();

    useNotificationByMutationResponseV2({
        response: createResponse,
        errorMessage: 'Failed to update',
        successMessage: 'Successfully updated',
        finishedFunction: handleExitForm,
    });

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        const itemWhereTryingToAddLessThanAvailable = form.Items.find(item => {
            const availableStockCountOfItem = item?.Available || 0;
            return item.RecQty + availableStockCountOfItem < 0;
        });

        if (form.Items.length === 0) {
            dispatch(setDialog({ content: `At least one item is required for submission`, title: `Item Needed` }));
        } else if (form.Items.some(item => item.RecQty === 0)) {
            dispatch(setDialog({ content: `An added item can not have a quantity of zero`, title: `Invalid Item` }));
        } else if (itemWhereTryingToAddLessThanAvailable) {
            dispatch(
                setDialog({
                    content: `Can not subtract less than available stock: ${itemWhereTryingToAddLessThanAvailable.Item}`,
                    title: `Invalid Item`,
                })
            );
        } else {
            const fixedForm = fixForm(form);
            if (mode === 'Edit') {
                triggerFormUpdate({ payload: fixedForm });
            } else {
                triggerFormCreate({ payload: fixedForm });
            }
            dispatch(setMode('View'));
        }
    };

    return (
        <Box sx={{ mt: 5 }}>
            <Header />
            <form onSubmit={handleFormSubmit}>
                <Paper sx={{ p: 2 }} elevation={5}>
                    <TextFields hasAsn={hasAsn} activeRules={activeRules} />

                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, mr: 3 }}>
                            <LaborTime activeRules={activeRules} />
                            <ScoreCard activeRules={activeRules} />
                        </Box>

                        <CommentField rule={activeRules.Comment} />
                    </Box>

                    <Items hasAsn={hasAsn} />

                    {mode !== 'Create' && <EditHistory />}

                    {mode !== 'View' && (
                        <Button
                            data-testid="final-submit-button"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, width: '100%' }}
                            type="submit"
                        >
                            {mode === 'Edit' ? 'Update' : 'Create'}
                        </Button>
                    )}
                </Paper>
            </form>

            <CreatedReceiverSpeedDial />
        </Box>
    );
};

export default DialogForm;
