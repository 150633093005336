import { Box } from '@mui/material';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import qualityControlApi from '../../qualityControl/qualityControlApi';
import AddSerialDialog from './AddSerialDialog';
import AddSerialForm from './AddSerialForm';

const AddSerialElement = () => {
    const [triggerGetQCData, getQCDataResponse] = qualityControlApi.endpoints.getOrderData.useLazyQuery();

    useNotificationByResponse({
        isFetching: getQCDataResponse.isFetching,
        isError: getQCDataResponse.isError,
        errorContent: 'Invalid Invoice Number',
        isUninitialized: getQCDataResponse.isUninitialized,
    });

    const handleInvoiceSubmit = (invoiceNumber: string) => {
        triggerGetQCData(invoiceNumber);
    };

    return (
        <Box sx={{ backgroundColor: '#FFF', p: 4 }}>
            <AddSerialDialog handleSubmit={handleInvoiceSubmit} isHidden={!!getQCDataResponse.data} />
            {getQCDataResponse.data && <AddSerialForm responseData={getQCDataResponse.data} />}
        </Box>
    );
};

export default AddSerialElement;
