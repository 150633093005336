import { Button, TextField, Toolbar } from '@mui/material';
import React from 'react';
// import { lighten } from '@mui/material/styles';
import { isFeatureAccessible } from '../../../common/utils/commonUtils';
import * as permissions from '../permissionsList';

// const useToolbarStyles = makeStyles(theme => ({
//     root: {
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(1),
//     },
//     highlight:
//         theme.palette.mode === 'light'
//             ? {
//                   color: theme.palette.primary.main,
//                   backgroundColor: lighten(theme.palette.primary.light, 0.85),
//               }
//             : {
//                   color: theme.palette.text.primary,
//                   backgroundColor: theme.palette.primary.dark,
//               },
//     title: {
//         flex: '1 1 100%',
//     },
// }));

const EnhancedTableToolbar = ({ setIsAddingRole, searchText, setSearchText, setSelectedRole, userPermissions }) => {
    //   const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <div style={{ flex: 1, marginRight: 20 }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="Search Role"
                        hiddenLabel
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        value={searchText}
                        onChange={({ target: { value } }) => setSearchText(value)}
                    />
                </div>
                {isFeatureAccessible(userPermissions, permissions.ROLE_CREATE) && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSelectedRole(null);
                            setIsAddingRole(true);
                        }}
                    >
                        Add Role
                    </Button>
                )}
            </div>
        </Toolbar>
    );
};

export default EnhancedTableToolbar;
