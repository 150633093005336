import { useEffect } from 'react';
import { NotificationByResponsePayload } from '../../features/notification/notificationModels';
import { useAppDispatch, useAppSelector } from '../../app/store';
import {
    decrementIsNonIntrusiveSpinnerOpen,
    decrementIsSpinnerOpen,
    incrementIsNonIntrusiveSpinnerOpen,
    incrementIsSpinnerOpen,
    updateNotificationByResponse,
} from '../../features/notification/notificationSlice';

export default function useNotificationByResponse(
    payload: NotificationByResponsePayload,
    finishedFunction: () => void = () => {}
): void {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (payload.isUninitialized !== undefined && !payload.isUninitialized) {
            if (payload.isNonIntrusive) {
                if (payload.isFetching) dispatch(incrementIsNonIntrusiveSpinnerOpen());
                else dispatch(decrementIsNonIntrusiveSpinnerOpen());
            } else {
                if (payload.isFetching) {
                    dispatch(incrementIsSpinnerOpen());
                } else {
                    dispatch(decrementIsSpinnerOpen());
                }
            }
        }

        //when get response
        if (!payload.isUninitialized && !payload.isFetching) {
            dispatch(
                updateNotificationByResponse({
                    isFetching: payload.isFetching,
                    isError: payload.isError,
                    errorContent: payload.errorContent,
                    isSuccess: payload.isSuccess,
                    successContent: payload.successContent,
                    isNonIntrusive: payload.isNonIntrusive || true,
                    isUninitialized: payload.isUninitialized,
                })
            );

            if (payload.isError || payload.isSuccess) {
                finishedFunction();
            }
        }
    }, [payload.isFetching]);
}
