import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReceivedItem } from '../../rmaModels';
import { ReceivedItemSummaryPanel } from './ReceivedItemSummaryPanel';

export const UnexpectedItemsDisplay = ({ unexpectedItems }: { unexpectedItems: ReceivedItem[] }) => {
    const isNewRma = unexpectedItems.length == 0 || !unexpectedItems[0].RmaId;
    const unExpectedItemTitle = isNewRma ? 'NEW RMA ITEMS' : 'UNEXPECTED ITEMS';
    const titleColor = isNewRma ? 'primary.main' : 'warning.main';

    return (
        <React.Fragment>
            {unexpectedItems.length > 0 ? (
                <React.Fragment>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <Typography fontWeight={'fontWeightBold'} sx={{ color: titleColor }} variant="h5">
                            {unExpectedItemTitle}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {unexpectedItems.map(item => (
                            <ReceivedItemSummaryPanel receivedItem={item} />
                        ))}
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment />
            )}
        </React.Fragment>
    );
};
