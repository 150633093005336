import { Box, Card, TextField, Typography } from '@mui/material';
import React, { FC, memo } from 'react';
import { SubmissionFormItem } from '../../../../receiversModels';
import ItemCardNumberTextfield from './ItemCardNumberTextfield';

interface ItemCardProps {
    item?: SubmissionFormItem;
    setItem: React.Dispatch<React.SetStateAction<SubmissionFormItem | undefined>>;
    hasAsn: boolean;
}

const ControlledItemCard: FC<ItemCardProps> = memo(({ item, setItem, hasAsn }) => {
    return (
        <Card variant="outlined" sx={{ p: 1, m: 2 }} key={item?.Item}>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="body1" sx={{ flex: 1 }}>
                    {!item ? `Valid UPC Required` : `${item?.Item} | ${item?.Descript}`}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', mt: 2 }}>
                <TextField
                    sx={{ flex: 1, display: hasAsn ? '' : 'none' }}
                    value={item?.LineId || 'N/A'}
                    disabled
                    label={'Line ID'}
                    variant="outlined"
                />

                <ItemCardNumberTextfield
                    value={item?.RecCrtncount || 0}
                    onChange={newValue => {
                        setItem(prevItem => {
                            if (!prevItem) return prevItem;
                            const newRecQty = newValue * prevItem?.RecCaseqty + prevItem?.RecPartial;
                            return {
                                ...prevItem,
                                RecQty: newRecQty,
                                RecCrtncount: newValue,
                            };
                        });
                    }}
                    textFieldProps={{
                        label: '# per Ctn',
                    }}
                />

                <ItemCardNumberTextfield
                    value={item?.RecCaseqty || 0}
                    onChange={newValue => {
                        setItem(prevItem => {
                            if (!prevItem) return prevItem;
                            const newRecQty = prevItem.RecCrtncount * newValue + prevItem.RecPartial;

                            return {
                                ...prevItem,
                                RecQty: newRecQty,
                                RecCaseqty: newValue,
                            };
                        });
                    }}
                    textFieldProps={{
                        label: '# of Ctns',
                    }}
                />

                <ItemCardNumberTextfield
                    value={item?.RecPartial || 0}
                    onChange={newValue => {
                        setItem(prevItem => {
                            if (!prevItem) return prevItem;
                            const newRecQty = prevItem.RecCrtncount * prevItem.RecCaseqty + newValue;

                            return {
                                ...prevItem,
                                RecQty: newRecQty,
                                RecPartial: newValue,
                            };
                        });
                    }}
                    textFieldProps={{
                        label: 'Loose Qty',
                    }}
                />

                <TextField
                    sx={{ flex: 1 }}
                    value={item?.RecQty || 0}
                    disabled
                    label={'Total'}
                    variant="outlined"
                    type="number"
                />

                <TextField
                    sx={{ flex: 1, display: hasAsn ? '' : 'none' }}
                    value={item?.PlQty || 0}
                    disabled
                    label={'Expected'}
                    variant="outlined"
                    type="number"
                />

                <TextField
                    sx={{ flex: 1, display: hasAsn ? '' : 'none' }}
                    value={(item?.RecQty || 0) - (item?.PlQty || 0)}
                    disabled
                    label={'Difference'}
                    variant="outlined"
                    type="number"
                />
            </Box>

            <TextField
                sx={{ width: '100%', mt: 1 }}
                label={'Notes'}
                variant="outlined"
                value={item?.Notes || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    setItem(prevItem => {
                        if (!prevItem) return prevItem;
                        return { ...prevItem, Notes: newValue };
                    });
                }}
            />
        </Card>
    );
});

export default ControlledItemCard;
