import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../../common/components/Dialog/DialogCloseButton';
import { ReceivedItem, ReceivedItemSerial } from '../../../rmaModels';
import { BulkScanSerialsDisplay } from './BulkScanSerialsDisplay';

export const BulkScanSerialsDialog = React.memo(
    ({
        receivedItem,
        setReceivedItem,
        scannedSerials,
        setScannedSerials,
        handleOpenDialog,
        showDialog = false,
    }: {
        receivedItem: ReceivedItem;
        setReceivedItem: (item: ReceivedItem) => void;
        scannedSerials: ReceivedItemSerial[];
        setScannedSerials: (serials: ReceivedItemSerial[]) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm" onClose={handleClose}>
                <DialogTitle color="white" bgcolor="primary.main" align="center" sx={{ px: 3, pt: 3 }}>
                    SCAN SERIAL
                </DialogTitle>
                <DialogContent>
                    <BulkScanSerialsDisplay
                        receivedItem={receivedItem}
                        setReceivedItem={setReceivedItem}
                        scannedSerials={scannedSerials}
                        setScannedSerials={setScannedSerials}
                    />
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" size="large" fullWidth onClick={handleClose}>
                            {scannedSerials.length > 0 ? 'DONE' : 'CLOSE'}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
