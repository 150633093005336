import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, SxProps, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../../app/store';
import { Rule } from '../../../receiversModels';
import { RECEIVERS_DIALOG_DEFAULT_TEXTFIELD_STYLING } from '../../../styles';

const ReceiverDialogTextField = ({
    value,
    onChange,
    label = ' ',
    textFieldProps = {},
    disabled = false,
    options,
    regex = '',
    sx = RECEIVERS_DIALOG_DEFAULT_TEXTFIELD_STYLING,
    rule = {
        Regex: '',
        IsDisabled: false,
        IsRequired: false,
    },
    shouldClearOnDisable = true,
}: {
    value: string;
    onChange: (input: string) => void;
    label?: string;
    textFieldProps?: TextFieldProps;
    options?: string[];
    regex?: string;
    disabled?: boolean;
    sx?: SxProps;
    rule?: Rule;
    shouldClearOnDisable?: boolean;
}) => {
    const mode = useAppSelector(state => state.receivers.mode);
    const isReadOnly = mode === 'View';
    const defaultTextFieldValue = isReadOnly ? 'N/A' : '';

    const combinedRegex = regex || rule.Regex;
    const combinedIsDisabled = rule.IsDisabled || disabled;
    const combinedIsRequired = rule.IsRequired || textFieldProps?.required;

    React.useEffect(() => {
        if (shouldClearOnDisable && combinedIsDisabled) onChange('');
    }, [combinedIsDisabled]);

    const doesValuePassRegex = () => {
        if (!combinedRegex || !value || isReadOnly || combinedIsDisabled) return true;

        const castedRegex = new RegExp(combinedRegex);
        return castedRegex.test(value);
    };

    return options ? (
        <Autocomplete
            disablePortal
            options={options.sort()}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="filled"
                    helperText=" "
                    {...textFieldProps}
                    error={!doesValuePassRegex() || textFieldProps.error}
                    required={combinedIsDisabled ? false : combinedIsRequired}
                />
            )}
            value={value || defaultTextFieldValue}
            onChange={(event: any, newValue: string | null) => {
                onChange(newValue || '');
            }}
            sx={sx}
            readOnly={isReadOnly}
            isOptionEqualToValue={(option, value) => value === option}
            popupIcon={isReadOnly ? '' : <ArrowDropDownIcon />}
            disabled={combinedIsDisabled}
            noOptionsText={'Company selection required first'}
        />
    ) : (
        <TextField
            value={value || defaultTextFieldValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value);
            }}
            label={label}
            variant="filled"
            InputProps={{
                readOnly: isReadOnly,
            }}
            sx={sx}
            disabled={combinedIsDisabled}
            helperText={doesValuePassRegex() ? ' ' : 'Invalid pattern'}
            {...textFieldProps}
            error={!doesValuePassRegex() || textFieldProps.error}
            required={combinedIsDisabled ? false : combinedIsRequired}
        />
    );
};

export default ReceiverDialogTextField;
