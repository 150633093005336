import { Navigate } from 'react-router';
import { Spinner } from '../../common/components';
import authenticationApi from '../authentication/authenticationApi';

const NotFound = () => {
    const getUserPermissionsResponse = authenticationApi.endpoints.getUserPermissions.useQuery();

    return getUserPermissionsResponse.isFetching ? <Spinner /> : <Navigate to="/" />;
};

export default NotFound;
