import ConfirmDialog from '../../../../common/components/Dialog/ConfirmDialog';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import useNotificationByQueryResponse from '../../../../common/hooks/useNotificationByQueryResponse';
import receiversApi from '../../receiversApi';
import { PlayReceiverPayload, PlayReceiverPayloadItem } from '../../receiversModels';

const PlayReceiverConfirmDialog = ({
    receiverToPlay,
    handleCancel,
}: {
    receiverToPlay: { client: string; number: number };
    handleCancel: () => void;
}) => {
    const [triggerPlayReceivers, playReceiversResponse] = receiversApi.endpoints.playReceiver.useMutation();
    const getReceiverItemsResponse = receiversApi.endpoints.getReceiverItem.useQuery(receiverToPlay.number);

    useNotificationByQueryResponse({
        response: getReceiverItemsResponse,
        errorMessage: 'Could not get data to play receiver',
    });

    useNotificationByMutationResponseV2({
        response: playReceiversResponse,
        errorMessage: 'Unable to play receiver: ' + JSON.stringify(playReceiversResponse.error),
        successMessage: 'Receiver was played successfully',
        finishedFunction: handleCancel,
    });

    const handlePlayReceiverConfirmationButtonClicked = () => {
        const items = getReceiverItemsResponse.data?.map(
            (item): PlayReceiverPayloadItem => {
                return { Id: item.Id || -1, Sku: item.Item, RecQty: item.RecQty };
            }
        );
        const result: PlayReceiverPayload = {
            MomCode: receiverToPlay.client,
            RecNumber: receiverToPlay.number || 0,
            Items: items || [],
        };

        triggerPlayReceivers({ momCode: result.MomCode, body: result });
    };

    return (
        <ConfirmDialog
            open={!!receiverToPlay}
            handleCancel={handleCancel}
            handleConfirm={handlePlayReceiverConfirmationButtonClicked}
            title={'Play Receiver'}
            content={`Are you sure you want to play receiver #${receiverToPlay.number}?`}
        />
    );
};

export default PlayReceiverConfirmDialog;
