import Box from '@mui/material/Box';
import React from 'react';
import { QcLogSpeedDialDialog } from '../QcLog/QcLogSpeedDialDialog';
import OrderLookupSpeedDial from './SpeedDialMenu';

const SpeedDialElement = () => {
    const [qcLogDialogOpen, setQcLogDialogOpen] = React.useState(false);

    return (
        <Box>
            <QcLogSpeedDialDialog setQcLogDialogOpen={setQcLogDialogOpen} showDialog={qcLogDialogOpen} />
            <OrderLookupSpeedDial handleQcLogOpenDialog={setQcLogDialogOpen} />
        </Box>
    );
};

export default SpeedDialElement;
