import { Box, Dialog, DialogTitle } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { HistoryMetadata } from '../../orderLookupModels';

// Adds id attribute to HistoryMetadata objects to work with DataGrid
interface GridHistoryMetadata extends HistoryMetadata {
    id: number; // DataGrid needs id attribute for each element.
}

export const HistoryMetadataDialog = React.memo(
    ({
        metadatas,
        dialogTitle,
        showDialog = false,
        handleOpenDialog,
    }: {
        metadatas?: HistoryMetadata[];
        dialogTitle: string;
        showDialog?: boolean;
        handleOpenDialog: (openState: boolean) => void;
    }) => {
        const gridhistoryMetadataData: GridHistoryMetadata[] = [];
        metadatas?.map((metadata: HistoryMetadata, index: number) => {
            gridhistoryMetadataData.push({ ...metadata, id: index + 1 });
        });

        const handleClose = () => {
            handleOpenDialog(false);
        };

        const columns: GridColDef[] = [
            {
                align: 'left',
                headerAlign: 'left',
                field: 'Key',
                headerName: 'Key',
                flex: 1,
            },
            {
                align: 'left',
                headerAlign: 'left',
                field: 'Value',
                headerName: 'Value',
                flex: 2,
                renderCell: params => (
                    <Box
                        sx={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        {params.row.Value}
                    </Box>
                ),
            },
        ];

        return (
            <Dialog open={showDialog} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <Box sx={{ m: 3 }}>
                    <DialogCloseButton handleClose={handleClose} />
                    <DataGrid
                        rows={gridhistoryMetadataData || []}
                        columns={columns}
                        disableRowSelectionOnClick
                        hideFooter
                    />
                </Box>
            </Dialog>
        );
    }
);
