import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import { OrderData } from '../qualityControl/qualityControlModels';
import { FRONT_END_VERSION, PROJECT_NAME } from '../version/version';
import {
    CheckSerialRequest,
    CheckSerialResponse,
    GetPackageIdRequest,
    GetPackageIdResponse,
    QualityControlPutRequest,
    QualityControlRequest,
    QualityControlStatusResponse,
    QualityControlSubmitRequest,
} from './serializedBatchShippingModels';

const apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ['QualityControlOrderData', 'QualityControlStatus'],
});

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getPackageId: builder.query<GetPackageIdResponse, GetPackageIdRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/pkgid`)}`,
                method: 'GET',
                params: { TrackingNumber: request.TrackingNumber },
            }),
        }),

        getOrderStatus: builder.query<QualityControlStatusResponse, QualityControlRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/order/${request.PackageId}/status`)}`,
                method: 'GET',
            }),
            providesTags: ['QualityControlStatus'],
        }),

        getOrderData: builder.query<OrderData, QualityControlRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/order/${request.PackageId}`)}`,
                method: 'GET',
            }),
            providesTags: ['QualityControlOrderData'],
        }),

        startQualityControl: builder.mutation<QualityControlStatusResponse, QualityControlPutRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/order/${request.PackageId}/start`)}`,
                method: 'PUT',
                body: {
                    UserId: request.UserId,
                    System: PROJECT_NAME,
                    QCVersion: FRONT_END_VERSION,
                },
            }),
            invalidatesTags: ['QualityControlStatus'],
        }),

        submitQualityControl: builder.mutation<QualityControlStatusResponse, QualityControlSubmitRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/submit`)}`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['QualityControlOrderData'],
        }),

        completeQualityControl: builder.mutation<QualityControlStatusResponse, QualityControlPutRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/order/${request.PackageId}/complete`)}`,
                method: 'PUT',
                body: {
                    UserId: request.UserId,
                    System: PROJECT_NAME,
                    QCVersion: FRONT_END_VERSION,
                },
            }),
            invalidatesTags: ['QualityControlStatus'],
        }),

        getIsSerialValid: builder.query<boolean, CheckSerialRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/checkSerial`)}`,
                method: 'GET',
                params: request,
                invalidatesTags: ['QualityControlOrderData'],
            }),
            transformResponse: (response: CheckSerialResponse): boolean => {
                return response?.SerialUseRecords?.length === 0;
            },
        }),
    }),
    overrideExisting: true,
});
