const {
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    GET_ROLE_SUCCESS,
    GET_ROLE_FAILURE,
    SET_ROLES_LOADING,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_FAILURE,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAILURE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,
    RESET_ROLE_SUCCESS_STATUS,
    EDIT_USER_ROLE_FAILURE,
    EDIT_USER_ROLE_SUCCESS,
    SET_ROLE_MODAL_LOADING,
    GET_ALL_ROLE_PERMISSION_SUCCESS,
    GET_ALL_ROLE_PERMISSION_FAILURE,
    GET_UNASSIGNED_ROLE_PERMISSIONS_SUCCESS,
    GET_UNASSIGNED_ROLE_PERMISSIONS_FAILURE,
    GET_ASSIGNED_ROLE_PERMISSIONS_SUCCESS,
    GET_ASSIGNED_ROLE_PERMISSIONS_FAILURE,
    EDIT_ROLE_PERMISSIONS_SUCCESS,
    EDIT_ROLE_PERMISSIONS_FAILURE,
    SET_PERMISSIONS_LOADING,
} = require('../store/actions/actionTypes');

export const initialState = {
    usersList: [],
    rolesList: [],
    allPermissions: [],
    unAssignedPermissions: [],
    assignedPermissions: [],
    errorMessage: '',
    isLoading: false,
    success: false,
    successMessage: '',
    permissionSuccess: false,
    permissionSuccessMessage: '',
    isModalLoading: false,
    isPermissionLoading: false,
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLES_LOADING:
            return { ...state, isLoading: true };
        case SET_ROLE_MODAL_LOADING:
            return { ...state, isModalLoading: true };
        case SET_PERMISSIONS_LOADING:
            return { ...state, isPermissionLoading: true };
        case GET_ALL_USERS_SUCCESS:
            return { ...state, usersList: action.payload, isLoading: false };
        case GET_ALL_USERS_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isLoading: false };
        case GET_ROLE_SUCCESS:
            return { ...state, rolesList: action.payload, isLoading: false };
        case GET_ROLE_FAILURE:
            return { ...state, rolesList: [], errorMessage: action.errorMessage, isLoading: false };
        case ADD_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: 'Role has been added successfully',
                isModalLoading: false,
            };
        case ADD_ROLE_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isModalLoading: false };
        case UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                // successMessage:'Role has been updated successfully',
                isModalLoading: false,
            };
        case UPDATE_ROLE_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isModalLoading: false };
        case DELETE_ROLE_SUCCESS:
            return { ...state, success: true, successMessage: 'Role has been deleted successfully', isLoading: false };
        case DELETE_ROLE_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isModalLoading: false };
        case EDIT_USER_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage:
                    action.payload?.actionType === 'ASSIGNED'
                        ? `Role has been assigned to the users successfully`
                        : 'Users have been un-assigned from the roles successfully',
                isLoading: false,
            };
        case EDIT_USER_ROLE_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isLoading: false };
        case GET_ALL_ROLE_PERMISSION_SUCCESS:
            return { ...state, allPermissions: action.payload, isModalLoading: false };
        case GET_ALL_ROLE_PERMISSION_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isModalLoading: false, allPermissions: [] };
        case GET_UNASSIGNED_ROLE_PERMISSIONS_SUCCESS:
            return { ...state, unAssignedPermissions: action.payload, isModalLoading: false };
        case GET_UNASSIGNED_ROLE_PERMISSIONS_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isModalLoading: false, unAssignedPermissions: [] };
        case GET_ASSIGNED_ROLE_PERMISSIONS_SUCCESS:
            return { ...state, assignedPermissions: action.payload, isModalLoading: false };
        case GET_ASSIGNED_ROLE_PERMISSIONS_FAILURE:
            return { ...state, errorMessage: action.errorMessage, isModalLoading: false, assignedPermissions: [] };
        case EDIT_ROLE_PERMISSIONS_SUCCESS:
            return {
                ...state,
                isPermissionLoading: false,
                permissionSuccess: true,
                permissionSuccessMessage: 'Permissions have been assigned successfully',
                isModalLoading: false,
                ...action.payload.meta,
            };
        case EDIT_ROLE_PERMISSIONS_FAILURE:
            return {
                ...state,
                errorMessage: action.errorMessage,
                isPermissionLoading: false,
                permissionSuccess: false,
                permissionSuccessMessage: '',
            };
        case RESET_ROLE_SUCCESS_STATUS:
            return {
                ...state,
                success: false,
                successMessage: '',
                error: false,
                errorMessage: '',
                isLoading: false,
                isModalLoading: false,
                permissionSuccess: false,
                permissionSuccessMessage: '',
            };
        default:
            break;
    }
    return state;
};
export default rolesReducer;
