import { TextField, Typography } from '@mui/material';
import React from 'react';
import { Arrow } from 'react-konva';
import { Html } from 'react-konva-utils';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import { ESCAPE_KEY, RETURN_KEY } from '../../../../common/utils/constants';
import { CreatedNextInstructionMapRecord, DraggableInstruction } from '../../models';
import ProcedureApi from '../../ProcedureApi';
import { getArrowPoints } from '../../util';

interface NextInstructionArrowProps {
    firstInstruction: DraggableInstruction;
    secondInstruction?: DraggableInstruction;
    isSelected: boolean;
    existingEdge?: CreatedNextInstructionMapRecord;
    onDoubleClick?: () => void;
    onFinishedUpsert: () => void;
}

const NextInstructionArrow = ({
    firstInstruction,
    secondInstruction,
    isSelected,
    existingEdge,
    onDoubleClick = () => {},
    onFinishedUpsert,
}: NextInstructionArrowProps) => {
    if (!secondInstruction) return;
    const arrowPoints = getArrowPoints(firstInstruction, secondInstruction);

    const [newLabel, setNewLabel] = React.useState(existingEdge?.Label || '');

    const [upsertTrigger, upsertResponse] = ProcedureApi.endpoints.upsertNextInstructionMap.useMutation();
    const [deleteTrigger, deleteResponse] = ProcedureApi.endpoints.deleteNextInstructionMap.useMutation();

    const handleTextInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!((event.keyCode === RETURN_KEY && !event.shiftKey) || event.keyCode === ESCAPE_KEY)) return;

        if (!newLabel && existingEdge?.Id) {
            deleteTrigger(existingEdge.Id);
        } else if (!newLabel) {
            onFinishedUpsert();
            return;
        }

        if (existingEdge) {
            upsertTrigger({
                ...existingEdge,
                CurrentInstructionId: firstInstruction.Id,
                Label: newLabel,
            });
        } else {
            upsertTrigger({
                CurrentInstructionId: firstInstruction.Id,
                NextInstructionId: secondInstruction.Id,
                Label: newLabel,
            });
        }
    };

    useNotificationByMutationResponseV2({ response: upsertResponse, finishedFunction: onFinishedUpsert });
    useNotificationByMutationResponseV2({ response: deleteResponse, finishedFunction: onFinishedUpsert });

    const arrowColor = isSelected ? 'orange' : 'gray';

    return (
        <>
            <Arrow
                points={arrowPoints}
                stroke={arrowColor}
                fill={arrowColor}
                pointerLength={50}
                pointerWidth={50}
                strokeWidth={5}
            />
            <Html
                divProps={{
                    style: { pointerEvents: 'none' },
                }}
                groupProps={{
                    x: (arrowPoints[0] + arrowPoints[2]) / 2,
                    y: (arrowPoints[1] + arrowPoints[3]) / 2,
                }}
            >
                {isSelected ? (
                    <TextField
                        label="New Label"
                        value={newLabel}
                        variant="filled"
                        autoFocus
                        onChange={event => {
                            const newValue = event.target.value;
                            setNewLabel(newValue);
                        }}
                        sx={{ backgroundColor: 'white' }}
                        onKeyDown={handleTextInputKeyDown}
                    />
                ) : (
                    <Typography sx={{ background: 'white', p: 1, pointerEvents: 'auto' }} onDoubleClick={onDoubleClick}>
                        {existingEdge?.Label}
                    </Typography>
                )}
            </Html>
        </>
    );
};

export default NextInstructionArrow;
