import { Box, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, QueryDefinition } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/store';
import { CompanyDropdownV2 } from '../../../../common/components/dropdown/CompanyDropdownV2';
import UserDropdown from '../../../../common/components/dropdown/UserDropdown';
import DateRangePickerPopover from '../../../../common/components/popover/DateRangePickerPopover';
import { stringToBoolean } from '../../../../common/utils/typedCommonUtils';
import { FILTER_MAX_ROWS_COUNT } from '../../constants';
import { Filter, SummarizedWorkOrderData } from '../../workOrderModels';
import TemplateTypeDropdown from '../template/TemplateTypeDropdown';

interface WorkOrderFilterProps {
    triggerGetWorkOrders: LazyQueryTrigger<
        QueryDefinition<
            Filter,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
            'WorkOrderViewList' | 'WorkOrderIndividual',
            SummarizedWorkOrderData[],
            'api'
        >
    >;
    handleFilterButtonClick: () => void;
}

const workOrderInitialForm: Filter = {
    IsDeleted: false,
    MaxRowsCount: FILTER_MAX_ROWS_COUNT,
    DateCreatedStart: dayjs().startOf('month').format(),
    DateCreatedEnd: dayjs().endOf('day').format(),
    TemplateTypeId: 1,
};

const templateInitialForm: Filter = {
    IsDeleted: false,
    TemplateTypeId: 2,
    MaxRowsCount: FILTER_MAX_ROWS_COUNT,
};

const maxDurationDaysOfRangePicker = 90;

const searchParamsToFilter = (searchParams: URLSearchParams): Filter => {
    const id = Number(searchParams.get('Id'));

    return {
        ...Object.fromEntries(searchParams),
        IsDeleted: stringToBoolean(searchParams.get('IsDeleted')),
        TemplateTypeId: Number(searchParams.get('TemplateTypeId')),
        MaxRowsCount: Number(searchParams.get('MaxRowsCount')),
        Id: id === 0 ? undefined : id,
    } as Filter;
};

const WorkOrderFilter = ({ triggerGetWorkOrders, handleFilterButtonClick }: WorkOrderFilterProps) => {
    const tab = useAppSelector(state => state.workOrder.tab);
    const fullName = useAppSelector(state => state.authentication.fullName);

    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = React.useState<Filter>(searchParamsToFilter(searchParams));
    const navigate = useNavigate();

    const [shouldShowError, setShouldShowError] = React.useState(false);

    const initialFilter = React.useMemo(() => (tab === 'Work Orders' ? workOrderInitialForm : templateInitialForm), [
        tab,
    ]);

    React.useEffect(() => {
        setFilter(tab === 'Work Orders' ? workOrderInitialForm : templateInitialForm);
    }, [tab]);

    React.useEffect(() => {
        const isSearchParamsEmpty = searchParams.size === 0;
        const filterToSearch = isSearchParamsEmpty ? initialFilter : searchParamsToFilter(searchParams);

        setFilter(filterToSearch);

        triggerGetWorkOrders(filterToSearch);
    }, [searchParams]);

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleFilterButtonClick();

        if (!filter.DateCreatedStart && !filter.DateCreatedEnd && tab === 'Work Orders') {
            setShouldShowError(true);
            return;
        }

        setShouldShowError(false);

        if (!!filter.Id)
            navigate({
                pathname: `/workOrder/${filter.Id}`,
                search: searchParams.toString(),
            });
        else setSearchParams(filter as URLSearchParams);
    };

    return (
        <Box>
            <form onSubmit={handleFormSubmit}>
                <Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <CompanyDropdownV2
                            setCompany={newValue => {
                                setFilter(prevState => ({
                                    ...prevState,
                                    MomCode: newValue,
                                }));
                            }}
                            startingMomCode={filter.MomCode}
                            label={'Company'}
                            sx={{ flex: 3, m: 1 }}
                        />

                        <TextField
                            variant="outlined"
                            label="WO #"
                            value={filter.Id?.toString() || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = parseInt(event.target.value);
                                if (event.target.value === '')
                                    setFilter(prevState => ({
                                        ...prevState,
                                        Id: undefined,
                                    }));
                                else if (newValue || newValue === 0)
                                    setFilter(prevState => ({
                                        ...prevState,
                                        Id: newValue,
                                    }));
                            }}
                            type="number"
                            sx={{ flex: 1, m: 1 }}
                        />

                        <TextField
                            variant="outlined"
                            label="Project Title Name"
                            value={filter.ProjectTitle || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = event.target.value;
                                setFilter(prevState => ({
                                    ...prevState,
                                    ProjectTitle: newValue,
                                }));
                            }}
                            sx={{ flex: 3, m: 1 }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', my: 2 }}>
                        <UserDropdown
                            setName={newValue => {
                                setFilter(prevState => ({
                                    ...prevState,
                                    RoRequester: newValue,
                                }));
                            }}
                            label={'Requester'}
                            startingName={filter.RoRequester}
                            testId="wo-filter-user-requester-dropdown"
                            sx={{ flex: 1, m: 1 }}
                        />

                        <UserDropdown
                            setName={newValue => {
                                setFilter(prevState => ({
                                    ...prevState,
                                    AssignedTo: newValue,
                                }));
                            }}
                            label={'Assigned To'}
                            startingName={filter.AssignedTo}
                            testId="wo-filter-user-assigned-dropdown"
                            sx={{ flex: 1, m: 1 }}
                        />
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ display: 'flex' }}>
                            <DateRangePickerPopover
                                startDate={filter.DateCreatedStart}
                                endDate={filter.DateCreatedEnd}
                                textFieldProps={{
                                    label: 'Created Date',
                                    sx: { width: '100%' },
                                    required: tab === 'Work Orders',
                                    error: shouldShowError,
                                    helperText: shouldShowError ? 'Required' : ' ',
                                }}
                                sx={{ flex: 1, m: 1 }}
                                maxDurationDays={maxDurationDaysOfRangePicker}
                                onStartDateChange={newValue => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        DateCreatedStart: newValue,
                                    }));
                                }}
                                onEndDateChange={newValue => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        DateCreatedEnd: newValue,
                                    }));
                                }}
                            />
                            <DateRangePickerPopover
                                startDate={filter.DueDateStart}
                                endDate={filter.DueDateEnd}
                                textFieldProps={{ label: 'Due Date', sx: { width: '100%' } }}
                                sx={{ flex: 1, m: 1 }}
                                maxDurationDays={maxDurationDaysOfRangePicker}
                                onStartDateChange={newValue => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        DueDateStart: newValue,
                                    }));
                                }}
                                onEndDateChange={newValue => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        DueDateEnd: newValue,
                                    }));
                                }}
                            />

                            <DateRangePickerPopover
                                startDate={filter.DateCompletedStart}
                                endDate={filter.DateCompletedEnd}
                                textFieldProps={{ label: 'Completed Date', sx: { width: '100%' } }}
                                sx={{ flex: 1, m: 1 }}
                                maxDurationDays={maxDurationDaysOfRangePicker}
                                onStartDateChange={newValue => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        DateCompletedStart: newValue,
                                    }));
                                }}
                                onEndDateChange={newValue => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        DateCompletedEnd: newValue,
                                    }));
                                }}
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!!filter?.IsDeleted}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const newValue = event?.target?.checked;
                                            setFilter(prevState => ({
                                                ...prevState,
                                                IsDeleted: newValue,
                                            }));
                                        }}
                                    />
                                }
                                label="Is Deleted"
                                sx={{ ml: 1 }}
                            />
                        </Box>
                    </LocalizationProvider>
                    {tab === 'Templates' && (
                        <Box sx={{ display: 'flex', my: 2 }}>
                            <TemplateTypeDropdown
                                currTemplateTypeId={filter?.TemplateTypeId || 0}
                                onChange={(newTemplateTypeId: number) => {
                                    const personalTemplateTypeId = 3;
                                    if (newTemplateTypeId === personalTemplateTypeId)
                                        setFilter(prevState => ({
                                            ...prevState,
                                            TemplateOwner: fullName,
                                            TemplateTypeId: newTemplateTypeId,
                                        }));
                                    else
                                        setFilter(prevState => ({
                                            ...prevState,
                                            TemplateTypeId: newTemplateTypeId,
                                        }));
                                }}
                                sx={{ flex: 1, m: 1 }}
                            />
                            <UserDropdown
                                setName={newValue => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        TemplateOwner: newValue,
                                    }));
                                }}
                                label={'Template Owner'}
                                startingName={filter.TemplateOwner}
                                testId="wo-filter-user-assigned-dropdown"
                                sx={{ flex: 1, m: 1 }}
                            />
                        </Box>
                    )}
                </Box>

                <Box sx={{ display: 'flex', mt: 1, mb: 3 }}>
                    <Button
                        sx={{ flex: 1, mx: 2 }}
                        variant="contained"
                        onClick={() => {
                            setFilter(initialFilter);
                        }}
                        color={'secondary'}
                    >
                        Reset Filter
                    </Button>

                    <Button sx={{ flex: 5, mx: 2 }} variant="contained" type="submit">
                        Search
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default WorkOrderFilter;
