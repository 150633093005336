import axios from 'axios';
import { ROAPI_URL } from '../../app/services/api_constants';
import store from '../../app/store';
import * as actionTypes from '../../archive/store/actions/actionTypes';

const AxiosAuthenticate = axios.create({
    baseURL: ROAPI_URL,
});

AxiosAuthenticate.interceptors.request.use(request => {
    if (request.url.endsWith('nonce') || request.url.endsWith('authentication') || request.url.endsWith('refresh'))
        return request;

    const userSession = JSON.parse(window.localStorage.getItem('userSession'));
    request.headers['Authorization'] = `Bearer ${userSession.token}`;
    request.headers['Content-Type'] = 'application/json';
    return request;
});

AxiosAuthenticate.interceptors.response.use(response => {
    if (response.status === 401 || response.status === 403) {
        store.dispatch({
            type: actionTypes.AUTHENTICATION_FAILED,
            error: 'Authentication Fails',
        });
    }
    return response;
});

export default AxiosAuthenticate;
