import { takeLatest, put, call, all, fork } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/qualityControlApi';

export function* getQCOrderStatusSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_ORDER_STATUS_REDUCER_LOADING });
        const result = yield call(api.getQCOrderStatusApi, action);
        const status = result?.response?.status ? result.response.status : result.status;
        if (status === 200) {
            yield put({ type: actionTypes.GET_QC_ORDER_STATUS_SUCCESS, payload: result.data });
        } else if (status === 400) {
            yield put({
                type: actionTypes.GET_QC_ORDER_STATUS_FAILED,
                errorMessage: 'GET STATUS1 400: ' + 'Invalid Invoice Number',
            });
        } else {
            yield put({ type: actionTypes.GET_QC_ORDER_STATUS_FAILED, errorMessage: 'GET STATUS1 ' + result.message });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_QC_ORDER_STATUS_FAILED, errorMessage: 'GET STATUS2 ' + error.message });
    }
}

export function* getQCOrderDataSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        const result = yield call(api.getQCOrderDataApi, action);
        const status = result?.response?.status ? result.response.status : result.status;
        if (status === 200) {
            if (action.markAsStartedAfterGet) {
                yield call(startQCSaga, action);
            }
            yield put({ type: actionTypes.GET_QC_ORDER_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({
                type: actionTypes.GET_QC_ORDER_DATA_FAILED,
                errorMessage: 'GET ORDER DATA1 ' + result.message,
            });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_QC_ORDER_DATA_FAILED, errorMessage: 'GET ORDER DATA2 ' + error.message });
    }
}

export function* partialSubmitQCSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        const result = yield call(api.partialSubmitQCApi, action);
        //"!result ? 200" is only for jest because shallow render doesn't support useEffect when this gets called on dismount in useEffect
        const status = !result ? 200 : result?.response?.status ? result.response.status : result.status;

        if (status >= 200 && status <= 300) {
            yield put({ type: actionTypes.QC_PARTIAL_SUBMIT_SUCCESS });
        } else {
            yield put({
                type: actionTypes.QC_PARTIAL_SUBMIT_FAILED,
                errorMessage: 'PARTIAL_SUBMIT ' + result.message,
            });
        }
        if (action.willResetQCAfter) {
            yield put({ type: actionTypes.SET_QC_TO_INITIAL_STATE });
        }
    } catch (error) {
        yield put({ type: actionTypes.QC_PARTIAL_SUBMIT_FAILED, errorMessage: 'PARTIAL_SUBMIT2 ' + error.message });
    }
}

export function* submitQCSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        const result = yield call(api.submitQCApi, action);
        const status = result?.response?.status ? result.response.status : result.status;

        if (status === 204) {
            if (action.markAsCompleteAfterSubmit) {
                yield call(completeQCSaga, action);
            }
            yield put({ type: actionTypes.QC_SUBMIT_SUCCESS });
        } else {
            yield put({
                type: actionTypes.QC_SUBMIT_FAILED,
                errorMessage: 'SUBMIT ' + result.message,
            });
        }
    } catch (error) {
        yield put({ type: actionTypes.QC_SUBMIT_FAILED, errorMessage: 'SUBMIT ' + error.message });
    }
}

export function* getQCSerialCheckSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        const result = yield call(api.getQCSerialCheckApi, action);
        const status = result?.response?.status ? result.response.status : result.status;

        if (status === 200) {
            yield put({ type: actionTypes.GET_QC_SERIAL_CHECK_SUCCESS, payload: result.data });
        } else {
            yield put({ type: actionTypes.GET_QC_SERIAL_CHECK_FAILED, errorMessage: 'CHECK SERIAL1 ' + error.message });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_QC_SERIAL_CHECK_FAILED, errorMessage: 'CHECK SERIAL2 ' + error.message });
    }
}

const createQCStatusUpdateBody = () => {
    const userSessionDetails = localStorage.getItem('userSession')
        ? JSON.parse(localStorage.getItem('userSession'))
        : '';
    return {
        userId: userSessionDetails?.userId || 0, //idk what to return if null
        system: 'n/a',
        qcVersion: 'n/a',
        force: true,
    };
};

export function* startQCSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        action.payload = createQCStatusUpdateBody();
        const result = yield call(api.startQCApi, action);
        const status = result?.response?.status ? result.response.status : result.status;

        if (status === 200) {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_SUCCESS });
        } else {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: 'START ' + result.message });
        }
    } catch (error) {
        yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: 'START ' + error.message });
    }
}

export function* completeQCSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        action.payload = createQCStatusUpdateBody();
        const result = yield call(api.completeQCApi, action);
        const status = result?.response?.status ? result.response.status : result.status;

        if (status === 200) {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_SUCCESS });
        } else {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: 'COMPLETE ' + result.message });
        }
    } catch (error) {
        yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: error.message });
    }
}

export function* abortQCSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        action.payload = createQCStatusUpdateBody();
        const result = yield call(api.abortQCApi, action);
        const status = result?.response?.status ? result.response.status : result.status;

        if (status === 200) {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_SUCCESS });
        } else {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: 'ABORT ' + result.message });
        }
    } catch (error) {
        yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: error.message });
    }
}

export function* resetQCSaga(action) {
    try {
        yield put({ type: actionTypes.SET_QC_REDUCER_LOADING });
        action.payload = createQCStatusUpdateBody();
        const result = yield call(api.resetQCApi, action);
        const status = result?.response?.status ? result.response.status : result.status;

        if (status === 200) {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_SUCCESS });
        } else {
            yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: 'RESET ' + result.message });
        }
    } catch (error) {
        yield put({ type: actionTypes.QC_STATUS_CHANGE_FAILED, errorMessage: error.message });
    }
}

function* getQCOrderStatusSagaWatcher() {
    yield takeLatest(actionTypes.GET_QC_ORDER_STATUS, getQCOrderStatusSaga);
}

function* getQCOrderDataSagaWatcher() {
    yield takeLatest(actionTypes.GET_QC_ORDER_DATA, getQCOrderDataSaga);
}

function* partialSubmitQCSagaWatcher() {
    yield takeLatest(actionTypes.QC_PARTIAL_SUBMIT, partialSubmitQCSaga);
}

function* submitQCSagaWatcher() {
    yield takeLatest(actionTypes.QC_SUBMIT, submitQCSaga);
}

function* getQCSerialCheckSagaWatcher() {
    yield takeLatest(actionTypes.GET_QC_SERIAL_CHECK, getQCSerialCheckSaga);
}

function* startQCSagaWatcher() {
    yield takeLatest(actionTypes.START_QC, startQCSaga);
}
function* completeQCSagaWatcher() {
    yield takeLatest(actionTypes.COMPLETE_QC, completeQCSaga);
}
function* abortQCSagaWatcher() {
    yield takeLatest(actionTypes.ABORT_QC, abortQCSaga);
}

function* resetQCSagaWatcher() {
    yield takeLatest(actionTypes.RESET_QC, resetQCSaga);
}

export default function* qualityControlSagaWatcher() {
    yield all([
        fork(getQCOrderStatusSagaWatcher),
        fork(getQCOrderDataSagaWatcher),
        fork(partialSubmitQCSagaWatcher),
        fork(submitQCSagaWatcher),
        fork(getQCSerialCheckSagaWatcher),
        fork(startQCSagaWatcher),
        fork(completeQCSagaWatcher),
        fork(abortQCSagaWatcher),
        fork(resetQCSagaWatcher),
    ]);
}
