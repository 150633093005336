import { takeLatest, put, call } from 'redux-saga/effects';

import {
    CREATE_TASK_FAILURE,
    CREATE_TASK_SUCCESS,
    CREATE_TASK,
    CREATE_TASK_FAILED_DUE_TO_UNAVAILABLITY_OF_SKU,
} from '../store/actions/actionTypes';
import { createTaskApi } from '../store/api/createTaskApi';
import { getCompanySkusByTaskStateAction } from '../store/actions/action';

export function* createTaskSaga(action) {
    try {
        const response = yield call(createTaskApi, action);
        if (response.data && response.data.success === false) {
            yield put({ type: CREATE_TASK_FAILED_DUE_TO_UNAVAILABLITY_OF_SKU, value: response.data.message });
        } else {
            yield put({ type: CREATE_TASK_SUCCESS, value: response.data });
            if (action.meta && action.meta.taskState) {
                yield put(getCompanySkusByTaskStateAction(action.meta.taskState));
            } else {
                yield put(getCompanySkusByTaskStateAction([1]));
            }
        }
    } catch (error) {
        yield put({ type: CREATE_TASK_FAILURE, errorMessage: error });
    }
}

export default function* createTaskSagaWatcher() {
    yield takeLatest(CREATE_TASK, createTaskSaga);
}
