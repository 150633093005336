import {
    ACTIVE_SESSION_URL,
    COMPLETE_SESSION_URL,
    CONTINUE_SESSION_URL,
    FILTERED_PROCEDURES_URL,
    INSTRUCTION_URL,
    NEXT_INSTRUCTION_MAP_URL,
    PROCEDURE_URL,
    RECEIVER_ITEM_URL,
    RECEIVER_URL,
    SESSION_URL,
    START_SESSION_URL,
    VARIABLE_URL,
    WORK_ORDER_TO_PROCEDURE_MAP_URL,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import {
    CompleteSessionRequestBody,
    ContinueSessionRequestBody,
    CreatedProcedureRecord,
    CreatedSession,
    CreateReceiverRequestBody,
    GetProceduresFilter,
    GetWorkOrderProcedureResponse,
    InstructionRecord,
    NextInstructionMapRecord,
    ProcedureRecord,
    ReceiverItemRecord,
    SimplifiedProcedureRecord,
    StartSessionRequestBody,
    VariableRecord,
} from './models';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['IndividualProcedure', 'Session'] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getProcedures: builder.query<SimplifiedProcedureRecord[], GetProceduresFilter>({
            query: param => ({
                url: `${prependDreamUrl(FILTERED_PROCEDURES_URL)}`,
                method: 'POST',
                params: param,
            }),
            providesTags: ['IndividualProcedure'],
        }),
        getProcedure: builder.query<CreatedProcedureRecord, number>({
            query: param => ({
                url: `${prependDreamUrl(PROCEDURE_URL)}/${param}`,
                method: 'GET',
            }),
            providesTags: ['IndividualProcedure'],
        }),
        getWorkOrderProcedure: builder.query<GetWorkOrderProcedureResponse, number>({
            query: param => ({
                url: `${prependDreamUrl(WORK_ORDER_TO_PROCEDURE_MAP_URL)}/${param}`,
                method: 'GET',
            }),
            providesTags: ['IndividualProcedure'],
        }),
        upsertProcedure: builder.mutation<number, ProcedureRecord>({
            query: param => ({
                url: prependDreamUrl(PROCEDURE_URL),
                body: param,
                method: 'POST',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        upsertInstruction: builder.mutation<number, InstructionRecord>({
            query: param => ({
                url: prependDreamUrl(INSTRUCTION_URL),
                body: param,
                method: 'POST',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        upsertNextInstructionMap: builder.mutation<number, NextInstructionMapRecord>({
            query: param => ({
                url: prependDreamUrl(NEXT_INSTRUCTION_MAP_URL),
                body: param,
                method: 'POST',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        upsertReceiverItem: builder.mutation<number, ReceiverItemRecord>({
            query: param => ({
                url: prependDreamUrl(RECEIVER_ITEM_URL),
                body: param,
                method: 'POST',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        upsertVariable: builder.mutation<number, VariableRecord>({
            query: param => ({
                url: prependDreamUrl(VARIABLE_URL),
                body: param,
                method: 'POST',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        deleteVariable: builder.mutation<number, number>({
            query: param => ({
                url: `${prependDreamUrl(VARIABLE_URL)}/${param}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        deleteInstruction: builder.mutation<boolean, number>({
            query: param => ({
                url: `${prependDreamUrl(INSTRUCTION_URL)}/${param}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        markInstructionAsStart: builder.mutation<boolean, number>({
            query: param => ({
                // url: `${prependDreamUrl(INSTRUCTION_URL)}/${param}`,
                url: `${prependDreamUrl(INSTRUCTION_URL)}/${param}`,
                method: 'PUT',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        deleteNextInstructionMap: builder.mutation<boolean, number>({
            query: param => ({
                url: `${prependDreamUrl(NEXT_INSTRUCTION_MAP_URL)}/${param}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        deleteReceiverItem: builder.mutation<boolean, number>({
            query: param => ({
                url: `${prependDreamUrl(RECEIVER_ITEM_URL)}/${param}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['IndividualProcedure'],
        }),
        startSession: builder.mutation<void, StartSessionRequestBody>({
            query: param => ({
                url: `${prependDreamUrl(START_SESSION_URL)}`,
                method: 'POST',
                body: param,
            }),
            invalidatesTags: ['Session'],
        }),
        continueSession: builder.mutation<void, ContinueSessionRequestBody>({
            query: param => ({
                url: `${prependDreamUrl(CONTINUE_SESSION_URL)}`,
                method: 'POST',
                body: param,
            }),
            invalidatesTags: ['Session'],
        }),
        completeSession: builder.mutation<void, CompleteSessionRequestBody>({
            query: param => ({
                url: `${prependDreamUrl(COMPLETE_SESSION_URL)}`,
                method: 'POST',
                body: param,
            }),
            invalidatesTags: ['Session', 'IndividualProcedure'],
        }),
        getActiveSession: builder.query<CreatedSession, number>({
            query: param => ({
                url: `${prependDreamUrl(ACTIVE_SESSION_URL)}/${param}`,
                method: 'GET',
            }),
            providesTags: ['Session'],
        }),
        getSessions: builder.query<CreatedSession[], number>({
            query: param => ({
                url: `${prependDreamUrl(SESSION_URL)}/${param}`,
                method: 'GET',
            }),
            providesTags: ['Session'],
        }),
        createReceiverFromProcedure: builder.mutation<void, CreateReceiverRequestBody>({
            query: param => ({
                url: `${prependDreamUrl(RECEIVER_URL)}`,
                method: 'POST',
                body: param,
            }),
            invalidatesTags: ['Session', 'IndividualProcedure'],
        }),
    }),
});
