import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { CreatedVariableRecord } from '../../../models';
import ProcedureApi from '../../../ProcedureApi';

export default function VariableDataGrid({ variables }: { variables: CreatedVariableRecord[] }) {
    const [triggerUpsertVariable, upsertVariableResponse] = ProcedureApi.endpoints.upsertVariable.useMutation();
    const [triggerDeleteVariable, deleteVariableResponse] = ProcedureApi.endpoints.deleteVariable.useMutation();

    const handleRowProcess = (newRow: CreatedVariableRecord): CreatedVariableRecord => {
        triggerUpsertVariable(newRow);
        return newRow;
    };

    const columns: GridColDef<CreatedVariableRecord>[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 2,
            editable: true,
        },
        {
            field: 'Value',
            headerName: 'Value',
            flex: 2,
            editable: true,
        },
        {
            field: 'Delete',
            type: 'actions',
            headerName: 'Delete',
            flex: 1,
            getActions: value => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => triggerDeleteVariable(value.row.Id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <DataGrid
            rows={variables}
            columns={columns}
            editMode="row"
            processRowUpdate={handleRowProcess}
            getRowId={row => row.Id}
            sx={{ minHeight: 300, my: 2 }}
            hideFooter
        />
    );
}
