import { Autocomplete, TextField } from '@mui/material';

import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Dispatch, SetStateAction, memo } from 'react';
import trackingApi from '../trackingApi';
import { RootState, useAppSelector } from '../../../app/store';
import { Carrier, Tracking } from '../trackingModels';

interface CarrierAutoCompleteProps {
    form: Tracking;
    setForm: Dispatch<SetStateAction<Tracking>>;
}

const CarrierAutoComplete = memo(
    ({ form, setForm }: CarrierAutoCompleteProps): JSX.Element => {
        const momCode = useAppSelector((state: RootState) => state.tracking.momCode);
        const { data } = trackingApi.endpoints.getCarriers.useQuery(momCode ? momCode : skipToken);

        return (
            <Autocomplete
                disablePortal
                options={data?.Carriers || []}
                data-testid="carrier-autocomplete"
                // groupBy={(carrier: Carrier) => carrier.CarrierName}
                autoSelect
                onChange={(event: any, newValue: Carrier | null) => {
                    setForm(
                        (prevForm): Tracking => {
                            return {
                                ...prevForm,
                                Carrier: newValue?.CarrierShort,
                                Scac: newValue?.Scac === '' ? 'null' : newValue?.Scac,
                                InternalCode: newValue?.Code,
                                Service: newValue?.Description,
                            };
                        }
                    );
                }}
                getOptionLabel={(carrier: Carrier) => `${carrier.Code} - ${carrier.Description}`}
                renderInput={params => (
                    <TextField {...params} label={'Carrier'} helperText={''} variant="outlined" error={!form.Scac} />
                )}
                sx={{ mt: 2, width: '100%' }}
            />
        );
    }
);

// function arePropsEqual(oldProps: CarrierAutoCompleteProps, newProps: CarrierAutoCompleteProps) {
//     return oldProps.value === newProps.value;
// }

export default CarrierAutoComplete;
