import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React from 'react';
import { Item } from '../../qualityControl/qualityControlModels';
import { AddSerialSubmitPayload } from '../addSerialModels';
import { useAppDispatch } from '../../../app/store';
import { setDialog } from '../../notification/notificationSlice';
const randexp = require('randexp').randexp;

interface AddSerialAddItemProps {
    items: Item[];
    submitForm: AddSerialSubmitPayload;
    setSubmitForm: React.Dispatch<React.SetStateAction<AddSerialSubmitPayload>>;
    setIsSubmitButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddSerialAddItem = ({ items, submitForm, setSubmitForm, setIsSubmitButtonEnabled }: AddSerialAddItemProps) => {
    const dispatch = useAppDispatch();
    const [itemToBeAdded, setItemToBeAdded] = React.useState<Item | null>(null);
    const [isRegexIgnored, setIsRegexIgnored] = React.useState(false);

    const isfirstSerialError = !(submitForm?.Items?.[0]?.Serial || '').match(
        itemToBeAdded?.SerialVerify[0]?.Regex || ''
    );
    const isSecondSerialError = itemToBeAdded?.SerialVerify[1]
        ? !(submitForm?.Items?.[0]?.Serial2 || '').match(itemToBeAdded?.SerialVerify[1]?.Regex)
        : false;

    //need to set with itemToBeAdded value, which only exists in this component
    React.useEffect(() => {
        setIsSubmitButtonEnabled((!isfirstSerialError && !isSecondSerialError) || isRegexIgnored);
    }, [submitForm, isRegexIgnored]);

    const handleRandomizeSerialsButtonClick = () => {
        try {
            if (itemToBeAdded?.SerialVerify[1]) {
                const randomSerial = randexp(itemToBeAdded?.SerialVerify[0]?.Regex);
                const randomSerial2 = randexp(itemToBeAdded?.SerialVerify[1]?.Regex);
                setSubmitForm(
                    (prevForm: AddSerialSubmitPayload): AddSerialSubmitPayload => {
                        return {
                            ...prevForm,
                            Items: [
                                {
                                    ItemId: itemToBeAdded?.ItemId || -1,
                                    Sku: itemToBeAdded?.Sku || '',
                                    Serial: randomSerial,
                                    Serial2: randomSerial2,
                                },
                            ],
                        };
                    }
                );
            } else if (itemToBeAdded?.SerialVerify[0]) {
                const randomSerial = randexp(itemToBeAdded?.SerialVerify[0]?.Regex);
                setSubmitForm(
                    (prevForm: AddSerialSubmitPayload): AddSerialSubmitPayload => {
                        return {
                            ...prevForm,
                            Items: [
                                {
                                    ItemId: itemToBeAdded?.ItemId || -1,
                                    Sku: itemToBeAdded?.Sku || '',
                                    Serial: randomSerial,
                                },
                            ],
                        };
                    }
                );
            }
        } catch (err) {
            dispatch(setDialog({ content: 'Invalid REGEX pattern' }));
        }
    };

    const getLabel = (alias: string | undefined): string => {
        if (!itemToBeAdded) return 'Item Select Required';

        if (alias) return alias;
        else return 'Item has no serial RegEx';
    };

    return (
        <Box sx={{ display: 'flex', alignSelf: 'center', alignItems: 'center', mt: 5 }}>
            <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                        disablePortal
                        autoSelect
                        data-testid={'items-dropdown-autocomplete'}
                        options={items}
                        getOptionLabel={(stock: Item) => `${stock.Sku}: ${stock.Description}`}
                        value={itemToBeAdded}
                        onChange={(event: any, newValue: Item | null) => {
                            setItemToBeAdded(newValue);
                            setSubmitForm(prevForm => {
                                return {
                                    ...prevForm,
                                    Items: [],
                                };
                            });
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={'Item'}
                                helperText={
                                    itemToBeAdded ? (itemToBeAdded?.Serialized ? 'Serialized' : 'Not Serialized') : ' '
                                }
                            />
                        )}
                        sx={{ flex: 1 }}
                        isOptionEqualToValue={(option, value) => value === option}
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Ignore RegEx"
                        sx={{ mx: 2.5 }}
                        checked={isRegexIgnored}
                        onChange={(event: any) => {
                            setIsRegexIgnored(event.target.checked);
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', mt: 3 }}>
                    <TextField
                        disabled={!itemToBeAdded || (!itemToBeAdded?.SerialVerify[0]?.Alias && !isRegexIgnored)}
                        label={getLabel(itemToBeAdded?.SerialVerify[0]?.Alias)}
                        helperText={isRegexIgnored ? 'RegEx is ignored' : itemToBeAdded?.SerialVerify[0]?.Regex || ''}
                        error={isfirstSerialError}
                        sx={{ flex: itemToBeAdded?.SerialVerify[1] || isRegexIgnored ? 3 : 6 }}
                        value={submitForm?.Items?.[0]?.Serial || ''}
                        inputProps={{ 'data-testid': 'serial1' }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = event.target.value;
                            setSubmitForm(
                                (prevForm: AddSerialSubmitPayload): AddSerialSubmitPayload => {
                                    return {
                                        ...prevForm,
                                        Items: [
                                            {
                                                ...prevForm.Items[0],
                                                ItemId: itemToBeAdded?.ItemId || -1,
                                                Sku: itemToBeAdded?.Sku || '',
                                                Serial: newValue,
                                            },
                                        ],
                                    };
                                }
                            );
                        }}
                    />
                    {(itemToBeAdded?.SerialVerify[1] || isRegexIgnored) && (
                        <TextField
                            disabled={!itemToBeAdded || (!itemToBeAdded?.SerialVerify[1]?.Alias && !isRegexIgnored)}
                            label={getLabel(itemToBeAdded?.SerialVerify[1]?.Alias)}
                            helperText={
                                isRegexIgnored ? 'RegEx is ignored' : itemToBeAdded?.SerialVerify[1]?.Regex || ''
                            }
                            error={isSecondSerialError}
                            sx={{ flex: 3 }}
                            value={submitForm?.Items?.[0]?.Serial2 || ''}
                            inputProps={{ 'data-testid': 'serial2' }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = event.target.value;
                                setIsSubmitButtonEnabled(!isfirstSerialError && !isSecondSerialError);
                                setSubmitForm(
                                    (prevForm: AddSerialSubmitPayload): AddSerialSubmitPayload => {
                                        return {
                                            ...prevForm,
                                            Items: [
                                                {
                                                    ...prevForm.Items[0],
                                                    ItemId: itemToBeAdded?.ItemId || -1,
                                                    Sku: itemToBeAdded?.Sku || '',
                                                    Serial2: newValue,
                                                },
                                            ],
                                        };
                                    }
                                );
                            }}
                        />
                    )}
                    <Button
                        disabled={!itemToBeAdded || !itemToBeAdded?.SerialVerify[0]?.Alias}
                        variant="contained"
                        sx={{ my: 3, mx: 1, flex: 1 }}
                        color="secondary"
                        onClick={handleRandomizeSerialsButtonClick}
                    >
                        Randomize
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default AddSerialAddItem;
