export const HOURS_PER_INCREMENT = 24;
export const DAYS_BACK = 7;
export const MILLISEC_REFRESH_TIME = 300000; // Refresh batch data every 5 minutes
export const LIGHT_RED = '#ef5350';
export const ORDER_TAB_VIEW = 'orderView';
export const BATCH_TAB_VIEW = 'batchView';

export const enum QcStatus {
    NOT_STARTED = 'Not Started',
    STARTED = 'Started',
    COMPLETED = 'Completed',
}
