import { Box, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';
import { HistoryCustomerDisplay } from './HistoryCustomerDisplay';
import { HistoryCustomer } from '../../orderLookupModels';

export function HistoryBillingShippingDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getHistoryOrderResponse = orderLookupApi.endpoints.getHistoryOrder.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const billingData: HistoryCustomer = getHistoryOrderResponse.data?.Data[0]?.BillTo || ({} as HistoryCustomer);
    const shippingData: HistoryCustomer = getHistoryOrderResponse.data?.Data[0]?.ShipTo || ({} as HistoryCustomer);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: 3, m: 1, mr: 2 }}>
                <Typography variant="h6"> Ship To </Typography>
                <HistoryCustomerDisplay {...shippingData} />
            </Box>
            <Box sx={{ flex: 3, m: 1 }}>
                <Typography variant="h6"> Bill To </Typography>
                <HistoryCustomerDisplay {...billingData} />
            </Box>
        </Box>
    );
}
