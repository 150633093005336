import { Avatar, Box, Button, Container, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import sealLogo from '../../common/assets/images/seal-black-transparent.png';
import useNotificationByMutationResponseV2 from '../../common/hooks/useNotificationByMutationResponseV2';
import authenticationApi from '../authentication/authenticationApi';
import { setAuthentication } from '../authentication/authenticationSlice';

export const SignIn = () => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [triggerAuthenticate, authResponse] = authenticationApi.endpoints.makeLoginRequest.useMutation();

    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/';

    useNotificationByMutationResponseV2({
        response: authResponse,
        successFinishedFunction: () => {
            if (authResponse.data) {
                dispatch(setAuthentication({ response: authResponse.data, username: username }));
                navigate(from, { replace: true });
            }
        },
    });

    const loginHandler = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.persist();
            event.preventDefault();
            triggerAuthenticate({ username: username, password: password, type: 'mom' });
        },
        [username, password, dispatch]
    );

    return (
        <Container maxWidth="xs" sx={{ backgroundColor: 'white' }}>
            <Box
                sx={{
                    marginTop: 8,
                    padding: '2rem 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar alt="Seal Logo" src={sealLogo} />

                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {authResponse.isError && (
                    <Typography sx={{ color: red['A400'], my: 2 }}>
                        Either user name or password is incorrect
                    </Typography>
                )}
                <form onSubmit={loginHandler}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="User Name"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={event => {
                            const newValue = event.target.value;
                            setUsername(newValue);
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={event => {
                            const newValue = event.target.value;
                            setPassword(newValue);
                        }}
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3 }}>
                        Sign In
                    </Button>
                </form>
            </Box>
        </Container>
    );
};
