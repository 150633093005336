import { Box, Divider, Paper, Tab, Tabs } from '@mui/material';
import React from 'react';
import { Group } from '../shipMatrixModel';
import ShipMatrixClientsTab from './Clients/ShipMatrixClientsTab';
import ShipMatrixFilter from './ShipMatrixFilter';
import ShipMatrixEditor from './Matrix/ShipMatrixEditor';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ShipMatrixElement = () => {
    const [tabNumber, setTabNumber] = React.useState(0);
    const [currGroup, setCurrGroup] = React.useState<Group | null>(null);

    return (
        <Box>
            <ShipMatrixFilter setCurrGroup={setCurrGroup} />
            <Divider sx={{ my: 2 }} variant="middle" />

            <Paper sx={{ p: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabNumber} onChange={(e, newValue) => setTabNumber(newValue)}>
                        <Tab label="Ship Matrix" {...a11yProps(0)} />
                        <Tab label="Clients" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <Box sx={{ p: 3 }}>
                    {tabNumber === 0 && currGroup && (
                        <ShipMatrixEditor currGroup={currGroup} key={JSON.stringify(currGroup)} />
                    )}
                    {tabNumber === 1 && currGroup && (
                        <ShipMatrixClientsTab currGroup={currGroup} key={JSON.stringify(currGroup)} />
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default ShipMatrixElement;
