import { Box, Button, Drawer, TextField } from '@mui/material';
import React from 'react';
import useScanner from '../hooks/useScanner';
export function QualityControlDrawer({
    serialDrawerOpen,
    setSerialDrawerOpen,
    serialInput,
    setSerialInput,
    alias,
    handleDrawerClose,
    handleSerialSubmitButtonClicked,
}) {
    const toggleDrawer = state => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (!state) {
            handleDrawerClose();
        }
        setSerialDrawerOpen(state);
    };

    const handleButtonClick = e => {
        handleSerialSubmitButtonClicked(e);
    };

    useScanner(serialInput, handleButtonClick);

    return (
        <Drawer anchor={'bottom'} open={serialDrawerOpen} onClose={toggleDrawer(false)}>
            <Box
                sx={{
                    p: '10px 24px',
                    marginBottom: 10,
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {/* <form id="serial-form-id" style={{ flex: 1, display: 'flex' }}> */}
                <TextField
                    autoFocus
                    id="serial-input"
                    inputProps={{ 'data-testid': 'serial-input' }}
                    label={alias}
                    variant="outlined"
                    value={serialInput}
                    onChange={e => setSerialInput(e.target.value.toUpperCase())}
                    sx={{
                        flex: 1,
                    }}
                />
                {/* </form> */}

                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    style={{
                        padding: '2px 1rem',
                        margin: 10,
                    }}
                    data-testid="serial-button"
                    onClick={handleButtonClick}
                    // form="serial-form-id"
                >
                    {' '}
                    Submit{' '}
                </Button>
            </Box>
        </Drawer>
    );
}
