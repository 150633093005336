const {
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    GET_USER_PERMISSION_SUCCESS,
    GET_USER_PERMISSION_FAILURE,
    SET_USERS_LOADING,
    GET_ASSIGNED_ROLES_BY_USER_SUCCESS,
    GET_ASSIGNED_ROLES_BY_USER_FAILURE,
    EDIT_ROLES_BY_USER_SUCCESS,
    EDIT_ROLES_BY_USER_FAILURE,
    RESET_USERS_SUCCESS_STATUS,
} = require('../store/actions/actionTypes');

export const initialState = {
    isLoading: false,
    users: [],
    userList: [],
    rolesList: [],
    errorMessage: '',
    userPermissions: [],
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS_LOADING:
            return { ...state, isLoading: true };
        case RESET_USERS_SUCCESS_STATUS:
            return { ...state, success: false, successMessage: '', error: false, errorMessage: '' };
        case GET_USERS_SUCCESS:
            return { ...state, isLoading: false, userList: action.users };
        case GET_USERS_FAILURE:
            return { ...state, isLoading: false, errorMessage: action.errorMessage };
        case GET_USER_PERMISSION_SUCCESS:
            return { ...state, isLoading: false, userPermissions: action.payload };
        case GET_USER_PERMISSION_FAILURE:
            return { ...state, isLoading: false, errorMessage: action.errorMessage };
        case GET_ASSIGNED_ROLES_BY_USER_SUCCESS:
            return { ...state, isLoading: false, rolesList: action.payload };
        case GET_ASSIGNED_ROLES_BY_USER_FAILURE:
            return { ...state, isLoading: false, errorMessage: action.errorMessage };
        case EDIT_ROLES_BY_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: `User role has been ${
                    action.payload.meta === 'ASSIGNED' ? 'assigned' : 'un-assigned'
                } successfully`,
            };
        case EDIT_ROLES_BY_USER_FAILURE:
            return { ...state, isLoading: false, errorMessage: action.errorMessage };
        default:
            break;
    }
    return state;
};

export default usersReducer;
