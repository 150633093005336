import { Box } from '@mui/material';
import ReceiverDatagrid from './ReceiverDatagrid';
import ReceiverFilter from './ReceiverFilter';

const ReceiverList = () => {
    return (
        <Box>
            <ReceiverFilter />
            <ReceiverDatagrid />
        </Box>
    );
};

export default ReceiverList;
