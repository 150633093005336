import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import CreateProcedureButton from './components/ProcedureList/CreateProcedureButton';
import ProceduresDatagrid from './components/ProcedureList/ProceduresDatagrid';
import ProceduresFilter from './components/ProcedureList/ProceduresFilter';
import { SimplifiedProcedureRecord } from './models';
import ProcedureApi from './ProcedureApi';

const Procedure = ({
    shouldRenderCreateButton,
    onRowClick,
}: {
    shouldRenderCreateButton: boolean;
    onRowClick?: (procedure: SimplifiedProcedureRecord) => void;
}) => {
    const [triggerGetProcedures, getProceduresResponses] = ProcedureApi.endpoints.getProcedures.useLazyQuery();
    const navigate = useNavigate();

    const handleOnRowClick = (procedure: SimplifiedProcedureRecord) => {
        if (onRowClick) onRowClick(procedure);
        else navigate(`canvas/${procedure.Id}`);
    };

    return (
        <Box>
            <ProceduresFilter triggerGetProcedures={triggerGetProcedures} />
            <ProceduresDatagrid procedures={getProceduresResponses.data || []} onRowClick={handleOnRowClick} />
            {shouldRenderCreateButton && <CreateProcedureButton />}
        </Box>
    );
};

export default Procedure;
