import { Box, Typography } from '@mui/material';
import { ExpectedItem, ReceivedItem, ReceivedItemUnitDetail, Serial } from '../../rmaModels';
import { ReceivedItemSummaryPanelAccordian } from './ReceivedItemSummaryPanelAccordian';
import { ReceivedItemSummaryPanelPaper } from './ReceivedItemSummaryPanelPaper';

interface GridUnit extends ReceivedItemUnitDetail {
    datagridId: number;
}

export const ReceivedItemSummaryPanel = ({
    receivedItem,
    expectedItem,
}: {
    receivedItem: ReceivedItem;
    expectedItem?: ExpectedItem;
}) => {
    const hasUnitDetails = receivedItem.ItemUnitDetails.length > 0;
    const itemTitle = () => {
        if (!!!receivedItem?.Sku && !!!expectedItem?.Sku) return 'UNKNOWN ITEM';

        return `${receivedItem.Description || expectedItem?.Description || 'NO DESCRIPTION FOUND'} (${
            receivedItem.Sku || expectedItem?.Sku || 'NO SKU FOUND'
        })`;
    };

    const qtyMissing = () => {
        if (!!expectedItem && expectedItem.Quantity != receivedItem.Quantity) {
            return (
                (expectedItem.Quantity ? expectedItem.Quantity : 0) -
                (!!receivedItem.Quantity ? receivedItem.Quantity : 0)
            );
        }
        return 0;
    };
    const qtyExtra = 0 - qtyMissing();

    return (
        <Box sx={{ mb: 2 }}>
            <Typography
                fontWeight={'fontWeightMedium'}
                sx={{ color: 'primary.main', ml: 1 }}
                variant="h6"
                justifyContent="flex-start"
            >
                {itemTitle()}
            </Typography>
            {hasUnitDetails ? (
                <ReceivedItemSummaryPanelAccordian
                    qtyMissing={qtyMissing()}
                    qtyExtra={qtyExtra}
                    receivedItem={receivedItem}
                    expectedItem={expectedItem}
                />
            ) : (
                <ReceivedItemSummaryPanelPaper
                    qtyMissing={qtyMissing()}
                    qtyExtra={qtyExtra}
                    receivedItem={receivedItem}
                    expectedItem={expectedItem}
                />
            )}
        </Box>
    );
};
