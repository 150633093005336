import { Autocomplete, Box, Button, TextField } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { BillOfMaterialsItem, BillOfMaterialsOptionRecord, BillOfMaterialsSecondary } from '../../workOrderModels';
import { overrideForm } from '../../workOrderSlice';
import workOrderApi from '../../workOrderApi';

interface MaterialsItemFormProps {
    isDisabled: boolean;
    indexToUpdate?: number;
    handleOnSubmit?: () => void;
}

const MaterialsItemForm = ({ isDisabled, indexToUpdate = -1, handleOnSubmit = () => {} }: MaterialsItemFormProps) => {
    const billsOfMaterialsItems = useAppSelector(state => state.workOrder.form.BillsOfMaterialsItems);
    const getBillOfMaterialsOptionsResponse = workOrderApi.endpoints.getBillOfMaterialsOptions.useQuery();

    const dispatch = useAppDispatch();
    const initialItem: BillOfMaterialsItem =
        billsOfMaterialsItems?.[indexToUpdate] || //index to update is -1
        ({
            Id: null,
            WorkOrderId: null,
            Primary: null,
            Secondary: null,
            Quantity: 0,
        } as BillOfMaterialsItem);
    const [itemBeingAdded, setitemBeingAdded] = React.useState<BillOfMaterialsItem>(initialItem);
    const [isErrorDisplayed, setIsErrorDisplayed] = React.useState(false);

    const isPrimaryError = !itemBeingAdded?.Primary?.Id;
    const isSecondaryError =
        itemBeingAdded?.Primary?.Secondaries?.length === 0
            ? false
            : itemBeingAdded?.Secondary === null
            ? false
            : !itemBeingAdded?.Secondary?.Id;

    const isQuantityError = !itemBeingAdded.Quantity;

    const isItemCombinationError = () => {
        const indexOfItemToAdd = billsOfMaterialsItems?.findIndex(
            item =>
                !item.DeleteDateTime &&
                item.Primary?.Id === itemBeingAdded.Primary?.Id &&
                item.Secondary?.Id === itemBeingAdded.Secondary?.Id
        );

        const isItemToAddDuplicate = indexOfItemToAdd >= 0;
        const isInUpdateMode = indexOfItemToAdd === indexToUpdate;

        if (billsOfMaterialsItems?.[indexOfItemToAdd]?.DeleteDateTime) {
            return false;
        }

        return isItemToAddDuplicate ? !isInUpdateMode : false;
    };

    const isError = isPrimaryError || isSecondaryError || isQuantityError || isItemCombinationError();

    const isPrimaryErrorDisplayed = isPrimaryError && isErrorDisplayed;
    const isSecondaryErrorDisplayed = isSecondaryError && isErrorDisplayed;
    const isQuantityErrorDisplayed = isQuantityError && isErrorDisplayed;
    const isDuplicateErrorDisplayed = isItemCombinationError() && isErrorDisplayed;

    const primaryHelpperText = () => {
        if (isPrimaryErrorDisplayed) return 'Required';
        if (isDuplicateErrorDisplayed) return 'No Duplicate (Material/Type) Combination';
        return ' ';
    };

    const handleAddBillingButtonClick = () => {
        if (isError) {
            setIsErrorDisplayed(true);
            return;
        }

        if (indexToUpdate !== -1) {
            let newItemsList = [...billsOfMaterialsItems];
            newItemsList.splice(indexToUpdate, 1);
            dispatch(overrideForm({ BillsOfMaterialsItems: [...newItemsList, { ...itemBeingAdded }] }));
            handleOnSubmit();
        } else {
            dispatch(overrideForm({ BillsOfMaterialsItems: [...billsOfMaterialsItems, { ...itemBeingAdded }] }));
        }
        setitemBeingAdded({
            ...initialItem,
        });
        setIsErrorDisplayed(false);
    };

    return (
        <Box sx={{}}>
            <form
                onSubmit={event => {
                    event.preventDefault();
                    handleAddBillingButtonClick();
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                        disablePortal
                        options={getBillOfMaterialsOptionsResponse?.data || []}
                        value={itemBeingAdded.Primary}
                        onChange={(event, newValue) => {
                            setitemBeingAdded(prev => {
                                return { ...prev, Primary: newValue || null, Secondary: null };
                            });
                        }}
                        getOptionLabel={primary => primary.Primary || ''}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Material"
                                error={isPrimaryErrorDisplayed || isDuplicateErrorDisplayed}
                                helperText={primaryHelpperText()}
                            />
                        )}
                        sx={{ flex: 6, mt: 2 }}
                        disabled={isDisabled}
                        isOptionEqualToValue={(curr, next) => curr.Id === next.Id}
                    />

                    <Autocomplete
                        disablePortal
                        options={
                            getBillOfMaterialsOptionsResponse.data?.find(
                                primary => primary.Primary === itemBeingAdded.Primary?.Primary
                            )?.Secondaries || []
                        }
                        value={itemBeingAdded.Secondary}
                        onChange={(event, newValue) => {
                            setitemBeingAdded(prev => {
                                return { ...prev, Secondary: newValue || null };
                            });
                        }}
                        getOptionLabel={secondary => secondary.Name || ''}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Type"
                                error={isSecondaryErrorDisplayed || isDuplicateErrorDisplayed}
                                helperText={isSecondaryErrorDisplayed ? 'Required' : ' '}
                            />
                        )}
                        sx={{ flex: 6, mt: 2, mx: 2 }}
                        disabled={isDisabled}
                        isOptionEqualToValue={(curr, next) => curr.Id === next.Id}
                    />

                    <TextField
                        variant="outlined"
                        label="Quantity"
                        value={itemBeingAdded.Quantity}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = Number(Number(event.target.value).toFixed(2));
                            if (newValue >= 0)
                                setitemBeingAdded(prev => {
                                    return { ...prev, Quantity: newValue };
                                });
                        }}
                        sx={{ flex: 3, mt: 2 }}
                        type="number"
                        disabled={isDisabled}
                        error={isQuantityErrorDisplayed}
                        helperText={isQuantityErrorDisplayed ? 'Must be Greater than 0' : ' '}
                    />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ flex: 1, m: 2 }}
                        disabled={isDisabled}
                        type="submit"
                    >
                        {indexToUpdate !== -1 ? 'Update' : 'Add to Bill of Materials'}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default MaterialsItemForm;
