import ReceiversCreateDialog from './createDialog/ReceiversCreateDialog';
import NoAsnTab from './tabbedView/NoAsnTab';

const ReceiversElement = () => {
    return (
        <>
            <NoAsnTab />

            <ReceiversCreateDialog hasAsn={false} />
        </>
    );
};

export default ReceiversElement;
