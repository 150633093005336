import { RECEIVER_PROJECTS_OPTIONS_WITH_REQUIRED_LADING } from './receiversConstants';
import { SubmissionForm } from './receiversModels';

export const isLadingError = (lading: string, project: string) => {
    return !lading && RECEIVER_PROJECTS_OPTIONS_WITH_REQUIRED_LADING.includes(project);
};

export const minsToHours = (minute: number) => {
    const timeInHours = minute / 60;
    return Math.round(timeInHours * 100) / 100;
};

export const convertMinsToHours = (brokenForm: SubmissionForm): SubmissionForm => {
    return {
        ...brokenForm,
        StockTime: minsToHours(brokenForm.StockTime),
        LabelTime: minsToHours(brokenForm.LabelTime),
        ShelfTime: minsToHours(brokenForm.ShelfTime),
    } as SubmissionForm;
};
