import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Tracking } from '../trackingModels';
import MemoDateTimePicker from '../../receivers/components/createDialog/formPartTwo/formControlledInputs/MemoDateTimePicker';
import MemoNumberTextField from '../../receivers/components/createDialog/formPartTwo/formControlledInputs/MemoNumberTextField';
import MemoTextField from '../../receivers/components/createDialog/formPartTwo/formControlledInputs/MemoTextField';
import ScacAutoComplete from './ScacAutoComplete';

interface TrackingFormPartTwoProps {
    form: Tracking;
    setForm: Dispatch<SetStateAction<Tracking>>;
}

const TrackingFormPartTwo: FC<TrackingFormPartTwoProps> = ({ form, setForm }) => {
    return (
        <Box>
            <MemoTextField
                label={'SSCC'}
                value={form.Sscc || ''}
                onChange={(newValue: string) => {
                    setForm(
                        (prevForm): Tracking => {
                            return { ...prevForm, Sscc: newValue };
                        }
                    );
                }}
            />

            <ScacAutoComplete value={form.Scac || ''} setForm={setForm} />

            <MemoNumberTextField
                label={'Package Weight'}
                value={form.Weight || 0}
                onChange={(newValue: number) => {
                    setForm(
                        (prevForm): Tracking => {
                            return { ...prevForm, Weight: newValue };
                        }
                    );
                }}
                step={0.001}
                error={!form.Weight}
            />

            {/* unused? */}
            {/* <MemoNumberTextField
                label={'Box #'}
                value={0}
                onChange={(newValue: number) => {
                    setForm(
                        (prevForm): Tracking => {
                            return { ...prevForm, Weight: newValue };
                        }
                    );
                }}
                step={0.001}
                disabled={true}
            /> */}

            <MemoDateTimePicker
                label={'Ship Date'}
                value={dayjs(form.ShipDate)}
                disabled={false}
                onChange={(newValue: Dayjs) => {
                    setForm(
                        (prevForm): Tracking => {
                            return { ...prevForm, ShipDate: newValue.toISOString() };
                        }
                    );
                }}
            />
            {/* need Service and InternalCode */}
        </Box>
    );
};

export default TrackingFormPartTwo;
