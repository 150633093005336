import { GET_REJECT_TASK_DETAILS } from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const rejectTaskDetailsApi = async ({ payload }) => {
    const params = new URLSearchParams({
        taskId: payload.taskId,
        completedDateTimeOffSet: payload.completedDateTimeOffSet,
        updatedByUserId: payload.updatedByUserId,
        comment: payload.comment,
    }).toString();

    const res = await API.post(`${GET_REJECT_TASK_DETAILS}?${params}`);
    return res;
};
