import { Autocomplete, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as messages from '../../constants/messages';
import componentsApi from '../componentsApi';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const CompanyDropdown = ({
    companyDetails = null,
    updateSelectedCompany,
    isValidated = true,
    isReAssign = true,
    label = 'Select Company*',
    disabled = false,
    startingMom = '',
    testId = '',
    autoFocus = false,
    readOnly = false,
    sx = {},
}) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const response = componentsApi.endpoints.getCompanies.useQuery();
    const [hasHadInitialValueSetAtLeastOnce, setHasInitialValueSetAtLeastOnce] = useState(false);

    const companiesList = useMemo(() => {
        // Getting list sorted with first letter of mom property
        if (response.isSuccess) {
            if (startingMom) {
                setValue(response.data.find(company => company.mom === startingMom));
            }

            let arrayToSort = [...response.data];
            const result = arrayToSort.sort((a, b) => {
                if (a.mom < b.mom) {
                    return -1;
                }
                if (a.mom > b.mom) {
                    return 1;
                }
                return 0;
            });

            return result;
        } else {
            return [];
        }
    }, [response.isSuccess]);

    // To handle on selection from one of the options
    const handleOnChange = useCallback(
        (event, newValue) => {
            setHasInitialValueSetAtLeastOnce(true);
            setValue(newValue); // Updating value in local component for validation
            updateSelectedCompany(newValue); // Updating value in parent component to use for API calls etc.
        },
        [updateSelectedCompany, setValue]
    );

    // To handle the typing in the input
    const handleInputChange = useCallback(
        (event, newInputValue) => {
            // updateSelectedCompany(null);
            setInputValue(newInputValue);
        },
        [setInputValue, updateSelectedCompany]
    );

    useEffect(() => {
        return () => {
            if (!companyDetails) {
                updateSelectedCompany(null);
            }
        };
    }, [isReAssign, companyDetails]);

    return (
        <React.Fragment>
            {companyDetails && companyDetails.companyName ? (
                <TextField
                    // className="mb-3"
                    data-testid={testId}
                    disabled
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={companyDetails.companyName}
                    sx={{ ...sx }}
                />
            ) : (
                <Autocomplete
                    disabled={disabled}
                    data-testid={testId}
                    value={value}
                    onChange={handleOnChange}
                    autoComplete
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    id="select-company"
                    // size="small"
                    options={companiesList}
                    groupBy={company => company.firstLetter}
                    loading={companiesList?.length === 0}
                    getOptionLabel={company => `${company.mom}: ${company.name ? company.name : ''}`}
                    renderInput={params => (
                        <TextField
                            autoFocus={autoFocus}
                            {...params}
                            label={companiesList[0] ? label : 'Loading'}
                            variant="outlined"
                            placeholder="Select Company"
                            error={isValidated && !value && hasHadInitialValueSetAtLeastOnce}
                            helperText={
                                isValidated && !value && hasHadInitialValueSetAtLeastOnce
                                    ? messages.SELECT_DROPDOWN_OPTION('company')
                                    : ''
                            }
                        />
                    )}
                    readOnly={readOnly}
                    popupIcon={readOnly ? '' : <ArrowDropDownIcon />}
                    isOptionEqualToValue={(option, newValue) => {
                        return option.mom === newValue.mom;
                    }}
                    sx={{ ...sx }}
                />
            )}
        </React.Fragment>
    );
};
