import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { CompanyDropdownV2 } from '../../../../common/components/dropdown/CompanyDropdownV2';
import UserDropdown from '../../../../common/components/dropdown/UserDropdown';
import { setForm } from '../../workOrderSlice';
import WorkOrderAttachmentItems from '../Attachment/WorkOrderAttachmentItems';
import TemplateTypeDropdown from '../template/TemplateTypeDropdown';
import FormDivider from './FormDivider';
import LeftDates from './LeftDates';

interface WorkOrderFormLeftProps {
    isErrorDisplayed: boolean;
    isDisabled: boolean;
}

const WorkOrderFormLeft = ({ isErrorDisplayed, isDisabled }: WorkOrderFormLeftProps) => {
    const form = useAppSelector(state => state.workOrder.form);
    const fullName = useAppSelector(state => state.authentication.fullName);
    const tab = useAppSelector(state => state.workOrder.tab);
    const dispatch = useAppDispatch();

    const receivedViaOptions = ['email', 'phone', 'fax', 'verbal', 'ticket'];

    return (
        <Box>
            {form.Id && (
                <Typography
                    sx={{
                        p: 1.2,
                        color: '#555',
                        my: 1,
                        fontWeight: 'bold',
                    }}
                    variant="h4"
                    component="div"
                >
                    {`${tab === 'Work Orders' ? 'Work Order' : 'Template'}: ${form.Id}`}
                </Typography>
            )}

            <TextField
                variant="outlined"
                label={isDisabled ? 'Project Title Name' : 'Project Title Name*'}
                value={form.ProjectTitle || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    dispatch(setForm({ ...form, ProjectTitle: newValue }));
                }}
                error={isErrorDisplayed && !form.ProjectTitle}
                helperText={isErrorDisplayed && !form.ProjectTitle ? 'Required' : ' '}
                sx={{ width: '100%' }}
                InputProps={{
                    readOnly: isDisabled,
                }}
            />

            <Box sx={{ display: 'flex', my: 3 }}>
                <CompanyDropdownV2
                    setCompany={newValue => {
                        dispatch(setForm({ ...form, MomCode: newValue || null }));
                    }}
                    startingMomCode={form.MomCode || undefined}
                    label={'Company'}
                    sx={{ flex: 1, mr: 1 }}
                    readOnly={isDisabled}
                />

                <TextField
                    variant="outlined"
                    label="Client Contact"
                    value={form.ClientContact || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        dispatch(setForm({ ...form, ClientContact: newValue }));
                    }}
                    sx={{ flex: 1, ml: 1 }}
                    helperText=" "
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', my: 3 }}>
                <TextField
                    variant="outlined"
                    label="Charge Code"
                    value={form.ChargeCode || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        dispatch(setForm({ ...form, ChargeCode: newValue }));
                    }}
                    sx={{ flex: 1, mr: 1 }}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    helperText={' '}
                />
                <Autocomplete
                    disablePortal
                    options={receivedViaOptions}
                    value={form.ReceivedBy}
                    onChange={(event, newValue) => {
                        dispatch(setForm({ ...form, ReceivedBy: newValue || null }));
                    }}
                    renderInput={params => <TextField {...params} variant="outlined" label="Received Via" />}
                    sx={{ flex: 1, ml: 1 }}
                    readOnly={isDisabled}
                    popupIcon={isDisabled ? '' : <ArrowDropDownIcon />}
                />
            </Box>

            <Box sx={{ display: 'flex', my: 2 }}>
                <LeftDates isDisabled={isDisabled} isErrorDisplayed={isErrorDisplayed} />
            </Box>

            <Box sx={{ display: 'flex', my: 2 }}>
                <UserDropdown
                    setName={newValue => dispatch(setForm({ ...form, RoRequester: newValue }))}
                    label={isDisabled ? 'Rush Order Requester' : 'Rush Order Requester*'}
                    readOnly={isDisabled}
                    error={isErrorDisplayed && !form.RoRequester}
                    helperText={isErrorDisplayed && !form.RoRequester ? 'Required' : ' '}
                    startingName={form.RoRequester}
                    sx={{ flex: 1, mr: 1 }}
                    testId="wo-requester-user-dropdown"
                    disabled={!isDisabled}
                />

                <UserDropdown
                    setName={newValue => dispatch(setForm({ ...form, AssignedTo: newValue }))}
                    label={isDisabled ? 'Project Assigned To' : 'Project Assigned To*'}
                    readOnly={isDisabled}
                    error={isErrorDisplayed && !form.AssignedTo}
                    helperText={isErrorDisplayed && !form.AssignedTo ? 'Required' : ' '}
                    startingName={form.AssignedTo}
                    sx={{ flex: 1, ml: 1 }}
                    testId="wo-assigned-user-dropdown"
                />
            </Box>

            {tab === 'Templates' && (
                <Box sx={{ display: 'flex', my: 2 }}>
                    <TemplateTypeDropdown
                        currTemplateTypeId={form?.TemplateTypeId || 0}
                        onChange={(newTemplateTypeId: number) => {
                            const personalTemplateTypeId = 3;
                            if (newTemplateTypeId === personalTemplateTypeId)
                                dispatch(
                                    setForm({
                                        ...form,
                                        TemplateOwner: fullName,
                                        TemplateTypeId: newTemplateTypeId,
                                    })
                                );
                            else dispatch(setForm({ ...form, TemplateTypeId: newTemplateTypeId }));
                        }}
                        sx={{ flex: 1, mr: 1 }}
                        readOnly={isDisabled}
                    />
                    <UserDropdown
                        setName={newValue => {
                            dispatch(setForm({ ...form, TemplateOwner: newValue }));
                        }}
                        label={'Template Owner'}
                        startingName={form.TemplateOwner}
                        testId="wo-filter-user-assigned-dropdown"
                        sx={{ flex: 1, ml: 1 }}
                        disabled
                    />
                </Box>
            )}

            {/* breaks datagrid rendering when marginY is 4, but fine with 3?? 
            This makes NO sense whatsoever */}

            <FormDivider text={'Attachments'} />

            <WorkOrderAttachmentItems isDisabled={isDisabled} />

            <FormDivider text={'Special Instructions'} />

            <TextField
                variant="outlined"
                label="Special Instructions"
                value={form.Instructions || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    dispatch(setForm({ ...form, Instructions: newValue }));
                }}
                sx={{ width: '100%', mb: 2 }}
                multiline
                rows={3}
                InputProps={{
                    readOnly: isDisabled,
                }}
            />
        </Box>
    );
};

export default WorkOrderFormLeft;
