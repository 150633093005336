import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import { formatDateWithoutTime } from '../../../../common/utils/dateUtil';
import { Batch, BatchOrderMonitor } from '../../batchOrderMonitorModels';
import { ORDER_TAB_VIEW } from '../../constants';
import { BatchIdBarcodeDialog } from './BatchIdBarcodeDialog';

export const BatchViewDataGrid = React.memo(
    ({
        batchOrders,
        handleBatchIdFilterChange,
        handleTabChange,
    }: {
        batchOrders: BatchOrderMonitor[];
        handleBatchIdFilterChange: (value: string) => void;
        handleTabChange: (event: React.SyntheticEvent, value: string) => void;
    }) => {
        const theme = useTheme();
        const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'));
        const [batchBarCode, setBatchBarCode] = React.useState('');
        const [batchBarCodeDialog, setBatchBarCodeDialog] = React.useState(false);

        const uniqueBatchesMap = batchOrders.reduce((acc, order) => {
            if (!acc.has(order.BatchId)) {
                acc.set(order.BatchId, {
                    BatchId: order.BatchId,
                    Client: order.Client,
                    Batched: order.Batched,
                    TotalOrdersCount: 0,
                    CompletedOrdersCount: 0,
                });
            }

            const batch = acc.get(order.BatchId);
            if (!!batch) {
                batch.TotalOrdersCount += 1;
                batch.CompletedOrdersCount += order.QcCompleted ? 1 : 0;
            }

            return acc;
        }, new Map<string, Batch>());

        const batches = Array.from(uniqueBatchesMap.values());
        const lgViewPortcolumns: GridColDef[] = [
            {
                field: 'Batched',
                headerName: 'Date Created',
                flex: 0.5,
                valueGetter: value => formatDateWithoutTime(value),
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'BatchId',
                headerName: 'Batch Id',
                flex: 1,
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'Client',
                headerName: 'Client',
                flex: 0.5,
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'Incomplete Count',
                headerName: 'Incomplete Count',
                renderHeader: (params: GridColumnHeaderParams) => (
                    <React.Fragment>
                        <Tooltip
                            title={"Click on the number to show the batch's incomplete orders."}
                            placement="top-start"
                        >
                            <Box sx={{ display: 'flex', alignContent: 'center' }}>
                                Incomplete Count
                                <InfoOutlinedIcon color="primary" fontSize="small" sx={{ ml: 1 }} />
                            </Box>
                        </Tooltip>
                    </React.Fragment>
                ),
                flex: 1,
                valueGetter: (_, row) => row.TotalOrdersCount - row.CompletedOrdersCount,
                renderCell: params => (
                    <Button
                        onClick={e => {
                            handleBatchIdFilterChange(params.row.BatchId);
                            handleTabChange(e, ORDER_TAB_VIEW);
                        }}
                    >
                        {params.value}
                    </Button>
                ),
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'barCode',
                headerName: 'Barcode',
                flex: 0.5,
                renderCell: params => (
                    <Button
                        onClick={e => {
                            setBatchBarCode(params.row.BatchId);
                            setBatchBarCodeDialog(true);
                        }}
                    >
                        SHOW
                    </Button>
                ),
            },
        ];

        const smViewPortcolumns: GridColDef[] = [
            {
                field: 'BatchId',
                headerName: 'BatchId',
                flex: 1,
            },
        ];

        return (
            <React.Fragment>
                <BatchIdBarcodeDialog
                    batchId={batchBarCode}
                    handleOpenDialog={setBatchBarCodeDialog}
                    showDialog={batchBarCodeDialog}
                />
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'Batched', sort: 'asc' }],
                        },
                    }}
                    disableDensitySelector
                    disableColumnSelector
                    autoHeight
                    getRowId={row => row.BatchId}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    rows={batches || []}
                    columns={isSmallViewPort ? smViewPortcolumns : lgViewPortcolumns}
                    disableRowSelectionOnClick
                    scrollbarSize={20}
                />
            </React.Fragment>
        );
    }
);
