import { Box } from '@mui/material';
import serialApi from '../../serialApi';
import { GetSerialRequest } from '../../serialModels';
import OnHandDataGrid from './OnHandDataGrid';

const OnHandTab = ({ formValues }: { formValues: GetSerialRequest }) => {
    const getSerialsResponse = serialApi.endpoints.getOnHandSerials.useQuery(formValues);

    return (
        <Box>
            <OnHandDataGrid
                data={getSerialsResponse.data || []}
                fileName={`onhand-serials-${formValues.Client}-${formValues.Sku}`}
            />
        </Box>
    );
};

export default OnHandTab;
