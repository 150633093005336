//given a rtk useMutation response, will provide user feedback
//intrusive defined as if action will stop user from taking other action

import { TypedUseMutationResult } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import useSound from 'use-sound';
import { useAppDispatch } from '../../app/store';
import errorSound from '../../common/assets/audio/error.mp3';
import { setDialog, setSnackbar } from '../../features/notification/notificationSlice';
import { isErrorWithMessage, isFetchBaseQueryError, parseErrorMessage } from '../utils/typedCommonUtils';

interface useNotificationByQueryMutationPayload {
    response: TypedUseMutationResult<any, unknown, any>;
    errorMessage: string; //intrusively shows dialog with msg
    successMessage?: string; //nonintrusively shows success snackbar with msg
    shouldPlayErrorAudio?: boolean;
    shouldPlaySuccessAudio?: boolean;
    onFinish?: () => void; //called when done fetching
    onError?: () => void;
    onSuccess?: () => void;
}

export default function useMutationResponse({
    response,
    errorMessage,
    successMessage = '',
    shouldPlayErrorAudio = true,
    onFinish = () => {},
    onError = () => {},
    onSuccess = () => {},
}: useNotificationByQueryMutationPayload): void {
    const dispatch = useAppDispatch();
    const [playErrorAudio] = useSound(errorSound);

    React.useEffect(() => {
        if (!response.isUninitialized && !response.isLoading) {
            displayNotification();
            callFinishedFunctions();
        }
    }, [response.isLoading]);

    const displayNotification = () => {
        if (errorMessage && response.isError) {
            if (shouldPlayErrorAudio) playErrorAudio();

            if (isFetchBaseQueryError(response.error)) {
                const parsedErrorMessage = parseErrorMessage(response?.error);

                dispatch(
                    setDialog({
                        content: `${errorMessage}:\n ${parsedErrorMessage} (${response.error.status})`,
                    })
                );
            } else if (isErrorWithMessage(response.error)) {
                dispatch(
                    setDialog({
                        content: `${errorMessage}:\n ${response.error.message}`,
                    })
                );
            }
        }

        if (response.isSuccess) {
            if (successMessage) {
                dispatch(
                    setSnackbar({
                        content: `${successMessage}`,
                        severity: 'success',
                    })
                );
            }
        }
    };

    const callFinishedFunctions = () => {
        onFinish();

        if (response.isError) {
            onError();
        }

        if (response.isSuccess) {
            onSuccess();
        }
    };
}
