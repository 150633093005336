import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../app/store';
import useNotificationByMutationResponseV2 from '../../../common/hooks/useNotificationByMutationResponseV2';
import { setDialog } from '../../notification/notificationSlice';
import clearSerialApi from '../clearSerialApi';
import { SerialFilter, SerialRecord } from '../clearSerialModels';
import ClearSerialForm from './form/ClearSerialForm';
import SerialStatusDataGrid from './SerialStatusDataGrid';

const ClearSerialElement = () => {
    const dispatch = useAppDispatch();
    const [serialsToClear, setSerialsToClear] = React.useState<SerialRecord[]>([]);
    const [reasonFormValue, setReasonFormValue] = React.useState('');

    const [triggerClearSerials, clearSerialsResponse] = clearSerialApi.endpoints.clearSerials.useMutation();

    useNotificationByMutationResponseV2({
        response: clearSerialsResponse,
        errorMessage: 'Submission failed',
        successMessage: 'Submission succeeded',
    });

    return (
        <Box>
            <ClearSerialForm
                onSubmit={newSerials => {
                    let result: SerialFilter[] = [];
                    for (const newSerial of newSerials) {
                        if (
                            serialsToClear.some(
                                oldSerial =>
                                    oldSerial.Client === newSerial.Client &&
                                    oldSerial.Sku === newSerial.Sku &&
                                    oldSerial.Serial === newSerial.Serial
                            )
                        ) {
                            dispatch(
                                setDialog({
                                    title: 'Invalid Serial',
                                    content: `Can not add same {client, sku, serial} combination as any in list.
                                                {${newSerial.Client}, ${newSerial.Sku}, ${newSerial.Serial}} already exists`,
                                })
                            );
                        } else {
                            result.push(newSerial);
                        }
                    }
                    setSerialsToClear(prev => [...prev, ...result]);
                }}
            />
            <SerialStatusDataGrid
                data={serialsToClear}
                onRowDelete={serial => {
                    setSerialsToClear(prev =>
                        prev.filter(
                            s => s.Client !== serial.Client || s.Sku !== serial.Sku || s.Serial !== serial.Serial
                        )
                    );
                }}
            />
            <form
                onSubmit={e => {
                    e.preventDefault();
                    triggerClearSerials({ Serials: serialsToClear, Reason: reasonFormValue });
                    setReasonFormValue('');
                }}
            >
                <TextField
                    label="Reason"
                    variant="filled"
                    sx={{ my: 2, width: '100%' }}
                    name="Reason"
                    required
                    value={reasonFormValue}
                    onChange={event => {
                        const newValue = event.target.value;
                        setReasonFormValue(newValue);
                    }}
                />

                <Button type="submit" sx={{ width: '100%', my: 2 }} variant="contained">
                    Submit
                </Button>
            </form>
        </Box>
    );
};

export default ClearSerialElement;
