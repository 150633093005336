import { Dayjs } from 'dayjs';

export interface GetOrderDetailsResponse {
    Data: OrderDetailsData[];
    Success: boolean;
    Error: string;
    Message: string;
}

export interface EditSerialsResponse {
    Data: Serial[];
    Success: boolean;
    Error: string;
    Message: string;
}

export interface GetHistoryOrderResponse {
    Data: HistoryOrder[];
    Success: boolean;
    Error: string;
    Message: string;
}

export interface OrderDetailsData {
    OrderData: OrderData;
    Billing: Customer;
    Shipping: Customer;
    OrdMemo: OrdMemo;
    Items: Item[];
    Shipments: Shipment[];
    Invoices: Invoice[];
    QcLogs: QcLog[];
    MomCode: string;
}

export interface OrderData {
    InternetId: string;
    OrderDate: Dayjs;
    Completed: boolean;
    PoNumber: string;
    ShipList: string;
    Order: number;
    Canceled: boolean;
    AltOrder: string;
    AdCode: string;
    OnHold: boolean;
    TimeImportedToMom: Dayjs;
}

export interface Customer {
    FirstName: string;
    LastName: string;
    Company: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    ZipCode: string;
    Country: Country;
    Phone: string;
    Email: string;
}

export interface Country {
    Iso2: string;
    Code: string;
    Name: string;
}

export interface Item {
    ItemId: number;
    Item: string;
    Description: string;
    Inpart: string;
    QuantityOrder: number;
    QuantityBacklog: number;
    QuantityShip: number;
    UnitPrice: number;
    GrossWeight: number;
    ReturnCode: string;
    Return: boolean;
    CustomInfo: string;
    IsSerialized: boolean;
    AllowSerialDups: boolean;
    SerialRegex: string;
    Serial2Regex: string;
    HasSerials2Table: boolean;
    HasSerials2Field: boolean;
    Status: string;
    Serials: Serial[];
    Ssccs: Sscc[];
}

export interface Shipment {
    ShipMethod: string;
    TrackingNumber: string;
    Inpart: string;
    Order: number;
    NetWeight: number;
    ShipDate: Dayjs;
}

export interface Serial {
    Serial: string;
    Serial2: string;
    ItemId: number;
    OrderId: number;
    Id: number | null;
}

export interface Sscc {
    SsccNumber: number;
    UnitsInBox: number;
    BoxNumber: number;
    TotalBoxes: number;
    TrackingNumber: string;
}

export interface OrdMemo {
    Notes: string;
    Fullfill: string;
}

export interface HistoryOrder {
    Id: number;
    Tokens: string;
    SrcCode: string;
    OrderStatus: string;
    ShipMethod: string;
    PoNumber: string;
    Session: string;
    RecordCreated: Dayjs;
    OrderTotal: number;
    CatCode: string;
    Note1: string;
    Note2: string;
    Note3: string;
    Note4: string;
    CustomNote: string;
    CustomNote2: string;
    OrderDate: Dayjs;
    Imported: Dayjs;
    DatabaseName: string;
    BillTo: HistoryCustomer;
    ShipTo: HistoryCustomer;
    Items: HistoryItem[];
    Metadatas: HistoryOrderMetadata[];
}

export interface HistoryItem {
    Id: number;
    ProductId: string;
    Description: string;
    Weight: number;
    Qty: number;
    Price: number;
    LineId: number;
    LineId1: string;
    Metadatas: HistoryItemMetadata[];
}

export interface HistoryCustomer extends Customer {
    Id: number;
    Metadatas: HistoryCustomerMetadata[];
}

export interface HistoryMetadata {
    Key: string;
    Value: string;
    CreatedAt: Dayjs;
}

export interface HistoryOrderMetadata extends HistoryMetadata {}

export interface HistoryItemMetadata extends HistoryMetadata {}

export interface HistoryCustomerMetadata extends HistoryMetadata {}

export interface Invoice {
    Order: string;
    Inpart: string;
    IncoTerms: string;
    InvDate: Dayjs;
}
export interface QcLog {
    Order: string;
    DateTime: Dayjs;
    User: string;
    ScanQty: number;
    Action: string;
    Upc: string;
    Serial: string;
    Error: boolean;
    System: string;
    SessionId: string;
}
export class SerialClass implements Serial {
    Serial: string;
    Serial2: string;
    ItemId: number;
    OrderId: number;
    Id: number | null;
    constructor(itemId: number, orderId: number, serial: string = '', serial2: string = '', id = null) {
        this.Serial = serial;
        this.Serial2 = serial2;
        this.ItemId = itemId;
        this.OrderId = orderId;
        this.Id = id;
    }
}
