// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_RMA_ITEMS_URL, POST_ADD_SERIAL_URL, POST_RMA_SERIAL_URL } from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';
import { AddSerialSubmitPayload } from './addSerialModels';
import { getMomCode } from '../../common/utils/commonUtils';

// Define a service using a base URL and expected endpoints
export default createApi({
    reducerPath: 'addSerialApi',
    baseQuery: dreamBaseQueryWithReauth,
    endpoints: builder => ({
        postAddSerial: builder.mutation<void, Partial<AddSerialSubmitPayload>>({
            query: body => ({
                url: `${POST_ADD_SERIAL_URL}`,
                method: 'POST',
                body,
                headers: {
                    RushOrderMomCode: getMomCode(body.InvoiceNumber),
                },
            }),
        }),
    }),
});
