import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { memo } from 'react';
import { useAppSelector } from '../../../../../../app/store';
import receiversApi from '../../../../receiversApi';
import { History } from '../../../../receiversModels';
import MemoTextField from '../formControlledInputs/MemoTextField';
import EditHistoryCard from './EditHistoryCard';

const EditHistory = memo(() => {
    // const { history } = useSelector((state: RootStateOrAny) => state.receivers);
    const client = useAppSelector(state => state.receivers.form.Client);
    const receiverNumber = useAppSelector(state => state.receivers.form.Number);

    const getReceiversHistoryResponse = receiversApi.endpoints.getReceiversHistory.useQuery(
        !!receiverNumber ? { momCode: client, receiverNumber } : skipToken
    );

    const historyCards = getReceiversHistoryResponse.data?.map((historyElement: History) => (
        <EditHistoryCard historyElement={historyElement} key={historyElement.Id} />
    ));

    return getReceiversHistoryResponse.isError ? (
        <MemoTextField
            label={''}
            value={'No Edit History Available'}
            onChange={function (newValue: string): void {}}
            disabled={true}
        />
    ) : (
        <Accordion elevation={8}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Edit History</Typography>
            </AccordionSummary>
            <AccordionDetails>{historyCards}</AccordionDetails>
        </Accordion>
    );
});

export default EditHistory;
