import { TextField, Tooltip } from '@mui/material';

export function QcLogPanelReadOnlyTextField({
    label,
    value,
    cursorType = 'auto',
}: {
    label: string;
    value: string | number | boolean | undefined | null;
    cursorType?: string;
}) {
    const displayValue = () => {
        if (typeof value == 'boolean') {
            return value ? 'Yes' : 'No';
        }
        return value ? value : '-';
    };

    return (
        <Tooltip title={value} placement="top-start">
            <TextField
                value={displayValue()}
                InputProps={{ readOnly: true, disableUnderline: true, style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 13 } }}
                label={label}
                variant="standard"
                sx={{ mr: 1, input: { cursor: cursorType } }}
                size="small"
            />
        </Tooltip>
    );
}
