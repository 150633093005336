import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Link,
    Tooltip,
    Box,
} from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import DeleteIcon from '@mui/icons-material/Delete';
import { overrideForm } from '../../workOrderSlice';
import InputDialog from '../../../../common/components/Dialog/InputDialog';
import AttachmentItemForm from './AttachmentItemForm';
import { AttachmentItem } from '../../workOrderModels';
import dayjs from 'dayjs';

interface AttachmentItemListProps {
    isDisabled: boolean;
}

const AttachmentItemList = ({ isDisabled }: AttachmentItemListProps) => {
    const attachmentItems = useAppSelector(state => state.workOrder.form.AttachmentItems);
    const dispatch = useAppDispatch();

    const [updateItemProps, setUpdateItemProps] = React.useState<{
        isOpen: boolean;
        indexToUpdate: number;
    }>({ isOpen: false, indexToUpdate: -1 });

    const handleItemDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: AttachmentItem) => {
        event.stopPropagation();
        if (isDisabled) return;

        const indexToRemove = attachmentItems.map(currItem => JSON.stringify(currItem)).indexOf(JSON.stringify(row));
        if (indexToRemove === -1) return;
        let newItemsList = [...attachmentItems];

        if (row.Id) {
            newItemsList[indexToRemove] = {
                ...newItemsList[indexToRemove],
                DeleteDateTime: dayjs().format(),
            };
        } else {
            newItemsList.splice(indexToRemove, 1);
        }
        dispatch(overrideForm({ AttachmentItems: newItemsList }));
    };

    const handleEditItem = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => {
        event.stopPropagation();
        if (isDisabled) return;

        const indexToUpdate = attachmentItems.map(currItem => JSON.stringify(currItem)).indexOf(JSON.stringify(row));
        if (indexToUpdate === -1) return;

        setUpdateItemProps({
            isOpen: true,
            indexToUpdate: indexToUpdate,
        });
    };

    const handleUpdateItemDialogClose = () => {
        setUpdateItemProps({
            isOpen: false,
            indexToUpdate: -1,
        });
    };

    const attachmentItemsToShow = attachmentItems?.filter((row: AttachmentItem) => !row.DeleteDateTime);

    return (
        <Box sx={{ display: attachmentItemsToShow?.length ? '' : 'none' }}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell align="right" sx={{ display: isDisabled ? 'none' : 'block' }}>
                                Delete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ cursor: isDisabled ? '' : 'pointer' }}>
                        {attachmentItemsToShow?.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={event => handleEditItem(event, row)}
                            >
                                <TableCell component="th" scope="row">
                                    <Tooltip title={row.Url} placement="right">
                                        <Link href={row.Url} target="_blank" rel="noopener">
                                            {row.Name}
                                        </Link>
                                    </Tooltip>
                                </TableCell>

                                <TableCell align="right" sx={{ display: isDisabled ? 'none' : 'block' }}>
                                    <IconButton
                                        edge="end"
                                        onClick={(event: any) => handleItemDelete(event, row)}
                                        disabled={isDisabled}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <InputDialog
                title={'Update Attachment Item'}
                isHidden={!updateItemProps.isOpen}
                handleClose={handleUpdateItemDialogClose}
                bodyElement={
                    <AttachmentItemForm
                        isDisabled={false}
                        indexToUpdate={updateItemProps.indexToUpdate}
                        handleOnSubmit={handleUpdateItemDialogClose}
                    />
                }
            />
        </Box>
    );
};

export default AttachmentItemList;
