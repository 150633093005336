import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import QcLogDisplay from './QcLogDisplay';
import { QcLogDisplayHeader } from './QcLogDisplayHeader';

export const QcLogDialog = React.memo(
    ({
        invoice,
        setQcLogDialogOpen,
        showDialog = false,
    }: {
        invoice: string;
        setQcLogDialogOpen: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            setQcLogDialogOpen(false);
        };

        return (
            <Dialog open={showDialog} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogCloseButton handleClose={handleClose} />
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Box sx={{ pl: 3 }}>{invoice}</Box>
                        <Box sx={{ pr: 2 }}>
                            <QcLogDisplayHeader invoice={invoice} />
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <QcLogDisplay invoice={invoice} />
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
);
