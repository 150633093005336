import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReceivedItem, ReceivedItemSerial, ReceivedItemUnitDetail } from '../../rmaModels';

export const ScannedSerialsDisplay = ({
    serials,
    receivedItem,
    isSerialized = true,
    missingSerialCount,
}: {
    serials: ReceivedItemSerial[];
    receivedItem: ReceivedItem;
    isSerialized?: boolean;
    missingSerialCount: number;
}) => {
    return (
        <React.Fragment>
            {missingSerialCount > 0 || serials.length > 0 ? (
                <Box>
                    <Typography variant="body2">SCANNED SERIALS:</Typography>
                    {!isSerialized && receivedItem.Serials.length <= 0 && (
                        <Typography color="warning.main" variant="body2">
                            THIS ITEM IS NOT SERIALIZED
                        </Typography>
                    )}
                    <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
                        {serials.map((serial: ReceivedItemSerial) => {
                            return (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                    <Typography sx={{ flex: 1 }} color="primary">
                                        {serial?.Serial1 || '-'}
                                    </Typography>
                                    {!!receivedItem.SerialVerify?.Serial2Alias && (
                                        <Typography sx={{ flex: 1, mb: 1 }} color="success.main">
                                            {serial?.Serial2 || '-'}
                                        </Typography>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    {missingSerialCount > 0 && (
                        <Typography color="error">
                            MISSING {missingSerialCount} SERIAL{missingSerialCount > 1 ? 'S' : ''}
                        </Typography>
                    )}
                </Box>
            ) : (
                <Box></Box>
            )}
        </React.Fragment>
    );
};
