import { useEffect } from 'react';
import { useAppDispatch } from '../../app/store';
import { setShouldShowNavigationWarningDialog } from '../../features/notification/notificationSlice';

export default function useNavigationWarning({
    shouldShowWarningOnNavigationChange,
}: {
    shouldShowWarningOnNavigationChange: boolean;
}) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (shouldShowWarningOnNavigationChange) {
            dispatch(setShouldShowNavigationWarningDialog(true));
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }

        return () => {
            dispatch(setShouldShowNavigationWarningDialog(false));
        };
    }, [shouldShowWarningOnNavigationChange]);
}
