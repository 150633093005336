import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../common/components/Dialog/DialogCloseButton';

export const LotNumberCapturedDialog = React.memo(
    ({
        handleOpenDialog,
        showDialog = false,
    }: {
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth={'sm'} onClose={handleClose} onKeyDown={handleClose}>
                <DialogTitle color="white" bgcolor="success.main" sx={{ px: 3 }}>
                    RAPID QC COMPLETE
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ p: 4, flex: 6 }}>
                            <Typography variant="h6">BATCH IS READY TO BE SHIPPED</Typography>
                        </Box>
                        <Box sx={{ flex: 6 }}>
                            <Button size="large" variant="contained" sx={{ flex: 1, px: 7 }} onClick={handleClose}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
