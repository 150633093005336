import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SyntheticEvent, useState } from 'react';
import { useAppDispatch } from '../../../../app/store';
import { CompanyDropdownV2 } from '../../../../common/components/dropdown/CompanyDropdownV2';
import DateRangePickerPopover from '../../../../common/components/popover/DateRangePickerPopover';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import oldReceiversApi from '../../oldReceiversApi';
import { setGetReceiversRequest } from '../../oldReceiversSlice';
import { Filter, GetReceiversRequest } from '../../receiversModels';

const ReceiversFilter = () => {
    const dispatch = useAppDispatch();

    const [filter, setFilter] = useState<Filter>({
        momCode: '',
        startDate: '',
        endDate: '',
    });

    const [triggerGetReceivers, receiversResponse] = oldReceiversApi.endpoints.getReceiversDeprecated.useLazyQuery();
    const handleFilterClick = (event: SyntheticEvent<HTMLButtonElement>) => {
        const getReceiversRequest: GetReceiversRequest = {
            momCode: filter.momCode,
            startDate: filter.startDate || '',
            endDate: filter.endDate || '',
            active: true,
        };

        dispatch(setGetReceiversRequest(getReceiversRequest));
        triggerGetReceivers(getReceiversRequest);
    };
    useNotificationByResponse({
        isFetching: receiversResponse.isFetching,
        isError: receiversResponse.isError,
        errorContent: 'Could not get receivers',
        isUninitialized: receiversResponse.isUninitialized,
    });

    return (
        <Box sx={{ p: 2 }}>
            <CompanyDropdownV2
                setCompany={newValue => {
                    if (newValue) {
                        setFilter({ ...filter, momCode: newValue });
                    }
                }}
                label={'Company'}
                autoFocus={true}
                key="select-company"
            />

            <Box sx={{ display: 'flex', mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePickerPopover
                        startDate={filter.startDate}
                        endDate={filter.endDate}
                        textFieldProps={{ label: 'Created Date', sx: { width: '100%' } }}
                        sx={{ flex: 1 }}
                        onStartDateChange={newValue => {
                            if (newValue) {
                                setFilter(oldFilter => {
                                    return { ...oldFilter, startDate: newValue };
                                });
                            }
                        }}
                        onEndDateChange={newValue => {
                            if (newValue) {
                                setFilter(oldFilter => {
                                    return { ...oldFilter, endDate: newValue };
                                });
                            }
                        }}
                    />
                </LocalizationProvider>
                <Button variant="contained" color="primary" onClick={handleFilterClick} sx={{ m: 1 }}>
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default ReceiversFilter;
