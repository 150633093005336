import { useEffect } from 'react';

//calls submitScanner() when textFieldInput idle for more than sec. Used to detect if scanner used
export default function useScanner(textFieldInput, submitScanner) {
    //WHI uses 'Enter' as seperator, but zebra returns 'Control'
    useEffect(() => {
        let ignore = false;
        if (textFieldInput) {
            setTimeout(() => {
                if (textFieldInput.toString().length > 3 && !ignore) {
                    submitScanner();
                }
            }, 500);
        }

        return () => {
            ignore = true;
        };
    }, [textFieldInput]);
}
