import { GET_COMPANIES_URL, GET_USERS_URL } from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependRoapiUrl } from '../../app/services/util';
import { Company, User } from './componentsModel';

export default baseApi.injectEndpoints({
    endpoints: builder => ({
        getCompanies: builder.query<Company[], boolean>({
            query: params => ({
                url: `${prependRoapiUrl(GET_COMPANIES_URL)}`,
                method: 'GET',
                params: { source: 'DreamReport', isActive: params },
            }),
        }),
        getUsers: builder.query<User[], void>({
            query: () => ({
                url: `${prependRoapiUrl(GET_USERS_URL)}`,
                method: 'GET',
            }),
        }),
    }),
});
