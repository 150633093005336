import { Autocomplete, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import NumberTextfieldV2 from '../../../../common/components/TextField/NumberTextfieldV2';
import { CompanyDropdownV2 } from '../../../../common/components/dropdown/CompanyDropdownV2';
import DateRangePickerPopover from '../../../../common/components/popover/DateRangePickerPopover';
import receiversApi from '../../receiversApi';
import { GetReceiversRequest } from '../../receiversModels';
import { resetFilter, setFilter } from '../../receiversSlice';

const ReceiversFilter = ({ triggerGetReceivers }: { triggerGetReceivers: (filter: GetReceiversRequest) => void }) => {
    const filter = useAppSelector(state => state.receivers.filter);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        triggerGetReceivers(filter);
    }, []);

    const handleFilterClick = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (filter.ReceiverNumber) triggerGetReceivers({ ReceiverNumber: filter.ReceiverNumber });
        else triggerGetReceivers(filter);
    };

    const getReceiversConfigResponse = receiversApi.endpoints.getReceiversConfig.useQuery('INVALID MOM CODE');
    const ProjectOptions =
        getReceiversConfigResponse?.data?.Rules?.find(rule => rule.Label === 'Project')
            ?.Options.map(option => option.Label)
            ?.sort() || [];

    return (
        <Box sx={{ p: 2 }}>
            <form onSubmit={handleFilterClick}>
                <Box sx={{ display: 'flex' }}>
                    <CompanyDropdownV2
                        setCompany={newValue => {
                            dispatch(setFilter({ MomCode: newValue || undefined }));
                        }}
                        label={'Company'}
                        autoFocus={true}
                        key="select-company"
                        sx={{ flex: 2, m: 1 }}
                        startingMomCode={filter?.MomCode}
                    />
                    <NumberTextfieldV2
                        value={filter.ReceiverNumber || 0}
                        onChange={newValue => {
                            dispatch(setFilter({ ReceiverNumber: newValue === 0 ? undefined : newValue }));
                        }}
                        textFieldProps={{ label: 'Receiver #', sx: { flex: 1, m: 1 } }}
                        shouldOnlyAllowValidNumberOnChange={true}
                    />

                    <Autocomplete
                        renderInput={params => <TextField {...params} label={'Project Type'} />}
                        value={filter.ProjectType || null}
                        onChange={(event, newValue) => {
                            dispatch(setFilter({ ProjectType: newValue || undefined }));
                        }}
                        options={ProjectOptions}
                        sx={{ flex: 2, m: 1 }}
                    />
                </Box>

                <Box sx={{ display: 'flex', my: 2 }}>
                    <TextField
                        label="PO"
                        name="Po"
                        sx={{ flex: 2, m: 1 }}
                        value={filter?.Po || ''}
                        onChange={event => {
                            const newValue = event.target.value;
                            dispatch(setFilter({ Po: newValue || undefined }));
                        }}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePickerPopover
                            startDate={filter.StartDate}
                            endDate={filter.EndDate}
                            textFieldProps={{ label: 'Received Date', sx: { width: '100%' } }}
                            sx={{ flex: 1, m: 1 }}
                            onStartDateChange={newValue => {
                                dispatch(setFilter({ StartDate: newValue }));
                            }}
                            onEndDateChange={newValue => {
                                dispatch(setFilter({ EndDate: newValue }));
                            }}
                            maxDurationDays={180}
                        />
                    </LocalizationProvider>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={filter.IsPlayed}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newValue = event?.target?.checked;
                                    dispatch(setFilter({ IsPlayed: newValue }));
                                }}
                            />
                        }
                        label="Is Played"
                        sx={{ ml: 1 }}
                    />
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Button
                        sx={{ flex: 1, mx: 2 }}
                        variant="contained"
                        onClick={() => {
                            dispatch(resetFilter());
                        }}
                        color={'secondary'}
                    >
                        Reset Filter
                    </Button>

                    <Button variant="contained" color="primary" type="submit" sx={{ flex: 5, mx: 2 }}>
                        Search
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default ReceiversFilter;
