import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';
import QcLogDisplay from './QcLogDisplay';
import { QcLogDisplayHeader } from './QcLogDisplayHeader';

export default function QcLogMenu() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const qcLogs = getOrderDetailsResponse.data?.Data[0]?.QcLogs;
    const invoices = Array.from(new Set(qcLogs?.map(log => log.Order))).sort();

    return (
        <Box sx={{ display: 'flex', mb: 1, width: 1 }}>
            <Box sx={{ flex: 8 }}>
                {invoices.map(invoice => (
                    <Accordion sx={{ boxShadow: 1 }} defaultExpanded={invoices.length == 1}>
                        <AccordionSummary
                            aria-controls="order-content"
                            id="order-header"
                            expandIcon={<GridExpandMoreIcon />}
                        >
                            <Grid container justifyContent="space-between">
                                <Typography variant="h6" sx={{ ml: 4, color: 'primary.main' }}>
                                    {invoice}
                                </Typography>
                                <QcLogDisplayHeader invoice={invoice} />
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <QcLogDisplay invoice={invoice} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
}
