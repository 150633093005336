import { red } from '@mui/material/colors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DialogPayload, NotificationByResponsePayload, NotificationPayload } from './notificationModels';

export interface NotificationState {
    snackbarOpen: boolean;
    snackbarContent: string;
    snackbarSeverity: string;
    dialogOpen: boolean;
    dialogTitle: string;
    dialogContent: string;
    dialogColor: string;
    spinnerCount: number; //blocking calls
    nonIntrusiveSpinnerCount: number; //non blocking calls
    shouldShowNavigationWarningDialog: boolean;
    isNavigationWarningDialogOpen: boolean;
    navigationWarningDialogDestination: string;
    navigationWarningDialogTitle: string;
    navigationWarningDialogContent: string;
}

const initialState: NotificationState = {
    snackbarOpen: false,
    snackbarContent: '',
    snackbarSeverity: '',
    dialogOpen: false,
    dialogTitle: '',
    dialogContent: '',
    dialogColor: red['A100'],
    spinnerCount: 0,
    nonIntrusiveSpinnerCount: 0, //shows spinner if greater than 1 where each async fetch incr and response decr
    shouldShowNavigationWarningDialog: false, //will prompt user if trying to leave current page to home page, else does navs without prompt
    isNavigationWarningDialogOpen: false, //whether navigation warning dialog is open or not
    navigationWarningDialogDestination: '/',
    navigationWarningDialogTitle: 'Possibly Unsaved Changes',
    navigationWarningDialogContent: 'Are you sure you want to exit?',
};

const slice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setSnackbar: (state, action: PayloadAction<NotificationPayload>) => {
            state.snackbarOpen = true;
            state.snackbarContent = action.payload.content;
            state.snackbarSeverity = action.payload.severity || 'success';
        },
        resetSnackbar: (state, action: PayloadAction<void>) => {
            state.snackbarOpen = false;
            state.snackbarContent = '';
            state.snackbarSeverity = '';
        },
        setDialog: (state, action: PayloadAction<DialogPayload>) => {
            state.dialogOpen = true;
            state.dialogTitle = action.payload.title || 'Error';
            state.dialogContent = action.payload.content;
            state.dialogColor = action.payload.color || red['A100'];
        },
        resetDialog: (state, action: PayloadAction<void>) => {
            state.dialogOpen = false;
            state.dialogTitle = '';
            state.dialogContent = '';
            state.dialogColor = red['A100'];
        },
        incrementIsSpinnerOpen: state => {
            state.spinnerCount += 1;
        },
        decrementIsSpinnerOpen: state => {
            if (state.spinnerCount > 0) state.spinnerCount -= 1;
        },
        resetSpinner: state => {
            state.spinnerCount = 0;
        },
        incrementIsNonIntrusiveSpinnerOpen: state => {
            state.nonIntrusiveSpinnerCount += 1;
        },
        decrementIsNonIntrusiveSpinnerOpen: state => {
            if (state.nonIntrusiveSpinnerCount > 0) state.nonIntrusiveSpinnerCount -= 1;
        },
        updateNotificationByResponse: (state, action: PayloadAction<NotificationByResponsePayload>) => {
            if (action.payload.isError) {
                state.dialogOpen = true;
                state.dialogTitle = 'Error';
                state.dialogContent = action.payload.errorContent || '';
                state.dialogColor = red['A100'];
            } else if (action.payload.isSuccess) {
                state.snackbarOpen = true;
                state.snackbarContent = action.payload.successContent || 'Action successful';
                state.snackbarSeverity = 'success';
            }
        },

        setShouldShowNavigationWarningDialog: (state, action: PayloadAction<boolean>) => {
            state.shouldShowNavigationWarningDialog = action.payload;
        },
        setNavigationWarningDialogDestination: (state, action: PayloadAction<string>) => {
            state.navigationWarningDialogDestination = action.payload;
        },
        setIsNavigationWarningDialogOpen: (state, action: PayloadAction<boolean>) => {
            state.isNavigationWarningDialogOpen = action.payload;
            if (!action.payload) state.navigationWarningDialogDestination = '/';
        },
    },
});

export const {
    setSnackbar,
    resetSnackbar,
    setDialog,
    resetDialog,
    incrementIsSpinnerOpen,
    decrementIsSpinnerOpen,
    updateNotificationByResponse,
    incrementIsNonIntrusiveSpinnerOpen,
    decrementIsNonIntrusiveSpinnerOpen,
    resetSpinner,
    setShouldShowNavigationWarningDialog,
    setNavigationWarningDialogDestination,
    setIsNavigationWarningDialogOpen,
} = slice.actions;

export default slice.reducer;
