import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Chip, List, ListItem, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';
import { QcLog } from '../../orderLookupModels';
import QcLogCollapseList from './QcLogCollapseList';
import { failedActions, inProgressActions, omittedActions, successfulActions } from './QcLogConstants';

export default function QcLogDisplay({ invoice }: { invoice: string }) {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const qcLogs = getOrderDetailsResponse.data?.Data[0]?.QcLogs;
    const [qcLogSections, setQcLogSections] = React.useState<QcLog[][]>([]);
    const getQcLogDialogData = (invoice?: string) => {
        const selectedQcLog = qcLogs?.filter(qcLog => qcLog.Order === invoice && !omittedActions.has(qcLog.Action));
        return selectedQcLog;
    };

    const getOrderStatusResponse = orderLookupApi.endpoints.getOrderStatus.useQuery(!!invoice ? invoice : skipToken);
    const orderStatus = getOrderStatusResponse.data;

    const getStatusColor = () => {
        if (!!orderStatus && failedActions.has(orderStatus.toUpperCase())) {
            return 'warning';
        }
        if (!!orderStatus && successfulActions.has(orderStatus.toUpperCase())) {
            return 'success';
        }

        if (!!orderStatus && inProgressActions.has(orderStatus.toUpperCase())) {
            return 'primary';
        }
        return 'default';
    };

    const getStatusIcon = () => {
        if (!!orderStatus && failedActions.has(orderStatus.toUpperCase())) {
            return <DoDisturbOnOutlinedIcon />;
        }
        if (!!orderStatus && successfulActions.has(orderStatus.toUpperCase())) {
            return <CheckIcon />;
        }
        if (!!orderStatus && inProgressActions.has(orderStatus.toUpperCase())) {
            return <PlayArrowIcon />;
        }
        return <PauseIcon />;
    };

    const getOrderStatusString = () => {
        return !!orderStatus ? orderStatus.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase() : '';
    };

    const getDateString = () => {
        const invoiceQcLog = getQcLogDialogData(invoice);
        if (!!!invoiceQcLog) {
            return '';
        }
        if (
            dayjs(invoiceQcLog[0].DateTime).format('M/DD/YY') ==
            dayjs(invoiceQcLog[invoiceQcLog.length - 1]?.DateTime).format('M/DD/YY')
        ) {
            return dayjs(invoiceQcLog[0].DateTime).format('M/DD/YY');
        }
        return `${dayjs(invoiceQcLog[0].DateTime).format('M/DD/YY')} to ${dayjs(
            invoiceQcLog[invoiceQcLog.length - 1]?.DateTime
        ).format('M/DD/YY')}`;
    };

    // Logic to group logs together by consecutive same user and system
    const assignQcLogSections = (qcLogList?: QcLog[]): QcLog[][] => {
        if (!!!qcLogList) {
            return [];
        }
        const tempQcLogSections: QcLog[][] = [];
        let currentSection: QcLog[] = [];

        for (let i = 0; i < qcLogList.length; i++) {
            if (
                currentSection.length === 0 ||
                (qcLogList[i].User === currentSection[0].User && qcLogList[i].System == currentSection[0].System)
            ) {
                // If the current section is empty or the field matches, add to the current section
                currentSection.push(qcLogList[i]);
            } else {
                // If the field does not match, push the current section and start a new one
                tempQcLogSections.push(currentSection);
                currentSection = [qcLogList[i]];
            }
        }

        // Add the last section if it exists
        if (currentSection.length > 0) {
            tempQcLogSections.push(currentSection);
        }
        return tempQcLogSections;
    };

    useEffect(() => {
        setQcLogSections(assignQcLogSections(getQcLogDialogData(invoice)));
    }, [qcLogs]);

    return (
        <Box>
            <Box sx={{ px: 4, pb: 1 }} alignItems="center" display="flex" justifyContent="space-between" width="100%">
                <Box>
                    <Typography variant="button" sx={{ pr: 1 }}>
                        OVERALL STATUS:
                    </Typography>
                    <Chip
                        color={getStatusColor()}
                        label={getOrderStatusString()}
                        deleteIcon={getStatusIcon()}
                        onDelete={() => {}} // Needs this property to have the icon be shown on the right
                        size="small"
                    />
                </Box>
                <Typography variant="button">{getDateString()}</Typography>
            </Box>
            <List>
                {qcLogSections.map(qcLogSection => (
                    <ListItem sx={{ my: -4 }}>
                        <QcLogCollapseList qcLogSection={qcLogSection} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}
