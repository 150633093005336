import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { overrideForm } from '../../workOrderSlice';
import InputDialog from '../../../../common/components/Dialog/InputDialog';
import MaterialsItemForm from './MaterialsItemForm';
import dayjs from 'dayjs';
import { BillOfMaterialsItem } from '../../workOrderModels';
interface MaterialsItemListProps {
    isDisabled: boolean;
}

const MaterialsItemList = ({ isDisabled }: MaterialsItemListProps) => {
    const billsOfMaterialsItems = useAppSelector(state => state.workOrder.form.BillsOfMaterialsItems);
    const dispatch = useAppDispatch();

    const [updateMaterialsItemProps, setUpdateMaterialsItemProps] = React.useState<{
        isOpen: boolean;
        indexToUpdate: number;
    }>({ isOpen: false, indexToUpdate: -1 });
    const handleItemDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: any) => {
        event.stopPropagation();
        const indexToRemove = billsOfMaterialsItems
            .map(currItem => JSON.stringify(currItem))
            .indexOf(JSON.stringify(row));
        if (indexToRemove === -1) return;

        let newItemsList = [...billsOfMaterialsItems];

        if (row.Id) {
            newItemsList[indexToRemove] = {
                ...newItemsList[indexToRemove],
                DeleteDateTime: dayjs().format(),
            };
        } else {
            newItemsList.splice(indexToRemove, 1);
        }

        dispatch(overrideForm({ BillsOfMaterialsItems: newItemsList }));
    };

    const handleEditItem = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => {
        event.stopPropagation();
        if (isDisabled) return;

        const indexToUpdate = billsOfMaterialsItems
            .map(currItem => JSON.stringify(currItem))
            .indexOf(JSON.stringify(row));
        if (indexToUpdate === -1) return;

        setUpdateMaterialsItemProps({
            isOpen: true,
            indexToUpdate: indexToUpdate,
        });
    };

    const handleUpdateBillingItemDialogClose = () => {
        setUpdateMaterialsItemProps({
            isOpen: false,
            indexToUpdate: -1,
        });
    };

    const billOfMaterialsItemToShow = billsOfMaterialsItems?.filter((row: BillOfMaterialsItem) => !row.DeleteDateTime);

    return (
        <Box sx={{ my: 2, display: billOfMaterialsItemToShow?.length ? '' : 'none' }}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Material</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell align="right" sx={{ display: isDisabled ? 'none' : '' }}>
                                Delete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ cursor: isDisabled ? '' : 'pointer' }}>
                        {billOfMaterialsItemToShow?.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={event => handleEditItem(event, row)}
                            >
                                <TableCell component="th" scope="row">
                                    {row.Primary?.Primary}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.Secondary?.Name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.Quantity}
                                </TableCell>
                                <TableCell align="right" sx={{ display: isDisabled ? 'none' : 'block' }}>
                                    <IconButton
                                        edge="end"
                                        onClick={(event: any) => handleItemDelete(event, row)}
                                        disabled={isDisabled}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <InputDialog
                title={'Update Materials Item'}
                isHidden={!updateMaterialsItemProps.isOpen}
                handleClose={handleUpdateBillingItemDialogClose}
                bodyElement={
                    <MaterialsItemForm
                        isDisabled={false}
                        indexToUpdate={updateMaterialsItemProps.indexToUpdate}
                        handleOnSubmit={handleUpdateBillingItemDialogClose}
                    />
                }
            />
        </Box>
    );
};

export default MaterialsItemList;
