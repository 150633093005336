import { TextField } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

import React, { FC, memo } from 'react';

interface MemoDateTimePickerProps {
    label: string;
    value: Dayjs;
    disabled: boolean;
    onChange: (newValue: Dayjs) => void;
}

const MemoDateTimePicker: FC<MemoDateTimePickerProps> = memo(({ label, value, disabled, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                label={label}
                value={value}
                onChange={newValue => {
                    onChange(newValue ?? dayjs());
                }}
                sx={{ width: '100%', mt: 2 }}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
});

export default MemoDateTimePicker;
