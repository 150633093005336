import EditIcon from '@mui/icons-material/Edit';
import PlayCircle from '@mui/icons-material/PlayCircle';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Backdrop, Box, Link, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React from 'react';
import { getReceiverPdfUrl } from '../../../../app/services/urlFunctions';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import ConfirmDialog from '../../../../common/components/Dialog/ConfirmDialog';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import receiversApi from '../../receiversApi';
import { PlayReceiverPayload, PlayReceiverPayloadItem } from '../../receiversModels';
import { setIsDialogOpen, setMode } from '../../receiversSlice';

const CreatedReceiverSpeedDial = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const mode = useAppSelector(state => state.receivers.mode);
    const { Number, Played, Items, Client } = useAppSelector(state => state.receivers.form);
    const dispatch = useAppDispatch();

    const [isPlayReceiverConfirmationDialogOpen, setIsPlayReceiverConfirmationDialogOpen] = React.useState(false);
    const [triggerPlayReceivers, playReceiversResponse] = receiversApi.endpoints.playReceiver.useMutation();

    useNotificationByResponse({
        isFetching: playReceiversResponse.isLoading,
        isError: playReceiversResponse.isError,
        errorContent: 'Unable to play receiver: ' + JSON.stringify(playReceiversResponse.error),
        isSuccess: playReceiversResponse.isSuccess,
        successContent: 'Receiver was played',
        isUninitialized: playReceiversResponse.isUninitialized,
    });

    const actions = [
        {
            icon: mode === 'Edit' ? <VisibilityIcon /> : <EditIcon />,
            name: mode === 'Edit' ? 'View' : 'Edit',
            handleClick: () => {
                const newMode = mode === 'Edit' ? 'View' : 'Edit';
                dispatch(setMode(newMode));
            },
        },
        {
            icon: <PlayCircle />,
            name: Played ? 'Already Played' : 'Play',
            handleClick: () => {
                setIsPlayReceiverConfirmationDialogOpen(true);
            },
        },
        {
            icon:
                Client && Number ? (
                    <Link
                        href={getReceiverPdfUrl(Client, Number)}
                        color="inherit"
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <PrintIcon />
                    </Link>
                ) : (
                    <PrintIcon />
                ),
            name: 'Print',
            handleClick: () => {},
        },
    ];

    return Number ? (
        <Box
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.modal + 1,
            }}
        >
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="Work Order Actions"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClick={open ? handleClose : handleOpen}
                open={open}
                onClose={(e, reason) => {
                    if (reason === 'blur') handleClose();
                }}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        // tooltipOpen
                        onClick={() => {
                            handleClose();
                            action.handleClick();
                        }}
                        sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                    />
                ))}
            </SpeedDial>

            <ConfirmDialog
                open={isPlayReceiverConfirmationDialogOpen}
                handleCancel={() => {
                    setIsPlayReceiverConfirmationDialogOpen(false);
                }}
                handleConfirm={() => {
                    if (Played) {
                        return;
                    }

                    const items: PlayReceiverPayloadItem[] = Items.map(
                        (item): PlayReceiverPayloadItem => {
                            return { Id: item.Id || -1, Sku: item.Item, RecQty: item.RecQty };
                        }
                    );
                    const result: PlayReceiverPayload = {
                        MomCode: Client,
                        RecNumber: Number || 0,
                        Items: items,
                    };

                    triggerPlayReceivers({ momCode: Client, body: result });
                    dispatch(setIsDialogOpen(false));
                    setIsPlayReceiverConfirmationDialogOpen(false);
                }}
                title={'Play Receiver'}
                content={`Playing a receiver is irreversible. Play receiver #${Number}?`}
            />
        </Box>
    ) : (
        <></>
    );
};

export default CreatedReceiverSpeedDial;
