import { Box, styled } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridValidRowModel,
} from '@mui/x-data-grid';
import React from 'react';
import { CompletedWorkOrderDetail, CompletedWorkOrdersByClient, ServiceRecord } from '../../workOrderModels';
import ServiceDataGrid from './ServiceDataGrid';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer',
    },
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const getTotalDuration = (workOrderDetails: CompletedWorkOrderDetail[], serviceCode: string) => {
    return workOrderDetails
        .reduce(
            (accumulator, currentWorkOrderDetail) =>
                accumulator +
                currentWorkOrderDetail.Services.reduce((accumulatorInner, currentService) => {
                    const durationAmountToAdd = currentService.Code === serviceCode ? currentService.Duration : 0;
                    return accumulatorInner + durationAmountToAdd;
                }, 0),
            0
        )
        .toFixed(2);
};

const DEFAULT_CLIENT_NAME = 'ClientIsNull';

const EndOfMonthDataGrid = ({ data, services }: { data: CompletedWorkOrdersByClient[]; services: ServiceRecord[] }) => {
    const [clientToView, setClientToView] = React.useState('');

    const columns: readonly GridColDef<GridValidRowModel>[] = [
        {
            field: 'ClientName',
            headerName: 'Client',
            width: 70,
        },
        ...services.map(
            (service): GridColDef<GridValidRowModel> => {
                return {
                    field: service.Code,
                    headerName: service.Code,
                    description: service.Description,
                    width: 78,
                    valueGetter: (value, row) => {
                        return getTotalDuration(row.CompletedWorkOrders, service.Code);
                    },
                    sortable: false,
                };
            }
        ),
    ];

    const handleRowClick: GridEventListener<'rowClick'> = params => {
        setClientToView(params.row.ClientName || DEFAULT_CLIENT_NAME);
    };

    const handleServiceDataGridClose = () => {
        setClientToView('');
    };

    return (
        <Box sx={{ p: 3 }}>
            <StyledDataGrid
                rows={data}
                columns={columns}
                getRowId={row => row.ClientName || DEFAULT_CLIENT_NAME}
                sx={{ mt: 2 }}
                onRowClick={handleRowClick}
                density="compact"
                slots={{ toolbar: CustomToolbar }}
            />

            {clientToView && (
                <ServiceDataGrid
                    data={
                        data.find(workOrders => {
                            if (clientToView === DEFAULT_CLIENT_NAME) return !workOrders.ClientName;

                            return workOrders.ClientName === clientToView;
                        })?.CompletedWorkOrders || []
                    }
                    handleClose={handleServiceDataGridClose}
                    services={services}
                    clientName={clientToView}
                />
            )}
        </Box>
    );
};

export default EndOfMonthDataGrid;
