import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import { setDialog } from '../../../../../../notification/notificationSlice';
import receiversApi from '../../../../../receiversApi';
import { SubmissionFormItem } from '../../../../../receiversModels';
import { overrideForm } from '../../../../../receiversSlice';
import ControlledItemCard from '../ControlledItemCard';
import ItemDetails from './ItemDetails';
import ItemTextField from './ItemTextField';

const AddItemDialog = ({
    itemToUpsert,
    setItemToUpsert,
    handleDialogClose,
    isAddingNewItem,
    hasAsn,
}: {
    itemToUpsert?: SubmissionFormItem;
    setItemToUpsert: React.Dispatch<React.SetStateAction<SubmissionFormItem | undefined>>;
    handleDialogClose: () => void;
    isAddingNewItem: boolean;
    hasAsn: boolean;
}) => {
    const dispatch = useAppDispatch();
    const form = useAppSelector(state => state.receivers.form);

    const getItemResponse = receiversApi.endpoints.getItem.useQuery(
        itemToUpsert?.Item
            ? {
                  MomCode: form?.Client,
                  Sku: itemToUpsert?.Item,
              }
            : skipToken
    );

    const fetchedItemData = getItemResponse.data?.Data[0];

    const handleFormSubmit = () => {
        if (!itemToUpsert) {
            handleDialogClose();
            return;
        }

        if (isAddingNewItem) {
            if (form.Items.find(item => item.Item === itemToUpsert?.Item)) {
                dispatch(setDialog({ content: `Choose different item`, title: `Stock already contained` }));
                return;
            }

            dispatch(overrideForm({ Items: [...form.Items, { ...itemToUpsert }] }));
        } else {
            const indexToUpdate = form.Items.findIndex(item => item.Item === itemToUpsert.Item);
            const items = [...form.Items];
            const copiedItem = { ...itemToUpsert };
            copiedItem.RecQty = copiedItem.RecCrtncount * copiedItem.RecCaseqty + copiedItem.RecPartial;
            items[indexToUpdate] = copiedItem;
            items[indexToUpdate].Available = fetchedItemData?.Available || 0;

            dispatch(overrideForm({ Items: items }));
        }

        handleDialogClose();
    };

    // useScanner(upcToUpdate, fetchCurrItemData);

    return (
        <Dialog onClose={handleDialogClose} open={true} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>{isAddingNewItem ? `Add` : `Update`} Item</DialogTitle>
            <DialogContent>
                {isAddingNewItem && (
                    <Box sx={{ display: 'flex' }}>
                        <ItemTextField itemToUpsert={itemToUpsert} setItemToUpsert={setItemToUpsert} />

                        {/* TODO: display button conditionally based on permission */}
                        {/* <Button color="secondary" variant="contained" onClick={fetchCurrItemData} sx={{ m: 3 }}>
                            Get Item Data
                        </Button> */}
                    </Box>
                )}

                <ControlledItemCard item={itemToUpsert} setItem={setItemToUpsert} hasAsn={hasAsn} />

                <ItemDetails item={fetchedItemData} />
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleFormSubmit} disabled={!itemToUpsert}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddItemDialog;
