import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Modal, Paper, Button } from '@mui/material';

const PREFIX = 'ConfirmModal';

const classes = {
    modal: `${PREFIX}-modal`,
    wrapper: `${PREFIX}-wrapper`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
    [`&.${classes.modal}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    [`& .${classes.wrapper}`]: {
        width: 'min(500px,80%)',
        padding: theme.spacing(2),
    },
}));

const ConfirmModal = ({ title, modal, setModal, description, handleConfirm }) => {
    return (
        <StyledModal className={classes.modal} open={modal} onClose={() => setModal(false)}>
            <Paper className={classes.wrapper}>
                <div>
                    <Typography variant="h6" color="initial">
                        {title}
                    </Typography>
                </div>
                <Typography
                    variant="subtitle1"
                    color="initial"
                    style={{
                        minHeight: 80,
                        borderTop: '1px solid #cbcbcb',
                        borderBottom: '1px solid #cbcbcb',
                        margin: '10px 0',
                        padding: '10px 0',
                        fontWeight: 500,
                    }}
                >
                    {description}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button variant="contained" style={{ marginRight: 20 }} onClick={() => setModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </div>
            </Paper>
        </StyledModal>
    );
};

export default ConfirmModal;
