import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { formatDate } from '../../utils/dateUtil';

const PREFIX = 'DateDisplay';

const classes = {
    root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme: { spacing, palette } }) => {
    return {
        [`&.${classes.root}`]: {
            padding: '10px 0',
            fontSize: '0.9rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
    };
});

export const DateDisplay = React.memo(({ lastUpdate, assignedOn }) => {
    // const assignedOnDate = formatDate(assignedOn).split(',');

    return (
        <StyledBox className={classes.root}>
            <Box>
                <div>
                    <strong>Last Update</strong>
                </div>
                <div>{(lastUpdate && formatDate(lastUpdate)) || '-'}</div>
            </Box>
            <Box>
                <div>
                    <strong>Assigned On</strong>
                </div>
                <div>{(assignedOn && formatDate(assignedOn)) || '-'}</div>
            </Box>
        </StyledBox>
    );
});
