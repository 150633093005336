import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import {
    DISABLE_DELIVERY_APPOINTMENT_WHEN_PALLET_COUNT_EXCEEDS_THIS_COUNT,
    UNIT_TYPES_THAT_REQUIRE_DELIVERY_APPOINTMENT,
} from '../../../receiversConstants';
import { ActiveRuleMap } from '../../../receiversModels';
import { overrideForm } from '../../../receiversSlice';
import ScoreCardCheckbox from './ScoreCardCheckbox';
import React from 'react';

const expandIcon = <ExpandMoreIcon />;

const ScoreCard = ({ activeRules }: { activeRules: ActiveRuleMap }) => {
    // const form = useAppSelector(state => state.receivers.form);
    const ScDeliveryApt = useAppSelector(state => state.receivers.form.ScDeliveryApt);
    const Palletcount = useAppSelector(state => state.receivers.form.Palletcount);
    const UnitType = useAppSelector(state => state.receivers.form.UnitType);
    const ScPl = useAppSelector(state => state.receivers.form.ScPl);
    const ScPlqty = useAppSelector(state => state.receivers.form.ScPlqty);
    const ScCartonMark = useAppSelector(state => state.receivers.form.ScCartonMark);
    const ScBoxCondition = useAppSelector(state => state.receivers.form.ScBoxCondition);
    const ScPalletCondition = useAppSelector(state => state.receivers.form.ScPalletCondition);

    const mode = useAppSelector(state => state.receivers.mode);
    const dispatch = useAppDispatch();
    const readOnly = mode === 'View';
    return (
        <Accordion sx={{ my: 2, flex: 1 }} elevation={8}>
            <AccordionSummary expandIcon={expandIcon} aria-controls="score-card-content" id="score-card-header">
                Score Card
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
                    <Box sx={{ display: 'flex', flexFlow: 'column', flexWrap: 'wrap', flex: 1 }}>
                        <ScoreCardCheckbox
                            label="Has Delivery Appointment?"
                            checked={ScDeliveryApt}
                            onChange={(checked: boolean) => {
                                dispatch(overrideForm({ ScDeliveryApt: checked }));
                            }}
                            readOnly={readOnly}
                            disabled={
                                Palletcount < DISABLE_DELIVERY_APPOINTMENT_WHEN_PALLET_COUNT_EXCEEDS_THIS_COUNT &&
                                !UNIT_TYPES_THAT_REQUIRE_DELIVERY_APPOINTMENT.includes(UnitType)
                            }
                            rule={activeRules?.ScDeliveryApt}
                        />
                        <ScoreCardCheckbox
                            label="Has Packing List?"
                            checked={ScPl}
                            onChange={(checked: boolean) => {
                                dispatch(overrideForm({ ScPl: checked }));
                            }}
                            readOnly={readOnly}
                            rule={activeRules?.ScPl}
                        />
                        <ScoreCardCheckbox
                            label="Correct Quantity?"
                            checked={ScPlqty}
                            onChange={(checked: boolean) => {
                                dispatch(overrideForm({ ScPlqty: checked }));
                            }}
                            readOnly={readOnly}
                            rule={activeRules?.ScPlqty}
                        />
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                    <Box sx={{ display: 'flex', flexFlow: 'column', flexWrap: 'wrap', flex: 1 }}>
                        <ScoreCardCheckbox
                            label="Correct Carton Labels?"
                            checked={ScCartonMark}
                            onChange={(checked: boolean) => {
                                dispatch(overrideForm({ ScCartonMark: checked }));
                            }}
                            readOnly={readOnly}
                            rule={activeRules?.ScCartonMark}
                        />
                        <ScoreCardCheckbox
                            label="Good Box Condition?"
                            checked={ScBoxCondition}
                            onChange={(checked: boolean) => {
                                dispatch(overrideForm({ ScBoxCondition: checked }));
                            }}
                            readOnly={readOnly}
                            rule={activeRules?.ScBoxCondition}
                        />
                        <ScoreCardCheckbox
                            label="Good Pallet Condition?"
                            checked={ScPalletCondition}
                            onChange={(checked: boolean) => {
                                dispatch(overrideForm({ ScPalletCondition: checked }));
                            }}
                            readOnly={readOnly}
                            rule={activeRules?.ScPalletCondition}
                        />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default React.memo(ScoreCard);
