import { Box, Typography } from '@mui/material';
import React from 'react';
import { PairedRmaItem } from '../../rmaModels';
import { ReceivedItemSummaryPanel } from './ReceivedItemSummaryPanel';

export const ExpectedRmaPairsDisplay = ({ pairedRmaItems }: { pairedRmaItems: PairedRmaItem[] }) => {
    return (
        <React.Fragment>
            {pairedRmaItems.length > 0 ? (
                <React.Fragment>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography fontWeight={'fontWeightBold'} sx={{ color: 'primary.main' }} variant="h5">
                            EXPECTED ITEMS
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {pairedRmaItems.map(pair => (
                            <ReceivedItemSummaryPanel
                                receivedItem={pair.ReceivedItem}
                                expectedItem={pair.ExpectedItem}
                            />
                        ))}
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment />
            )}
        </React.Fragment>
    );
};
