import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TablePagination,
    Checkbox,
    Button,
    lighten,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { getComparator, isFeatureAccessible, stableSort } from '../../../common/utils/commonUtils';
import * as accessPermission from '../permissionsList';
import { Spinner } from '../../../common/components/Spinner/Spinner';
import EnhancedTableHead from './EnhancedTableHead2';

const PREFIX = 'PermissionsTable';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    selected: `${PREFIX}-selected`,
    title: `${PREFIX}-title`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.paper}`]: {
        width: '100%',
        marginBottom: '5rem',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },

    [`& .${classes.table}`]: {
        // minWidth:0,
    },

    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    [`& .${classes.selected}`]: {
        backgroundColor: `${lighten(theme.palette.primary.light, 0.85)} !important`,
    },

    [`& .${classes.title}`]: {
        flex: '1 1 100%',
    },
}));

const PermissionsTable = ({
    list,
    isAdding,
    handleCancel,
    handleSubmit,
    isEdittingRoleName,
    isAssigningNewPermission,
    handleAssignMoreRoles,
    isPermissionLoading,
    userPermissions,
}) => {
    const [orderBy, setOrderBy] = useState('Name');
    const [order, setOrder] = useState('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPermission, setSelectedPermission] = useState([]);
    const [unAssignedPermission, setUnAssignedPermission] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (e, row) => {
        const isListItem = selectedPermission.find(perm => row.id === perm.permissionId);

        const assignPerm = isListItem => {
            //  IF ITEM IS IN LIST REMOVE IT ELSE ADD IT
            if (isListItem) {
                setSelectedPermission(selectedPermission.filter(perm => perm.permissionId !== row.id));
                return;
            }
            setSelectedPermission(selectedPermission.concat({ permissionId: row.id, name: row.name, assigned: true }));
            return;
        };

        if (isAdding) {
            assignPerm(isListItem);
        }
        if (isEdittingRoleName) {
            if (isAssigningNewPermission) {
                assignPerm(isListItem);
            }
            const isUnAssignListItem = unAssignedPermission.find(perm => row.id === perm.permissionId);
            if (isUnAssignListItem) {
                setUnAssignedPermission(unAssignedPermission.filter(perm => perm.permissionId !== row.id));
                return;
            }
            setUnAssignedPermission(
                unAssignedPermission.concat({ permissionId: row.id, name: row.name, assigned: false })
            );
        }
    };

    const isSelected = row => {
        if (isAdding) {
            return selectedPermission.find(perm => perm.permissionId === row.id) ? true : false;
        }
        if (isEdittingRoleName) {
            if (isAssigningNewPermission) {
                return selectedPermission.find(perm => perm.permissionId === row.id) ? true : false;
            }
            return unAssignedPermission.find(perm => perm.permissionId === row.id) ? true : false;
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            if (isAssigningNewPermission || isAdding) {
                setSelectedPermission(list.map(perm => ({ permissionId: perm.id, name: perm.name, assigned: true })));
                return;
            }
            setUnAssignedPermission(list.map(perm => ({ permissionId: perm.id, name: perm.name, assigned: false })));
            return;
        }
        if (isAssigningNewPermission || isAdding) {
            setSelectedPermission([]);
            return;
        }
        setUnAssignedPermission([]);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

    return (
        <Root>
            <TableContainer>
                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={
                            isAdding || isAssigningNewPermission
                                ? selectedPermission.length
                                : unAssignedPermission.length
                        }
                        rowCount={list.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {isPermissionLoading && !isAdding && isAssigningNewPermission ? (
                            <TableRow>
                                <TableCell colSpan={-1}>
                                    <Spinner
                                        style={{
                                            height: 230,
                                            width: '90vw',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ) : (
                            stableSort(list, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected({ ...row });
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => handleClick(event, { ...row })}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`permission-${row.id}-${row.name}`}
                                            selected={isItemSelected}
                                            color="primary"
                                            style={{
                                                textDecoration:
                                                    !isAssigningNewPermission && !isAdding && isItemSelected
                                                        ? 'line-through'
                                                        : 'none',
                                            }}
                                            classes={{
                                                selected: classes.selected,
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                {isFeatureAccessible(
                                                    userPermissions,
                                                    accessPermission.ROLE_PERMISSION_EDIT
                                                ) && (
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        color="primary"
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                style={{ fontWeight: 500, cursor: 'pointer' }}
                                            >
                                                {row.name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 20 * emptyRows }}>
                                <TableCell colSpan={2} align="center">
                                    <Typography variant="body1" color="initial">
                                        {list.length === 0
                                            ? isAssigningNewPermission
                                                ? 'No more permissions available to assign to role'
                                                : ' No permission has been assigned to this role'
                                            : null}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isAssigningNewPermission ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAssignMoreRoles(selectedPermission)}
                    >
                        Assign
                    </Button>
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    {/* <Button variant="contained" style={{ marginRight: 20 }} onClick={handleCancel}>
                        Cancel
                    </Button> */}
                    {isFeatureAccessible(userPermissions, accessPermission.ROLE_PERMISSION_EDIT) && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit([...selectedPermission, ...unAssignedPermission])}
                        >
                            {isEdittingRoleName ? 'Update' : 'Add'}
                        </Button>
                    )}
                </div>
            )}
        </Root>
    );
};

export default PermissionsTable;
