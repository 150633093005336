import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { ReceivedItem, ReceivedItemUnitDetail } from '../../rmaModels';
import { ScanSerialsDisplay } from './ScanSerialsDisplay';

export const ScanSerialsDialog = React.memo(
    ({
        receivedItem,
        receivedItemUnitDetail,
        setReceivedItemUnitDetail,
        handleOpenDialog,
        showDialog = false,
    }: {
        receivedItem: ReceivedItem;
        receivedItemUnitDetail: ReceivedItemUnitDetail;
        setReceivedItemUnitDetail: (
            addingItemUnit: ReceivedItemUnitDetail | ((prevItem: ReceivedItemUnitDetail) => ReceivedItemUnitDetail)
        ) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            handleOpenDialog(false);
        };

        React.useEffect(() => {
            if (receivedItemUnitDetail.Serials.length > 0) {
                handleClose();
            }
        }, [receivedItemUnitDetail.Serials.length]);

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm" onClose={handleClose}>
                <DialogTitle color="white" bgcolor="primary.main" align="center" sx={{ px: 3, pt: 3 }}>
                    SCAN SERIAL
                </DialogTitle>
                <DialogContent>
                    <ScanSerialsDisplay
                        receivedItem={receivedItem}
                        receivedItemUnitDetail={receivedItemUnitDetail}
                        setReceivedItemUnitDetail={setReceivedItemUnitDetail}
                    />
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" size="large" fullWidth onClick={handleClose}>
                            {receivedItemUnitDetail.Serials.length > 0 ? 'DONE' : 'CLOSE'}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
