import {
    GET_RMA_NUMBER_FROM_RETURN_LABEL_URL,
    GET_SEAL_RMA_STOCK_URL,
    GET_SEAL_RMA_URL,
    POST_SEAL_NEW_RMA_URL,
    POST_SEAL_RMA_DISPOSITIONS_URL,
    POST_SEAL_RMA_MISSING_SERIAL_REASONS_URL,
    POST_SEAL_RMA_RECEIVED_ITEMS_URL,
    POST_SEAL_RMA_SKU_IMAGE_URL,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl, prependRoapiUrl } from '../../app/services/util';
import {
    GetDispositionsResponse,
    GetMissingSerialReasonsResponse,
    GetRmaResponse,
    GetStockResponse,
    PostReceivedItemsResponse,
    PostSkuImageResponse,
    ReceivedItem,
    ReturnLabel,
    Rma,
} from './rmaModels';

export const rmaApi = baseApi.enhanceEndpoints({
    addTagTypes: ['RmaResponse', 'ReturnLabelResponse'],
});

export default rmaApi.injectEndpoints({
    endpoints: builder => ({
        getRma: builder.query<GetRmaResponse, { momCode: string | null; rmaQuery: string }>({
            query: param => ({
                url: prependDreamUrl(`${GET_SEAL_RMA_URL}/${param.rmaQuery}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: !!param.momCode ? param.momCode : '',
                },
            }),
            providesTags: ['RmaResponse'],
        }),
        getReturnLabel: builder.mutation<ReturnLabel[], { refPo: string }>({
            query: param => ({
                url: prependRoapiUrl(GET_RMA_NUMBER_FROM_RETURN_LABEL_URL),
                method: 'GET',
                params: param,
            }),
            invalidatesTags: ['RmaResponse'],
        }),
        getStock: builder.query<GetStockResponse, { momCode: string; stockQuery: string }>({
            query: param => ({
                url: prependDreamUrl(`${GET_SEAL_RMA_STOCK_URL}/${param.stockQuery}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: `${param.momCode}`,
                },
            }),
        }),
        getDispositions: builder.query<GetDispositionsResponse, {}>({
            query: () => ({
                url: prependDreamUrl(POST_SEAL_RMA_DISPOSITIONS_URL),
                method: 'GET',
            }),
        }),
        getMissingSerialReasons: builder.query<GetMissingSerialReasonsResponse, {}>({
            query: () => ({
                url: prependDreamUrl(POST_SEAL_RMA_MISSING_SERIAL_REASONS_URL),
                method: 'GET',
            }),
        }),
        postRecievedItems: builder.mutation<
            PostReceivedItemsResponse,
            Partial<{ receivedItems: ReceivedItem[]; momCode: string; username: string }>
        >({
            query: param => ({
                url: prependDreamUrl(POST_SEAL_RMA_RECEIVED_ITEMS_URL),
                method: 'POST',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
                body: {
                    ReceivedItems: param.receivedItems,
                    User: param.username,
                },
            }),
        }),
        postNewRma: builder.mutation<
            PostReceivedItemsResponse,
            Partial<{ rma: Rma; momCode: string; username: string }>
        >({
            query: param => ({
                url: prependDreamUrl(POST_SEAL_NEW_RMA_URL),
                method: 'POST',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
                body: {
                    Rma: param.rma,
                    User: param.username,
                },
            }),
        }),
        postSkuImage: builder.mutation<
            PostSkuImageResponse,
            Partial<{
                sku: string;
                base64Image: string | ArrayBuffer | undefined | null;
                momCode: string;
                userId: number;
            }>
        >({
            query: param => ({
                url: prependDreamUrl(POST_SEAL_RMA_SKU_IMAGE_URL),
                method: 'POST',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
                body: {
                    Sku: param.sku,
                    Base64Image: param.base64Image,
                    User: !!param.userId ? param.userId.toString() : '',
                },
            }),
        }),
    }),
});
