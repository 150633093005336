import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { PairedRmaItem, ReceivedItem } from '../../rmaModels';
import { RmaIdDisplay } from '../RmaIdDisplay';
import { RecievedItemsSummaryDisplay } from './RecievedItemsSummaryDisplay';

export const RecievedItemsSummaryDialog = React.memo(
    ({
        pairedRmaItems,
        unexpectedItems,
        handleOpenDialog,
        showDialog = false,
    }: {
        pairedRmaItems: PairedRmaItem[];
        unexpectedItems: ReceivedItem[];
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog
                PaperProps={{
                    sx: { backgroundColor: grey[200] },
                }}
                fullWidth
                maxWidth="md"
                open={showDialog}
                onClose={handleClose}
            >
                <DialogTitle color="white" bgcolor="primary.main" align="center">
                    <Typography fontWeight={'fontWeightBold'} variant="h5">
                        SUMMARY
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h6">CLIENT: {rma?.Client}</Typography>
                        <RmaIdDisplay />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <RecievedItemsSummaryDisplay
                        pairedRmaItems={pairedRmaItems}
                        unexpectedItems={unexpectedItems}
                        handleOpenDialog={handleOpenDialog}
                    />
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
