import { Grid, TextField } from '@mui/material';
import { Customer } from '../orderLookupModels';

export function CustomerDisplay(customer: Customer) {
    const getNameString = () => {
        var nameString = '';
        nameString += !!customer.FirstName ? `${customer.FirstName} ` : '';
        nameString += !!customer.LastName ? `${customer.LastName}` : '';
        if (!!!nameString) {
            return '-';
        }
        return nameString;
    };

    const getAddressString = () => {
        var addressString = '';
        addressString += !!customer.Address1 ? `${customer.Address1}\n` : '';
        addressString += !!customer.Address2 ? `${customer.Address2}\n` : '';
        if (!!!addressString) {
            return '-';
        }
        addressString += `${customer.City}, ${customer.State} ${customer.ZipCode}\n`;
        addressString += customer?.Country?.Name || '';
        return addressString;
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <TextField
                    value={getNameString()}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label="Name"
                    variant="filled"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    value={!!customer.Company ? customer.Company : '-'}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label="Company"
                    variant="filled"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={getAddressString()}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    variant="filled"
                    multiline
                    rows={getAddressString().split(/\r\n|\r|\n/).length}
                    label="Address"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    value={customer.Phone || '-'}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label="Phone Number"
                    variant="filled"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    value={customer.Email || '-'}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label="Email Address"
                    variant="filled"
                    size="small"
                    fullWidth
                />
            </Grid>
        </Grid>
    );
}
