import { Box } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../app/store';
import useMutationResponse from '../../../common/hooks/useMutationResponse';
import useQueryResponse from '../../../common/hooks/useQueryResponse';
import serializedBatchShippingApi from '../serializedBatchShippingApi';
import { QCLog, SubmitForm } from '../serializedBatchShippingModels';
import TrackingForm from './TrackingForm';
import ItemForm from './itemForm/ItemForm';
import OrderDataDisplay from './itemForm/OrderDataDisplay';

import useSound from 'use-sound';
import successSound from '../../../common/assets/audio/success.mp3';

const initialForm: SubmitForm = {
    Tracking: '',
    ParsedTracking: '',
    PackageId: '',
    Serial: '',
    Sku: '',
};

const SerializedBatchShippingElement = () => {
    const [form, setForm] = React.useState<SubmitForm>(initialForm);
    const [triggerStartQc, startQcResponse] = serializedBatchShippingApi.endpoints.startQualityControl.useMutation();
    const [
        triggerGetOrderData,
        getOrderDataResponse,
    ] = serializedBatchShippingApi.endpoints.getOrderData.useLazyQuery();
    const userId = useAppSelector(state => state.authentication.authenticationResponse?.userInfo.id);
    const [qcLog, setQcLog] = React.useState<QCLog[]>([]);
    const trackingInputRef = React.useRef<any>();
    const [isQualityControlInProgress, setIsQualityControlInProgress] = React.useState(false);

    const handleReset = () => {
        setForm(prev => {
            const initialFormWithoutOverridingAll = {
                ...initialForm,
                ParsedTracking: prev.ParsedTracking,
            };

            return initialFormWithoutOverridingAll;
        });
        setIsQualityControlInProgress(false);

        trackingInputRef?.current?.focus?.();
    };

    useQueryResponse({
        response: getOrderDataResponse,
        errorMessage: 'Get Order Data',
        notFoundErrorMessage: 'No order data found for this package id',
        onError: handleReset,
    });

    useMutationResponse({
        response: startQcResponse,
        errorMessage: 'Start QC',
        onSuccess: () => {
            setIsQualityControlInProgress(true);

            setQcLog([
                {
                    Datetime: new Date().toISOString(),
                    ScanQty: 0,
                    Error: false,
                    Action: 'Begin QC',
                },
            ]);
        },
    });

    const handleTrackingValidated = async (packageId: string) => {
        const orderData = (await triggerGetOrderData({ PackageId: packageId }))?.data;

        if (!orderData) {
            return;
        }

        triggerStartQc({ PackageId: packageId, UserId: userId || -1 });
    };

    const [playSuccessSound] = useSound(successSound);

    const handleSubmissionSuccess = () => {
        playSuccessSound();
        setTimeout(() => {
            handleReset();
        }, 1500);
    };

    return (
        <Box
            style={{
                flex: 1,
            }}
        >
            {!isQualityControlInProgress && (
                <TrackingForm
                    form={form}
                    setForm={setForm}
                    onTrackingIsValid={handleTrackingValidated}
                    trackingInputRef={trackingInputRef}
                />
            )}

            {!!getOrderDataResponse.data && !!startQcResponse.isSuccess && isQualityControlInProgress && (
                <>
                    <OrderDataDisplay orderData={getOrderDataResponse.data} form={form} />

                    <ItemForm
                        form={form}
                        setForm={setForm}
                        orderData={getOrderDataResponse.data}
                        qcLog={qcLog}
                        setQcLog={setQcLog}
                        onValidSubmission={handleSubmissionSuccess}
                    />
                </>
            )}
        </Box>
    );
};

export default SerializedBatchShippingElement;
