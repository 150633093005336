import React from 'react';
import useNotificationByResponse from './useNotificationByResponse';

interface usePdfWindowProps {
    useLazyQuery: any;
}

const usePdfWindow = ({ useLazyQuery }: usePdfWindowProps) => {
    const [triggerGetPdf, getPdfResponse] = useLazyQuery;

    useNotificationByResponse({
        isFetching: getPdfResponse.isFetching,
        isError: getPdfResponse.isError,
        errorContent: 'Failed to get print',
        isSuccess: getPdfResponse.isSuccess,
        successContent: `Opening print`,
        isUninitialized: getPdfResponse.isUninitialized,
    });

    React.useEffect(() => {
        if (getPdfResponse.data && !getPdfResponse.isFetching) {
            const file = new Blob([getPdfResponse.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            if (pdfWindow) pdfWindow.location.href = fileURL;
        }
    }, [getPdfResponse.isFetching]);

    return [triggerGetPdf, getPdfResponse];
};

export default usePdfWindow;
