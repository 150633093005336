import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const DialogCloseButton = ({
    handleClose,
    positionRight = 8,
    positionTop = 8,
}: {
    handleClose: () => void;
    positionRight?: number;
    positionTop?: number;
}) => {
    return (
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: positionRight,
                top: positionTop,
                color: theme => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
    );
};

export default DialogCloseButton;
