import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { ActiveRuleMap } from '../../../receiversModels';
import { overrideForm } from '../../../receiversSlice';
import ReceiverDialogNumberTextField from './ReceiverDialogNumberTextField';
import ReceiverDialogTextField from './ReceiverDialogTextField';

const TextFieldsSecondHalf = ({ activeRules }: { activeRules: ActiveRuleMap }) => {
    const Palletcount = useAppSelector(state => state.receivers.form.Palletcount);
    const Shipper = useAppSelector(state => state.receivers.form.Shipper);
    const Carrier = useAppSelector(state => state.receivers.form.Carrier);
    const Pack = useAppSelector(state => state.receivers.form.Pack);
    const Lading = useAppSelector(state => state.receivers.form.Lading);

    const dispatch = useAppDispatch();

    return (
        <>
            <ReceiverDialogNumberTextField
                value={Palletcount}
                onChange={newValue => {
                    dispatch(
                        overrideForm({
                            Palletcount: newValue,
                        })
                    );
                }}
                textFieldProps={{
                    label: 'Pallet Count',
                }}
                key={Palletcount}
            />

            <ReceiverDialogTextField
                label="Shipper"
                value={Shipper}
                onChange={newValue => {
                    dispatch(overrideForm({ Shipper: newValue }));
                }}
                rule={activeRules?.Shipper}
            />
            <ReceiverDialogTextField
                label="Carrier"
                value={Carrier}
                onChange={newValue => {
                    dispatch(overrideForm({ Carrier: newValue }));
                }}
                rule={activeRules?.Carrier}
            />
            <ReceiverDialogTextField
                label="P/L Number"
                value={Pack}
                onChange={newValue => {
                    dispatch(overrideForm({ Pack: newValue }));
                }}
                rule={activeRules?.Pack}
            />
            <ReceiverDialogTextField
                label="BOL / Tracking"
                value={Lading}
                onChange={newValue => {
                    dispatch(overrideForm({ Lading: newValue }));
                }}
                rule={activeRules?.Lading}
            />
        </>
    );
};

export default React.memo(TextFieldsSecondHalf);
