import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    FormControlLabel,
    Switch,
    Typography,
} from '@mui/material';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, TooltipProps, YAxis } from 'recharts';
import { GetSkuVelocityResponse } from '../../models';

const CustomBarGraphTooltip = ({ active, payload, label }: TooltipProps<string, string>) => {
    if (active && payload && payload.length) {
        return (
            <Card sx={{ p: 1 }}>
                <Typography className="label">{`Client: ${payload[0].payload.Client}`}</Typography>
                <Typography className="desc">{`Sku: ${payload[0].payload.Sku}`}</Typography>
                <Typography className="desc">{`Quantity: ${payload[0].payload.Quantity}`}</Typography>
            </Card>
        );
    }

    return null;
};

const TopQuantityBarChart = ({ data }: { data: GetSkuVelocityResponse[] }) => {
    const [shouldScaleLogrithmically, setShouldScaleLogrithmically] = React.useState(true);

    return (
        <Accordion sx={{ mt: 1 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Bar Chart</AccordionSummary>

            <AccordionDetails sx={{ height: 1000 }}>
                <ResponsiveContainer>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis
                            scale={shouldScaleLogrithmically ? 'log' : 'linear'}
                            dataKey={'Quantity'}
                            domain={['auto', 'auto']}
                            type="number"
                        />
                        <Tooltip content={<CustomBarGraphTooltip />} />
                        <Bar dataKey="Quantity" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                    </BarChart>
                </ResponsiveContainer>
            </AccordionDetails>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={shouldScaleLogrithmically}
                            onChange={event => {
                                setShouldScaleLogrithmically(event.target.checked);
                            }}
                            sx={{ flex: 1 }}
                        />
                    }
                    label="Scale Logrithmically"
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default TopQuantityBarChart;
