import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { SerialFilter } from '../../clearSerialModels';
import SkuByUpcDropdown from './SkuByUpcDropdown';

const UpcForm = ({ client, onSubmit }: { client: string; onSubmit: (serial: SerialFilter[]) => void }) => {
    const [upc, setUpc] = React.useState('');
    const [sku, setSku] = React.useState('');
    const [serial, setSerial] = React.useState('');

    return (
        <form
            onSubmit={e => {
                e.preventDefault();

                onSubmit([
                    {
                        Client: client,
                        Sku: sku.trim(),
                        Serial: serial.trim(),
                    },
                ]);

                setSerial('');
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <TextField
                    value={upc}
                    onChange={e => {
                        const newValue = e.target.value;
                        setUpc(newValue);
                    }}
                    label="UPC"
                    name="UPC"
                    sx={{ flex: 1, m: 1, width: '100%' }}
                    required
                    variant="filled"
                />
            </Box>

            <Box sx={{ display: 'flex' }}>
                <SkuByUpcDropdown
                    client={client}
                    upc={upc}
                    value={sku}
                    onChange={(event, value) => {
                        if (value) setSku(value.Sku);
                    }}
                    textFieldProps={{
                        label: 'Sku',
                        variant: 'filled',
                        required: true,
                        helperText: 'Upc select required first',
                    }}
                    sx={{ flex: 3, m: 1 }}
                />

                <TextField
                    value={serial}
                    onChange={e => {
                        const newValue = e.target.value;
                        setSerial(newValue);
                    }}
                    label="Serial"
                    name="Serial"
                    sx={{ flex: 3, m: 1 }}
                    required
                    variant="filled"
                />

                <Button type="submit" sx={{ flex: 1, m: 1 }} variant="contained">
                    Add
                </Button>
            </Box>
        </form>
    );
};

export default UpcForm;
