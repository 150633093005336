import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';

const SpeedDialMenu = ({ handleQcLogOpenDialog }: { handleQcLogOpenDialog: (openState: boolean) => void }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const qcLogs = getOrderDetailsResponse.data?.Data[0]?.QcLogs;
    const invoiceLabels = Array.from(new Set(qcLogs?.map(log => log.Order)));
    const hasQcLog = invoiceLabels.length > 0;

    const actions = [
        {
            icon: <PlaylistAddCheckIcon />,
            name: hasQcLog ? 'View QC LOG' : 'No QC LOG to View',
            isDisabled: hasQcLog ? false : true,
            handleClick: () => {
                handleQcLogOpenDialog(true);
            },
        },
    ];
    return (
        <Box
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.modal + 1,
            }}
        >
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="Order Lookup Actions"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClick={open ? handleClose : handleOpen}
                open={open}
                onClose={(e, reason) => {
                    if (reason === 'blur') handleClose();
                }}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                            handleClose();
                            action.handleClick();
                        }}
                        sx={{
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            '&.Mui-disabled': {
                                pointerEvents: 'auto',
                            },
                            '&.MuiFab-root:hover': {
                                bgcolor: theme => (action.isDisabled ? theme.palette.action.disabledBackground : ''),
                            },
                        }}
                        FabProps={{ disabled: action.isDisabled }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};

export default SpeedDialMenu;
