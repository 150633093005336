import React from 'react';
import { BatchIdDisplay } from './BatchIdDisplay';
import { Box } from '@mui/material';

const RapidQcElement = () => {
    return (
        <React.Fragment>
            <Box sx={{ pt: 1 }}>
                <BatchIdDisplay />
            </Box>
        </React.Fragment>
    );
};

export default RapidQcElement;
