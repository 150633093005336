import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { Item, Serial } from '../../orderLookupModels';
import { SerialsDeleteDialog } from '../Serials/SerialsDeleteDialog';
import { SerialsUpdateDialog } from './SerialsUpdateDialog';

// Adds id attribute to Serial objects to work with DataGrid
interface GridSerial extends Serial {
    datagridId: number; // DataGrid needs id attribute for each element.
    tablename: string; // Differentiate between Serial1 and Serial2
}

export const SerialsViewDialog = React.memo(
    ({
        itemData,
        handleOpenDialog,
        showDialog = false,
    }: {
        itemData: Item | undefined;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const skuTitle = `SKU: ${itemData?.Item}`;
        const gridSerialsData: GridSerial[] =
            itemData?.Serials?.map((serial, index) => {
                const gridSerial: GridSerial = { ...serial, datagridId: index + 1, tablename: 'Serial' };
                return gridSerial;
            }) || [];
        const [qtyOrderedTitle, setQtyOrderedTitle] = React.useState('');
        const [serialsUpdateDialogOpen, setSerialsUpdateDialogOpen] = React.useState(false);
        const [serialsDeleteDialogOpen, setSerialsDeleteDialogOpen] = React.useState(false);
        const [selectedUpdateSerialData, setSelectedUpdateSerialData] = React.useState<Serial>();
        const [selectedDeleteSerialData, setSelectedDeleteSerialData] = React.useState<Serial>();

        const setSerialsUpdateSerialData = (serials: Serial) => {
            setSerialsUpdateDialogOpen(true);
            setSelectedUpdateSerialData(serials);
        };

        const setSerialsDeleteSerialData = (serials: Serial) => {
            setSerialsDeleteDialogOpen(true);
            setSelectedDeleteSerialData(serials);
        };

        const handleClose = () => {
            handleOpenDialog(false);
        };

        React.useEffect(() => {
            if (showDialog) {
                setQtyOrderedTitle(`Qty Ordered: ${itemData?.QuantityOrder}`);
            }
        }, [showDialog]);

        const columns: GridColDef[] = [
            {
                align: 'center',
                headerAlign: 'center',
                field: 'datagridId',
                headerName: '#',
                flex: 1,
                type: 'number',
            },
            {
                align: 'left',
                headerAlign: 'left',
                field: 'Serial',
                headerName: 'Serial',
                flex: 3,
                renderCell: params => <Box>{!!params.value ? params.value : '-'}</Box>,
            },
        ];

        if (itemData?.HasSerials2Field) {
            columns.push({
                align: 'left',
                headerAlign: 'left',
                field: 'Serial2',
                headerName: 'Serial2',
                flex: 3,
                renderCell: params => <Box>{!!params.value ? params.value : '-'}</Box>,
            });
        }

        columns.push({
            align: 'center',
            headerAlign: 'center',
            field: 'update',
            headerName: 'Update',
            flex: 1,
            renderCell: params => (
                <Button
                    variant="text"
                    onClick={() => {
                        setSerialsUpdateSerialData(params.row);
                    }}
                >
                    <EditIcon color="primary" />
                </Button>
            ),
        });

        columns.push({
            align: 'center',
            headerAlign: 'center',
            field: 'delete',
            headerName: 'Delete',
            flex: 1,
            renderCell: params => (
                <Button
                    variant="text"
                    onClick={() => {
                        setSerialsDeleteSerialData(params.row);
                    }}
                >
                    <DeleteForeverIcon color="error" />
                </Button>
            ),
        });

        return (
            <Dialog
                open={showDialog}
                onClose={handleClose}
                fullWidth
                maxWidth={itemData?.HasSerials2Field ? 'md' : 'sm'}
            >
                <Box sx={{ m: 3 }}>
                    <SerialsUpdateDialog
                        itemData={itemData}
                        serialData={selectedUpdateSerialData}
                        handleOpenDialog={setSerialsUpdateDialogOpen}
                        showDialog={serialsUpdateDialogOpen}
                    />
                    <SerialsDeleteDialog
                        itemData={itemData}
                        serialData={selectedDeleteSerialData}
                        handleOpenDialog={setSerialsDeleteDialogOpen}
                        showDialog={serialsDeleteDialogOpen}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <DialogTitle sx={{ flex: 3, m: 0, p: 1 }}>{skuTitle}</DialogTitle>
                        <DialogTitle sx={{ flex: 4, m: 0, p: 1 }}>{qtyOrderedTitle}</DialogTitle>
                    </Box>
                    <DialogCloseButton handleClose={handleClose} />
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'BoxNumber', sort: 'asc' }],
                            },
                        }}
                        rows={gridSerialsData || []}
                        columns={columns}
                        disableRowSelectionOnClick
                        getRowId={(row: any) => row.datagridId}
                    />
                </Box>
            </Dialog>
        );
    }
);
