import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import UserRoleTableHead from './UserRoleTableHead';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    IconButton,
    AppBar,
    Toolbar,
    Button,
    Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getComparator, stableSort } from '../../../common/utils/commonUtils';
import { Spinner, SuccessSnackbar } from '../../../common/components';
import { useDispatch, useSelector } from 'react-redux';
import { editRolesByUserAction, getRolesAction, resetUsersSuccessStatusAction } from '../../store/actions/action';

const PREFIX = 'UserRoleTable';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    roleModalWrapper: `${PREFIX}-roleModalWrapper`,
    iconBtnStyles: `${PREFIX}-iconBtnStyles`,
    assignBtn: `${PREFIX}-assignBtn`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        position: 'relative',
        height: '100vh',
    },

    [`& .${classes.paper}`]: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: 600,
        },
        [theme.breakpoints.down('600px')]: {
            width: '100%',
        },
    },

    [`& .${classes.table}`]: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 600,
        },
    },

    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    [`& .${classes.roleModalWrapper}`]: {
        width: '500px',
        padding: 20,
    },

    [`& .${classes.iconBtnStyles}`]: {
        outline: 'none',
        padding: '7px',
        '&:focus': {
            outline: 'none',
        },
    },

    [`& .${classes.assignBtn}`]: {
        position: 'absolute',
        right: 25,
        bottom: 35,
    },
}));

const UserRoleTable = ({ setIsAssigningRole, isLoading, assignedList, selectedUser }) => {
    const dispatch = useDispatch();
    const {
        roles: { rolesList },
        users: { success, successMessage },
    } = useSelector(state => state);
    const [assignedRoles, setAssignedRoles] = useState([]);
    const [unAssignedRoles, setUnAssignedRoles] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        dispatch(getRolesAction());
    }, [dispatch]);

    useEffect(() => {
        if (rolesList?.[0]) {
            setUnAssignedRoles(
                rolesList.filter(role => (assignedList.find(assigned => assigned.id === role.id) ? false : true))
            );
        } else {
            setUnAssignedRoles([]);
        }
    }, [rolesList, assignedList]);

    useEffect(() => {
        if (success && successMessage !== '' && successMessage.includes('assigned')) {
            setIsAssigningRole(false);
        }
    }, [successMessage, success, setIsAssigningRole]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rolesList.length - page * rowsPerPage);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAll = ({ target: { checked } }) => {
        if (checked) {
            setAssignedRoles([...unAssignedRoles]);
            return;
        }
        setAssignedRoles([]);
    };

    const handleClick = row => {
        const isListItem = assignedRoles.find(role => role.id === row.id);
        if (isListItem) {
            setAssignedRoles(assignedRoles.filter(role => role.id !== row.id));
            return;
        }
        setAssignedRoles(assignedRoles.concat(row));
    };

    const isSelected = roleId => (assignedRoles.find(role => role.id === roleId) ? true : false);

    const handleAssignRole = () => {
        dispatch(resetUsersSuccessStatusAction());
        dispatch(
            editRolesByUserAction(
                {
                    userId: selectedUser.userId,
                    userRoles: assignedRoles.map(role => ({ roleId: role.id, assigned: true })),
                },
                'ASSIGNED'
            )
        );
    };

    return (
        <Root>
            {success && <SuccessSnackbar show={success} message={successMessage} />}
            <AppBar position="static" color="primary">
                <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Assign Role</Typography>
                    <IconButton
                        aria-label=""
                        onClick={() => setIsAssigningRole(null)}
                        data-testid="close-assign-role-drawer-btn"
                        size="large"
                    >
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <TableContainer>
                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <UserRoleTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        handleSelectAll={handleSelectAll}
                        numSelected={assignedRoles.length}
                        rowCount={unAssignedRoles.length}
                    />
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 300,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Spinner />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : (
                            stableSort(unAssignedRoles, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const isItemSelected = isSelected(row.id);
                                    return (
                                        <TableRow
                                            hover
                                            key={row.id}
                                            color="primary"
                                            onClick={() => handleClick({ ...row })}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    color="primary"
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                style={{
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {row.name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        )}
                        {emptyRows > 0 && !isLoading && (
                            <TableRow style={{ height: 43 * emptyRows }}>
                                <TableCell colSpan={3} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 25]}
                component="div"
                count={unAssignedRoles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Button
                variant="contained"
                className={classes.assignBtn}
                color="primary"
                data-testid="assign-roles-btn"
                onClick={handleAssignRole}
            >
                Assign Roles
            </Button>
        </Root>
    );
};

export default UserRoleTable;
