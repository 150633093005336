import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { setDialog } from '../../../notification/notificationSlice';
import { PairedRmaItem, ReceivedItem } from '../../rmaModels';
import { AddItemDisplay } from './AddItemDispay';
import { NEW_RECEIVED_ITEM } from '../../constants';

const newFormErrors = { sku: false, qty: false, triedSubmitting: false };

export const AddItemDialog = React.memo(
    ({
        pairedRmaItems,
        addedUnexpectedItems,
        setAddedUnexpectedItems,
        handleOpenDialog,
        showDialog = false,
    }: {
        pairedRmaItems: PairedRmaItem[];
        addedUnexpectedItems: ReceivedItem[];
        setAddedUnexpectedItems: (items: ReceivedItem[]) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const dispatch = useDispatch();
        const rma = useAppSelector(state => state.rma.rma);
        const newAddingItem: ReceivedItem = { ...NEW_RECEIVED_ITEM, RmaId: rma?.Id };
        const [addingItem, setAddingItem] = React.useState<ReceivedItem>(newAddingItem);
        const [formErrors, setFormErrors] = React.useState(newFormErrors);
        const existingSkuErrorMsg = `SKU "${addingItem.Sku}" already exists in the items list.`;
        const addItemTitle = !!rma?.Id ? 'ADD UNEXPECTED ITEM' : 'ADD ITEM';

        const resetVariablesToInitial = () => {
            setAddingItem(newAddingItem);
            setFormErrors(newFormErrors);
        };

        const closeWithoutSaving = () => {
            resetVariablesToInitial();
            handleOpenDialog(false);
        };

        const handleAddItem = () => {
            // Check adding a SKU that already exists as an expecting RMA item
            if (checkExistingSku()) {
                dispatch(
                    setDialog({
                        title: 'SKU ALREADY EXISTS',
                        content: existingSkuErrorMsg,
                        color: red['A100'],
                    })
                );
                setFormErrors(prev => ({
                    ...prev,
                    sku: true,
                }));
                return;
            }

            // Check if fields are properly filled out
            if (formErrors.qty || formErrors.sku) {
                setFormErrors(prev => ({
                    ...prev,
                    triedSubmitting: true,
                }));
                return;
            }

            setFormErrors(prev => ({
                ...prev,
                triedSubmitting: false,
            }));

            setAddedUnexpectedItems([...addedUnexpectedItems, addingItem]); // Add to new items list
            setAddingItem(newAddingItem);

            setFormErrors(newFormErrors);
            handleOpenDialog(false);
        };

        const checkExistingSku = () => {
            if (addingItem.NotFound) return false; // No SKUS to compare

            if (
                pairedRmaItems.some(
                    pair =>
                        (!!pair.ExpectedItem.Sku ? pair.ExpectedItem.Sku.toLowerCase() : '') ===
                        (!!addingItem.Sku ? addingItem.Sku.toLowerCase() : '')
                )
            )
                return true;

            return addedUnexpectedItems.some(
                (item, index) =>
                    (!!item.Sku ? item.Sku.toLowerCase() : '') ===
                        (!!addingItem.Sku ? addingItem.Sku.toLowerCase() : '') && !item.NotFound
            );
        };

        useEffect(() => {
            setFormErrors(prev => ({
                ...prev,
                qty: !!!addingItem.Quantity || addingItem.Quantity < 1,
                sku: !addingItem.NotFound && !!!addingItem.Sku,
            }));
        }, [addingItem]);

        return (
            <React.Fragment>
                <Dialog open={showDialog} fullWidth maxWidth="sm">
                    <DialogTitle color="white" bgcolor="primary.main" align="center">
                        <Typography fontWeight={'fontWeightMedium'} variant="h5">
                            {addItemTitle}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <AddItemDisplay
                                addingItem={addingItem}
                                setAddingItem={setAddingItem}
                                showErrors={formErrors}
                            />
                            <Box sx={{ display: 'flex', mt: 3 }}>
                                <Button size="large" fullWidth onClick={handleAddItem} variant="contained">
                                    ADD ITEM
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogCloseButton handleClose={closeWithoutSaving} />
                </Dialog>
            </React.Fragment>
        );
    }
);
