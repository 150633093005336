import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from '../../../../app/store';
import { Box, TextField, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { EditLogItem } from '../../workOrderModels';
import EditHistoryCard from '../../../../common/components/Card/EditHistoryCard';

const EditHistoryLogs = () => {
    const logs = useAppSelector(state => state.workOrder.form.EditLogItems);

    const historyCards = [...logs]
        ?.reverse()
        ?.map((logs: EditLogItem) => <EditHistoryCard logs={logs} key={logs.Id} />);

    return (
        <Box sx={{ m: 2 }}>
            {history && history?.length <= 0 ? (
                <TextField
                    label={''}
                    value={'No Edit History Available'}
                    // onChange={function (newValue: string): void {}}
                    disabled={true}
                />
            ) : (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>View History</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{historyCards}</AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
};

export default React.memo(EditHistoryLogs);
