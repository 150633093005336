import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const PREFIX = 'FailedSnackbar';

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const FailedSnackbar = React.memo(({ message, show = false }) => {
    const [open, setOpen] = React.useState(show);

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    return (
        <Root className={classes.root}>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <div>
                    <Alert onClose={handleClose} severity="error">
                        {message}
                    </Alert>
                </div>
            </Snackbar>
        </Root>
    );
});
