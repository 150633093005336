import { Box } from '@mui/material';
import RmaDataGrid from './RmaDataGrid';
import RmaDisplay from './RmaDisplay';
import RmaScanner from './RmaScanner';

const RmaItemsScanner = () => {
    return (
        <Box sx={{ pb: 1 }}>
            <RmaDisplay />
            <RmaScanner />
            <RmaDataGrid />
        </Box>
    );
};

export default RmaItemsScanner;
