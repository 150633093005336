import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { memo } from 'react';
import { useAppSelector } from '../../../../../../app/store';
import receiversApi from '../../../../oldReceiversApi';
import { History } from '../../../../receiversModels';
import MemoTextField from '../formControlledInputs/MemoTextField';
import EditHistoryCard from './EditHistoryCard';

const EditHistory = memo(() => {
    // const { history } = useSelector((state: RootStateOrAny) => state.receivers);
    const momCode = useAppSelector(state => state.oldReceivers.momCode);
    const receiverNumber = useAppSelector(state => state.receivers.receiverNumber);

    const { data: history } = receiversApi.endpoints.getReceiversHistoryDeprecated.useQuery(
        !!receiverNumber ? { momCode, receiverNumber } : skipToken
    );

    const historyCards = history?.map((historyElement: History) => (
        <EditHistoryCard historyElement={historyElement} key={historyElement.Id} />
    ));

    return (
        <Box sx={{ m: 2 }}>
            {history && history?.length <= 0 ? (
                <MemoTextField
                    label={''}
                    value={'No Edit History Available'}
                    onChange={function (newValue: string): void {}}
                    disabled={true}
                />
            ) : (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>View Edit History</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{historyCards}</AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
});

export default EditHistory;
