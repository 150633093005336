import { takeLatest, put, call, all, fork } from 'redux-saga/effects';
import { editRolePermissionsAction, getAssignedRolePermissionsAction } from '../store/actions/action';
import {
    ADD_ROLE,
    GET_ALL_USERS,
    GET_ALL_USERS_FAILURE,
    GET_ALL_USERS_SUCCESS,
    GET_ROLE,
    GET_ROLE_FAILURE,
    GET_ROLE_SUCCESS,
    UPDATE_ROLE,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_FAILURE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,
    DELETE_ROLE,
    SET_ROLES_LOADING,
    EDIT_USER_ROLE_SUCCESS,
    EDIT_USER_ROLE_FAILURE,
    EDIT_USER_ROLE,
    SET_ROLE_MODAL_LOADING,
    GET_ALL_ROLE_PERMISSION,
    GET_ALL_ROLE_PERMISSION_SUCCESS,
    GET_ALL_ROLE_PERMISSION_FAILURE,
    GET_UNASSIGNED_ROLE_PERMISSIONS_SUCCESS,
    GET_UNASSIGNED_ROLE_PERMISSIONS_FAILURE,
    GET_UNASSIGNED_ROLE_PERMISSIONS,
    GET_ASSIGNED_ROLE_PERMISSIONS,
    GET_ASSIGNED_ROLE_PERMISSIONS_SUCCESS,
    GET_ASSIGNED_ROLE_PERMISSIONS_FAILURE,
    EDIT_ROLE_PERMISSIONS,
    EDIT_ROLE_PERMISSIONS_SUCCESS,
    EDIT_ROLE_PERMISSIONS_FAILURE,
    SET_PERMISSIONS_LOADING,
} from '../store/actions/actionTypes';
import * as api from '../store/api/roleApi';

export function* getAllUsersSaga(action) {
    try {
        yield put({ type: SET_ROLES_LOADING });
        const response = yield call(api.getAllUsersApi, action);

        yield put({
            type: GET_ALL_USERS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: GET_ALL_USERS_FAILURE,
            errorMessage: error,
        });
    }
}

export function* getRolesSaga(action) {
    try {
        yield put({ type: SET_ROLES_LOADING });
        const response = yield call(api.getRoleApi, action);
        yield put({
            type: GET_ROLE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: GET_ROLE_FAILURE,
            errorMessage: error,
        });
    }
}

export function* addRolesSaga(action) {
    try {
        yield put({ type: SET_ROLE_MODAL_LOADING });
        const response = yield call(api.addRoleApi, action);
        yield put({
            type: ADD_ROLE_SUCCESS,
            payload: response.data,
        });
        if (action.payload.rolePermissions) {
            yield put(
                editRolePermissionsAction({ roleId: response.data, rolePermissions: action.payload.rolePermissions })
            );
        } else {
            yield put({ type: GET_ROLE });
        }
    } catch (error) {
        yield put({
            type: ADD_ROLE_FAILURE,
            errorMessage: error,
        });
    }
}

export function* updateRolesSaga(action) {
    // try {
    //     yield put({type:SET_ROLE_MODAL_LOADING})
    //     const response = yield call(api.updateRoleApi,action);
    //     yield put({
    //         type: UPDATE_ROLE_SUCCESS,
    //         payload: response?.data,
    //     });
    //     if(action.payload.updateRolePermission){
    //       yield put(editRolePermissionsAction(action.payload.updateRolePermission))
    //     } else {
    //       yield put({type: GET_ROLE})
    //     }
    // } catch (error) {
    //     yield put({
    //         type: UPDATE_ROLE_FAILURE,
    //         errorMessage: error,
    //     });
    // }
}

export function* deleteRolesSaga(action) {
    try {
        yield put({ type: SET_ROLES_LOADING });
        const response = yield call(api.deleteRoleApi, action);

        yield put({
            type: DELETE_ROLE_SUCCESS,
            payload: response.data,
        });
        yield put({ type: GET_ROLE });
    } catch (error) {
        yield put({
            type: DELETE_ROLE_FAILURE,
            errorMessage: error,
        });
    }
}

export function* editUserRoleSaga(action) {
    try {
        yield put({ type: SET_ROLES_LOADING });
        const response = yield call(api.editUserRoleApi, action);

        yield put({
            type: EDIT_USER_ROLE_SUCCESS,
            payload: { data: response.data, actionType: action.payload?.actionType },
        });
    } catch (error) {
        yield put({
            type: EDIT_USER_ROLE_FAILURE,
            errorMessage: error,
        });
    }
}

export function* getAllRolePermissionSaga(action) {
    try {
        yield put({ type: SET_ROLE_MODAL_LOADING });
        const response = yield call(api.getAllRolePermissionApi, action);

        yield put({
            type: GET_ALL_ROLE_PERMISSION_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: GET_ALL_ROLE_PERMISSION_FAILURE,
            errorMessage: error,
        });
    }
}

export function* getUnAssignedRolePermissionsSaga(action) {
    try {
        yield put({ type: SET_ROLE_MODAL_LOADING });
        const response = yield call(api.getPermissionsByRoleId, action);

        yield put({
            type: GET_UNASSIGNED_ROLE_PERMISSIONS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: GET_UNASSIGNED_ROLE_PERMISSIONS_FAILURE,
            errorMessage: error,
        });
    }
}

export function* getAssignedRolePermissionsSaga(action) {
    try {
        yield put({ type: SET_ROLE_MODAL_LOADING });
        const response = yield call(api.getPermissionsByRoleId, action);

        yield put({
            type: GET_ASSIGNED_ROLE_PERMISSIONS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: GET_ASSIGNED_ROLE_PERMISSIONS_FAILURE,
            errorMessage: error,
        });
    }
}

export function* editRolePermissionsSaga(action) {
    try {
        yield put({ type: SET_PERMISSIONS_LOADING });
        const response = yield call(api.editRolePermissionsApi, action);
        yield put({
            type: EDIT_ROLE_PERMISSIONS_SUCCESS,
            payload: { data: response.data, meta: action.meta },
        });

        if (action?.meta?.refetchAssigned) {
            yield put(getAssignedRolePermissionsAction({ roleId: action?.payload?.roleId, assigned: true }));
        } else {
            yield put({
                type: GET_ROLE,
            });
        }
    } catch (error) {
        yield put({
            type: EDIT_ROLE_PERMISSIONS_FAILURE,
            errorMessage: error,
        });
    }
}

function* getAllUsersSagaWatcher() {
    yield takeLatest(GET_ALL_USERS, getAllUsersSaga);
}

function* getRolesSagaWatcher() {
    yield takeLatest(GET_ROLE, getRolesSaga);
}

function* addRolesSagaWatcher() {
    yield takeLatest(ADD_ROLE, addRolesSaga);
}

function* updateRolesSagaWatcher() {
    yield takeLatest(UPDATE_ROLE, updateRolesSaga);
}

function* deleteRolesSagaWatcher() {
    yield takeLatest(DELETE_ROLE, deleteRolesSaga);
}

function* editUserRoleSagaWatcher() {
    yield takeLatest(EDIT_USER_ROLE, editUserRoleSaga);
}

function* getAllRolePermissionSagaWatcher() {
    yield takeLatest(GET_ALL_ROLE_PERMISSION, getAllRolePermissionSaga);
}

function* getUnAssignedRolePermissionsSagaWatcher() {
    yield takeLatest(GET_UNASSIGNED_ROLE_PERMISSIONS, getUnAssignedRolePermissionsSaga);
}

function* getAssignedRolePermissionsSagaWatcher() {
    yield takeLatest(GET_ASSIGNED_ROLE_PERMISSIONS, getAssignedRolePermissionsSaga);
}

function* editRolePermissionsSagaWatcher() {
    yield takeLatest(EDIT_ROLE_PERMISSIONS, editRolePermissionsSaga);
}

export default function* roleSagaWatcher() {
    yield all([
        fork(getAllUsersSagaWatcher),
        fork(getRolesSagaWatcher),
        fork(addRolesSagaWatcher),
        fork(updateRolesSagaWatcher),
        fork(deleteRolesSagaWatcher),
        fork(editUserRoleSagaWatcher),
        fork(getAllRolePermissionSagaWatcher),
        fork(getUnAssignedRolePermissionsSagaWatcher),
        fork(getAssignedRolePermissionsSagaWatcher),
        fork(editRolePermissionsSagaWatcher),
    ]);
}
