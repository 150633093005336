import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { ReceivedItem } from '../../rmaModels';

export const ConfirmDeleteItemDialog = React.memo(
    ({
        addedUnexpectedItems,
        index,
        setAddedUnexpectedItems,
        handleOpenDialog,
        showDialog = false,
    }: {
        addedUnexpectedItems: ReceivedItem[];
        index: number;
        setAddedUnexpectedItems: (items: ReceivedItem[]) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleDeleteAddedItem = () => {
            const updatedItems = [...addedUnexpectedItems.slice(0, index), ...addedUnexpectedItems.slice(index + 1)];
            setAddedUnexpectedItems(updatedItems);
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm">
                <DialogTitle color="white" bgcolor="error.main" align="center">
                    <Typography fontWeight={'fontWeightMedium'} variant="h6">
                        CONFIRM DELETE
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography sx={{ my: 4 }} variant="h6">
                            ARE YOU SURE WANT TO DELETE THIS ADDED ITEM?
                        </Typography>
                        <Box sx={{ display: 'flex', mt: 3 }}>
                            <Button
                                size="large"
                                fullWidth
                                onClick={() => {
                                    handleOpenDialog(false);
                                }}
                            >
                                NO
                            </Button>
                            <Button
                                size="large"
                                fullWidth
                                variant="contained"
                                color="error"
                                onClick={handleDeleteAddedItem}
                            >
                                DELETE
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogCloseButton
                    handleClose={() => {
                        handleOpenDialog(false);
                    }}
                />
            </Dialog>
        );
    }
);
