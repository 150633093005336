import { Box, Button, Popover, SxProps, TextField, TextFieldProps, Theme, Typography } from '@mui/material';
import React from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
    addDays,
    addMonths,
    addQuarters,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    parseISO,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
} from 'date-fns';
import { useState } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import dayjs from 'dayjs';
import { formatDateWithoutTime } from '../../utils/dateUtil';

interface DateRangePickerPopoverProps {
    textFieldProps: TextFieldProps;
    sx?: SxProps<Theme>;
    startDate: string | undefined;
    endDate: string | undefined;
    onStartDateChange: (newValue: string | undefined) => void;
    onEndDateChange: (newValue: string | undefined) => void;
    maxDurationDays?: number;
}

const DateRangePickerPopover = ({
    textFieldProps,
    sx = {},
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    maxDurationDays = undefined,
}: DateRangePickerPopoverProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [state, setState] = useState<any>([
        {
            startDate: startDate ? parseISO(startDate) : undefined,
            endDate: endDate ? parseISO(endDate) : undefined,
            key: 'selection',
        },
    ]);

    const getFormattedDate = (date: string | undefined) => {
        if (date) return formatDateWithoutTime(date);
        return '';
    };

    const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
        startOfLastQuarter: startOfQuarter(addQuarters(new Date(), -1)),
        endOfLastQuarter: endOfQuarter(addQuarters(new Date(), -1)),
        startOfQuarter: startOfQuarter(new Date()),
        startOfYear: startOfYear(new Date()),
    };

    const defaultStaticRanges = createStaticRanges([
        {
            label: 'Reset',
            range: () => ({
                startDate: undefined,
                endDate: undefined,
            }),
        },
        {
            label: 'Today',
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday,
            }),
        },
        {
            label: 'Yesterday',
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday,
            }),
        },
        {
            label: 'Last Week',
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek,
            }),
        },
        {
            label: 'Last Month',
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth,
            }),
        },
        {
            label: 'Last Quarter',
            range: () => ({
                startDate: defineds.startOfLastQuarter,
                endDate: defineds.endOfLastQuarter,
            }),
        },
        {
            label: 'Month To Date',
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfToday,
            }),
        },
        {
            label: 'Quarter To Date',
            range: () => ({
                startDate: defineds.startOfQuarter,
                endDate: defineds.endOfToday,
            }),
        },
        {
            label: 'Year To Date',
            range: () => ({
                startDate: defineds.startOfYear,
                endDate: defineds.endOfToday,
            }),
        },
    ]);

    return (
        <Box sx={{ ...sx }}>
            <TextField
                {...textFieldProps}
                onClick={handleClick}
                InputProps={{
                    readOnly: true,
                }}
                value={`${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateRangePicker
                    onChange={item => {
                        const newStartDate = item.selection.startDate ? startOfDay(item.selection.startDate) : null;
                        const newEndDate = item.selection.endDate ? endOfDay(item.selection.endDate) : null;

                        onStartDateChange(newStartDate?.toISOString());
                        onEndDateChange(newEndDate?.toISOString());
                        setState([item.selection]);
                    }}
                    // showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    minDate={
                        maxDurationDays && state?.[0]?.endDate
                            ? addDays(state?.[0]?.endDate, -maxDurationDays)
                            : undefined
                    }
                    maxDate={
                        maxDurationDays && state?.[0]?.startDate
                            ? addDays(state?.[0]?.startDate, maxDurationDays)
                            : undefined
                    }
                    months={2}
                    ranges={state}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    staticRanges={defaultStaticRanges}
                    inputRanges={[]}
                />
            </Popover>
        </Box>
    );
};

export default DateRangePickerPopover;
