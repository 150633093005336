import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Rma } from './rmaModels';

export interface RmaNumberCapturedState {
    rma: Rma | null | undefined;
    reset: boolean;
}

const initialState: RmaNumberCapturedState = {
    rma: undefined,
    reset: false,
};

const slice = createSlice({
    name: 'rma',
    initialState,
    reducers: {
        setRma: (state, action: PayloadAction<Rma | undefined | null>) => {
            if (!action.payload) {
                return;
            }
            const rma: Rma = action.payload;
            state.rma = {
                ...action.payload,
                ReceivedItems: rma.ReceivedItems?.map(item => ({
                    ...item,
                    ValidToSubmit: true,
                    NotFound: !item.Quantity,
                    ItemUnitDetails: item.ItemUnitDetails?.map(unit => ({
                        ...unit,
                        ValidToSubmit: true,
                    })),
                })),
            };
        },
        setReset: (state, action: PayloadAction<boolean>) => {
            state.reset = action.payload;
        },
    },
});

export const { setReset, setRma } = slice.actions;
export default slice.reducer;
