import { Box } from '@mui/material';
import { ActiveRuleMap } from '../../../receiversModels';
import TextFieldsFirstHalf from './TextFieldsFirstHalf';
import TextFieldsSecondHalf from './TextFieldsSecondHalf';

const TextFields = ({ hasAsn, activeRules }: { hasAsn: boolean; activeRules: ActiveRuleMap }) => {
    return (
        <Box sx={{ mt: 2, display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
            <TextFieldsFirstHalf hasAsn={hasAsn} activeRules={activeRules} />
            <TextFieldsSecondHalf activeRules={activeRules} />
        </Box>
    );
};

export default TextFields;
