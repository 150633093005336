import EditIcon from '@mui/icons-material/Edit';
import { Fab } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router';
import ProcedureApi from '../../ProcedureApi';

const EditFab = () => {
    const { WorkOrderProcedureId } = useParams();
    const castedWorkOrderProcedureId = WorkOrderProcedureId ? Number(WorkOrderProcedureId) : null;
    const procedureResponse = ProcedureApi.endpoints.getWorkOrderProcedure.useQuery(
        castedWorkOrderProcedureId ? castedWorkOrderProcedureId : skipToken
    );

    const { procedureId } = useParams();

    const navigate = useNavigate();

    const handleEditFabClick = () => {
        navigate(`/swrop/canvas/${procedureResponse?.data?.Id ?? procedureId}`);
    };

    return (
        <Fab
            sx={{ position: 'fixed', bottom: 16, right: 16, outline: 'none !important' }}
            color="primary"
            onClick={handleEditFabClick}
            aria-label="Recenter"
        >
            <EditIcon />
        </Fab>
    );
};

export default EditFab;
