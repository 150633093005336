import { Box, Grid, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';
import { ReadOnlyTextField } from '../ReadOnlyTextField';

export function HistoryOrderNotesDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getHistoryOrderResponse = orderLookupApi.endpoints.getHistoryOrder.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const historyOrderData = getHistoryOrderResponse.data?.Data[0];
    const hasNotes =
        historyOrderData?.Note1 ||
        historyOrderData?.Note2 ||
        historyOrderData?.Note3 ||
        historyOrderData?.Note4 ||
        historyOrderData?.CustomNote ||
        !!historyOrderData?.CustomNote2;

    const showNoteField = (label: string, value?: string) => {
        return !!value ? (
            <ReadOnlyTextField label={label} value={value} multiline={true} />
        ) : (
            <React.Fragment></React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {hasNotes ? (
                <Box>
                    {showNoteField('Note 1', historyOrderData?.Note1)}
                    {showNoteField('Note 2', historyOrderData?.Note2)}
                    {showNoteField('Note 3', historyOrderData?.Note3)}
                    {showNoteField('Note 4', historyOrderData?.Note4)}
                    {showNoteField('Custom Note', historyOrderData?.CustomNote)}
                    {showNoteField('Custom Note 2', historyOrderData?.CustomNote2)}
                </Box>
            ) : (
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                    <Typography>No Notes</Typography>
                </Box>
            )}
        </React.Fragment>
    );
}
