import { Box, Button, Typography } from '@mui/material';
import * as PapaParse from 'papaparse';
import { FileUploader } from 'react-drag-drop-files-ro';
import { useAppDispatch } from '../../../../app/store';
import { setDialog } from '../../../notification/notificationSlice';
import { SerialFilter } from '../../clearSerialModels';

const ACCEPTED_FILE_TYPES = ['csv'];
const EXAMPLE_CSV_PATH = '../exampleClearSerialUpload.csv';

const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
};

const CsvForm = ({ client, onSubmit }: { client: string; onSubmit: (serial: SerialFilter[]) => void }) => {
    const dispatch = useAppDispatch();

    const handleFileUpload = async (file: File) => {
        PapaParse.parse(file, {
            complete: function (results) {
                const firstRow = results?.data[0] as SerialFilter;
                const areHeadersValid = firstRow?.Client && firstRow?.Sku && firstRow?.Serial;
                if (!areHeadersValid) {
                    dispatch(
                        setDialog({
                            title: 'Invalid CSV',
                            content: `Must match format: \nClient,Sku,Serial
                                        FRAN,M2-DOCK-US,N77984T
                                        STX,111AOCF-A,STRA01138013446`,
                        })
                    );
                    return;
                }

                const serials = results.data.map((row: any) => {
                    return {
                        Client: row.Client.trim(),
                        Serial: row.Serial.trim(),
                        Sku: row.Sku.trim(),
                    } as SerialFilter;
                });

                onSubmit(serials);
            },
            ...papaparseOptions,
        });
    };

    return (
        <Box>
            <Box sx={{ display: 'flex' }}>
                <Typography sx={{ m: 1, flex: 1 }}>CSV must have the following headers: Client, Sku, Serial</Typography>
                {/* <Divider orientation="vertical" flexItem /> */}
                <Button
                    color="secondary"
                    variant="outlined"
                    sx={{ m: 1 }}
                    href={EXAMPLE_CSV_PATH}
                    download="exampleClearSerialUpload.csv"
                    target="_blank"
                >
                    Download Example CSV File
                </Button>
            </Box>

            <FileUploader
                children={
                    <Box sx={{ display: 'flex' }}>
                        <Button variant="outlined" sx={{ m: 1, flex: 1 }}>
                            Upload CSV
                        </Button>
                    </Box>
                }
                handleChange={handleFileUpload}
                types={ACCEPTED_FILE_TYPES}
                onTypeError={() => {
                    dispatch(
                        setDialog({
                            title: 'Invalid File Type',
                            content: `Accepted file types: ${ACCEPTED_FILE_TYPES}`,
                        })
                    );
                }}
            />
        </Box>
    );
};

export default CsvForm;
