import React from 'react';
import shipMatrixApi from '../../../shipMatrixApi';
import { Company, CompanyWithStatus, GetClientEditLogDetailsRequest } from '../../../shipMatrixModel';
import CompaniesTransiferIndividualList from '../CompaniesTransiferIndividualList';
import CompanyList from './CompanyList';

const EditLogDetailList = ({ editLogDetailId }: { editLogDetailId: number }) => {
    const getEditLogDetailResponse = shipMatrixApi.endpoints.getClientEditLogDetails.useQuery({
        editLogId: editLogDetailId,
    } as GetClientEditLogDetailsRequest);

    const companyResponse = shipMatrixApi.endpoints.getRateShoppingCompanies.useQuery();

    const unchangedCompanies = getEditLogDetailResponse.data?.Data?.UnchangedClients?.map(client => {
        const foundCompany = companyResponse.data?.Data.find(company => company.Mom === client);
        if (foundCompany) return { ...foundCompany, Status: 'unchanged' } as CompanyWithStatus;
        return {} as CompanyWithStatus;
    });

    const addedCompanies = getEditLogDetailResponse.data?.Data?.AddedClients?.map(client => {
        const foundCompany = companyResponse.data?.Data.find(company => company.Mom === client);
        if (foundCompany) return { ...foundCompany, Status: 'added' } as CompanyWithStatus;
        return {} as CompanyWithStatus;
    });

    const removedCompanies = getEditLogDetailResponse.data?.Data?.RemovedClients?.map(client => {
        const foundCompany = companyResponse.data?.Data.find(company => company.Mom === client);
        if (foundCompany) return { ...foundCompany, Status: 'removed' } as CompanyWithStatus;
        return {} as CompanyWithStatus;
    });

    const companies =
        unchangedCompanies && addedCompanies && removedCompanies
            ? [...unchangedCompanies, ...addedCompanies, ...removedCompanies]
            : [];

    return <CompanyList companies={companies} />;
};

export default EditLogDetailList;
