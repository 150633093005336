import { Box, FormGroup, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { UNIT_TYPES_THAT_REQUIRE_DELIVERY_APPOINTMENT } from '../../../receiversConstants';
import { SubmissionForm } from '../../../receiversModels';
import MemoCheckbox from './formControlledInputs/MemoCheckbox';
import MemoNumberTextField from './formControlledInputs/MemoNumberTextField';

interface NumericalAndBooleanFieldsProps {
    form: SubmissionForm;
    setForm: Dispatch<SetStateAction<SubmissionForm>>;
}

export const NumericalAndBooleanFields: FC<NumericalAndBooleanFieldsProps> = ({ form, setForm }) => {
    const DISABLE_DELIVERY_APPOINTMENT_WHEN_PALLET_COUNT_EXCEEDS_THIS_COUNT = 5;

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            <Box
                sx={{
                    mt: 2,
                    width: '100%',
                    flex: 1,
                    minWidth: '300px',
                }}
            >
                <MemoNumberTextField
                    label={'Stock Time (mins)'}
                    value={Number(form.StockTime.toFixed(0))}
                    onChange={(newValue: number) => {
                        setForm(
                            (prevForm: SubmissionForm): SubmissionForm => {
                                return { ...prevForm, StockTime: newValue };
                            }
                        );
                    }}
                />
                <MemoNumberTextField
                    label={'Label Time (mins)'}
                    value={form.LabelTime}
                    onChange={(newValue: number) => {
                        setForm(
                            (prevForm: SubmissionForm): SubmissionForm => {
                                return { ...prevForm, LabelTime: newValue };
                            }
                        );
                    }}
                />
                <MemoNumberTextField
                    label={'Shelf Time (mins)'}
                    value={form.ShelfTime}
                    onChange={(newValue: number) => {
                        setForm(
                            (prevForm: SubmissionForm): SubmissionForm => {
                                return { ...prevForm, ShelfTime: newValue };
                            }
                        );
                    }}
                />
                <MemoNumberTextField
                    label={'Pallet Count'}
                    value={form.Palletcount}
                    onChange={(newValue: number) => {
                        setForm(
                            (prevForm: SubmissionForm): SubmissionForm => {
                                return {
                                    ...prevForm,
                                    ScDeliveryApt:
                                        newValue < DISABLE_DELIVERY_APPOINTMENT_WHEN_PALLET_COUNT_EXCEEDS_THIS_COUNT
                                            ? true
                                            : prevForm.ScDeliveryApt,
                                    Palletcount: newValue,
                                };
                            }
                        );
                    }}
                />
            </Box>

            <Box
                sx={{
                    pl: 5,
                    mt: 2,
                    width: '100%',
                    flex: 1,
                    minWidth: '310px',
                }}
            >
                <Typography variant="h5">Scorecard</Typography>
                <FormGroup>
                    <MemoCheckbox
                        label={'Has Delivery Appointment?'}
                        checked={form.ScDeliveryApt}
                        disabled={
                            form.Palletcount < DISABLE_DELIVERY_APPOINTMENT_WHEN_PALLET_COUNT_EXCEEDS_THIS_COUNT &&
                            !UNIT_TYPES_THAT_REQUIRE_DELIVERY_APPOINTMENT.includes(form.UnitType)
                        }
                        onChange={(checked: boolean) => {
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, ScDeliveryApt: checked };
                                }
                            );
                        }}
                    />

                    <MemoCheckbox
                        label={'Has Packing List?'}
                        checked={form.ScPl}
                        onChange={(checked: boolean) => {
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, ScPl: checked };
                                }
                            );
                        }}
                    />

                    <MemoCheckbox
                        label={'Correct Quantity?'}
                        checked={form.ScPlqty}
                        onChange={(checked: boolean) => {
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, ScPlqty: checked };
                                }
                            );
                        }}
                    />

                    <MemoCheckbox
                        label={'Correct Carton Label?'}
                        checked={form.ScCartonMark}
                        onChange={(checked: boolean) => {
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, ScCartonMark: checked };
                                }
                            );
                        }}
                    />

                    <MemoCheckbox
                        label={'Good Box Condition?'}
                        checked={form.ScBoxCondition}
                        onChange={(checked: boolean) => {
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, ScBoxCondition: checked };
                                }
                            );
                        }}
                    />

                    <MemoCheckbox
                        label={'Good Pallet Condition?'}
                        checked={form.ScPalletCondition}
                        onChange={(checked: boolean) => {
                            setForm(
                                (prevForm: SubmissionForm): SubmissionForm => {
                                    return { ...prevForm, ScPalletCondition: checked };
                                }
                            );
                        }}
                    />
                </FormGroup>
            </Box>
        </Box>
    );
};
