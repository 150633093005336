import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import { getCompanySkusByTaskStateAction } from '../store/actions/action';
import * as api from '../store/api/reAssignTaskUserApi';

export function* reAssignTaskUserSaga(action) {
    try {
        const json = yield call(api.getReAssignTaskUserApi, action);
        yield put({ type: actionTypes.REASSIGN_TASK_DETAILS_SUCCESS, payload: json.data });
        yield put(getCompanySkusByTaskStateAction([1]));
    } catch (error) {
        yield put({ type: actionTypes.REASSIGN_TASK_DETAILS_FAILURE, error: error });
    }
}

export default function* reAssignTaskUserWatcher() {
    yield takeLatest(actionTypes.REASSIGN_TASK_DETAILS, reAssignTaskUserSaga);
}
