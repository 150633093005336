import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Drawer, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import StockCountTask from './StockCountTask';
// import Icon1 from '../../../assets/images/icon1.png';
// import Icon2 from '../../../assets/images/icon2.png';
import StockCountTaskPalletCards from './StockCountTaskPalletCards';
// import ArrowImage from '../../../assets/images/Arrow.png';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../common/components/Spinner/Spinner';

const StyledColumn = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,

    textAlign: 'left',
    color: '#9899A7',
    fontWeight: '500',
    [theme.breakpoints.up('sm')]: {
        minWidth: 180,
    },
    [theme.breakpoints.down('md')]: {
        minWidth: 130,
    },
}));

const StyledSpinner = styled(Spinner)(({ theme }) => ({
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 600,
    [theme.breakpoints.down('md')]: {
        minWidth: 500,
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: '100vw',
    },
}));

const StockTaskAccordian = ({ companyDetails, skuList, index, handleCloseDialog, tabType }) => {
    const [isPalletDrawerOpen, setIsPalletDrawerOpen] = useState(false);
    const [countTask, setCountTask] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const { isLoading } = useSelector(state => state.tasks);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const toggleDrawer = (open, selectedValue) => {
        setCountTask(selectedValue);
        setIsPalletDrawerOpen(open);
    };

    return (
        <div sx={{ width: '100%' }}>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                TransitionProps={{ unmountOnExit: true }}
                style={{ borderRadius: 0, borderTop: '2px solid #E0E2E5', boxShadow: '0 !important' }}
                elevation={0}
            >
                <AccordionSummary
                    style={{ boxShadow: 0 }}
                    // expandIcon={<img src={ArrowImage} alt="icon" />}
                    sx={{
                        '& .MuiIconButton-label > img': {
                            transform: 'rotate(-90deg)',
                        },
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    data-testid={`stock-count-task-${index}`}
                >
                    <Box display="flex" alignItems="center">
                        <div style={{ marginRight: 10 }}>
                            {/* <img src={index % 2 === 0 ? Icon1 : Icon2} alt="test" /> */}
                        </div>
                        <Typography
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(15),
                                fontWeight: theme => theme.typography.fontWeightRegular,
                            }}
                        >
                            {companyDetails.companyName}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0, boxShadow: 'none' }}>
                    <Grid container sx={{ width: '100%' }}>
                        <Grid container style={{ backgroundColor: '#F5F5FA', padding: '10px 16px' }}>
                            {/* <Grid item style={{backgroundColor:'#F5F5FA',padding:'10px 0 10px 5px'}}> */}
                            <Grid item xs={4}>
                                <StyledColumn>Task Name</StyledColumn>
                            </Grid>
                            <Grid item xs={4}>
                                <StyledColumn>Assignee</StyledColumn>
                            </Grid>
                            <Grid item xs={4} />
                        </Grid>
                        <Container disableGutters>
                            {skuList.map((skuData, index) => (
                                <StockCountTask
                                    key={`${skuData.sku}-${index}`}
                                    skuData={skuData}
                                    skuName={skuData.sku}
                                    index={index}
                                    companyDetails={companyDetails}
                                    toggleDrawer={toggleDrawer}
                                    totalItems={skuList.length}
                                    tabType={tabType}
                                />
                            ))}
                        </Container>
                    </Grid>
                    <Drawer
                        anchor={'right'}
                        PaperProps={{
                            style: {
                                backgroundColor: '#f4f8f9',
                            },
                        }}
                        open={isPalletDrawerOpen}
                        onClose={() => toggleDrawer(false, null)}
                    >
                        {isLoading ? (
                            <Box>
                                <StyledSpinner></StyledSpinner>
                            </Box>
                        ) : (
                            <StockCountTaskPalletCards
                                countTask={countTask}
                                toggleDrawer={toggleDrawer}
                                companyDetails={companyDetails}
                                handleCloseDialog={handleCloseDialog}
                                tabType={tabType}
                            />
                        )}
                    </Drawer>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default StockTaskAccordian;
