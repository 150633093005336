import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { Company } from '../../shipMatrixModel';
import CompaniesTransiferIndividualList from './CompaniesTransiferIndividualList';

function not(a: readonly Company[], b: readonly Company[]) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: readonly Company[], b: readonly Company[]) {
    return a.filter(value => b.indexOf(value) !== -1);
}

interface CompaniesTransferListUIProps {
    companyList: Company[];
    selectedClients: string[];
    setSelectedClients: React.Dispatch<React.SetStateAction<string[]>>;
    companyFilter?: string;
    currGroupId: number;
}

export default function CompaniesTransferListUI({
    companyList,
    selectedClients,
    setSelectedClients,
    companyFilter = '',
    currGroupId,
}: CompaniesTransferListUIProps) {
    const startingChosenCompanies = selectedClients.map(client => {
        const foundCompany = companyList.find(company => company.Mom === client);
        if (foundCompany) return foundCompany;
        return {} as Company;
    });

    const [checked, setChecked] = React.useState<readonly Company[]>([]);
    const [left, setLeft] = React.useState<readonly Company[]>(not(companyList, startingChosenCompanies));
    const [right, setRight] = React.useState<readonly Company[]>(startingChosenCompanies);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: Company) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        const newChosenCompanies = right.concat(left);
        setSelectedClients(newChosenCompanies.map(company => company.Mom));

        setRight(newChosenCompanies);
        setLeft([]);
    };

    const handleCheckedRight = () => {
        const newChosenCompanies = right.concat(leftChecked);
        setSelectedClients(newChosenCompanies.map(company => company.Mom));

        setRight(newChosenCompanies);
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        const newChosenCompanies = not(right, rightChecked);
        setSelectedClients(newChosenCompanies.map(company => company.Mom));

        setRight(newChosenCompanies);
        setLeft(left.concat(rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        const newChosenCompanies: Company[] = [];
        setSelectedClients(newChosenCompanies.map(company => company.Mom));
        setRight(newChosenCompanies);

        setLeft(left.concat(right));
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <CompaniesTransiferIndividualList
                    companies={left}
                    title={'Choices'}
                    companyFilter={companyFilter}
                    checked={checked}
                    currGroupId={currGroupId}
                    handleToggle={handleToggle}
                />
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                {' '}
                <CompaniesTransiferIndividualList
                    companies={right}
                    title={'Chosen'}
                    companyFilter={companyFilter}
                    checked={checked}
                    currGroupId={currGroupId}
                    handleToggle={handleToggle}
                />
            </Grid>
        </Grid>
    );
}
