import { Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '../../../app/store';

const NonIntrusiveSpinner = () => {
    const nonIntrusiveSpinnerCount = useAppSelector(state => state.notification.nonIntrusiveSpinnerCount);

    return (
        <Box
            data-testid="nonintrusiveSpinner"
            sx={{ position: 'fixed', left: 16, top: 12 }}
            hidden={nonIntrusiveSpinnerCount === 0}
        >
            <CircularProgress sx={{ margin: 'auto' }} data-testid={'nonintrusiveLoading'} color="secondary" />;
        </Box>
    );
};

export default NonIntrusiveSpinner;
