import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../../app/store';
import { setDialog } from '../../../notification/notificationSlice';
import { parseInitialQCScan } from '../../../qualityControl/util/handleInitialScan';
import { SerialFilter } from '../../clearSerialModels';
import SkuByUpcDropdown from './SkuByUpcDropdown';

const BarcodeForm = ({ client, onSubmit }: { client: string; onSubmit: (serial: SerialFilter[]) => void }) => {
    const dispatch = useAppDispatch();
    const [barcode, setBarcode] = React.useState('');
    const [selectedSku, setSelectedSku] = React.useState('');

    const parsedBarcode = parseInitialQCScan(client, barcode);

    const parsedUpc = parsedBarcode?.upc?.trim();
    const parsedSku = parsedBarcode?.sku?.trim();
    const serial = parsedBarcode?.serial?.trim();

    return (
        <form
            onSubmit={e => {
                e.preventDefault();

                const sku = parsedSku || selectedSku || '';
                if (!sku || !serial) {
                    dispatch(
                        setDialog({ title: 'Invalid Barcode', content: 'Barcode must have both a SKU/UPC and Serial' })
                    );
                    return;
                }

                onSubmit([
                    {
                        Client: client,
                        Sku: sku,
                        Serial: serial,
                    },
                ]);

                setBarcode('');
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex' }}>
                    <TextField
                        value={barcode}
                        onChange={e => {
                            const newValue = e.target.value;
                            setBarcode(newValue);
                        }}
                        label="Barcode"
                        name="Barcode"
                        sx={{ flex: 3, mx: 1 }}
                        required
                        variant="filled"
                    />

                    <Button type="submit" sx={{ flex: 1, mx: 1 }} variant="contained">
                        Add
                    </Button>
                </Box>
                <Divider flexItem sx={{ my: 2 }} />
                <Typography sx={{ m: 1 }}>Result: </Typography>
                <Box sx={{ display: 'flex' }}>
                    <TextField
                        value={parsedUpc ? parsedUpc : ''}
                        disabled
                        label="Upc"
                        name="Upc"
                        sx={{ flex: 1, mx: 1 }}
                        variant="filled"
                    />

                    {parsedUpc && !parsedSku ? (
                        <SkuByUpcDropdown
                            client={client}
                            upc={parsedUpc}
                            value={selectedSku}
                            onChange={(event, value) => {
                                if (value?.Sku) setSelectedSku(value.Sku);
                            }}
                            sx={{ flex: 1, mx: 1 }}
                            textFieldProps={{ label: 'Sku', name: 'Sku', variant: 'filled' }}
                        />
                    ) : (
                        <TextField
                            value={parsedSku ? parsedSku : ''}
                            label="Sku"
                            name="Sku"
                            sx={{ flex: 1, mx: 1 }}
                            variant="filled"
                            disabled
                        />
                    )}

                    <TextField
                        value={serial ? serial : ''}
                        disabled
                        label="Serial"
                        name="Serial"
                        sx={{ flex: 1, mx: 1 }}
                        variant="filled"
                    />
                </Box>
            </Box>
        </form>
    );
};

export default BarcodeForm;
