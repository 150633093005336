import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { green, red } from '@mui/material/colors';

export const renderBooleanCell = (value: boolean) => {
    return value ? (
        <CheckIcon
            style={{
                color: green[500],
            }}
        />
    ) : (
        <CloseIcon
            style={{
                color: red[500],
            }}
        />
    );
};
