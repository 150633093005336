import React from 'react';
import useNotificationByResponse from './useNotificationByResponse';

interface useNotificationByMutationResponseProps {
    useMutation: any;
    errorContent: string;
    successContent: string;
    finishedFuntion?: () => void;
}

const useNotificationByMutationResponse = ({
    useMutation,
    errorContent,
    successContent,
    finishedFuntion = () => {},
}: useNotificationByMutationResponseProps) => {
    const [triggerUseMutation, useMutationResponse] = useMutation;

    useNotificationByResponse(
        {
            isFetching: useMutationResponse.isLoading,
            isError: useMutationResponse.isError,
            errorContent: errorContent,
            isSuccess: useMutationResponse.isSuccess,
            successContent: successContent,
            isUninitialized: useMutationResponse.isUninitialized,
        },
        () => {
            finishedFuntion();
        }
    );

    return [triggerUseMutation, useMutationResponse];
};

export default useNotificationByMutationResponse;
