import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

const PREFIX = 'LinkToHome';

const classes = {
    navLink: `${PREFIX}-navLink`,
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    [`&.${classes.navLink}`]: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export const LinkToHome = color => {
    return (
        <StyledNavLink to={`/`} className={classes.navLink}>
            SEAL
        </StyledNavLink>
    );
};

export const LinkToTasks = ({ foreColor }) => {
    return (
        <NavLink to={`/tasks`} className={classes.navLink} style={{ color: foreColor ? foreColor : 'inherit' }}>
            <Typography>Task List</Typography>
        </NavLink>
    );
};

export const LinkToSupervisor = ({ foreColor }) => {
    return (
        <NavLink to={`/stockCount`} className={classes.navLink} style={{ color: foreColor ? foreColor : 'inherit' }}>
            <Typography>Supervisor</Typography>
        </NavLink>
    );
};
export const LinkToPallets = ({ foreColor }) => {
    return (
        <NavLink to={`/pallets`} className={classes.navLink} style={{ color: foreColor ? foreColor : 'inherit' }}>
            <Typography>Pallets</Typography>
        </NavLink>
    );
};
export const LinkToPalletsSearch = ({ foreColor }) => {
    return (
        <NavLink
            to={`/pallets-search`}
            className={classes.navLink}
            style={{ color: foreColor ? foreColor : 'inherit' }}
        >
            <Typography>Pallets Search</Typography>
        </NavLink>
    );
};
