import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { green, lime, red } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import { useAppSelector } from '../../../app/store';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .incomplete': {
        backgroundColor: red['A100'],
        '&:hover': { backgroundColor: red['A100'] },
    },

    '& .inProgress': {
        backgroundColor: '#fff',
        '&:hover': { backgroundColor: '#fff' },
    },

    '& .accepted': {
        backgroundColor: green['A200'],
        '&:hover': { backgroundColor: green['A200'] },
    },

    '& .bypass': {
        backgroundColor: lime['100'],
        '&:hover': { backgroundColor: lime['100'] },
    },
}));

const QualityControlTable = () => {
    const orderData = useAppSelector(state => state.qualityControl.orderData);

    const columns = [
        {
            field: 'Quants',
            headerName: 'Pending',
            width: 100,
        },

        {
            field: 'Quantp',
            headerName: 'Completed',
            width: 100,
        },
        { field: 'Quanto', headerName: 'Total', width: 100 },
        {
            field: 'Sku',
            headerName: 'SKU',
            flex: 1,
        },
        {
            field: 'Description',
            headerName: 'Description',
            flex: 2,
        },
    ];

    const itemIsServiceItem = item => {
        return item.NonProduct || item.Breakout;
    };

    return (
        <Box>
            <StyledDataGrid
                disableRowSelectionOnClick
                rows={orderData.Items.filter(item => !itemIsServiceItem(item))}
                columns={columns}
                getRowId={row => row.ItemId}
                sx={{
                    m: 5,
                }}
                getRowClassName={params => {
                    const pending = params.row.Quants;
                    const completed = params.row.Quantp;
                    const total = params.row.Quanto;

                    if (itemIsServiceItem(params.row)) return 'bypass';
                    if (completed === 0) return 'incomplete';
                    if (completed === total) return 'accepted';
                    if (completed < total) return 'inProgress';

                    return 'accepted';
                }}
            />
        </Box>
    );
};

export default QualityControlTable;
