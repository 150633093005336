import { Autocomplete, Box, TextField } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { useAppSelector } from '../../../../../app/store';
import receiversApi from '../../../oldReceiversApi';
import { UNIT_TYPES_OPTIONS } from '../../../receiversConstants';
import { SubmissionForm } from '../../../receiversModels';
import { isLadingError } from '../../../receiversUtil';
import MemoTextField from './formControlledInputs/MemoTextField';

interface TextFieldFieldsProps {
    form: SubmissionForm;
    setForm: Dispatch<SetStateAction<SubmissionForm>>;
    isInEditMode: boolean;
    shouldFormShowError: boolean;
}

export const TextFieldFields: FC<TextFieldFieldsProps> = ({ form, setForm, isInEditMode, shouldFormShowError }) => {
    const { username } = useAppSelector(state => state.authentication);

    const config = receiversApi.endpoints.getReceiversConfigDeprecated.useQuery('FRAN');

    return (
        <>
            {' '}
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <TextField
                    disabled
                    value={form.RecBy}
                    label="Received By"
                    variant="outlined"
                    sx={{
                        mt: 2,
                        width: '100%',
                        flex: 1,
                    }}
                />

                <TextField
                    disabled
                    value={username}
                    label="Edited By"
                    variant="outlined"
                    sx={{
                        display: isInEditMode ? 'inline-flex' : 'none',
                        mt: 2,
                        width: '100%',
                        flex: 1,
                    }}
                />
            </Box>
            <Autocomplete
                disablePortal
                autoSelect
                options={
                    config?.data?.Rules?.find(rule => rule.Label === 'Location')?.Options.map(option => option.Label) ||
                    []
                }
                renderInput={params => <TextField {...params} label="Location" />}
                value={form.Location}
                onChange={(event: any, newValue: string | null) => {
                    setForm(
                        (prevForm: SubmissionForm): SubmissionForm => {
                            return { ...prevForm, Location: newValue || '' };
                        }
                    );
                }}
                sx={{
                    mt: 2,
                }}
                isOptionEqualToValue={(option, value) => value === option}
            />
            <Autocomplete
                disablePortal
                autoSelect
                options={UNIT_TYPES_OPTIONS}
                renderInput={params => <TextField {...params} label="Unit Type" />}
                value={form.UnitType}
                onChange={(event: any, newValue: string | null) => {
                    setForm(
                        (prevForm: SubmissionForm): SubmissionForm => {
                            return { ...prevForm, UnitType: newValue || '' };
                        }
                    );
                }}
                sx={{
                    mt: 2,
                }}
                isOptionEqualToValue={(option, value) => value === option}
            />
            <MemoTextField
                label={'Shipper'}
                value={form.Shipper}
                onChange={(newValue: string) => {
                    setForm(
                        (prevForm: SubmissionForm): SubmissionForm => {
                            return { ...prevForm, Shipper: newValue };
                        }
                    );
                }}
            />
            <MemoTextField
                label={'Carrier'}
                value={form.Carrier}
                onChange={(newValue: string) => {
                    setForm(
                        (prevForm: SubmissionForm): SubmissionForm => {
                            return { ...prevForm, Carrier: newValue };
                        }
                    );
                }}
            />
            <MemoTextField
                label={'Packing List Number'}
                value={form.Pack}
                onChange={(newValue: string) => {
                    setForm(
                        (prevForm: SubmissionForm): SubmissionForm => {
                            return { ...prevForm, Pack: newValue };
                        }
                    );
                }}
            />
            <TextField
                label={'BOL / Tracking*'}
                value={form.Lading}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    setForm(
                        (prevForm: SubmissionForm): SubmissionForm => {
                            return { ...prevForm, Lading: newValue };
                        }
                    );
                }}
                error={isLadingError(form.Lading, form.Project) && shouldFormShowError}
                sx={{ mt: 2, width: '100%' }}
                helperText={isLadingError(form.Lading, form.Project) && shouldFormShowError ? 'Required' : ' '}
            />
        </>
    );
};
