import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/stocksApi';

export function* searchPalletByFiltersSaga(action) {
    try {
        yield put({ type: actionTypes.SET_STOCKS_REDUCER_LOADING, payload: true });
        const response = yield call(api.searchPalletByFilterApi, action);
        if (response && !Array.isArray(response) && response.data) {
            yield put({
                type: actionTypes.SEARCH_PALLETS_BY_FILTERS_SUCCESS,
                payload: response && response.data,
                meta: { palletTag: action.meta.palletTag },
            });
        } else {
            yield put({
                type: actionTypes.SEARCH_PALLETS_BY_FILTERS_SUCCESS,
                payload: response,
                meta: { palletTag: action.meta.palletTag },
            });
        }
    } catch (error) {
        yield put({
            type: actionTypes.SEARCH_PALLETS_BY_FILTERS_FAILURE,
            errorMessage: error,
        });
    }
}

export function* loadMoreSearchPalletByFiltersSaga(action) {
    try {
        yield put({ type: actionTypes.SET_LOAD_MORE_SEARCH_PALLET_LOADING });
        const response = yield call(api.loadMoreSearchPalletByFilterApi, action);
        if (response && !Array.isArray(response) && response.data) {
            yield put({
                type: actionTypes.LOAD_MORE_SEARCH_PALLETS_BY_FILTERS_SUCCESS,
                payload: response && response.data,
                meta: { palletTag: action.meta.palletTag },
            });
        } else {
            yield put({
                type: actionTypes.LOAD_MORE_SEARCH_PALLETS_BY_FILTERS_SUCCESS,
                payload: response,
                meta: { palletTag: action.meta.palletTag },
            });
        }
    } catch (error) {
        yield put({
            type: actionTypes.LOAD_MORE_SEARCH_PALLETS_BY_FILTERS_FAILURE,
            errorMessage: error,
        });
    }
}

export function* getStockPalletFiltersSaga(action) {
    // FETCH FILTERS FOR FILTERING THE PALLET
    try {
        yield put({ type: actionTypes.SET_STOCKS_REDUCER_LOADING, payload: true });
        const palletFiltersResponse = yield call(api.getStockPalletFilterApi, action);
        yield put({
            type: actionTypes.GET_STOCK_PALLET_FILTERS_SUCCESS,
            payload: palletFiltersResponse.data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.GET_STOCK_PALLET_FILTERS_FAILURE,
            errorMessage: 'Something is not right. Please try again.',
        });
    }
}

export function* getStockPalletLogsSaga(action) {
    // FETCH FILTERS FOR FILTERING THE PALLET
    try {
        const palletFiltersResponse = yield call(api.getStockPalletLogsApi, action);
        yield put({
            type: actionTypes.GET_STOCK_PALLET_LOGS_SUCCESS,
            payload: palletFiltersResponse.data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.GET_STOCK_PALLET_LOGS_FAILURE,
            errorMessage: 'Something is not right. Please try again.',
        });
    }
}

export function* updatePalletLocationSaga(action) {
    try {
        yield put({ type: actionTypes.SET_STOCK_PALLET_ACTION_LOADING, payload: true });
        const res = yield call(api.updatePalletLocationApi, action);
        yield put({ type: actionTypes.UPDATE_PALLET_LOCATION_SUCCESS, payload: res.data });
        //re-fetch pallets by pallet tag
        yield put({
            type: actionTypes.SEARCH_PALLETS_BY_FILTERS,
            payload: {
                palletTags: [action.payload.palletTag],
                locations: [],
                skus: [],
                upcs: [],
                client: [],
            },
            meta: {
                palletTag: action.payload.palletTag,
            },
        });
        // re-fetch pallets log
        yield put({ type: actionTypes.GET_STOCK_PALLET_LOGS, payload: { palletTag: action.payload.palletTag } });
    } catch (error) {
        yield put({ type: actionTypes.UPDATE_PALLET_LOCATION_FAILURE });
    }
}

export function* retirePalletTagSaga(action) {
    try {
        yield put({ type: actionTypes.SET_STOCK_PALLET_ACTION_LOADING, payload: true });
        const res = yield call(api.retirePalletTagApi, action);
        yield put({ type: actionTypes.RETIRE_PALLET_TAG_SUCCESS, payload: res.data });
        yield put({
            type: actionTypes.SEARCH_PALLETS_BY_FILTERS,
            payload: {
                palletTags: [action.payload.palletTag],
                locations: [],
                skus: [],
                upcs: [],
                client: [],
            },
            meta: {
                palletTag: action.payload.palletTag,
            },
        });
    } catch (error) {
        yield put({ type: actionTypes.RETIRE_PALLET_TAG_FAILURE });
    }
}

export function* quarantinePalletTagSaga(action) {
    try {
        yield put({ type: actionTypes.SET_STOCK_PALLET_ACTION_LOADING, payload: true });
        const res = yield call(api.quarantinePalletTagApi, action);
        yield put({
            type: actionTypes.QUARANTINE_PALLET_TAG_SUCCESS,
            payload: { data: res.data, quarantine: action.payload.quarantine },
        });
        yield put({
            type: actionTypes.SEARCH_PALLETS_BY_FILTERS,
            payload: {
                palletTags: [action.payload.data.palletTag],
                locations: [],
                skus: [],
                upcs: [],
                client: [],
            },
            meta: {
                palletTag: action.payload.data.palletTag,
            },
        });
        yield put({ type: actionTypes.GET_STOCK_PALLET_LOGS, payload: { palletTag: action.payload.data.palletTag } });
    } catch (error) {
        yield put({ type: actionTypes.QUARANTINE_PALLET_TAG_FAILURE });
    }
}

export function* clonePalletTagSaga(action) {
    try {
        yield put({ type: actionTypes.SET_STOCK_PALLET_ACTION_LOADING, payload: true });
        const res = yield call(api.clonePalletTagApi, action);
        yield put({ type: actionTypes.CLONE_PALLET_TAG_SUCCESS, payload: res.data });
        yield put({ type: actionTypes.GET_STOCK_PALLET_LOGS, payload: { palletTag: action.payload.palletTag } });
    } catch (error) {
        yield put({ type: actionTypes.CLONE_PALLET_TAG_FAILURE });
    }
}

export function* validatePalletTagSaga(action) {
    try {
        yield put({ type: actionTypes.SET_STOCK_PALLET_ACTION_LOADING, payload: true });
        const res = yield call(api.validatePalletTagApi, action);
        yield put({ type: actionTypes.VALIDATE_PALLET_TAG_SUCCESS, payload: res.data });
        yield put({ type: actionTypes.GET_STOCK_PALLET_LOGS, payload: { palletTag: action.payload.palletTag } });
    } catch (error) {
        yield put({ type: actionTypes.VALIDATE_PALLET_TAG_FAILURE });
    }
}

export function* getWarehouseListSaga(action) {
    try {
        yield put({ type: actionTypes.SET_STOCK_WAREHOUSE_OPTIONS_LOADING });
        const res = yield call(api.getWarehouseListApi, action);
        yield put({ type: actionTypes.GET_WAREHOUSE_LIST_SUCCESS, payload: res.data });
    } catch (error) {
        yield put({ type: actionTypes.GET_WAREHOUSE_LIST_FAILURE });
    }
}

export function* getLocationListByWarehouseSaga(action) {
    try {
        yield put({
            type: actionTypes.SET_STOCK_LOCATION_OPTIONS_LOADING,
        });
        const res = yield call(api.getLocationListByWarehouseApi, action);
        yield put({ type: actionTypes.GET_LOCATIONS_BY_WAREHOUSE_SUCCESS, payload: res.data });
    } catch (error) {
        yield put({ type: actionTypes.GET_LOCATIONS_BY_WAREHOUSE_FAILURE });
    }
}

export default function* stockSagaWatcher() {
    yield takeLatest(actionTypes.SEARCH_PALLETS_BY_FILTERS, searchPalletByFiltersSaga);
    yield takeLatest(actionTypes.GET_STOCK_PALLET_FILTERS, getStockPalletFiltersSaga);
    yield takeLatest(actionTypes.GET_STOCK_PALLET_LOGS, getStockPalletLogsSaga);
    yield takeLatest(actionTypes.RETIRE_PALLET_TAG, retirePalletTagSaga);
    yield takeLatest(actionTypes.QUARANTINE_PALLET_TAG, quarantinePalletTagSaga);
    yield takeLatest(actionTypes.CLONE_PALLET_TAG, clonePalletTagSaga);
    yield takeLatest(actionTypes.VALIDATE_PALLET_TAG, validatePalletTagSaga);
    yield takeLatest(actionTypes.UPDATE_PALLET_LOCATION, updatePalletLocationSaga);
    yield takeLatest(actionTypes.LOAD_MORE_SEARCH_PALLETS_BY_FILTERS, loadMoreSearchPalletByFiltersSaga);
    yield takeLatest(actionTypes.GET_WAREHOUSE_LIST, getWarehouseListSaga);
    yield takeLatest(actionTypes.GET_LOCATIONS_BY_WAREHOUSE, getLocationListByWarehouseSaga);
}
