import {
    CLEAR_SERIALS_ENDPOINT,
    GET_RECEIVERS_ITEM_URL,
    GET_SERIAL_STATUS_ENDPOINT,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import { GetItemResponse } from '../receivers/receiversModels';
import {
    ClearSerialsRequest,
    GetSerialStatusRequest,
    GetSerialStatusResponse,
    GetSkuRequest,
} from './clearSerialModels';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['SerialStatuses'] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getSerialStatuses: builder.query<GetSerialStatusResponse, GetSerialStatusRequest>({
            query: param => ({
                url: prependDreamUrl(GET_SERIAL_STATUS_ENDPOINT),
                method: 'POST',
                body: param,
                keepUnusedDataFor: 5,
            }),
            providesTags: ['SerialStatuses'],
        }),
        clearSerials: builder.mutation<void, ClearSerialsRequest>({
            query: param => ({
                url: prependDreamUrl(CLEAR_SERIALS_ENDPOINT),
                method: 'POST',
                body: param,
            }),
            invalidatesTags: ['SerialStatuses'],
        }),
        getSku: builder.query<GetItemResponse, GetSkuRequest>({
            query: param => ({
                url: prependDreamUrl(`${GET_RECEIVERS_ITEM_URL}`),
                params: {
                    Upc: param.Upc,
                },
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.Client,
                },
            }),
        }),
    }),
});
