import { TextField } from '@mui/material';

import React, { FC, memo } from 'react';

interface MemoTextFieldProps {
    label: string;
    value: string;
    onChange: (newValue: string) => void;
    helperText?: string;
    error?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
}

const MemoTextField: FC<MemoTextFieldProps> = memo(
    ({ label, value, onChange, helperText, error = false, disabled = false, autoFocus = false }) => {
        return (
            <TextField
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                }}
                label={label}
                key={label}
                variant="outlined"
                sx={{ mt: 2, width: '100%' }}
                error={error}
                helperText={helperText || ''}
                disabled={disabled}
                autoFocus={autoFocus}
            />
        );
    },
    arePropsEqual
);

function arePropsEqual(oldProps: MemoTextFieldProps, newProps: MemoTextFieldProps) {
    return oldProps.value === newProps.value;
}

export default MemoTextField;
