import { RefObject } from 'react';

export default function textFieldFocus({
    textFieldRef,
}: {
    textFieldRef: RefObject<HTMLInputElement> | undefined | null;
}) {
    if (!!textFieldRef && textFieldRef.current) {
        textFieldRef.current.focus();
    }
}
