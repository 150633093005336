import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface OrderDetailsState {
    momCode: string | undefined;
    orderId: number | undefined;
}

const initialState: OrderDetailsState = {
    momCode: undefined,
    orderId: undefined,
};

const slice = createSlice({
    name: 'orderLookup',
    initialState,
    reducers: {
        setMomCode: (state, action: PayloadAction<string | undefined>) => {
            state.momCode = action.payload;
        },
        setOrderId: (state, action: PayloadAction<number | undefined>) => {
            state.orderId = action.payload;
        },
    },
});

export const { setMomCode, setOrderId } = slice.actions;

export default slice.reducer;
