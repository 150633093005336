import {
    ROLE_URL,
    ROLE_GET_ALL_USERS_URL,
    EDIT_USER_ROLE_URL,
    GET_ALL_ROLE_PERMISSION_URL,
    EDIT_ROLE_PERMISSIONS_URL,
} from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getAllUsersApi = async action => {
    const res = await API.post(`${ROLE_GET_ALL_USERS_URL}`, action.payload);
    return res;
};

export const getRoleApi = async action => {
    const res = await API.get(`${ROLE_URL}`);
    return res;
};

export const addRoleApi = async action => {
    const res = await API.post(`${ROLE_URL}`, action.payload.data);
    return res;
};

export const updateRoleApi = async action => {
    const res = await API.put(`${ROLE_URL}`, action.payload.data);
    return res;
};

export const deleteRoleApi = async action => {
    const res = await API.delete(`${ROLE_URL}`, {
        data: action.payload,
    });
    return res;
};

export const editUserRoleApi = async action => {
    const res = await API.post(`${EDIT_USER_ROLE_URL}`, action.payload?.data);
    return res;
};

export const getAllRolePermissionApi = async action => {
    const res = await API.post(`${GET_ALL_ROLE_PERMISSION_URL}`, { pageNo: 1, pageSize: 100 });
    return res;
};

export const getPermissionsByRoleId = async action => {
    const res = await API.post(`${GET_ALL_ROLE_PERMISSION_URL}`, action.payload);
    return res;
};

export const editRolePermissionsApi = async action => {
    const res = await API.post(`${EDIT_ROLE_PERMISSIONS_URL}`, action.payload);
    return res;
};
