import { Box, Card, CardContent, Chip, Typography } from '@mui/material';
import { formatDateWithoutSeconds } from '../../../common/utils/dateUtil';
import useGetCreatedProcedureRecord from '../hooks/useGetCreatedProcedureRecord';

const ProcedureDetailsCard = () => {
    const response = useGetCreatedProcedureRecord();

    return (
        <Card sx={{ mb: 2 }} elevation={5}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h5" sx={{ flex: 1 }}>
                        {response.data?.Title}
                    </Typography>
                    <Typography variant="body2" sx={{ flex: 1 }}>
                        Created by {response.data?.CreatedBy} on {formatDateWithoutSeconds(response.data?.CreatedAt)}
                    </Typography>
                </Box>

                <Chip label={response.data?.Client} size="medium" color="info" />
            </CardContent>
        </Card>
    );
};

export default ProcedureDetailsCard;
