import { Box } from '@mui/material';
import useNotificationByQueryResponse from '../../../../common/hooks/useNotificationByQueryResponse';
import receiversApi from '../../receiversApi';
import ReceiversDataGrid from './ReceiversDataGrid';
import ReceiversFilter from './ReceiversFilter';

const ViewRecievers = ({ shouldNavigateToReceiversPage }: { shouldNavigateToReceiversPage: boolean }) => {
    const [triggerGetReceivers, receiversResponse] = receiversApi.endpoints.getReceivers.useLazyQuery();

    useNotificationByQueryResponse({
        response: receiversResponse,
        errorMessage: 'Could not get receivers',
    });

    return (
        <Box sx={{ backgroundColor: '#FFF', pt: 1, pb: 1 }}>
            <ReceiversFilter triggerGetReceivers={triggerGetReceivers} />
            <ReceiversDataGrid
                shouldNavigateToReceiversPage={shouldNavigateToReceiversPage}
                receivers={receiversResponse.data || []}
            />
        </Box>
    );
};

export default ViewRecievers;
