import { Grid } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import { getUsdString } from '../../../../common/utils/commonUtils';
import { formatDayjsDate } from '../../../../common/utils/dateUtil';
import orderLookupApi from '../../orderLookupApi';
import { ReadOnlyTextField } from '../ReadOnlyTextField';

export function HistoryOrderDetailsDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getHistoryOrderResponse = orderLookupApi.endpoints.getHistoryOrder.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const historyOrderData = getHistoryOrderResponse.data?.Data[0];

    return (
        <React.Fragment>
            <Grid container spacing={2} direction="row">
                <Grid item xs={3}>
                    <ReadOnlyTextField label={'Order Id'} value={historyOrderData?.Id} />
                    <ReadOnlyTextField
                        label={'Record Created'}
                        value={formatDayjsDate(historyOrderData?.RecordCreated)}
                    />
                    <ReadOnlyTextField label={'Order Date'} value={formatDayjsDate(historyOrderData?.OrderDate)} />
                    <ReadOnlyTextField label={'Imported'} value={formatDayjsDate(historyOrderData?.Imported)} />
                </Grid>
                <Grid item xs={2.5}>
                    <ReadOnlyTextField label={'SrcCode'} value={historyOrderData?.SrcCode} />
                    <ReadOnlyTextField label={'Order Status'} value={historyOrderData?.OrderStatus} />
                    <ReadOnlyTextField label={'Order Total'} value={getUsdString(historyOrderData?.OrderTotal)} />
                </Grid>
                <Grid item xs={2.5}>
                    <ReadOnlyTextField label={'Ship Method'} value={historyOrderData?.ShipMethod} />
                    <ReadOnlyTextField label={'CatCode'} value={historyOrderData?.CatCode} />
                    <ReadOnlyTextField label={'Database Name'} value={historyOrderData?.DatabaseName} />
                </Grid>
                <Grid item xs={4}>
                    <ReadOnlyTextField label={'Tokens'} value={historyOrderData?.Tokens} />
                    <ReadOnlyTextField label={'PoNumber'} value={historyOrderData?.PoNumber} />
                    <ReadOnlyTextField label={'Session'} value={historyOrderData?.Session} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
