import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    Button,
} from '@mui/material';

import { getComparator, stableSort, debounce } from '../../common/utils/commonUtils';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTableHead from '../../archive/components/users/EnhancedTableHead';
import EnhancedTableToolbar from '../../archive/components/users/EnhancedTableToolbar';
import { Spinner, SuccessSnackbar } from '../../common/components';
import { getAllUsersAction, resetUsersSuccessStatusAction } from '../../archive/store/actions/action';
import UsersRoleDrawer from '../../archive/components/users/UsersRoleDrawer';

const PREFIX = 'Users';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    roleModalWrapper: `${PREFIX}-roleModalWrapper`,
    iconBtnStyles: `${PREFIX}-iconBtnStyles`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.paper}`]: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.table}`]: {
        width: '100%',
    },

    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    [`& .${classes.roleModalWrapper}`]: {
        width: 'min(500px,80%)',
        padding: 20,
    },

    [`& .${classes.iconBtnStyles}`]: {
        outline: 'none',
        padding: '7px',
        '&:focus': {
            outline: 'none',
        },
    },
}));

const Users = () => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const dispatch = useDispatch();
    const {
        users: { isLoading, rolesList, success, successMessage },
        roles: { usersList },
    } = useSelector(state => state);

    useEffect(() => {
        dispatch(resetUsersSuccessStatusAction());
        dispatch(getAllUsersAction({ pageSize: 1000 }));
    }, [dispatch]);

    const handleSearchTextChange = useCallback(value => {
        setSearchText(value);
    }, []);

    useEffect(() => {
        if (success && successMessage !== '' && successMessage.includes('un-assigned')) {
            setSelectedUser(null);
        }
    }, [success, successMessage]);

    const handleChange = useCallback(debounce(handleSearchTextChange, 300), []);

    const usersArray = useMemo(() => {
        if (usersList?.[0]) {
            return stableSort(usersList, getComparator(order, orderBy)).reduce((acc, row, index) => {
                if (row.userName.toLowerCase().includes(searchText?.toLowerCase())) {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    acc.push(
                        <TableRow hover key={row.userId} color="primary">
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                style={{ paddingTop: '12px', paddingBottom: '12px' }}
                            >
                                <Typography
                                    variant="body2"
                                    color="initial"
                                    style={{ paddingLeft: 15, fontWeight: 500 }}
                                >
                                    {index + 1}
                                </Typography>
                            </TableCell>
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                style={{
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    fontWeight: 500,
                                }}
                            >
                                {row.userName}
                            </TableCell>
                            <TableCell align="left" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ padding: '2px 1rem' }}
                                        data-testid={`edit-role-btn-${index}`}
                                        onClick={() => setSelectedUser(row)}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    );
                    return acc;
                }
                return acc;
            }, []);
        }
        return [];
    }, [usersList, searchText, order, orderBy]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, usersArray.length - page * rowsPerPage);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <StyledContainer style={{ paddingTop: '1rem', paddingBottom: '2rem', maxWidth: '1400px' }}>
            <Typography
                variant="h5"
                color="initial"
                className="my-3"
                style={{ fontWeight: '500', margin: ' 0 0 1rem 0' }}
            >
                Users
            </Typography>
            <div className={classes.root} data-testid="role-table-element">
                {success && <SuccessSnackbar show={success} message={successMessage} />}
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar handleChange={handleChange} />
                    <TableContainer>
                        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {!selectedUser && isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: 300,
                                                    width: 'calc(100vw - 60px)',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <Spinner />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    usersArray?.[0] &&
                                    usersArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                )}
                                {emptyRows > 0 && !isLoading && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={3} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 25]}
                        component="div"
                        count={usersArray.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            {selectedUser && (
                <UsersRoleDrawer
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    rolesList={rolesList}
                    isLoading={isLoading}
                />
            )}
        </StyledContainer>
    );
};

export default Users;
