import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../app/store';
import { getUsdString } from '../../../common/utils/commonUtils';
import orderLookupApi from '../orderLookupApi';
import { Item } from '../orderLookupModels';
import { SerialsAddDialog } from './Serials/SerialsAddDialog';
import { SerialsViewDialog } from './Serials/SerialsViewDialog';

interface GridItem extends Item {
    id: number; // DataGrid needs id attribute for each element.
}

export function ItemsDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const itemsData = getOrderDetailsResponse.data?.Data[0]?.Items;
    const [serialsViewDialogOpen, setSerialsViewDialogOpen] = React.useState(false);
    const [serialsAddDialogOpen, setSerialsAddDialogOpen] = React.useState(false);
    const [selectedViewSerialsItemData, setSelectedViewSerialsItemData] = React.useState<Item>();
    const [selectedAddSerialsItemData, setSelectedAddSerialsItemData] = React.useState<Item>();
    const [gridItemsData, setGridItemsData] = React.useState<GridItem[]>([]);
    const MAX_CUSTOM_INFO_LEN = 40;

    // Updates display on change
    React.useEffect(() => {
        let newItemsList: GridItem[] = [];
        itemsData?.map((item, index) => {
            newItemsList.push({ ...item, id: index + 1 });
        });
        setGridItemsData(newItemsList);
        if (!!selectedViewSerialsItemData) {
            setSerialsViewItemData(selectedViewSerialsItemData.ItemId);
        }
    }, [itemsData]);

    const getCustomInfoDisplay = (customInfo: string) => {
        if (customInfo.length > MAX_CUSTOM_INFO_LEN) {
            return `${customInfo.substring(0, MAX_CUSTOM_INFO_LEN)}...`;
        }
        return customInfo;
    };

    const hasSerials = (itemId: number) => {
        return !!itemsData?.some(item => item.ItemId === itemId && item.Serials?.length > 0);
    };

    const setSerialsViewItemData = (itemId: number) => {
        const selectedItem = itemsData?.find(item => item.ItemId === itemId);
        if (!!selectedItem) {
            setSelectedViewSerialsItemData(selectedItem);
            setSerialsViewDialogOpen(true);
        }
    };

    const setSerialsAddItemData = (itemId: number) => {
        const selectedItem = itemsData?.find(item => item.ItemId === itemId);
        if (!!selectedItem) {
            setSelectedAddSerialsItemData(selectedItem);
            setSerialsAddDialogOpen(true);
        }
    };

    const columns: GridColDef[] = [
        {
            align: 'center',
            headerAlign: 'center',
            field: 'Inpart',
            headerName: 'Inpart',
            flex: 1.5,
            valueGetter: (params, row) => (!!row.Inpart ? row.Inpart : '-'),
        },
        {
            field: 'Item',
            headerName: 'SKU',
            flex: 2,
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 2,
        },
        {
            field: 'Description',
            headerName: 'Description',
            flex: 4,
            renderCell: params => (
                <Box sx={{ pt: 1 }}>
                    <Box>{params.value}</Box>
                    <Tooltip title={!!params.row.CustomInfo ? params.row.CustomInfo : ''} placement="top-start">
                        <Box sx={{ pb: 1 }}>
                            <Typography fontSize={11}>{getCustomInfoDisplay(params.row.CustomInfo)}</Typography>
                        </Box>
                    </Tooltip>
                </Box>
            ),
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'QuantityOrder',
            headerName: 'Qty Ordered',
            type: 'number',
            flex: 1.5,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'QuantityBacklog',
            headerName: 'Qty B/O',
            type: 'number',
            flex: 1.5,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'QuantityShip',
            headerName: 'Qty Shipped',
            type: 'number',
            flex: 1.5,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'UnitPrice',
            headerName: 'Unit Price',
            renderCell: params => getUsdString(params.value),
            type: 'number',
            flex: 1.5,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'extendedprice',
            headerName: 'Extended Price',
            valueGetter: (params, row) => row.UnitPrice * row.QuantityOrder,
            renderCell: params => getUsdString(params.row.UnitPrice * params.row.QuantityOrder),
            type: 'number',
            flex: 2.5,
        },
        {
            field: 'serials',
            headerName: 'Edit Serials',
            align: 'center',
            headerAlign: 'center',
            flex: 2,
            renderCell: params => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ flex: 3 }}>
                        <Tooltip
                            title={hasSerials(params.row.ItemId) ? 'View/Update Serials' : 'No Serials'}
                            placement="top-start"
                        >
                            <span>
                                {/* Added span to have Tooltip work for disabled Button */}
                                <Button
                                    sx={{ m: -1.5 }}
                                    disabled={!hasSerials(params.row.ItemId)}
                                    variant="text"
                                    onClick={() => {
                                        setSerialsViewItemData(params.row.ItemId);
                                    }}
                                >
                                    <VisibilityIcon color={hasSerials(params.row.ItemId) ? 'primary' : 'disabled'} />
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                    <Box sx={{ flex: 3 }}>
                        <Tooltip
                            title={!params.row.IsSerialized ? 'This SKU is not serialized' : 'Add Serials'}
                            placement="top-start"
                        >
                            <span>
                                {/* Added span to have Tooltip work for disabled Button */}
                                <Button
                                    sx={{ m: -1.5 }}
                                    disabled={!params.row.IsSerialized}
                                    variant="text"
                                    onClick={() => {
                                        setSerialsAddItemData(params.row.ItemId);
                                    }}
                                >
                                    <LibraryAddIcon color={params.row.IsSerialized ? 'primary' : 'disabled'} />
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            ),
        },
    ];

    if (!!itemsData) {
        return (
            <Box style={{ width: 1120 }}>
                <SerialsViewDialog
                    itemData={selectedViewSerialsItemData}
                    handleOpenDialog={setSerialsViewDialogOpen}
                    showDialog={serialsViewDialogOpen}
                />
                <SerialsAddDialog
                    itemData={selectedAddSerialsItemData}
                    handleOpenDialog={setSerialsAddDialogOpen}
                    showDialog={serialsAddDialogOpen}
                />
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'Inpart', sort: 'asc' }],
                        },
                    }}
                    autoHeight
                    rows={gridItemsData || []}
                    columns={columns}
                    disableRowSelectionOnClick
                    scrollbarSize={20}
                    hideFooter
                    getRowHeight={() => 'auto'}
                />
            </Box>
        );
    } else {
        return (
            <Box>
                <Typography> NO ITEMS </Typography>
            </Box>
        );
    }
}
