import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { BULK_UNIT_THRESHOLD_QTY } from '../../constants';
import { ExpectedItem, ReceivedItem } from '../../rmaModels';
import { ConfirmClosingDialog } from '../ConfirmClosingDialog';
import { ReceivedItemUnitDetailDisplay } from './ReceivedItemUnitDetailDisplay';
import { setSnackbar } from '../../../notification/notificationSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import { isRmaCompleted } from '../../utilities';

const unknownItemLabel = 'UNKNOWN ITEM';

export const ReceivedItemUnitDetailDialog = React.memo(
    ({
        receivedItem,
        expectedItem,
        setReceivedItem,
        handleOpenDialog,
        showDialog = false,
    }: {
        receivedItem: ReceivedItem;
        expectedItem?: ExpectedItem;
        setReceivedItem: (item: ReceivedItem) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const dialogTitle = isRmaCompleted(rma?.Status) ? 'DETAILS FOR' : 'PROCESS';
        const [editingItem, setEditingItem] = React.useState<ReceivedItem>(receivedItem);
        const [showErrors, setShowErrors] = React.useState(false);
        const [confirmCloseDialog, setConfirmCloseDialog] = React.useState(false);
        const receivedItemNotStarted = editingItem.Quantity === null || editingItem.Quantity === undefined;

        const dispatch = useDispatch();

        const closeWithoutSaving = () => {
            setConfirmCloseDialog(true);
        };

        const handleSaveEditedItem = () => {
            // Check if fields are properly filled out
            if (!passFormErrorValidation()) {
                dispatch(
                    setSnackbar({
                        content: 'Please update all units.',
                        severity: 'error',
                    })
                );
                setShowErrors(true);
                return;
            }

            savedEditedItem();
            setShowErrors(false);
            handleOpenDialog(false);
        };

        const savedEditedItem = () => {
            setReceivedItem({
                ...editingItem,
                Serials: editingItem.ItemUnitDetails.map(unit => unit.Serials).flat(),
                ValidToSubmit: true,
            });
        };

        const passFormErrorValidation = () => {
            // Ensure a received qty has been entered
            if (editingItem.Quantity === null) {
                return false;
            }
            // Check if each item unit has been completed
            if (editingItem.ItemUnitDetails.some(unit => !unit.ValidToSubmit)) {
                return false;
            }
            return true;
        };

        React.useEffect(() => {
            if (receivedItem.NotFound) {
                setShowErrors(false);
            }
            setEditingItem({ ...receivedItem });
        }, [receivedItem.NotFound]);

        return (
            <React.Fragment>
                <ConfirmClosingDialog
                    showDialog={confirmCloseDialog}
                    handleOpenDialog={setConfirmCloseDialog}
                    setPreviousDialog={handleOpenDialog}
                    unsavedItem={receivedItem}
                    resetToUnsavedItem={setEditingItem}
                />
                <Dialog
                    open={showDialog}
                    maxWidth={!!editingItem.Quantity && editingItem.Quantity > BULK_UNIT_THRESHOLD_QTY ? 'lg' : 'md'}
                    fullWidth
                    PaperProps={{
                        sx: { backgroundColor: grey[100] },
                    }}
                >
                    <DialogTitle color="white" bgcolor="primary.main" align="center">
                        <Typography fontWeight={'fontWeightMedium'} variant="h5">
                            {dialogTitle} SKU: {expectedItem?.Sku || receivedItem.Sku || unknownItemLabel}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <ReceivedItemUnitDetailDisplay
                                editingItem={editingItem}
                                expectedItem={expectedItem}
                                setEditingItem={setEditingItem}
                                showErrors={showErrors}
                            />
                            {!receivedItemNotStarted && !isRmaCompleted(rma?.Status) && (
                                <Box sx={{ display: 'flex', mt: 1 }}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={handleSaveEditedItem}
                                        variant="contained"
                                    >
                                        SAVE CHANGES
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogCloseButton
                        handleClose={() => {
                            if (isRmaCompleted(rma?.Status)) {
                                handleOpenDialog(false);
                            } else {
                                closeWithoutSaving();
                            }
                        }}
                    />
                </Dialog>
            </React.Fragment>
        );
    }
);
