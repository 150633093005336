import { Box } from '@mui/material';
import React, { FC, memo } from 'react';
import { SubmissionForm } from '../../receiversModels';
import ReceiversDataGrid from './ReceiversDataGrid';
import ReceiversFilter from './ReceiversFilter';

interface ViewReceiversProps {
    form: SubmissionForm;
    handleContinueWithClickedRow: (newForm: SubmissionForm) => void;
}

const ViewRecievers: FC<ViewReceiversProps> = memo(({ form, handleContinueWithClickedRow }) => {
    return (
        <Box sx={{ backgroundColor: '#FFF', pt: 1, pb: 1 }}>
            <ReceiversFilter />
            <ReceiversDataGrid handleContinueWithClickedRow={handleContinueWithClickedRow} />
        </Box>
    );
}, arePropsEqual);

//implementing arePropsEqual may cause unintended results
function arePropsEqual(oldProps: ViewReceiversProps, newProps: ViewReceiversProps) {
    return oldProps.form.Project === newProps.form.Project;
}

export default ViewRecievers;
