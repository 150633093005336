const duplicateWarningString = 'There is another serial with this value in this item.';
const regexWarningString = 'This serial does not match the regex pattern:';

export const getSerialInputHelperText = (
    passedRegex: boolean,
    passedDuplicate: boolean,
    regexString: string | undefined
) => {
    if ((passedRegex || !!!regexString) && passedDuplicate) {
        return ' ';
    } else if (passedRegex && !passedDuplicate) {
        return duplicateWarningString;
    } else if (!passedRegex && passedDuplicate) {
        return `${regexWarningString}: ${regexString}.`;
    } else {
        return `${duplicateWarningString} ${regexWarningString}: ${regexString}.`;
    }
};
