import { GET_SKU_VELOCITY_ENDPOINT, GET_SKU_VELOCITY_LOCATIONS_ENDPOINT } from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import { GetSkuVelocityRequest, GetSkuVelocityResponse } from './models';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getSkuVelocity: builder.query<GetSkuVelocityResponse[], GetSkuVelocityRequest>({
            query: param => ({
                url: prependDreamUrl(GET_SKU_VELOCITY_ENDPOINT),
                method: 'GET',
                params: param,
            }),
        }),
        getLocations: builder.query<string[], void>({
            query: () => ({
                url: prependDreamUrl(GET_SKU_VELOCITY_LOCATIONS_ENDPOINT),
                method: 'GET',
            }),
        }),
    }),
});
