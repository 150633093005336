import {
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from '@mui/material/colors';

// export const SHIP_METHOD_COLORS = [
//     pink['A100'],
//     purple['A100'],
//     deepPurple['A100'],
//     indigo['A100'],
//     lightBlue['A100'],
//     teal['A100'],
//     lime['A100'],
//     amber['A100'],
//     deepOrange['A100'],
//     brown['A200'],
// ];

export const SHIP_METHOD_COLORS = [
    red['300'],
    amber['300'],
    lightBlue['300'],
    blue['300'],
    deepPurple['300'],
    brown['300'],
    teal['300'],
    purple['300'],
    deepOrange['300'],
    lightGreen['300'],
    lime['300'],
    yellow['300'],
    green['300'],
    orange['300'],
    pink['300'],
    indigo['300'],
];
