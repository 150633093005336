import { Box, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import qualityControlApi from '../qualityControlApi';
import { resetQCReducer } from '../qualityControlSlice';
import { createPartialSubmitBody, createSubmitBody } from '../util/submission';
import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SerialCheckPayload } from '../qualityControlModels';

interface StateChangeButtonsProps {
    setCurrBackgroundColor: (newColor: string) => void;
}

const StateChangeButtons = ({ setCurrBackgroundColor }: StateChangeButtonsProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const { orderData, serial1, serial2 } = useAppSelector(state => state.qualityControl);
    const QCLog = useAppSelector(state => state.qualityControl.QCLog);

    const [triggerPostFullSubmit, postFullSubmitResponse] = qualityControlApi.endpoints.postFullSubmit.useMutation();
    const [
        triggerPostPartialSubmit,
        postPartialSubmitResponse,
    ] = qualityControlApi.endpoints.postPartialSubmit.useMutation();

    const [
        triggerPutCompleteState,
        putCompleteStateResponse,
    ] = qualityControlApi.endpoints.putCompleteState.useMutation();
    const [triggerPutAbortState, putAbortStateResponse] = qualityControlApi.endpoints.putAbortState.useMutation();
    const [triggerPutPauseState, putPauseStateResponse] = qualityControlApi.endpoints.putPauseState.useMutation();

    const getSerialCheckResponse = qualityControlApi.endpoints.getSerialCheck.useQuery(
        serial1?.serialNumber ? serial1 : skipToken
    );
    const getSerialCheck2Response = qualityControlApi.endpoints.getSerialCheck.useQuery(
        serial2?.serialNumber ? serial2 : skipToken
    );

    const [
        triggerPutAbortThenResetState,
        putAbortThenResetStateResponse,
    ] = qualityControlApi.endpoints.putAbortThenResetState.useMutation();

    const [triggerPutResetState, putResetStateResponse] = qualityControlApi.endpoints.putResetState.useMutation();

    const reset = () => {
        setTimeout(() => {
            setCurrBackgroundColor('#fff');
            dispatch(resetQCReducer());
            dispatch(qualityControlApi.util.resetApiState());
        }, 1000);
    };

    useEffect(() => {
        if (!orderData) return;
        const isNoItemPending = !orderData.Items.filter(item => !item.NonProduct && !item.Breakout).find(
            item => item.Quants !== 0
        );
        if (isNoItemPending && !getSerialCheckResponse.isFetching && !getSerialCheck2Response.isFetching) {
            triggerPostFullSubmit(createSubmitBody(orderData, QCLog));
        }
    }, [QCLog]);

    useNotificationByResponse(
        {
            isFetching: postFullSubmitResponse.isLoading,
            isError: postFullSubmitResponse.isError,
            errorContent: 'Could not submit',
            isSuccess: postFullSubmitResponse.isSuccess,
            successContent: `Submitted ${orderData?.PackageId.PkgId}`,
            isUninitialized: postFullSubmitResponse.isUninitialized,
        },
        () => {
            reset();
            if (orderData) triggerPutCompleteState(orderData?.PackageId.PkgId);
        }
    );

    useNotificationByResponse(
        {
            isFetching: postPartialSubmitResponse.isLoading,
            isError: postPartialSubmitResponse.isError,
            errorContent: 'Could not pause',
            isSuccess: postPartialSubmitResponse.isSuccess,
            successContent: `Paused ${orderData?.PackageId.PkgId}`,
            isUninitialized: postPartialSubmitResponse.isUninitialized,
        },
        () => {
            reset();
            // if (orderData) triggerPutPauseState(orderData?.PackageId.PkgId);
        }
    );

    useNotificationByResponse(
        {
            isFetching: postFullSubmitResponse.isLoading,
            isError: postFullSubmitResponse.isError,
            errorContent: 'Could not submit',
            isSuccess: postFullSubmitResponse.isSuccess,
            successContent: `Submitted ${orderData?.PackageId.PkgId}`,
            isUninitialized: postFullSubmitResponse.isUninitialized,
        },
        () => reset()
    );

    useNotificationByResponse(
        {
            isFetching: putCompleteStateResponse.isLoading,
            isError: putCompleteStateResponse.isError,
            errorContent: 'Could not complete',
            isSuccess: putCompleteStateResponse.isSuccess,
            successContent: `Completed ${orderData?.PackageId.PkgId}`,
            isUninitialized: putCompleteStateResponse.isUninitialized,
        },
        () => reset()
    );

    useNotificationByResponse(
        {
            isFetching: putAbortThenResetStateResponse.isLoading,
            isError: putAbortThenResetStateResponse.isError,
            errorContent: 'Could not abort',
            isSuccess: putAbortThenResetStateResponse.isSuccess,
            successContent: `Abortted ${orderData?.PackageId.PkgId}`,
            isUninitialized: putAbortThenResetStateResponse.isUninitialized,
        },
        () => reset()
    );

    useNotificationByResponse(
        {
            isFetching: putResetStateResponse.isLoading,
            isError: putResetStateResponse.isError,
            errorContent: 'Could not reset',
            isSuccess: putResetStateResponse.isSuccess,
            successContent: `Resetted ${orderData?.PackageId.PkgId}`,
            isUninitialized: putResetStateResponse.isUninitialized,
        },
        () => reset()
    );

    const abortQC = () => {
        if (orderData) triggerPutAbortThenResetState(orderData?.PackageId.PkgId);
        setCurrBackgroundColor('#f0f4c3');
    };

    const pauseQC = () => {
        if (orderData) triggerPostPartialSubmit(createPartialSubmitBody(orderData, QCLog));
        setCurrBackgroundColor('#f0f4c3');
    };

    const resetQC = () => {
        if (orderData) triggerPutResetState(orderData?.PackageId.PkgId);
        setCurrBackgroundColor('#bbdefb');
    };

    const submitQC = () => {
        if (orderData) triggerPostFullSubmit(createSubmitBody(orderData, QCLog));
        setCurrBackgroundColor('#c8e6c9');
    };

    return (
        <Box>
            <Button onClick={abortQC} variant="contained" color="error" sx={{ margin: 5 }}>
                Abort QC
            </Button>

            <Button onClick={pauseQC} variant="contained" color="warning" sx={{ margin: 5 }}>
                Pause QC
            </Button>

            <Button onClick={resetQC} variant="contained" color="info" sx={{ margin: 5 }}>
                Reset QC
            </Button>
        </Box>
    );
};

export default StateChangeButtons;
