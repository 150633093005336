import { Navigate, Outlet, useLocation } from 'react-router';
import { useAppSelector } from '../../app/store';

export const PrivateRoutes = () => {
    const location = useLocation();

    const { username, authenticationResponse } = useAppSelector(state => state.authentication);
    const isUserAuthenticated = username && authenticationResponse;

    return isUserAuthenticated ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />;
};
