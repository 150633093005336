import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILURE } from '../store/actions/actionTypes';
import { getCompanies } from '../store/api/companiesApi';

export function* getCompaniesSaga(action) {
    try {
        const { data } = yield call(getCompanies, action);
        yield put({
            type: GET_COMPANIES_SUCCESS,
            listOfCompanies: data,
        });
    } catch (error) {
        yield put({
            type: GET_COMPANIES_FAILURE,
            errorMessage: error,
        });
    }
}

export default function* companiesSagaWatcher(action) {
    yield takeLatest(GET_COMPANIES, getCompaniesSaga);
}
