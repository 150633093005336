import { REASSIGN_TASK_USER } from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getReAssignTaskUserApi = async action => {
    const { taskDetail } = action;
    const res = await API.post(
        `${REASSIGN_TASK_USER}?taskId=${taskDetail.taskId}&assignedTouserId=${
            taskDetail.assignedToUserId
        }&updatedDateTimeOffSet=${new Date().toISOString()}`
    );
    return res;
};
