import { Box, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';
import { Item, Serial } from '../../orderLookupModels';
import { getSerialInputHelperText } from './SerialUtilities';

export function SerialsUpdateFormTextField({
    itemData,
    serialData,
    setFormSerials,
    setSerialFieldValidated,
}: {
    itemData: Item | undefined;
    serialData: Serial | undefined;
    setFormSerials: (value: Serial | undefined) => void;
    setSerialFieldValidated: (value: boolean) => void;
}) {
    const [serialValue, setSerialValue] = React.useState<Serial>();
    const [currentSerialsSet, setCurrentSerialsSet] = React.useState(new Set<string>());
    const [currentSerials2Set, setCurrentSerials2Set] = React.useState(new Set<string>());
    // Individual error types.
    const [serialRegexValidated, setSerialRegexValidated] = React.useState(true);
    const [serial2RegexValidated, setSerial2RegexValidated] = React.useState(true);
    const [serialDupsValidated, setSerialDupsValidated] = React.useState(true);
    const [serial2DupsValidated, setSerial2DupsValidated] = React.useState(true);
    const [hasSerialValues, setHasSerialValues] = React.useState(true);

    const globalSerialDupsErrorString = 'There are duplicate serials found in this item.';

    // Initialize serial values
    React.useEffect(() => {
        if (!!serialData) {
            setSerialValue(serialData);
        }
        itemData?.Serials.map((serial: Serial) => {
            if (!!serial.Serial && serial.Serial != serialData?.Serial) {
                setCurrentSerialsSet(prev => new Set(prev.add(serial.Serial)));
            }
            if (!!serial.Serial2 && serial.Serial2 != serialData?.Serial2) {
                setCurrentSerials2Set(prev => new Set(prev.add(serial.Serial2)));
            }
        });
    }, [itemData]);

    React.useEffect(() => {
        setSerialRegexValidated(passSerialRegex(serialValue?.Serial, itemData?.SerialRegex));
        setSerial2RegexValidated(
            !itemData?.HasSerials2Field || passSerialRegex(serialValue?.Serial2, itemData?.Serial2Regex)
        );
        setSerialDupsValidated(passSerialDuplicateCheck(serialValue?.Serial, currentSerialsSet));
        setSerial2DupsValidated(passSerialDuplicateCheck(serialValue?.Serial2, currentSerials2Set));
        setFormSerials(serialValue); // Sets the values in the parent form.
        setHasSerialValues(!!serialValue?.Serial || !!serialValue?.Serial2);
    }, [serialValue]);

    // Check for regex, dups, and empty fields for submit validation
    React.useEffect(() => {
        setSerialFieldValidated(
            serialRegexValidated &&
                serial2RegexValidated &&
                serialDupsValidated &&
                serial2DupsValidated &&
                hasSerialValues
        );
    }, [serialRegexValidated, serialDupsValidated, serial2RegexValidated, serial2DupsValidated, hasSerialValues]);

    const handleOnChangeSerialValues = (serialFieldValue: string, serialFieldName: string) => {
        if (!!serialValue) {
            setSerialValue({
                ...serialValue,
                [serialFieldName]: serialFieldValue,
            });
        }
    };

    const passSerialRegex = (serial: string | undefined, regexPattern: string | undefined) => {
        if (regexPattern && !!serial) {
            let regex = new RegExp(regexPattern);
            return regex.test(serial);
        }
        return true;
    };

    const passSerialDuplicateCheck = (serial: string | undefined | null, serialsSet: Set<string>) => {
        if (itemData?.AllowSerialDups || !!!serialValue) {
            return true;
        }
        return !(!!serial && serialsSet.has(serial.trim()));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: 7, m: 1 }}>
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            sx={{ flex: 7, m: 1 }}
                            value={serialValue?.Serial}
                            label={`SERIAL`}
                            variant="outlined"
                            size="small"
                            helperText={getSerialInputHelperText(
                                serialRegexValidated,
                                serialDupsValidated,
                                itemData?.SerialRegex
                            )}
                            fullWidth
                            error={!serialRegexValidated || !serialDupsValidated}
                            onChange={event => {
                                handleOnChangeSerialValues(event.target.value, 'Serial');
                            }}
                        />
                        {itemData?.HasSerials2Field ? (
                            <TextField
                                sx={{ flex: 7, m: 1 }}
                                value={serialValue?.Serial2}
                                label={`SERIAL2`}
                                variant="outlined"
                                size="small"
                                helperText={getSerialInputHelperText(
                                    serial2RegexValidated,
                                    serial2DupsValidated,
                                    itemData.Serial2Regex
                                )}
                                fullWidth
                                error={!serial2RegexValidated || !serial2DupsValidated}
                                onChange={event => {
                                    handleOnChangeSerialValues(event.target.value, 'Serial2');
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ flex: 1, m: 1 }}>
                {serialDupsValidated && serial2DupsValidated ? (
                    ''
                ) : (
                    <Alert severity="error">{globalSerialDupsErrorString}</Alert>
                )}
            </Box>
        </Box>
    );
}
