import { Box, Button } from '@mui/material';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import workOrderApi from '../../workOrderApi';
import EndOfMonthDataGrid from './EndOfMonthDataGrid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

const EndOfMonthReport = () => {
    const [
        triggerGetEndOfMonthReport,
        getEndOfMonthReportResponse,
    ] = workOrderApi.endpoints.getEndOfMonthReport.useLazyQuery();
    const getServicesResponse = workOrderApi.endpoints.getServices.useQuery();

    useNotificationByResponse({
        isFetching: getEndOfMonthReportResponse.isFetching,
        isError: getEndOfMonthReportResponse.isError,
        errorContent: 'Error getting EOM report',
        isUninitialized: getEndOfMonthReportResponse.isUninitialized,
    });

    const [date, setDate] = React.useState<dayjs.Dayjs>(dayjs());

    React.useEffect(() => {
        triggerGetEndOfMonthReport({ Date: date.toISOString() });
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        triggerGetEndOfMonthReport({ Date: date.toISOString() });
    };

    return (
        <Box sx={{ backgroundColor: '#fff' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', p: 3 }}>
                        <DatePicker
                            views={['month', 'year']}
                            value={date}
                            onChange={newValue => {
                                setDate(newValue || dayjs());
                            }}
                            sx={{ flex: 1, mx: 3 }}
                        />
                        <Button sx={{ flex: 1, mx: 3 }} variant="contained" type="submit">
                            Submit
                        </Button>
                    </Box>
                </form>
            </LocalizationProvider>
            <EndOfMonthDataGrid
                data={getEndOfMonthReportResponse.data || []}
                services={getServicesResponse.data || []}
            />
        </Box>
    );
};

export default EndOfMonthReport;
