import { Box, Button, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/store';
import useNotificationByMutationResponseV2 from '../../../common/hooks/useNotificationByMutationResponseV2';
import useScanner from '../../qualityControl/hooks/useScanner';
import { parseInitialQCScan } from '../../qualityControl/util/handleInitialScan';
import rapidQcApi from '../rapidQcApi';
import { setLotNumberCaptured } from '../rapidQcSlice';
import textFieldFocus from '../utils/textFieldFocus';
import { InvalidLotNumberDialog } from './InvalidLotNumberDialog';

export function LotNumberDisplay() {
    const dispatch = useDispatch();

    const [inputLotNumber, setInputLotNumber] = React.useState('');
    const [parsedLotNumber, setParsedLotNumber] = React.useState('');
    const [invalidLotNumberDialogOpen, setInvalidLotNumberDialogOpen] = React.useState(false);
    const lotNumberTextfieldRef = useRef<HTMLInputElement>(null);
    const [displayLotNumber, setDisplayLotNumber] = React.useState('');

    const momCode = useAppSelector(state => state.rapidQc.momCode);
    const batchId = useAppSelector(state => state.rapidQc.batchId);
    const userId = useAppSelector(state => state.authentication.authenticationResponse?.userInfo.id);

    const [putLotNumber, putLotNumberResponse] = rapidQcApi.endpoints.putLotNumber.useMutation();

    useNotificationByMutationResponseV2({
        response: putLotNumberResponse,
    });

    const submitHandler = () => {
        if (putLotNumberResponse.isLoading) {
            return;
        }

        setParsedLotNumber('');
        if (!!inputLotNumber && !!momCode && !!batchId) {
            const scannedValues = parseInitialQCScan(momCode, inputLotNumber, false);
            setDisplayLotNumber(scannedValues.serial || inputLotNumber);
            if (!!scannedValues.serial) {
                setParsedLotNumber(scannedValues.serial);
                putLotNumber({
                    lotNumber: scannedValues.serial,
                    momCode: momCode,
                    batchId: batchId,
                    userId: userId,
                    message: 'Lot number captured done by SEAL Rapid QC',
                });
            } else {
                invalidLotNumberActions();
            }
        }
    };

    React.useEffect(() => {
        if (!putLotNumberResponse.isUninitialized && !putLotNumberResponse.isLoading) {
            if (putLotNumberResponse.data?.Success) {
                dispatch(setLotNumberCaptured(parsedLotNumber));
            } else {
                invalidLotNumberActions();
            }
        }
    }, [putLotNumberResponse.isLoading, putLotNumberResponse.data]);

    const invalidLotNumberActions = () => {
        setInputLotNumber('');
        setInvalidLotNumberDialogOpen(true);
        textFieldFocus({ textFieldRef: lotNumberTextfieldRef });
    };

    useScanner(inputLotNumber, submitHandler);

    return (
        <Box sx={{ display: 'flex', p: 3 }}>
            <InvalidLotNumberDialog
                lotNumber={displayLotNumber}
                handleOpenDialog={setInvalidLotNumberDialogOpen}
                showDialog={invalidLotNumberDialogOpen}
            />
            <TextField
                autoFocus
                ref={lotNumberTextfieldRef}
                sx={{ flex: 4 }}
                value={inputLotNumber}
                label="SCAN LOT NUMBER"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInputLotNumber(event.target.value);
                }}
            />
            <Button sx={{ flex: 1, ml: 1 }} variant="contained">
                CAPTURE
            </Button>
        </Box>
    );
}
