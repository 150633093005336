import { Box, Card, Paper, Tab, Tabs } from '@mui/material';
import React from 'react';
import { GetSerialRequest } from '../serialModels';
import CompanySkuForm from './common/CompanySkuForm';
import DifferenceTab from './difference/DifferenceTab';
import InboundTab from './inbound/InboundTab';
import LocationTab from './location/LocationTab';
import OnHandTab from './onhand/OnHandTab';
import OutboundTab from './outbound/OutboundTab';

const DEFAULT_CLIENT = 'FRS';
const DEFAULT_SKU = '710-0009-01';

const SerialElement = () => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const [formValues, setFormValues] = React.useState<GetSerialRequest>({
        Client: DEFAULT_CLIENT,
        Sku: DEFAULT_SKU,
    });

    return (
        <Box>
            <Card sx={{ p: 2 }}>
                <CompanySkuForm
                    initialValue={formValues}
                    onSubmit={value => {
                        setFormValues(value);
                    }}
                />
            </Card>
            <Paper sx={{ mt: 2 }}>
                <Tabs value={tabIndex} onChange={handleChange} variant="fullWidth">
                    <Tab label="Inbound" />
                    <Tab label="Outbound" />
                    <Tab label="On Hand" />
                    <Tab label="HVC Location" />
                    <Tab label="On Hand Vs HVC" />
                </Tabs>

                <Box sx={{ p: 2 }}>
                    {tabIndex === 0 && <InboundTab formValues={formValues} />}
                    {tabIndex === 1 && <OutboundTab formValues={formValues} />}
                    {tabIndex === 2 && <OnHandTab formValues={formValues} />}
                    {tabIndex === 3 && <LocationTab formValues={formValues} />}
                    {tabIndex === 4 && <DifferenceTab formValues={formValues} />}
                </Box>
            </Paper>
        </Box>
    );
};

export default SerialElement;
