import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { IMAGE_URL } from '../../../../../app/services/api_constants';
import noImage from '../../../../../common/assets/images/NoImage.png';
import useNotificationByMutationResponseV2 from '../../../../../common/hooks/useNotificationByMutationResponseV2';
import { CreatedInstructionRecord } from '../../../models';
import ProcedureApi from '../../../ProcedureApi';
import ConfirmFinishDialog from './ConfirmFinishDialog';

const InteractableMediaCard = ({
    instruction,
    shouldRenderBackButton,
    handleBackButtonClick,
    handleNextButtonClick,
}: {
    instruction: CreatedInstructionRecord;
    shouldRenderBackButton: boolean;
    handleBackButtonClick: () => void;
    handleNextButtonClick: (newInstructionId: number, currentInstructionId: number) => void;
}) => {
    const isFinishCard = !!instruction.ReceiverItems.length;
    const [triggerFinish, finishResponse] = ProcedureApi.endpoints.completeSession.useMutation();
    const [isConfirmFinishDialogOpen, setIsConfirmFinishDialogOpen] = React.useState(false);

    const { WorkOrderProcedureId } = useParams();
    const castedWorkOrderProcedureId = WorkOrderProcedureId ? Number(WorkOrderProcedureId) : null;

    const { procedureId } = useParams();
    const castedProcedureId = procedureId ? Number(procedureId) : null;

    const navigate = useNavigate();
    const handleFinish = () => {
        if (castedWorkOrderProcedureId)
            triggerFinish({
                WorkOrderProcedureId: castedWorkOrderProcedureId,
                CurrentInstructionId: instruction.Id,
            });
        else if (castedProcedureId) {
            navigate(`/swrop/canvas/${castedProcedureId}`);
        }
    };

    useNotificationByMutationResponseV2({
        response: finishResponse,
    });

    return (
        <>
            <Card sx={{}} elevation={10}>
                <CardMedia
                    component="img"
                    sx={{ objectFit: 'contain', height: 450 }}
                    referrerPolicy="no-referrer"
                    src={instruction.Image ? `${IMAGE_URL}${instruction.Image}` : noImage}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {instruction.HeaderText}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {instruction.BodyText}
                    </Typography>
                </CardContent>
                <CardActions sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Button
                            size="small"
                            onClick={handleBackButtonClick}
                            color="error"
                            disabled={!shouldRenderBackButton}
                        >
                            Back
                        </Button>
                        {instruction.CurrentInstructions.map(innerInstruction => (
                            <Button
                                size="small"
                                onClick={() =>
                                    handleNextButtonClick(innerInstruction.NextInstructionId, instruction.Id)
                                }
                                variant="contained"
                                key={innerInstruction.Id}
                                sx={{ mx: 1 }}
                            >
                                {innerInstruction.Label}
                            </Button>
                        ))}
                    </Box>

                    {isFinishCard && (
                        <Button
                            color="success"
                            onClick={() => {
                                setIsConfirmFinishDialogOpen(true);
                            }}
                        >
                            Finish
                        </Button>
                    )}
                </CardActions>
            </Card>

            <ConfirmFinishDialog
                items={instruction.ReceiverItems}
                isDialogOpen={isConfirmFinishDialogOpen}
                onDialogClose={() => setIsConfirmFinishDialogOpen(false)}
                onSubmit={handleFinish}
            />
        </>
    );
};

export default InteractableMediaCard;
