import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { BATCH_ID_URL } from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';
import { GetBatchIdResponse, UpdateLotNumberResponse } from './rapidQcModels';

export default createApi({
    reducerPath: 'rapidQcApi',
    baseQuery: dreamBaseQueryWithReauth,
    endpoints: builder => ({
        getBatchId: builder.query<GetBatchIdResponse, { batchId: string; momCode: string }>({
            query: param => ({
                url: `${BATCH_ID_URL}/${param.batchId}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
        }),
        putLotNumber: builder.mutation<
            UpdateLotNumberResponse,
            Partial<{ batchId: string; lotNumber: string; momCode: string; userId: number; message: string }>
        >({
            query: param => ({
                url: `${BATCH_ID_URL}/${param.batchId}`,
                method: 'PUT',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
                body: {
                    LotNumber: param.lotNumber,
                    User: !!param.userId ? param.userId.toString() : '',
                    Message: param.message,
                },
            }),
        }),
    }),
});
