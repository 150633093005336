import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, IconButton, TextField, Typography } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, memo } from 'react';
import NumberTextfieldV2 from '../../../../../../common/components/TextField/NumberTextfieldV2';
import { SubmissionForm, SubmissionFormItem } from '../../../../receiversModels';

interface ItemCardProps {
    item: SubmissionFormItem;
    setForm: Dispatch<SetStateAction<SubmissionForm>>;
    itemIndex: number;
    isDisabled: boolean;
}

const ItemCard: FC<ItemCardProps> = memo(({ item, setForm, itemIndex, isDisabled }) => {
    const handleClose = () => {
        setForm(
            (prevForm: SubmissionForm): SubmissionForm => {
                if (!prevForm) return prevForm;
                const items = [...prevForm.Items];
                items.splice(itemIndex, 1);

                return { ...prevForm, Items: items };
            }
        );
    };

    const setCurrItem = (newItem: SubmissionFormItem) => {
        setForm(
            (prevForm: SubmissionForm): SubmissionForm => {
                const items = [...prevForm.Items];
                const copiedItem = { ...newItem };
                copiedItem.RecQty = copiedItem.RecCrtncount * copiedItem.RecCaseqty + copiedItem.RecPartial;
                items[itemIndex] = copiedItem;

                return { ...prevForm, Items: items };
            }
        );
    };

    return (
        <Card variant="outlined" sx={{ p: 1, m: 2 }} key={item.Item}>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="body1" sx={{ flex: 1 }}>
                    {item.Item} | {item.Descript}
                </Typography>
                <IconButton
                    sx={{ outline: 'none !important', marginLeft: 5, display: isDisabled ? 'none' : '' }}
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{ display: 'flex', mt: 2 }}>
                <TextField
                    sx={{ flex: 1 }}
                    value={item.LineId || 'N/A'}
                    disabled
                    label={'Line ID'}
                    variant="outlined"
                />

                <NumberTextfieldV2
                    value={item.RecCrtncount}
                    onChange={newValue => {
                        setCurrItem({ ...item, RecCrtncount: newValue });
                    }}
                    isNegativeAllowed={true}
                    shouldOnlyAllowValidNumberOnChange={true}
                    textFieldProps={{
                        label: 'Carton Units',
                        disabled: isDisabled,
                        sx: { flex: 1 },
                    }}
                />

                <NumberTextfieldV2
                    value={item.RecCaseqty}
                    onChange={newValue => {
                        setCurrItem({ ...item, RecCaseqty: newValue });
                    }}
                    isNegativeAllowed={true}
                    shouldOnlyAllowValidNumberOnChange={true}
                    textFieldProps={{
                        label: '# of Cartons',
                        disabled: isDisabled,
                        sx: { flex: 1 },
                    }}
                />

                <NumberTextfieldV2
                    value={item.RecPartial}
                    onChange={(newValue: number) => {
                        setCurrItem({ ...item, RecPartial: newValue });
                    }}
                    isNegativeAllowed={true}
                    shouldOnlyAllowValidNumberOnChange={true}
                    textFieldProps={{
                        label: 'Partial Units',
                        sx: { flex: 1 },
                        disabled: isDisabled,
                    }}
                />

                <TextField
                    sx={{ flex: 1 }}
                    value={item.RecQty}
                    disabled
                    label={'Received'}
                    variant="outlined"
                    type="number"
                />

                <TextField
                    sx={{ flex: 1 }}
                    value={item.PlQty}
                    disabled
                    label={'Expected'}
                    variant="outlined"
                    type="number"
                />

                <TextField
                    sx={{ flex: 1 }}
                    value={item.RecQty - item.PlQty}
                    disabled
                    label={'Difference'}
                    variant="outlined"
                    type="number"
                />
            </Box>

            <TextField
                sx={{ width: '100%', mt: 1 }}
                label={'Notes'}
                variant="outlined"
                value={item.Notes}
                disabled={isDisabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    setCurrItem({ ...item, Notes: newValue });
                }}
            />
        </Card>
    );
}, arePropsEqual);

function arePropsEqual(oldProps: ItemCardProps, newProps: ItemCardProps) {
    return oldProps.item === newProps.item;
}

export default ItemCard;
