import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'Spinner';

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        '& > * + *': {
            margin: theme.spacing(2),
        },
        height: '500px',
    },
}));

export const Spinner = props => {
    return (
        <Root className={classes.root} {...props} data-testid="spinner">
            <CircularProgress style={{ margin: 'auto' }} data-testid={'loading'} />
        </Root>
    );
};
