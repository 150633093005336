import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../app/store';
import DialogCloseButton from '../../../common/components/Dialog/DialogCloseButton';
import { LotNumberDisplay } from './LotNumberDisplay';

export const LotNumberDialog = React.memo(
    ({
        handleOpenDialog,
        showDialog = false,
    }: {
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const batchId = useAppSelector(state => state.rapidQc.batchId);
        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth={'md'} onClose={handleClose}>
                <DialogTitle color="white" bgcolor="primary.main" sx={{ px: 3 }}>
                    LOT NUMBER FOR: {batchId}
                </DialogTitle>
                <DialogContent>
                    <LotNumberDisplay />
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
