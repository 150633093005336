import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';
import React from 'react';
import trackingApi from '../trackingApi';
import { RootState, useAppDispatch, useAppSelector } from '../../../app/store';
import { Tracking } from '../trackingModels';
import CompanyDialog from './CompanyDialog';
import TrackingForm from './TrackingForm';
import { setDialog } from '../../notification/notificationSlice';

const TrackingElement = () => {
    const momCode = useAppSelector((state: RootState) => state.tracking.momCode);
    const response = trackingApi.endpoints.getCarriers.useQuery(momCode ? momCode : skipToken);
    const dispatch = useAppDispatch();

    const initialForm = {
        PackageId: undefined,
        TrackingNumber: undefined,
        Sscc: undefined,
        Scac: undefined,
        InternalCode: undefined,
        Carrier: undefined,
        Service: undefined,
        ShipDate: dayjs().toISOString(),
        Weight: undefined,
    } as Tracking;
    const [form, setForm] = React.useState<Tracking>(initialForm);

    React.useEffect(() => {
        if (response.status === 'rejected') {
            dispatch(setDialog({ content: `${JSON.stringify(response?.error)}` }));
        }
    }, [response.status]);

    return (
        <Box>
            {response?.status === 'fulfilled' ? (
                <TrackingForm form={form} setForm={setForm} initialForm={initialForm} />
            ) : (
                <CompanyDialog setForm={setForm} />
            )}
        </Box>
    );
};

export default TrackingElement;
