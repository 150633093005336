import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router';
import ProcedureApi from '../ProcedureApi';

const useGetCreatedProcedureRecord = () => {
    const { WorkOrderProcedureId } = useParams();
    const castedWorkOrderProcedureId = WorkOrderProcedureId ? Number(WorkOrderProcedureId) : null;
    const WorkOrderProcedureResponse = ProcedureApi.endpoints.getWorkOrderProcedure.useQuery(
        castedWorkOrderProcedureId ? castedWorkOrderProcedureId : skipToken
    );

    const { procedureId } = useParams();
    const castedProcedureId = procedureId ? Number(procedureId) : null;
    const procedureResponse = ProcedureApi.endpoints.getProcedure.useQuery(
        castedProcedureId ? castedProcedureId : skipToken
    );

    return WorkOrderProcedureResponse?.data ? WorkOrderProcedureResponse : procedureResponse;
};

export default useGetCreatedProcedureRecord;
