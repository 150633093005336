import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface LotNumberCapturedState {
    lotNumberCaptured: string | null | undefined;
    momCode: string | null | undefined;
    batchId: string | null | undefined;
}

const initialState: LotNumberCapturedState = {
    lotNumberCaptured: undefined,
    momCode: undefined,
    batchId: undefined,
};

const slice = createSlice({
    name: 'rapidQc',
    initialState,
    reducers: {
        setLotNumberCaptured: (state, action: PayloadAction<string | undefined | null>) => {
            state.lotNumberCaptured = action.payload;
        },
        setBatchIdMomCode: (state, action: PayloadAction<string | undefined | null>) => {
            state.momCode = action.payload;
        },
        setBatchId: (state, action: PayloadAction<string | undefined | null>) => {
            state.batchId = action.payload;
        },
    },
});

export const { setLotNumberCaptured, setBatchIdMomCode, setBatchId } = slice.actions;
export default slice.reducer;
