import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Tooltip } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';
import { HistoryMetadataDialog } from './HistoryMetadataDialog';
import { HistoryOrderDetailsDisplay } from './HistoryOrderDetailsDisplay';
import { HistoryOrderNotesDisplay } from './HistoryOrderNotesDisplay';

export function HistoryOrderDisplay() {
    const [tabValue, setTabValue] = React.useState('details');

    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getHistoryOrderResponse = orderLookupApi.endpoints.getHistoryOrder.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const historyOrderData = getHistoryOrderResponse.data?.Data[0];
    const [historyMetadatasDialogOpen, setHistoryMetadatasDialogOpen] = React.useState(false);

    const hasHistoryMetadata = !!historyOrderData?.Metadatas && historyOrderData?.Metadatas.length > 0;
    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        setTabValue(tabValue);
    };
    return (
        <React.Fragment>
            <HistoryMetadataDialog
                metadatas={historyOrderData?.Metadatas}
                dialogTitle={'History Metadata'}
                showDialog={historyMetadatasDialogOpen}
                handleOpenDialog={setHistoryMetadatasDialogOpen}
            />
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderColor: 'divider', mb: -1.5 }}>
                        <TabList onChange={handleTabChange} aria-label="history-order-tabs">
                            <Tab label="Details" value="details" />
                            <Tab label="Notes" value="notes" />
                        </TabList>
                    </Box>
                    <TabPanel value="details" sx={{ mx: -2 }}>
                        <HistoryOrderDetailsDisplay />
                    </TabPanel>
                    <TabPanel value="notes" sx={{ mx: -2 }}>
                        <HistoryOrderNotesDisplay />
                    </TabPanel>
                </TabContext>
            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{ mr: 1 }}>
                <Tooltip title={hasHistoryMetadata ? '' : 'No Metadata'} placement="top-start">
                    <span>
                        <Button
                            size="small"
                            disabled={!hasHistoryMetadata}
                            onClick={() => {
                                setHistoryMetadatasDialogOpen(true);
                            }}
                        >
                            HISTORY METADATA
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        </React.Fragment>
    );
}
