import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, SxProps, TextField, TextFieldVariants, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import authenticationApi from '../../../features/authentication/authenticationApi';
import { INACTIVE_COMPANY_VIEW } from '../../../features/roles/permissionsList';
import { isFeatureAccessible } from '../../utils/commonUtils';
import componentsApi from '../componentsApi';
import { Company } from '../componentsModel';

interface CompanyDropdownProps {
    setCompany: (name: string) => void;
    label?: string;
    readOnly?: boolean;
    error?: boolean;
    helperText?: string;
    startingMomCode?: string;
    sx?: SxProps<Theme>;
    testId?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    required?: boolean;
    variant?: TextFieldVariants;
}

export const CompanyDropdownV2 = ({
    setCompany,
    label = 'Company',
    readOnly = false,
    error = false,
    helperText = '',
    startingMomCode = '',
    sx = {},
    testId = 'company-dropdown-autocomplete',
    autoFocus = false,
    disabled = false,
    required = false,
    variant = 'outlined',
}: CompanyDropdownProps) => {
    const getUserPermissions = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions: string[] = getUserPermissions.data || [];

    const shouldGetOnlyActiveClients = !isFeatureAccessible(userPermissions, INACTIVE_COMPANY_VIEW);
    const response = componentsApi.endpoints.getCompanies.useQuery(shouldGetOnlyActiveClients);

    const [currCompany, setCurrCompany] = React.useState<Company | null | undefined>(null);

    const sortedCompanies = useMemo(() => {
        if (response.isSuccess) {
            const startingCompany = response?.data?.find(company => company.mom === startingMomCode);
            if (startingCompany) {
                setCurrCompany(startingCompany);
            }

            let arrayToSort = [...response.data];
            const result = arrayToSort.sort((a, b) => {
                if (a.mom < b.mom) {
                    return -1;
                }
                if (a.mom > b.mom) {
                    return 1;
                }
                return 0;
            });

            return result;
        } else {
            return [];
        }
    }, [response.isSuccess, startingMomCode]);

    return response.isError || response?.data?.length == 0 ? (
        <Autocomplete
            autoSelect
            freeSolo={response.isError || response?.data?.length == 0}
            disablePortal
            options={sortedCompanies?.map(option => option.name) || []}
            data-testid={`${testId}-${response.status}`}
            value={currCompany?.name || null}
            onChange={(event, newValue) => {
                setCompany(newValue || '');
                setCurrCompany(sortedCompanies?.find(option => option.name === newValue));
            }}
            // getOptionLabel={(option: User) => `${option.name} (${option.code})`}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    error={error}
                    helperText={helperText}
                    required={required}
                />
            )}
            readOnly={readOnly}
            popupIcon={readOnly ? '' : <ArrowDropDownIcon />}
            sx={{ ...sx }}
            disabled={disabled}
            autoFocus={autoFocus}
        />
    ) : (
        <Autocomplete
            disablePortal
            options={sortedCompanies || []}
            data-testid={`${testId}-${response.status}`}
            value={currCompany}
            onChange={(event, newValue) => {
                setCompany(newValue?.mom || '');
                setCurrCompany(newValue);
            }}
            getOptionLabel={(option: Company) => `${option.mom} - ${option.name}`}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    error={error}
                    helperText={helperText}
                    required={required}
                />
            )}
            readOnly={readOnly}
            popupIcon={readOnly ? '' : <ArrowDropDownIcon />}
            sx={{ ...sx }}
            disabled={disabled}
            autoFocus={autoFocus}
        />
    );
};
