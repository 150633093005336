import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/store';
import { CompanyDropdown } from '../../../common/components/dropdown/CompanyDropdown';
import { setDialog } from '../../notification/notificationSlice';
import trackingApi from '../trackingApi';
import { Tracking } from '../trackingModels';
import { setMomCode } from '../trackingSlice';

interface CompanyDialogProps {
    setForm: Dispatch<SetStateAction<Tracking>>;
}

const CompanyDialog = ({ setForm }: CompanyDialogProps): JSX.Element => {
    const navigate = useNavigate();
    const [momCodeState, setMomCodeState] = useState<string>('');

    const dispatch = useAppDispatch();
    const handleDialogClose = () => {
        navigate('/');
    };

    const [trigger] = trackingApi.endpoints.getCarriers.useLazyQuery();

    const handleSubmitClick = () => {
        if (momCodeState) {
            dispatch(setMomCode(momCodeState));
            trigger(momCodeState);
            setForm((prevForm: Tracking) => {
                {
                    return {
                        ...prevForm,
                        PackageId: momCodeState,
                    };
                }
            });
        } else {
            dispatch(setDialog({ content: 'Enter a company' }));
        }
    };

    return (
        <Dialog open={true} onClose={handleDialogClose} fullWidth>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ flex: 1 }}>
                        {'Company'}
                    </Typography>

                    <IconButton
                        sx={{ outline: 'none !important', marginLeft: 0 }}
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 1 }}>
                    <CompanyDropdown
                        autoFocus={true}
                        updateSelectedCompany={(newValue: any) => {
                            setMomCodeState(newValue?.mom);
                        }}
                        key="select-company"
                        testId="company-dropdown-autocomplete"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={handleSubmitClick}
                    // form="invoice-form-id"
                >
                    {' '}
                    Submit{' '}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompanyDialog;
