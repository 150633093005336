import { Box } from '@mui/material';
import { ParsedTwoDimensionalBarcode } from '../../../../common/utils/twoDimentialBarcodeParser';
import ReadOnlyTextfield from './ReadOnlyTextfield';

const ParsedBarcodeDisplay = ({ parsedBarcode }: { parsedBarcode: ParsedTwoDimensionalBarcode }) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 3 }}>
            <ReadOnlyTextfield label="Upc" value={parsedBarcode.Upc || ''} />
            <ReadOnlyTextfield label="Sku" value={parsedBarcode.Sku || ''} />
            <ReadOnlyTextfield label="Serial" value={parsedBarcode.Serial || ''} />
            <ReadOnlyTextfield label="Lot" value={parsedBarcode.Lot || ''} />
            <ReadOnlyTextfield label="Production Date" value={parsedBarcode.ProductionDate || ''} />
        </Box>
    );
};

export default ParsedBarcodeDisplay;
