// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import { CarrierResponse, DreamResponse, Tracking } from './trackingModels';
import { GET_CARRIERS_URL, POST_TRACKING_URL } from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';

// Define a service using a base URL and expected endpoints
export default createApi({
    reducerPath: 'trackingApi',
    baseQuery: dreamBaseQueryWithReauth,
    endpoints: builder => ({
        getCarriers: builder.query<CarrierResponse, string>({
            query: (momCode: string) => ({
                url: `${GET_CARRIERS_URL}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),
        postTracking: builder.mutation<DreamResponse, Partial<Tracking>>({
            query: body => ({
                url: `${POST_TRACKING_URL}`,
                method: 'POST',
                body,
            }),
        }),
    }),
});
