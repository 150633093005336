import {
    CLONE_PALLET_TAG_URL,
    GET_LOCATION_BY_WAREHOUSE_URL,
    GET_STOCK_PALLET_FILTERS_URL,
    GET_STOCK_PALLET_LOGS_URL,
    GET_WAREHOUSE_LIST_URL,
    QUARANTINE_PALLET_TAG_URL,
    RETIRE_PALLET_TAG_URL,
    SEARCH_PALLET_BY_FILTERS_URL,
    UPDATE_PALLET_LOCATION_URL,
    VALIDATE_PALLET_TAG_URL,
} from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const searchPalletByFilterApi = async action => {
    const res = await API.post(
        SEARCH_PALLET_BY_FILTERS_URL,
        {
            ...action.payload,
        },
        {
            params: {
                page: 1,
                pageSize: 10,
            },
        }
    );
    return res;
};

export const loadMoreSearchPalletByFilterApi = async action => {
    const res = await API.post(
        SEARCH_PALLET_BY_FILTERS_URL,
        {
            ...action.payload,
        },
        {
            params: {
                page: action.meta.currentPage,
            },
        }
    );
    return res;
};

export const getStockPalletFilterApi = async action => {
    const res = await API.get(GET_STOCK_PALLET_FILTERS_URL);
    return res;
};

export const getStockPalletLogsApi = async action => {
    const res = await API.post(GET_STOCK_PALLET_LOGS_URL, action.payload);
    return res;
};

export const retirePalletTagApi = async ({ payload }) => {
    const res = await API.post(RETIRE_PALLET_TAG_URL, payload);
    return res;
};

export const quarantinePalletTagApi = async ({ payload }) => {
    const res = await API.post(QUARANTINE_PALLET_TAG_URL, payload.data, {
        params: {
            quarantine: payload.quarantine,
        },
    });
    return res;
};

export const clonePalletTagApi = async ({ payload }) => {
    const res = await API.post(CLONE_PALLET_TAG_URL, payload);
    return res;
};

export const validatePalletTagApi = async ({ payload }) => {
    const res = await API.post(VALIDATE_PALLET_TAG_URL, payload);
    return res;
};

export const updatePalletLocationApi = async ({ payload }) => {
    const res = await API.post(UPDATE_PALLET_LOCATION_URL, payload);
    return res;
};

export const getWarehouseListApi = async action => {
    const res = await API.get(GET_WAREHOUSE_LIST_URL);
    return res;
};

export const getLocationListByWarehouseApi = async ({ payload }) => {
    const res = await API.post(GET_LOCATION_BY_WAREHOUSE_URL, {
        warehouse: payload,
        active: true,
    });
    return res;
};
