import { Box, Button, Card, CardContent, Chip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { ReceivedItem } from '../../rmaModels';
import { ItemCardMedia } from '../Media/ItemCardMedia';
import { ReceivedItemUnitDetailDialog } from '../ReceivedItems/ReceivedItemUnitDetailDialog';
import { ScannedSerialsDisplay } from '../Serials/ScannedSerialsDisplay';
import { ConfirmDeleteItemDialog } from './ConfirmDeleteItemDialog';
import { useAppSelector } from '../../../../app/store';
import { getDetailsButtonLabel, isRmaCompleted } from '../../utilities';

export const AddedItemCard = ({
    addedRmaItem,
    itemIndex,
    addedUnexpectedItems,
    setAddedUnexpectedItems,
}: {
    addedRmaItem: ReceivedItem;
    itemIndex: number;
    addedUnexpectedItems: ReceivedItem[];
    setAddedUnexpectedItems: (items: ReceivedItem[]) => void;
}) => {
    const rma = useAppSelector(state => state.rma.rma);
    const [recievedItemDetailDialog, setRecievedItemDetailDialog] = React.useState(false);
    const [editingAddedItem, setEditingAddedItem] = React.useState(addedRmaItem);
    const [showConfirmDeleteItemDialog, setShowConfirmDeleteItemDialog] = React.useState(false);

    const itemTitle = () => {
        if (!!!addedRmaItem.Sku) return 'UNKNOWN ITEM';

        return `${addedRmaItem.Description || 'NO DESCRIPTION'} (${addedRmaItem.Sku})`;
    };

    const upcTitle = `UPC: ${addedRmaItem.Upc}`;

    useEffect(() => {
        const updatedItems = addedUnexpectedItems.map((item, currentIndex) =>
            currentIndex === itemIndex ? { ...editingAddedItem } : item
        );
        setAddedUnexpectedItems(updatedItems);
    }, [editingAddedItem]);

    const card = (
        <React.Fragment>
            <ConfirmDeleteItemDialog
                addedUnexpectedItems={addedUnexpectedItems}
                index={itemIndex}
                setAddedUnexpectedItems={setAddedUnexpectedItems}
                handleOpenDialog={setShowConfirmDeleteItemDialog}
                showDialog={showConfirmDeleteItemDialog}
            />
            <CardContent>
                <Box sx={{ px: 2, pt: 1 }}>
                    <Typography color="primary.main" gutterBottom variant="h5">
                        {itemTitle()}
                    </Typography>
                    {addedRmaItem.NotFound || !!!addedRmaItem.Upc ? (
                        <React.Fragment />
                    ) : (
                        <Typography color="primary.main">{upcTitle}</Typography>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                        }}
                    >
                        <Box sx={{ flex: 1, px: 1, pb: 3, pt: 1 }}>
                            <ItemCardMedia sku={addedRmaItem.Sku} />
                        </Box>

                        <Box
                            sx={{
                                flex: 1.5,
                                px: 3,
                                pb: 3,
                                pt: 1.5,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {!isRmaCompleted(rma?.Status) && (
                                    <Box sx={{ mb: 1.5, ml: -0.5 }}>
                                        <Chip
                                            label={addedRmaItem.ValidToSubmit ? 'PROCESSED' : 'UNPROCESSED'}
                                            color={addedRmaItem.ValidToSubmit ? 'success' : 'warning'}
                                        />
                                    </Box>
                                )}
                                <Typography fontWeight={'fontWeightMedium'}>
                                    RECEIVED QTY: {addedRmaItem.Quantity}
                                </Typography>
                                <ScannedSerialsDisplay
                                    serials={addedRmaItem.Serials}
                                    receivedItem={addedRmaItem}
                                    isSerialized={addedRmaItem.IsSerialized}
                                    missingSerialCount={
                                        (addedRmaItem.IsSerialized && !!addedRmaItem.Quantity
                                            ? addedRmaItem.Quantity
                                            : 0) - addedRmaItem.Serials.length
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: isRmaCompleted(rma?.Status) ? 'flex-end' : 'space-between',
                        }}
                    >
                        {!isRmaCompleted(rma?.Status) && (
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    setShowConfirmDeleteItemDialog(true);
                                }}
                            >
                                DELETE ITEM
                            </Button>
                        )}

                        <Button
                            variant={addedRmaItem.ValidToSubmit ? 'outlined' : 'contained'}
                            onClick={() => {
                                setRecievedItemDetailDialog(true);
                            }}
                        >
                            {getDetailsButtonLabel(isRmaCompleted(rma?.Status), addedRmaItem.ValidToSubmit)}
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <ReceivedItemUnitDetailDialog
                handleOpenDialog={setRecievedItemDetailDialog}
                showDialog={recievedItemDetailDialog}
                receivedItem={editingAddedItem}
                setReceivedItem={setEditingAddedItem}
            />
            <Card>{card}</Card>
        </React.Fragment>
    );
};
