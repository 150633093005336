import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import useSound from 'use-sound';
import { useAppDispatch } from '../../app/store';
import errorSound from '../../common/assets/audio/error.mp3';
import { setDialog, setSnackbar } from '../../features/notification/notificationSlice';
import { isErrorWithMessage, isFetchBaseQueryError, parseErrorMessage } from '../utils/typedCommonUtils';

interface useNotificationByQueryMutationPayload {
    response: TypedUseQueryStateResult<any, unknown, any>;
    errorMessage?: string; //intrusively shows dialog with msg
    notFoundErrorMessage?: string;
    successMessage?: string; //nonintrusively shows success snackbar with msg
    shouldPlayErrorAudio?: boolean;
    shouldPlaySuccessAudio?: boolean;
    onFinish?: () => void; //called when done fetching
    onError?: () => void;
    onSuccess?: () => void;
}

export default function useQueryResponse({
    response,
    errorMessage = '',
    notFoundErrorMessage = '',
    successMessage = '',
    shouldPlayErrorAudio = true,
    onFinish = () => {},
    onError = () => {},
    onSuccess = () => {},
}: useNotificationByQueryMutationPayload): void {
    const dispatch = useAppDispatch();
    const [playErrorAudio] = useSound(errorSound);

    React.useEffect(() => {
        if (!response.isUninitialized && !response.isFetching) {
            displayNotification();
            callFinishedFunctions();
        }
    }, [response.isFetching]);

    const displayNotification = () => {
        if (errorMessage && response.isError) {
            if (shouldPlayErrorAudio) playErrorAudio();

            if (isFetchBaseQueryError(response.error)) {
                let parsedErrorMessage = parseErrorMessage(response?.error);

                if (notFoundErrorMessage && response.error.status === 404) {
                    parsedErrorMessage = notFoundErrorMessage;
                }

                dispatch(
                    setDialog({
                        content: `${errorMessage}:\n ${parsedErrorMessage} (${response.error.status})`,
                    })
                );
            } else if (isErrorWithMessage(response.error)) {
                dispatch(
                    setDialog({
                        content: `${errorMessage}:\n ${response.error.message}`,
                    })
                );
            }
        }

        if (response.isSuccess) {
            if (successMessage) {
                dispatch(
                    setSnackbar({
                        content: `${successMessage}`,
                        severity: 'success',
                    })
                );
            }
        }
    };

    const callFinishedFunctions = () => {
        onFinish();

        if (response.isError) {
            onError();
        }

        if (response.isSuccess) {
            onSuccess();
        }
    };
}
