import { Box, Button, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import { getUsdString } from '../../../../common/utils/commonUtils';
import orderLookupApi from '../../orderLookupApi';
import { HistoryItem, HistoryItemMetadata } from '../../orderLookupModels';
import { HistoryMetadataDialog } from './HistoryMetadataDialog';

interface GridHistoryItem extends HistoryItem {
    id: number; // DataGrid needs id attribute for each element.
}

export function HistoryItemsDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getHistoryOrderResponse = orderLookupApi.endpoints.getHistoryOrder.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const itemsData = getHistoryOrderResponse.data?.Data[0]?.Items;
    const gridItemsData: GridHistoryItem[] = [];

    itemsData?.map((item: HistoryItem, index: number) => {
        gridItemsData.push({ ...item, id: index + 1 });
    });

    const [selectedViewHistoryItemMetadata, setSelectedViewHistoryItemMetadata] = React.useState<
        HistoryItemMetadata[]
    >();
    const [itemMetadatasDialogOpen, setItemMetadatasDialogOpen] = React.useState(false);

    const setItemMetadataViewData = (selectedMetadatas: HistoryItemMetadata[]) => {
        if (!!selectedMetadatas) {
            setSelectedViewHistoryItemMetadata(selectedMetadatas);
            setItemMetadatasDialogOpen(true);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'ProductId',
            headerName: 'ProductId',
            flex: 1,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'Qty',
            headerName: 'Qty',
            type: 'number',
            flex: 1,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'Weight',
            headerName: 'Weight',
            type: 'number',
            flex: 1,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'Description',
            headerName: 'Description',
            flex: 1.5,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'Price',
            headerName: 'Price',
            renderCell: params => getUsdString(params.value),
            type: 'number',
            flex: 1,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'LineId',
            headerName: 'LineId 1',
            type: 'number',
            flex: 1,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'LineId1',
            headerName: 'LineId 2',
            flex: 2,
        },
        {
            field: 'Metadatas',
            headerName: 'Metadata',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: params => (
                <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                    <HistoryMetadataDialog
                        metadatas={selectedViewHistoryItemMetadata}
                        dialogTitle={'Item Metadata'}
                        showDialog={itemMetadatasDialogOpen}
                        handleOpenDialog={setItemMetadatasDialogOpen}
                    />
                    <Tooltip title={!!params.row.Metadatas ? '' : 'No Metadata'} placement="top-start">
                        <span>
                            {/* Added span to have Tooltip work for disabled Button */}
                            <Button
                                size="small"
                                sx={{ m: -1.5 }}
                                disabled={!!!params.row.Metadatas}
                                variant="text"
                                onClick={() => {
                                    setItemMetadataViewData(params.row.Metadatas);
                                }}
                            >
                                Show
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    if (!!itemsData) {
        return (
            <Box sx={{ m: 1 }}>
                <DataGrid
                    autoHeight
                    rows={gridItemsData || []}
                    columns={columns}
                    disableRowSelectionOnClick
                    scrollbarSize={20}
                    hideFooter
                    getRowHeight={() => 'auto'}
                />
            </Box>
        );
    } else {
        return (
            <Box>
                <Typography> NO ITEMS </Typography>
            </Box>
        );
    }
}
