import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Dialog, Tab } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { BatchOrderMonitor } from '../../batchOrderMonitorModels';
import { BATCH_TAB_VIEW, ORDER_TAB_VIEW } from '../../constants';
import { BatchDialogTitle } from './BatchDialogTitle';
import { BatchViewDataGrid } from './BatchViewDataGrid';
import { OrderViewDataGrid } from './OrderViewDataGrid';

export const BatchOrderDetailsDialog = React.memo(
    ({
        location,
        batchOrders,
        handleOpenDialog,
        showDialog = false,
    }: {
        location: string;
        batchOrders: BatchOrderMonitor[];
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const [tabValue, setTabValue] = React.useState(BATCH_TAB_VIEW);

        const [filterBatchIdModel, setFilterBatchIdModel] = useState<GridFilterModel>({
            items: [{ field: 'BatchId', operator: 'contains', value: '' }],
        });
        const handleBatchIdFilterChange = (value: string) => {
            setFilterBatchIdModel({
                items: [{ field: 'BatchId', operator: 'contains', value }],
            });
        };

        const handleTabChange = (event: React.SyntheticEvent, value: string) => {
            setTabValue(value);
            if (value != ORDER_TAB_VIEW) {
                // Clear filters when tabbing away
                handleBatchIdFilterChange('');
            }
        };

        const handleClose = () => {
            handleOpenDialog(false);
            handleBatchIdFilterChange('');
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth="lg" onClose={handleClose}>
                <Box sx={{ m: 3 }}>
                    <BatchDialogTitle location={location} />
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="batch-order-details">
                                <Tab label="Batch View" value={BATCH_TAB_VIEW} />
                                <Tab label="Order View" value={ORDER_TAB_VIEW} />
                            </TabList>
                        </Box>
                        <TabPanel value={BATCH_TAB_VIEW}>
                            <BatchViewDataGrid
                                batchOrders={batchOrders}
                                handleBatchIdFilterChange={handleBatchIdFilterChange}
                                handleTabChange={handleTabChange}
                            />
                        </TabPanel>
                        <TabPanel value={ORDER_TAB_VIEW}>
                            <OrderViewDataGrid
                                batchOrders={batchOrders}
                                filterBatchIdModel={filterBatchIdModel}
                                setFilterBatchIdModel={setFilterBatchIdModel}
                            />
                        </TabPanel>
                    </TabContext>
                    <DialogCloseButton handleClose={handleClose} />
                </Box>
            </Dialog>
        );
    }
);
