import { takeLatest, put, call, all, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/palletsApi';

export function* getAllPalletsSaga(action) {
    //FETCH ALL THE AVAILABLE PALLETS
    try {
        yield put({ type: actionTypes.SET_PALLET_REDUCER_LOADING });
        const palletResponse = yield call(api.getPalletCountsApi, action);
        yield put({
            type: actionTypes.GET_ALL_PALLETS_SUCCESS,
            payload: palletResponse.data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.GET_ALL_PALLETS_FAILURE,
            errorMessage: 'Something is not right. Please try again.',
        });
    }
}

export function* getPalletFiltersSaga(action) {
    // FETCH FILTERS FOR FILTERING THE PALLET
    try {
        yield put({ type: actionTypes.SET_PALLET_REDUCER_LOADING });
        const palletFiltersResponse = yield call(api.getPalletFiltersApi, action);
        yield put({
            type: actionTypes.GET_PALLET_FILTERS_SUCCESS,
            payload: palletFiltersResponse.data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.GET_PALLET_FILTERS_FAILURE,
            errorMessage: 'Something is not right. Please try again.',
        });
    }
}

export function* getFilteredPalletSaga(action) {
    // FETCH PALLETS BASED ON THE FILTER APPLIED BY THE USER
    try {
        yield put({ type: actionTypes.SET_PALLET_REDUCER_LOADING });
        var palletResponse;
        palletResponse = yield call(api.getFilteredPalletApi, action);

        yield put({ type: actionTypes.GET_FILTERED_PALLETS_SUCCESS, payload: palletResponse.data });
    } catch (error) {
        yield put({ type: actionTypes.GET_FILTERED_PALLETS_FAILURE, errorMessage: error });
    }
}

export function* getPalletsByTaskIdSaga(action) {
    try {
        yield put({ type: actionTypes.SET_PALLET_REDUCER_LOADING });
        const palletResponse = yield call(api.getPalletsByTaskIdApi, action);
        yield put({
            type: actionTypes.PALLETS_RECEIVED,
            payload: palletResponse.data,
        });
        if (palletResponse && palletResponse.data.length === 0) {
            yield put({ type: actionTypes.GENERATE_COUNT_AND_REFETCH_PALLET, payload: action.payload });
        }
    } catch (error) {
        yield put({
            type: actionTypes.PALLETS_RECEIVED_FAILED,
            errorMessage: error,
        });
    }
}

export function* refetchPalletByTaskIdSaga(action) {
    try {
        let newAction = JSON.parse(JSON.stringify(action));
        newAction.payload.countTaskId = newAction.payload.countTaskId[0];

        yield put({ type: actionTypes.SET_PALLET_REDUCER_LOADING });
        yield call(api.generatePalletCountApi, newAction);

        const pallResponse = yield call(api.getPalletsByTaskIdApi, action);
        yield put({
            type: actionTypes.PALLETS_RECEIVED,
            payload: pallResponse.data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.PALLETS_RECEIVED_FAILED,
            errorMessage: error,
        });
    }
}

export function* submitPalletSaga(action) {
    try {
        yield call(api.updateCountTaskApi, action);
        yield put({
            type: actionTypes.COMPLETED_PALLET_REMOVED,
            payload: action.payload.id,
        });

        yield put({
            type: actionTypes.GET_PALLETS_BY_TASK_ID,
            payload: action.payload,
        });
    } catch (error) {
        yield put({
            type: actionTypes.PALLET_SUBMIT_FAILED,
            errorMessage: 'Something is not right. Please try again.',
        });
    }
}

export function* generatePalletCountSaga(action) {
    try {
        const res = yield call(api.generatePalletCountWithoutParamsApi, action);
        yield put({ type: actionTypes.GENERATE_PALLET_COUNT_SUCCESS, payload: res.data });
    } catch (error) {
        yield put({ type: actionTypes.GENERATE_PALLET_COUNT_FAILED });
    }
}

export default function* palletsSagaWatcher() {
    yield takeLatest(actionTypes.GET_PALLETS_BY_TASK_ID, getPalletsByTaskIdSaga);
    yield takeLatest(actionTypes.PALLET_SUBMIT, submitPalletSaga);
    yield takeLatest(actionTypes.GENERATE_PALLET_COUNT, generatePalletCountSaga);
    yield takeLatest(actionTypes.GENERATE_COUNT_AND_REFETCH_PALLET, refetchPalletByTaskIdSaga);
    yield takeLatest(actionTypes.GET_ALL_PALLETS, getAllPalletsSaga);
    yield takeLatest(actionTypes.GET_PALLET_FILTERS, getPalletFiltersSaga);
    yield takeLatest(actionTypes.GET_FILTERED_PALLETS, getFilteredPalletSaga);
}
