import { all } from 'redux-saga/effects';
import authenticationSagaWatcher from '../../features/authentication/authenticateSaga';
import taskSagaWatcher from './tasksSaga';
import palletsSagaWatcher from './palletsSaga';
import countResultSagaWatcher from './countResultSaga';
import companiesSagaWatcher from './companiesSaga';
import usersSagaWatcher from './usersSaga';
import skuSagaWatcher from './skuSaga';
import createTaskSagaWatcher from './createTaskSaga';
import companySkusByTaskStateSagaWatcher from './companySkusByTaskStateSaga';
import taskDetailsBasedOnSkusSagaWatcher from './taskDetailsBasedOnSkuSaga';
import rejectTaskDetailsWatcher from './rejectTaskDetailsSaga';
import approveTaskDetailsWatcher from './approveTaskDetailsSaga';
import reAssignTaskDetailsWatcher from './reAssignTaskDetailsSaga';
import reAssignTaskUserWatcher from './reAssignTaskUserSaga';
import stockSagaWatcher from './stocksSaga';
import roleSagaWatcher from './roleSaga';
import OrdersSaga from './orderSaga';
import DashboardSaga from './dashboardSaga';
import qualityControlSagaWatcher from './qualityControlSaga';
// import receiversSagaWatcher from '../../features/receivers/receiversSaga';

export default function* rootSaga() {
    yield all([
        authenticationSagaWatcher(),
        // receiversSagaWatcher(),
        taskSagaWatcher(),
        palletsSagaWatcher(),
        qualityControlSagaWatcher(),
        countResultSagaWatcher(),
        companiesSagaWatcher(),
        usersSagaWatcher(),
        skuSagaWatcher(),
        createTaskSagaWatcher(),
        companySkusByTaskStateSagaWatcher(),
        taskDetailsBasedOnSkusSagaWatcher(),
        rejectTaskDetailsWatcher(),
        approveTaskDetailsWatcher(),
        reAssignTaskDetailsWatcher(),
        reAssignTaskUserWatcher(),
        stockSagaWatcher(),
        roleSagaWatcher(),
        OrdersSaga(),
        DashboardSaga(),
    ]);
}
