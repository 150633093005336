import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DraggableInstruction } from '../../../models';
import SetVariableForm from './SetVariableForm';
import VariableDataGrid from './VariableDataGrid';

const InstructionSetVariableDialog = ({
    instruction,
    onDialogClose,
}: {
    instruction: DraggableInstruction;
    onDialogClose: () => void;
}) => {
    return (
        <Dialog onClose={onDialogClose} open={!!instruction} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>Set Variable</DialogTitle>
            <DialogContent>
                <SetVariableForm instructionId={instruction.Id} />
                <VariableDataGrid variables={instruction.Variables} />
            </DialogContent>
        </Dialog>
    );
};

export default InstructionSetVariableDialog;
