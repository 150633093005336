import { takeLatest, put, call, fork, all } from 'redux-saga/effects';
import { getAssignedRolesByUserAction } from '../store/actions/action';
import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    GET_USER_PERMISSION,
    GET_USER_PERMISSION_SUCCESS,
    GET_USER_PERMISSION_FAILURE,
    SET_USERS_LOADING,
    GET_ASSIGNED_ROLES_BY_USER_SUCCESS,
    GET_ASSIGNED_ROLES_BY_USER_FAILURE,
    GET_ASSIGNED_ROLES_BY_USER,
    EDIT_ROLES_BY_USER,
    EDIT_ROLES_BY_USER_SUCCESS,
    EDIT_ROLES_BY_USER_FAILURE,
} from '../store/actions/actionTypes';
import * as api from '../store/api/usersApi';

export function* getUsersSaga(action) {
    try {
        const { data } = yield call(api.getUserUrlApi, action);

        yield put({
            type: GET_USERS_SUCCESS,
            users: data,
        });
    } catch (error) {
        yield put({
            type: GET_USERS_FAILURE,
            errorMessage: error,
        });
    }
}

export function* getUsersPermissionSaga(action) {
    try {
        yield put({ type: SET_USERS_LOADING });
        const response = yield call(api.getUserPermissionApi, action);
        yield put({
            type: GET_USER_PERMISSION_SUCCESS,
            payload: response?.data,
        });
    } catch (error) {
        yield put({
            type: GET_USER_PERMISSION_FAILURE,
            errorMessage: error,
        });
    }
}

export function* getAssignedRolesByUserSaga(action) {
    try {
        yield put({ type: SET_USERS_LOADING });
        const response = yield call(api.getAssignedRolesByUserApi, action);
        yield put({
            type: GET_ASSIGNED_ROLES_BY_USER_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: GET_ASSIGNED_ROLES_BY_USER_FAILURE,
            errorMessage: error,
        });
    }
}

export function* editRolesByUserSaga(action) {
    try {
        yield put({ type: SET_USERS_LOADING });
        const response = yield call(api.editRolesByUserApi, action);
        yield put({
            type: EDIT_ROLES_BY_USER_SUCCESS,
            payload: { data: response.data, meta: action.meta },
        });
        yield put(getAssignedRolesByUserAction({ userId: action.payload?.userId }));
    } catch (error) {
        yield put({
            type: EDIT_ROLES_BY_USER_FAILURE,
            errorMessage: error,
        });
    }
}

function* usersSagaWatcher() {
    yield takeLatest(GET_USERS, getUsersSaga);
}

function* getUsersPermissionSagaWatcher() {
    yield takeLatest(GET_USER_PERMISSION, getUsersPermissionSaga);
}

function* getAssignedRolesByUserWatcher() {
    yield takeLatest(GET_ASSIGNED_ROLES_BY_USER, getAssignedRolesByUserSaga);
}

function* editRolesByUserWatcher() {
    yield takeLatest(EDIT_ROLES_BY_USER, editRolesByUserSaga);
}

export default function* usersSaga() {
    yield all([
        fork(usersSagaWatcher),
        fork(getUsersPermissionSagaWatcher),
        fork(getAssignedRolesByUserWatcher),
        fork(editRolesByUserWatcher),
    ]);
}
