import { Box } from '@mui/material';
import ProcedureDetailsCard from '../ProcedureDetailsCard';
import EditFab from './EditFab';
import Instruction from './Instruction/Instruction';

const ActiveSession = () => {
    return (
        <Box>
            <ProcedureDetailsCard />
            <Instruction />
            <EditFab />
        </Box>
    );
};

export default ActiveSession;
