import { GET_USER_PERMISSION_URL } from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependRoapiUrl } from '../../app/services/util';
import { AuthenticationResponse, LoginRequest } from './authenticationModels';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['UserTag'] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        makeLoginRequest: builder.mutation<AuthenticationResponse, LoginRequest>({
            query: request => ({
                url: prependRoapiUrl('api/authentication'),
                method: 'POST',
                body: {
                    username: request.username,
                    password: request.password,
                    type: request.type,
                },
            }),
            invalidatesTags: ['UserTag'],
        }),
        getUserPermissions: builder.query<string[], void>({
            query: () => ({
                url: prependRoapiUrl(GET_USER_PERMISSION_URL),
                method: 'GET',
                responseHandler: 'text',
            }),
            providesTags: ['UserTag'],
        }),
    }),
    overrideExisting: false,
});
