import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, QueryDefinition } from '@reduxjs/toolkit/query';
import React from 'react';
import { GetSerialLocationRequest, GetSerialRequest, LocationAssignmentRecord } from '../../serialModels';

const LocationForm = ({
    triggerGetSerialLocation,
    upperFormValues,
}: {
    triggerGetSerialLocation: LazyQueryTrigger<
        QueryDefinition<
            GetSerialLocationRequest,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
            never,
            LocationAssignmentRecord[],
            'api'
        >
    >;
    upperFormValues: GetSerialRequest;
}) => {
    const [locationName, setLocationName] = React.useState('');
    const [serialValue, setSerialValue] = React.useState('');
    const [isShelved, setIsShelved] = React.useState(true);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                triggerGetSerialLocation({
                    IsShelved: isShelved,
                    LocationName: locationName,
                    SerialValue: serialValue,
                    ...upperFormValues,
                });
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <TextField
                    variant="outlined"
                    label="Location Name"
                    value={locationName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        setLocationName(newValue);
                    }}
                    sx={{ flex: 3, m: 1 }}
                />

                <TextField
                    variant="outlined"
                    label="Serial Value"
                    value={serialValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        setSerialValue(newValue);
                    }}
                    sx={{ flex: 3, m: 1 }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            value={isShelved}
                            onChange={event => {
                                const newValue = event.target.checked;
                                setIsShelved(newValue);
                            }}
                        />
                    }
                    label="Shelved"
                    sx={{ px: 3 }}
                />
                <Button type="submit" sx={{ flex: 1, m: 2 }} variant="contained" color="secondary">
                    Filter
                </Button>
            </Box>
        </form>
    );
};

export default LocationForm;
