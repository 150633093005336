import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../common/components/Dialog/DialogCloseButton';

export const InvalidLotNumberDialog = React.memo(
    ({
        lotNumber,
        handleOpenDialog,
        showDialog = false,
    }: {
        lotNumber: string;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            handleOpenDialog(false);
        };
        return (
            <Dialog open={showDialog} fullWidth maxWidth={'sm'} onClose={handleClose} onKeyDown={handleClose}>
                <DialogTitle bgcolor="error.main" color="white" sx={{ px: 3 }}>
                    INVALID LOT #
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ py: 6, flex: 6 }}>
                            <Typography variant="h6">LOT # "{lotNumber}" IS INVALID</Typography>
                        </Box>
                        <Box sx={{ flex: 6 }}>
                            <Button sx={{ px: 7 }} variant="contained" size="large" onClick={handleClose}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogCloseButton handleClose={handleClose} />
            </Dialog>
        );
    }
);
