import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoleAction, getRolesAction, resetRoleSuccessStatusAction } from '../../archive/store/actions/action';
import { Spinner, SuccessSnackbar } from '../../common/components';
import { getComparator, isFeatureAccessible, stableSort } from '../../common/utils/commonUtils';
import authenticationApi from '../authentication/authenticationApi';
import AddNewRole from './components/AddNewRole';
import AddRole from './components/AddRole';
import ConfirmModal from './components/ConfirmModal';
import EnhancedTableHead from './components/EnhancedTableHead';
import EnhancedTableToolbar from './components/EnhancedTableToolbar';
import * as permissions from './permissionsList';

const PREFIX = 'Roles';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    roleModalWrapper: `${PREFIX}-roleModalWrapper`,
    iconBtnStyles: `${PREFIX}-iconBtnStyles`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.paper}`]: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.table}`]: {
        minWidth: 400,
    },

    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    [`& .${classes.roleModalWrapper}`]: {
        width: 'min(500px,80%)',
        padding: 20,
    },

    [`& .${classes.iconBtnStyles}`]: {
        outline: 'none',
        padding: '7px',
        '&:focus': {
            outline: 'none',
        },
    },
}));

// const AddRole = React.lazy(() => import('../../components/Roles/AddRole'));

const Roles = () => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [isAddingRole, setIsAddingRole] = useState(false);
    const [isEdittingRoleName, setIsEdittingRoleName] = useState(false);
    const [isEdittingUserRole, setIsEdittingUserRole] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [roleName, setRoleName] = useState('');
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const {
        roles: {
            isLoading,
            rolesList,
            success,
            successMessage,
            isModalLoading,
            assignedPermissions,
            unAssignedPermissions,
            allPermissions,
            permissionSuccess,
            permissionSuccessMessage,
            isPermissionLoading,
        },
    } = useSelector(state => state);

    const getUserPermissions = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions = getUserPermissions.data ? JSON.parse(getUserPermissions.data) : [];

    useEffect(() => {
        dispatch(resetRoleSuccessStatusAction());
        dispatch(getRolesAction());
    }, [dispatch]);

    useEffect(() => {
        if (selectedRole) {
            setRoleName(selectedRole.name);
        } else {
            setRoleName('');
        }
    }, [selectedRole]);

    useEffect(() => {
        if (success) {
            setIsEdittingRoleName(false);
            if (successMessage !== 'Role has been assigned to the users successfully') {
                setIsEdittingUserRole(false);
            }
            setIsAddingRole(false);
            setConfirmDeleteModal(false);
        }
    }, [success, successMessage]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rolesList?.length - page * rowsPerPage);

    const handleEditRoleUsers = row => {
        dispatch(resetRoleSuccessStatusAction());
        setSelectedRole(row);
        setIsEdittingUserRole(true);
    };

    const handleEditRolePermissions = row => {
        dispatch(resetRoleSuccessStatusAction());
        setSelectedRole(row);
        setIsEdittingRoleName(true);
    };

    const handleDeleteRole = row => {
        dispatch(resetRoleSuccessStatusAction());
        setSelectedRole(row);
        setConfirmDeleteModal(true);
        // dispatch(deleteRoleAction(row));
    };

    return (
        <StyledContainer style={{ paddingBottom: '3rem', maxWidth: 1400 }}>
            {success && <SuccessSnackbar show={success} message={successMessage} />}
            {isEdittingUserRole ? (
                <Suspense fallback={<Spinner />}>
                    <AddRole
                        selectedRole={selectedRole}
                        setIsEdittingUserRole={setIsEdittingUserRole}
                        setSelectedRole={setSelectedRole}
                    />
                </Suspense>
            ) : (
                <Fragment>
                    <Typography variant="h5" color="initial" style={{ fontWeight: '500', margin: '1rem 0' }}>
                        Roles
                    </Typography>
                    <div className={classes.root} data-testid="role-table-element">
                        <Paper className={classes.paper}>
                            <EnhancedTableToolbar
                                setIsAddingRole={setIsAddingRole}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                setSelectedRole={setSelectedRole}
                                userPermissions={userPermissions}
                            />
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={0}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rolesList?.length}
                                    />
                                    <TableBody>
                                        {isLoading ? (
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: 300,
                                                            width: 'calc(100vw - 60px)',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <Spinner />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rolesList?.[0] &&
                                            stableSort(rolesList, getComparator(order, orderBy))
                                                .reduce((acc, row, index) => {
                                                    if (row.name.toLowerCase().includes(searchText?.toLowerCase())) {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        acc.push(
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={row.name}
                                                                color="primary"
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    style={{
                                                                        paddingTop: '12px',
                                                                        paddingBottom: '12px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="initial"
                                                                        style={{ paddingLeft: 15, fontWeight: 500 }}
                                                                    >
                                                                        {index + 1}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    style={{
                                                                        paddingTop: '5px',
                                                                        paddingBottom: '5px',
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    {row.name}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    style={{ paddingTop: '5px', paddingBottom: '5px' }}
                                                                >
                                                                    <div>
                                                                        {isFeatureAccessible(
                                                                            userPermissions,
                                                                            permissions.ROLE_EDIT
                                                                        ) && (
                                                                            <IconButton
                                                                                data-testid={`users-btn-${index}`}
                                                                                className={classes.iconBtnStyles}
                                                                                onClick={() => handleEditRoleUsers(row)}
                                                                                size="large"
                                                                            >
                                                                                <PeopleAltIcon
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        color: '#707070',
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        )}
                                                                        {isFeatureAccessible(
                                                                            userPermissions,
                                                                            permissions.ROLE_PERMISSION_EDIT
                                                                        ) && (
                                                                            <IconButton
                                                                                className={classes.iconBtnStyles}
                                                                                data-testid={`edit-role-btn-${index}`}
                                                                                style={{ margin: '0 8px' }}
                                                                                onClick={() =>
                                                                                    handleEditRolePermissions(row)
                                                                                }
                                                                                size="large"
                                                                            >
                                                                                <EditIcon
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        color: '#707070',
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        )}
                                                                        {isFeatureAccessible(
                                                                            userPermissions,
                                                                            permissions.ROLE_DELETE
                                                                        ) && (
                                                                            <IconButton
                                                                                className={classes.iconBtnStyles}
                                                                                data-testid={`delete-role-btn-${index}`}
                                                                                onClick={() => handleDeleteRole(row)}
                                                                                size="large"
                                                                            >
                                                                                <DeleteIcon
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        color: '#707070',
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        )}
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                        return acc;
                                                    }
                                                    return acc;
                                                }, [])
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        )}
                                        {emptyRows > 0 && !isLoading && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={3} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20, 30, 50]}
                                component="div"
                                count={rolesList?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </Fragment>
            )}

            {(isAddingRole || isEdittingRoleName) && (
                <AddNewRole
                    isAddingRole={isAddingRole}
                    setIsAddingRole={setIsAddingRole}
                    isEdittingRoleName={isEdittingRoleName}
                    setIsEdittingRoleName={setIsEdittingRoleName}
                    roleName={roleName}
                    setRoleName={setRoleName}
                    isModalLoading={isModalLoading}
                    selectedRole={selectedRole}
                    assignedPermissions={assignedPermissions}
                    unAssignedPermissions={unAssignedPermissions}
                    allPermissions={allPermissions}
                    permissionSuccess={permissionSuccess}
                    permissionSuccessMessage={permissionSuccessMessage}
                    isPermissionLoading={isPermissionLoading}
                    userPermissions={userPermissions}
                />
            )}

            <ConfirmModal
                modal={confirmDeleteModal}
                setModal={setConfirmDeleteModal}
                title="Confirm Delete Role"
                description={`Are you sure you want to delete role "${selectedRole && selectedRole.name}" `}
                handleConfirm={() => {
                    dispatch(deleteRoleAction(selectedRole));
                }}
            />
        </StyledContainer>
    );
};

export default Roles;
