// Starts with "[)>"
export const IS_TWO_DIMENSIONAL_BARCODE_REGEX = /^\[\)>/;

//Looks for 10, 12, 15, 16, 20, 22, 24, 34 digits long substring.
export const FEDEX_TRACKING_PARSING_REGEX = /\b\d{10}(?:\d{2})?(?:\d{3})?(?:\d{1})?(?:\d{5})?(?:\d{2})?(?:\d{2})?(?:\d{10})?\b/;

//Looks for this pattern: digits {chars} digits
export const IS_USPS_OR_DHL_PARSING_REGEX = /\d{3,}.*?\{[^\}]*\}.*?(\d{3,})/;

//Grabs the last continuous digits of a string (for USPS and DHL)
export const LAST_DIGITS_REGEX = /\d+$/;
