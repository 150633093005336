import React from 'react';
import componentsApi from '../../../../common/components/componentsApi';
import CompaniesTransferListUI from './CompaniesTransferListUI';
import { Button } from '@mui/material';
import { Spinner } from '../../../../common/components';
import shipMatrixApi from '../../shipMatrixApi';

interface CompaniesTransferListProps {
    selectedClients: string[];
    setSelectedClients: React.Dispatch<React.SetStateAction<string[]>>;
    companyFilter?: string;
    currGroupId: number;
}

export default function CompaniesTransferList({
    selectedClients,
    setSelectedClients,
    companyFilter,
    currGroupId,
}: CompaniesTransferListProps) {
    const companyResponse = shipMatrixApi.endpoints.getRateShoppingCompanies.useQuery();

    return (
        <>
            {companyResponse?.data ? (
                <CompaniesTransferListUI
                    currGroupId={currGroupId}
                    companyList={companyResponse.data?.Data}
                    selectedClients={selectedClients}
                    setSelectedClients={setSelectedClients}
                    companyFilter={companyFilter}
                    key={JSON.stringify(companyResponse.data)}
                />
            ) : (
                <Spinner />
            )}
        </>
    );
}
