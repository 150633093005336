import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useRef } from 'react';
import textFieldFocus from '../../../../rapidQc/utils/textFieldFocus';
import { MISSING_SERIAL_REASON_OTHER_ID } from '../../../constants';
import rmaApi from '../../../rmaApi';
import {
    ItemReceivedMissingSerialReason,
    ReceivedItem,
    ReceivedItemSerial,
    ReceivedItemUnitDetail,
} from '../../../rmaModels';
import { BulkScanSerialsDialog } from '../../Serials/BulkSerial/BulkScanSerialsDialog';
import { ScannedSerialsDisplay } from '../../Serials/ScannedSerialsDisplay';

export const ReceivedItemUnitDetailBulkFormSerial = React.memo(
    ({
        editingItem,
        setEditingItem,
        editingItemUnit,
        serials,
        setSerials,
        setReceivedItemUnit,
        showErrors = false,
    }: {
        editingItem: ReceivedItem;
        setEditingItem: (unit: ReceivedItem) => void;
        editingItemUnit: ReceivedItemUnitDetail;
        serials: ReceivedItemSerial[];
        setSerials: (serials: ReceivedItemSerial[]) => void;
        setReceivedItemUnit: (
            unit: ReceivedItemUnitDetail | ((prevUnit: ReceivedItemUnitDetail) => ReceivedItemUnitDetail)
        ) => void;
        showErrors?: boolean;
    }) => {
        const [showSerialScanDialog, setShowSerialScanDialog] = React.useState(false);

        const missingSerialReasonOptions: ItemReceivedMissingSerialReason[] =
            rmaApi.endpoints.getMissingSerialReasons.useQuery({}).data?.Data || [];

        const serialNotesTextfieldRef = useRef<HTMLInputElement>(null);

        const getExistingSerialCount = () => {
            return editingItem.ItemUnitDetails.filter(unit => unit.ValidToSubmit).length;
        };
        const serialMissingCount = editingItem.ItemUnitDetails.length - (serials.length + getExistingSerialCount());
        const disableBulkSerial = !editingItem.ItemUnitDetails.some(unit => !unit.ValidToSubmit);
        const showMissingSerialErrorMsg = showErrors && !!serialMissingCount && !!!editingItemUnit.MissingSerialReason;

        React.useEffect(() => {
            if (serialMissingCount <= 0) {
                setReceivedItemUnit({ ...editingItemUnit, MissingSerialReason: null, SerialNotes: null });
            }
        }, [serialMissingCount]);

        React.useEffect(() => {
            if (editingItemUnit.MissingSerialReason?.Id === MISSING_SERIAL_REASON_OTHER_ID) {
                textFieldFocus({ textFieldRef: serialNotesTextfieldRef });
            }
        }, [editingItemUnit.MissingSerialReason]);

        if (!editingItem.IsSerialized || disableBulkSerial) {
            return <React.Fragment />;
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3, mr: 2 }}>
                <Box sx={{ display: 'flex', flex: 3, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', mt: 2 }}>
                        <BulkScanSerialsDialog
                            receivedItem={editingItem}
                            setReceivedItem={setEditingItem}
                            scannedSerials={serials}
                            setScannedSerials={setSerials}
                            handleOpenDialog={setShowSerialScanDialog}
                            showDialog={showSerialScanDialog}
                        />
                        <Box sx={{ flex: 1.5, mr: 1 }}>
                            <ScannedSerialsDisplay
                                serials={serials}
                                receivedItem={editingItem}
                                isSerialized={editingItem?.IsSerialized}
                                missingSerialCount={editingItem.IsSerialized ? serialMissingCount : 0}
                            />
                        </Box>
                        <Box sx={{ flex: 2, mb: -3, display: 'flex', flexDirection: 'column' }}>
                            <Button
                                disabled={!editingItem.IsSerialized}
                                onClick={() => {
                                    setShowSerialScanDialog(true);
                                }}
                                variant="contained"
                                size="large"
                            >
                                {serials?.length > 0 ? 'EDIT' : 'SCAN'} SERIAL
                            </Button>
                            {showMissingSerialErrorMsg ? (
                                <FormHelperText sx={{ color: 'error.main', flex: 0.5 }}>
                                    Scan serial or choose a reason for missing serials
                                </FormHelperText>
                            ) : (
                                <React.Fragment />
                            )}
                        </Box>
                    </Box>
                    {serialMissingCount > 0 ? (
                        <FormControl variant="standard" sx={{ flex: 1 }}>
                            <InputLabel id="missing-serial-label">
                                SELECT REASON FOR MISSING {serialMissingCount} SERIAL{serialMissingCount > 1 ? 'S' : ''}
                            </InputLabel>
                            <Select
                                error={showErrors && editingItemUnit.MissingSerialReason === null}
                                labelId="serial-label"
                                id="serial"
                                value={editingItemUnit.MissingSerialReason?.Id}
                                onChange={event => {
                                    setReceivedItemUnit({
                                        ...editingItemUnit,
                                        MissingSerialReason: missingSerialReasonOptions.find(
                                            option => option.Id === event.target.value
                                        ),
                                        SerialNotes:
                                            event.target.value === MISSING_SERIAL_REASON_OTHER_ID
                                                ? editingItemUnit.SerialNotes
                                                : null,
                                    });
                                }}
                                label={`SELECT REASON FOR MISSING ${serialMissingCount} SERIAL${
                                    serialMissingCount > 1 ? 'S' : ''
                                }`}
                            >
                                {missingSerialReasonOptions.map(option => (
                                    <MenuItem value={option.Id}>{option.Reason}</MenuItem>
                                ))}
                            </Select>
                            {showErrors && editingItemUnit.MissingSerialReason === null ? (
                                <FormHelperText sx={{ color: 'error.main' }}>Select a reason</FormHelperText>
                            ) : (
                                <React.Fragment />
                            )}
                        </FormControl>
                    ) : (
                        <React.Fragment />
                    )}

                    {editingItemUnit.MissingSerialReason?.Id === MISSING_SERIAL_REASON_OTHER_ID ? (
                        <TextField
                            sx={{ flex: 1, mt: 1 }}
                            fullWidth
                            value={editingItemUnit.SerialNotes}
                            id="serialNotes"
                            label="ENTER REASON"
                            inputRef={serialNotesTextfieldRef}
                            error={showErrors && !!!editingItemUnit.SerialNotes}
                            variant="standard"
                            onChange={event => {
                                setReceivedItemUnit({
                                    ...editingItemUnit,
                                    SerialNotes: event.target.value,
                                });
                            }}
                        />
                    ) : (
                        <React.Fragment />
                    )}
                </Box>
            </Box>
        );
    }
);
