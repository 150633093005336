import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { Item, ReceivedItem } from '../../rmaModels';

export const ChooseSkuDialog = React.memo(
    ({
        stocks,
        setAddingItem,
        setQuery,
        handleOpenDialog,
        showDialog = false,
    }: {
        stocks: Item[];
        setAddingItem: (addingItem: ReceivedItem | ((prevItem: ReceivedItem) => ReceivedItem)) => void;
        setQuery: (query: string) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const selectSku = (item: Item) => {
            handleOpenDialog(false);
            setAddingItem((prevItem: ReceivedItem) => ({
                ...prevItem,
                Upc: item.Upc,
                Sku: item.Sku,
                IsSerialized: item.IsSerialized,
                SerialVerify: item.SerialVerify,
            }));
            if (!!item.Sku) {
                setQuery(item.Sku);
            }
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm">
                <DialogTitle align="center">
                    <Typography color="primary.main" fontWeight={'fontWeightMedium'} variant="h5">
                        MULTIPLE SKUS FOUND
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        {stocks.map(item => (
                            <Button
                                sx={{ mb: 1 }}
                                variant="contained"
                                onClick={() => {
                                    selectSku(item);
                                }}
                                size="large"
                            >
                                {item.Sku}
                            </Button>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
);
