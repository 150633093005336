export const RECEIVER_PROJECTS_OPTIONS = [
    'Inbound Delivery - PO',
    'Inbound Delivery - no PO',
    'Inventory Adjustment',
    'Configuration',
    'Correction',
    'Kit Assembly',
    'Temp Receiver',
    'Return to MFG',
    'Scrap / Destruction',
    'Adjustment to PL / CC',
    'Stock Re-Id',
    'Stock Transfer',
    'B Stock Activities',
    'Other',
];

export const RECEIVER_PROJECTS_OPTIONS_WITH_REQUIRED_LADING = ['Inbound Delivery - PO'];

export const RECEIVER_PROJECTS_OPTIONS_WITH_PO_NUMBER = ['Inbound Delivery - PO', 'Correction'];

export const LOCATION_OPTIONS = [
    'ENGLE-A',
    'ENGLE-D',
    'ENGLE-E',
    'ENGLE-H',
    'LEAVESLEY',
    'SILACCI',
    'ALEXANDER',
    'NEW YORK',
    'CANADA',
    'UNITED KINGDOM',
    'NETHERLANDS',
    'HONG KONG',
    'AUSTRALIA',
    'MEXICO',
    'ca', //test cases had 'ca', but production shouldn't?
];

export const UNIT_TYPES_OPTIONS = [
    'pallets',
    'cases',
    '20 ft. floor container',
    '40 ft. floor container',
    'N/A - Stock Adjustment',
];

export const UNIT_TYPES_THAT_REQUIRE_DELIVERY_APPOINTMENT = ['20 ft. floor container', '40 ft. floor container'];
