export const RECEIVER_PROJECTS_OPTIONS = [
    'Inbound Delivery - PO',
    'Inbound Delivery - no PO',
    'Inventory Adjustment',
    'Configuration',
    'Correction',
    'Kit Assembly',
    'Temp Receiver',
    'Return to MFG',
    'Scrap / Destruction',
    'Adjustment to PL / CC',
    'Stock Re-Id',
    'Stock Transfer',
    'B Stock Activities',
    'Other',
];

export const RECEIVER_PROJECTS_OPTIONS_THAT_ALLOW_TYPING = ['Inventory Adjustment', 'Correction'];

export const RECEIVER_PROJECTS_OPTIONS_WITH_REQUIRED_LADING = ['Inbound Delivery - PO'];

export const RECEIVER_PROJECTS_OPTIONS_WITH_PO_NUMBER = ['Inbound Delivery - PO'];

export const UNIT_TYPES_THAT_SHOW_ITEM_AUTOCOMPLETE = ['Correction', 'Adjustment to PL / CC'];

export const LOCATION_OPTIONS = [
    'ENGLE-A',
    'ENGLE-D',
    'ENGLE-E',
    'ENGLE-H',
    'LEAVESLEY',
    'SILACCI',
    'ALEXANDER',
    'OHIO',
    'NEW YORK',
    'CANADA',
    'UNITED KINGDOM',
    'NETHERLANDS',
    'HONG KONG',
    'AUSTRALIA',
    'MEXICO',
    'ca', //test cases had 'ca', but production shouldn't?
];

const UNIT_TYPE_PALLETS = 'pallets';
const UNIT_TYPE_CASES = 'cases';
const UNIT_TYPE_20_FT_FLOOR_CONTAINER = '20 ft. floor container';
const UNIT_TYPE_40_FT_FLOOR_CONTAINER = '40 ft. floor container';
const UNIT_TYPE_STOCK_ADJUSTMENT = 'N/A - Stock Adjustment';

export const UNIT_TYPES_OPTIONS = [
    UNIT_TYPE_PALLETS,
    UNIT_TYPE_CASES,
    UNIT_TYPE_20_FT_FLOOR_CONTAINER,
    UNIT_TYPE_40_FT_FLOOR_CONTAINER,
    UNIT_TYPE_STOCK_ADJUSTMENT,
];

export const UNIT_TYPES_THAT_REQUIRE_DELIVERY_APPOINTMENT = [
    UNIT_TYPE_20_FT_FLOOR_CONTAINER,
    UNIT_TYPE_40_FT_FLOOR_CONTAINER,
];

export const UNIT_TYPES_THAT_FORCE_GOOD_PALLET_CONDITION_TRUE = [
    UNIT_TYPE_CASES,
    UNIT_TYPE_20_FT_FLOOR_CONTAINER,
    UNIT_TYPE_40_FT_FLOOR_CONTAINER,
];

export const DISABLE_DELIVERY_APPOINTMENT_WHEN_PALLET_COUNT_EXCEEDS_THIS_COUNT = 5;
