import { Box, Collapse, List, ListItem, ListItemButton } from '@mui/material';
import React, { useState } from 'react';
import { QcLog } from '../../orderLookupModels';
import QcLogPanel from './QcLogPanel';
import dayjs from 'dayjs';
import { secondsInHour } from 'date-fns/constants/index.js';
import { secondsInMinute } from 'date-fns/constants/index';

export default function QcLogCollapseList({ qcLogSection }: { qcLogSection: QcLog[] | [] }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const elapsedTimeString = () => {
        const elapsedTimeSeconds = dayjs(qcLogSection.at(-1)?.DateTime).diff(dayjs(qcLogSection[0].DateTime), 'second');
        const hours = Math.floor(elapsedTimeSeconds / secondsInHour);

        const minutes = Math.floor((elapsedTimeSeconds % secondsInHour) / secondsInMinute);

        const hoursString = hours === 1 ? `${hours} hr` : `${hours} hrs`;
        const minutesString = minutes === 1 ? `${minutes} min` : `${minutes} mins`;
        const secondsString = `${elapsedTimeSeconds} sec`;

        if (elapsedTimeSeconds >= secondsInHour) {
            return `${hoursString}, ${minutesString}`;
        }

        if (elapsedTimeSeconds < 1) {
            return '1 sec';
        }

        if (elapsedTimeSeconds < secondsInMinute) {
            return secondsString;
        }

        return minutesString;
    };

    const sameDateFinished =
        qcLogSection.length === 1 ||
        dayjs(qcLogSection[0].DateTime).format('M/DD/YY') == dayjs(qcLogSection.at(-1)?.DateTime).format('M/DD/YY');

    return (
        <List sx={{ width: '100%' }}>
            {qcLogSection.length > 1 ? (
                <React.Fragment>
                    <ListItemButton onClick={handleClick}>
                        <Box sx={{ width: '100%' }}>
                            <QcLogPanel
                                qcLog={qcLogSection[0]}
                                sameDateFinished={sameDateFinished}
                                endingQcLog={qcLogSection.at(-1)}
                                isParent={true}
                                elapsedTime={elapsedTimeString()}
                                listOpened={open}
                            />
                        </Box>
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {qcLogSection.map((qcLog, index) => (
                            <ListItem key={index} sx={{ my: -1 }}>
                                <Box sx={{ mr: 10 }} />
                                <Box sx={{ width: '100%' }}>
                                    <QcLogPanel qcLog={qcLog} sameDateFinished={sameDateFinished} index={index} />
                                </Box>
                            </ListItem>
                        ))}
                    </Collapse>
                </React.Fragment>
            ) : (
                <ListItem>
                    <Box sx={{ width: '100%' }}>
                        <QcLogPanel
                            qcLog={qcLogSection[0]}
                            sameDateFinished={sameDateFinished}
                            endingQcLog={qcLogSection[0]}
                            isParent={true}
                            isOnePanelSection={true}
                        />
                    </Box>
                </ListItem>
            )}
        </List>
    );
}
