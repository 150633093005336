import axios from 'axios';
import store from '../../app/store';

import { DREAM_URL } from '../../app/services/api_constants';
import { getNewTokenAction } from '../../archive/store/actions/action';

const AxiosApiInstanceDream = axios.create({
    baseURL: DREAM_URL,
    timeout: 30000,
});

let reduxStore;

export const dreamInjectStore = _reduxStore => {
    reduxStore = _reduxStore;
};

AxiosApiInstanceDream.interceptors.request.use(request => {
    if (request.url.endsWith('nonce') || request.url.endsWith('authentication') || request.url.endsWith('refresh'))
        return request;

    const token = reduxStore.getState().authentication.authenticationResponse?.token;

    request.headers['Authorization'] = `Bearer ${token}`;
    request.headers['Content-Type'] = 'application/json';
    request.headers['Cache-Control'] = 'no-store, max-age=0';

    return request;
});

AxiosApiInstanceDream.interceptors.response.use(
    response => response,
    error => {
        //response is undefined if lack of access-control-allow-origin; which only in testing
        if (error?.response?.status === 401 || error.status === 401) {
            store.dispatch(getNewTokenAction());
        }
        return error;
    }
);

export default AxiosApiInstanceDream;
