import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const PREFIX = 'FeatureCard';

const classes = {
    cardLinks: `${PREFIX}-cardLinks`,
    card: `${PREFIX}-card`,
    cardCaption: `${PREFIX}-cardCaption`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.cardLinks}`]: {
        '&:hover': {
            textDecoration: 'none',
        },
    },

    [`& .${classes.card}`]: {
        padding: '30px 15px',
        display: 'flex',
        height: '225px',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0 0 6px rgba(0,0,0,0.1)',
    },

    [`& .${classes.cardCaption}`]: { textDecoration: 'none', color: '#25262a', fontWeight: 600 },
}));

const FeatureCard = ({ image, title, description, testId, path }) => {
    return (
        <StyledGrid item xs={12} sm={6} md={4} lg={3} data-testid={testId}>
            <Link to={path} className={classes.cardLinks}>
                <Paper className={classes.card}>
                    <div style={{ padding: '15px', height: 100, width: 100 }}>
                        <img src={image} alt="svg-warehouse-icon" style={{ height: '100%', width: '100%' }} />
                    </div>
                    <Typography variant="subtitle1" color="initial" className={classes.cardCaption} align={'center'}>
                        {title}
                    </Typography>
                    <Typography variant="body2" style={{ textDecoration: 'none', color: '#616060' }}>
                        {description}
                    </Typography>
                </Paper>
            </Link>
        </StyledGrid>
    );
};

export default FeatureCard;
