import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../app/store';
import { setSnackbar } from '../../../../notification/notificationSlice';
import { ReceivedItem } from '../../../rmaModels';
import { setRma as setRmaSlice } from '../../../rmaSlice';
import { AddedItemCard } from '../../AddedItems/AddedItemCard';
import { AddItemDialog } from '../../AddedItems/AddItemDialog';
import { RecievedItemsSummaryDialog } from '../../RecievedItemsSummary/RecievedItemsSummaryDialog';
import { CreateRmaInfoForm } from './CreateRmaInfoForm';
import { OriginalOrderDisplay } from '../../OriginalOrderDisplay';

export const CreateRmaDisplay = () => {
    const dispatch = useDispatch();
    const rma = useAppSelector(state => state.rma.rma);
    const [showRecievedItemsSummaryDialog, setShowRecievedItemsSummaryDialog] = React.useState(false);
    const [showAddItemDialog, setShowAddItemDialog] = React.useState(false);
    const [addedUnexpectedItems, setAddedUnexpectedItems] = React.useState<ReceivedItem[]>([]);
    const submitReviewHandler = () => {
        if (addedUnexpectedItems.filter(item => !item.ValidToSubmit).length > 0) {
            dispatch(
                setSnackbar({
                    content: 'Some items not are processed',
                    severity: 'error',
                })
            );
        } else {
            setShowRecievedItemsSummaryDialog(true);
        }
    };

    return (
        <React.Fragment>
            <RecievedItemsSummaryDialog
                showDialog={showRecievedItemsSummaryDialog}
                handleOpenDialog={setShowRecievedItemsSummaryDialog}
                pairedRmaItems={[]}
                unexpectedItems={addedUnexpectedItems}
            />
            <AddItemDialog
                showDialog={showAddItemDialog}
                handleOpenDialog={setShowAddItemDialog}
                pairedRmaItems={[]}
                setAddedUnexpectedItems={setAddedUnexpectedItems}
                addedUnexpectedItems={addedUnexpectedItems}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <CreateRmaInfoForm setAddedItems={setAddedUnexpectedItems} />
                {!!rma?.TrackingNumbers && rma?.TrackingNumbers.length > 0 && (
                    <OriginalOrderDisplay newRmaView={true} />
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2, pt: 1 }}>
                    {addedUnexpectedItems.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Typography fontWeight={'fontWeightMedium'} color="primary" variant="h5">
                                ITEMS
                            </Typography>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ flexdirection: 'column' }}>
                            {addedUnexpectedItems.map((item, index) => (
                                <Box sx={{ py: 0.5 }}>
                                    <AddedItemCard
                                        addedRmaItem={item}
                                        itemIndex={index}
                                        addedUnexpectedItems={addedUnexpectedItems}
                                        setAddedUnexpectedItems={setAddedUnexpectedItems}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Button
                        disabled={!rma?.Client}
                        sx={{ mt: 2 }}
                        size="large"
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            setShowAddItemDialog(true);
                            dispatch(setRmaSlice(rma));
                        }}
                    >
                        ADD ITEM
                    </Button>
                    <Button
                        disabled={addedUnexpectedItems.length < 1}
                        sx={{ mt: 3 }}
                        size="large"
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            submitReviewHandler();
                            dispatch(setRmaSlice(rma));
                        }}
                    >
                        FINISH RETURN
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
};
