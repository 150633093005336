import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_RMA_ITEMS_URL, POST_RMA_SERIAL_URL } from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';
import {
    GetRmaItemRequest,
    GetRmaItemsResponse,
    PostSerialRequest,
    TransformedGetRmaItem,
    TransformedGetRmaItemSerialVerify,
    TransformedGetRmaItemsResponse,
} from './rmaModels';

export default createApi({
    reducerPath: 'rmaApi',
    baseQuery: dreamBaseQueryWithReauth,
    endpoints: builder => ({
        getRmaItems: builder.query<GetRmaItemsResponse | TransformedGetRmaItemsResponse, GetRmaItemRequest>({
            query: (request: GetRmaItemRequest) => ({
                url: `${GET_RMA_ITEMS_URL}?rmaNumber=${request.rmaNumber}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: request.momCode,
                },
            }),
            transformResponse: (response: GetRmaItemsResponse): TransformedGetRmaItemsResponse => {
                let newItems: TransformedGetRmaItem[] = [];

                response.Items.forEach(item => {
                    for (let i = 0; i < item.QuantityTotal; i++) {
                        const isCompleted =
                            item.SerialVerifies.length ===
                            item.SerialVerifies.filter(serialVerify => !!serialVerify.ScanValue[i]).length;

                        const currItemData = {
                            ...item,
                            QuantityIndex: i,
                            IsCompleted: isCompleted,
                            IsCompletedFromStart: isCompleted,
                            SerialVerifies: item.SerialVerifies.map(serialVerify => {
                                return {
                                    ...serialVerify,
                                    ScanValue: serialVerify.ScanValue[i],
                                } as TransformedGetRmaItemSerialVerify;
                            }),
                        } as TransformedGetRmaItem;

                        newItems.push({ ...currItemData });
                    }
                });

                return {
                    ...response,
                    Items: newItems,
                } as TransformedGetRmaItemsResponse;
            },
        }),
        postRmaSerial: builder.mutation<void, Partial<PostSerialRequest>>({
            query: body => ({
                url: `${POST_RMA_SERIAL_URL}`,
                method: 'POST',
                body,
                headers: {
                    RushOrderMomCode: body.MomCode,
                },
            }),
        }),
    }),
});
