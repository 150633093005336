import {
    GET_INBOUND_SERIAL_URL,
    GET_ONHAND_SERIAL_URL,
    GET_OUTBOUND_SERIAL_URL,
    GET_SERIAL_LOCATION_URL,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import {
    GetSerialLocationRequest,
    GetSerialRequest,
    InboundRecord,
    LocationAssignmentRecord,
    OnHandRecord,
    OutboundRecord,
} from './serialModels';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getInboundSerials: builder.query<InboundRecord[], GetSerialRequest>({
            query: param => ({
                url: prependDreamUrl(GET_INBOUND_SERIAL_URL),
                method: 'GET',
                params: param,
                headers: {
                    RushOrderMomCode: param.Client,
                },
            }),
        }),
        getOutboundSerials: builder.query<OutboundRecord[], GetSerialRequest>({
            query: param => ({
                url: prependDreamUrl(GET_OUTBOUND_SERIAL_URL),
                method: 'GET',
                params: param,
                headers: {
                    RushOrderMomCode: param.Client,
                },
            }),
        }),
        getOnHandSerials: builder.query<OnHandRecord[], GetSerialRequest>({
            query: param => ({
                url: prependDreamUrl(GET_ONHAND_SERIAL_URL),
                method: 'GET',
                params: param,
                headers: {
                    RushOrderMomCode: param.Client,
                },
            }),
        }),
        getSerialLocations: builder.query<LocationAssignmentRecord[], GetSerialLocationRequest>({
            query: param => ({
                url: prependDreamUrl(GET_SERIAL_LOCATION_URL),
                method: 'GET',
                params: param,
            }),
        }),
    }),
});
