import { Autocomplete, SxProps, TextField, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import workOrderApi from '../../workOrderApi';
import { TemplateType } from '../../workOrderModels';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { UNASSIGNED_TEMPLATE_TYPE_ID } from '../../constants';

const TemplateTypeDropdown = ({
    currTemplateTypeId,
    onChange,
    sx = {},
    readOnly = false,
}: {
    sx?: SxProps<Theme>;
    currTemplateTypeId: number;
    onChange: (newTemplateTypeId: number) => void;
    readOnly?: boolean;
}) => {
    const getTemplateTypesReponse = workOrderApi.endpoints.getTemplateTypes.useQuery();

    const options = () => {
        return getTemplateTypesReponse?.data
            ?.filter(option => option.Id !== UNASSIGNED_TEMPLATE_TYPE_ID)
            .map(option => option.Id);
    };

    return !getTemplateTypesReponse.isError && getTemplateTypesReponse?.data?.length !== 0 ? (
        <Autocomplete
            disablePortal
            options={options() || []}
            value={currTemplateTypeId}
            onChange={(event, newValue) => {
                onChange(newValue || 0);
            }}
            getOptionLabel={(option: number) =>
                `${getTemplateTypesReponse?.data?.find(templateType => templateType.Id === option)?.Name}`
            }
            renderInput={params => <TextField {...params} variant="outlined" label={'Template type'} />}
            sx={{ ...sx }}
            readOnly={readOnly}
            popupIcon={readOnly ? '' : <ArrowDropDownIcon />}
            disableClearable
        />
    ) : (
        <Autocomplete
            disablePortal
            options={[]}
            renderInput={params => <TextField {...params} variant="outlined" label={'Template type'} />}
            sx={{ ...sx }}
            readOnly={readOnly}
            popupIcon={readOnly ? '' : <ArrowDropDownIcon />}
        />
    );
};

export default TemplateTypeDropdown;
