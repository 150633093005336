import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Button,
    Box,
    AppBar,
    Dialog,
    Toolbar,
    IconButton,
    Typography,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersAction } from '../../../archive/store/actions/action';
import CloseIcon from '@mui/icons-material/Close';
import RoleTable from './RoleTable';
import { SlideUpTransition } from '../../../common/components/Transition/SlideUpTransition';

const PREFIX = 'AddRole';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    selected: `${PREFIX}-selected`,
    title: `${PREFIX}-title`,
    backBtn: `${PREFIX}-backBtn`,
    tabButton: `${PREFIX}-tabButton`,
    unAssignFab: `${PREFIX}-unAssignFab`,
    assignFab: `${PREFIX}-assignFab`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.paper}`]: {
        width: '100%',
        marginBottom: '5rem',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },

    [`& .${classes.table}`]: {
        // minWidth:0,
    },

    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    [`& .${classes.selected}`]: {
        backgroundColor: `${lighten(theme.palette.primary.light, 0.85)} !important`,
    },

    [`& .${classes.title}`]: {
        flex: '1 1 100%',
    },

    [`& .${classes.backBtn}`]: {
        '&:focus': {
            outline: 'none',
        },
    },

    [`& .${classes.tabButton}`]: {
        '& button': {
            color: 'black',
            //targeting the inner button element
            outline: 'none',
            '&:focus': {
                outline: 'none',
            },
        },
    },

    [`& .${classes.unAssignFab}`]: {
        position: 'fixed',
        bottom: '8rem',
        right: theme.spacing(3),
        outline: 'none !important',
        backgroundColor: 'red',
        color: 'white',
        '&:hover': {
            backgroundColor: '#D32F2F',
            color: 'white',
        },
    },

    [`& .${classes.assignFab}`]: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        outline: 'none !important',
    },
}));

const AddRole = ({ selectedRole, setSelectedRole, setIsEdittingUserRole }) => {
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [unAssignedUsers, setUnAssignedUsers] = useState([]);
    const [isAssigningUsers, setIsAssigningUsers] = useState(false);
    const dispatch = useDispatch();
    const { usersList, isLoading, success } = useSelector(state => state.roles);

    useEffect(() => {
        if (selectedRole) {
            dispatch(getAllUsersAction({ roleId: selectedRole.id, pageSize: 1000 }));
        }
    }, [selectedRole, dispatch]);

    useEffect(() => {
        if (usersList?.[0]) {
            setAssignedUsers(usersList.filter(usr => usr.assigned));
            setUnAssignedUsers(usersList.filter(usr => !usr.assigned));
            // setUserList(usersList);
        }
    }, [success, usersList]);

    useEffect(() => {
        if (success) {
            setIsAssigningUsers(false);
        }
    }, [success]);

    return (
        <Root className={classes.root}>
            <Box display="flex" style={{ padding: '1rem 0' }}>
                <Button
                    className={classes.backBtn}
                    onClick={() => {
                        setSelectedRole(null);
                        setIsEdittingUserRole(false);
                    }}
                >
                    <ArrowBackIcon style={{ marginRight: 8 }} />
                    Back
                </Button>
            </Box>
            <Paper className={classes.paper}>
                <div>
                    <RoleTable
                        classes={classes}
                        userDataList={assignedUsers}
                        selectedRole={selectedRole}
                        isLoading={isLoading}
                        setIsAssigningUsers={setIsAssigningUsers}
                    />
                    {/* <Button variant="contained" color="primary" className={classes.assignFab} onClick={() => {
                      setIsAssigningUsers(true)}}>
                      Assign Users
                    </Button> */}
                    <Dialog
                        open={isAssigningUsers}
                        onClose={() => {
                            setIsAssigningUsers(false);
                        }}
                        aria-labelledby=""
                        fullScreen
                        TransitionComponent={SlideUpTransition}
                    >
                        <DialogTitle>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography variant="h6" className={classes.title}>
                                            Assign Users
                                        </Typography>
                                        <IconButton
                                            className={classes.cancelButton}
                                            edge="start"
                                            color="inherit"
                                            onClick={() => {
                                                setIsAssigningUsers(false);
                                            }}
                                            aria-label="close"
                                            size="large"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </Toolbar>
                            </AppBar>
                        </DialogTitle>

                        <DialogContent style={{ marginTop: '3rem' }}>
                            <Paper className={classes.paper}>
                                <RoleTable
                                    isAssigningUsers={isAssigningUsers}
                                    classes={classes}
                                    userDataList={unAssignedUsers}
                                    selectedRole={selectedRole}
                                    isLoading={isLoading}
                                />
                            </Paper>
                        </DialogContent>
                    </Dialog>
                </div>
            </Paper>
        </Root>
    );
};

export default AddRole;
