import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import workOrderApi from '../../workOrderApi';
import { AttachmentFile } from '../../workOrderModels';
import { overrideForm } from '../../workOrderSlice';

interface AttachmentFileListProps {
    isDisabled: boolean;
}

const AttachmentFileList = ({ isDisabled }: AttachmentFileListProps) => {
    const attachmentFiles = useAppSelector(state => state.workOrder.form.AttachmentFiles);
    const dispatch = useAppDispatch();
    const [triggerDownload, downloadResponse] = workOrderApi.endpoints.downloadAttachmentFile.useMutation();

    useNotificationByMutationResponseV2({
        response: downloadResponse,
        errorMessage: 'Unable to download file',
    });

    const handleItemDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: AttachmentFile) => {
        event.stopPropagation();
        if (isDisabled) return;

        const indexToRemove = attachmentFiles.map(currItem => JSON.stringify(currItem)).indexOf(JSON.stringify(row));
        if (indexToRemove === -1) return;
        let newItemsList = [...attachmentFiles];

        if (row.Id) {
            newItemsList[indexToRemove] = {
                ...newItemsList[indexToRemove],
                DeleteDateTime: dayjs().format(),
            };
        } else {
            newItemsList.splice(indexToRemove, 1);
        }
        dispatch(overrideForm({ AttachmentFiles: newItemsList }));
    };

    const handleEditItem = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: AttachmentFile) => {
        event.stopPropagation();

        const isFileContentSavedOnClient = !row?.GoogleDriveId;
        if (isFileContentSavedOnClient) {
            var hiddenElement = document.createElement('a');
            hiddenElement.href = `${row.EncodingHeader}${row.Content}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = row.Name;
            hiddenElement.click();
        } else {
            triggerDownload({ id: row.Id, name: row.Name });
        }
    };

    const attachmentFilesToShow = attachmentFiles?.filter((row: AttachmentFile) => !row.DeleteDateTime);

    return (
        <Box sx={{ my: 2, display: attachmentFilesToShow?.length ? '' : 'none' }}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>File</TableCell>
                            <TableCell align="right" sx={{ display: isDisabled ? 'none' : 'block' }}>
                                Delete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attachmentFilesToShow?.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={event => handleEditItem(event, row)}
                            >
                                <TableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                                    <Link download={row.Name} target="_blank" rel="noopener">
                                        {row.Name}
                                    </Link>
                                </TableCell>

                                <TableCell align="right" sx={{ display: isDisabled ? 'none' : 'block' }}>
                                    <IconButton
                                        edge="end"
                                        onClick={(event: any) => handleItemDelete(event, row)}
                                        disabled={isDisabled}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AttachmentFileList;
