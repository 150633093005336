import { Box } from '@mui/material';
import React from 'react';
import { SubmissionFormItem } from '../../../../../receiversModels';
import ItemAutocomplete from './ItemAutocomplete';

const ItemTextField = ({
    itemToUpsert,
    setItemToUpsert,
}: {
    itemToUpsert?: SubmissionFormItem;
    setItemToUpsert: React.Dispatch<React.SetStateAction<SubmissionFormItem | undefined>>;
}) => {
    // const project = useAppSelector(state => state.receivers.form.Project);

    return (
        <Box sx={{ display: 'flex', flex: 1 }}>
            {/* TODO: display textfield conditionally based on permission */}
            {/* {UNIT_TYPES_THAT_SHOW_ITEM_AUTOCOMPLETE.includes(project) ? ( */}
            <ItemAutocomplete itemToUpsert={itemToUpsert} setItemToUpsert={setItemToUpsert} />
            {/* ) : (
                <TextField
                    value={upcToUpdate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        setUpcToUpdate(newValue);
                    }}
                    label="Scan UPC"
                    variant="outlined"
                    sx={{ mt: 2, flex: 1 }}
                    autoFocus
                    onKeyDown={event => {
                        if (event.key === 'Enter') {
                            fetchCurrItemData();
                        }
                    }}
                />
            )} */}
        </Box>
    );
};

export default ItemTextField;
