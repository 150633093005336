import AuthenticateAPI from './axiosAuthenticate';

export const makeLoginRequest = async action => {
    const res = await AuthenticateAPI.post('api/authentication', {
        username: action.username,
        password: action.password,
        type: 'mom',
    });
    if (res.status >= 400) {
        throw new Error('Invalid Credentials');
    }
    return res;
};
