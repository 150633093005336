import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/store';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import { resetDialog, setDialog } from '../../notification/notificationSlice';
import useScanner from '../hooks/useScanner';
import qualityControlApi from '../qualityControlApi';
import { TransformedItem, TransformedItemSerialVerify } from '../qualityControlModels';
import { setOrderData, setTransformedItems } from '../qualityControlSlice';

const InvoiceNumberDialog = () => {
    const navigate = useNavigate();
    const [invoiceNumber, setInvoiceNumber] = useState<string>('');
    const [triggerGetOrderStatus, getOrderStatusResponse] = qualityControlApi.endpoints.getOrderStatus.useLazyQuery();
    const [triggerGetOrderData, getOrderDataResponse] = qualityControlApi.endpoints.getOrderData.useLazyQuery();
    const [triggerPutStartState] = qualityControlApi.endpoints.putStartState.useMutation();

    const dispatch = useAppDispatch();
    const handleDialogClose = () => {
        navigate('/');
    };

    const handleScannerInputted = () => {
        if (getOrderStatusResponse.data) {
            displayStatus();
        }
        // dispatch(getQCOrderStatusAction(invoiceNumber));
        triggerGetOrderStatus(invoiceNumber);
    };

    useScanner(invoiceNumber, handleScannerInputted);

    useEffect(() => {
        if (!getOrderStatusResponse.isLoading && getOrderStatusResponse.data) {
            setInvoiceNumber('');
            displayStatus();
        }
    }, [getOrderStatusResponse.data, getOrderStatusResponse.isLoading, dispatch]);

    useEffect(() => {
        if (getOrderDataResponse.isSuccess) {
            triggerPutStartState(getOrderDataResponse.data.PackageId.PkgId);
            dispatch(setOrderData(getOrderDataResponse.data));

            //transform data
            let newItems: TransformedItem[] = [];
            getOrderDataResponse.data.Items.forEach(item => {
                const currItemData = {
                    ItemId: item.ItemId,
                    Sku: item.Sku,
                    Upc: item.Upc,
                    IsSerialCompleted: item.SerialVerify.length === 0,
                    SerialVerify: item.SerialVerify.map(
                        serialVerify =>
                            ({
                                ...serialVerify,
                                ScanValue: '',
                            } as TransformedItemSerialVerify)
                    ),
                };
                for (let i = 0; i < item.Quants; i++) {
                    newItems.push({ ...currItemData, QuantityIndex: i });
                }
            });
            dispatch(setTransformedItems(newItems));
        }
    }, [getOrderDataResponse.isSuccess]);

    useNotificationByResponse({
        isFetching: getOrderStatusResponse.isFetching,
        isError: getOrderStatusResponse.isError,
        errorContent: 'Could not get order status',
        isUninitialized: getOrderStatusResponse.isUninitialized,
    });

    useNotificationByResponse({
        isFetching: getOrderDataResponse.isFetching,
        isError: getOrderDataResponse.isError,
        errorContent: 'Could not get order data',
        isUninitialized: getOrderDataResponse.isUninitialized,
    });

    const displayStatus = () => {
        if (process.env.REACT_APP_MODE === 'dev' && false) {
            if (getOrderStatusResponse.data) {
                triggerGetOrderData(invoiceNumber);
            }
        } else {
            switch (getOrderStatusResponse.data) {
                case 'NotStarted':
                case 'Paused':
                case 'Invalid':
                    triggerGetOrderData(invoiceNumber);
                    dispatch(resetDialog());
                    break;
                case 'InProgress':
                    dispatch(setDialog({ content: 'QC Already in Progress' }));
                    break;
                case 'Complete':
                    dispatch(setDialog({ content: 'QC Already Completed' }));
                    break;
                case 'Aborted':
                    dispatch(setDialog({ content: 'QC Aborted, Must be reset by supervisor' }));
                    break;
            }
        }
    };

    const handleSubmitClick = (e: any) => {
        e.preventDefault();
        handleScannerInputted();
    };

    return (
        <Dialog open={true} onClose={handleDialogClose}>
            <DialogTitle>
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        variant="h5"
                        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    >
                        {'Quality Control'}
                    </Typography>

                    <IconButton
                        sx={{ outline: 'none !important', marginLeft: 0 }}
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {/* <form id="invoice-form-id"> */}
                <TextField
                    autoFocus
                    margin="dense"
                    id="invoice-number-input"
                    fullWidth
                    label={'Invoice Number'}
                    variant="outlined"
                    value={invoiceNumber}
                    onChange={e => setInvoiceNumber(e.target.value.toUpperCase())}
                    error={getOrderStatusResponse.isError}
                    helperText={getOrderStatusResponse.isError ? 'Invalid Input' : ''}
                    sx={{ w: '100%' }}
                />
                {/* </form> */}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={handleSubmitClick}
                    // form="invoice-form-id"
                >
                    {' '}
                    Submit{' '}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceNumberDialog;
