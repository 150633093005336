import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../../../app/store';
import useNotificationByMutationResponseV2 from '../../../../../common/hooks/useNotificationByMutationResponseV2';
import { setDialog } from '../../../../notification/notificationSlice';
import ProcedureApi from '../../../ProcedureApi';
import { doesMatchVariableLabelRegex } from '../../../util';

const SetVariableForm = ({ instructionId }: { instructionId: number }) => {
    const [triggerUpsertVariable, upsertVariableResponse] = ProcedureApi.endpoints.upsertVariable.useMutation();
    const dispatch = useAppDispatch();

    const [name, setName] = React.useState('');
    const [value, setValue] = React.useState('');

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const validCustomSku = doesMatchVariableLabelRegex(name);
        if (!validCustomSku) {
            dispatch(
                setDialog({
                    content:
                        'Variable label: \n\n- can not contain numbers or special characters other than underscore',
                    title: 'Invalid label',
                })
            );
            return;
        }

        triggerUpsertVariable({ Name: name.toUpperCase(), Value: value, InstructionId: instructionId });
    };

    const resetForm = () => {
        setName('');
        setValue('');
    };

    useNotificationByMutationResponseV2({
        response: upsertVariableResponse,
        successFinishedFunction: resetForm,
    });

    return (
        <form onSubmit={onFormSubmit}>
            <Box sx={{ my: 1, display: 'flex' }}>
                <TextField
                    label="Name"
                    sx={{ flex: 1, mr: 1 }}
                    value={name}
                    onChange={event => {
                        const newValue = event.target.value;
                        setName(newValue);
                    }}
                />
                <TextField
                    label="Value"
                    sx={{ flex: 1 }}
                    value={value}
                    onChange={event => {
                        const newValue = event.target.value;
                        setValue(newValue);
                    }}
                />
                <Button variant="contained" color="secondary" type="submit" sx={{ m: 1 }}>
                    Add Variable
                </Button>
            </Box>
        </form>
    );
};

export default SetVariableForm;
