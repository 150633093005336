import { Autocomplete, Box, TextField } from '@mui/material';
import { formatDate } from '../../../../../common/utils/dateUtil';
import shipMatrixApi from '../../../shipMatrixApi';
import { EditLog, GetClientEditLogRequest } from '../../../shipMatrixModel';
import React from 'react';
import EditLogDetailList from './EditLogDetailList';

const EditLogAutocomplete = ({ groupId }: { groupId: number }) => {
    const getEditLogsResponse = shipMatrixApi.endpoints.getClientEditLog.useQuery({
        groupId: groupId,
    } as GetClientEditLogRequest);

    const [editLogDetailId, setEditLogDetailId] = React.useState<number | null>(null);

    return (
        <Box>
            <Autocomplete
                disablePortal
                options={getEditLogsResponse?.data?.Data || []}
                getOptionLabel={(option: EditLog) =>
                    `${option.EditedByName} (${option.EditedByCode}): ${formatDate(option.EditedAt)}`
                }
                onChange={(event, newValue) => {
                    setEditLogDetailId(newValue?.Id || null);
                }}
                renderInput={params => <TextField {...params} label="Edit Log" />}
            />

            {editLogDetailId && <EditLogDetailList editLogDetailId={editLogDetailId} />}
        </Box>
    );
};

export default EditLogAutocomplete;
