import { Autocomplete, Box, Button, TextField } from '@mui/material';
import React from 'react';
import { Filter, Group } from '../shipMatrixModel';
import { CompanyDropdown } from '../../../common/components';
import shipMatrixApi from '../shipMatrixApi';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';

interface ShipMatrixFilterProps {
    setCurrGroup: React.Dispatch<React.SetStateAction<Group | null>>;
}

const ShipMatrixFilter = ({ setCurrGroup }: ShipMatrixFilterProps) => {
    const getGroupsResponse = shipMatrixApi.endpoints.getRateShoppingGroups.useQuery();
    const [group, setGroup] = React.useState<Group | null>(null);

    useNotificationByResponse({
        isFetching: getGroupsResponse.isFetching,
        isError: getGroupsResponse.isError,
        errorContent: 'Failed to get groups',
        // isSuccess: getGroupsResponse.isSuccess,
        // successContent: `Received groups`,
        isUninitialized: getGroupsResponse.isUninitialized,
    });

    //on group response change, set curr group matching previous group
    React.useEffect(() => {
        if (group) {
            setGroup(oldGroup => {
                const matchingGroup = getGroupsResponse?.data?.Data?.find(currGroup => currGroup.Id === oldGroup?.Id);
                if (matchingGroup) return matchingGroup;
                else return null;
            });
        }
    }, [getGroupsResponse]);

    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                setCurrGroup(group);
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <Autocomplete
                    disablePortal
                    options={getGroupsResponse?.data?.Data || []}
                    value={group}
                    onChange={(event, newValue) => {
                        setGroup(newValue);
                    }}
                    getOptionLabel={(option: Group) => `${option.Name} (${option?.Clients?.join(', ')})`}
                    renderInput={params => <TextField {...params} variant="outlined" label={'Group Name'} />}
                    sx={{ flex: 4 }}
                    isOptionEqualToValue={(option, value) => option.Id === value.Id}
                />

                <Button type="submit" sx={{ flex: 1, m: 1 }} variant="contained">
                    Select
                </Button>
            </Box>
        </form>
    );
};

export default ShipMatrixFilter;
