import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import InputDialog from '../../../../common/components/Dialog/InputDialog';
import workOrderApi from '../../workOrderApi';
import { overrideForm } from '../../workOrderSlice';
import BillingItemForm from './BillingItemForm';
import { BillingItem } from '../../workOrderModels';
import dayjs from 'dayjs';
interface BillingItemListProps {
    isDisabled: boolean;
}

const BillingItemList = ({ isDisabled }: BillingItemListProps) => {
    const billingItems = useAppSelector(state => state.workOrder.form.BillingItems);
    const getServicesResponse = workOrderApi.endpoints.getServices.useQuery();
    const dispatch = useAppDispatch();

    const [updateBillingItemProps, setUpdateBillingItemProps] = React.useState<{
        isOpen: boolean;
        indexToUpdate: number;
    }>({ isOpen: false, indexToUpdate: -1 });
    const handleItemDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: any) => {
        event.stopPropagation();
        const indexToRemove = billingItems.map(currItem => JSON.stringify(currItem)).indexOf(JSON.stringify(row));
        if (indexToRemove === -1) return;

        let newItemsList = [...billingItems];

        if (row.Id) {
            newItemsList[indexToRemove] = {
                ...newItemsList[indexToRemove],
                DeleteDateTime: dayjs().format(),
            };
        } else {
            newItemsList.splice(indexToRemove, 1);
        }

        dispatch(overrideForm({ BillingItems: newItemsList }));
    };

    const handleEditItem = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => {
        event.stopPropagation();
        if (isDisabled) return;

        const indexToUpdate = billingItems.map(currItem => JSON.stringify(currItem)).indexOf(JSON.stringify(row));
        if (indexToUpdate === -1) return;

        setUpdateBillingItemProps({
            isOpen: true,
            indexToUpdate: indexToUpdate,
        });
    };

    const handleUpdateBillingItemDialogClose = () => {
        setUpdateBillingItemProps({
            isOpen: false,
            indexToUpdate: -1,
        });
    };

    const billingItemsToShow = billingItems?.filter((row: BillingItem) => !row.DeleteDateTime);

    return (
        <Box sx={{ display: billingItemsToShow?.length ? '' : 'none' }}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Service</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Rush</TableCell>
                            <TableCell>No Charge</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell align="right" sx={{ display: isDisabled ? 'none' : '' }}>
                                Delete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ cursor: isDisabled ? '' : 'pointer' }}>
                        {billingItemsToShow?.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={event => handleEditItem(event, row)}
                            >
                                <TableCell component="th" scope="row">
                                    <Tooltip
                                        title={
                                            getServicesResponse.data?.find(service => service.Code === row.Service)
                                                ?.Description
                                        }
                                        placement="left"
                                    >
                                        <Typography>{row.Service}</Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.Instructions}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.Rush ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.NoCharge ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.Duration}
                                </TableCell>
                                <TableCell align="right" sx={{ display: isDisabled ? 'none' : 'block' }}>
                                    <IconButton
                                        edge="end"
                                        onClick={(event: any) => handleItemDelete(event, row)}
                                        disabled={isDisabled}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <InputDialog
                title={'Update Billing Item'}
                isHidden={!updateBillingItemProps.isOpen}
                handleClose={handleUpdateBillingItemDialogClose}
                bodyElement={
                    <BillingItemForm
                        isDisabled={false}
                        indexToUpdate={updateBillingItemProps.indexToUpdate}
                        handleOnSubmit={handleUpdateBillingItemDialogClose}
                    />
                }
            />
        </Box>
    );
};

export default BillingItemList;
