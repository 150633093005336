import { Box } from '@mui/material';
import { BatchOrderMonitorDisplay } from './BatchOrderMonitorDisplay';

const BatchOrderMonitorElement = () => {
    return (
        <Box sx={{ pt: 1 }}>
            <BatchOrderMonitorDisplay />
        </Box>
    );
};

export default BatchOrderMonitorElement;
