import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../app/store';
import Page from '../../common/components/container/Page';
import {
    decrementIsSpinnerOpen,
    incrementIsSpinnerOpen,
    setDialog,
    setSnackbar,
} from '../notification/notificationSlice';
import TrackingElement from './components/TrackingElement';
import trackingApi from './trackingApi';
import { setMomCode } from './trackingSlice';

const Tracking = () => {
    const dispatch = useAppDispatch();
    const momCode = useAppSelector((state: RootState) => state.tracking.momCode);
    const response = trackingApi.endpoints.getCarriers.useQuery(momCode ? momCode : skipToken);
    const [postTracking, request] = trackingApi.endpoints.postTracking.useMutation({
        fixedCacheKey: 'post-tracking',
    });

    const resetStates = () => {
        dispatch(trackingApi.util.resetApiState());
        dispatch(setMomCode(''));
    };

    useEffect(() => {
        return () => {
            resetStates();
        };
    }, []);

    useEffect(() => {
        if (!request.isUninitialized && !request.isLoading) {
            if (request.data?.Success && request.isSuccess) {
                dispatch(setSnackbar({ content: 'Submission Success', severity: 'success' }));
            } else {
                dispatch(setDialog({ content: `${JSON.stringify(request?.error)}` }));
            }
            resetStates();
        }
    }, [request.isLoading]);

    useEffect(() => {
        if (request.isLoading || response.isLoading) dispatch(incrementIsSpinnerOpen());
        else dispatch(decrementIsSpinnerOpen());
    }, [request.isLoading, response.isLoading]);

    return <Page title={'Tracking'} childrenElement={<TrackingElement />} />;
};

export default Tracking;
