import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import useNotificationByQueryResponse from '../../../common/hooks/useNotificationByQueryResponse';
import batchOrderMonitorApi from '../batchOrderMonitorApi';
import { BatchOrderMonitor, BatchOrderStatusCounts } from '../batchOrderMonitorModels';
import { BatchOrderDetailsDialog } from './BatchOrderDetails/BatchOrderDetailsDialog';

const PENDING_LABEL_FONT_SIZE = { xs: '0.5rem', sm: '1.25rem', md: '1.5rem' };
const COUNT_FONT_SIZE = { xs: '2.0rem', sm: '3.5rem', md: '5.0rem' };

export const BatchOrderTotalCountsPanelDetails = ({
    totalBatchOrderCounts,
    location,
}: {
    totalBatchOrderCounts: BatchOrderStatusCounts | undefined | null;
    location: string;
}) => {
    const theme = useTheme();
    const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'));
    const [incompleteBatchOrders, setIncompleteBatchOrders] = React.useState<BatchOrderMonitor[]>([]);
    const [incompleteOrderDialog, setIncompleteOrderDialog] = React.useState(false);
    const [
        triggerGetIncompleteOrders,
        getIncompleteOrdersResponse,
    ] = batchOrderMonitorApi.endpoints.getBatchOrders.useLazyQuery();

    const handleGetIncompleteOrders = () => {
        triggerGetIncompleteOrders(
            {
                FromDate: null,
                ToDate: null,
                MomCode: null,
                Location: location,
                IncompleteOnly: true,
            },
            false
        );
        setIncompleteOrderDialog(true);
    };

    const ResponsiveDivider = () => {
        if (isSmallViewPort) return <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 1 }} />;

        return <Divider />;
    };

    useNotificationByQueryResponse({
        response: getIncompleteOrdersResponse,
        successFinishedFunction: () => {
            if (getIncompleteOrdersResponse.data?.Data) {
                setIncompleteBatchOrders(getIncompleteOrdersResponse.data.Data);
            }
        },
    });

    if (!totalBatchOrderCounts) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', m: 1, mt: 4 }}>
                <Typography color="warning.main">No Serial Batches Found</Typography>
            </Box>
        );
    }

    return (
        <Box>
            <BatchOrderDetailsDialog
                location={location}
                batchOrders={incompleteBatchOrders}
                handleOpenDialog={setIncompleteOrderDialog}
                showDialog={incompleteOrderDialog}
            />

            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            sm: 'row',
                            md: 'column',
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 4, flex: 1 }}>
                        <Typography variant="h6" sx={{ fontSize: PENDING_LABEL_FONT_SIZE, textAlign: 'center' }}>
                            TOTAL PENDING BATCHES:
                        </Typography>
                        <Typography variant="h1" fontWeight="medium" sx={{ fontSize: COUNT_FONT_SIZE }}>
                            {totalBatchOrderCounts?.IncompleteBatches}
                        </Typography>
                    </Box>
                    {ResponsiveDivider()}
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 4, flex: 1 }}>
                        <Typography variant="h6" sx={{ fontSize: PENDING_LABEL_FONT_SIZE, textAlign: 'center' }}>
                            TOTAL PENDING ORDERS:
                        </Typography>
                        <Typography variant="h1" fontWeight="medium" sx={{ fontSize: COUNT_FONT_SIZE }}>
                            {totalBatchOrderCounts.Batched - totalBatchOrderCounts.QcCompleted}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleGetIncompleteOrders} size="large">
                        SHOW DETAILS
                    </Button>
                </Box>
                <Divider />
            </Box>
        </Box>
    );
};
