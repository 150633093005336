import { Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import { UNASSIGNED_TEMPLATE_TYPE_ID } from '../../constants';
import workOrderApi from '../../workOrderApi';
import { WorkOrderData } from '../../workOrderModels';
import { setMode } from '../../workOrderSlice';
import WorkOrderFormLeft from './WorkOrderFormLeft';
import WorkOrderFormRight from './WorkOrderFormRight';

interface WorkOrderCreateFormProps {
    hasUserMadeChanges: boolean;
}

const WorkOrderCreateForm = ({ hasUserMadeChanges }: WorkOrderCreateFormProps) => {
    const form = useAppSelector(state => state.workOrder.form);
    const mode = useAppSelector(state => state.workOrder.workOrderMode);
    const tab = useAppSelector(state => state.workOrder.tab);
    const username = useAppSelector(state => state.authentication.username);

    const [triggerFormCreate, createResponse] = workOrderApi.endpoints.postWorkOrder.useMutation();
    const [isErrorDisplayed, setIsErrorDisplayed] = React.useState(false);
    const dispatch = useAppDispatch();

    const handleFormSubmit = () => {
        const isDateCompletedValid = form.DateCompleted
            ? dayjs(form.DateCompleted).isAfter(dayjs().startOf('month'))
            : true;
        if (form.ProjectTitle && form.AssignedTo && form.RoRequester && isDateCompletedValid) {
            triggerFormCreate({ Data: fixFormOnUpload(form), Username: username });
        }

        setIsErrorDisplayed(true);
    };

    const fixFormOnUpload = (form: WorkOrderData): WorkOrderData => {
        return {
            ...form,
            DateCreated: dayjs().format(),
            TemplateTypeId: tab === 'Work Orders' ? UNASSIGNED_TEMPLATE_TYPE_ID : form.TemplateTypeId,
        };
    };

    useNotificationByMutationResponseV2({
        response: createResponse,
        errorMessage: `Failed to ${mode}`,
        successMessage: `Successful ${mode}`,
        finishedFunction: () => {
            dispatch(setMode('View'));
        },
    });

    return (
        <Box>
            <Box sx={{ display: 'flex', mt: 5 }}>
                <Box sx={{ flex: 1, mr: 2 }}>
                    <WorkOrderFormLeft isErrorDisplayed={isErrorDisplayed} isDisabled={mode === 'View'} />
                </Box>
                <Box sx={{ flex: 1, ml: 2 }}>
                    <WorkOrderFormRight isErrorDisplayed={isErrorDisplayed} isDisabled={mode === 'View'} />
                </Box>
            </Box>

            <Button
                data-testid={'submit-button'}
                variant="contained"
                sx={{ width: '100%', my: 2, display: mode === 'View' ? 'none' : 'block' }}
                onClick={handleFormSubmit}
            >
                Save {tab === 'Work Orders' ? 'Work Order' : 'Template'}
            </Button>

            <Typography sx={{ color: theme => theme.palette.warning.main }}>
                {hasUserMadeChanges ? `Changes detected. Please click 'save' to confirm changes.` : ``}&nbsp;
            </Typography>
        </Box>
    );
};

export default WorkOrderCreateForm;
