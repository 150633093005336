import { Backdrop } from '@mui/material';
import { RootState, useAppSelector } from '../../../app/store';
import { Spinner } from './Spinner';

const GlobalSpinner = () => {
    const spinnerCount = useAppSelector(state => state.notification.spinnerCount);

    const isPendingQueries = useAppSelector((state: RootState) =>
        Object.values(state.api.queries).some(query => {
            return !(query?.endpointName ?? '').endsWith('Async') && query?.status === 'pending';
        })
    );
    const isPendingMutations = useAppSelector((state: RootState) =>
        Object.values(state.api.mutations).some(mutation => {
            return !(mutation?.endpointName ?? '').endsWith('Async') && mutation?.status === 'pending';
        })
    );

    const isRequestPending = isPendingQueries || isPendingMutations;

    return (
        <Backdrop
            open={spinnerCount !== 0 || isRequestPending}
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.modal + 1,
            }}
        >
            <Spinner style={{ height: 350 }} />
        </Backdrop>
    );
};

export default GlobalSpinner;
