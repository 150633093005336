import { Box, Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { Item, Serial } from '../../orderLookupModels';
import { SerialsUpdateForm } from './SerialsUpdateForm';

export const SerialsUpdateDialog = React.memo(
    ({
        itemData,
        serialData,
        handleOpenDialog,
        showDialog = false,
    }: {
        itemData: Item | undefined;
        serialData: Serial | undefined;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth={itemData?.HasSerials2Field ? 'lg' : 'sm'}>
                <Box sx={{ m: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <DialogTitle sx={{ flex: 4, m: 0, p: 1 }}>Update Serial</DialogTitle>
                    </Box>
                    <Box>
                        <SerialsUpdateForm itemData={itemData} serialData={serialData} handleClose={handleClose} />
                    </Box>
                    <DialogCloseButton handleClose={handleClose} />
                </Box>
            </Dialog>
        );
    }
);
