import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/store';
import { CustomerDisplay } from './CustomerDisplay';
import { Customer } from '../orderLookupModels';
import orderLookupApi from '../orderLookupApi';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export function BillingShippingDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);

    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const billingData = getOrderDetailsResponse.data?.Data[0]?.Billing;
    const shippingData = getOrderDetailsResponse.data?.Data[0]?.Shipping;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: 3, m: 1, mr: 2 }}>
                <Typography variant="h6"> Ship To </Typography>
                {!!shippingData ? <CustomerDisplay {...shippingData} /> : ''}
            </Box>
            <Box sx={{ flex: 3, m: 1 }}>
                <Typography variant="h6"> Bill To </Typography>
                {!!billingData ? <CustomerDisplay {...billingData} /> : ''}
            </Box>
        </Box>
    );
}
