import { Box, Typography } from '@mui/material';
import React from 'react';
import useNotificationByQueryResponse from '../../../common/hooks/useNotificationByQueryResponse';
import batchOrderMonitorApi from '../batchOrderMonitorApi';
import { BatchOrderStatusCounts } from '../batchOrderMonitorModels';
import { MILLISEC_REFRESH_TIME } from '../constants';
import { BatchOrderTotalCountsPanelDetails } from './BatchOrderTotalCountsPanelDetails';

export const BatchOrderTotalCountsPanel = ({ location }: { location: string }) => {
    const [totalBatchOrderCounts, setTotalBatchOrderCounts] = React.useState<BatchOrderStatusCounts>();
    const getBatchOrderCountsResponse = batchOrderMonitorApi.endpoints.getBatchOrderCounts.useQuery(
        {
            FromDate: null,
            ToDate: null,
            MomCode: null,
            IncrementHours: 0,
            Location: location,
        },
        { pollingInterval: MILLISEC_REFRESH_TIME }
    );

    useNotificationByQueryResponse({
        response: getBatchOrderCountsResponse,
        successFinishedFunction: () => {
            if (getBatchOrderCountsResponse.data?.Data && getBatchOrderCountsResponse.data?.Data.length > 0) {
                setTotalBatchOrderCounts(getBatchOrderCountsResponse.data?.Data[0]);
            }
        },
    });

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <Typography
                    variant="h4"
                    fontWeight="medium"
                    sx={{ fontSize: { xs: '1.25rem', sm: '1.50rem', md: '2.0rem' } }}
                >
                    {location.toUpperCase()}
                </Typography>
            </Box>
            <BatchOrderTotalCountsPanelDetails totalBatchOrderCounts={totalBatchOrderCounts} location={location} />
        </Box>
    );
};
