import { Box } from '@mui/material';
import React from 'react';
import AttachmentItemList from './AttachmentItemList';
import AttachmentItemForm from './AttachmentItemForm';
import AttachmentFileForm from './AttachmentFileForm';
import AttachmentFileList from './AttachmentFileList';

interface WorkOrderAttachmentItemsProps {
    isDisabled: boolean;
}

const WorkOrderAttachmentItems = ({ isDisabled }: WorkOrderAttachmentItemsProps) => {
    return (
        <Box>
            <AttachmentFileForm isDisabled={isDisabled} />
            <AttachmentFileList isDisabled={isDisabled} />
            <AttachmentItemForm isDisabled={isDisabled} />
            <Box sx={{ width: '50%' }}>
                <AttachmentItemList isDisabled={isDisabled} />
            </Box>
        </Box>
    );
};

export default React.memo(WorkOrderAttachmentItems);
