import { createApi } from '@reduxjs/toolkit/query/react';
import { roapiBaseQueryWithReauth } from '../../app/services/roapiBaseQueryWithReauth';
import { GET_APPLICATION_VERSION_URL } from '../../app/services/api_constants';

export default createApi({
    reducerPath: 'versionApi',
    baseQuery: roapiBaseQueryWithReauth,
    endpoints: builder => ({
        getApplicationVersion: builder.query<string, void>({
            query: () => ({
                url: `${GET_APPLICATION_VERSION_URL}`,
                method: 'GET',
                responseHandler: 'text',
            }),
        }),
    }),
});
