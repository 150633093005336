import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { formatDate } from '../../../../common/utils/dateUtil';
import { LocationAssignmentRecord } from '../../serialModels';

const SerialLocationDataGrid = ({ data, fileName }: { data: LocationAssignmentRecord[]; fileName: string }) => {
    const columns: readonly GridColDef<LocationAssignmentRecord>[] = [
        {
            field: 'LocationName',
            headerName: 'Location Name',
            flex: 1,
        },

        {
            field: 'SerialValue',
            headerName: 'Serial Value',
            flex: 1,
        },
        {
            field: 'ShelvedAt',
            headerName: 'Shelved At',
            flex: 1,
            valueGetter: (value, row) => {
                const newDate = formatDate(row.ShelvedAt);
                if (newDate === 'Invalid date') {
                    return 'N/A';
                }
                return newDate;
            },
        },
        {
            field: 'UnshelvedAt',
            headerName: 'Unshelved At',
            flex: 1,
            valueGetter: (value, row) => {
                const newDate = formatDate(row.UnshelvedAt);
                if (newDate === 'Invalid date') {
                    return 'N/A';
                }
                return newDate;
            },
        },
    ];

    return (
        <Box>
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={row => row.Id}
                sx={{ mt: 2 }}
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { csvOptions: { fileName: fileName } } }}
            />
        </Box>
    );
};

export default SerialLocationDataGrid;
