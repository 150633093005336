import { FormControl } from '@mui/base/FormControl';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import orderLookupApi from '../../orderLookupApi';
import { Item, Serial } from '../../orderLookupModels';
import { SerialsUpdateFormTextField } from './SerialsUpdateFormTextField';

export function SerialsUpdateForm({
    itemData,
    serialData,
    handleClose,
}: {
    itemData: Item | undefined;
    serialData: Serial | undefined;
    handleClose: () => void;
}) {
    const [serialValues, setSerialValues] = React.useState<Serial>();
    const [putUpdateSerial, putUpdateSerialResponse] = orderLookupApi.endpoints.putUpdateSerial.useMutation();
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const [serialFieldValidated, setSerialFieldValidated] = React.useState(false);

    const saveSerialsEdits = () => {
        if (serialFieldValidated) {
            putUpdateSerial({
                serials: serialValues,
                momCode: momCode,
                itemId: itemData?.ItemId,
                allowDuplicates: itemData?.AllowSerialDups,
            });
        }
    };

    useNotificationByResponse({
        isFetching: putUpdateSerialResponse.isLoading,
        isError: !putUpdateSerialResponse.data?.Success,
        errorContent: putUpdateSerialResponse.data ? putUpdateSerialResponse.data.Message : '',
        isUninitialized: putUpdateSerialResponse.isUninitialized,
    });

    React.useEffect(() => {
        if (!putUpdateSerialResponse.isUninitialized) {
            if (putUpdateSerialResponse.data?.Success) {
                handleClose();
            }
        }
    }, [putUpdateSerialResponse]);

    return (
        <Box>
            <FormControl>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ flex: 1, m: 2 }}>
                        <SerialsUpdateFormTextField
                            itemData={itemData}
                            serialData={serialData}
                            setFormSerials={setSerialValues}
                            setSerialFieldValidated={setSerialFieldValidated}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button sx={{ flex: 1, m: 0, p: 1 }} color="error" onClick={saveSerialsEdits}>
                        SAVE
                    </Button>
                    <Button sx={{ flex: 1, m: 0, p: 1 }} onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </FormControl>
        </Box>
    );
}
