import {
    GENERATE_PALLET_COUNT_URL,
    GET_PALLETS_FOR_TASK_URL,
    GET_PALLET_FILTERS_URL,
    UPDATE_COUNT_TASK_URL,
    GET_PALLETS_BY_ID_URL,
} from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getPalletCountsApi = async action => {
    const res = await API.get(`${UPDATE_COUNT_TASK_URL}`);
    return res;
};

//takes palletCount id
//returns id, countTaskId, palletTag, location, caseCount, sealedCases, partialQuantity, size, comment, isCompleted
// export const getPalletCountsByIdApi = async action => {
//     const res = await API.get(`${GET_PALLETS_BY_ID_URL}/${action.payload.id}`);
//     return res;
// };

export const getPalletFiltersApi = async action => {
    const res = await API.get(GET_PALLET_FILTERS_URL);
    return res;
};

export const getFilteredPalletApi = async action => {
    const res = await API.post(GET_PALLETS_FOR_TASK_URL, {
        countTaskIds: action.payload,
    });
    return res;
};

export const generatePalletCountApi = async action => {
    const res = await API.post(`${GENERATE_PALLET_COUNT_URL}`, action.payload);
    return res;
};

export const getPalletsByTaskIdApi = async action => {
    const res = await API.post(`${GET_PALLETS_FOR_TASK_URL}`, {
        countTaskIds: action.payload.countTaskId,
    });
    return res;
};

export const updateCountTaskApi = async action => {
    const res = await API.put(UPDATE_COUNT_TASK_URL, action.payload.content);
    return res;
};

export const generatePalletCountWithoutParamsApi = async action => {
    const res = await API.post(GENERATE_PALLET_COUNT_URL);
    return res;
};
