import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrderData, QCLogElement, QCLogPayload, SerialCheckPayload, TransformedItem } from './qualityControlModels';

export interface QualityControlState {
    QCLog: QCLogElement[];
    orderData: OrderData | undefined;
    serial1: SerialCheckPayload;
    serial2: SerialCheckPayload;
    transformedItems: TransformedItem[];
}

const initialState: QualityControlState = {
    QCLog: [],
    orderData: undefined,
    serial1: {
        momCode: '',
        sku: '',
        serialNumber: '',
    },
    serial2: {
        momCode: '',
        sku: '',
        serialNumber: '',
    },
    transformedItems: [],
};

const slice = createSlice({
    name: 'qualityControl',
    initialState,
    reducers: {
        setOrderData: (state, action: PayloadAction<OrderData>) => {
            state.orderData = action.payload;
        },
        appendQCLog: (state, action: PayloadAction<QCLogPayload>) => {
            if (state.QCLog) state.QCLog.push({ ...action.payload, datetime: new Date().toISOString() });
            else state.QCLog = [{ ...action.payload, datetime: new Date().toISOString() }];
        },
        resetQCReducer: (state, action: PayloadAction<void>) => {
            state.QCLog = [];
            state.orderData = undefined; //find way to reset to initial
        },
        setSerial1: (state, action: PayloadAction<SerialCheckPayload>) => {
            state.serial1 = action.payload;
        },
        setSerial2: (state, action: PayloadAction<SerialCheckPayload>) => {
            state.serial2 = action.payload;
        },
        setTransformedItems: (state, action: PayloadAction<TransformedItem[]>) => {
            state.transformedItems = action.payload;
        },
    },
});

export const { setOrderData, appendQCLog, resetQCReducer, setSerial1, setSerial2, setTransformedItems } = slice.actions;

export default slice.reducer;
