import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, Typography } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../app/store';
import orderLookupApi from '../orderLookupApi';
import { BillingShippingDisplay } from './BillingShippingDisplay';
import { ItemsDisplay } from './ItemsDisplay';
import { NotesDisplay } from './NotesDisplay';
import { OrderDataDisplay } from './OrderDataDisplay';
import { ShipmentsDisplay } from './ShipmentsDisplay';
import SpeedDialElement from './SpeedDial/SpeedDialElement';

export function OrderDetailsDisplay({ showResults, companyCode }: { showResults: boolean; companyCode: string }) {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const orderData = getOrderDetailsResponse.data?.Data[0]?.OrderData;

    const OrderStatusChip = () => {
        if (orderData?.Canceled) {
            return <Chip label="Canceled" color="error" />;
        }
        if (orderData?.Completed) {
            return <Chip label="Completed" color="success" />;
        }
        if (orderData?.OnHold) {
            return <Chip label="On Hold" color="warning" />;
        }
        return <Chip label="Pending" color="info" />;
    };

    return (
        <Box sx={{ display: showResults ? 'flex' : 'none', mb: 1, width: 1 }}>
            <Box sx={{ flex: 8 }}>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        aria-controls="order-content"
                        id="order-header"
                        expandIcon={<GridExpandMoreIcon />}
                    >
                        <Grid container justifyContent="flex-start">
                            <Typography variant="h6">
                                ORDER: {companyCode}
                                {orderData?.Order}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="flex-end" sx={{ mr: 4 }}>
                            <OrderStatusChip />
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OrderDataDisplay />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        aria-controls="customer-content"
                        id="customer-header"
                        expandIcon={<GridExpandMoreIcon />}
                    >
                        <Typography variant="h6">CUSTOMER</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BillingShippingDisplay />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        aria-controls="items-content"
                        id="items-header"
                        expandIcon={<GridExpandMoreIcon />}
                    >
                        <Typography variant="h6">LINE ITEMS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ItemsDisplay />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        aria-controls="shipments-content"
                        id="shipments-header"
                        expandIcon={<GridExpandMoreIcon />}
                    >
                        <Typography variant="h6">SHIPMENTS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ShipmentsDisplay />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        aria-controls="notes-content"
                        id="notes-header"
                        expandIcon={<GridExpandMoreIcon />}
                    >
                        <Typography variant="h6">NOTES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <NotesDisplay />
                    </AccordionDetails>
                </Accordion>
            </Box>
            <SpeedDialElement />
        </Box>
    );
}
