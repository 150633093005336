import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button } from '@mui/material';
import { green, red } from '@mui/material/colors';
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/query';
import clearSerialApi from '../clearSerialApi';
import { SerialRecord } from '../clearSerialModels';

const SerialStatusDataGrid = ({
    data,
    onRowDelete,
}: {
    data: SerialRecord[];
    onRowDelete: (serial: SerialRecord) => void;
}) => {
    const getSerialStatusesResponse = clearSerialApi.endpoints.getSerialStatuses.useQuery(
        data.length > 0 ? { Serials: data } : skipToken
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
                <Button
                    onClick={() => {
                        if (!getSerialStatusesResponse.isUninitialized) getSerialStatusesResponse.refetch();
                    }}
                    variant="contained"
                    color="secondary"
                    sx={{ m: 1 }}
                >
                    Refresh Statuses
                </Button>
            </GridToolbarContainer>
        );
    }

    const columns: readonly GridColDef<SerialRecord>[] = [
        {
            field: 'Client',
            headerName: 'Client',
            flex: 1,
        },
        {
            field: 'Sku',
            headerName: 'Sku',
            flex: 2,
        },
        {
            field: 'Serial',
            headerName: 'Serial',
            flex: 2,
        },
        {
            field: 'IsActive',
            headerName: 'IsActive',
            flex: 1,
        },
        {
            field: 'Delete',
            type: 'actions',
            headerName: 'Delete',
            flex: 1,
            getActions: value => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => onRowDelete(value.row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box>
            <DataGrid
                rows={data.length > 0 ? getSerialStatusesResponse?.data?.Serials || [] : []}
                columns={columns}
                getRowId={row => `${row.Client}${row.Sku}${row.Serial}`}
                sx={{
                    mt: 2,
                    minHeight: 300,
                    '& .true': { backgroundColor: red['A100'] },
                    '& .false': { backgroundColor: green['A100'] },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'inherit',
                    },
                }}
                density="compact"
                slots={{ toolbar: CustomToolbar }}
                getRowClassName={params => String(params.row.IsActive)}
                slotProps={{ toolbar: { csvOptions: { fileName: 'serialStatuses' } } }}
                disableRowSelectionOnClick
            />
        </Box>
    );
};

export default SerialStatusDataGrid;
