import { FetchBaseQueryError, createApi } from '@reduxjs/toolkit/query/react';
import {
    ABORT_QC_URL_POSTFIX,
    COMPLETE_QC_URL_POSTFIX,
    GET_QC_ORDER_DATA_URL,
    GET_QC_ORDER_STATUS_URL_POSTFIX,
    GET_QC_ORDER_STATUS_URL_PREFIX,
    GET_QC_SERIAL_CHECK_URL,
    PARTIAL_SUBMIT_QC_URL,
    PAUSE_QC_URL_POSTFIX,
    RESET_QC_URL_POSTFIX,
    START_QC_URL_POSTFIX,
    SUBMIT_QC_URL,
} from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';
import {
    OrderData,
    PartialSubmitPayload,
    QCSerialCheckResult,
    STATUS_VALUE,
    SerialCheckPayload,
    SubmitPayload,
} from './qualityControlModels';

const createQCStatusUpdateBody: any = () => {
    // const id = useAppSelector(state => state.authentication.authenticationResponse?.userInfo.id);

    return {
        userId: 0, //TODO: need to get userId as argument
        system: 'n/a',
        qcVersion: 'n/a',
        force: true,
    };
};

export default createApi({
    reducerPath: 'qualityControlApi',
    baseQuery: dreamBaseQueryWithReauth,
    endpoints: builder => ({
        getOrderStatus: builder.query<STATUS_VALUE, string>({
            query: invoice => ({
                url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${GET_QC_ORDER_STATUS_URL_POSTFIX}`,
                method: 'GET',
            }),
        }),
        getOrderData: builder.query<OrderData, string>({
            query: invoice => ({
                url: `${GET_QC_ORDER_DATA_URL}${invoice}`,
                method: 'GET',
            }),
        }),
        postPartialSubmit: builder.mutation<void, Partial<PartialSubmitPayload>>({
            query: body => ({
                url: `${PARTIAL_SUBMIT_QC_URL}`,
                method: 'POST',
                body,
            }),
        }),
        postFullSubmit: builder.mutation<void, Partial<SubmitPayload>>({
            query: body => ({
                url: `${SUBMIT_QC_URL}`,
                method: 'POST',
                body,
            }),
        }),
        getSerialCheck: builder.query<QCSerialCheckResult, SerialCheckPayload>({
            query: body => ({
                url: `${GET_QC_SERIAL_CHECK_URL}`,
                method: 'GET',
                params: body,
            }),
        }),
        putStartState: builder.mutation<void, Partial<string>>({
            query: invoice => ({
                url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${START_QC_URL_POSTFIX}`,
                method: 'PUT',
                body: createQCStatusUpdateBody(),
            }),
        }),
        putCompleteState: builder.mutation<void, Partial<string>>({
            query: invoice => ({
                url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${COMPLETE_QC_URL_POSTFIX}`,
                method: 'PUT',
                body: createQCStatusUpdateBody(),
            }),
        }),
        putAbortState: builder.mutation<void, Partial<string>>({
            query: invoice => ({
                url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${ABORT_QC_URL_POSTFIX}`,
                method: 'PUT',
                body: createQCStatusUpdateBody(),
            }),
        }),
        putPauseState: builder.mutation<void, Partial<string>>({
            query: invoice => ({
                url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${PAUSE_QC_URL_POSTFIX}`,
                method: 'PUT',
                body: createQCStatusUpdateBody(),
            }),
        }),
        putResetState: builder.mutation<void, Partial<string>>({
            query: invoice => ({
                url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${RESET_QC_URL_POSTFIX}`,
                method: 'PUT',
                body: createQCStatusUpdateBody(),
            }),
        }),
        putAbortThenResetState: builder.mutation<null, Partial<string>>({
            async queryFn(invoice, _queryApi, _extraOptions, fetchWithBQ) {
                const fetchAbortResult = await fetchWithBQ({
                    url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${ABORT_QC_URL_POSTFIX}`,
                    method: 'PUT',
                    body: createQCStatusUpdateBody(),
                });
                // if (fetchAbortResult.error) return { error: fetchAbortResult.error as FetchBaseQueryError };
                const result = await fetchWithBQ({
                    url: `${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${RESET_QC_URL_POSTFIX}`,
                    method: 'PUT',
                    body: createQCStatusUpdateBody(),
                });

                return result?.error ? { error: result.error as FetchBaseQueryError } : { data: null };
            },
        }),
    }),
});
