import { Box, Button, Grid, Tooltip } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../app/store';
import { formatDayjsDate } from '../../../common/utils/dateUtil';
import orderLookupApi from '../orderLookupApi';
import { HistoryDialog } from './History/HistoryDialog';
import CommercialInvoicePdfMenu from './Invoice/CommercialInvoicePdfMenu';
import { ReadOnlyTextField } from './ReadOnlyTextField';
export function OrderDataDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);

    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const getHistoryOrderResponse = orderLookupApi.endpoints.getHistoryOrder.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const historyOrderData = getHistoryOrderResponse.data?.Data[0];
    const orderData = getOrderDetailsResponse.data?.Data[0]?.OrderData;

    const [historyMetadatasDialogOpen, setHistoryMetadatasDialogOpen] = React.useState(false);

    return (
        <React.Fragment>
            <HistoryDialog showDialog={historyMetadatasDialogOpen} handleOpenDialog={setHistoryMetadatasDialogOpen} />
            <Grid container spacing={2} direction="row" sx={{ p: 1 }}>
                <Grid item xs={4}>
                    <ReadOnlyTextField label={'Order Date'} value={formatDayjsDate(orderData?.OrderDate)} />
                    <ReadOnlyTextField label={'Ship Code'} value={orderData?.ShipList} />
                    <ReadOnlyTextField
                        label={'IMPORTED TO MOM'}
                        value={formatDayjsDate(orderData?.TimeImportedToMom)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReadOnlyTextField label={'Alt Order'} value={orderData?.AltOrder} />
                    <ReadOnlyTextField label={'Ponumber'} value={orderData?.PoNumber} />
                </Grid>
                <Grid item xs={4}>
                    <ReadOnlyTextField label={'Source Code'} value={orderData?.AdCode} />
                    <ReadOnlyTextField label={'Internetid'} value={orderData?.InternetId} />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end" height="%100">
                <Tooltip title={!!!historyOrderData ? 'No DB Records Found' : ''} placement="top-start">
                    <span>
                        {/* Added span to have Tooltip work for disabled Button */}
                        <Button
                            size="small"
                            disabled={!!!historyOrderData}
                            onClick={() => {
                                setHistoryMetadatasDialogOpen(true);
                            }}
                        >
                            RAW DB RECORDS
                        </Button>
                    </span>
                </Tooltip>
                <CommercialInvoicePdfMenu />
            </Box>
        </React.Fragment>
    );
}
