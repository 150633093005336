import { Button, Card, TextField } from '@mui/material';
import React from 'react';
import useSound from 'use-sound';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import errorSound from '../../../../common/assets/audio/error.mp3';
import useMutationResponse from '../../../../common/hooks/useMutationResponse';
import useQueryResponse from '../../../../common/hooks/useQueryResponse';
import { parseTwoDimensionalBarcode } from '../../../../common/utils/twoDimentialBarcodeParser';
import { getClient } from '../../../../common/utils/typedCommonUtils';
import { setDialog } from '../../../notification/notificationSlice';
import { FRONT_END_VERSION, PROJECT_NAME } from '../../../version/version';
import serializedBatchShippingApi from '../../serializedBatchShippingApi';
import { Item, OrderData, QCLog, SubmitForm } from '../../serializedBatchShippingModels';
import ParsedBarcodeDisplay from './ParsedBarcodeDisplay';

const LENGTH_OF_UPC = 13;

const ItemForm = ({
    form,
    setForm,
    orderData,
    onValidSubmission,
    qcLog,
    setQcLog,
}: {
    form: SubmitForm;
    setForm: React.Dispatch<React.SetStateAction<SubmitForm>>;
    orderData: OrderData;
    onValidSubmission: () => void;
    qcLog: QCLog[];
    setQcLog: React.Dispatch<React.SetStateAction<QCLog[]>>;
}) => {
    const [itemBarcodeScan, setItemBarcodeScan] = React.useState('');
    const client = getClient(form.PackageId);
    const userId = useAppSelector(state => state.authentication.authenticationResponse?.userInfo.id) || -1;
    const username = useAppSelector(state => state.authentication.username);
    const parsedBarcode = parseTwoDimensionalBarcode(client, itemBarcodeScan);

    const [
        triggerGetIsSerialValid,
        getIsSerialValidResponse,
    ] = serializedBatchShippingApi.endpoints.getIsSerialValid.useLazyQuery();
    const [triggerSubmitQc, submitQcResponse] = serializedBatchShippingApi.endpoints.submitQualityControl.useMutation();
    const [
        triggerCompleteQc,
        completeQcResponse,
    ] = serializedBatchShippingApi.endpoints.completeQualityControl.useMutation();

    useQueryResponse({
        response: getIsSerialValidResponse,
        errorMessage: 'Check Serial',
    });

    useMutationResponse({
        response: submitQcResponse,
        errorMessage: 'Submit QC',
        shouldPlaySuccessAudio: false,
        onSuccess: () => {
            triggerCompleteQc({
                PackageId: orderData.PackageId.PkgId,
                UserId: userId,
            });
        },
    });

    useMutationResponse({
        response: completeQcResponse,
        errorMessage: 'Complete QC',
        successMessage: `Successfully Completed: ${form.PackageId}`,
        onSuccess: () => {
            onValidSubmission();
        },
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!parsedBarcode.Sku) {
            handleScanError('Sku required', 'Sku was not provided');
            return;
        }

        if (!parsedBarcode.Serial) {
            handleScanError('Serial required', 'Serial was not provided');
            return;
        }

        const scannedItem = orderData.Items.find(isParsedBarcodeOnOrder);

        if (!scannedItem) {
            handleScanError(
                `Item not on order. \nUPC: ${parsedBarcode.Upc} \nSKU: ${parsedBarcode.Sku}`,
                '2D Scan- Item not on order'
            );
            return;
        }

        const regexToTest = scannedItem.SerialVerify.find(verify => verify.ScanOrder === 0)?.Regex;

        if (regexToTest) {
            const serialRegex = new RegExp(regexToTest);
            const isSerialMatchingRegex = serialRegex.test(parsedBarcode.Serial);

            if (!isSerialMatchingRegex) {
                handleScanError(
                    `Invalid Serial Pattern \nSerial: ${parsedBarcode.Serial}`,
                    '2D Scan- Invalid serial pattern'
                );
                return;
            }
        }

        const hasSerialBeenUsed = (
            await triggerGetIsSerialValid({
                MomCode: orderData.PackageId.Mom,
                Sku: parsedBarcode.Sku,
                SerialNumber: parsedBarcode.Serial,
            })
        )?.data;

        if (!hasSerialBeenUsed) {
            handleScanError(`Serial already used\n${parsedBarcode.Serial}`, '2D Scan- Serial already used');
            return;
        }

        await triggerSubmitQc({
            Client: orderData.PackageId.Mom,
            Order: orderData.PackageId.OrderNum,
            Inpart: orderData.PackageId.Inpart,
            System: PROJECT_NAME,
            Version: FRONT_END_VERSION,
            User: username,
            Message: `${orderData.PackageId.PkgId} QC Completed by ${username} at ${new Date().toLocaleString()}`,
            ItemId: orderData.Items.map(item => item.ItemId),
            Serials: [
                {
                    Id: scannedItem.ItemId,
                    Item: scannedItem.Sku,
                    Serial1: parsedBarcode.Serial,
                    Serial2: parsedBarcode.Serial2 || '',
                },
            ],
            Qclog: [
                ...qcLog,
                {
                    Datetime: new Date().toISOString(),
                    ScanQty: 1,
                    Upc: parsedBarcode?.Upc,
                    Serial: parsedBarcode?.Serial,
                    Error: false,
                    Action: '2D UPC/Serial Scan',
                },
                {
                    Datetime: new Date().toISOString(),
                    ScanQty: 0,
                    Error: false,
                    Action: 'Order Scan Completed',
                },
            ],
        });
    };

    const dispatch = useAppDispatch();
    const [playErrorSound] = useSound(errorSound);

    const handleScanError = (dialogText: string, qcLogAction: string) => {
        handleTextChange('');
        playErrorSound();
        dispatch(setDialog({ title: 'Error', content: dialogText }));

        setQcLog(prevQcLog => [
            ...prevQcLog,
            {
                Datetime: new Date().toISOString(),
                ScanQty: 1,
                Upc: parsedBarcode?.Upc,
                Serial: parsedBarcode?.Serial,
                Error: true,
                Action: qcLogAction,
            },
        ]);
    };

    const isParsedBarcodeOnOrder = (item: Item): boolean => {
        const orderDataSku = removeLocationDataOnSku(item.Sku)?.toUpperCase();
        const scannedSku = parsedBarcode.Sku?.trim()?.toUpperCase() || '';

        const orderDataUpc = item.Upc?.toUpperCase();
        const scannedUpc = removeLeadingZeroFromUpc(parsedBarcode.Upc?.toUpperCase() || '');

        const isSkuValid = orderDataSku === scannedSku;

        const isUpcValid = orderDataUpc === scannedUpc;

        return isSkuValid && isUpcValid;
    };

    const removeLocationDataOnSku = (inputSku: string): string => {
        const isVariantSku = inputSku.length >= 2 && inputSku.charAt(inputSku.length - 2) === '-';
        if (isVariantSku) return inputSku.slice(0, -2);
        return inputSku;
    };

    const removeLeadingZeroFromUpc = (inputUpc: string): string => {
        const hasExtraLeadingZero = inputUpc.length === LENGTH_OF_UPC + 1 && inputUpc.charAt(0) === '0';
        if (hasExtraLeadingZero) return inputUpc?.slice(1);
        return inputUpc;
    };

    const handleTextChange = (newValue: string) => {
        const parsedBarcode = parseTwoDimensionalBarcode(client, newValue);
        setItemBarcodeScan(newValue);
        setForm(oldForm => {
            return {
                ...oldForm,
                Sku: parsedBarcode.Sku || '',
                Serial: parsedBarcode.Serial || '',
            };
        });
    };

    //TODO: button to abort, then reset qc
    return (
        <form onSubmit={handleSubmit}>
            <Card sx={{ p: 3 }}>
                <TextField
                    autoFocus
                    label={'Scan Item Barcode'}
                    value={itemBarcodeScan}
                    onChange={e => {
                        const newValue = e.target.value;
                        handleTextChange(newValue);
                    }}
                    style={{ margin: 5, width: '100%' }}
                    name="ItemBarcode"
                />

                <ParsedBarcodeDisplay parsedBarcode={parsedBarcode} />

                <Button sx={{ width: '100%' }} type="submit" variant="contained">
                    Submit
                </Button>
            </Card>
        </form>
    );
};

export default ItemForm;
