import { GET_DASHBOARD_WAREHOUSE_URL, GET_ORDER_BY_WAREHOUSE_URL } from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getOrderbyWarehouseApi = async action => {
    const res = await API.get(`${GET_ORDER_BY_WAREHOUSE_URL}/${action.payload.warehouse}`);
    return res;
};

export const getDashboardWarehouseApi = async action => {
    const res = await API.get(GET_DASHBOARD_WAREHOUSE_URL);
    return res;
};
