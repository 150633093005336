import { Box, Card, Paper, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import CompanyDropdownV3 from '../../../../common/components/dropdown/CompanyDropdownV3';
import { SerialFilter } from '../../clearSerialModels';
import BarcodeForm from './BarcodeForm';
import CsvForm from './CsvForm';
import SkuForm from './SkuForm';
import UpcForm from './UpcForm';

const ClearSerialForm = ({ onSubmit }: { onSubmit: (serial: SerialFilter[]) => void }) => {
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const [client, setClient] = React.useState('');

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <CompanyDropdownV3
                value={client}
                onChange={(e, company) => {
                    setClient(company?.mom || '');
                }}
                textFieldProps={{ label: 'Client', required: true, variant: 'filled' }}
                sx={{ flex: 2, mx: 1 }}
            />

            <Paper sx={{ mt: 2 }}>
                <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
                    <Tab label="2D Barcode" />
                    <Tab label="Upc" />
                    <Tab label="Sku" />
                    <Tab label="CSV" />
                </Tabs>

                <Box sx={{ p: 2 }}>
                    {client ? (
                        <Box>
                            {tabIndex === 0 && <BarcodeForm client={client} onSubmit={onSubmit} />}
                            {tabIndex === 1 && <UpcForm client={client} onSubmit={onSubmit} />}
                            {tabIndex === 2 && <SkuForm client={client} onSubmit={onSubmit} />}
                            {tabIndex === 3 && <CsvForm client={client} onSubmit={onSubmit} />}
                        </Box>
                    ) : (
                        <Typography>Client selection required</Typography>
                    )}
                </Box>
            </Paper>
        </Card>
    );
};

export default ClearSerialForm;
