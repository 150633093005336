import { Box } from '@mui/material';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useNotificationByQueryResponse from '../../../../common/hooks/useNotificationByQueryResponse';
import workOrderApi from '../../workOrderApi';
import WorkOrderDataGrid from './WorkOrderDataGrid';
import WorkOrderFilter from './WorkOrderFilter';

interface ViewWorkOrdersProps {}

const ViewWorkOrders = ({}: ViewWorkOrdersProps) => {
    const [triggerGetWorkOrders, getWorkOrdersResponse] = workOrderApi.endpoints.getWorkOrders.useLazyQuery();
    const [
        shouldViewWorkOrderOnSearchWithSingleResult,
        setShouldViewWorkOrderOnSearchWithSingleResult,
    ] = React.useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleFetchWorkOrdersDone = () => {
        const filterResultHasSingleResult = getWorkOrdersResponse.data?.length === 1;
        if (
            shouldViewWorkOrderOnSearchWithSingleResult &&
            filterResultHasSingleResult &&
            getWorkOrdersResponse?.data?.[0].Id
        ) {
            navigate({
                pathname: `/workOrder/${getWorkOrdersResponse.data[0].Id}`,
                search: searchParams.toString(),
            });
            setShouldViewWorkOrderOnSearchWithSingleResult(false);
        }
    };

    useNotificationByQueryResponse({
        response: getWorkOrdersResponse,
        errorMessage: 'Could not fetch work orders',
        finishedFunction: handleFetchWorkOrdersDone,
        shouldUseGlobalSpinner: true,
    });

    return (
        <Box sx={{ backgroundColor: '#fff', p: 3 }}>
            <WorkOrderFilter
                triggerGetWorkOrders={triggerGetWorkOrders}
                handleFilterButtonClick={() => {
                    setShouldViewWorkOrderOnSearchWithSingleResult(true);
                }}
            />
            <WorkOrderDataGrid data={getWorkOrdersResponse.data || []} />
        </Box>
    );
};

export default React.memo(ViewWorkOrders);
