import { Button, Card, TextField } from '@mui/material';
import React from 'react';
import useSound from 'use-sound';
import { useAppDispatch } from '../../../app/store';
import errorSound from '../../../common/assets/audio/error.mp3';
import useQueryResponse from '../../../common/hooks/useQueryResponse';
import { parseTrackingNumberV2 } from '../../../common/utils/typedCommonUtils';
import { setDialog } from '../../notification/notificationSlice';
import serializedBatchShippingApi from '../serializedBatchShippingApi';
import { QualityControlStatusResponse, SubmitForm } from '../serializedBatchShippingModels';

const STARTABLE_STATUSES: QualityControlStatusResponse[] = ['NotStarted', 'Paused', 'Invalid'];

const TrackingForm = ({
    form,
    setForm,
    onTrackingIsValid,
    trackingInputRef,
}: {
    form: SubmitForm;
    setForm: React.Dispatch<React.SetStateAction<SubmitForm>>;
    onTrackingIsValid: (packageId: string) => void;
    trackingInputRef: React.MutableRefObject<any>;
}) => {
    const [
        triggerGetPackageId,
        getPackageIdResponse,
    ] = serializedBatchShippingApi.endpoints.getPackageId.useLazyQuery();

    const [triggerGetStatus, getStatusResponse] = serializedBatchShippingApi.endpoints.getOrderStatus.useLazyQuery();
    const dispatch = useAppDispatch();

    useQueryResponse({
        response: getPackageIdResponse,
        errorMessage: 'Get PackageId Error',
        notFoundErrorMessage: 'No matching PackageId found for this tracking number',
    });

    useQueryResponse({
        response: getStatusResponse,
        errorMessage: 'Get Status Error',
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const parsedTrackingNumber =
            process.env.REACT_APP_MODE === 'dev' && true ? form.Tracking : parseTrackingNumberV2(form.Tracking);
        if (!parsedTrackingNumber) {
            handleScanError(`Invalid tracking barcode scanned`);
            return;
        }

        setForm(oldForm => {
            return { ...oldForm, Tracking: parsedTrackingNumber };
        });

        if (!form.Tracking) {
            handleScanError(`Tracking textfield required`);
            return;
        }

        const packageIdResponse = (
            await triggerGetPackageId({
                TrackingNumber: parsedTrackingNumber,
            })
        ).data;

        const packageId = packageIdResponse?.PkgId?.PkgId;

        if (!packageIdResponse || !packageId) {
            handleScanError(`No matching package id found`);
            return;
        }

        setForm(prevForm => {
            return {
                ...prevForm,
                PackageId: packageId,
                ParsedTracking: parsedTrackingNumber,
            };
        });

        const statusResponse = (
            await triggerGetStatus({
                PackageId: packageId,
            })
        )?.data;

        if (!statusResponse) {
            handleScanError(`Could not get QC status`);
            return;
        }

        if (STARTABLE_STATUSES.includes(statusResponse)) {
            onTrackingIsValid(packageId);
        } else {
            if (statusResponse === 'Complete') handleScanError(`Order already completed.\n${packageId}`);
            else if (statusResponse === 'InProgress')
                handleScanError(`QC already in progress on another device.\n${packageId}`);
            else if (statusResponse === 'Aborted')
                handleScanError(`QC was aborted. Order must be reset before continuing.\n${packageId}`);
            else handleScanError(`Can not start from (${statusResponse}) QC status `);
        }
    };

    const [playErrorSound] = useSound(errorSound);

    const handleScanError = (dialogText: string) => {
        dispatch(setDialog({ title: 'Error', content: dialogText }));
        playErrorSound();
        setForm(oldForm => {
            return { ...oldForm, Tracking: '' };
        });
    };

    const getCaption = () => {
        if (getPackageIdResponse?.data && getStatusResponse.data)
            return `${getPackageIdResponse?.data?.PkgId.PkgId} (${form.ParsedTracking})- ${getStatusResponse.data}`;

        return '';
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card sx={{ display: 'flex' }}>
                <TextField
                    autoFocus
                    label={'Scan Tracking #'}
                    value={form.Tracking}
                    onChange={event => {
                        const newValue = event.target.value;
                        setForm(oldForm => {
                            return { ...oldForm, Tracking: newValue };
                        });
                    }}
                    sx={{ flex: 1, m: 3 }}
                    helperText={getCaption()}
                    ref={trackingInputRef}
                    name="trackingNumber"
                />

                <Button sx={{ m: 4 }} variant="contained" type="submit">
                    Continue
                </Button>
            </Card>
        </form>
    );
};

export default TrackingForm;
