import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router';
import ProcedureApi from '../../ProcedureApi';
import ActiveSession from './ActiveSession';
import BeginSession from './BeginSession';

const SessionStatus = () => {
    const { WorkOrderProcedureId } = useParams();
    const castedWorkOrderProcedureId = WorkOrderProcedureId ? Number(WorkOrderProcedureId) : null;
    const getActiveSessionResponse = ProcedureApi.endpoints.getActiveSession.useQuery(
        castedWorkOrderProcedureId ? castedWorkOrderProcedureId : skipToken
    );

    if (getActiveSessionResponse.data) {
        return <ActiveSession />;
    } else {
        return <BeginSession />;
    }
};

export default SessionStatus;
