import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useAppSelector } from '../../../../app/store';
import workOrderApi from '../../workOrderApi';

interface BillingSummaryProps {}

const BillingSummary = ({}: BillingSummaryProps) => {
    const billingItems = useAppSelector(state => state.workOrder.form.BillingItems);
    const getServicesResponse = workOrderApi.endpoints.getServices.useQuery();

    return (
        <Accordion sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Billing Summary</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Service</TableCell>
                                <TableCell align="right">Units</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getServicesResponse?.data?.map(row => (
                                <TableRow
                                    key={row.Description + row.Code}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {`${row.Description} (${row.Code})`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {billingItems
                                            .filter(item => item.Service === row.Code)
                                            .reduce((accumulator, currentValue) => {
                                                if (currentValue.NoCharge) return accumulator;
                                                if (currentValue.Rush) return accumulator + currentValue.Duration * 1.5;
                                                return accumulator + currentValue.Duration;
                                            }, 0)}
                                    </TableCell>
                                    {/* accumulate duration for each  */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default BillingSummary;
