import { Box, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../app/store';
import CompanyDropdownV3 from '../../../../../common/components/dropdown/CompanyDropdownV3';
import orderLookupApi from '../../../../orderLookup/orderLookupApi';
import textFieldFocus from '../../../../rapidQc/utils/textFieldFocus';
import { ReceivedItem } from '../../../rmaModels';
import { setRma } from '../../../rmaSlice';
import { filterKeyDownForTracking, parseTrackingNumber, submitOnEnterKeyDown } from '../../../utilities';

export const CreateRmaInfoForm = ({ setAddedItems }: { setAddedItems: (items: ReceivedItem[]) => void }) => {
    const rma = useAppSelector(state => state.rma.rma);
    const dispatch = useDispatch();
    const trackingTextfieldRef = useRef<HTMLInputElement>(null);
    const [trackingNumber, setTrackingNumber] = React.useState(
        !!rma?.TrackingNumbers && rma.TrackingNumbers.length > 0 ? rma.TrackingNumbers[0] : ''
    );

    const helperText = !trackingNumber ? 'Scan to find the original outbound order' : '';

    const [
        triggerGetOrderDetails,
        getOrderDetailsResponse,
    ] = orderLookupApi.endpoints.getOrderDetailsByTracking.useLazyQuery();

    const handleTrackingNumberInput = () => {
        if (!rma?.Client || !trackingNumber) {
            return;
        }
        const parsedTracking = parseTrackingNumber(trackingNumber.trim());
        setTrackingNumber(parsedTracking);
        triggerGetOrderDetails({ momCode: rma.Client, trackingNumber: parsedTracking });
    };

    const handleClientSelect = async (momCode: string | null | undefined) => {
        if (!!rma) {
            await dispatch(setRma({ ...rma, Client: momCode, TrackingNumbers: [], OriginalOrderDetails: null }));
            setAddedItems([]);
            setTrackingNumber('');
            textFieldFocus({ textFieldRef: trackingTextfieldRef });
        }
    };

    React.useEffect(() => {
        if (!getOrderDetailsResponse.isUninitialized && !getOrderDetailsResponse.isFetching && !!rma) {
            const originalOrderDetails =
                getOrderDetailsResponse.data?.Success && getOrderDetailsResponse.data.Data.length > 0
                    ? getOrderDetailsResponse.data.Data[0]
                    : null;
            dispatch(
                setRma({
                    ...rma,
                    OriginalOrderDetails: originalOrderDetails,
                    TrackingNumbers: [trackingNumber],
                })
            );
        }
    }, [getOrderDetailsResponse]);

    return (
        <Box sx={{ display: 'flex', pt: 2 }}>
            <CompanyDropdownV3
                value={rma?.Client || ''}
                textFieldProps={{ label: 'Client' }}
                sx={{ flex: 3, mr: 1 }}
                onChange={(event, company) => {
                    handleClientSelect(company?.mom);
                }}
            />

            <TextField
                inputRef={trackingTextfieldRef}
                helperText={helperText}
                disabled={!rma?.Client}
                sx={{ flex: 7 }}
                label="Scan Tracking Number (Optional)"
                value={trackingNumber}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    filterKeyDownForTracking(event, trackingNumber, setTrackingNumber);
                    submitOnEnterKeyDown(event, handleTrackingNumberInput);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTrackingNumber(event.target.value.trim());
                }}
            />
        </Box>
    );
};
