import {
    COMPLETE_WORK_ORDER_URL,
    DELETE_WORK_ORDER_URL,
    GET_DETAILED_WORK_ORDER_URL,
    GET_END_OF_MONTH_REPORT_URL,
    GET_TEMPLATE_TYPE_URL,
    GET_WORK_ORDERS_URL,
    POST_WORK_ORDER_URL,
    WORK_ORDER_BILL_OF_MATERIALS_URL,
    WORK_ORDER_SERVICES_URL,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import {
    decrementIsSpinnerOpen,
    incrementIsSpinnerOpen,
    updateNotificationByResponse,
} from '../notification/notificationSlice';
import { UNASSIGNED_TEMPLATE_TYPE_ID } from './constants';
import { setToTemplatesTab, setToWorkOrderTab } from './util';
import {
    BillOfMaterialsOptionRecord,
    CompletedWorkOrdersByClient,
    Filter,
    GetDetailedWorkOrderRequest,
    GetEomReportRequest,
    PutWorkOrderPayload,
    ServiceRecord,
    SummarizedWorkOrderData,
    TemplateType,
    UpdateWorkOrderStatePayload,
    WorkOrderData,
} from './workOrderModels';
import { setForm } from './workOrderSlice';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['WorkOrderViewList', 'WorkOrderIndividual'] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getWorkOrders: builder.query<SummarizedWorkOrderData[], Filter>({
            query: param => ({
                url: prependDreamUrl(GET_WORK_ORDERS_URL),
                method: 'GET',
                params: param,
            }),
            providesTags: ['WorkOrderViewList'],
        }),
        downloadAttachmentFile: builder.mutation({
            queryFn: async ({ id, name }, { dispatch }, extraOptions, baseQuery) => {
                dispatch(incrementIsSpinnerOpen());

                const result = await baseQuery({
                    url: prependDreamUrl(`/api/workorder/attachmentFile?id=${id}`),
                    responseHandler: response => response.json(),
                });

                dispatch(decrementIsSpinnerOpen());

                var hiddenElement = document.createElement('a');
                hiddenElement.href = result.data as string;
                hiddenElement.target = '_blank';
                hiddenElement.download = name;
                hiddenElement.click();
                return { data: null };
            },
        }),
        getDetailedWorkOrder: builder.query<WorkOrderData, GetDetailedWorkOrderRequest>({
            query: param => ({
                url: prependDreamUrl(GET_DETAILED_WORK_ORDER_URL),
                method: 'GET',
                params: param,
            }),
            providesTags: ['WorkOrderIndividual'],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                // `onStart` side-effect
                try {
                    const { data } = await queryFulfilled;
                    // `onSuccess` side-effect
                    const isTemplate = data.TemplateTypeId !== UNASSIGNED_TEMPLATE_TYPE_ID;
                    if (isTemplate) {
                        setToTemplatesTab(dispatch);
                    } else {
                        setToWorkOrderTab(dispatch);
                    }

                    dispatch(setForm(data));
                    dispatch(
                        updateNotificationByResponse({
                            isFetching: false,
                            isError: false,
                            errorContent: 'error',
                        })
                    );
                } catch (err) {
                    // `onError` side-effect
                    dispatch(
                        updateNotificationByResponse({
                            isFetching: false,
                            isError: true,
                            errorContent: 'Failed to retrieve Work Order details',
                            isSuccess: false,
                            successContent: 'success',
                        })
                    );
                }
            },
        }),
        getServices: builder.query<ServiceRecord[], void>({
            query: () => ({
                url: prependDreamUrl(WORK_ORDER_SERVICES_URL),
                method: 'GET',
            }),
        }),
        getBillOfMaterialsOptions: builder.query<BillOfMaterialsOptionRecord[], void>({
            query: () => ({
                url: prependDreamUrl(WORK_ORDER_BILL_OF_MATERIALS_URL),
                method: 'GET',
            }),
        }),
        postWorkOrder: builder.mutation<number, Partial<PutWorkOrderPayload>>({
            query: param => ({
                url: prependDreamUrl(POST_WORK_ORDER_URL),
                method: 'POST',
                body: {
                    WorkOrderData: param.Data,
                    User: param.Username,
                },
            }),
            invalidatesTags: ['WorkOrderViewList', 'WorkOrderIndividual'],
        }),
        completeWorkOrder: builder.mutation<void, Partial<UpdateWorkOrderStatePayload>>({
            query: param => ({
                url: prependDreamUrl(COMPLETE_WORK_ORDER_URL),
                method: 'POST',
                body: {
                    Id: param.Id,
                    CompletedBy: param.Username,
                },
            }),
            invalidatesTags: ['WorkOrderViewList'],
        }),
        deleteWorkOrder: builder.mutation<void, Partial<UpdateWorkOrderStatePayload>>({
            query: param => ({
                url: prependDreamUrl(DELETE_WORK_ORDER_URL),
                method: 'DELETE',
                body: {
                    Id: param.Id,
                    DeletedBy: param.Username,
                },
            }),
            invalidatesTags: ['WorkOrderViewList'],
        }),
        getPdf: builder.query<any, number>({
            query: param => ({
                url: prependDreamUrl(`/api/workorder?id=${param}`),
                responseHandler: response => response.blob(),
            }),
        }),
        getEndOfMonthReport: builder.query<CompletedWorkOrdersByClient[], GetEomReportRequest>({
            query: param => ({
                url: prependDreamUrl(GET_END_OF_MONTH_REPORT_URL),
                method: 'GET',
                params: param,
            }),
        }),
        getTemplateTypes: builder.query<TemplateType[], void>({
            query: param => ({
                url: prependDreamUrl(GET_TEMPLATE_TYPE_URL),
                method: 'GET',
            }),
        }),
    }),
});
