import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { isRmaCompleted } from '../../utilities';
import { ConfirmClosingDialog } from '../ConfirmClosingDialog';
import { RmaIdDisplay } from '../RmaIdDisplay';
import { ExpectedItemsDisplay } from './ExpectedItemsDisplay';

export const ExpectedItemsDialog = React.memo(
    ({
        handleOpenDialog,
        showDialog = false,
    }: {
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const [confirmCloseDialog, setConfirmCloseDialog] = React.useState(false);
        const expectedItemsTitle = isRmaCompleted(rma?.Status) ? 'PROCESSED ITEMS' : 'EXPECTED NUMBER OF ITEMS';

        return (
            <React.Fragment>
                <ConfirmClosingDialog
                    showDialog={confirmCloseDialog}
                    handleOpenDialog={setConfirmCloseDialog}
                    setPreviousDialog={handleOpenDialog}
                />
                <Dialog
                    maxWidth="md"
                    open={showDialog}
                    PaperProps={{
                        sx: { backgroundColor: grey[100] },
                    }}
                >
                    <DialogTitle color="white" bgcolor="primary.main" align="center">
                        <Typography fontWeight={'fontWeightMedium'} variant="h5">
                            {expectedItemsTitle}
                        </Typography>
                        <Box sx={{ justifyContent: 'space-between', display: 'flex', mt: 2 }}>
                            <Typography variant="h6">CLIENT: {rma?.Client}</Typography>
                            <RmaIdDisplay />
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                            <ExpectedItemsDisplay />
                        </Box>
                    </DialogContent>
                    <DialogCloseButton
                        handleClose={() => {
                            if (isRmaCompleted(rma?.Status)) {
                                handleOpenDialog(false);
                            } else {
                                setConfirmCloseDialog(true);
                            }
                        }}
                    />
                </Dialog>
            </React.Fragment>
        );
    }
);
