import { Box, List, ListItem, ListItemText, Paper } from '@mui/material';
import React from 'react';
import { SHIP_METHOD_COLORS } from '../../constants';
import shipMatrixApi from '../../shipMatrixApi';
import { blueGrey } from '@mui/material/colors';

const ColoredListItem = ({ name, color }: { name: string; color: string }) => {
    return (
        <ListItem key={name} sx={{ width: '100%', px: 1, py: 0 }}>
            <ListItemText
                primary={name}
                sx={{
                    color: '#1a3e72',
                    backgroundColor: color,
                    width: '100%',
                    p: 1,
                    m: 0,
                }}
            />
        </ListItem>
    );
};

const ShipMatrixLegend = () => {
    const getShipmethodsResponse = shipMatrixApi.endpoints.getShipMethods.useQuery();

    return (
        <Box>
            <Paper elevation={5} sx={{ m: 5, flex: 1, display: 'block', overflow: 'auto' }}>
                {getShipmethodsResponse.data && (
                    <List>
                        <ColoredListItem name={'Selected'} color={blueGrey['A100']} />

                        {getShipmethodsResponse.data?.Data.map((shipMethod, index) => (
                            <ColoredListItem
                                name={shipMethod.Name}
                                color={SHIP_METHOD_COLORS[index]}
                                key={shipMethod.Name}
                            />
                        ))}
                    </List>
                )}
            </Paper>
        </Box>
    );
};

export default ShipMatrixLegend;
