import {
    GET_SHIP_HISTORY_BY_DATE_RANGE_URL,
    GET_SHIP_HISTORY_BY_YEAR_MONTH_URL,
    GET_SHIP_HISTORY_BY_YEAR_URL,
    GET_WAREHOUSE_URL,
} from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getShipHistoryByYearApi = async action => {
    const res = await API.post(GET_SHIP_HISTORY_BY_YEAR_URL, action.payload);

    return res;
};

export const getWarehouseApi = async action => {
    const res = await API.get(GET_WAREHOUSE_URL, action.payload);
    return res;
};

export const getShipHistoryByDateRangeApi = async action => {
    const res = await API.post(GET_SHIP_HISTORY_BY_DATE_RANGE_URL, action.payload);
    return res;
};

export const getShipHistoryByYearMonthApi = async action => {
    const res = await API.post(GET_SHIP_HISTORY_BY_YEAR_MONTH_URL, action.payload);
    return res;
};
