import styled from '@emotion/styled';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Stack,
    Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { LAST_YEAR, NEXT_YEAR } from '../../../common/constants/date';
import {
    ACTIVE_STATE,
    APPROVED_STATE,
    COMPLETED_STATE,
    DISCARDED_STATE,
    PENDING_STATE,
    REJECTED_STATE,
} from '../../../common/constants/taskStates';
import { getFilteredTasksAction } from '../../store/actions/action';
import { CompanyDropdown, SkuDropdown, UserDropdown } from '../../../common/components/index';

const StyledButtonLabel = styled(FormControlLabel)(theme => {
    return { paddingRight: 10, marginBottom: 10 };
});

const TaskAllFilter = ({}) => {
    const reduxDispatch = useDispatch();

    const initialState = {
        companyDetail: null,
        skuDetail: null,
        assignedUserDetail: null,
        pendingState: true,
        activeState: true,
        completedState: true,
        rejectedState: false,
        approvedState: false,
        discardedState: false,

        createdOnStartDate: LAST_YEAR,
        createdOnEndDate: NEXT_YEAR,
        lastModifiedStartDate: LAST_YEAR,
        lastModifiedEndDate: NEXT_YEAR,
    };
    const reducer = (state, action) => {
        return { ...state, [action.key]: action.value };
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    //formats filter to be compatible with api
    const handleRefetchTasks = () => {
        let filteredRequestBody = {
            TaskState: [
                ...(state.pendingState ? [PENDING_STATE] : []),
                ...(state.activeState ? [ACTIVE_STATE] : []),
                ...(state.completedState ? [COMPLETED_STATE] : []),
                ...(state.rejectedState ? [REJECTED_STATE] : []),
                ...(state.approvedState ? [APPROVED_STATE] : []),
                ...(state.discardedState ? [DISCARDED_STATE] : []),
            ],
            createdOnStartDateTimeOffset: state.createdOnStartDate,
            createdOnEndDateTimeOffset: state.createdOnEndDate,
            lastModifiedStartDateTimeOffset: state.lastModifiedStartDate,
            lastModifiedEndDateTimeOffset: state.lastModifiedEndDate,
        };

        if (state.companyDetail?.id) filteredRequestBody.CompanyId = state.companyDetail.id;
        if (state.skuDetail) filteredRequestBody.Sku = state.skuDetail.sku; //only filters by first sku
        if (state.assignedUserDetail?.id) filteredRequestBody.AssignedToUserId = state.assignedUserDetail.id;

        reduxDispatch(getFilteredTasksAction(filteredRequestBody));
    };

    return (
        <div>
            <Box sx={{ p: 2.8 }}>
                <Accordion sx={{ backgroundColor: '#F5F5FA' }} expanded>
                    <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="filter-header"
                    >
                        <Typography>Filter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {' '}
                        <Box style={{ padding: 10 }}>
                            <CompanyDropdown
                                updateSelectedCompany={selectedValue => {
                                    dispatch({ key: 'companyDetail', value: selectedValue });
                                }}
                                key="select-company"
                                label="Select Company"
                            ></CompanyDropdown>
                            <br />
                            <SkuDropdown
                                key={'skuForCompany' + ((state.companyDetail || {}).mom || 'none')}
                                updateSelectedSku={selectedValue => {
                                    dispatch({ key: 'skuDetail', value: selectedValue });
                                }}
                                companyId={(state.companyDetail || {}).id}
                                label="Select Sku"
                                multiple={false}
                            />
                            <br />
                            <UserDropdown
                                updateSelectedUser={selectedValue => {
                                    if (selectedValue?.id) {
                                        dispatch({ key: 'assignedUserDetail', value: selectedValue });
                                    }
                                }}
                                label="Select Assignee"
                                key="select-assignee"
                            />
                            <br />
                            <Stack direction="column">
                                <FormGroup
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                        padding: 10,
                                    }}
                                >
                                    <StyledButtonLabel
                                        sx={{
                                            backgroundColor: theme => theme.palette.orange,
                                        }}
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                onClick={e => {
                                                    dispatch({ key: 'pendingState', value: e.target.checked });
                                                }}
                                            />
                                        }
                                        label="Pending"
                                    />
                                    <StyledButtonLabel
                                        sx={{
                                            backgroundColor: theme => theme.palette.yellow,
                                        }}
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                onClick={e => {
                                                    dispatch({ key: 'activeState', value: e.target.checked });
                                                }}
                                            />
                                        }
                                        label="Active"
                                    />
                                    <StyledButtonLabel
                                        sx={{
                                            backgroundColor: theme => theme.palette.blue,
                                        }}
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                onClick={e => {
                                                    dispatch({ key: 'completedState', value: e.target.checked });
                                                }}
                                            />
                                        }
                                        label="Completed"
                                    />
                                    <StyledButtonLabel
                                        sx={{ backgroundColor: theme => theme.palette.red }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onClick={e => {
                                                    dispatch({ key: 'rejectedState', value: e.target.checked });
                                                }}
                                            />
                                        }
                                        label="Rejected"
                                    />
                                    <StyledButtonLabel
                                        sx={{ backgroundColor: theme => theme.palette.green }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onClick={e => {
                                                    dispatch({ key: 'approvedState', value: e.target.checked });
                                                }}
                                            />
                                        }
                                        label="Approved"
                                    />
                                    <StyledButtonLabel
                                        sx={{ backgroundColor: theme => theme.palette.purple }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onClick={e => {
                                                    dispatch({ key: 'discardedState', value: e.target.checked });
                                                }}
                                            />
                                        }
                                        label="Discarded"
                                    />
                                </FormGroup>
                            </Stack>
                            <Stack direction="column" justifyContent="end">
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-around',
                                        padding: 10,
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ marginTop: 2 }}>
                                            <DateTimePicker
                                                label="Created On Start Date"
                                                value={state.createdOnStartDate}
                                                onChange={newValue => {
                                                    dispatch({ key: 'createdOnStartDate', value: newValue });
                                                }}
                                                sx={{ marginTop: 1 }}
                                            />
                                            <DateTimePicker
                                                label="Created On End Date"
                                                value={state.createdOnEndDate}
                                                onChange={newValue => {
                                                    dispatch({ key: 'createdOnEndDate', value: newValue });
                                                }}
                                                sx={{ marginTop: 1 }}
                                            />
                                        </Box>
                                        <Box sx={{ marginTop: 2 }}>
                                            <DateTimePicker
                                                label="Last Modified Start Date"
                                                value={state.lastModifiedStartDate}
                                                onChange={newValue => {
                                                    dispatch({ key: 'lastModifiedStartDate', value: newValue });
                                                }}
                                                sx={{ marginTop: 1 }}
                                            />
                                            <DateTimePicker
                                                label="Last Modified End Date"
                                                value={state.lastModifiedEndDate}
                                                onChange={newValue => {
                                                    dispatch({ key: 'lastModifiedEndDate', value: newValue });
                                                }}
                                                sx={{ marginTop: 1 }}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </Box>

                                <Button
                                    data-testid="refetch-tasks-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRefetchTasks}
                                    sx={{ p: 1, m: 2 }}
                                >
                                    Refetch Tasks
                                </Button>
                            </Stack>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
};

export default TaskAllFilter;
