import { Box, Card, TextField, Typography } from '@mui/material';
import React from 'react';
import { GetSkuVelocityResponse } from '../../models';
import TopQuantityBarChart from './TopQuantityBarChart';
import TopQuantityTreeMap from './TopQuantityTreeMap';

export default function TopQuantityChart({ data }: { data: GetSkuVelocityResponse[] }) {
    const [inputPercentage, setInputPercentage] = React.useState(100);

    const borderIndex = Math.round(data.length * (inputPercentage / 100));
    const takeTop = data.slice(0, borderIndex);

    return (
        <Card sx={{ mt: 2, p: 2 }} elevation={5}>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="h4" sx={{ mb: 2, flex: 1 }}>
                    Top Quantity
                </Typography>
                <TextField
                    value={inputPercentage}
                    label={'Top % Skus'}
                    onChange={event => {
                        const newValue = event.target.value;
                        setInputPercentage(Number(newValue));
                    }}
                    type="number"
                />
            </Box>

            <TopQuantityBarChart data={takeTop} />

            <TopQuantityTreeMap data={takeTop} />
        </Card>
    );
}
