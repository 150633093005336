export const SELECT_DROPDOWN_OPTION = option => `Please select one ${option ? option : 'option'}`;
export const SELECT_DROPDOWN_ATLEAST_ONE = option => `Please select atleast one ${option ? option : 'option'}`;
export const SELECT_COMPANY_FIRST = 'Please select Company first.';
export const TASK_CREATION_FAILED = 'Task Creation failed. Try again.';
export const TASK_CREATION_SUCCESS = 'Task created successfully';
export const TASK_UPDATON_SUCCESS = username => `Task re-assigned to ${username} successfully`;
export const TASK_REASSIGNED_SUCCESS = 'Task re-assigned successfully';
export const TASK_REASSIGNED_FAILED = 'Task re-assigned failed. Try again.';
export const TASK_REJECTION_SUCCESS = 'Task rejected successfully';
export const TASK_APPROVE_SUCCESS = 'Task approved successfully';
export const FILTER_PALLETS_FAILED = 'Please select atleast one value to filter the pallets';
