import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import * as React from 'react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';
import { Invoice } from '../../orderLookupModels';

export default function CommercialInvoicePdfMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);

    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );

    const invoices: Invoice[] = getOrderDetailsResponse.data?.Data[0]?.Invoices || [];
    const [selectedInvoice, setSelectedInvoice] = React.useState<Invoice>();

    const [
        triggerPostBuildCommercialInvoicePdf,
        postBuildCommercialInvoicePdfResponse,
    ] = orderLookupApi.endpoints.postBuildCommercialInvoicePdf.useLazyQuery();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (
            !postBuildCommercialInvoicePdfResponse.isUninitialized &&
            !postBuildCommercialInvoicePdfResponse.isFetching
        ) {
            var hiddenElement = document.createElement('a');
            hiddenElement.href = `data:application/pdf;base64,${postBuildCommercialInvoicePdfResponse.data}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = `${selectedInvoice?.Order}.pdf`;
            hiddenElement.click();
        }
    }, [postBuildCommercialInvoicePdfResponse]);

    return (
        <Box>
            <Tooltip title={invoices.length > 0 ? '' : 'No Invoices'} placement="top-start">
                <span>
                    <Button
                        size="small"
                        disabled={invoices.length == 0}
                        id="download-pdf-button"
                        aria-controls={open ? 'invoice-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Box display="flex" flexDirection={'row'} sx={{ justifyContent: 'space-between' }}>
                            <Box sx={{ mr: 1 }}>COMMERCIAL INVOICES</Box>
                            <Box>
                                <InsertDriveFileIcon />
                            </Box>
                        </Box>
                    </Button>
                </span>
            </Tooltip>
            <Menu
                id="invoice-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'download-pdf-button',
                }}
            >
                {invoices.map((invoice, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            if (momCode && !!orderId) {
                                setSelectedInvoice(invoice);
                                triggerPostBuildCommercialInvoicePdf({
                                    momCode: momCode,
                                    orderId: orderId,
                                    inpart: invoice.Inpart,
                                });
                                handleClose();
                            }
                        }}
                    >
                        {invoice.Order}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
