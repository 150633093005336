import { Box, Paper } from '@mui/material';
import { ExpectedItem, ReceivedItem } from '../../rmaModels';
import ReadOnlyTextField from '../ReadOnlyTextField';

export const ReceivedItemSummaryPanelPaper = ({
    receivedItem,
    expectedItem,
    qtyMissing,
    qtyExtra,
}: {
    receivedItem: ReceivedItem;
    expectedItem?: ExpectedItem;
    qtyMissing: number;
    qtyExtra: number;
}) => {
    return (
        <Paper sx={{ display: 'flex', p: 1 }}>
            {!!expectedItem && (
                <ReadOnlyTextField
                    variant="filled"
                    sx={{ flex: 1, m: 1 }}
                    label="EXPECTED QTY"
                    value={expectedItem.Quantity}
                />
            )}

            <ReadOnlyTextField
                variant="filled"
                sx={{ flex: 1, m: 1 }}
                label="RECEIVED QTY"
                value={receivedItem.Quantity}
            />

            {!!qtyMissing ? (
                !!(qtyMissing > 0) ? (
                    <ReadOnlyTextField
                        variant="filled"
                        sx={{ flex: 1, m: 1 }}
                        label="MISSING QTY"
                        value={qtyMissing}
                        InputLabelProps={{
                            sx: {
                                color: 'red',
                            },
                        }}
                    />
                ) : (
                    <ReadOnlyTextField
                        variant="filled"
                        sx={{ flex: 1, m: 1 }}
                        label="EXTRA QTY"
                        value={qtyExtra}
                        InputLabelProps={{
                            sx: {
                                color: 'primary.main',
                            },
                        }}
                    />
                )
            ) : (
                <Box sx={{ flex: 1, m: 1 }}></Box>
            )}
        </Paper>
    );
};
