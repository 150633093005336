import { Button, Divider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Group, MatrixCell, ShipMethod } from '../../shipMatrixModel';
import ShipMatrixDataGrid from './ShipMatrixDataGrid';
import ShipMatrixLegend from './ShipMatrixLegend';
import ShipMatrixUpdateForm from './ShipMatrixUpdateForm';
import shipMatrixApi from '../../shipMatrixApi';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import EditLogAutocomplete from './editLog/EditLogAutocomplete';
import ShipMatrixPropertyToEditRadio from './ShipMatrixPropertyToEditRadio';

interface ShipMatrixEditorChildProps {
    data: MatrixCell[];
    currGroup: Group;
}

const ShipMatrixEditorChild = ({ data, currGroup }: ShipMatrixEditorChildProps) => {
    const [triggerSetShipMethod, setShipMethodResponse] = shipMatrixApi.endpoints.editShipMethod.useMutation();
    const [submitData, setSubmitData] = React.useState(data);
    const [selectedCells, setSelectedCells] = React.useState<MatrixCell[]>([]);
    const [propertyToEdit, setPropertyToEdit] = React.useState<
        'ShipCode' | 'FreightCost' | 'Markup' | 'FreightCostAndMarkup'
    >('ShipCode');

    useNotificationByResponse({
        isFetching: setShipMethodResponse.isLoading,
        isError: setShipMethodResponse.isError,
        errorContent:
            setShipMethodResponse?.error && 'data' in setShipMethodResponse?.error
                ? JSON.stringify(setShipMethodResponse.error.data)
                : 'Failed to edit',
        isSuccess: setShipMethodResponse.isSuccess,
        successContent: 'Edit successful',
        isUninitialized: setShipMethodResponse.isUninitialized,
    });

    const handleSubmit = () => {
        triggerSetShipMethod({
            GroupId: currGroup.Id,
            Rows: submitData,
        });
    };

    return (
        <Box>
            <ShipMatrixUpdateForm
                selectedCells={selectedCells}
                setSubmitData={setSubmitData}
                onSubmit={() => {
                    setSelectedCells([]);
                }}
            />
            <Box sx={{ display: 'flex' }}>
                <ShipMatrixDataGrid
                    submitData={submitData}
                    selectedCells={selectedCells}
                    setSelectedCells={setSelectedCells}
                    propertyToEdit={propertyToEdit}
                    setSubmitData={setSubmitData}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ShipMatrixLegend />
                    <ShipMatrixPropertyToEditRadio
                        propertyToEdit={propertyToEdit}
                        setPropertyToEdit={setPropertyToEdit}
                    />
                </Box>
            </Box>

            <Button sx={{ width: '100%', mt: 2 }} color="primary" variant="contained" onClick={handleSubmit}>
                Save
            </Button>

            <Divider sx={{ marginY: 2 }} />

            <EditLogAutocomplete groupId={currGroup.Id} propertyToEdit={propertyToEdit} />
        </Box>
    );
};

export default ShipMatrixEditorChild;
