import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import ConfirmDialog from '../../../common/components/Dialog/ConfirmDialog';
import { setIsNavigationWarningDialogOpen } from '../notificationSlice';

const AppBarNavigationWarningDialog = () => {
    const {
        navigationWarningDialogDestination,
        isNavigationWarningDialogOpen,
        navigationWarningDialogContent,
        navigationWarningDialogTitle,
    } = useAppSelector(state => state.notification);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleDialogClose = () => {
        navigate(navigationWarningDialogDestination || '/');
        dispatch(setIsNavigationWarningDialogOpen(false));
    };

    return (
        <ConfirmDialog
            open={isNavigationWarningDialogOpen}
            handleCancel={() => {
                dispatch(setIsNavigationWarningDialogOpen(false));
            }}
            handleConfirm={handleDialogClose}
            title={navigationWarningDialogTitle}
            content={navigationWarningDialogContent}
        />
    );
};

export default AppBarNavigationWarningDialog;
