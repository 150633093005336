import { Grid } from '@mui/material';
import { isFeatureAccessible } from '../../common/utils/commonUtils';
import authenticationApi from '../authentication/authenticationApi';
import { ROUTES_WITH_CARDS } from '../authentication/Routes';
import FeatureCard from './FeatureCard';

const CardGrid = () => {
    const getUserPermissionsResponse = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions = getUserPermissionsResponse.data ? getUserPermissionsResponse.data : [];

    return (
        <Grid container spacing={3} sx={{ mt: 4, display: 'flex' }}>
            {ROUTES_WITH_CARDS.filter(data => {
                return (
                    !!data &&
                    !!data.cardData &&
                    data.permissions?.every(permission => isFeatureAccessible(userPermissions, permission))
                );
            })?.map(data => (
                <FeatureCard
                    image={data?.cardData?.image}
                    title={data?.cardData?.title}
                    description={data?.cardData?.description}
                    path={data?.path}
                    testId={data?.path}
                    key={data?.path}
                />
            ))}
        </Grid>
    );
};

export default CardGrid;
