import { Box, Button, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/store';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import textFieldFocus from '../../rapidQc/utils/textFieldFocus';
import rmaApi from '../rmaApi';
import { Rma } from '../rmaModels';
import { setReset, setRma as setRmaSlice } from '../rmaSlice';
import {
    captureClientCodeFromLabel,
    filterKeyDownForTracking,
    parseTrackingNumber,
    submitOnEnterKeyDown,
} from '../utilities';
import { ChooseRmaDialog } from './ChooseRmaDialog';
import { ExpectedItemsDialog } from './ExpectedItems/ExpectedItemsDialog';
import { RmaNotFoundDialog } from './ExpectedItems/RmaNotFoundDialog';
import { CreateRmaDialog } from './ReceivedItems/CreateNewRma/CreateRmaDialog';

const SERVER_ERROR_MSG = 'Could not correctly retrieve data, please try again later.';

export const LandingPageDisplay = () => {
    const dispatch = useDispatch();
    const [returnLabelQuery, setReturnLabelQuery] = React.useState('');
    const [expectedItemsDialog, setExpectedItemsDialog] = React.useState(false);
    const [rmaNotFoundDialog, setRmaNotFoundDialog] = React.useState(false);
    const [multipleRmasDialog, setMultipleRmasDialog] = React.useState(false);
    const [newRmaDialog, setNewRmaDialog] = React.useState(false);
    const [rmas, setRmas] = React.useState<Rma[]>([]);

    const finishAndCloseAllWindows = useAppSelector(state => state.rma.reset); // Set to true after confirming summary submission
    const returnLabelTextfieldRef = useRef<HTMLInputElement>(null);

    const [triggerGetRma, rmaResponse] = rmaApi.endpoints.getRma.useLazyQuery();
    const [triggerGetReturnLabel, returnLabelResponse] = rmaApi.endpoints.getReturnLabel.useMutation();

    useNotificationByResponse({
        isFetching: rmaResponse.isFetching,
        isError: rmaResponse.isError,
        errorContent: SERVER_ERROR_MSG,
        isUninitialized: rmaResponse.isUninitialized,
    });

    const submitReturnLabel = () => {
        const parsedValue = parseTrackingNumber(returnLabelQuery).replace(/\n/g, '');
        if (!!returnLabelQuery) {
            triggerGetReturnLabel({ refPo: parsedValue });
        }
        setReturnLabelQuery(parsedValue);
    };

    // Return rmas with unique client and rma numbers
    const getUniqueClientRma = (rawRmaList: Rma[]) => {
        const uniqueRma = new Set();
        return rawRmaList.filter(rma => {
            const identifier = `${rma.Client}-${rma.AltRmaNumber}-${rma.RmaNumber}`;
            if (!uniqueRma.has(identifier)) {
                uniqueRma.add(identifier);
                return true;
            }
            return false;
        });
    };

    const getReturnLabel = () => {
        if (!!returnLabelResponse.data && returnLabelResponse.data?.length > 0) {
            return returnLabelResponse.data[0];
        }
        return null;
    };

    const clearAndFocusRmaTextfield = () => {
        setReturnLabelQuery('');
        textFieldFocus({ textFieldRef: returnLabelTextfieldRef });
    };

    const onSuccessfulRmaResponse = () => {
        if (!rmaResponse.data?.Data) {
            return;
        }

        const uniqueRmas = getUniqueClientRma(rmaResponse.data.Data);
        if (uniqueRmas.length == 1) {
            dispatch(setRmaSlice(uniqueRmas[0]));
            setExpectedItemsDialog(true);
        } else if (uniqueRmas.length > 1) {
            setRmas(uniqueRmas);
            setMultipleRmasDialog(true);
        }
    };

    React.useEffect(() => {
        if (!rmaResponse.isUninitialized && !rmaResponse.isFetching) {
            if (rmaResponse.data?.Success && rmaResponse.data.Data.length > 0) {
                onSuccessfulRmaResponse();
            } else {
                dispatch(setRmaSlice(null));
                clearAndFocusRmaTextfield();
                setRmaNotFoundDialog(true);
            }
        }
    }, [rmaResponse]);

    React.useEffect(() => {
        if (!returnLabelResponse.isUninitialized && !returnLabelResponse.isLoading) {
            // Attempt to get return label
            if (!!returnLabelResponse.data && returnLabelResponse.data?.length > 0) {
                const newestReturnlabel = returnLabelResponse.data[0];
                const possibleClientCode = captureClientCodeFromLabel(newestReturnlabel.reference) || null;
                triggerGetRma({ momCode: possibleClientCode, rmaQuery: newestReturnlabel.rma });
                // If no record found, treat scan as tracking or rma number
            } else {
                triggerGetRma({ momCode: null, rmaQuery: returnLabelQuery });
            }
        }
    }, [returnLabelResponse]);

    React.useEffect(() => {
        if (finishAndCloseAllWindows) {
            setExpectedItemsDialog(false);
            setNewRmaDialog(false);
            dispatch(setReset(false));
            clearAndFocusRmaTextfield();
        }
    }, [finishAndCloseAllWindows]);

    return (
        <React.Fragment>
            <ExpectedItemsDialog showDialog={expectedItemsDialog} handleOpenDialog={setExpectedItemsDialog} />
            <RmaNotFoundDialog
                showDialog={rmaNotFoundDialog}
                handleOpenDialog={setRmaNotFoundDialog}
                handleNewRmaDialog={setNewRmaDialog}
                returnLabel={getReturnLabel()}
            />
            <CreateRmaDialog showDialog={newRmaDialog} handleOpenDialog={setNewRmaDialog} />
            <ChooseRmaDialog
                rmas={rmas}
                showDialog={multipleRmasDialog}
                handleOpenDialog={setMultipleRmasDialog}
                setExpectedItemsDialog={setExpectedItemsDialog}
            />
            <Box sx={{ display: 'flex' }}>
                <TextField
                    value={returnLabelQuery}
                    inputRef={returnLabelTextfieldRef}
                    autoFocus
                    sx={{ flex: 6 }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        filterKeyDownForTracking(event, returnLabelQuery, setReturnLabelQuery);
                        submitOnEnterKeyDown(event, submitReturnLabel);
                    }}
                    label={'SCAN RETURN LABEL, TRACKING, OR RMA NUMBER'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setReturnLabelQuery(event.target.value.trim());
                    }}
                />
                <Button sx={{ flex: 1, ml: 1 }} variant="contained" onClick={submitReturnLabel}>
                    SUBMIT
                </Button>
            </Box>
        </React.Fragment>
    );
};
