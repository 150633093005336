import { SxProps, TextFieldProps } from '@mui/material';
import NumberTextfieldV2 from '../../../../../../common/components/TextField/NumberTextfieldV2';

const ItemCardNumberTextfield = ({
    value,
    onChange,
    textFieldProps = {},
}: {
    value: number;
    onChange: (input: number) => void;
    textFieldProps?: TextFieldProps;
    sx?: SxProps;
}) => {
    return (
        <NumberTextfieldV2
            value={value}
            key={value}
            onChange={newValue => {
                onChange(newValue);
            }}
            isNegativeAllowed={true}
            textFieldProps={{
                helperText: ' ',
                ...textFieldProps,
                variant: 'outlined',
                sx: { flex: 1 },
            }}
        />
    );
};

export default ItemCardNumberTextfield;
