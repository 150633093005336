import { Box } from '@mui/material';
import FormDivider from '../../../../common/components/divider/FormDivider';
import serialApi from '../../serialApi';
import { GetSerialRequest } from '../../serialModels';
import SerialLocationDataGrid from '../location/SerialLocationDataGrid';
import OnHandDataGrid from '../onhand/OnHandDataGrid';

const DifferenceTab = ({ formValues }: { formValues: GetSerialRequest }) => {
    const getSerialsResponse = serialApi.endpoints.getOnHandSerials.useQuery({
        Client: formValues.Client,
        Sku: formValues.Sku,
    });
    const getSerialLocationResponse = serialApi.endpoints.getSerialLocations.useQuery({
        IsShelved: true,
        LocationName: '',
        SerialValue: '',
        ...formValues,
    });

    const areSerialsDoneFetching = getSerialsResponse?.data && getSerialsResponse?.data;

    const onHandSerials = getSerialsResponse?.data?.map(record => record.Serial);
    const hvcSerials = getSerialLocationResponse?.data?.map(record => record.SerialValue);
    const onHandSerialsOnly = areSerialsDoneFetching
        ? getSerialsResponse?.data?.filter(record => !hvcSerials?.includes(record.Serial))
        : [];
    const hvcSerialsOnly = areSerialsDoneFetching
        ? getSerialLocationResponse?.data?.filter(record => !onHandSerials?.includes(record.SerialValue))
        : [];

    return (
        <Box>
            <Box>
                <FormDivider text="On Hand Only" />
                <OnHandDataGrid
                    data={onHandSerialsOnly || []}
                    fileName={`difference-onhand-serials-${formValues.Client}-${formValues.Sku}`}
                />
                <FormDivider text="HVC Only" />
                <SerialLocationDataGrid
                    data={hvcSerialsOnly || []}
                    fileName={`difference-hvc-location-serials-${formValues.Client}-${formValues.Sku}`}
                />
            </Box>
        </Box>
    );
};

export default DifferenceTab;
