import { Box, Button, Card } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import CompanyDropdownV3 from '../../../common/components/dropdown/CompanyDropdownV3';
import DateRangePickerPopover from '../../../common/components/popover/DateRangePickerPopover';
import { GetSkuVelocityRequest } from '../models';
import LocationDropdown from './LocationDropdown';

const DEFAULT_LOCATION = 'CA';

const INITIAL_FILTER: GetSkuVelocityRequest = {
    StartedAt: dayjs().subtract(1, 'week').toISOString(),
    EndedAt: dayjs().toISOString(),
    Client: '',
    Location: DEFAULT_LOCATION,
};

const SkuVelocityForm = ({ onSubmit }: { onSubmit: (formData: GetSkuVelocityRequest) => void }) => {
    const [filter, setFilter] = React.useState(INITIAL_FILTER);

    return (
        <Card sx={{ p: 1 }} elevation={5}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit(filter);
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ display: 'flex' }}>
                        <DateRangePickerPopover
                            startDate={filter.StartedAt}
                            endDate={filter.EndedAt}
                            textFieldProps={{
                                label: 'Created Date',
                                sx: { width: '100%' },
                            }}
                            sx={{ flex: 1, m: 1 }}
                            onStartDateChange={newValue => {
                                if (newValue)
                                    setFilter(
                                        (prevState): GetSkuVelocityRequest => ({
                                            ...prevState,
                                            StartedAt: newValue,
                                        })
                                    );
                            }}
                            onEndDateChange={newValue => {
                                if (newValue)
                                    setFilter(
                                        (prevState): GetSkuVelocityRequest => ({
                                            ...prevState,
                                            EndedAt: newValue,
                                        })
                                    );
                            }}
                        />
                        <CompanyDropdownV3
                            value={filter.Client}
                            onChange={(e, value) => {
                                setFilter(
                                    (prevState): GetSkuVelocityRequest => ({ ...prevState, Client: value?.mom || '' })
                                );
                            }}
                            sx={{ flex: 1, m: 1 }}
                        />
                        <LocationDropdown
                            sx={{ flex: 1, m: 1 }}
                            value={filter.Location}
                            onChange={(event, value) => {
                                setFilter(
                                    (prevState): GetSkuVelocityRequest => ({ ...prevState, Location: value || '' })
                                );
                            }}
                            textFieldProps={{ label: 'Location', name: 'location' }}
                        />
                        <Button type="submit" variant="contained" sx={{ m: 2 }}>
                            Filter
                        </Button>
                    </Box>
                </LocalizationProvider>
            </form>
        </Card>
    );
};

export default SkuVelocityForm;
