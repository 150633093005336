import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React from 'react';

const ShipMatrixPropertyToEditRadio = ({
    propertyToEdit,
    setPropertyToEdit,
}: {
    propertyToEdit: 'ShipCode' | 'FreightCost' | 'Markup' | 'FreightCostAndMarkup';
    setPropertyToEdit: React.Dispatch<
        React.SetStateAction<'ShipCode' | 'FreightCost' | 'Markup' | 'FreightCostAndMarkup'>
    >;
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = (event.target as HTMLInputElement).value;

        if (
            newValue === 'ShipCode' ||
            newValue === 'FreightCost' ||
            newValue === 'Markup' ||
            newValue === 'FreightCostAndMarkup'
        )
            setPropertyToEdit(newValue);
    };

    return (
        <FormControl sx={{ marginX: 2 }}>
            <FormLabel id="ShipMatrixPropertyToEditRadio">Property</FormLabel>
            <RadioGroup
                aria-labelledby="ShipMatrixPropertyToEditRadio"
                value={propertyToEdit}
                name="radio-buttons-group"
                onChange={handleChange}
            >
                <FormControlLabel value="ShipCode" control={<Radio />} label="Ship Code" />
                <FormControlLabel value="FreightCost" control={<Radio />} label="Freight Cost" />
                <FormControlLabel value="Markup" control={<Radio />} label="Markup" />
                <FormControlLabel value="FreightCostAndMarkup" control={<Radio />} label="Freight Cost + Markup" />
            </RadioGroup>
        </FormControl>
    );
};

export default ShipMatrixPropertyToEditRadio;
