import { RECEIVER_PROJECTS_OPTIONS_WITH_REQUIRED_LADING } from './receiversConstants';
import { ConfigData, SubmissionForm, ActiveRuleMap } from './receiversModels';

export const isLadingError = (lading: string, project: string) => {
    return !lading && RECEIVER_PROJECTS_OPTIONS_WITH_REQUIRED_LADING.includes(project);
};

export const getRule = (configData: ConfigData | undefined, formProperty: keyof SubmissionForm) => {
    return configData?.Rules.find(rule => rule.Label === formProperty);
};

export const getOptions = (configData: ConfigData | undefined, formProperty: keyof SubmissionForm) => {
    return getRule(configData, formProperty)?.Options?.map(option => option.Label) || [];
};

export const getActiveRules = (configData: ConfigData | undefined, form: SubmissionForm): ActiveRuleMap => {
    const rulesWithOptions = configData?.Rules?.filter(rule => rule.Options?.length > 0) || []; //filter to find active option and add its rules
    const allRules = configData?.Rules || []; //merge all of these rules

    const activeRulesFromRulesWithOptions = rulesWithOptions
        .map(rule => rule.Options.find(option => form[rule.Label] === option.Label))
        .flatMap(rule => rule?.Rules)
        .filter(rule => rule !== undefined);

    const appendedRules = [...activeRulesFromRulesWithOptions, ...allRules];
    let activeRules: ActiveRuleMap = {};

    appendedRules.forEach(rule => {
        if (!rule) return;
        const existingRule = activeRules[rule.Label];
        if (existingRule) {
            activeRules[rule.Label] = {
                Regex: existingRule.Regex || rule.Regex,
                IsRequired: existingRule.IsRequired || rule.IsRequired,
                IsDisabled: existingRule.IsDisabled || rule.IsDisabled,
            };
        } else {
            activeRules[rule.Label] = { ...rule };
        }
    });

    return activeRules;
};

export const minsToHours = (minute: number) => {
    const timeInHours = minute / 60;
    return Math.round(timeInHours * 100) / 100;
};

export const fixForm = (brokenForm: SubmissionForm): SubmissionForm => {
    return {
        ...brokenForm,
        StockTime: minsToHours(brokenForm.StockTime),
        LabelTime: minsToHours(brokenForm.LabelTime),
        ShelfTime: minsToHours(brokenForm.ShelfTime),
    } as SubmissionForm;
};
