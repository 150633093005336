import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import componentsApi from '../componentsApi';
import { User } from '../componentsModel';

interface WrappedAutoCompleteProps
    extends Omit<AutocompleteProps<User, undefined, boolean, undefined>, 'options' | 'renderInput' | 'value'> {
    textFieldProps?: TextFieldProps;
    value: string;
}

const getLabel = (input: string | User) => {
    if (typeof input === 'string') {
        return '';
    }

    return `${input.name} (${input.code})`;
};

const arrowDropDownIcon = <ArrowDropDownIcon />;

const circularProgress = <CircularProgress color="inherit" size={20} />;

//V1 -> V2: Use user code as value
export default function UserDropdownV2({ textFieldProps, value, ...props }: WrappedAutoCompleteProps) {
    const response = componentsApi.endpoints.getUsers.useQuery();

    const companyMatchingControllValue = React.useMemo(() => {
        return response?.data?.find(user => user.code === value);
    }, [value, response]);

    const sortedUsers = React.useMemo(() => {
        if (response.isSuccess) {
            return [...response?.data].sort((a, b) => a.name.localeCompare(b.name));
        }
        return [];
    }, [response.isSuccess]);

    return (
        <Autocomplete
            key={companyMatchingControllValue?.code || ''}
            value={companyMatchingControllValue}
            options={sortedUsers}
            getOptionLabel={getLabel}
            loading={response.isFetching}
            popupIcon={props.readOnly ? '' : arrowDropDownIcon}
            renderInput={params => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {response.isFetching ? circularProgress : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    {...textFieldProps}
                />
            )}
            {...props}
        />
    );
}
