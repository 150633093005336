import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';
import MemoTextField from '../../receivers/components/createDialog/formPartTwo/formControlledInputs/MemoTextField';
import trackingApi from '../trackingApi';
import { Tracking } from '../trackingModels';
import CarrierAutoComplete from './CarrierAutoComplete';
import TrackingFormPartTwo from './TrackingFormPartTwo';

interface TrackingFormProps {
    form: Tracking;
    setForm: Dispatch<SetStateAction<Tracking>>;
    initialForm: Tracking;
}

const TrackingForm = ({ form, setForm, initialForm }: TrackingFormProps): JSX.Element => {
    const [postTracking] = trackingApi.endpoints.postTracking.useMutation({ fixedCacheKey: 'post-tracking' });

    const handleSubmitClick = () => {
        postTracking(form);
        setForm(initialForm);
    };

    return (
        <Box sx={{ backgroundColor: '#FFF', p: 4 }}>
            <Typography variant="h6" color="initial">
                Add New Tracking
            </Typography>

            <MemoTextField
                autoFocus={true}
                label={'Invoice'}
                value={form.PackageId || ''}
                error={!form.PackageId || form.PackageId.length < 5}
                helperText={!form.PackageId || form.PackageId.length < 5 ? 'Invalid length' : ''}
                onChange={(newValue: string) => {
                    setForm(
                        (prevForm): Tracking => {
                            return { ...prevForm, PackageId: newValue };
                        }
                    );
                }}
            />

            <CarrierAutoComplete form={form} setForm={setForm} />

            <MemoTextField
                label={'Tracking Number'}
                value={form.TrackingNumber || ''}
                error={!form.TrackingNumber}
                onChange={(newValue: string) => {
                    setForm(
                        (prevForm): Tracking => {
                            return { ...prevForm, TrackingNumber: newValue };
                        }
                    );
                }}
            />

            {!!form?.InternalCode && <TrackingFormPartTwo form={form} setForm={setForm} />}

            <Button
                onClick={handleSubmitClick}
                disabled={!form.PackageId || !form.Scac || !form.TrackingNumber || !form.Weight} //don't check for carrier since it can be valid as empty string
                variant="contained"
                sx={{ width: '100%', mt: 2 }}
            >
                Submit
            </Button>
        </Box>
    );
};

export default TrackingForm;
