import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/approveTaskDetailsApi';

export function* getApproveTaskDetailsSaga(action) {
    try {
        yield put({ type: actionTypes.SET_TASK_LOADING_TRUE });
        const json = yield call(api.getApproveTaskDetailApi, action);
        yield put({ type: actionTypes.GET_APPROVE_TASK_DETAILS_SUCCESS, payload: json.data });
        yield put({ type: actionTypes.GET_COMPANY_SKUS_BY_TASK_STATE, taskState: [3, 4] });
    } catch (error) {
        yield put({ type: actionTypes.GET_APPROVE_TASK_DETAILS_FAILURE, error: error });
    }
}

export default function* approveTaskDetailsWatcher() {
    yield takeLatest(actionTypes.GET_APPROVE_TASK_DETAILS, getApproveTaskDetailsSaga);
}
