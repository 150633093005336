import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import workOrderApi from '../../workOrderApi';
import { BillingItem, ServiceRecord } from '../../workOrderModels';
import { overrideForm } from '../../workOrderSlice';

interface BillingItemFormProps {
    isDisabled: boolean;
    indexToUpdate?: number;
    handleOnSubmit?: () => void;
}

const SERVICE_CODES_WITHOUT_DURATION_REQUIREMENT = ['NOT'];

const BillingItemForm = ({ isDisabled, indexToUpdate = -1, handleOnSubmit = () => {} }: BillingItemFormProps) => {
    const billingItems = useAppSelector(state => state.workOrder.form.BillingItems);
    const getServicesResponse = workOrderApi.endpoints.getServices.useQuery();
    const dispatch = useAppDispatch();
    const initialBillingItem: BillingItem = billingItems?.[indexToUpdate] || {
        Id: null,
        WorkOrderId: null,
        Instructions: '',
        Service: '',
        Duration: 0,
        Rush: false,
        NoCharge: false,
    };
    const [billingItemBeingAdded, setBillingItemBeingAdded] = React.useState<BillingItem>(initialBillingItem);
    const [isErrorDisplayed, setIsErrorDisplayed] = React.useState(false);

    const isServiceError = !billingItemBeingAdded.Service;
    const isBillingError = !billingItemBeingAdded.Instructions;
    const isDurationError =
        !billingItemBeingAdded.Duration &&
        !billingItemBeingAdded.NoCharge &&
        !SERVICE_CODES_WITHOUT_DURATION_REQUIREMENT.includes(billingItemBeingAdded.Service);

    const isError = isServiceError || isBillingError || isDurationError;

    const shouldDisplayServiceError = isServiceError && isErrorDisplayed;
    const shouldDisplayDurationError = isDurationError && isErrorDisplayed;
    const shouldDiplayBillingError = isBillingError && isErrorDisplayed;

    const handleAddBillingButtonClick = () => {
        if (isError) {
            setIsErrorDisplayed(true);
        } else {
            if (indexToUpdate !== -1) {
                let newItemsList = [...billingItems];
                newItemsList.splice(indexToUpdate, 1);
                dispatch(overrideForm({ BillingItems: [...newItemsList, { ...billingItemBeingAdded }] }));
                handleOnSubmit();
            } else {
                dispatch(overrideForm({ BillingItems: [...billingItems, { ...billingItemBeingAdded }] }));
            }
            setBillingItemBeingAdded({
                ...initialBillingItem,
            });
            setIsErrorDisplayed(false);
        }
    };

    const getServiceValue = () => {
        if (billingItemBeingAdded.Service) {
            return getServicesResponse?.data?.find(item => item.Code === billingItemBeingAdded.Service);
        } else {
            return null;
        }
    };

    return (
        <Box sx={{ my: 2 }}>
            <form
                onSubmit={event => {
                    event.preventDefault();
                    handleAddBillingButtonClick();
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                        disablePortal
                        options={getServicesResponse?.data || []}
                        value={getServiceValue()}
                        onChange={(event, newValue) => {
                            setBillingItemBeingAdded(prev => {
                                return { ...prev, Service: newValue?.Code || '' };
                            });
                        }}
                        getOptionLabel={(option: ServiceRecord) => `${option.Description} (${option.Code})`}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Service"
                                error={shouldDisplayServiceError}
                                helperText={shouldDisplayServiceError ? 'Required' : ' '}
                            />
                        )}
                        sx={{ flex: 6, mt: 2 }}
                        disabled={isDisabled}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={billingItemBeingAdded.Rush}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newValue = event.target.checked;
                                    setBillingItemBeingAdded(prev => {
                                        return { ...prev, Rush: newValue };
                                    });
                                }}
                            />
                        }
                        sx={{ flex: 1, ml: 2, textAlign: 'center' }}
                        label="Rush"
                        disabled={isDisabled}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={billingItemBeingAdded.NoCharge}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newValue = event.target.checked;
                                    setBillingItemBeingAdded(prev => {
                                        return { ...prev, NoCharge: newValue };
                                    });
                                }}
                            />
                        }
                        sx={{ flex: 1, textAlign: 'center' }}
                        label="No Charge"
                        disabled={isDisabled}
                    />

                    <TextField
                        variant="outlined"
                        label="Duration"
                        value={billingItemBeingAdded.Duration}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = Number(Number(event.target.value).toFixed(2));
                            if (newValue >= 0)
                                setBillingItemBeingAdded(prev => {
                                    return { ...prev, Duration: newValue };
                                });
                        }}
                        sx={{ flex: 2, mt: 2 }}
                        type="number"
                        disabled={isDisabled}
                        error={shouldDisplayDurationError}
                        helperText={shouldDisplayDurationError ? 'Must be greater than 0' : ' '}
                    />
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <TextField
                        variant="outlined"
                        label="Billing Description"
                        value={billingItemBeingAdded.Instructions}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = event.target.value;
                            setBillingItemBeingAdded(prev => {
                                return { ...prev, Instructions: newValue };
                            });
                        }}
                        onKeyDown={event => {
                            const isEnterKeyPressed = event.key === 'Enter';
                            const isShiftEnterKeyPressed = event.key === 'Enter' && event.shiftKey;
                            if (isShiftEnterKeyPressed) {
                                return;
                            }
                            if (isEnterKeyPressed) {
                                event.preventDefault();
                                handleAddBillingButtonClick();
                            }
                        }}
                        sx={{ flex: 4, mt: 3 }}
                        multiline
                        rows={3}
                        disabled={isDisabled}
                        error={shouldDiplayBillingError}
                        helperText={shouldDiplayBillingError ? 'Required' : ' '}
                    />

                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ flex: 1, my: 7.3, mx: 2 }}
                        disabled={isDisabled}
                        type="submit"
                    >
                        {indexToUpdate !== -1 ? 'Update' : 'Add Service'}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default BillingItemForm;
