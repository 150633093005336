import { Box, CardMedia } from '@mui/material';
import React, { useState } from 'react';
import { useAppSelector } from '../../../../app/store';
import { UploadedPicture } from '../../rmaModels';
import { SKU_IMG_WIDTH } from '../../constants';
import { UploadItemPictureDialog } from './UploadItemPictureDialog';

const IMAGE_PATH_DIR = 'https://assets.rushorder.com/images/rma/';
const NOT_FOUND_IMG = 'pic_not_found.jpg';
const IMAGE_EXTENSIONS = ['.webp', '.jpg', '.png', '.jpeg', '.gif'];

export const ItemCardMedia = ({ sku }: { sku: string | null | undefined }) => {
    const rma = useAppSelector(state => state.rma.rma);

    const [imgSrc, setImgSrc] = useState(`${IMAGE_PATH_DIR}${rma?.Client}/${sku}.jpg`);
    const [currentExtIndex, setCurrentExtIndex] = useState(0);
    const [uploadPictureDialog, setUploadPictureDialog] = React.useState(false);
    const [recentlyUploadedPicture, setRecentlyUploadedPicture] = useState<UploadedPicture>();

    // Since the image location is not stored anywhere, we use [client]/[sku].[extension] to find the image.
    // This function iterates through image extensions to find the file name.
    const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (currentExtIndex < IMAGE_EXTENSIONS.length - 1) {
            const newExtIndex = currentExtIndex + 1;
            setCurrentExtIndex(newExtIndex);
            setImgSrc(`${IMAGE_PATH_DIR}${rma?.Client}/${sku}${IMAGE_EXTENSIONS[newExtIndex]}?${new Date().getTime()}`);
        } else {
            const target = e.target as HTMLImageElement;
            target.src = `${IMAGE_PATH_DIR}${NOT_FOUND_IMG}`;
        }
    };

    // Display the newly uploaded image locally
    React.useEffect(() => {
        if (!!recentlyUploadedPicture?.Preview) {
            setImgSrc(recentlyUploadedPicture.Preview);
        }
    }, [recentlyUploadedPicture?.Preview]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <UploadItemPictureDialog
                sku={sku}
                handleOpenDialog={setUploadPictureDialog}
                setRecentlyUploadedPicture={setRecentlyUploadedPicture}
                showDialog={uploadPictureDialog}
            />
            <CardMedia
                component="img"
                sx={{
                    maxWidth: SKU_IMG_WIDTH,
                }}
                alt="SKU"
                referrerPolicy="no-referrer"
                onError={e => handleError(e)}
                src={imgSrc}
            />
        </Box>
    );
};
