import { Box } from '@mui/material';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Layer, Stage } from 'react-konva';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../../app/store';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import { setSnackbar } from '../../../notification/notificationSlice';
import { DraggableInstruction } from '../../models';
import ProcedureApi from '../../ProcedureApi';
import ProcedureDetailsCard from '../ProcedureDetailsCard';
import CanvasSpeedDial from './CanvasSpeedDial';
import InstructionAddItemDialog from './InstructionAddItemDialog';
import InstructionCard from './InstructionCard';
import NextInstructionArrow from './NextInstructionArrow';
import InstructionSetVariableDialog from './SetVariable/InstructionSetVariableDialog';

const CanvasStage = ({
    instructions,
    setInstructions,
}: {
    instructions: DraggableInstruction[];
    setInstructions: React.Dispatch<React.SetStateAction<DraggableInstruction[]>>;
}) => {
    const dispatch = useAppDispatch();

    const { procedureId } = useParams();
    const castedProcedureId = procedureId ? Number(procedureId) : null;

    const [instructionIdTryingToAddItemsTo, setInstructionIdTryingToAddItemsTo] = React.useState<number | undefined>();
    const [instructionIdTryingToAddVariablesTo, setInstructionIdTryingToAddVariablesTo] = React.useState<
        number | undefined
    >();

    const instructionTryingToAddItemsTo = instructions.find(
        instruction => instruction.Id === instructionIdTryingToAddItemsTo
    );
    const instructionTryingToVariablesTo = instructions.find(
        instruction => instruction.Id === instructionIdTryingToAddVariablesTo
    );

    const stageRef = React.useRef<any>();
    const [stageSpec, setStageSpec] = React.useState({
        scale: 1,
        x: 0,
        y: 0,
    });

    const handleWheel = (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();

        const scaleBy = 1.1;
        const stage = e.target.getStage();
        if (!stage) return;

        const pointerPosition = stage.getRelativePointerPosition(); //currently where pointer on
        if (!pointerPosition) return;

        const oldScale = stage.scaleX();

        const mousePointTo = {
            x: (pointerPosition.x - stage.x()) / oldScale,
            y: (pointerPosition.y - stage.y()) / oldScale,
        };

        let zoomDirection = e.evt.deltaY > 0 ? 1 : -1;

        // when we zoom on trackpad, e.evt.ctrlKey is true
        // in that case lets revert direction
        if (e.evt.ctrlKey) {
            zoomDirection = -zoomDirection;
        }

        var newScale = zoomDirection > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStageSpec({
            scale: newScale,
            x: pointerPosition.x - mousePointTo.x * newScale,
            y: pointerPosition.y - mousePointTo.y * newScale,
        });
    };

    const handleCanvasDragEnd = (e: KonvaEventObject<DragEvent>) => {
        e.evt.preventDefault();

        const stage = e.target.getStage();
        if (!stage) return;

        const pointerPosition = stage.getRelativePointerPosition(); //currently where pointer on
        if (!pointerPosition) return;

        const oldScale = stage.scaleX();

        const mousePointTo = {
            x: (pointerPosition.x - stage.x()) / oldScale,
            y: (pointerPosition.y - stage.y()) / oldScale,
        };

        setStageSpec({
            scale: oldScale,
            x: pointerPosition.x - mousePointTo.x * oldScale,
            y: pointerPosition.y - mousePointTo.y * oldScale,
        });
    };

    const [selectedInstructionId, setSelectedInstructionId] = React.useState<number | undefined>();
    const [secondSelectedInstructionId, setSecondSelectedInstructionId] = React.useState<number | undefined>();

    const [isAddingNewConnection, setIsAddingNewConnection] = React.useState(false);

    const firstSelectedInstruction = instructions.find(instruction => instruction.Id === selectedInstructionId);
    const secondSelectedInstruction = instructions.find(instruction => instruction.Id === secondSelectedInstructionId);
    const matchingEdge = firstSelectedInstruction?.CurrentInstructions.find(
        currInstruction => currInstruction.NextInstructionId === secondSelectedInstructionId
    );

    const [
        triggerUpsertInstruction,
        upsertInstructionResponse,
    ] = ProcedureApi.endpoints.upsertInstruction.useMutation();

    const handleDragEnd = (event: Konva.KonvaEventObject<DragEvent>) => {
        const id = event.target.id();

        setInstructions((prevInstruction): DraggableInstruction[] =>
            prevInstruction.map(instruction => {
                const isSelectedInstruction = String(instruction.Id) === id;
                if (isSelectedInstruction) {
                    const updatedInstruction: DraggableInstruction = {
                        ...instruction,
                        CanvasPositionX: Math.trunc(event.target.x()),
                        CanvasPositionY: Math.trunc(event.target.y()),
                        IsDragging: false,
                    };
                    triggerUpsertInstruction(updatedInstruction);
                    return updatedInstruction;
                } else return { ...instruction };
            })
        );
    };

    const handleFinishEditingText = () => {
        if (firstSelectedInstruction) triggerUpsertInstruction(firstSelectedInstruction);

        setSelectedInstructionId(undefined);
        setIsAddingNewConnection(false);
        setSecondSelectedInstructionId(undefined);
    };

    const handleInstructionCardClicked = (clickedInstructionId: number) => {
        if (selectedInstructionId === clickedInstructionId) return;

        if (firstSelectedInstruction) triggerUpsertInstruction(firstSelectedInstruction);

        if (selectedInstructionId && isAddingNewConnection) {
            setSecondSelectedInstructionId(clickedInstructionId);
        } else {
            setSelectedInstructionId(clickedInstructionId);
        }
    };

    const moveCameraToTopLeftInstruction = () => {
        const topLeftMostInstruction = instructions.sort(
            (a, b) => (a.CanvasPositionX || 0) - (b.CanvasPositionX || 0)
        )?.[0];

        if (!topLeftMostInstruction) {
            setStageSpec({
                scale: stageSpec.scale,
                x: 0,
                y: 0,
            });
            return;
        }

        setStageSpec({
            scale: stageSpec.scale,
            x: ((topLeftMostInstruction.CanvasPositionX || 0) - 50) * -stageSpec.scale,
            y: ((topLeftMostInstruction.CanvasPositionY || 0) - 50) * -stageSpec.scale,
        });
    };

    const stageCenter = {
        x: Math.trunc(((stageSpec.x || 0) - 50) / -stageSpec.scale),
        y: Math.trunc(((stageSpec.y || 0) - 50) / -stageSpec.scale),
    };

    const [
        triggerCreateInstruction,
        createInstructionResponse,
    ] = ProcedureApi.endpoints.upsertInstruction.useMutation();

    const handleCreateInstructionButtonClick = () => {
        if (castedProcedureId)
            triggerCreateInstruction({
                ProcedureId: castedProcedureId,
                HeaderText: '',
                BodyText: '',
                Image: null,
                ImageContent: null,
                CanvasPositionX: stageCenter.x,
                CanvasPositionY: stageCenter.y,
                IsStartOfProcedure: !instructions.length,
                CurrentInstructions: [],
                ReceiverItems: [],
            });
    };

    useNotificationByMutationResponseV2({
        response: createInstructionResponse,
        successFinishedFunction: () => {
            setSelectedInstructionId(createInstructionResponse?.data);
        },
    });

    return (
        <Box>
            <ProcedureDetailsCard />
            <Box sx={{ overflow: 'hidden' }}>
                <Stage
                    width={window.innerWidth * 0.97}
                    height={window.innerHeight * 0.68}
                    style={{
                        border: '1px solid grey',
                        backgroundColor: 'lightblue',
                    }}
                    ref={stageRef}
                    draggable={true}
                    scaleX={stageSpec.scale}
                    scaleY={stageSpec.scale}
                    x={stageSpec.x}
                    y={stageSpec.y}
                    onWheel={handleWheel}
                    onClick={e => {
                        if (e.currentTarget._id === e.target._id) {
                            handleFinishEditingText();
                        }
                    }}
                    onDragEnd={handleCanvasDragEnd}
                >
                    <Layer>
                        {instructions.map((instruction, index) => (
                            <React.Fragment key={instruction.Id}>
                                <InstructionCard
                                    instruction={instruction}
                                    onClick={() => {
                                        handleInstructionCardClicked(instruction.Id);
                                    }}
                                    onAddNextInstructionMapClick={() => {
                                        setIsAddingNewConnection(true);
                                        dispatch(
                                            setSnackbar({
                                                content: 'Click on instruction you want to create connection to',
                                                severity: 'warning',
                                            })
                                        );
                                    }}
                                    onDragEnd={handleDragEnd}
                                    isSelected={selectedInstructionId === instruction.Id}
                                    isSelectedSecond={secondSelectedInstructionId === instruction.Id}
                                    isAddingNewConnection={isAddingNewConnection}
                                    onEscapeKeyPressed={handleFinishEditingText}
                                    onChange={instruction => {
                                        setInstructions(prevInstructions => {
                                            return prevInstructions.map(prevInstruction => {
                                                if (prevInstruction.Id === selectedInstructionId)
                                                    return { ...instruction };
                                                return prevInstruction;
                                            });
                                        });
                                    }}
                                    onAddItemButtonClick={() => {
                                        setInstructionIdTryingToAddItemsTo(instruction.Id);
                                    }}
                                    onSetVariableButtonClick={() => {
                                        setInstructionIdTryingToAddVariablesTo(instruction.Id);
                                    }}
                                    key={instruction.Id}
                                />

                                {instruction.CurrentInstructions.map(currentInstruction => {
                                    return (
                                        <NextInstructionArrow
                                            firstInstruction={instruction}
                                            existingEdge={currentInstruction}
                                            isSelected={
                                                instruction.Id === selectedInstructionId &&
                                                currentInstruction.NextInstructionId === secondSelectedInstructionId
                                            }
                                            onDoubleClick={() => {
                                                setSelectedInstructionId(instruction.Id);
                                                setSecondSelectedInstructionId(currentInstruction.NextInstructionId);
                                            }}
                                            secondInstruction={instructions.find(
                                                i => i.Id === currentInstruction.NextInstructionId
                                            )}
                                            onFinishedUpsert={() => {
                                                setIsAddingNewConnection(false);
                                                setSecondSelectedInstructionId(undefined);
                                            }}
                                            key={currentInstruction.Id}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        ))}
                        {firstSelectedInstruction && secondSelectedInstruction && (
                            <NextInstructionArrow
                                firstInstruction={firstSelectedInstruction}
                                secondInstruction={secondSelectedInstruction}
                                existingEdge={matchingEdge}
                                isSelected={!!firstSelectedInstruction && !!secondSelectedInstruction}
                                onFinishedUpsert={() => {
                                    setIsAddingNewConnection(false);
                                    setSecondSelectedInstructionId(undefined);
                                }}
                                key={JSON.stringify(matchingEdge)}
                            />
                        )}
                    </Layer>
                </Stage>
            </Box>

            <CanvasSpeedDial
                onResetCameraButtonClick={moveCameraToTopLeftInstruction}
                onCreateInstructionButtonClick={handleCreateInstructionButtonClick}
            />

            {instructionTryingToAddItemsTo && (
                <InstructionAddItemDialog
                    instruction={instructionTryingToAddItemsTo}
                    onDialogClose={() => {
                        setInstructionIdTryingToAddItemsTo(undefined);
                    }}
                />
            )}

            {instructionTryingToVariablesTo && (
                <InstructionSetVariableDialog
                    instruction={instructionTryingToVariablesTo}
                    onDialogClose={() => {
                        setInstructionIdTryingToAddVariablesTo(undefined);
                    }}
                />
            )}
        </Box>
    );
};

export default CanvasStage;
