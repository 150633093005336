import { Box, Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { HistoryBillingShippingDisplay } from './HistoryBillingShippingDisplay';
import { HistoryOrderDisplay } from './HistoryOrderDisplay';
import { HistoryItemsDisplay } from './HistoryItemsDisplay';

const historyTitle = 'History Order Details';
const historyItemsTitle = 'Items';

export const HistoryDialog = React.memo(
    ({
        showDialog = false,
        handleOpenDialog,
    }: {
        showDialog?: boolean;
        handleOpenDialog: (openState: boolean) => void;
    }) => {
        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} onClose={handleClose} fullWidth maxWidth="lg">
                <Box sx={{ m: 3 }}>
                    <DialogTitle sx={{ mb: -3, mx: -1, p: 2 }}>{historyTitle}</DialogTitle>
                    <DialogCloseButton handleClose={handleClose} />
                    <HistoryOrderDisplay />
                    <DialogTitle sx={{ mb: -3, mx: -1, px: 2 }}>{historyItemsTitle}</DialogTitle>
                    <HistoryItemsDisplay />
                    <Box sx={{ pt: 1 }}>
                        <HistoryBillingShippingDisplay />
                    </Box>
                </Box>
            </Dialog>
        );
    }
);
