import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { enableMapSet } from 'immer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../archive/sagas/rootSaga';
import { concatMiddleware, reducers, slicesToNotPersist } from './reducer';
const sagaMiddleware = createSagaMiddleware();

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// offlineConfig.persistOptions = { storage: localforage }; // store offline data in indexedDB

// let store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(sagaMiddleware)));
// let store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(sagaMiddleware, logger)));
enableMapSet();

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: slicesToNotPersist,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        concatMiddleware(
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these action types
                    ignoredActions: [
                        'workOrderApi/executeQuery/fulfilled',
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                    // Ignore these field paths in all actions
                    ignoredActionPaths: ['meta.arg', 'meta.baseQueryMeta'],
                    // Ignore these paths in the state
                    ignoredPaths: ['workOrderApi.queries'],
                },
            }).concat(sagaMiddleware)
        ),
});

setupListeners(store.dispatch);

//sagaMiddlware, logger
sagaMiddleware.run(rootSaga);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
