import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { setForm } from '../../workOrderSlice';
import WorkOrderBillingItems from '../Billing/WorkOrderBillingItems';
import WorkOrderBillOfMaterials from '../BillOfMaterials/WorkOrderBillOfMaterials';
import EditHistoryLogs from './EditHistoryLogs';
import FormDivider from './FormDivider';
import Procedures from './Procedure/Procedures';

interface WorkOrderFormRightProps {
    isErrorDisplayed: boolean;
    isDisabled: boolean;
}

const WorkOrderFormRight = ({ isErrorDisplayed, isDisabled }: WorkOrderFormRightProps) => {
    const dispatch = useAppDispatch();
    const form = useAppSelector(state => state.workOrder.form);
    const mode = useAppSelector(state => state.workOrder.workOrderMode);
    const tab = useAppSelector(state => state.workOrder.tab);

    return (
        <Box>
            <FormDivider text={'Services Billing'} />

            <WorkOrderBillingItems isDisabled={isDisabled} />

            <FormDivider text={'Bill of Materials'} />

            <WorkOrderBillOfMaterials isDisabled={isDisabled} />

            <TextField
                variant="outlined"
                label="Bill of Materials Note"
                value={form.BillOfMaterialsComment || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    dispatch(setForm({ ...form, BillOfMaterialsComment: newValue }));
                }}
                sx={{ width: '100%', my: 2 }}
                multiline
                rows={3}
                InputProps={{
                    readOnly: isDisabled,
                }}
            />

            {/* hide until feature is ready */}
            {false && tab === 'Work Orders' && <Procedures isDisabled={isDisabled} />}

            {mode !== 'Create' && tab === 'Work Orders' && <EditHistoryLogs />}
        </Box>
    );
};

export default WorkOrderFormRight;
