import {
    FEDEX_TRACKING_PARSING_REGEX,
    IS_TWO_DIMENSIONAL_BARCODE_REGEX,
    IS_USPS_OR_DHL_PARSING_REGEX,
    LAST_DIGITS_REGEX,
} from '../../common/constants/trackingParsingRegex';
import {
    CLIENT_CODE_REGEX_FROM_REF_PO,
    COMPLETED_STATUS,
    DISPOSITION_OTHER_ID,
    MISSING_SERIAL_REASON_OTHER_ID,
    RMA_STATUS,
} from './constants';
import { ReceivedItemUnitDetail, Serial } from './rmaModels';

enum PreventDefaultKeyPresses {
    Tab = 84,
    F1 = 70,
    F2 = 70,
    F3 = 70,
    F4 = 70,
    F5 = 70,
    F6 = 70,
    F7 = 70,
    F8 = 70,
    F9 = 70,
    F10 = 70,
    F11 = 70,
    F12 = 70,
}
const ENTER_KEY_STR = 'Enter';

export const isRmaCompleted = (status: number | null | undefined) => {
    if (!status) {
        return false;
    }

    return RMA_STATUS[status] == COMPLETED_STATUS;
};

export const serialDisplay = (serialRecord: Serial, hasSerial2: boolean) => {
    if (hasSerial2) {
        return `${serialRecord?.Serial1 || '(none)'}, ${serialRecord?.Serial2 || '(none)'}`;
    }
    return serialRecord.Serial1;
};

export const checkItemUnitReadyToSubmit = (
    itemUnit: ReceivedItemUnitDetail,
    isSerialized: boolean,
    hasEnoughSerials?: boolean
) => {
    const hasDispositionNotesError = itemUnit.Disposition?.Id === DISPOSITION_OTHER_ID && !!!itemUnit.DispositionNotes;

    if (!!!itemUnit.Disposition) return false;
    if (hasDispositionNotesError) return false;

    if (!isSerialized) {
        return true;
    }

    // Optional serial count bypass
    if (!!hasEnoughSerials) {
        return true;
    }
    // Missing serial with no reason selected
    if (itemUnit.Serials.length === 0 && itemUnit.MissingSerialReason === null) return false;

    // Missing serial reason selected as other and no notes
    if (itemUnit.MissingSerialReason?.Id === MISSING_SERIAL_REASON_OTHER_ID && !!!itemUnit.SerialNotes) {
        return false;
    }
    return true;
};

export const captureClientCodeFromLabel = (label: string | null | undefined) => {
    if (!label) {
        return null;
    }

    const match = label.match(CLIENT_CODE_REGEX_FROM_REF_PO);
    return match ? match[0] : null;
};

export const getDetailsButtonLabel = (isRmaCompleted: boolean, isReceivedItemValidToSubmit: boolean) => {
    if (isRmaCompleted) return 'SHOW DETAILS';
    else if (isReceivedItemValidToSubmit) return 'EDIT';
    return 'PROCESS';
};

// Mostly used for guns scanning 2-D tracking barcodes that has functional keys set
export const filterKeyDownForTracking = (
    event: React.KeyboardEvent<HTMLInputElement>,
    stringValue: string,
    setStringValue: (value: string) => void
) => {
    const currentKeycode = event.key.charCodeAt(0);
    // Prevent these keys' default behavior but still note them being pressed as part of the string
    if (Object.values(PreventDefaultKeyPresses).includes(currentKeycode)) {
        event.preventDefault();
        event.stopPropagation();
        if (event.key.length > 1) {
            setStringValue(stringValue + `{${event.key}}`);
        } else {
            setStringValue(stringValue + event.key);
        }
    }
};

export const parseTrackingNumber = (tracking: string | null | undefined) => {
    if (!tracking) {
        return '';
    }
    // FEDEX
    if (IS_TWO_DIMENSIONAL_BARCODE_REGEX.test(tracking)) {
        const match = tracking?.match(FEDEX_TRACKING_PARSING_REGEX);
        if (!!match) {
            const matchedTrackingNumber = match[0];
            // If matching number is exactly 16, only the first 12 is the tracking number
            if (matchedTrackingNumber.length === 16) {
                return matchedTrackingNumber.substring(0, 12);
            }
            return matchedTrackingNumber;
        }
    }

    // USPS and DHL
    if (IS_USPS_OR_DHL_PARSING_REGEX.test(tracking)) {
        const match = tracking?.match(LAST_DIGITS_REGEX);
        if (!!match) {
            return match[0];
        }
    }

    // General cleanup for scanned strings that made through the above
    // Remove substring matching this pattern {chars}
    const withoutBraces = tracking.replace(/\{.*?\}/g, '');

    //Remove non-alpha numeric except for these characters: #, -, _
    const cleanedText = withoutBraces.replace(/[^a-zA-Z0-9\s#_\-]/g, '');
    return cleanedText;
};

export const submitOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, submit: () => void) => {
    if (event.key === ENTER_KEY_STR) {
        submit();
    }
};
