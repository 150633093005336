import { Accordion, AccordionDetails, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ExpectedItem, ReceivedItem, ReceivedItemUnitDetail, Serial } from '../../rmaModels';
import { ReceivedItemSummaryPanelAccordianSummary } from './ReceivedItemSummaryPanelAccordianSummary';
import { serialDisplay } from '../../utilities';

interface GridUnit extends ReceivedItemUnitDetail {
    datagridId: number;
}

export const ReceivedItemSummaryPanelAccordian = ({
    receivedItem,
    expectedItem,
    qtyMissing,
    qtyExtra,
}: {
    receivedItem: ReceivedItem;
    expectedItem?: ExpectedItem;
    qtyMissing: number;
    qtyExtra: number;
}) => {
    const gridUnitsData: GridUnit[] =
        receivedItem.ItemUnitDetails.map((unit, index) => {
            const gridUnit: GridUnit = { ...unit, datagridId: index + 1 };
            return gridUnit;
        }) || [];

    const columns: GridColDef[] = [
        {
            align: 'center',
            headerAlign: 'center',
            field: 'datagridId',
            headerName: '#',
            flex: 1,
            type: 'number',
            renderCell: params => (
                <Box>
                    <Typography>{params?.value}</Typography>
                </Box>
            ),
        },
        {
            align: 'left',
            headerAlign: 'left',
            field: 'Disposition',
            headerName: 'Disposition',
            flex: 4,
            valueGetter: (value: any) => {
                return value?.Label || '-';
            },
            renderCell: params => (
                <Box>
                    <Typography>{params?.value}</Typography>
                </Box>
            ),
        },
        {
            align: 'left',
            headerAlign: 'left',
            field: 'DispositionNotes',
            headerName: 'Disposition Notes',
            flex: 5,
            valueGetter: (value: any, row) => {
                return value || '-';
            },
            renderCell: params => (
                <Box>
                    <Typography>{params.value}</Typography>
                </Box>
            ),
        },
    ];

    if (receivedItem.IsSerialized) {
        columns.push({
            align: 'left',
            headerAlign: 'left',
            field: 'Serials',
            headerName: 'Serial',
            flex: 3,
            valueGetter: (value: any) => {
                return !!value && value.length > 0
                    ? serialDisplay(value[0], !!receivedItem.SerialVerify?.Serial2Alias)
                    : null;
            },

            renderCell: params => (
                <Box>
                    {!!params.value ? (
                        <Typography>{params.value}</Typography>
                    ) : (
                        <Typography color="error.main">MISSING</Typography>
                    )}
                </Box>
            ),
        });

        columns.push({
            align: 'left',
            headerAlign: 'left',
            field: 'MissingSerialReason',
            headerName: 'Missing Serial Reason',
            flex: 5,
            valueGetter: (value: any, row) => {
                return !!row?.MissingSerialReason?.Reason ? row.MissingSerialReason.Reason : '-';
            },
            renderCell: params => (
                <Box>
                    <Typography>{params.value}</Typography>
                </Box>
            ),
        });

        columns.push({
            align: 'left',
            headerAlign: 'left',
            field: 'SerialNotes',
            headerName: 'Serial Notes',
            flex: 3,
            valueGetter: (value: any, row) => {
                return !!row?.SerialNotes ? row.SerialNotes : '-';
            },
            renderCell: params => (
                <Box>
                    <Typography>{params.value}</Typography>
                </Box>
            ),
        });
    }
    return (
        <Accordion>
            <ReceivedItemSummaryPanelAccordianSummary
                qtyMissing={qtyMissing}
                qtyExtra={qtyExtra}
                receivedItem={receivedItem}
                expectedItem={expectedItem}
            />
            <AccordionDetails>
                <DataGrid
                    hideFooter={gridUnitsData.length < 100}
                    rows={gridUnitsData || []}
                    columns={columns}
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'}
                    autoHeight
                    getRowId={(row: any) => row.datagridId}
                />
            </AccordionDetails>
        </Accordion>
    );
};
