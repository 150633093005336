import { takeLatest, put, call, all, fork } from 'redux-saga/effects';
import {
    GET_SHIP_HISTORY_BY_YEAR,
    GET_SHIP_HISTORY_BY_YEAR_SUCCESS,
    GET_SHIP_HISTORY_BY_YEAR_FAILURE,
    GET_WAREHOUSE_SUCCESS,
    GET_WAREHOUSE_FAILURE,
    GET_WAREHOUSE,
    SET_ORDERS_LOADING,
    GET_SHIP_HISTORY_BY_DATE_RANGE_SUCCESS,
    GET_SHIP_HISTORY_BY_DATE_RANGE_FAILURE,
    GET_SHIP_HISTORY_BY_DATE_RANGE,
    SET_WAREHOUSE_CLIENT_GRAPH_LOADING,
    GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_SUCCESS,
    GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_FAILURE,
    GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE,
} from '../store/actions/actionTypes';
import {
    getShipHistoryByDateRangeApi,
    getShipHistoryByYearApi,
    getShipHistoryByYearMonthApi,
    getWarehouseApi,
} from '../store/api/ordersApi';

export function* getShipHistoryByYearSaga(action) {
    try {
        yield put({ type: SET_ORDERS_LOADING });
        const response = yield call(getShipHistoryByYearApi, action);
        const shipHistory = response.data?.shipHistory;
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        const state = {
            '2021': {
                months: [],
                data: [],
            },
            '2020': {
                months: [],
                data: [],
            },
        };

        months.forEach(element => {
            if (shipHistory[2021][element]) {
                state['2021'].months.push(element);
                state['2021'].data.push(shipHistory[2021][element]);
            }
            if (shipHistory[2020] && shipHistory[2020][element]) {
                state['2020'].months.push(element);
                state['2020'].data.push(shipHistory[2020][element]);
            }
        });

        if (!shipHistory[2020]) {
            const newData = {};
            for (const key in shipHistory[2021]) {
                if (Object.hasOwnProperty.call(shipHistory[2021], key)) {
                    const element = shipHistory[2021][key] * 2;
                    newData[key] = element;
                }
            }
            months.forEach(element => {
                if (newData[element]) {
                    state['2020'].months.push(element);
                    state['2020'].data.push(newData[element]);
                }
            });
        }
        yield put({ type: GET_SHIP_HISTORY_BY_YEAR_SUCCESS, payload: state });
    } catch (error) {
        yield put({ type: GET_SHIP_HISTORY_BY_YEAR_FAILURE, errorMessage: error });
    }
}

export function* getWarehouseSaga(action) {
    try {
        yield put({ type: SET_ORDERS_LOADING });
        const { data } = yield call(getWarehouseApi, action);
        yield put({ type: GET_WAREHOUSE_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: GET_WAREHOUSE_FAILURE, errorMessage: error });
    }
}

export function* getShipHistoryByDateRangeSaga(action) {
    try {
        yield put({ type: SET_WAREHOUSE_CLIENT_GRAPH_LOADING });
        const { data } = yield call(getShipHistoryByDateRangeApi, action);

        yield put({ type: GET_SHIP_HISTORY_BY_DATE_RANGE_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: GET_SHIP_HISTORY_BY_DATE_RANGE_FAILURE, errorMessage: error });
    }
}

export function* getShipHistoryByYearMonthSaga(action) {
    try {
        yield put({ type: SET_WAREHOUSE_CLIENT_GRAPH_LOADING });
        const { data } = yield call(getShipHistoryByYearMonthApi, action);

        yield put({ type: GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_FAILURE, errorMessage: error });
    }
}

function* getShipHistoryByYearWatcher() {
    yield takeLatest(GET_SHIP_HISTORY_BY_YEAR, getShipHistoryByYearSaga);
}

function* getWarehouseWatcher() {
    yield takeLatest(GET_WAREHOUSE, getWarehouseSaga);
}

function* getShipHistoryByDateRangeWatcher() {
    yield takeLatest(GET_SHIP_HISTORY_BY_DATE_RANGE, getShipHistoryByDateRangeSaga);
}

function* getShipHistoryByYearMonthWatcher() {
    yield takeLatest(GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE, getShipHistoryByYearMonthSaga);
}

export default function* OrdersSaga() {
    yield all([
        fork(getShipHistoryByYearWatcher),
        fork(getWarehouseWatcher),
        fork(getShipHistoryByDateRangeWatcher),
        fork(getShipHistoryByYearMonthWatcher),
    ]);
}
