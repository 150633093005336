import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../../app/store';
import { overrideForm, setForm } from '../../workOrderSlice';
import { AttachmentItem } from '../../workOrderModels';

interface AttachmentItemFormProps {
    isDisabled: boolean;
    indexToUpdate?: number;
    handleOnSubmit?: () => void;
}

const AttachmentItemForm = ({ isDisabled, indexToUpdate = -1, handleOnSubmit = () => {} }: AttachmentItemFormProps) => {
    const attachmentItems = useAppSelector(state => state.workOrder.form.AttachmentItems);
    const dispatch = useAppDispatch();

    const initialItem: AttachmentItem =
        attachmentItems?.[indexToUpdate] || //index to update is -1
        ({
            Id: null,
            WorkOrderId: null,
            Name: '',
            Url: '',
        } as AttachmentItem);

    const [itemBeingAdded, setItemBeingAdded] = React.useState<AttachmentItem>(initialItem);
    const [isErrorDisplayed, setIsErrorDisplayed] = React.useState(false);

    const isNameError = !itemBeingAdded?.Name;
    const isUrlError = !itemBeingAdded?.Url;

    const isError = isNameError || isUrlError;

    const isNameErrorDisplayed = isNameError && isErrorDisplayed;
    const isUrlErrorDisplayed = isUrlError && isErrorDisplayed;

    const handleAddUrlButtonClick = () => {
        if (isError) {
            setIsErrorDisplayed(true);
            return;
        }

        if (indexToUpdate !== -1) {
            let newItemsList = [...attachmentItems];
            newItemsList.splice(indexToUpdate, 1);
            dispatch(overrideForm({ AttachmentItems: [...newItemsList, { ...itemBeingAdded }] }));
            handleOnSubmit();
        } else {
            dispatch(overrideForm({ AttachmentItems: [...attachmentItems, { ...itemBeingAdded }] }));
        }

        setItemBeingAdded({ ...initialItem });
        setIsErrorDisplayed(false);
    };

    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                handleAddUrlButtonClick();
            }}
        >
            <Box sx={{ my: 2, display: isDisabled ? 'none' : 'flex' }}>
                <TextField
                    label="Name"
                    sx={{ flex: 1 }}
                    disabled={isDisabled}
                    value={itemBeingAdded.Name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        setItemBeingAdded(prev => {
                            return { ...prev, Name: newValue };
                        });
                    }}
                    error={isNameErrorDisplayed}
                    helperText={isNameErrorDisplayed ? 'Required' : ' '}
                />
                <TextField
                    label="URL"
                    sx={{ flex: 2, mx: 2 }}
                    disabled={isDisabled}
                    value={itemBeingAdded.Url}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue = event.target.value;
                        setItemBeingAdded(prev => {
                            return { ...prev, Url: newValue };
                        });
                    }}
                    error={isUrlErrorDisplayed}
                    helperText={isUrlErrorDisplayed ? 'Required' : ' '}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ flex: 1, my: 1 }}
                    disabled={isDisabled}
                    type="submit"
                >
                    {'Add Link'}
                </Button>
            </Box>
        </form>
    );
};

export default AttachmentItemForm;
