import { useAppSelector } from '../../../app/store';
import { TimeoutSnackbar } from './TimeoutSnackbar';

const GlobalSnackbar = () => {
    const snackbarOpen = useAppSelector(state => state.notification.snackbarOpen);
    const snackbarSeverity = useAppSelector(state => state.notification.snackbarSeverity);
    const snackbarContent = useAppSelector(state => state.notification.snackbarContent);

    return (
        <TimeoutSnackbar
            showSnackbar={snackbarOpen}
            snackbarSeverity={snackbarSeverity}
            snackbarMessage={snackbarContent}
        />
    );
};

export default GlobalSnackbar;
