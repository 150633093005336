import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import orderLookupApi from '../../orderLookupApi';
import { Item, Serial } from '../../orderLookupModels';
import { ReadOnlyTextField } from '../ReadOnlyTextField';

export const SerialsDeleteDialog = React.memo(
    ({
        itemData,
        serialData,
        handleOpenDialog,
        showDialog = false,
    }: {
        itemData: Item | undefined;
        serialData: Serial | undefined;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const deleteWarningMessage = 'Are you sure want to delete this serial?';
        const [deleteSerialsEdits, editResponse] = orderLookupApi.endpoints.deleteSerial.useMutation();
        const momCode = useAppSelector(state => state.orderLookup.momCode);

        const saveSerialsEdits = () => {
            deleteSerialsEdits({
                serials: serialData,
                momCode: momCode,
                itemId: itemData?.ItemId,
            });
            handleClose();
        };

        const handleClose = () => {
            handleOpenDialog(false);
        };

        return (
            <Dialog open={showDialog} fullWidth maxWidth={itemData?.HasSerials2Field ? 'lg' : 'sm'}>
                <Box sx={{ m: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <DialogTitle sx={{ flex: 4, m: 0, p: 1 }}>{deleteWarningMessage}</DialogTitle>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <ReadOnlyTextField label={'Serial'} value={serialData?.Serial} />
                        {itemData?.HasSerials2Field ? (
                            <ReadOnlyTextField label={'Serial2'} value={serialData?.Serial2} />
                        ) : (
                            ''
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button sx={{ flex: 1, m: 0, p: 1 }} color="error" onClick={saveSerialsEdits}>
                            DELETE
                        </Button>
                        <Button sx={{ flex: 1, m: 0, p: 1 }} onClick={handleClose}>
                            CANCEL
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        );
    }
);
