import { DISCARD_TASK, GET_REVIEW_TASK_DETAILS_URL, GET_USER_TASKS_URL } from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getTaskByUserIdAndStateApi = async action => {
    const res = await API.post(`${GET_USER_TASKS_URL}`, {
        assignedToUserId: action.id,
        taskState: action.state,
    });
    return res;
};

export const getFilteredTasksApi = async action => {
    const res = await API.post(`${GET_USER_TASKS_URL}`, action.payload || {});
    return res;
};

export const getReviewTaskDetailsApi = async action => {
    const finalData = action.payload || {};
    const res = await API.post(`${GET_REVIEW_TASK_DETAILS_URL}`, finalData);
    return res;
};

export const discardTaskApi = async ({ payload }) => {
    return await API.post(
        `${DISCARD_TASK}?taskId=${payload.taskId}&completedDateTimeOffSet=${payload.completedDateTimeOffSet}&updatedByUserId=${payload.updatedByUserId}`
    );
};
