import React from 'react';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Divider } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { deepPurple } from '@mui/material/colors';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import { LinkToTasks, LinkToSupervisor, LinkToPallets } from '../../../features/authentication';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { logOut } from '../../../features/authentication/authenticationSlice';

const PREFIX = 'Drawer';

const classes = {
    list: `${PREFIX}-list`,
    avatar: `${PREFIX}-avatar`,
    profile: `${PREFIX}-profile`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.list}`]: {
        width: 200,
    },

    [`& .${classes.avatar}`]: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
        width: theme.spacing(7),
        height: theme.spacing(7),
    },

    [`& .${classes.profile}`]: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

export const Drawer = ({ open, toggleDrawer }) => {
    const username = useAppSelector(state => state.authentication.username);

    const dispatch = useAppDispatch();

    const list = (
        <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItem alignItems="center" className={classes.profile}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar} alt="Remy Sharp">
                            {username
                                .split(' ')
                                .map(str => str[0])
                                .join('')}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={username}></ListItemText>
                </ListItem>
                <Divider variant="middle" light />
                <ListItem>
                    <ListItemIcon>
                        <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <LinkToTasks />
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <LinkToSupervisor />
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <ViewAgendaIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <LinkToPallets />
                    </ListItemText>
                </ListItem>
                <ListItem onClick={() => dispatch(logOut())}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" style={{ cursor: 'pointer' }}></ListItemText>
                </ListItem>
            </List>
        </div>
    );
    return (
        <Root>
            <SwipeableDrawer anchor="left" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                {list}
            </SwipeableDrawer>
        </Root>
    );
};
