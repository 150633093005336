import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import skuVelocityApi from '../skuVelocityApi';

interface WrappedAutoCompleteProps
    extends Omit<
        AutocompleteProps<string, undefined, boolean | undefined, false>,
        'options' | 'renderInput' | 'value'
    > {
    textFieldProps?: TextFieldProps;
    value: string;
}

const arrowDropDownIcon = <ArrowDropDownIcon />;

const circularProgress = <CircularProgress color="inherit" size={20} />;

export default function LocationDropdown({ textFieldProps, value, ...props }: WrappedAutoCompleteProps) {
    const response = skuVelocityApi.endpoints.getLocations.useQuery();
    const formattedResponse = response?.data?.map(location => location.toUpperCase()) || [];

    return (
        <Autocomplete
            value={value}
            options={formattedResponse}
            loading={response.isFetching}
            popupIcon={props.readOnly ? '' : arrowDropDownIcon}
            renderInput={params => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {response.isFetching ? circularProgress : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    {...textFieldProps}
                />
            )}
            {...props}
        />
    );
}
