import { red } from '@mui/material/colors';
import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppDispatch } from '../../app/store';
import {
    decrementIsNonIntrusiveSpinnerOpen,
    decrementIsSpinnerOpen,
    incrementIsNonIntrusiveSpinnerOpen,
    incrementIsSpinnerOpen,
    setDialog,
    setSnackbar,
} from '../../features/notification/notificationSlice';

//given a rtk useQuery response, will provide user feedback
//intrusive defined as if action will stop user from taking other action

interface useNotificationByQueryResponsePayload {
    response: TypedUseQueryStateResult<any, unknown, any>;
    errorMessage?: string; //intrusively shows dialog with msg
    successMessage?: string; //nonintrusively shows success snackbar with msg
    isLoaderIntrusive?: boolean; //is fetching spinner intrusive
    finishedFunction?: () => void; //called when done fetching
    errorFinishedFunction?: () => void;
    successFinishedFunction?: () => void;
    shouldUseGlobalSpinner?: boolean;
}

export default function useNotificationByQueryResponse({
    response,
    errorMessage = '',
    successMessage = '',
    isLoaderIntrusive = true,
    finishedFunction = () => {},
    errorFinishedFunction = () => {},
    successFinishedFunction = () => {},
    shouldUseGlobalSpinner = false,
}: useNotificationByQueryResponsePayload): void {
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        if (!response.isUninitialized) {
            if (shouldUseGlobalSpinner) displaySpinner();

            if (!response.isFetching) {
                displayNotification();
                callFinishedFunctions();
            }
        }
    }, [response.isFetching]);

    const displaySpinner = () => {
        if (isLoaderIntrusive) {
            if (response.isFetching) dispatch(incrementIsSpinnerOpen());
            else dispatch(decrementIsSpinnerOpen());
        } else {
            if (response.isFetching) dispatch(incrementIsNonIntrusiveSpinnerOpen());
            else dispatch(decrementIsNonIntrusiveSpinnerOpen());
        }
    };

    const displayNotification = () => {
        if (errorMessage && response.isError) {
            dispatch(
                setDialog({
                    title: 'Error',
                    content: errorMessage,
                    color: red['A100'],
                })
            );
        }

        if (successMessage && response.isSuccess) {
            dispatch(
                setSnackbar({
                    content: successMessage,
                    severity: 'success',
                })
            );
        }
    };

    const callFinishedFunctions = () => {
        finishedFunction();

        if (response.isError) {
            errorFinishedFunction();
        }

        if (response.isSuccess) {
            successFinishedFunction();
        }
    };
}
