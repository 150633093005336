import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React, { useRef } from 'react';
import textFieldFocus from '../../../rapidQc/utils/textFieldFocus';
import { MISSING_SERIAL_REASON_OTHER_ID } from '../../constants';
import rmaApi from '../../rmaApi';
import { ItemReceivedMissingSerialReason, ReceivedItem, ReceivedItemUnitDetail } from '../../rmaModels';
import { ScannedSerialsDisplay } from '../Serials/ScannedSerialsDisplay';
import { ScanSerialsDialog } from '../Serials/ScanSerialsDialog';
import { useAppSelector } from '../../../../app/store';
import { isRmaCompleted } from '../../utilities';

export const ReceivedItemUnitDetailIndividualFormSerial = React.memo(
    ({
        editingItem,
        editingItemUnit,
        setReceivedItemUnit,
        showErrors = false,
    }: {
        editingItem: ReceivedItem;
        editingItemUnit: ReceivedItemUnitDetail;
        setReceivedItemUnit: (
            unit: ReceivedItemUnitDetail | ((prevUnit: ReceivedItemUnitDetail) => ReceivedItemUnitDetail)
        ) => void;
        showErrors?: boolean;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const [noSerialChecked, setNoSerialChecked] = React.useState(editingItemUnit.MissingSerialReason != null);
        const [showSerialScanDialog, setShowSerialScanDialog] = React.useState(false);

        const missingSerialReasonOptions: ItemReceivedMissingSerialReason[] =
            rmaApi.endpoints.getMissingSerialReasons.useQuery({}).data?.Data || [];

        const serialNotesTextfieldRef = useRef<HTMLInputElement>(null);

        React.useEffect(() => {
            if (noSerialChecked) {
                setReceivedItemUnit({ ...editingItemUnit, Serials: [] });
            } else {
                setReceivedItemUnit({ ...editingItemUnit, MissingSerialReason: null, SerialNotes: null });
            }
        }, [noSerialChecked]);

        React.useEffect(() => {
            setNoSerialChecked(editingItemUnit.MissingSerialReason != null);
        }, [editingItemUnit.MissingSerialReason]);

        React.useEffect(() => {
            if (editingItemUnit.MissingSerialReason?.Id === MISSING_SERIAL_REASON_OTHER_ID) {
                textFieldFocus({ textFieldRef: serialNotesTextfieldRef });
            }
        }, [editingItemUnit.MissingSerialReason]);

        if (!editingItem.IsSerialized && editingItem.Serials.length <= 0) {
            return <React.Fragment />;
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3, mr: 2 }}>
                {noSerialChecked ? (
                    <Box sx={{ display: 'flex', flex: 3, flexDirection: 'column' }}>
                        <FormControl variant="standard" sx={{ flex: 1 }}>
                            <InputLabel id="missing-serial-label">SELECT REASON</InputLabel>
                            <Select
                                disabled={isRmaCompleted(rma?.Status)}
                                error={showErrors && editingItemUnit.MissingSerialReason === null}
                                labelId="serial-label"
                                id="serial"
                                value={editingItemUnit.MissingSerialReason?.Id}
                                onChange={event => {
                                    setReceivedItemUnit({
                                        ...editingItemUnit,
                                        MissingSerialReason: missingSerialReasonOptions.find(
                                            option => option.Id === event.target.value
                                        ),
                                        SerialNotes:
                                            event.target.value === MISSING_SERIAL_REASON_OTHER_ID
                                                ? editingItemUnit.SerialNotes
                                                : null,
                                    });
                                }}
                                label="SELECT REASON"
                            >
                                {missingSerialReasonOptions.map(option => (
                                    <MenuItem value={option.Id}>{option.Reason}</MenuItem>
                                ))}
                            </Select>
                            {showErrors && editingItemUnit.MissingSerialReason === null && (
                                <FormHelperText sx={{ color: 'error.main' }}>Select a reason</FormHelperText>
                            )}
                        </FormControl>
                        {editingItemUnit.MissingSerialReason?.Id === MISSING_SERIAL_REASON_OTHER_ID && (
                            <TextField
                                sx={{ flex: 1, mt: 1 }}
                                disabled={isRmaCompleted(rma?.Status)}
                                fullWidth
                                value={editingItemUnit.SerialNotes}
                                id="serialNotes"
                                label="ENTER REASON"
                                inputRef={serialNotesTextfieldRef}
                                error={showErrors && !!!editingItemUnit.SerialNotes}
                                variant="standard"
                                onChange={event => {
                                    setReceivedItemUnit({
                                        ...editingItemUnit,
                                        SerialNotes: event.target.value,
                                    });
                                }}
                            />
                        )}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', mt: 2 }}>
                        <ScanSerialsDialog
                            receivedItem={editingItem}
                            receivedItemUnitDetail={editingItemUnit}
                            setReceivedItemUnitDetail={setReceivedItemUnit}
                            handleOpenDialog={setShowSerialScanDialog}
                            showDialog={showSerialScanDialog}
                        />
                        <Box sx={{ flex: 1.5, mr: 1 }}>
                            <ScannedSerialsDisplay
                                serials={editingItemUnit.Serials}
                                receivedItem={editingItem}
                                isSerialized={editingItem?.IsSerialized}
                                missingSerialCount={(editingItem.IsSerialized ? 1 : 0) - editingItemUnit.Serials.length}
                            />
                        </Box>
                        <Box sx={{ flex: 2, mb: -3, display: 'flex', flexDirection: 'column' }}>
                            <Button
                                disabled={!editingItem.IsSerialized || isRmaCompleted(rma?.Status)}
                                onClick={() => {
                                    setShowSerialScanDialog(true);
                                }}
                                variant="contained"
                                size="large"
                                sx={{ flex: 2 }}
                            >
                                {editingItemUnit.Serials?.length > 0 ? 'EDIT' : 'SCAN'} SERIAL
                            </Button>
                            {showErrors && editingItemUnit.Serials.length < 1 && (
                                <FormHelperText sx={{ color: 'error.main', flex: 0.5 }}>
                                    Scan serial or select "NO SERIAL"
                                </FormHelperText>
                            )}
                        </Box>
                    </Box>
                )}
                <FormControlLabel
                    sx={{ flex: 1.5 }}
                    disabled={isRmaCompleted(rma?.Status)}
                    label="NO SERIAL"
                    control={
                        <Checkbox
                            checked={noSerialChecked}
                            onChange={event => setNoSerialChecked(event.target.checked)}
                        />
                    }
                />
            </Box>
        );
    }
);
