import { useAppSelector } from '../../../app/store';
import {
    OrderData,
    ItemSerial,
    SubmitPayload,
    PauseItem,
    PartialSubmitPayload,
    QCLogElement,
    QCLog,
} from '../qualityControlModels';

const parseOrderDataItemsToSubmitItems = (orderData: OrderData) => {
    let result: ItemSerial[] = [];
    orderData.Items.forEach(item => {
        //serial2 only used with exactly 2 nonUPCscans
        if (item.PerUnit == 2) {
            const newItem: ItemSerial = {
                Id: item.ItemId,
                Item: item.Sku,
                Serial1: item?.NonUPCScans?.find(scan => scan.Key == 1)?.Value || 'invalid',
                Serial2: item?.NonUPCScans?.find(scan => scan.Key == 2)?.Value || 'invalid',
            };

            result.push(newItem);
        } else {
            item.NonUPCScans?.forEach(scan => {
                const newItem: ItemSerial = {
                    Id: item.ItemId,
                    Item: item.Sku,
                    Serial1: scan.Value,
                    Serial2: '',
                };

                result.push(newItem);
            });
        }
    });
    return result;
};

export const createSubmitBody = (orderData: OrderData, QCLog: QCLog[]): SubmitPayload => {
    const { username } = useAppSelector(state => state.authentication);

    const submitData = {
        client: orderData.PackageId.Mom,
        order: orderData.PackageId.OrderNum,
        inpart: orderData.PackageId.Inpart,
        system: 'n/a',
        version: 'n/a',
        user: username,
        message: `${orderData.PackageId.OrderNum}${
            orderData.PackageId.Inpart
        } QC Completed by ${username} at ${new Date().toISOString()}`,
        itemId: orderData.Items.map(item => item.ItemId),
        serials: parseOrderDataItemsToSubmitItems(orderData),
        qclog: QCLog,
    };

    return submitData;
};

const parseOrderDataItemsToPartialSubmitItems = (orderData: OrderData) => {
    //need to include previous paused
    //scan order must be preserved when adding to Serial.PausedSerials

    let result: PauseItem[] = [];
    orderData.Items.forEach(item => {
        const newItem: PauseItem = {
            ItemId: item.ItemId,
            PendingQuantity: item.Quants,
            CompletedQuantity: item.Quantp,
            TotalQuantity: item.Quanto,
            NonUPCScans: item?.NonUPCScans
                ? [...item.Serial.PausedSerials, ...item?.NonUPCScans]
                : item.Serial.PausedSerials,
        };
        result.push(newItem);
    });
    return result;
};

export const createPartialSubmitBody = (orderData: OrderData, QCLog: QCLog[]): PartialSubmitPayload => {
    const { username } = useAppSelector(state => state.authentication);

    const submitData = {
        client: orderData.PackageId.Mom,
        order: orderData.PackageId.OrderNum,
        inpart: orderData.PackageId.Inpart,
        system: 'n/a',
        version: 'n/a',
        user: username,
        message: `${orderData.PackageId.OrderNum}${
            orderData.PackageId.Inpart
        } QC Paused by ${username} at ${new Date().toISOString()}`,
        items: parseOrderDataItemsToPartialSubmitItems(orderData),
        qclog: QCLog?.length
            ? QCLog
            : [
                  {
                      datetime: new Date().toISOString(),
                      scanQty: 0,
                      upc: '',
                      serial: '',
                      error: false,
                      action: 'Paused with no scans',
                  },
              ],
    };

    return submitData;
};
