import { Box, Button } from '@mui/material';
import React from 'react';
import { isFeatureAccessible } from '../../../../common/utils/commonUtils';
import authenticationApi from '../../../authentication/authenticationApi';
import { RESET_MANIFEST, RESET_QC } from '../../../roles/permissionsList';
import { QcLogResetManifestDialog } from './QcLogResetManifestDialog';
import { QcLogResetQcDialog } from './QcLogResetQcDialog';

export const QcLogDisplayHeader = React.memo(({ invoice }: { invoice: string }) => {
    const [resetQcLogDialogOpen, setResetQcLogDialogOpen] = React.useState(false);
    const [resetManifestLogDialogOpen, setResetManifestLogDialogOpen] = React.useState(false);

    const getUserPermissions = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions: string[] = getUserPermissions.data || [];
    const permittedToResetQc = isFeatureAccessible(userPermissions, RESET_QC);
    const permittedToResetManifest = isFeatureAccessible(userPermissions, RESET_MANIFEST);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Box sx={{ pr: 2 }}>
                    {permittedToResetQc && (
                        <Button
                            color="error"
                            sx={{ mx: 1.5 }}
                            onClick={() => {
                                setResetQcLogDialogOpen(true);
                            }}
                        >
                            Reset QC
                        </Button>
                    )}
                    {permittedToResetManifest && (
                        <Button
                            color="error"
                            sx={{ mx: 1.5 }}
                            onClick={() => {
                                setResetManifestLogDialogOpen(true);
                            }}
                        >
                            Reset Manifest
                        </Button>
                    )}
                </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%">
                <QcLogResetQcDialog
                    invoice={invoice}
                    handleOpenDialog={setResetQcLogDialogOpen}
                    showDialog={resetQcLogDialogOpen}
                />
                <QcLogResetManifestDialog
                    invoice={invoice}
                    handleOpenDialog={setResetManifestLogDialogOpen}
                    showDialog={resetManifestLogDialogOpen}
                />
            </Box>
        </Box>
    );
});
