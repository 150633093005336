import { Box } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files-ro';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { getContent, getEncodingHeaeder, toBase64 } from '../../../../common/utils/typedCommonUtils';
import { AttachmentFile } from '../../workOrderModels';
import { overrideForm } from '../../workOrderSlice';

const FILE_UPLOAD_MESSAGE = `Browse for or drag/drop file to attach`;

const AttachmentFileForm = ({ isDisabled }: { isDisabled: boolean }) => {
    const attachmentFiles = useAppSelector(state => state.workOrder.form.AttachmentFiles);

    const dispatch = useAppDispatch();

    const handleChange = async (files: FileList) => {
        const filesArray = Array.from(files);

        const convertedFiles = await Promise.all(
            filesArray.map(async file => {
                const base64Encoded = await toBase64(file);
                return {
                    Name: file.name,
                    Content: getContent(base64Encoded),
                    EncodingHeader: getEncodingHeaeder(base64Encoded),
                } as AttachmentFile;
            })
        );

        dispatch(overrideForm({ AttachmentFiles: [...attachmentFiles, ...convertedFiles] }));
    };

    return (
        <Box sx={{ my: 2, display: isDisabled ? 'none' : '' }}>
            <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                label={FILE_UPLOAD_MESSAGE}
                uploadedLabel={FILE_UPLOAD_MESSAGE}
            />
        </Box>
    );
};

export default AttachmentFileForm;
