import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { WorkOrderData } from './workOrderModels';
import { setTab } from './workOrderSlice';

export const cleanTemplateData = (data: WorkOrderData): WorkOrderData => {
    return {
        ...data,
        Id: null,
        DateCreated: dayjs().format(),
        DueDate: dayjs().add(1, 'week').format(),
        DateCompleted: null,
        CompletedBy: null,
        DeletedDate: null,
        DeletedBy: null,
        AttachmentItems: data.AttachmentItems.map(item => {
            return { ...item, Id: null, WorkOrderId: null };
        }),

        BillingItems: data.BillingItems.map(item => {
            return { ...item, Id: null, WorkOrderId: null };
        }),

        BillsOfMaterialsItems: data.BillsOfMaterialsItems.map(item => {
            return { ...item, Id: null, WorkOrderId: null };
        }),

        EditLogItems: [],
    };
};

export const setToTemplatesTab = (dispatch: Dispatch<AnyAction>) => {
    dispatch(setTab('Templates'));
};

export const setToWorkOrderTab = (dispatch: Dispatch<AnyAction>) => {
    dispatch(setTab('Work Orders'));
};

export const DISTINCT_COLORS = [
    // '#696969',
    // '#808080',
    // '#a9a9a9',
    // '#d3d3d3',
    '#2f4f4f',
    '#556b2f',
    '#6b8e23',
    '#a0522d',
    '#a52a2a',
    '#2e8b57',
    '#228b22',
    '#800000',
    '#191970',
    '#006400',
    '#708090',
    '#808000',
    '#483d8b',
    '#b22222',
    '#5f9ea0',
    '#3cb371',
    '#bc8f8f',
    '#663399',
    '#b8860b',
    '#bdb76b',
    '#008b8b',
    '#cd853f',
    '#4682b4',
    '#000080',
    '#d2691e',
    '#9acd32',
    '#20b2aa',
    '#cd5c5c',
    '#4b0082',
    '#32cd32',
    '#daa520',
    '#8fbc8f',
    '#800080',
    '#b03060',
    '#d2b48c',
    '#48d1cc',
    '#66cdaa',
    '#9932cc',
    '#ff0000',
    '#ff4500',
    '#ff8c00',
    '#ffa500',
    '#ffd700',
    '#6a5acd',
    '#ffff00',
    '#c71585',
    '#0000cd',
    '#7cfc00',
    '#40e0d0',
    '#00ff00',
    '#9400d3',
    '#ba55d3',
    '#00fa9a',
    '#00ff7f',
    '#4169e1',
    '#e9967a',
    '#dc143c',
    '#00ffff',
    '#00bfff',
    '#f4a460',
    '#9370db',
    '#0000ff',
    '#a020f0',
    '#f08080',
    '#adff2f',
    '#ff6347',
    '#d8bfd8',
    '#b0c4de',
    '#ff7f50',
    '#ff00ff',
    '#1e90ff',
    '#db7093',
    '#f0e68c',
    '#fa8072',
    '#eee8aa',
    '#ffff54',
    '#6495ed',
    '#dda0dd',
    '#add8e6',
    '#87ceeb',
    '#ff1493',
    '#7b68ee',
    '#ffa07a',
    '#afeeee',
    '#ee82ee',
    '#98fb98',
    '#7fffd4',
    '#ffe4b5',
    '#ff69b4',
    // '#fdf5e6',
    // '#ffebcd',
    // '#fffacd',
    // '#e6e6fa',
    // '#ffe4e1',
    // '#e0ffff',
    // '#ffc0cb',
];
