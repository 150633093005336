import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/taskDetailsBasedOnSkuApi';
import { getReviewTaskDetailsAction } from '../store/actions/action';

export function* getTaskDetailsBasedOnSkus(action) {
    try {
        yield put({ type: actionTypes.SET_TASK_LOADING_TRUE });
        const response = yield call(api.getTaskDetailsBasedOnSkuApi, action);
        yield put({
            type: actionTypes.GET_TASK_DETAILS_BASED_ON_SKU_SUCCESS,
            payload: response.data,
        });
        if (action.tabType === 1) {
            const { sku, compId } = action;
            yield put(getReviewTaskDetailsAction({ sku: sku, companyId: compId, taskState: [4] }, true));
        }
    } catch (error) {
        yield put({
            type: actionTypes.GET_TASK_DETAILS_BASED_ON_SKU_FAILURE,
            errorMessage: error,
        });
    }
}

export default function* taskDetailsBasedOnSkusSagaWatcher() {
    yield takeLatest(actionTypes.GET_TASK_DETAILS_BASED_ON_SKU, getTaskDetailsBasedOnSkus);
}
