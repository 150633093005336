import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../app/store';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import qualityControlApi from '../../qualityControl/qualityControlApi';
import { OrderData } from '../../qualityControl/qualityControlModels';
import addSerialApi from '../addSerialApi';
import { AddSerialSubmitPayload } from '../addSerialModels';
import AddSerialAddItem from './AddSerialAddItem';

interface AddSerialFormProps {
    responseData: OrderData;
}

const AddSerialForm = ({ responseData }: AddSerialFormProps) => {
    const [submitForm, setSubmitForm] = React.useState<AddSerialSubmitPayload>({
        InvoiceNumber: responseData.PackageId.PkgId,
        Items: [],
    });

    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = React.useState(false);
    const [triggerAddSerialSubmit, addSerialSubmitResponse] = addSerialApi.endpoints.postAddSerial.useMutation();
    const dispatch = useAppDispatch();

    const handleSubmit = () => {
        triggerAddSerialSubmit(submitForm);
    };

    useNotificationByResponse(
        {
            isFetching: addSerialSubmitResponse.isLoading,
            isError: addSerialSubmitResponse.isError,
            errorContent: 'Could not submit',
            isSuccess: addSerialSubmitResponse.isSuccess,
            successContent: `Successfully submitted`,
            isUninitialized: addSerialSubmitResponse.isUninitialized,
        },
        () => {
            dispatch(qualityControlApi.util.resetApiState());
        }
    );

    return (
        <Box>
            <Typography textAlign={'center'} variant="h2">
                {responseData.PackageId.PkgId}
            </Typography>
            <AddSerialAddItem
                items={responseData.Items}
                submitForm={submitForm}
                setSubmitForm={setSubmitForm}
                setIsSubmitButtonEnabled={setIsSubmitButtonEnabled}
            />
            <Button
                disabled={!isSubmitButtonEnabled}
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: '100%', my: 2 }}
            >
                Submit Serial
            </Button>
        </Box>
    );
};

export default AddSerialForm;
