import { Box, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';

const DIALOG_TITLE_LG = 'All Incomplete Serialized Batch Orders';
const DIALOG_TITLE_SM = 'Incomplete Orders';

export const BatchDialogTitle = ({ location }: { location: string }) => {
    const theme = useTheme();
    const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'));
    if (isSmallViewPort) {
        return (
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography>
                        {DIALOG_TITLE_SM} - {location.toUpperCase()}
                    </Typography>
                </Box>
            </DialogTitle>
        );
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle sx={{ p: 1 }}>{DIALOG_TITLE_LG}</DialogTitle>
            <DialogTitle sx={{ p: 1 }}>{location.toUpperCase()}</DialogTitle>
        </Box>
    );
};
