import { Box } from '@mui/material';
import useNotificationByQueryResponse from '../../../common/hooks/useNotificationByQueryResponse';
import { GetSkuVelocityRequest, GetSkuVelocityResponse } from '../models';
import skuVelocityApi from '../skuVelocityApi';
import SkuVelocityDataGrid from './SkuVelocityDataGrid';
import SkuVelocityForm from './SkuVelocityForm';
import TopQuantityChart from './charts/TopQuantityChart';

const SkuVelocityElement = () => {
    const [getSkuVelocityTrigger, getSkuVelocityResponse] = skuVelocityApi.endpoints.getSkuVelocity.useLazyQuery();

    useNotificationByQueryResponse({ response: getSkuVelocityResponse, errorMessage: 'Failed  to fetch sku velocity' });

    const groupedAndSortedData = [...(getSkuVelocityResponse?.data || [])]
        .reduce<GetSkuVelocityResponse[]>((result, skuVelocity) => {
            const matchingSku = result.find(
                element => element.Client === skuVelocity.Client && element.Sku === skuVelocity.Sku
            );

            if (matchingSku) {
                matchingSku.Quantity += skuVelocity.Quantity;
            } else {
                result.push({ ...skuVelocity });
            }

            return result;
        }, [])
        .sort((a, b) => b?.Quantity - a?.Quantity);

    return (
        <Box>
            <SkuVelocityForm
                onSubmit={(formData: GetSkuVelocityRequest) => {
                    getSkuVelocityTrigger(formData);
                }}
            />
            <TopQuantityChart data={groupedAndSortedData} />
            <SkuVelocityDataGrid data={groupedAndSortedData} />
        </Box>
    );
};

export default SkuVelocityElement;
