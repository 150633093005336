import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useParams } from 'react-router';
import useNotificationByQueryResponse from '../../../../common/hooks/useNotificationByQueryResponse';
import { DraggableInstruction } from '../../models';
import ProcedureApi from '../../ProcedureApi';
import CanvasStage from './CanvasStage';

const Canvas = () => {
    const { procedureId } = useParams();
    const castedProcedureId = procedureId ? Number(procedureId) : null;
    const procedureResponse = ProcedureApi.endpoints.getProcedure.useQuery(
        castedProcedureId ? castedProcedureId : skipToken
    );

    const [instructions, setInstructions] = React.useState<DraggableInstruction[]>([]);
    useNotificationByQueryResponse({
        response: procedureResponse,
        successFinishedFunction: () => {
            setInstructions(
                procedureResponse?.data?.Instructions?.map(instruction => {
                    return { ...instruction, IsDragging: false } as DraggableInstruction;
                }) || []
            );
        },
    });

    return procedureResponse.data?.Instructions ? (
        <CanvasStage instructions={instructions} setInstructions={setInstructions} />
    ) : (
        <></>
    );
};

export default Canvas;
