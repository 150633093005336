import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import QcLogMenu from './QcLogMenu';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';

export const QcLogSpeedDialDialog = React.memo(
    ({
        setQcLogDialogOpen,
        showDialog = false,
    }: {
        setQcLogDialogOpen: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const handleClose = () => {
            setQcLogDialogOpen(false);
        };

        return (
            <Dialog open={showDialog} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogCloseButton handleClose={handleClose} />
                <DialogTitle variant="h5">VIEW QC LOG</DialogTitle>
                <DialogContent>
                    <QcLogMenu />
                </DialogContent>
            </Dialog>
        );
    }
);
