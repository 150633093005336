import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { GetSkuVelocityResponse } from '../models';

const columns: readonly GridColDef<GetSkuVelocityResponse>[] = [
    {
        field: 'Client',
        headerName: 'Client',
        flex: 1,
    },
    {
        field: 'Sku',
        headerName: 'Sku',
        flex: 2,
    },
    {
        field: 'Location',
        headerName: 'Location',
        flex: 2,
        valueFormatter: (value, row) => row.Location.toUpperCase(),
    },
    {
        field: 'Quantity',
        headerName: 'Quantity',
        flex: 1,
    },
];

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
};

const SkuVelocityDataGrid = ({ data }: { data: GetSkuVelocityResponse[] }) => {
    return (
        <DataGrid
            rows={data}
            columns={columns}
            getRowId={row => `${row.Client}${row.Sku}${row.Location}${row.WeekOf}`}
            sx={{
                mt: 2,
            }}
            autoHeight
            density="compact"
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ toolbar: { csvOptions: { fileName: 'skuVelocity' } } }}
            disableRowSelectionOnClick
        />
    );
};

export default SkuVelocityDataGrid;
