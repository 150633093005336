import { Box, Button, CardMedia, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files-ro';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import { setDialog } from '../../../notification/notificationSlice';
import rmaApi from '../../rmaApi';
import { UploadedPicture } from '../../rmaModels';
import { SKU_IMG_WIDTH } from '../../constants';

export const UploadItemPictureDialog = React.memo(
    ({
        sku,
        setRecentlyUploadedPicture,
        handleOpenDialog,
        showDialog = false,
    }: {
        sku: string | null | undefined;
        setRecentlyUploadedPicture: (pictureDict: UploadedPicture) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const dispatch = useDispatch();
        const rma = useAppSelector(state => state.rma.rma);
        const userId = useAppSelector(state => state.authentication.authenticationResponse?.userInfo.id);
        const fileTypes = ['JPG', 'PNG', 'GIF'];

        const [uploadedPicture, setUploadedPicture] = useState<UploadedPicture>();
        const [postReceivedSkuImage, postReceivedSkuImageResponse] = rmaApi.endpoints.postSkuImage.useMutation();

        const fileUploadHandler = (file: File) => {
            const reader = new FileReader();
            reader.onload = e => {
                setUploadedPicture({
                    Preview: URL.createObjectURL(file),
                    Base64Image: e.target?.result,
                });
            };
            reader.readAsDataURL(file);
        };

        const handleOnClose = () => {
            handleOpenDialog(false);
        };

        const updateImageHandler = () => {
            if (!!sku && !!rma?.Client) {
                postReceivedSkuImage({
                    sku: sku,
                    userId: userId,
                    momCode: rma?.Client,
                    base64Image: uploadedPicture?.Base64Image,
                });
                handleOnClose();
            }
        };

        useNotificationByMutationResponseV2({
            response: postReceivedSkuImageResponse,
        });

        React.useEffect(() => {
            if (!postReceivedSkuImageResponse.isUninitialized && !postReceivedSkuImageResponse.isLoading) {
                if (postReceivedSkuImageResponse.data?.Success) {
                    if (!!uploadedPicture) setRecentlyUploadedPicture(uploadedPicture);
                } else {
                    dispatch(
                        setDialog({
                            title: 'COULD NOT UPDATE',
                            content: postReceivedSkuImageResponse.data?.Error || 'UNKNOWN ERROR, PLEASE CONTACT ADMIN',
                        })
                    );
                }
            }
        }, [postReceivedSkuImageResponse.isLoading, postReceivedSkuImageResponse.data]);

        return (
            <Dialog open={showDialog} maxWidth="sm" onClose={handleOnClose}>
                <DialogTitle color="white" bgcolor="primary.main" align="center">
                    <Typography fontWeight={'fontWeightMedium'} variant="h6">
                        UPDATE PICTURE FOR THIS SKU
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ m: 2, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <FileUploader handleChange={fileUploadHandler} name="pictureUpload" fileTypes={fileTypes} />
                        </Box>
                        {!!uploadedPicture?.Preview ? (
                            <React.Fragment>
                                <CardMedia
                                    component="img"
                                    sx={{
                                        maxWidth: SKU_IMG_WIDTH,
                                    }}
                                    alt="SKU"
                                    referrerPolicy="no-referrer"
                                    src={uploadedPicture.Preview}
                                />
                                <Button onClick={updateImageHandler} sx={{ mt: 3 }} size="large">
                                    CONFIRM CHANGE IMAGE FOR SKU
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </Box>
                </DialogContent>
                <DialogCloseButton
                    handleClose={() => {
                        handleOpenDialog(false);
                    }}
                />
            </Dialog>
        );
    }
);
