// Authentication
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const ALREADY_AUTHENTICATED = 'ALREADY_AUTHENTICATED';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const SET_AUTHENTICATE_LOADING = 'SET_AUTHENTICATE_LOADING';
export const RESET_AUTHENTICATE_LOADING = 'RESET_AUTHENTICATE_LOADING';
export const SIGN_OUT = 'SIGN_OUT';
export const GET_NEW_TOKEN = 'GET_NEW_TOKEN';
export const GET_NEW_TOKEN_FAILURE = 'GET_NEW_TOKEN_FAILURE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

// Tracking
export const SET_TRACKING_LOADING = 'SET_TRACKING_LOADING';
export const RESET_TRACKING = 'RESET_TRACKING';

export const POST_TRACKING = 'POST_TRACKING';
export const POST_TRACKING_SUCCESS = 'POST_TRACKING_SUCCESS';
export const POST_TRACKING_FAILED = 'POST_TRACKING_FAILED';

export const GET_CARRIERS = 'GET_CARRIERS';
export const GET_CARRIERS_SUCCESS = 'GET_CARRIERS_SUCCESS';
export const GET_CARRIERS_FAILED = 'GET_CARRIERS_FAILED';

// Receivers
export const SET_RECEIVERS_LOADING = 'SET_RECEIVERS_LOADING';
export const GET_RECEIVERS = 'GET_RECEIVERS';
export const GET_RECEIVERS_SUCCESS = 'GET_RECEIVERS_SUCCESS';
export const GET_RECEIVERS_FAILED = 'GET_RECEIVERS_FAILED';

export const GET_RECEIVERS_CONFIG = 'GET_RECEIVERS_CONFIG';
export const GET_RECEIVERS_CONFIG_SUCCESS = 'GET_RECEIVERS_CONFIG_SUCCESS';
export const GET_RECEIVERS_CONFIG_FAILED = 'GET_RECEIVERS_CONFIG_FAILED';

export const GET_RECEIVERS_INBOUNDS = 'GET_RECEIVERS_INBOUNDS';
export const GET_RECEIVERS_INBOUNDS_SUCCESS = 'GET_RECEIVERS_INBOUNDS_SUCCESS';
export const GET_RECEIVERS_INBOUNDS_FAILED = 'GET_RECEIVERS_INBOUNDS_FAILED';

export const GET_RECEIVERS_STOCKS = 'GET_RECEIVERS_STOCKS';
export const GET_RECEIVERS_STOCKS_SUCCESS = 'GET_RECEIVERS_STOCKS_SUCCESS';
export const GET_RECEIVERS_STOCKS_FAILED = 'GET_RECEIVERS_STOCKS_FAILED';

export const POST_RECEIVERS_FORM = 'POST_RECEIVERS_FORM';
export const POST_RECEIVERS_FORM_SUCCESS = 'POST_RECEIVERS_FORM_SUCCESS';
export const POST_RECEIVERS_FORM_FAILED = 'POST_RECEIVERS_FORM_FAILED';
export const RESET_RECEIVERS_SUBMIT_FORM_STATUS = 'RESET_RECEIVERS_SUBMIT_FORM_STATUS';

export const GET_RECEIVERS_HISTORY = 'GET_RECEIVERS_HISTORY';
export const GET_RECEIVERS_HISTORY_SUCCESS = 'GET_RECEIVERS_HISTORY_SUCCESS';
export const GET_RECEIVERS_HISTORY_FAILED = 'GET_RECEIVERS_HISTORY_FAILED';

export const PLAY_RECEIVERS = 'PLAY_RECEIVERS';
export const PLAY_RECEIVERS_SUCCESS = 'PLAY_RECEIVERS_SUCCESS';
export const PLAY_RECEIVERS_FAILED = 'PLAY_RECEIVERS_FAILED';

// Notification
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';
export const SET_DIALOG = 'SET_DIALOG';
export const RESET_DIALOG = 'RESET_DIALOG';

// Application
export const GET_APPLICATION_VERSION = 'GET_APPLICATION_VERSION';
export const GET_APPLICATION_VERSION_SUCCESS = 'GET_APPLICATION_VERSION_SUCCESS';
export const GET_APPLICATION_VERSION_FAILED = 'GET_APPLICATION_VERSION_FAILED';

// Tasks
export const GET_TASKS_BY_USER_ID_AND_STATE = 'GET_TASKS_BY_USER_ID_AND_STATE';
export const GET_FILTERED_TASKS = 'GET_FILTERED_TASKS';
export const FILTERED_TASKS_RECEIVED_SUCCESS = 'FILTERED_TASKS_RECEIVED_SUCCESS';
export const FILTERED_TASKS_RECEIVED_FAILED = 'FILTERED_TASKS_RECEIVED_FAILED';
export const TASKS_RECEIVED_SUCCESS = 'TASKS_RECEIVED_SUCCESS';
export const TASKS_RECEIVED_FAILED = 'TASKS_RECEIVED_FAILED';

export const SET_TASK_LOADING_TRUE = 'SET_TASK_LOADING_TRUE';
export const SET_FILTERED_TASK_LOADING_TRUE = 'SET_FILTERED_TASK_LOADING_TRUE';

// Task
export const UPDATE_SELECTED_TASK = 'UPDATE_SELECTED_TASK';

// Quality Control
export const SET_QC_REDUCER_LOADING = 'SET_QC_REDUCER_LOADING';
export const SET_QC_ORDER_STATUS_REDUCER_LOADING = 'SET_QC_ORDER_STATUS_REDUCER_LOADING';
export const GET_QC_ORDER_STATUS = 'GET_QC_ORDER_STATUS';
export const GET_QC_ORDER_STATUS_SUCCESS = 'GET_QC_ORDER_STATUS_SUCCESS';
export const GET_QC_ORDER_STATUS_FAILED = 'GET_QC_ORDER_STATUS_FAILED';
export const SET_QC_TO_INITIAL_STATE = 'SET_QC_TO_INITIAL_STATE'; //used for front end

export const SET_QC_ORDER_DATA = 'SET_QC_ORDER_DATA';
export const GET_QC_ORDER_DATA = 'GET_QC_ORDER_DATA';
export const GET_QC_ORDER_DATA_SUCCESS = 'GET_QC_ORDER_DATA_SUCCESS';
export const GET_QC_ORDER_DATA_FAILED = 'GET_QC_ORDER_DATA_FAILED';

export const QC_SUBMIT = 'QC_SUBMIT';
export const QC_SUBMIT_SUCCESS = 'QC_SUBMIT_SUCCESS';
export const QC_SUBMIT_FAILED = 'QC_SUBMIT_FAILED';
export const QC_PARTIAL_SUBMIT = 'QC_PARTIAL_SUBMIT';
export const QC_PARTIAL_SUBMIT_SUCCESS = 'QC_PARTIAL_SUBMIT_SUCCESS';
export const QC_PARTIAL_SUBMIT_FAILED = 'QC_PARTIAL_SUBMIT_FAILED';

export const GET_QC_SERIAL_CHECK = 'GET_QC_SERIAL_CHECK';
export const GET_QC_SERIAL_CHECK_SUCCESS = 'GET_QC_SERIAL_CHECK_SUCCESS';
export const GET_QC_SERIAL_CHECK_FAILED = 'GET_QC_SERIAL_CHECK_FAILED';

export const START_QC = 'START_QC';
export const COMPLETE_QC = 'COMPLETE_QC';
export const ABORT_QC = 'ABORT_QC';
export const RESET_QC = 'RESET_QC';
export const QC_STATUS_CHANGE_SUCCESS = 'QC_STATUS_CHANGE_SUCCESS';
export const QC_STATUS_CHANGE_FAILED = 'QC_STATUS_CHANGE_FAILED';
export const INCR_QC_ITEM_QUANTITY = 'INCR_QC_ITEM_QUANTITY';

// Pallets
export const GET_PALLETS_BY_TASK_ID = 'GET_PALLETS_BY_TASK_ID';
export const PALLETS_RECEIVED = 'PALLETS_RECEIVED';
export const PALLETS_RECEIVED_FAILED = 'PALLETS_RECEIVED_FAILED';
export const COMPLETED_PALLET_REMOVED = 'COMPLETED_PALLET_REMOVED';
export const PALLET_SUBMIT = 'PALLET_SUBMIT';
export const PALLET_SUBMIT_SUCCESS = 'PALLET_SUBMIT_SUCCESS';
export const PALLET_SUBMIT_FAILED = 'PALLET_SUBMIT_FAILED';
export const PALLET_SUBMIT_COMMIT = 'PALLET_SUBMIT_COMMIT';
export const PALLET_SUBMIT_ROLLBACK = 'PALLET_SUBMIT_ROLLBACK';
export const GENERATE_PALLET_COUNT = 'GENERATE_PALLET_COUNT';
export const GENERATE_PALLET_COUNT_SUCCESS = 'GENERATE_PALLET_COUNT_SUCCESS';
export const GENERATE_PALLET_COUNT_FAILED = 'GENERATE_PALLET_COUNT_FAILED';
export const GENERATE_COUNT_AND_REFETCH_PALLET = 'GENERATE_COUNT_AND_REFETCH_PALLET';
export const GET_ALL_PALLETS = 'GET_ALL_PALLETS';
export const GET_ALL_PALLETS_SUCCESS = 'GET_ALL_PALLETS_SUCCESS';
export const GET_ALL_PALLETS_FAILURE = 'GET_ALL_PALLETS_FAILURE';
export const GET_PALLET_FILTERS = 'GET_PALLET_FILTERS';
export const GET_PALLET_FILTERS_SUCCESS = 'GET_PALLET_FILTERS_SUCCESS';
export const GET_PALLET_FILTERS_FAILURE = 'GET_PALLET_FILTERS_FAILURE';
export const GET_FILTERED_PALLETS = 'GET_FILTERED_PALLETS';
export const GET_FILTERED_PALLETS_SUCCESS = 'GET_FILTERED_PALLETS_SUCCESS';
export const GET_FILTERED_PALLETS_FAILURE = 'GET_FILTERED_PALLETS_FAILURE';
export const SET_PALLET_REDUCER_LOADING = 'SET_PALLET_REDUCER_LOADING';

// stock
export const INIT_STOCK_STATE = 'INIT_STOCK_STATE';
export const GET_STOCK_PALLET_FILTERS = 'GET_STOCK_PALLET_FILTERS';
export const GET_STOCK_PALLET_FILTERS_SUCCESS = 'GET_STOCK_PALLET_FILTERS_SUCCESS';
export const GET_STOCK_PALLET_FILTERS_FAILURE = 'GET_STOCK_PALLET_FILTERS_FAILURE';
export const SET_STOCKS_REDUCER_LOADING = 'SET_STOCKS_REDUCER_LOADING';
export const SEARCH_PALLETS_BY_FILTERS = 'SEARCH_PALLETS_BY_FILTERS';
export const SEARCH_PALLETS_BY_FILTERS_SUCCESS = 'SEARCH_PALLETS_BY_FILTERS_SUCCESS';
export const SEARCH_PALLETS_BY_FILTERS_FAILURE = 'SEARCH_PALLETS_BY_FILTERS_FAILURE';

export const LOAD_MORE_SEARCH_PALLETS_BY_FILTERS = 'LOAD_MORE_SEARCH_PALLETS_BY_FILTERS';
export const LOAD_MORE_SEARCH_PALLETS_BY_FILTERS_SUCCESS = 'LOAD_MORE_SEARCH_PALLETS_BY_FILTERS_SUCCESS';
export const LOAD_MORE_SEARCH_PALLETS_BY_FILTERS_FAILURE = 'LOAD_MORE_SEARCH_PALLETS_BY_FILTERS_FAILURE';

export const SET_LOAD_MORE_SEARCH_PALLET_LOADING = 'SET_LOAD_MORE_SEARCH_PALLET_LOADING';

export const GET_STOCK_PALLET_LOGS = 'GET_STOCK_PALLET_LOGS';
export const GET_STOCK_PALLET_LOGS_SUCCESS = 'GET_STOCK_PALLET_LOGS_SUCCESS';
export const GET_STOCK_PALLET_LOGS_FAILURE = 'GET_STOCK_PALLET_LOGS_FAILURE';
export const SET_STOCK_PALLET_VALUE = 'SET_STOCK_PALLET_VALUE';

// Result
export const GET_COUNT_RESULT = 'GET_COUNT_RESULT';
export const GET_COUNT_RESULT_SUCCESS = 'GET_COUNT_RESULT_SUCCESS';
export const GET_COUNT_RESULT_FAILURE = 'GET_COUNT_RESULT_FAILURE';
export const COUNT_RESULT_INITIAL_STATE = 'COUNT_RESULT_INITIAL_STATE';

// Get Companies
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';

// Get Users
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';

// Get SKU
export const GET_SKUS = 'GET_SKUS';
export const GET_SKUS_SUCCESS = 'GET_SKUS_SUCCESS';
export const GET_SKUS_FAILURE = 'GET_SKUS_FAILURE';

// Create Task
export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';
export const CREATE_TASK_INITIAL_STATE = 'CREATE_TASK_INITIAL_STATE';

//Get Company Skus By Task State
export const GET_COMPANY_SKUS_BY_TASK_STATE = 'GET_COMPANY_SKUS_BY_TASK_STATE';
export const GET_COMPANY_SKUS_BY_TASK_STATE_SUCCESS = 'GET_COMPANY_SKUS_BY_TASK_STATE_SUCCESS';
export const GET_COMPANY_SKUS_BY_TASK_STATE_FAILURE = 'GET_COMPANY_SKUS_BY_TASK_STATE_FAILURE';
export const COMPANY_SKUS_INITIAL_STATE = 'COMPANY_SKUS_INITIAL_STATE';

//Get Task Details Based On Sku
export const GET_TASK_DETAILS_BASED_ON_SKU = 'GET_TASK_DETAILS_BASED_ON_SKU';
export const GET_TASK_DETAILS_BASED_ON_SKU_SUCCESS = 'GET_TASK_DETAILS_BASED_ON_SKU_SUCCESS';
export const GET_TASK_DETAILS_BASED_ON_SKU_FAILURE = 'GET_TASK_DETAILS_BASED_ON_SKU_FAILURE';

//Get review Tasks Details
export const GET_REVIEW_TASK_DETAILS = 'GET_REVIEW_TASK_DETAILS';
export const GET_REVIEW_TASK_DETAILS_SUCCESS = 'GET_REVIEW_TASK_DETAILS_SUCCESS';
export const GET_REVIEW_TASK_DETAILS_FAILURE = 'GET_REVIEW_TASK_DETAILS_FAILURE';

//Reject Task
export const GET_REJECT_TASK_DETAILS = 'GET_REJECT_TASK_DETAILS';
export const GET_REJECT_TASK_DETAILS_SUCCESS = 'GET_REJECT_TASK_DETAILS_SUCCESS';
export const GET_REJECT_TASK_DETAILS_FAILURE = 'GET_REJECT_TASK_DETAILS_FAILURE';
export const REJECT_AND_CREATE_TASK = 'REJECT_AND_CREATE_TASK';

//Approve Task
export const GET_APPROVE_TASK_DETAILS = 'GET_APPROVE_TASK_DETAILS';
export const GET_APPROVE_TASK_DETAILS_SUCCESS = 'GET_APPROVE_TASK_DETAILS_SUCCESS';
export const GET_APPROVE_TASK_DETAILS_FAILURE = 'GET_APPROVE_TASK_DETAILS_FAILURE';

//Discard Task
export const DISCARD_TASK = 'DISCARD_TASK';
export const DISCARD_TASK_SUCCESS = 'DISCARD_TASK_SUCCESS';
export const DISCARD_TASK_FAILURE = 'DISCARD_TASK_FAILURE';

//Re-Assign Task
export const REASSIGN_TASK_INITIAL_STATE = 'REASSIGN_TASK_INITIAL_STATE';
export const GET_REASSIGN_TASK_DETAILS = 'GET_REASSIGN_TASK_DETAILS';
export const GET_REASSIGN_TASK_DETAILS_SUCCESS = 'GET_REASSIGN_TASK_DETAILS_SUCCESS';
export const GET_REASSIGN_TASK_DETAILS_FAILURE = 'GET_REASSIGN_TASK_DETAILS_FAILURE';
export const REASSIGN_TASK_DETAILS = 'REASSIGN_TASK_DETAILS';
export const REASSIGN_TASK_DETAILS_SUCCESS = 'REASSIGN_TASK_DETAILS_SUCCESS';
export const REASSIGN_TASK_DETAILS_FAILURE = 'REASSIGN_TASK_DETAILS_FAILURE';
export const UPDATE_TASK_LIST_AFTER_REASSIGNING = 'UPDATE_TASK_LIST_AFTER_REASSIGNING';
export const CREATE_TASK_FAILED_DUE_TO_UNAVAILABLITY_OF_SKU = 'CREATE_TASK_FAILED_DUE_TO_UNAVAILABLITY_OF_SKU';

// PALLET ACTION
// RETIRE
export const RETIRE_PALLET_TAG = 'RETIRE_PALLET_TAG';
export const RETIRE_PALLET_TAG_SUCCESS = 'RETIRE_PALLET_TAG_SUCCESS';
export const RETIRE_PALLET_TAG_FAILURE = 'RETIRE_PALLET_TAG_FAILURE';

// QUARANTINE
export const QUARANTINE_PALLET_TAG = 'QUARANTINE_PALLET_TAG';
export const QUARANTINE_PALLET_TAG_SUCCESS = 'QUARANTINE_PALLET_TAG_SUCCESS';
export const QUARANTINE_PALLET_TAG_FAILURE = 'QUARANTINE_PALLET_TAG_FAILURE';

//CLONE
export const CLONE_PALLET_TAG = 'CLONE_PALLET_TAG';
export const CLONE_PALLET_TAG_SUCCESS = 'CLONE_PALLET_TAG_SUCCESS';
export const CLONE_PALLET_TAG_FAILURE = 'CLONE_PALLET_TAG_FAILURE';

//VALIDATE
export const VALIDATE_PALLET_TAG = 'VALIDATE_PALLET_TAG';
export const VALIDATE_PALLET_TAG_SUCCESS = 'VALIDATE_PALLET_TAG_SUCCESS';
export const VALIDATE_PALLET_TAG_FAILURE = 'VALIDATE_PALLET_TAG_FAILURE';

//UPDATE PALLET LOCATION IN PALLETS PAGE
export const UPDATE_PALLET_LOCATION = 'UPDATE_PALLET_LOCATION';
export const UPDATE_PALLET_LOCATION_SUCCESS = 'UPDATE_PALLET_LOCATION_SUCCESS';
export const UPDATE_PALLET_LOCATION_FAILURE = 'UPDATE_PALLET_LOCATION_FAILURE';

//RESET STOCKS SUCCESS STATUS
export const RESET_STOCKS_SUCCESS_STATUS = 'RESET_STOCKS_SUCCESS_STATUS';
// STOCK PALLET ACTION LOADING
export const SET_STOCK_PALLET_ACTION_LOADING = 'SET_STOCK_PALLET_ACTION_LOADING';

// GET WAREHOUSES LIST FOR STOCK PALLET ACTIONS

export const GET_WAREHOUSE_LIST = 'GET_WAREHOUSE_LIST';
export const GET_WAREHOUSE_LIST_SUCCESS = 'GET_WAREHOUSE_LIST_SUCCESS';
export const GET_WAREHOUSE_LIST_FAILURE = 'GET_WAREHOUSE_LIST_FAILURE';
export const SET_STOCK_WAREHOUSE_OPTIONS_LOADING = 'SET_STOCK_WAREHOUSE_OPTIONS_LOADING';
// GET LOCATIONS LIST BASED ON SELECTED WAREHOUSE FOR STOCK PALLET ACTION

export const GET_LOCATIONS_BY_WAREHOUSE = 'GET_LOCATIONS_BY_WAREHOUSE';
export const GET_LOCATIONS_BY_WAREHOUSE_SUCCESS = 'GET_LOCATIONS_BY_WAREHOUSE_SUCCESS';
export const GET_LOCATIONS_BY_WAREHOUSE_FAILURE = 'GET_LOCATIONS_BY_WAREHOUSE_FAILURE';
export const SET_STOCK_LOCATION_OPTIONS_LOADING = 'SET_STOCK_LOCATION_OPTIONS_LOADING';

// ROLES API ACTION TYPE

export const SET_ROLES_LOADING = 'SET_ROLES_LOADING';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';

export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const EDIT_USER_ROLE = 'EDIT_USER_ROLE';
export const EDIT_USER_ROLE_SUCCESS = 'EDIT_USER_ROLE_SUCCESS';
export const EDIT_USER_ROLE_FAILURE = 'EDIT_USER_ROLE_FAILURE';
export const SET_ROLE_MODAL_LOADING = 'SET_ROLE_MODAL_LOADING';
export const RESET_ROLE_SUCCESS_STATUS = 'RESET_ROLE_SUCCESS_STATUS';

export const GET_ALL_ROLE_PERMISSION = 'GET_ALL_ROLE_PERMISSION';
export const GET_ALL_ROLE_PERMISSION_SUCCESS = 'GET_ALL_ROLE_PERMISSION_SUCCESS';
export const GET_ALL_ROLE_PERMISSION_FAILURE = 'GET_ALL_ROLE_PERMISSION_FAILURE';

export const GET_UNASSIGNED_ROLE_PERMISSIONS = 'GET_UNASSIGNED_ROLE_PERMISSIONS';
export const GET_UNASSIGNED_ROLE_PERMISSIONS_SUCCESS = 'GET_UNASSIGNED_ROLE_PERMISSIONS_SUCCESS';
export const GET_UNASSIGNED_ROLE_PERMISSIONS_FAILURE = 'GET_UNASSIGNED_ROLE_PERMISSIONS_FAILURE';

export const GET_ASSIGNED_ROLE_PERMISSIONS = 'GET_ASSIGNED_ROLE_PERMISSIONS';
export const GET_ASSIGNED_ROLE_PERMISSIONS_SUCCESS = 'GET_ASSIGNED_ROLE_PERMISSIONS_SUCCESS';
export const GET_ASSIGNED_ROLE_PERMISSIONS_FAILURE = 'GET_ASSIGNED_ROLE_PERMISSIONS_FAILURE';

export const EDIT_ROLE_PERMISSIONS = 'EDIT_ROLE_PERMISSIONS';
export const EDIT_ROLE_PERMISSIONS_SUCCESS = 'EDIT_ROLE_PERMISSIONS_SUCCESS';
export const EDIT_ROLE_PERMISSIONS_FAILURE = 'EDIT_ROLE_PERMISSIONS_FAILURE';

export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';
export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';
export const GET_USER_PERMISSION_FAILURE = 'GET_USER_PERMISSION_FAILURE';

export const SET_PERMISSIONS_LOADING = 'SET_PERMISSIONS_LOADING';

export const GET_ASSIGNED_ROLES_BY_USER = 'GET_ASSIGNED_ROLES_BY_USER';
export const GET_ASSIGNED_ROLES_BY_USER_SUCCESS = 'GET_ASSIGNED_ROLES_BY_USER_SUCCESS';
export const GET_ASSIGNED_ROLES_BY_USER_FAILURE = 'GET_ASSIGNED_ROLES_BY_USER_FAILURE';

export const EDIT_ROLES_BY_USER = 'EDIT_ROLES_BY_USER';
export const EDIT_ROLES_BY_USER_SUCCESS = 'EDIT_ROLES_BY_USER_SUCCESS';
export const EDIT_ROLES_BY_USER_FAILURE = 'EDIT_ROLES_BY_USER_FAILURE';

export const RESET_USERS_SUCCESS_STATUS = 'RESET_USERS_SUCCESS_STATUS';

export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';

export const GET_SHIP_HISTORY_BY_YEAR = 'GET_SHIP_HISTORY_BY_YEAR';
export const GET_SHIP_HISTORY_BY_YEAR_SUCCESS = 'GET_SHIP_HISTORY_BY_YEAR_SUCCESS';
export const GET_SHIP_HISTORY_BY_YEAR_FAILURE = 'GET_SHIP_HISTORY_BY_YEAR_FAILURE';

export const GET_WAREHOUSE = 'GET_WAREHOUSE';
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS';
export const GET_WAREHOUSE_FAILURE = 'GET_WAREHOUSE_FAILURE';

export const GET_SHIP_HISTORY_BY_DATE_RANGE = 'GET_SHIP_HISTORY_BY_DATE_RANGE';
export const GET_SHIP_HISTORY_BY_DATE_RANGE_SUCCESS = 'GET_SHIP_HISTORY_BY_DATE_RANGE_SUCCESS';
export const GET_SHIP_HISTORY_BY_DATE_RANGE_FAILURE = 'GET_SHIP_HISTORY_BY_DATE_RANGE_FAILURE';

export const GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE = 'GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE';
export const GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_SUCCESS = 'GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_SUCCESS';
export const GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_FAILURE = 'GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE_FAILURE';

export const SET_WAREHOUSE_CLIENT_GRAPH_LOADING = 'SET_WAREHOUSE_CLIENT_GRAPH_LOADING';

export const RESET_DASHBOARD_WAREHOUSE_DATA = 'RESET_DASHBOARD_WAREHOUSE_DATA';
export const SET_WAREHOUSE_ORDER_LOADING = 'SET_WAREHOUSE_ORDER_LOADING';
export const GET_ORDER_BY_WAREHOUSE = 'GET_ORDER_BY_WAREHOUSE';
export const GET_ORDER_BY_WAREHOUSE_SUCCESS = 'GET_ORDER_BY_WAREHOUSE_SUCCESS';
export const GET_ORDER_BY_WAREHOUSE_FAILURE = 'GET_ORDER_BY_WAREHOUSE_FAILURE';
export const GET_DASHBOARD_BY_WAREHOUSE = 'GET_DASHBOARD_BY_WAREHOUSE';
export const GET_DASHBOARD_BY_WAREHOUSE_SUCCESS = 'GET_DASHBOARD_BY_WAREHOUSE_SUCCESS';
export const GET_DASHBOARD_BY_WAREHOUSE_FAILURE = 'GET_DASHBOARD_BY_WAREHOUSE_FAILURE';
export const GET_DASHBOARD_WAREHOUSE = 'GET_DASHBOARD_WAREHOUSE';
export const GET_DASHBOARD_WAREHOUSE_SUCCESS = 'GET_DASHBOARD_WAREHOUSE_SUCCESS';
export const GET_DASHBOARD_WAREHOUSE_FAILURE = 'GET_DASHBOARD_WAREHOUSE_FAILURE';
