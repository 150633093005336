import React from 'react';
import BillingSummary from './BillingSummary';
import BillingItemList from './BillingItemList';
import { Box, Typography } from '@mui/material';
import workOrderApi from '../../workOrderApi';
import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import { Spinner } from '../../../../common/components';
import BillingItemForm from './BillingItemForm';

interface WorkOrderBillingItemsProps {
    isDisabled: boolean;
}

const WorkOrderBillingItems = ({ isDisabled }: WorkOrderBillingItemsProps) => {
    const getServicesResponse = workOrderApi.endpoints.getServices.useQuery();

    useNotificationByResponse({
        isFetching: getServicesResponse.isFetching,
        isError: getServicesResponse.isError,
        errorContent: 'Failed to get services',
        // isSuccess: getServicesResponse.isSuccess,
        // successContent: `Received services`,
    });

    return (
        <Box sx={{}}>
            {getServicesResponse?.data ? (
                <Box>
                    <BillingSummary />
                    <Box sx={{ display: isDisabled ? 'none' : '' }}>
                        <BillingItemForm isDisabled={isDisabled} />
                    </Box>
                    <BillingItemList isDisabled={isDisabled} />
                </Box>
            ) : getServicesResponse.isError ? (
                <Typography>Failed to get services</Typography>
            ) : (
                <Spinner />
            )}
        </Box>
    );
};

export default React.memo(WorkOrderBillingItems);
