import React from 'react';
import ShipMatrixDataGrid from '../ShipMatrixDataGrid';
import shipMatrixApi from '../../../shipMatrixApi';
import { GetClientEditLogDetailsRequest } from '../../../shipMatrixModel';
import { Spinner } from '../../../../../common/components';

const EditLogDetailMatrix = ({
    editLogDetailId,
    propertyToEdit,
}: {
    editLogDetailId: number;
    propertyToEdit: 'ShipCode' | 'FreightCost' | 'Markup' | 'FreightCostAndMarkup';
}) => {
    const getEditLogDetailResponse = shipMatrixApi.endpoints.getMapEditLogDetails.useQuery({
        editLogId: editLogDetailId,
    } as GetClientEditLogDetailsRequest);

    return (
        <>
            {!getEditLogDetailResponse.data?.Data || getEditLogDetailResponse.isFetching ? (
                <Spinner />
            ) : (
                <ShipMatrixDataGrid
                    submitData={getEditLogDetailResponse.data?.Data}
                    selectedCells={[]}
                    setSelectedCells={() => {}}
                    propertyToEdit={propertyToEdit}
                    setSubmitData={() => {}}
                />
            )}
        </>
    );
};

export default EditLogDetailMatrix;
