import { Grid, Paper } from '@mui/material';
import LoadingCard from '../../common/components/Card/LoadingCard';

const LoadingCards = () => {
    return (
        <Grid container sx={{ mt: 4, display: 'flex' }} spacing={3}>
            {Array.from({ length: 4 }, (_, ind) => (
                <Grid item xs={12} sm={6} md={4} key={ind} lg={3}>
                    <Paper>
                        <LoadingCard />
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default LoadingCards;
