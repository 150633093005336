import { GET_COMPANY_SKUS } from '../../../app/services/api_constants';
import API from '../../../features/authentication/axiosApiInstance';

export const getCompanySkusByTaskState = async action => {
    const res = await API.post(`${GET_COMPANY_SKUS}`, action.taskState);
    // if (res.status >= 400) {
    //     throw new Error('Invalid Credentials');
    // }
    return res;
};
