import useNotificationByResponse from '../../../../common/hooks/useNotificationByResponse';
import shipMatrixApi from '../../shipMatrixApi';
import { Filter, Group } from '../../shipMatrixModel';
import ShipMatrixEditorChild from './ShipMatrixEditorChild';

interface ShipMatrixEditorProps {
    currGroup: Group;
}

const ShipMatrixEditor = ({ currGroup }: ShipMatrixEditorProps) => {
    const getMatrixResponse = shipMatrixApi.endpoints.getMatrix.useQuery({ GroupId: currGroup.Id } as Filter);

    useNotificationByResponse({
        isFetching: getMatrixResponse.isFetching,
        isError: getMatrixResponse.isError,
        errorContent: 'Failed to ship matrix',
        isSuccess: getMatrixResponse.isSuccess,
        successContent: `Succesfully got ship matrix`,
        isUninitialized: getMatrixResponse.isUninitialized,
    });

    return getMatrixResponse.data ? (
        <ShipMatrixEditorChild
            data={getMatrixResponse.data.Data}
            currGroup={currGroup}
            key={JSON.stringify(getMatrixResponse.data)}
        />
    ) : (
        <></>
    );
};

export default ShipMatrixEditor;
