import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { SerialFilter } from '../../clearSerialModels';

const SkuForm = ({ client, onSubmit }: { client: string; onSubmit: (serial: SerialFilter[]) => void }) => {
    const [sku, setSku] = React.useState('');
    const [serial, setSerial] = React.useState('');

    return (
        <form
            onSubmit={e => {
                e.preventDefault();

                onSubmit([
                    {
                        Client: client,
                        Sku: sku.trim(),
                        Serial: serial.trim(),
                    },
                ]);

                setSerial('');
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <TextField
                    value={sku}
                    onChange={e => {
                        const newValue = e.target.value;
                        setSku(newValue);
                    }}
                    label="SKU"
                    name="SKU"
                    sx={{ flex: 3, mx: 1 }}
                    required
                    variant="filled"
                />

                <TextField
                    value={serial}
                    onChange={e => {
                        const newValue = e.target.value;
                        setSerial(newValue);
                    }}
                    label="Serial"
                    name="Serial"
                    sx={{ flex: 3, mx: 1 }}
                    required
                    variant="filled"
                />

                <Button type="submit" sx={{ flex: 1, mx: 1 }} variant="contained">
                    Add
                </Button>
            </Box>
        </form>
    );
};

export default SkuForm;
