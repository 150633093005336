import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from '../store/actions/actionTypes';
import * as api from '../store/api/companySkusByTaskStateApi';

export function* getCompanySkusByTaskState(action) {
    try {
        const { data } = yield call(api.getCompanySkusByTaskState, action);
        yield put({
            type: actionTypes.GET_COMPANY_SKUS_BY_TASK_STATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: actionTypes.GET_COMPANY_SKUS_BY_TASK_STATE_FAILURE,
            errorMessage: error,
        });
    }
}

export default function* companySkusByTaskStateSagaWatcher() {
    yield takeLatest(actionTypes.GET_COMPANY_SKUS_BY_TASK_STATE, getCompanySkusByTaskState);
}
