import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TrackingState {
    momCode: string;
}

const initialState: TrackingState = {
    momCode: '',
};

const slice = createSlice({
    name: 'tracking',
    initialState,
    reducers: {
        setMomCode: (state, action: PayloadAction<string>) => {
            state.momCode = action.payload;
        },
    },
});

export const { setMomCode } = slice.actions;

export default slice.reducer;

// export const selectGlobalPollingEnabled = (state: RootState) => state.polling.enabled;
// export const selectPollingConfigByApp = (state: RootState, app: PollingAppKey) => state.polling.apps[app];
