import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { AuthenticationResponse } from '../../features/authentication/authenticationModels';
import { logOut, setAuthentication } from '../../features/authentication/authenticationSlice';
import { RootState } from '../store';
import { AUTH_REFRESH, ROAPI_URL } from './api_constants';

const mutex = new Mutex();

export const roapiBaseQuery = fetchBaseQuery({
    baseUrl: ROAPI_URL,
    timeout: 30000,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).authentication.authenticationResponse?.token;

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Content-Type', 'application/json');
        headers.set('Cache-Control', 'no-store, max-age=0');

        return headers;
    },
});

export const roapiBaseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    await mutex.waitForUnlock();
    let result = await roapiBaseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                const token = (api.getState() as RootState).authentication.authenticationResponse?.token;
                const refresh = (api.getState() as RootState).authentication.authenticationResponse?.refresh;
                const refreshResult = await roapiBaseQuery(
                    {
                        method: 'POST',
                        url: AUTH_REFRESH,
                        body: {
                            token: token,
                            refresh: refresh,
                        },
                    },
                    api,
                    extraOptions
                );

                if (refreshResult.data) {
                    api.dispatch(setAuthentication({ response: refreshResult.data as AuthenticationResponse }));
                    result = await roapiBaseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(logOut());
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await roapiBaseQuery(args, api, extraOptions);
        }
    }
    return result;
};
