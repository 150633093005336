import 'fontsource-roboto';
import 'fontsource-roboto/500.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store from './app/store';
import { Spinner } from './common/components';
import { roapiInjectStore } from './features/authentication/axiosApiInstance';
import { dreamInjectStore } from './features/authentication/axiosApiInstanceDream';
import './index.css';

let persistor = persistStore(store);
roapiInjectStore(store);
dreamInjectStore(store);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
