import * as actionTypes from './actionTypes';

export const authenticateAction = (username, password) => ({
    type: actionTypes.AUTHENTICATE,
    username: username,
    password: password,
});

export const resetAuthenticateLoadingAction = () => ({
    type: actionTypes.RESET_AUTHENTICATE_LOADING,
});

export const alreadyAuthenticated = () => ({
    type: actionTypes.ALREADY_AUTHENTICATED,
});

export const getNewTokenAction = () => ({
    type: actionTypes.GET_NEW_TOKEN,
});

//notification

export const setSnackbarAction = (message, severity = 'success') => ({
    type: actionTypes.SET_SNACKBAR,
    message: message,
    severity: severity,
});

export const resetSnackbarAction = () => ({
    type: actionTypes.RESET_SNACKBAR,
});

export const setDialogAction = (content, title = 'Notify Supervisor!', color = 'red') => ({
    type: actionTypes.SET_DIALOG,
    title: title,
    content: content,
    color: color,
});

export const resetDialogAction = () => ({
    type: actionTypes.RESET_DIALOG,
});

// Receivers

export const getReceiversAction = (momCode, startDate, endDate) => ({
    type: actionTypes.GET_RECEIVERS,
    payload: {
        momCode,
        startDate,
        endDate,
    },
});

export const getReceiverConfigAction = momCode => ({
    type: actionTypes.GET_RECEIVERS_CONFIG,
    payload: {
        momCode,
    },
});

export const getReceiverInboundsAction = momCode => ({
    type: actionTypes.GET_RECEIVERS_INBOUNDS,
    payload: {
        momCode,
    },
});

export const getReceiverStocksAction = momCode => ({
    type: actionTypes.GET_RECEIVERS_STOCKS,
    payload: {
        momCode,
    },
});

export const postReceiversFormAction = (form, ifUpdateInsteadOfCreate, receiverNumber) => ({
    type: actionTypes.POST_RECEIVERS_FORM,
    payload: {
        form,
        ifUpdateInsteadOfCreate,
        receiverNumber,
    },
});

export const resetReceiversSubmitFormStatusAction = () => ({
    type: actionTypes.RESET_RECEIVERS_SUBMIT_FORM_STATUS,
});

export const getReceiversHistoryAction = (momCode, receiverNumber) => ({
    type: actionTypes.GET_RECEIVERS_HISTORY,
    payload: {
        momCode,
        receiverNumber,
    },
});

export const playReceiversAction = PlayReceiverPayload => ({
    type: actionTypes.PLAY_RECEIVERS,
    payload: {
        ...PlayReceiverPayload,
    },
});

//Application

export const getApplicationVersionAction = () => ({
    type: actionTypes.GET_APPLICATION_VERSION,
});

// Tasks

export const getTasksByUserIdAndStateAction = (id, state) => ({
    type: actionTypes.GET_TASKS_BY_USER_ID_AND_STATE,
    id: id,
    state: state,
});

export const getFilteredTasksAction = filter => ({
    type: actionTypes.GET_FILTERED_TASKS,
    payload: filter,
});

export const setTasksLoadingTrueAction = () => ({
    type: actionTypes.SET_TASK_LOADING_TRUE,
});

export const updateSelectedTask = selectedTask => ({
    type: actionTypes.UPDATE_SELECTED_TASK,
    selectedTask: selectedTask,
});

export const getPalletsForTaskIdAction = taskTypeId => ({
    type: actionTypes.GET_PALLETS_BY_TASK_ID,
    payload: taskTypeId,
});

export const submitPalletAction = (content, countTaskId) => ({
    type: actionTypes.PALLET_SUBMIT,
    payload: { countTaskId: content.countTaskId, content, countTaskId },
});

// Quality Control
export const getQCOrderStatusAction = invoice => ({
    type: actionTypes.GET_QC_ORDER_STATUS,
    invoice: invoice,
});

export const setQCToInitialStateAction = () => ({
    type: actionTypes.SET_QC_TO_INITIAL_STATE,
});

export const setQCOrderDataAction = data => ({
    type: actionTypes.SET_QC_ORDER_DATA,
    data: data,
});

export const getQCOrderDataAction = (invoice, markAsStartedAfterGet = true) => ({
    type: actionTypes.GET_QC_ORDER_DATA,
    invoice: invoice,
    markAsStartedAfterGet,
});

export const partialSubmitQCAction = (invoice, payload, willResetQCAfter = false) => ({
    type: actionTypes.QC_PARTIAL_SUBMIT,
    invoice,
    payload,
    willResetQCAfter,
});

export const submitQCAction = (invoice, payload, markAsCompleteAfterSubmit) => ({
    type: actionTypes.QC_SUBMIT,
    invoice,
    payload,
    markAsCompleteAfterSubmit,
});

export const getQCSerialCheckAction = payload => ({
    type: actionTypes.GET_QC_SERIAL_CHECK,
    payload,
});

export const startQCAction = (invoice, payload) => ({
    type: actionTypes.START_QC,
    invoice,
});

export const completeQCAction = (invoice, payload) => ({
    type: actionTypes.COMPLETE_QC,
    invoice,
});

export const abortQCAction = (invoice, payload) => ({
    type: actionTypes.ABORT_QC,
    invoice,
});

export const resetQCAction = (invoice, payload) => ({
    type: actionTypes.RESET_QC,
    invoice,
});

export const incrQCItemQuantityAction = payload => ({
    type: actionTypes.INCR_QC_ITEM_QUANTITY,
    payload,
});

// Stock
export const initStockStateAction = () => ({
    type: actionTypes.INIT_STOCK_STATE,
});
export const setStockPalletValueAction = value => ({
    type: actionTypes.SET_STOCK_PALLET_VALUE,
    payload: value,
});

export const setStockReducerLoading = value => ({
    type: actionTypes.SET_STOCKS_REDUCER_LOADING,
    payload: value,
});
export const getStockPalletFiltersAction = () => ({
    type: actionTypes.GET_STOCK_PALLET_FILTERS,
    // meta:{
    //     offline:{
    //         effect:{
    //             url:generateApiLink(GET_STOCK_PALLET_FILTERS_URL),
    //             method:"GET",
    //             headers: getHeadersForOfflineRequest(),
    //         },
    //         commit:{
    //             type:actionTypes.GET_STOCK_PALLET_FILTERS_SUCCESS,
    //             meta:{message:'yipee its a success'}
    //         },
    //         rollback:{
    //             type:actionTypes.GET_STOCK_PALLET_FILTERS_FAILURE,
    //             meta:{message:'bummer its a failure'}
    //         }
    //     }
    // }
});

export const searchPalletsByFiltersAction = (value, palletTag) => ({
    type: actionTypes.SEARCH_PALLETS_BY_FILTERS,
    payload: value,
    meta: {
        palletTag: palletTag,
        // offline:{
        //     effect:{
        //         url:generateApiLink(SEARCH_PALLET_BY_FILTERS_URL),
        //         method:"POST",
        //         headers: getHeadersForOfflineRequest(),
        //         body:JSON.stringify({...value}),
        //     },
        //     commit:{
        //         type:actionTypes.SEARCH_PALLETS_BY_FILTERS_SUCCESS,
        //         meta:{ palletTag }
        //     },
        //     rollback:{
        //         type:actionTypes.SEARCH_PALLETS_BY_FILTERS_FAILURE,
        //         meta:{errorMessage:'something went wrong on our end'}
        //     }
        // }
    },
});

export const loadMoreSearchPalletsByFiltersAction = (value, palletTag, currentPage) => ({
    type: actionTypes.LOAD_MORE_SEARCH_PALLETS_BY_FILTERS,
    payload: value,
    meta: {
        palletTag: palletTag,
        currentPage,
    },
});

export const getStockPalletLogsAction = value => ({
    type: actionTypes.GET_STOCK_PALLET_LOGS,
    payload: value,
});

// pallet

export const getAllPalletsAction = () => ({
    type: actionTypes.GET_ALL_PALLETS,
});

export const getPalletFiltersAction = () => ({
    type: actionTypes.GET_PALLET_FILTERS,
});

export const getFilteredPalletsAction = filterParam => ({
    type: actionTypes.GET_FILTERED_PALLETS,
    payload: filterParam,
});

export const generatePalletCountAction = () => ({
    type: actionTypes.GENERATE_PALLET_COUNT,
});

export const getCountResultAction = () => ({
    type: actionTypes.GET_COUNT_RESULT,
});

export const setCountResultInitialStateAction = () => ({
    type: actionTypes.COUNT_RESULT_INITIAL_STATE,
});

export const getCompaniesAction = () => ({
    type: actionTypes.GET_COMPANIES,
});

export const getUsersAction = () => ({
    type: actionTypes.GET_USERS,
});

export const getSkusForCompanyAction = companyId => ({
    type: actionTypes.GET_SKUS,
    companyId: companyId,
});

export const createTaskAction = (taskToCreate, taskState) => ({
    type: actionTypes.CREATE_TASK,
    taskDetail: taskToCreate,
    meta: {
        taskState,
    },
});

export const setCreateTaskToInitialAction = () => ({
    type: actionTypes.CREATE_TASK_INITIAL_STATE,
});

export const getCompanySkusByTaskStateAction = taskState => ({
    type: actionTypes.GET_COMPANY_SKUS_BY_TASK_STATE,
    taskState: taskState,
});

export const setCompanySkusByTaskStateInitialStateAction = () => ({
    type: actionTypes.COMPANY_SKUS_INITIAL_STATE,
});

export const getTaskDetailsBasedOnSkuAction = (skuName, assignedUserId, companyId, tabType) => ({
    type: actionTypes.GET_TASK_DETAILS_BASED_ON_SKU,
    sku: skuName,
    assignedUserId: assignedUserId,
    compId: companyId,
    tabType,
});

export const getReviewTaskDetailsAction = (filter, shouldMerge) => ({
    type: actionTypes.GET_REVIEW_TASK_DETAILS,
    payload: {
        ...filter,
    },
    meta: {
        shouldMerge,
    },
});

export const getRejectTaskDetailsAction = (payload, shouldRedirect) => ({
    type: actionTypes.GET_REJECT_TASK_DETAILS,
    payload,
    shouldRedirect,
});

export const rejectAndCreateTaskAction = (payload, shouldRedirect, taskToCreate, taskState) => ({
    type: actionTypes.REJECT_AND_CREATE_TASK,
    payload,
    shouldRedirect,
    taskDetail: taskToCreate,
    meta: {
        taskState,
    },
});

export const setReAssignTaskToInitialAction = () => ({
    type: actionTypes.REASSIGN_TASK_INITIAL_STATE,
});

export const getApproveTaskDetailsAction = payload => ({
    type: actionTypes.GET_APPROVE_TASK_DETAILS,
    payload,
});

export const getReAssignTaskDetailsAction = taskId => ({
    type: actionTypes.GET_REASSIGN_TASK_DETAILS,
    taskId,
});

export const updateTaskListAfterReAssigningAction = updatedTaskList => ({
    type: actionTypes.UPDATE_TASK_LIST_AFTER_REASSIGNING,
    payload: updatedTaskList,
});

export const reAssignTaskUserAction = reassignTask => ({
    type: actionTypes.REASSIGN_TASK_DETAILS,
    taskDetail: reassignTask,
});

export const discardTaskAction = payload => ({
    type: actionTypes.DISCARD_TASK,
    payload,
});

// PALLET_ACTION ACTIONS
export const retirePalletTagAction = payload => ({
    type: actionTypes.RETIRE_PALLET_TAG,
    payload,
});

export const quarantinePalletTagAction = payload => ({
    type: actionTypes.QUARANTINE_PALLET_TAG,
    payload,
});

export const clonePalletTagAction = payload => ({
    type: actionTypes.CLONE_PALLET_TAG,
    payload,
});

export const validatePalletTagAction = payload => ({
    type: actionTypes.VALIDATE_PALLET_TAG,
    payload,
});

export const updatePalletLocationAction = payload => ({
    type: actionTypes.UPDATE_PALLET_LOCATION,
    payload,
});

export const resetStocksSuccessStatusAction = () => ({
    type: actionTypes.RESET_STOCKS_SUCCESS_STATUS,
});

export const setStockPalletActionLoading = () => ({
    type: actionTypes.SET_STOCK_PALLET_ACTION_LOADING,
});

export const getWarehouseListAction = () => ({
    type: actionTypes.GET_WAREHOUSE_LIST,
});

export const getLocationListByWarehouseAction = payload => ({
    type: actionTypes.GET_LOCATIONS_BY_WAREHOUSE,
    payload,
});

// Roles Api Actions

export const getAllUsersAction = payload => ({
    type: actionTypes.GET_ALL_USERS,
    payload,
});

export const getRolesAction = () => ({
    type: actionTypes.GET_ROLE,
});

export const addRoleAction = payload => ({
    type: actionTypes.ADD_ROLE,
    payload,
});

export const updateRoleAction = payload => ({
    type: actionTypes.UPDATE_ROLE,
    payload,
});

export const deleteRoleAction = payload => ({
    type: actionTypes.DELETE_ROLE,
    payload,
});

export const editUserRoleAction = payload => ({
    type: actionTypes.EDIT_USER_ROLE,
    payload,
});

export const getAllRolePermissionAction = payload => ({
    type: actionTypes.GET_ALL_ROLE_PERMISSION,
    payload,
});

export const getUnAssignedRolePermissionsAction = payload => ({
    type: actionTypes.GET_UNASSIGNED_ROLE_PERMISSIONS,
    payload,
});

export const getAssignedRolePermissionsAction = payload => ({
    type: actionTypes.GET_ASSIGNED_ROLE_PERMISSIONS,
    payload,
});

export const editRolePermissionsAction = (payload, meta) => ({
    type: actionTypes.EDIT_ROLE_PERMISSIONS,
    payload,
    meta,
});

export const getUserPermissionAction = payload => ({
    type: actionTypes.GET_USER_PERMISSION,
    payload,
});

export const resetRoleSuccessStatusAction = payload => ({
    type: actionTypes.RESET_ROLE_SUCCESS_STATUS,
});

export const getAssignedRolesByUserAction = payload => ({
    type: actionTypes.GET_ASSIGNED_ROLES_BY_USER,
    payload,
});

export const setPermissionsLoading = payload => ({
    type: actionTypes.SET_PERMISSIONS_LOADING,
});

export const editRolesByUserAction = (payload, meta) => ({
    type: actionTypes.EDIT_ROLES_BY_USER,
    payload,
    meta,
});

export const resetUsersSuccessStatusAction = () => ({
    type: actionTypes.RESET_USERS_SUCCESS_STATUS,
});

export const getShipHistoryByYearAction = payload => ({
    type: actionTypes.GET_SHIP_HISTORY_BY_YEAR,
    payload,
});

export const getWarehouseAction = payload => ({
    type: actionTypes.GET_WAREHOUSE,
    payload,
});

export const getShipHistoryByDateRangeAction = payload => ({
    type: actionTypes.GET_SHIP_HISTORY_BY_DATE_RANGE,
    payload,
});

export const getShipHistoryByYearMonthAction = payload => ({
    type: actionTypes.GET_SHIP_HISTORY_BY_YEAR_MONTH_WAREHOUSE,
    payload,
});

export const getOrderbyWarehouseAction = payload => ({
    type: actionTypes.GET_ORDER_BY_WAREHOUSE,
    payload,
});

export const getDashboardWarehouseAction = payload => ({
    type: actionTypes.GET_DASHBOARD_WAREHOUSE,
    payload,
});

export const resetDashboardWarehouseData = payload => ({
    type: actionTypes.RESET_DASHBOARD_WAREHOUSE_DATA,
    payload,
});
