import { Card } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { formatDayjsDateWithoutTime } from '../../../../common/utils/dateUtil';
import { SimplifiedProcedureRecord } from '../../models';

const columns: GridColDef<SimplifiedProcedureRecord>[] = [
    {
        field: 'Id',
        headerName: 'Id',
        flex: 1,
    },
    {
        field: 'Client',
        headerName: 'Client',
        flex: 2,
    },
    {
        field: 'Title',
        headerName: 'Title',
        flex: 6,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        flex: 2,
    },
    {
        field: 'CreatedAt',
        headerName: 'Created At',
        flex: 3,
        valueGetter: (value, row) => formatDayjsDateWithoutTime(row.CreatedAt),
    },
];

const ProceduresDatagrid = ({
    procedures,
    onRowClick,
}: {
    procedures: SimplifiedProcedureRecord[];
    onRowClick: (procedure: SimplifiedProcedureRecord) => void;
}) => {
    const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams<SimplifiedProcedureRecord>) => {
        onRowClick(params.row);
    };

    return (
        <Card elevation={4} sx={{}}>
            <DataGrid
                disableRowSelectionOnClick
                rows={procedures}
                columns={columns}
                getRowId={row => row.Id}
                sx={{}}
                onRowClick={handleRowClick}
            />
        </Card>
    );
};

export default ProceduresDatagrid;
