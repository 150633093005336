import { Autocomplete, TextField } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useAppSelector } from '../../../../../../../app/store';
import receiversApi from '../../../../../receiversApi';
import { SubmissionFormItem } from '../../../../../receiversModels';

const ItemAutocomplete = ({
    itemToUpsert,
    setItemToUpsert,
}: {
    itemToUpsert?: SubmissionFormItem;
    setItemToUpsert: React.Dispatch<React.SetStateAction<SubmissionFormItem | undefined>>;
}) => {
    const { form } = useAppSelector(state => state.receivers);

    const response = receiversApi.endpoints.getReceiverStocks.useQuery(form.Client ? form.Client : skipToken);

    const getLabel = (): string => {
        if (response.isFetching) return 'Loading...';

        if (response.isError) return 'Error loading stock';

        return 'Item';
    };

    return (
        <Autocomplete
            disablePortal
            autoSelect
            options={[...new Map(response?.data?.map(item => [item['Item'], item])).values()]} //gets unique, but only needed for FRAN
            getOptionLabel={(stock: SubmissionFormItem) =>
                // `${stock.Sku} ${stock.Description} (Available in stock: ${stock.Available})`
                `${stock.Item}: ${stock.Descript} (${stock.Upc})`
            }
            value={itemToUpsert}
            onChange={(event: any, newValue: SubmissionFormItem | null) => {
                if (newValue?.Item) setItemToUpsert({ ...newValue });
                else setItemToUpsert(undefined);
            }}
            renderInput={params => <TextField {...params} label={getLabel()} />}
            sx={{ mt: 2, flex: 1 }}
            isOptionEqualToValue={(option, value) => value === option}
        />
    );
};

export default ItemAutocomplete;
